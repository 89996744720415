<div
  *ngIf="{ students: dataStudents$ | async, load: loading$ | async } as data"
  app-modal-content
  class="h-[80vh] w-[98vw] !p-0 md:h-[90vh]"
>
  <ng-container *ngIf="!data.load; else emptyTemplateGeneral">
    <app-sidenav-group class="!bg-base-whisper-500">
      <ng-container *ngIf="aplication$ | async as aplication">
        <ng-template [opened]="true" app-sidenav-start>
          <div class="h-full bg-white p-6">
            <div class="grid w-full p-5">
              <span class="font-bold" translate> gestor-avaliacoes.alunos </span>

              <div
                *ngIf="uploadHistory$ | async as history"
                class="my-2 flex items-center gap-2 rounded-lg border border-base-status-warning-400 bg-base-status-warning-200 px-5 py-2"
              >
                <h1 class="text-sm text-zinc-900" translate>
                  {{ history.nome }} - Processando {{ history.tentativas_count }}/{{ history.page_count }}
                </h1>
                <button
                  app-flat-button
                  (click)="onRefreshHistoryUpload()"
                  class="text-black/2 bg-black/5"
                  type="button"
                  translate
                >
                  <svg-icon class="h-6 w-6" src="assets/icons/icon-refresh.svg"></svg-icon>
                </button>
              </div>

              <div class="flex justify-between">
                <span class="font-light" translate> gestor-avaliacoes.aguardando-revisao </span>
                <app-switch [formControl]="pendingCtrl"></app-switch>
              </div>
              <label app-label translate> gestor-avaliacoes.filtro-turma-aluno </label>
              <ng-select
                app-select
                [formControl]="searchGroupCtrl"
                [items]="groups"
                [loading]="loading$ | async"
                [placeholder]="'geral.selecione' | translate"
                [loadingText]="'geral.carregando' | translate"
                [clearable]="false"
              >
              </ng-select>
            </div>
            <hr app-divider />
            <!-- CARD DOS ALUNOS  -->
            <div class="px-1 pb-16 pt-0">
              <ng-container *ngIf="!!data.students?.length; else emptyStudents">
                <app-card
                  *ngFor="let student of data.students; let index = index"
                  [id]="'user_' + student.usuario_id"
                  (click)="onSetSelectedUser(student, index)"
                  class="mb-1 mt-5 flex !flex-row items-center p-4 !shadow-black/25 hover:bg-base-mantis-200"
                  [ngClass]="{
                    ' active:!brightness-85 z-10 !bg-base-mantis-100 !shadow-inner !shadow-black/40 !transition-all !duration-300':
                      student.usuario_id === selectedUser$.value?.data?.usuario_id &&
                      student.tentativa_uuid === selectedUser$.value?.data?.tentativa_uuid
                  }"
                >
                  <ng-container *ngIf="student.nome; else emptyStudentTemplate">
                    <div class="w-3/12">
                      <app-user-photo
                        class="h-auto !w-max md:!h-16"
                        [height]="'!h-16'"
                        [width]="'!w-max'"
                        [src]="student.foto"
                      ></app-user-photo>
                    </div>
                    <div class="flex w-full flex-col">
                      <span class="flex justify-between gap-2">
                        <div class="ml-2 flex md:ml-0 md:gap-1">
                          <span class="font-semibold">
                            {{ student.nome }}

                            <svg-icon
                              *ngIf="student.necessidades_especiais?.length"
                              app-tooltip
                              [tooltip]="formattedNecessidadesEspeciais(student.necessidades_especiais)"
                              class="active:brightness-85 inline-block h-6 w-6 cursor-pointer pt-1 text-gray-400 hover:brightness-110"
                              src="assets/icons/icon-info-rounded.svg"
                            >
                            </svg-icon>
                          </span>
                        </div>
                        <div class="flex gap-1">
                          <svg-icon
                            *ngIf="!!student.tentativa_uuid"
                            app-tooltip
                            tooltip="Desvincular aluno"
                            class="active:brightness-85 w-6 cursor-pointer text-base-status-info hover:brightness-110"
                            (click)="onRemoveStudent(student, index)"
                            src="assets/icons/icon-copy-square.svg"
                          ></svg-icon>
                          <svg-icon
                            *ngIf="!!student.tentativa_uuid"
                            app-tooltip
                            tooltip="Excluir respostas"
                            class="active:brightness-85 hover w-6 cursor-pointer text-base-status-danger hover:brightness-110"
                            (click)="onRemoveAttempt(student)"
                            src="assets/icons/icon-trash.svg"
                          ></svg-icon>
                        </div>
                      </span>
                      <ng-container
                        *ngTemplateOutlet="
                          statusTemplate;
                          context: { status: student.status, aplication, usuario_id: student.usuario_id }
                        "
                      ></ng-container>
                    </div>
                  </ng-container>
                  <ng-template #emptyStudentTemplate>
                    <div class="w-3/12">
                      <div
                        class="flex h-12 w-12 items-center justify-center rounded-full bg-theme-300 text-2xl font-semibold text-theme-700"
                      >
                        {{ student.order + 1 }}
                      </div>
                    </div>
                    <div class="flex w-full flex-col gap-2">
                      <div class="flex w-full items-center gap-4 font-bold">
                        <div class="w-full">
                          <ng-select
                            app-select
                            [formControl]="dataStudentCtrl"
                            [items]="dataStudentFilter$ | async"
                            bindLabel="nome"
                            bindValue="usuario_id"
                            [notFoundText]="'geral.nao-encontrado' | translate"
                            (change)="onVincularStudent($event)"
                            [placeholder]="'eclass.selecione' | translate"
                          >
                          </ng-select>
                        </div>
                        <div>
                          <svg-icon
                            app-tooltip
                            tooltip="Excluir respostas"
                            class="active:brightness-85 block w-6 cursor-pointer text-base-status-danger hover:brightness-110"
                            (click)="onRemoveAttempt(student)"
                            src="assets/icons/icon-trash.svg"
                          ></svg-icon>
                        </div>
                      </div>
                      <ng-container
                        *ngTemplateOutlet="
                          statusTemplate;
                          context: { usuario_id: student.usuario_id, status: student.status, aplication }
                        "
                      ></ng-container>
                    </div>
                  </ng-template>
                </app-card>
              </ng-container>
              <ng-template #emptyStudents>
                <app-card>
                  <div app-card-content class="w-full">
                    <app-jumbotron translate> gestor-avaliacoes.sem-alunos-revisao </app-jumbotron>
                  </div>
                </app-card>
              </ng-template>
            </div>
          </div>
        </ng-template>

        <ng-template app-sidenav-outer class="relative">
          <!-- CARD DOS ALUNOS -->
          <ng-container *ngIf="selectedUser$.value?.data as user">
            <div class="flex h-auto gap-4 p-5">
              <app-card class="flex w-full">
                <div class="flex justify-between p-3">
                  <!-- BOTÃO ANTERIOR -->
                  <div class="flex">
                    <button
                      app-icon-button
                      app-tooltip
                      [disabled]="user.index <= 0"
                      (click)="onPreviousUser()"
                      tooltip="Anterior"
                      type="button"
                      class="!rounded-full bg-white"
                    >
                      <svg-icon class="flex h-6" src="assets/icons/icon-arrow-left-fine.svg"></svg-icon>
                    </button>
                  </div>

                  <!-- INFORMAÇÕES DO ALUNO -->
                  <div class="flex px-5 md:items-center">
                    <app-user-photo
                      class="!h-24 !w-max"
                      [height]="'!h-24'"
                      [width]="'!w-max'"
                      [src]="user.foto"
                    ></app-user-photo>
                    <div class="grid pl-5">
                      <div class="grid items-center" *ngIf="!!user.usuario_id; else noStudent">
                        <span class="text-md font-bold active:text-base-mantis-500 md:text-lg"> {{ user.nome }} </span>
                        <span *ngIf="user.turma_descricao" class="active:text-base-mantis-500">
                          {{ user.turma_descricao }}
                        </span>
                      </div>
                      <ng-template #noStudent>
                        <span class="text-md font-bold active:text-base-mantis-500 md:text-lg">Sem aluno</span>
                      </ng-template>

                      <div class="flex items-baseline">
                        <ng-container *ngIf="evaluationByWeight; else labelPercentual">
                          <p class="text-[14px]">{{ 'gestor-avaliacoes.nota' | translate }}:</p>
                        </ng-container>
                        <ng-template #labelPercentual>
                          <p class="text-[14px]">{{ 'gestor-avaliacoes.percentual-de-acerto' | translate }}:</p>
                        </ng-template>
                        <ng-container *ngIf="!!user.status; else empty">
                          <span *ngIf="!!evaluationByWeight" class="mx-2 text-md font-semibold">
                            {{ user?.nota_calculada | currency : '' : '' : '1.2-2' }}
                          </span>
                          <span *ngIf="!evaluationByWeight" class="mx-2 text-md font-semibold">
                            {{ user?.nota_calculada | currency : '' : '' : '1.0-0' }} %
                          </span>
                        </ng-container>
                        <ng-template #empty>
                          <span class="pl-2 font-bold">-</span>
                        </ng-template>
                      </div>
                    </div>
                  </div>
                  <!-- BOTÃO PRÓXIMO -->
                  <div class="flex">
                    <button
                      [disabled]="user.index >= data.students.length - 1"
                      (click)="onNextUser()"
                      app-icon-button
                      app-tooltip
                      tooltip="Próximo"
                      type="button"
                      class="!rounded-full bg-white"
                    >
                      <svg-icon class="flex h-6" src="assets/icons/icon-arrow-right-fine.svg"></svg-icon>
                    </button>
                  </div>
                </div>
              </app-card>
            </div>
          </ng-container>

          <div class="grid h-auto w-full grid-cols-1 gap-4 p-4 md:grid-cols-2 xl:grid-cols-[45rem_1fr]">
            <!-- CARD CENTRAL COM A IMAGEM  -->
            <div class="relative flex h-full flex-col gap-4">
              <button
                app-outline-button
                *ngIf="(dataViewer$ | async) || (loadingImg$ | async)"
                (click)="onToggleZoom()"
                class="text-full group !absolute right-0 z-10 flex h-12 w-12 justify-center rounded-full border-none bg-base-ea-400 hover:bg-base-ea-600 hover:font-semibold"
              >
                <app-skeleton *ngIf="loadingImg$ | async" class="w-6" baseStyle="bg-base-ea-400"></app-skeleton>
                <svg-icon
                  *ngIf="isZoomedIn; else zoomOutTemplate"
                  class="w-6 text-white group-hover:text-white"
                  src="assets/icons/icon-zoom-out.svg"
                ></svg-icon>
                <ng-template #zoomOutTemplate>
                  <svg-icon
                    class="w-6 text-white group-hover:text-white"
                    src="assets/icons/icon-zoom-in.svg"
                  ></svg-icon>
                </ng-template>
              </button>

              <app-card
                class="flex h-[55vh] w-full overflow-y-auto p-5 shadow-inner shadow-black/40 md:h-full md:max-h-[77vh]"
              >
                <ng-container *ngIf="dataViewer$ | async as dataViewer; else emptyImage">
                  <div class="flex h-full w-full justify-center !rounded-lg">
                    <div class="h-full w-full">
                      <pinch-zoom
                        *ngIf="dataViewer.type === fileTypeEnum.Image; else pdfTemplate"
                        #pinchZoom
                        [zoomControlScale]="2"
                        [autoHeight]="true"
                        [wheel]="false"
                      >
                        <img [src]="dataViewer.url | safe : 'resourceUrl'" (load)="onLoadedImg()" />
                      </pinch-zoom>
                      <ng-template #pdfTemplate>
                        <pdf-viewer
                          [src]="dataViewer.url"
                          [original-size]="false"
                          [show-all]="false"
                          [page]="dataViewer.page"
                          [fit-to-page]="false"
                          [zoom]="zoom$ | async"
                          [zoom-scale]="'page-width'"
                          [stick-to-page]="false"
                          [render-text]="true"
                          [external-link-target]="'blank'"
                          [autoresize]="true"
                          [show-borders]="false"
                          class="h-full w-full"
                          (after-load-complete)="onLoadedImg()"
                        ></pdf-viewer>
                      </ng-template>
                    </div>
                  </div>
                </ng-container>
                <ng-template #emptyImage>
                  <div class="grid h-auto gap-4">
                    <app-card>
                      <div app-card-content class="h-[40rem] w-full">
                        <app-jumbotron [showIcon]="false" translate>
                          <svg-icon class="mb-5 w-20 text-gray-300" src="assets/icons/icon-improvement.svg"></svg-icon>
                          gestor-avaliacoes.sem-imagem
                        </app-jumbotron>
                      </div>
                    </app-card>
                  </div>
                </ng-template>
              </app-card>
            </div>

            <!-- SEGUNDO CARD  -->
            <app-card class="p-5">
              <form [formGroup]="form">
                <app-form-field
                  *ngIf="!!aplication.embaralhar_questoes || !!aplication.embaralhar_alternativas"
                  class="w-full"
                  [required]="false"
                >
                  <ng-select
                    app-select
                    [clearable]="false"
                    [searchable]="false"
                    formControlName="modo_rand"
                    [placeholder]="'gestor-avaliacoes.selecione-modelo' | translate"
                  >
                    <ng-option [value]="1">Modelo 1</ng-option>
                    <ng-option [value]="2">Modelo 2</ng-option>
                    <ng-option [value]="3">Modelo 3</ng-option>
                    <ng-option [value]="4">Modelo 4</ng-option>
                  </ng-select>
                </app-form-field>
              </form>
              <div class="grid gap-5 overflow-y-auto rounded-lg border-2 p-5 shadow-inner-bg md:max-h-[73vh]">
                <ng-container
                  *ngIf="{
                    questions: onGetQuestionsWithAnswers(),
                    loadingQuestion: loadingQuestionAlternative$ | async
                  } as data"
                >
                  <ng-container
                    *ngIf="
                      hasQuestions && (!!data.questions.problems?.length || !!data.questions.list?.length);
                      else empty
                    "
                  >
                    <button
                      *ngIf="questionAllblankdata$ | async"
                      app-outline-button
                      (click)="onQuestionsAllblank()"
                      [disabled]="loadingAllblankdata$ | async"
                      class="flex h-9 w-full justify-center gap-3 border-none bg-black/5 text-black/80 hover:bg-base-status-info hover:font-semibold hover:text-white"
                    >
                      <ng-container *ngIf="loadingAllblankdata$ | async; else iconAllblank">
                        <app-skeleton class="flex !h-4 !w-4" [showLabel]="false"></app-skeleton>
                      </ng-container>
                      <ng-template #iconAllblank>
                        <svg-icon
                          class="mr-2 w-5 text-base-status-info group-hover:text-white"
                          src="assets/icons/icon-comments-disable.svg"
                        ></svg-icon>
                      </ng-template>

                      <span class="group-hover:text-theme-contrast md:inline-block">
                        {{ 'gestor-avaliacoes.todas-em-branco' | translate }}
                      </span>
                    </button>

                    <ng-container *ngIf="!!data.questions.problems?.length">
                      <div class="flex items-center gap-2 rounded-lg border border-red-300 bg-red-50 px-5 py-2">
                        <svg-icon
                          class="flex w-[14px] text-red-500"
                          src="assets/icons/icon-notify-fine.svg"
                          alt="icon"
                        ></svg-icon>
                        <strong translate> gestor-avaliacoes.atencao </strong>
                        <h1 class="text-sm text-zinc-900" translate>
                          Existem questões com rasura e/ou mal preenchidas. Verifique!
                        </h1>
                      </div>
                      <div *ngFor="let question of data.questions.problems" [id]="'question_' + question.uuid">
                        <app-card
                          class="grid !flex-row items-center rounded-xl p-4 !shadow-black/25 hover:bg-base-mantis-100 md:flex md:w-full xl:w-full"
                        >
                          <div class="grid w-full gap-4">
                            <div class="flex justify-center gap-8">
                              <button
                                app-outline-button
                                #anullAnswer2
                                (click)="onOpenAnullQuestion(anullAnswer2, question)"
                                class="group flex h-9 w-full min-w-[140px] justify-center border-none bg-black/5 text-black/80 hover:bg-base-status-danger hover:font-semibold hover:text-white"
                              >
                                <svg-icon
                                  class="mr-2 w-5 text-base-status-danger group-hover:text-white"
                                  src="assets/icons/icon-block.svg"
                                ></svg-icon>
                                <span class="group-hover:text-theme-contrast md:inline-block" translate> Anular </span>
                              </button>

                              <button
                                app-outline-button
                                *ngIf="question.tipo === 0"
                                (click)="onCleanAlternative(question.uuid)"
                                class="group flex h-9 w-full min-w-[140px] justify-center border-none bg-black/5 text-black/80 hover:bg-base-status-info hover:font-semibold hover:text-white"
                              >
                                <svg-icon
                                  class="mr-2 w-5 text-base-status-info group-hover:text-white"
                                  src="assets/icons/icon-comments-disable.svg"
                                ></svg-icon>
                                <span class="group-hover:text-theme-contrast md:inline-block" translate>
                                  Em branco
                                </span>
                              </button>
                            </div>
                            <div class="flex justify-between">
                              <div class="flex w-full">
                                <span class="flex w-full items-center gap-2 text-md font-semibold">
                                  {{ 'gestor-avaliacoes.questao' | translate }} {{ question.index }}
                                  <div
                                    *ngIf="data.loadingQuestion === question.uuid"
                                    class="ml-2 flex h-6 w-6 items-center justify-center"
                                  >
                                    <app-skeleton class="flex h-4 w-4" [showLabel]="false"></app-skeleton>
                                  </div>
                                  <svg-icon
                                    class="mr-2 flex h-3 text-danger"
                                    src="assets/icons/icon-alert-fill.svg"
                                  ></svg-icon>
                                </span>
                              </div>

                              <div class="flex w-full justify-end pt-5 md:pt-0">
                                <ng-container *ngIf="question?.tipo === assessmentQuestionTypeEnum.Objetiva">
                                  <div class="flex w-full gap-2 md:justify-end">
                                    <span
                                      *ngFor="let alternative of question.alternativas; let i = index"
                                      (click)="onChangeAlternativa(question.uuid, alternative.uuid)"
                                      class="flex cursor-pointer items-center rounded-lg border px-2 py-0 text-[1.2rem] font-medium hover:border-base-mantis-400 hover:text-base-mantis-400"
                                      [ngClass]="{
                                        'border-base-mantis-500 text-base-mantis-500': onCorrectOrSociodemographic(
                                          alternative,
                                          question
                                        ),
                                        'text-base-gray-300': onIncorrectAndNotSociodemographic(alternative, question),
                                        'border-red-500 text-red-500 hover:border-red-700 hover:!text-red-700':
                                          onSelectedIncorrectAndNotSociodemographic(alternative, question),
                                        'bg-white shadow-sm shadow-black/40': shouldApplyWhiteBackground(
                                          alternative,
                                          question
                                        )
                                      }"
                                    >
                                      <svg-icon
                                        *ngIf="!!alternative.alternativa_correta"
                                        class="mr-2 flex h-3"
                                        src="assets/icons/icon-check.svg"
                                      ></svg-icon>
                                      <svg-icon
                                        *ngIf="
                                          alternative.uuid === question.answer?.alternativa?.uuid &&
                                          !alternative.alternativa_correta &&
                                          question?.sociodemografica !== 1
                                        "
                                        class="mr-2 flex h-3"
                                        src="assets/icons/icon-delete.svg"
                                      ></svg-icon>

                                      {{ toLetter(i) }}
                                    </span>
                                  </div>
                                </ng-container>
                              </div>
                            </div>
                          </div>
                        </app-card>
                      </div>
                      <hr app-divider />
                    </ng-container>

                    <div
                      *ngFor="let question of data.questions.list; trackBy: trackByUuid"
                      [id]="'question_' + question.uuid"
                    >
                      <app-card
                        class="grid flex-col gap-4 rounded-xl border p-4 !shadow-black/25 hover:bg-base-mantis-100 md:flex md:w-full xl:w-full"
                        [ngClass]="{
                          'border-base-status-danger-400': !!question.answer?.anulado,
                          'border-base-status-info-600': !!question.answer?.em_branco,
                          'border-base-status-warning-600':
                            !question.answer?.em_branco && !question.answer?.anulado && !!question.answer?.alterado_por
                        }"
                      >
                        <div class="flex w-full justify-between">
                          <div class="flex w-full">
                            <span class="mr-4 flex w-full items-center gap-2 whitespace-nowrap text-md font-semibold">
                              {{ 'gestor-avaliacoes.questao' | translate }} {{ question.index }}
                              <div
                                *ngIf="data.loadingQuestion === question.uuid"
                                class="ml-2 flex h-6 w-6 items-center justify-center"
                              >
                                <app-skeleton class="flex h-4 w-4" [showLabel]="false"></app-skeleton>
                              </div>
                            </span>
                          </div>

                          <div class="flex w-full items-center justify-end gap-4 pt-5 md:pt-0">
                            <ng-container
                              *ngIf="question?.tipo === assessmentQuestionTypeEnum.Objetiva; else discursivaTemplate"
                            >
                              <div class="flex w-full gap-2 md:justify-end">
                                <span
                                  *ngFor="let alternative of question.alternativas; let i = index"
                                  (click)="onChangeAlternativa(question.uuid, alternative.uuid)"
                                  class="flex w-16 cursor-pointer items-center justify-center rounded-lg border px-2 py-0 text-[1.2rem] font-medium hover:border-base-mantis-400 hover:text-base-mantis-400"
                                  [ngClass]="{
                                    'border-base-mantis-500 text-base-mantis-500': onCorrectOrSociodemographic(
                                      alternative,
                                      question
                                    ),
                                    'text-base-gray-300': onIncorrectAndNotSociodemographic(alternative, question),
                                    'border-red-500 text-red-500 hover:border-red-700 hover:!text-red-700':
                                      onSelectedIncorrectAndNotSociodemographic(alternative, question),
                                    'bg-white shadow-sm shadow-black/40': shouldApplyWhiteBackground(
                                      alternative,
                                      question
                                    )
                                  }"
                                >
                                  <svg-icon
                                    *ngIf="!!alternative.alternativa_correta"
                                    class="mr-2 flex h-3"
                                    src="assets/icons/icon-check.svg"
                                  ></svg-icon>
                                  <svg-icon
                                    *ngIf="
                                      alternative.uuid === question.answer?.alternativa?.uuid &&
                                      !alternative.alternativa_correta &&
                                      question?.sociodemografica !== 1
                                    "
                                    class="mr-2 flex h-3"
                                    src="assets/icons/icon-delete.svg"
                                  ></svg-icon>

                                  {{ toLetter(i) }}
                                </span>
                              </div>
                            </ng-container>
                            <ng-template #discursivaTemplate>
                              <div class="flex items-center">
                                <ng-container *ngFor="let scale of scales">
                                  <ng-container *ngIf="onCalcNota(question.peso, scale.value) as result">
                                    <div
                                      class="flex !items-center bg-gray-200 p-1 first:rounded-l-lg last:rounded-r-lg"
                                    >
                                      <button
                                        [ngClass]="{
                                          'shadow-inner shadow-black/40': result?.nota === question.answer?.nota
                                        }"
                                        (click)="onSaveNota(question.uuid, result?.nota)"
                                        [id]="'btn_' + scale.shortcut"
                                        class="flex p-1 px-3 transition-all duration-300 hover:bg-white/80 md:px-5"
                                      >
                                        <span
                                          *ngIf="
                                            aplication.avaliacao.peso_tipo === assessmentWeightType.Peso;
                                            else percent
                                          "
                                        >
                                          {{ result.nota }}
                                        </span>
                                        <ng-template #percent>
                                          {{ scale.value | percent }}
                                        </ng-template>
                                      </button>
                                    </div>
                                  </ng-container>
                                </ng-container>
                              </div>
                            </ng-template>
                            <app-badge-annuled-question
                              *ngIf="!!question.answer?.anulado; else optionsTemplate"
                              [cancelAnnulment]="true"
                              (updateQuestion)="onCancelAnull($event, question.uuid)"
                              [applicationUuid]="aplication.uuid"
                              [questionUuid]="question.uuid"
                              [tentativaUuid]="(selectedUser$ | async)?.data?.tentativa_uuid"
                            ></app-badge-annuled-question>
                            <ng-template #optionsTemplate>
                              <button
                                #popoverTop
                                app-outline-button
                                type="button"
                                class="top-[-1.6rem] !border-none bg-black/5 p-2 text-black md:top-[0rem] hover:[&:not([disabled])]:bg-black hover:[&:not([disabled])]:text-theme-contrast"
                              >
                                <svg-icon
                                  (click)="onOpenPopover(popoverTop, question)"
                                  class="flex h-5"
                                  src="assets/icons/icon-three-dots.svg"
                                ></svg-icon>
                              </button>
                            </ng-template>
                          </div>
                        </div>
                        <div
                          class="border-t pt-4"
                          *ngIf="
                            !!question.answer?.anulado ||
                            !!question.answer?.em_branco ||
                            !!question.answer?.alterado_por
                          "
                        >
                          <p
                            class="mb-0 text-base"
                            [ngClass]="{
                              'text-danger': !!question.answer?.anulado,
                              'text-info': !!question.answer?.em_branco,
                              'text-warning':
                                !question.answer?.em_branco &&
                                !question.answer?.anulado &&
                                !!question.answer?.alterado_por
                            }"
                          >
                            <ng-container *ngIf="!!question.answer?.alterado_por?.nome">
                              {{ 'gestor-avaliacoes.auditado-por' | translate }}
                              {{ question.answer?.alterado_por?.nome }}:
                              {{ question.answer?.updated_at | date : 'dd/MM HH:mm' }}
                            </ng-container>
                            <ng-container *ngIf="!question.answer?.alterado_por?.nome">
                              {{ 'gestor-avaliacoes.auditado-em' | translate }}:
                              {{ question.answer?.updated_at | date : 'dd/MM HH:mm' }}
                            </ng-container>
                          </p>
                        </div>
                      </app-card>
                    </div>
                  </ng-container>
                </ng-container>
              </div>
              <ng-template #empty>
                <div class="grid h-[200px] gap-4">
                  <app-skeleton *ngIf="loadingQuestions$ | async; else jumbotron"></app-skeleton>
                  <ng-template #jumbotron>
                    <app-jumbotron
                      *ngIf="
                        hasQuestions && (!!aplication.embaralhar_questoes || !!aplication.embaralhar_alternativas);
                        else jumbotrons
                      "
                      [showIcon]="false"
                      translate
                    >
                      <svg-icon
                        class="mb-5 w-10 text-gray-300"
                        src="assets/icons/icon-info-rounded.svg"
                        alt="Materiais Didaticos"
                      >
                      </svg-icon>
                      gestor-avaliacoes.selecione-modelo
                    </app-jumbotron>
                    <ng-template #jumbotrons>
                      <div class="grid h-auto gap-4">
                        <app-card>
                          <div app-card-content class="h-[200px]">
                            <app-jumbotron translate> geral.filtro-vazio </app-jumbotron>
                          </div>
                        </app-card>
                      </div>
                    </ng-template>
                  </ng-template>
                </div>
              </ng-template>
            </app-card>
          </div>
        </ng-template>
      </ng-container>
    </app-sidenav-group>
  </ng-container>
</div>

<ng-template #emptyTemplateGeneral>
  <div app-modal-content class="h-[90vh] w-[98vw] !p-0">
    <app-skeleton></app-skeleton>
  </div>
</ng-template>

<ng-template #statusTemplate let-status="status" let-aplication="aplication" let-usuario_id="usuario_id">
  <div *ngIf="!usuario_id && status !== assessmentStatusAttemptEnum.ResponseCardInvalid; else statusLabelTemplate">
    <span class="w-full rounded-full bg-red-500 px-9 text-white md:px-2" translate
      >gestor-avaliacoes.status.nao-atribuido</span
    >
  </div>
  <ng-template #statusLabelTemplate>
    <div [ngSwitch]="status">
      <span *ngSwitchCase="null" class="w-full rounded-full bg-gray-200 px-9 md:px-2" translate
        >gestor-avaliacoes.status.sem-respostas
      </span>
      <span
        *ngSwitchCase="assessmentStatusAttemptEnum.InProgress"
        class="w-full rounded-full bg-base-status-info px-9 text-white md:px-2"
      >
        {{ 'gestor-avaliacoes.status.em-andamento' | translate }}
      </span>
      <span
        *ngSwitchCase="assessmentStatusAttemptEnum.Review"
        class="w-full rounded-full bg-red-500 px-9 text-white md:px-2"
      >
        {{ 'gestor-avaliacoes.status.revisar' | translate }}
      </span>
      <span
        *ngSwitchCase="assessmentStatusAttemptEnum.ModelUndefined"
        class="w-full rounded-full bg-red-500 px-9 text-white md:px-2"
      >
        {{ 'gestor-avaliacoes.status.modelo-nao-identificado' | translate }}
      </span>
      <span
        *ngSwitchCase="assessmentStatusAttemptEnum.ResponseCardInvalid"
        class="w-full rounded-full bg-red-500 px-9 text-white md:px-2"
      >
        {{ 'gestor-avaliacoes.status.cartao-invalido' | translate }}
      </span>
      <span
        *ngSwitchCase="assessmentStatusAttemptEnum.Wait"
        class="w-full rounded-full bg-base-status-warning px-9 text-white md:px-2"
      >
        {{ 'gestor-avaliacoes.status.aguardando-correcao' | translate }}
      </span>
      <span
        *ngSwitchCase="assessmentStatusAttemptEnum.Finish || assessmentStatusAttemptEnum.WaitDateResult"
        class="w-full rounded-full bg-base-mantis-500 px-9 text-white md:px-2"
      >
        {{ 'gestor-avaliacoes.finalizada' | translate }}
      </span>
    </div>
  </ng-template>
</ng-template>
