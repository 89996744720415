import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-skeleton',
  templateUrl: './skeleton.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { class: 'w-full h-full' },
})
export class SkeletonComponent {
  @Input() public showLabel = true;
  @Input() public baseStyle = 'bg-base-gray-light-400';
}
