<div
  app-modal-content
  *ngIf="{ value: application$ | async } as application"
  class="h-[70vh] w-[96vw] !p-0 md:h-[92vh] md:w-[97vw]"
>
  <app-sidenav-group>
    <ng-template
      app-sidenav-start
      [componentRef]="formInstructionsReportComponentRef"
      [data]="{ application: application.value }"
      [opened]="true"
      class="rounded-bl-lg"
    >
    </ng-template>
    <ng-template app-sidenav-outer>
      <div class="h-full overflow-y-auto p-5">
        <app-tab-group>
          <!-- ENTIDADE -->
          <app-tab *ngIf="isNotEntity$ | async" class="flex w-full">
            <button
              app-tab-header
              class="!bg-white text-black group-[.active]:!bg-base-mantis-500 group-[.active]:!text-white"
              type="button"
              translate
            >
              gestor-avaliacoes.por-unidade
            </button>
            <ng-template app-tab-content>
              <app-tab-organization
                [application]="application.value"
                (activateStudentTab)="activateStudentTab($event)"
                class="w-full"
              ></app-tab-organization>
            </ng-template>
          </app-tab>

          <!-- TABELA POR ALUNO  -->
          <app-tab [active]="entityId">
            <button
              app-tab-header
              class="!bg-white text-black group-[.active]:!bg-base-mantis-500 group-[.active]:!text-white"
              type="button"
              translate
            >
              gestor-avaliacoes.por-aluno
            </button>
            <ng-template app-tab-content>
              <app-tab-student class="w-full" [application]="application.value" [entityId]="entityId"></app-tab-student>
            </ng-template>
          </app-tab>

          <!-- TABELA POR QUESTÃO -->
          <app-tab *ngIf="!!onPreviewer(application.value)">
            <button
              app-tab-header
              class="!bg-white text-black group-[.active]:!bg-base-mantis-500 group-[.active]:!text-white"
              type="button"
              translate
            >
              gestor-avaliacoes.por-questao
            </button>
            <ng-template app-tab-content>
              <app-tab-questions class="w-full" [application]="application.value"></app-tab-questions>
            </ng-template>
          </app-tab>

          <!-- MAPA DA PROVA -->
          <app-tab *ngIf="!!onPreviewer(application.value)">
            <button
              app-tab-header
              class="!bg-white text-black group-[.active]:!bg-base-mantis-500 group-[.active]:!text-white"
              type="button"
              translate
            >
              gestor-avaliacoes.mapa-avaliacao
            </button>
            <ng-template app-tab-content>
              <app-tab-questions-map [application]="application.value" class="w-full"></app-tab-questions-map>
            </ng-template>
          </app-tab>

          <!-- TABELA NOTAS  -->
          <app-tab [active]="entityId">
            <button
              app-tab-header
              class="!bg-white text-black group-[.active]:!bg-base-mantis-500 group-[.active]:!text-white"
              type="button"
              translate
            >
              gestor-avaliacoes.notas
            </button>
            <ng-template app-tab-content>
              <app-tab-note [application]="application.value" [entityId]="entityId" class="w-full"></app-tab-note>
            </ng-template>
          </app-tab>

          <!-- TABELA HABILIDADE/DISCIPLINA -->
          <app-tab *ngIf="application.value.avaliacao.tipo_gerenciada">
            <button
              app-tab-header
              class="!bg-white text-black group-[.active]:!bg-base-mantis-500 group-[.active]:!text-white"
              type="button"
              translate
            >
              gestor-avaliacoes.habilidade-disciplina
            </button>
            <ng-template app-tab-content>
              <app-tab-abilities-disciplines
                [application]="application.value"
                class="w-full"
              ></app-tab-abilities-disciplines>
            </ng-template>
          </app-tab>
        </app-tab-group>
      </div>
    </ng-template>
  </app-sidenav-group>
  <ng-template #empty> - </ng-template>
</div>
