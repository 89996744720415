import { Injectable } from '@angular/core';
import { portalConst } from '@app/config';
import { Observable } from 'rxjs';
import { ApiService, Resp } from './api.service';
import { CrudService } from './crud.service';

@Injectable({
  providedIn: 'root',
})
export class AssessmentAttemptService extends CrudService<any> {
  private _baseUrl = `${portalConst.api.assessments}/v1/aplicacao`;
  constructor(private _api: ApiService) {
    super(_api, `${portalConst.api.assessments}/v1/aplicacao/tentativa`);
  }

  public start(aplicacaoUuid: string): Observable<Resp<any>> {
    return this._api.post(`${this._baseUrl}/${aplicacaoUuid}/tentativa/start`);
  }

  public finalize(tentativaUuid: string, body: any): Observable<Resp<any>> {
    return this._api.post(`${this._baseUrl}/tentativa/${tentativaUuid}/finalize`, body);
  }

  public question(questionUuid: string, params: any): Observable<Resp<any>> {
    return this._api.post(`${this._baseUrl}/tentativa/questao/${questionUuid}/resposta`, params);
  }
}
