import { Overlay, OverlayRef, PositionStrategy, ScrollStrategy } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Directive, ElementRef, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { Strategy } from '../popover/strategy';
import { TooltipComponent } from './tooltip.component';

@Directive({
  selector: '[app-tooltip]',
})
export class TooltipDirective implements OnInit, OnDestroy {
  @Input('tooltip') public text: string;
  private _overlayRef: OverlayRef;

  constructor(private overlay: Overlay, private elementRef: ElementRef) {}

  @HostListener('mouseenter')
  public show() {
    if (!this.text) {
      return;
    }
    const portal = new ComponentPortal(TooltipComponent);
    this._overlayRef?.detach();
    this._overlayRef.attach(portal).instance.text = this.text;
  }

  @HostListener('mouseleave', ['$event.toElement'])
  public hide() {
    this._overlayRef.detach();
  }

  public ngOnInit() {
    this._overlayRef = this.overlay.create({
      positionStrategy: this.positionStrategy(),
      scrollStrategy: this.scrollStrategy(),
      hasBackdrop: false,
    });
  }

  public ngOnDestroy() {
    this._overlayRef?.dispose();
  }

  private positionStrategy(): PositionStrategy {
    const positions = [Strategy.BOTTOM, Strategy.TOP];
    return this.overlay.position().flexibleConnectedTo(this.elementRef).withPositions(positions).withPush(false);
  }

  private scrollStrategy(): ScrollStrategy {
    return this.overlay.scrollStrategies.reposition();
  }
}
