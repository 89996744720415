import { HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppState } from '@app/store/app.state';
import { Store } from '@ngrx/store';
import { catchError, throwError } from 'rxjs';
import { Login } from '../guards';

const STATUS_UNAUTHORIZED = 401;

@Injectable({ providedIn: 'root' })
export class AuthExpiredInterceptor implements HttpInterceptor {
  constructor(private store: Store<AppState>) {}

  public intercept(req: HttpRequest<any>, next: HttpHandler) {
    return next.handle(req).pipe(
      catchError((error) => {
        if (error instanceof HttpErrorResponse) {
          if (error.status === STATUS_UNAUTHORIZED) {
            Login.navigateToLogin(this.store);
          }
        }
        return throwError(() => error);
      }),
    );
  }
}
