import { Directive, HostBinding, Input, Optional, TemplateRef } from '@angular/core';
import { TAB_STYLE } from './tab.interfaces';

@Directive({
  selector: '[app-tab-header]',
})
export class TabHeaderDirective {
  @Input() public tabStyle = TAB_STYLE.Dense;

  constructor(@Optional() public templateRef: TemplateRef<any>) {}

  @HostBinding('class') public get styles() {
    return this.tabStyle?.header;
  }
}
