import { NgModule } from '@angular/core';
import { BadgeModule } from '../badge/badge.module';
import { TemplateModule } from '../template/template.module';
import { FilterBadgeComponent } from './filter-badge.component';

@NgModule({
  declarations: [FilterBadgeComponent],
  imports: [TemplateModule, BadgeModule],
  exports: [FilterBadgeComponent],
})
export class FilterBadgeModule {}
