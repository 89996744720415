import { AppState } from '@app/store/app.state';

export enum Services {
  ChaveEnemI = 4,
  SequenciaDidatica = 5,
  EnemInterativo = 6,
  Drive = 7,
  SalaProfessores = 8,
  EletivaFaama = 9,
  BibliotecaVirtual = 11,
  SuperPro = 12,
  CpbProva = 13,
  ChaveEnemII = 14,
  ConteudoPedagogico = 15,
  ConteudoSiteEscola = 16,
  ArquivoSiteEscola = 17,
  ChaveEnemIII = 22,
  Canva = 25,
  Office365 = 26,
  PMDE = 27,
  Redacao = 32,
  SkyEnglish = 34,
}

export interface Link {
  key: Services;
  popover: string;
  icon: string;
  link: any;
  style: any;
  show?: (store: AppState) => boolean;
}
