import { Directive, OnDestroy, Optional, TemplateRef } from '@angular/core';
import { LayoutTemplateService } from '@app/core/services';

@Directive({
  selector: '[app-layout-template]',
})
export class LayoutTemplateDirective implements OnDestroy {
  constructor(@Optional() templateRef: TemplateRef<any>, private layoutTemplateService: LayoutTemplateService) {
    this.layoutTemplateService.set(templateRef);
  }

  public ngOnDestroy() {
    this.layoutTemplateService.clear();
  }
}
