import { Injectable } from '@angular/core';
import { portalConst } from '@app/config';
import { Event, EventsResponse } from '@app/core/models/events.model';
import { ApiService } from '@app/core/services/api.service';
import { CrudService } from '@app/core/services/crud.service';
import { Observable } from 'rxjs';
import { TrailsService } from './trails.service';
@Injectable({
  providedIn: 'root',
})
export class EventsService extends CrudService<EventsResponse> {
  constructor(api: ApiService, private trailsService: TrailsService) {
    super(api, `${portalConst.api.trilhas}/`);
  }

  public listEvents(entity: any, courseId: number, pageSize: number, migrate?: boolean): Observable<any> {
    const date = new Date();
    let paramUcIvca = {};
    if (this.trailsService.uc$.value) paramUcIvca = { uc: true };
    if (this.trailsService.ivca$.value) paramUcIvca = { ivca: true };

    const params = {
      entidade: entity,
      data_inicio: `${date.getUTCFullYear()}-01-01`,
      curso_id: courseId,
      migrate,
      pageSize,
      ...paramUcIvca,
    };

    return this.api.get<EventsResponse>(`${this.url}v1/evento`, params);
  }

  public showEvent(eventId: number, migrate?: boolean) {
    return this.api.get<Event>(`${this.url}v1/evento/${eventId}`, { migrate });
  }

  public postEvent(data: any) {
    return this.api.post<Event>(`${this.url}v1/evento`, data);
  }

  public updateEvent(eventId: number, data: any) {
    return this.api.put<Event>(`${this.url}v1/evento/${eventId}`, data);
  }

  public destroyEvent(eventId: number) {
    return this.api.delete<Event>(`${this.url}v1/evento/${eventId}`);
  }
}
