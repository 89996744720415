import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { AssessmentWeightType, Entidade } from '@app/core/models';
import { ReportApiRoutesEnum } from '@app/core/models/report-api-routes.enum';
import { TipoEntidadeEnum } from '@app/core/models/tipo-entidade.enum';
import { AssessmentApplicationService } from '@app/core/services/assessment-application.service';
import { notNull, selectedEntityActive } from '@app/shared';
import { AppSelectors } from '@app/store';
import { AppState } from '@app/store/app.state';
import { Store } from '@ngrx/store';
import { first, isEqual, uniqBy } from 'lodash';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, finalize, map, switchMap } from 'rxjs/operators';

interface StudentNotes {
  nome: string;
  turma: string;
  turma_id: number;
  ra: string;
  serie: string;
  serie_id: number;
  entidade_id: string;
  disciplinas: {
    [disciplina: string]: {
      nome: string;
      notas: number[];
      qtd_questoes: number;
      qtd_acertos: number;
      percentual: number;
      qtd_questoes_geral: number;
    };
  };
  nota_calculada: number;
  peso_tipo: AssessmentWeightType;
  percentual_geral: number;
  total_acertos: number;
  total_questoes: number;
}

@Component({
  selector: 'app-tab-note',
  templateUrl: './tab-note.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabNoteComponent implements OnInit {
  public notesGrouped$: Observable<StudentNotes[]>;
  public entityActive: Entidade;
  public uniqueDisciplines: any[] = [];
  public singleClasses: string[] = [];
  public entityIdCtrl = new FormControl(undefined);
  public entities$ = new BehaviorSubject<any>([]);
  public searchTerm$ = new BehaviorSubject<string>('');
  public searchTurmaCtrl = new FormControl(null);
  public loading$ = new BehaviorSubject<boolean>(true);
  public assessmentWeightType = AssessmentWeightType;
  public switchNotes = new FormControl(true);
  private _activeUser$ = new BehaviorSubject<any>(undefined);
  private application$ = new BehaviorSubject<string | null>(null);
  private originalStudents: StudentNotes[] = [];
  private filteredStudents$ = new BehaviorSubject<StudentNotes[]>([]);

  constructor(
    private _store: Store<AppState>,
    private _assessmentService: AssessmentApplicationService,
    private _assessmentApplicationService: AssessmentApplicationService,
  ) {
    this.application$
      .pipe(
        switchMap((applicationUuid) => {
          if (!applicationUuid || !this.entityIdCtrl.value) {
            this.loading$.next(false);
            this.filteredStudents$.next([]);
            return of([]);
          }

          this.loading$.next(true);

          return this._assessmentService.getGradesByDiscipline(applicationUuid, this.entityIdCtrl.value).pipe(
            map((response) => {
              const notas = response?.data || [];
              const groupedNotes = this.groupNotesByStudent(notas);

              this.originalStudents = groupedNotes;
              this.filteredStudents$.next(groupedNotes);

              this.uniqueDisciplines = Array.from(
                new Map(
                  groupedNotes
                    .flatMap((s) => Object.values(s.disciplinas))
                    .map((disciplina) => [disciplina.nome, disciplina]),
                ).values(),
              );
              this.singleClasses = Array.from(
                new Set(groupedNotes.map((s) => `${s.serie} - ${s.turma}`).filter(Boolean)),
              ).sort();
              if (this.singleClasses.length > 0) {
                this.searchTurmaCtrl.setValue(this.singleClasses[0], { emitEvent: false });
                this.applyFilters();
              }

              return groupedNotes;
            }),
            catchError(() => of([])),
            finalize(() => this.loading$.next(false)),
          );
        }),
      )
      .subscribe();

    this.entityIdCtrl.valueChanges
      .pipe(
        debounceTime(100),
        switchMap((entidadeId) => {
          if (!this.application$.value || !entidadeId) {
            this.filteredStudents$.next([]);
            return of([]);
          }

          this.loading$.next(true);

          return this._assessmentService.getGradesByDiscipline(this.application$.value, entidadeId).pipe(
            map((response) => {
              const notas = response?.data || [];
              const groupedNotes = this.groupNotesByStudent(notas);

              this.originalStudents = groupedNotes;
              this.applyFilters();

              this.uniqueDisciplines = Array.from(
                new Map(
                  groupedNotes
                    .flatMap((s) => Object.values(s.disciplinas))
                    .map((disciplina) => [disciplina.nome, disciplina]),
                ).values(),
              );

              this.singleClasses = Array.from(
                new Set(groupedNotes.map((s) => `${s.serie} - ${s.turma}`).filter(Boolean)),
              );

              return groupedNotes;
            }),
            catchError(() => of([])),
            finalize(() => this.loading$.next(false)),
          );
        }),
      )
      .subscribe();

    this.switchNotes.valueChanges.subscribe((value) => value);
    this._store
      .select(AppSelectors.ActiveUser)
      .pipe(notNull(), distinctUntilChanged(isEqual))
      .subscribe((activeUser) => {
        this._activeUser$.next(activeUser);
        this.entityActive = first(selectedEntityActive(activeUser));
        if (this.entityActive.tipo === TipoEntidadeEnum.Escola) {
          this.entityIdCtrl.setValue(this.entityActive.id);
        }
      });
  }

  @Input()
  public set application(value: any) {
    if (value?.uuid) {
      this.application$.next(value.uuid);
      this.entities$.next(
        uniqBy(
          value.publico_alvo.map((target) => target.entidade),
          'id',
        ),
      );
    }
    if (this.entities$.value.length === 1) {
      this.entityIdCtrl.setValue((first(this.entities$.value) as any).id);
    }
  }

  @Input() public set entityId(value) {
    this.entityIdCtrl.setValue(value);
  }

  public getDisciplina(disciplinas: any[], nome: string) {
    return disciplinas?.find((d) => d.nome === nome);
  }

  public ngOnInit() {
    this.notesGrouped$ = this.filteredStudents$.asObservable();

    this.searchTurmaCtrl.valueChanges.pipe(debounceTime(100)).subscribe(() => this.applyFilters());

    this.searchTerm$.pipe(debounceTime(100)).subscribe(() => this.applyFilters());
  }

  public onSearchChange(search: string): void {
    this.searchTerm$.next(search.toLowerCase().trim());
  }

  public onExport() {
    let groupId: number | null = null;
    let serieId: number | null = null;

    if (!!this.searchTurmaCtrl.value) {
      const selectedStudent = this.originalStudents.find(
        (s) => `${s.serie} - ${s.turma}` === this.searchTurmaCtrl.value,
      );

      if (selectedStudent) {
        groupId = selectedStudent.turma_id;
        serieId = selectedStudent.serie_id;
      }
    }

    const data = {
      turma_id: groupId ?? null,
      serie_id: serieId ?? null,
      entity_id: this.entityActive.tipo === TipoEntidadeEnum.Escola ? this.entityActive.id : this.entityIdCtrl.value,
      specific_students: !!this.searchTurmaCtrl.value && !groupId ? 1 : 0,
      acertos: !!this.switchNotes.value ? 1 : 0,
      api_route: ReportApiRoutesEnum.ReportByGrades,
    };

    const applicationUuid = this.application$.getValue();
    if (applicationUuid) {
      this._assessmentApplicationService.reportExport(applicationUuid, data, 'by-grades');
    }
  }

  private applyFilters(): void {
    const selectedEntityId = this.entityIdCtrl.value;
    const searchTurma = this.searchTurmaCtrl.value;
    const searchTerm = this.searchTerm$.getValue();

    let filteredData = [...this.originalStudents];

    if (selectedEntityId) {
      filteredData = filteredData.filter((student) =>
        student.entidade_id ? student.entidade_id === selectedEntityId : false,
      );
    }

    if (searchTurma) {
      filteredData = filteredData.filter((student) => `${student.serie} - ${student.turma}` === searchTurma);
    }

    if (searchTerm) {
      filteredData = filteredData.filter((student) => student.nome.toLowerCase().includes(searchTerm));
    }

    this.filteredStudents$.next(filteredData);
  }

  private groupNotesByStudent(data: any[]): StudentNotes[] {
    const students: any[] = data['alunos'];
    return students;
  }
}
