import { DialogRef } from '@angular/cdk/dialog';
import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Renderer2, ViewChild } from '@angular/core';
import { UploadService } from '@app/core/services';
import { MathfieldElement } from 'mathlive';
import { TIPS } from './tips';
import { BehaviorSubject, finalize } from 'rxjs';

@Component({
  templateUrl: './modal-math-formula.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalMathFormulaComponent implements AfterViewInit {
  public readonly tips = TIPS;
  public mathfield: MathfieldElement;
  public submitted$ = new BehaviorSubject<boolean>(false);

  @ViewChild('mathField', { static: true })
  public mathFieldElement: ElementRef<HTMLDivElement>;

  constructor(private renderer: Renderer2, private _uploadService: UploadService, private dialogRef: DialogRef) {}

  public ngAfterViewInit() {
    this.mathfield = new MathfieldElement();
    this.mathfield.value = '';
    MathfieldElement.keypressSound = null;
    MathfieldElement.plonkSound = null;
    this.renderer.appendChild(this.mathFieldElement.nativeElement, this.mathfield);
    this.onFocus();
  }

  public onSubmit() {
    if (!!this.mathfield.value) {
      if (!this.submitted$.value) {
        this.submitted$.next(true);
        this._uploadService
          .latexToSvg({ latex: this.mathfield.value })
          .pipe(finalize(() => this.submitted$.next(false)))
          .subscribe(({ url }) => this.dialogRef.close(url));
      }
    } else {
      this.dialogRef.close();
    }
  }

  public onTip(latex: string) {
    this.mathfield.value = `${this.mathfield.value} ${latex}`;
  }

  public onPrevent(event: MouseEvent) {
    event.stopPropagation();
  }

  private onFocus() {
    setTimeout(() => {
      const element = this.mathFieldElement.nativeElement.firstElementChild as HTMLElement;
      element.focus();
    }, 500);
  }
}
