import { Injectable } from '@angular/core';
import { Role } from '@app/shared';
import { AppSelectors } from '@app/store';
import { AppState } from '@app/store/app.state';
import { Store } from '@ngrx/store';
import { filter, map } from 'rxjs/operators';
import { MenuItem, MenuRef } from './menu';

@Injectable({ providedIn: 'root' })
export class EducationalMaterialsMenu implements MenuRef {
  public identifier = 'educationalMaterials';

  public readonly menu: MenuItem[] = [
    () => ({
      title: 'menu.materiais-didaticos',
      url: '/materiais-didaticos',
      icon: 'icon-library',
      permission: [1, 1],
      show: this.store.select(AppSelectors.appFeature).pipe(
        filter(({ usuario_ativo }) => !!usuario_ativo),
        map((store) => Role.educationalMaterials(store)),
      ),
    }),
  ];

  public readonly submenu: MenuItem[] = [
    () => ({
      title: 'breadcrumb.materiais-didaticos.titulo',
      url: '/materiais-didaticos',
      icon: 'icon-item-edit',
      group: 'menu.materiais-didaticos',
    }),
  ];

  constructor(private store: Store<AppState>) {}
}
