import { Injectable } from '@angular/core';
import { portalConst } from '@app/config';
import { ApiService } from './api.service';
import { CrudService } from './crud.service';

@Injectable({
  providedIn: 'root',
})
export class AppointmentTypeService extends CrudService<any> {
  constructor(api: ApiService) {
    super(api, `${portalConst.api.academic}/v1/occurrence-type`);
  }
}
