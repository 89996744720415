import { Directive, HostListener, Input } from '@angular/core';
import { NotificationService } from '@app/core/services';
import { TranslateService } from '@ngx-translate/core';
import { Clipboard } from '@angular/cdk/clipboard';

@Directive({
  selector: '[app-clipboard]',
})
export class ClipboardDirective {
  @Input({ required: true }) public clipboard: any;

  constructor(
    private _clipboard: Clipboard,
    private _translateService: TranslateService,
    private _notificationService: NotificationService,
  ) {}

  @HostListener('click') public click() {
    this._clipboard.copy(this.clipboard);
    const message = this._translateService.instant('geral.copiado');
    this._notificationService.success(message);
  }
}
