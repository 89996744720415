export interface PaymentParams {
  entidade: number;
  permite_pagar_online: boolean;
  permite_pix: boolean;
  permite_recorrencia_cartao: boolean;
}

export interface Payment {
  dataParcels: PaymentData[];
  scholarship: number;
}

export interface PaymentData {
  contPai: number;
  dados: Intallment;
}

export type StatusIntallment = 'PAGO' | 'A VENCER' | 'EM ATRASO';
export interface Intallment {
  pgc: number;
  pg_pix: number;
  registro: number;
  nosso_numero: number;
  tipo_carne: number;
  carne: number;
  parcela: number;
  banco: string;
  vencimento: Date;
  valor: number;
  pagamento: string;
  codigoBarras: string;
  valorPago: string;
  statusCode: number;
  statusText: StatusIntallment;
  statusClass: string;
  infoBoleto: BankBilling[];
  data_cobrador: string;
  cobrador: any;
  maxQtdParcelas: number;
  minValorParcelas: number;
}

export interface BankBilling {
  servico: string;
  valor: string;
  historico: string;
}
