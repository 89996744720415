import { Injectable } from '@angular/core';
import { portalConst } from '@app/config';
import { ApiService, Resp } from './api.service';
import { CrudService } from './crud.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AcademicAbsenceService extends CrudService<any> {
  private readonly _url = `${portalConst.api.academic}/v1/absence`;

  constructor(api: ApiService) {
    super(api, `${portalConst.api.academic}/v1/absence`);
  }

  public override destroy(data: any): Observable<Resp<any>> {
    return this.api.delete(`${this._url}/destroy`, data);
  }

  public storeBatch(data: any) {
    return this.api.post(`${this._url}/store-batch`, data);
  }

  public destroyBatch(scheduleId: number) {
    return this.api.delete(`${this._url}/${scheduleId}/destroy-batch`);
  }
}
