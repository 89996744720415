<ng-container *ngIf="{ items: data$ | async, loading: loading$ | async } as data">
  <ng-select
    app-select
    [placeholder]="placeholder | translate"
    [items]="data.items"
    [multiple]="multiple"
    [clearable]="clearable"
    [searchable]="searchable"
    [searchFn]="searchFn"
    [loading]="data.loading"
    (change)="onChange($event, data.items)"
    (focus)="onFocus()"
    [formControl]="ctrl"
    bindValue="id"
    bindLabel="descricao"
  >
    <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
      <span [ngOptionHighlight]="search">{{ item.descricao }}</span>
    </ng-template>
  </ng-select>
</ng-container>
