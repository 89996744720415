import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'date',
  pure: false,
})
export class DateTranslatePipe implements PipeTransform {
  constructor(private datePipe: DatePipe, private translateService: TranslateService) {}

  public transform(value: any, format?: string, timezone?: string, locale?: string): string | null {
    return this.datePipe.transform(value, format, timezone, locale || this.translateService.defaultLang);
  }
}
