import { Directive } from '@angular/core';

@Directive({
  selector: '[app-table-cell]',
  host: {
    class:
      'p-2 first:rounded-l-lg first:pl-3 last:rounded-r-lg last:pr-3 [&:not(th)]:bg-white [&:is(th)]:bg-theme-500 [&:is(th)]:text-theme-contrast',
  },
})
export class TableCellDirective {}
