import { Injectable } from '@angular/core';
import { portalConst } from '@app/config';

import { Observable } from 'rxjs';
import { Entidade, PaisEnum } from '../models';
import { ApiService, Resp } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class LevelEducationService {
  private readonly resource = `${portalConst.api.usuario}/v1/hub-educacional/niveis-modelo`;

  constructor(private api: ApiService) {}

  public index(country: PaisEnum, entity: Entidade): Observable<Resp<any[]>> {
    return this.api.get(`${this.resource}/${country}`, { entity });
  }
}
