import { addDays, isSaturday, isSunday, parse, startOfDay, subDays } from 'date-fns';
import { deburr, isDate, isNil, lowerCase, trim } from 'lodash';

const iso8601Full = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}/;
const iso8601Date = /^\d{4}-\d{2}-\d{2}/;

export function reduce(data: any[]): any {
  return data.reduce((acc, curr) => ({ ...acc, ...curr }), []);
}

export function someValue(obj: any = {}): boolean {
  return !!Object.values(obj).some((v) => !!v);
}

export function compareEquals(x: any, y: any): boolean {
  return sanitize(x) === sanitize(y);
}

export function compareLike(x: any, y: any): boolean {
  return sanitize(y).indexOf(sanitize(x)) !== -1;
}

export function sanitize(data: string): string {
  return lowerCase(deburr(trim(data)));
}

export function preventWeekend(day: Date) {
  if (isSunday(day)) {
    return addDays(day, 1);
  }
  if (isSaturday(day)) {
    return subDays(day, 1);
  }
  return day;
}

export function safeDate(date: any): Date | null {
  if (isNil(date)) {
    return null;
  }
  if (isDate(date)) {
    return date;
  }
  if (iso8601Full.test(date)) {
    return parse(date, 'yyyy-MM-dd HH:mm:ss', new Date());
  }
  if (iso8601Date.test(date)) {
    return startOfDay(parse(date, 'yyyy-MM-dd', new Date()));
  }
  return date;
}
