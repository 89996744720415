import { Action, createReducer, on } from '@ngrx/store';
import { cloneDeep, isEmpty, isNil } from 'lodash';
import * as ModalAvaliacaoGDAActions from './modal-new-assessment.actions';
import { ModalNewAssessmentState, initialState } from './modal-new-assessment.state';

const ModalNewAssessmentReducer = createReducer(
  initialState,
  on(ModalAvaliacaoGDAActions.DataLoadedReducer, (state, { data, isUpdate, hasChanges, extra }) => {
    const { avaliacao, submitted, ...aplicacao } = data || { avaliacao: {} };
    const { questoes, ...value } = avaliacao || { questoes: [] };
    const init = {
      disabled: !data?.uuid,
      aplicacao,
      avaliacao: value,
      isUpdate,
      hasChanges,
      questoes: questoes || [],
      submitted,
      extra,
    };

    return updateState(initialState, init);
  }),
  on(ModalAvaliacaoGDAActions.UpdateStateReducer, (state, { value }) => updateState(state, value)),
  on(ModalAvaliacaoGDAActions.ClearStageReducer, () => cloneDeep(initialState)),
);

export function reducer(state: ModalNewAssessmentState, action: Action) {
  return ModalNewAssessmentReducer(state, action);
}

export function updateState(state, value = {} as any) {
  const hasChanges = isNil(value?.hasChanges) ? true : value?.hasChanges;
  const submitted = value?.submitted || state?.submitted;

  const avaliacao = { ...(state?.avaliacao || {}), ...(value?.avaliacao || {}) };
  const aplicacao = { ...(state?.aplicacao || {}), ...(value?.aplicacao || {}) };
  const questoes = value?.questoes ? value?.questoes : state?.questoes;
  const extra = isEmpty(value?.extra) ? state?.extra || [] : value?.extra;
  return cloneDeep({ ...state, ...value, aplicacao, avaliacao, questoes, hasChanges, submitted, extra });
}
