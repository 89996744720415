import { Injectable } from '@angular/core';
import { portalConst } from '@app/config';
import { Observable, take } from 'rxjs';
import { ApiService } from './api.service';
import { HttpBackend, HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class EnrollmentService {
  constructor(private api: ApiService, private handler: HttpBackend) {}

  public schoolData(): Observable<any> {
    return this.api.post(`${portalConst.api.iatec_api}/remainder/dados-escola`);
  }

  public list(req: any): Observable<any> {
    return this.api.post(`${portalConst.api.iatec_api}/remainder/get-rematricula`, {}, req);
  }

  public saveIatec(data: any): Observable<any> {
    return this.api.post(`${portalConst.api.iatec_api}/remainder/insere-rematricula`, data);
  }

  public savePortal(data: any): Observable<any> {
    return this.api.post(`${portalConst.api.matricula}/v1/rematricula`, data);
  }

  public searchReenrollmentByStudent(aluno_id: number, req: any): Observable<any> {
    return this.api.get(`${portalConst.api.matricula}/v1/rematricula-busca/${aluno_id}`, req);
  }

  public sendMail(data: any): Observable<any> {
    return this.api.post(`${portalConst.api.matricula}/v1/rematricula-email`, data);
  }

  public urlTerm(id: number, req: any) {
    const params = this.api.resolveParams(req);
    return `${portalConst.api.matricula}/v1/rematricula/term/${id}?${params.toString()}`;
  }

  public leadACRM(req: any): Observable<any> {
    // const resource = `https://test-sistemas.usb.org.br/acrm-api-php/matricula-online/matricula.php` //-- api de testes
    const resource = `https://sistemas.usb.org.br/acrm-api-php/matricula-online/matricula.php`;
    const params = this.api.resolveParams(req);
    return new HttpClient(this.handler).post(resource, null, { params }).pipe(take(1));
  }
}
