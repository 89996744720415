<div class="flex h-screen flex-col overflow-hidden">
  <!-- Header -->
  <app-main-header
    [showToogle]="true"
    [toogleStatus]="(status$ | async)?.opened"
    (toogle)="onSidenavToogle($event)"
  ></app-main-header>
  <ng-container *ngIf="{ validation: validation$ | async } as guard">
    <ng-container *ngIf="!guard.validation; else requiredTemplate">
      <!-- Content -->
      <app-sidenav-group (attached)="onAttached($event)" class="w-screen">
        <ng-template app-sidenav-start [update]="status$ | async"> </ng-template>
        <ng-template app-sidenav-outer>
          <div
            class="no-scrollbar h-[calc(100vh_-_7rem)] overflow-hidden overflow-y-auto lg:h-[calc(100vh_-_3rem)] xl:overflow-hidden"
          >
            <router-outlet></router-outlet>
          </div>
        </ng-template>
      </app-sidenav-group>
    </ng-container>

    <ng-template #requiredTemplate>
      <div class="layout-content__module">
        <div class="grid h-auto gap-4 p-5 pt-14">
          <app-card>
            <div app-card-content class="h-72">
              <app-jumbotron [showIcon]="false">
                <div class="flex w-auto flex-col items-center">
                  <svg-icon src="assets/icons/icon-alert-fill-fine.svg" class="w-20 text-gray-400"></svg-icon>
                  <ng-container *ngFor="let validation of guard.validation | keyvalue">
                    <div class="text-xl font-semibold text-gray-400">
                      {{ label(validation.key) | translate : validation.value }}
                    </div>
                  </ng-container>
                </div>
              </app-jumbotron>
            </div>
          </app-card>
        </div>
      </div>
    </ng-template>
  </ng-container>
</div>
