import { Directive } from '@angular/core';

@Directive({
  selector: '[app-flat-button]',
  host: {
    class:
      'relative inline-flex cursor-pointer select-none items-center justify-center rounded-lg border-none p-2 shadow-md shadow-black/25 outline-none hover:brightness-110 active:brightness-85  disabled:cursor-not-allowed disabled:opacity-50 disabled:brightness-100',
  },
})
export class FlatButtonDirective {}
