<app-horizontal-scroll>
  <div class="flex flex-nowrap gap-2 px-7 py-4">
    <app-badge [badgeStyle]="badgeStyle" [active]="isAllActive" (click)="onToogleBatch()">
      {{ 'geral.todos' | translate }}
    </app-badge>
    <ng-container *ngFor="let badge of badges">
      <app-badge [badgeStyle]="badgeStyle" [active]="badge.active" (click)="onToogle(badge)">
        {{ badge.name }}
      </app-badge>
    </ng-container>
  </div>
</app-horizontal-scroll>
