import { Injectable } from '@angular/core';
import { Category } from '@app/core/models';
import { dropRight, last } from 'lodash';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

export interface BreadcrumbFolder {
  name: string;
  path: string;
}

@Injectable()
export class BreadcrumbFolderService {
  public navigate$: Observable<string>;
  public breadcrumb$: Observable<BreadcrumbFolder[]>;
  private _breadcrumb$ = new BehaviorSubject<BreadcrumbFolder[]>([]);
  private _navigate$ = new Subject<string>();

  constructor() {
    this.breadcrumb$ = this._breadcrumb$.asObservable();
    this.navigate$ = this._navigate$.asObservable();
  }

  public breadcrumb(data: Category) {
    this._breadcrumb$.next([{ name: data.name, path: data.path }]);
  }

  public append(breadcrumb: string) {
    const sanitize = last(breadcrumb.split('/'));
    this._breadcrumb$.next([...this._breadcrumb$.value, { name: sanitize, path: breadcrumb }]);
  }

  public navigate(url: string, index: number) {
    const breadcrumb = dropRight(this._breadcrumb$.value, this._breadcrumb$.value.length - index - 1);
    this._breadcrumb$.next(breadcrumb);
    this._navigate$.next(url);
  }
}
