import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  Output,
  forwardRef,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-switch',
  templateUrl: './switch.component.html',
  host: {
    class:
      'relative w-14 h-8 inline-block cursor-pointer rounded-2xl transition duration-300 before:absolute before:h-6 before:w-6 before:bg-white before:rounded-full before:transition before:duration-300 before:left-1 before:right-1 before:bottom-1',
  },
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SwitchComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SwitchComponent implements ControlValueAccessor, AfterViewInit {
  @Output() public changeStatus = new EventEmitter<boolean>();

  @Input() public colorTrue = '!bg-green-500';
  @Input() public colorFalse = '!bg-gray-300';
  @Input() public disable: boolean;
  private _value: boolean;
  private _disabled: boolean;

  @HostBinding('class') public get styles() {
    let styles = '';
    if (this._value) {
      styles += ` ${this.colorTrue} before:translate-x-6`;
    } else {
      styles += this.colorFalse;
    }
    if (this._disabled) {
      styles += ' !cursor-not-allowed opacity-60';
    }
    return styles;
  }

  public get value(): boolean {
    return this._value;
  }

  @Input() public set checked(value: boolean) {
    this.writeValue(value);
  }

  @HostListener('click', ['$event'])
  public onClick(e: PointerEvent) {
    if (!this._disabled) {
      this._value = !this._value;
      this.change(this._value);
      this.changeStatus.emit(this._value);
    }
    this.touched(true);
    e.stopPropagation();
  }

  public ngAfterViewInit(): void {
    if (this.disable) {
      this.setDisabledState(true);
    }
  }

  public writeValue(obj: any): void {
    this._value = coerceBooleanProperty(obj);
  }

  public registerOnChange(fn: any): void {
    this.change = fn;
  }

  public registerOnTouched(fn: any): void {
    this.touched = fn;
  }

  public setDisabledState?(isDisabled: boolean): void {
    this._disabled = coerceBooleanProperty(isDisabled);
  }

  private change = (_: any) => true;
  private touched = (_: any) => true;
}
