import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  InjectionToken,
  Input,
  Output,
  forwardRef,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';

export const RADIO_GROUP = new InjectionToken<RadioGroupComponent>('RadioGroup');

@Component({
  selector: 'app-radio-group',
  templateUrl: './radio-group.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RadioGroupComponent),
      multi: true,
    },
    { provide: RADIO_GROUP, useExisting: RadioGroupComponent },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RadioGroupComponent implements ControlValueAccessor {
  private static nextId = 0;
  @Output() public changeStatus = new EventEmitter<any>();
  @Input() public radioClass = '';
  public readonly name: string = `radio-group-${RadioGroupComponent.nextId++}`;

  private _value$ = new BehaviorSubject<any>(undefined);
  private _disabled: boolean;

  public get disabled(): boolean {
    return this._disabled;
  }

  public get value(): any {
    return this._value$.asObservable();
  }

  @Input()
  public set value(obj: any) {
    this._value$.next(obj);
  }

  public writeValue(obj: any): void {
    this._value$.next(obj);
  }

  public registerOnChange(fn: any): void {
    this.change = fn;
  }

  public registerOnTouched(fn: any): void {
    this.touched = fn;
  }

  public setDisabledState?(isDisabled: boolean): void {
    this._disabled = coerceBooleanProperty(isDisabled);
  }

  public send(value: any) {
    this.change(value);
    this.touched(true);
    this.changeStatus.emit(value);
  }

  private change = (_: any) => true;
  private touched = (_: any) => true;
}
