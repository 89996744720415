import { Injectable } from '@angular/core';
import { portalConst } from '@app/config';
import { ApiService, Resp } from './api.service';
import { CrudService } from './crud.service';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AttachmentService extends CrudService<any> {
  constructor(api: ApiService) {
    super(api, `${portalConst.api.eclass}/v1/attachments`);
  }

  public deleteAttachment(id: number): Observable<Resp<any>> {
    return this.api.delete(`${this.url}/${id}`);
  }

  public checkVideo(req: any): Observable<any> {
    return this.api.post(`${portalConst.api.eclass}/v1/video-hash`, req);
  }
}
