import { DATE_PIPE_DEFAULT_OPTIONS } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { LOCALE_ID, NgModule, Optional, SkipSelf } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@app/shared';
import { TemplateModule } from '@app/shared/modules';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ToastrModule } from 'ngx-toastr';
import { AlertComponent } from './components/alert/alert.component';
import { LoadingCpbComponent } from './components/loading-cpb/loading-cpb.component';
import { LoadingEclassComponent } from './components/loading-eclass/loading-eclass.component';
import { LoadingWeeklyComponent } from './components/loading-weekly/loading-weekly.component';
import { LoadingComponent } from './components/loading/loading.component';
import { httpInterceptorProviders } from './interceptors';
import { CoreServiceModule } from './services/core-service.module';
import { I18nService } from './services/i18n.service';
import { NotificationService } from './services/notification.service';
import { WebSocketService } from './services/web-socket.service';

const languages = ['pt-br', 'es', 'en'];
@NgModule({
  declarations: [LoadingComponent, AlertComponent, LoadingCpbComponent, LoadingEclassComponent, LoadingWeeklyComponent],
  imports: [
    RouterModule,
    SharedModule,
    TemplateModule,
    CoreServiceModule,
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-center',
      preventDuplicates: true,
    }),
    TranslateModule.forRoot({
      useDefaultLang: true,
      loader: {
        provide: TranslateLoader,
        useFactory: (http: HttpClient) => new TranslateHttpLoader(http, 'i18n/', '.json?cb=' + new Date().getTime()),
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    NotificationService,
    WebSocketService,
    I18nService,
    {
      provide: LOCALE_ID,
      useFactory: (translate: TranslateService) => translate.defaultLang,
      deps: [TranslateService],
    },
    { provide: DATE_PIPE_DEFAULT_OPTIONS, useValue: { dateFormat: 'shortDate' } },
    httpInterceptorProviders,
  ],
  exports: [RouterModule, LoadingComponent],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error('CoreModule has already been loaded. Import CoreModule in the AppModule only.');
    }
  }
}
