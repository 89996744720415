<div
  class="popover relative m-2 flex flex-col justify-start rounded-lg bg-white py-2"
  *ngIf="!!pointer"
  [ngClass]="popoverRef.panelClass"
  [ngStyle]="pointer.border"
>
  <div class="absolute flex" [ngStyle]="pointer.indicator">
    <div class="h-0 w-0 border-8" [ngClass]="popoverRef.panelClass" [ngStyle]="pointer.arrow"></div>
  </div>
  <ng-content></ng-content>
</div>
