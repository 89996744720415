<div app-modal-content class="h-[80vh] w-[90vw] !p-0">
  <app-sidenav-group>
    <ng-container *ngIf="dataApplication$ | async as dataApplication">
      <div *ngIf="isEmpty(dataApplication.avaliacao); else dataTemplate" class="grid h-auto gap-4">
        <app-card>
          <div app-card-content class="h-[80vh] w-[90vw] !p-0">
            <app-skeleton *ngIf="loading$ | async; else jumbotron"></app-skeleton>
            <ng-template #jumbotron>
              <app-jumbotron translate> geral.filtro-vazio </app-jumbotron>
            </ng-template>
          </div>
        </app-card>
      </div>
      <ng-template #dataTemplate>
        <ng-template app-sidenav-start [opened]="true" class="!rounded-xl bg-white">
          <div class="h-full p-5">
            <div class="grid w-full">
              <span class="truncate whitespace-nowrap text-md font-semibold">
                {{ dataApplication.avaliacao.titulo }}
              </span>
            </div>
            <hr app-divider class="my-5" />

            <div class="mb-5 flex w-full">
              <span class="text-sm font-bold" translate> gestor-avaliacoes.mais-informacoes </span>
            </div>

            <div class="grid h-auto">
              <table app-table>
                <tbody app-table-body>
                  <tr>
                    <td app-table-cell translate>gestor-avaliacoes.nota</td>
                    <td app-table-cell class="text-md font-semibold">
                      <ng-container [ngSwitch]="attempt?.status">
                        <ng-container *ngSwitchCase="assessmentStatusAttemptEnum.Wait">
                          {{ 'gestor-avaliacoes.status.aguardando-correcao' | translate }}
                        </ng-container>
                        <ng-container *ngSwitchCase="assessmentStatusAttemptEnum.InProgress">
                          {{ 'gestor-avaliacoes.status.em-andamento' | translate }}
                        </ng-container>
                        <ng-container *ngSwitchCase="null">
                          {{ 'gestor-avaliacoes.status.nao-iniciado' | translate }}
                        </ng-container>
                        <ng-container *ngSwitchDefault>
                          <ng-container *ngIf="dataApplication.avaliacao.peso_tipo !== 0">
                            {{ attempt?.nota_calculada | number : '1.2-2' }}
                          </ng-container>
                          <ng-container *ngIf="dataApplication.avaliacao.peso_tipo === 0">
                            {{ attempt?.nota_calculada | number : '1.0-0' }}
                            <span class="font-semibold"> % </span>
                          </ng-container>
                        </ng-container>
                      </ng-container>
                    </td>
                  </tr>
                  <tr *ngIf="isPAAEB">
                    <td app-table-cell translate>gestor-avaliacoes.proficiencia</td>
                    <td app-table-cell translate>
                      <ng-container *ngIf="!!attempt?.proficiencia?.length; else emptyTemplate">
                        <div *ngFor="let proficiencia of attempt.proficiencia">
                          {{ proficiencia.padrao_disciplina.descricao }} -
                          {{ proficiencia.proficiencia | number : '1.2-2' }}
                        </div>
                      </ng-container>
                    </td>
                  </tr>
                  <tr>
                    <td app-table-cell translate>gestor-avaliacoes.aluno</td>
                    <td app-table-cell>
                      <span>
                        {{ userName }}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td app-table-cell translate>gestor-avaliacoes.tipo.data-inicio</td>
                    <td app-table-cell>
                      <ng-container *ngIf="!!attempt; else emptyTemplate">
                        {{ attempt.data_inicio | date : 'short' }}
                      </ng-container>
                    </td>
                  </tr>
                  <ng-container *ngIf="!!attempt">
                    <tr>
                      <td app-table-cell translate>gestor-avaliacoes.tipo.data-fim</td>
                      <td app-table-cell>
                        <ng-container *ngIf="!!attempt; else emptyTemplate">
                          {{ attempt.data_fim | date : 'short' }}
                        </ng-container>
                      </td>
                    </tr>
                    <tr>
                      <td app-table-cell translate>gestor-avaliacoes.tipo.tempo-de-prova</td>
                      <td app-table-cell>
                        <ng-container *ngIf="!!attempt; else emptyTemplate">
                          {{ onTempoProva(attempt.data_inicio, attempt.data_fim) }}
                        </ng-container>
                      </td>
                    </tr>
                  </ng-container>

                  <tr>
                    <td app-table-cell translate>gestor-avaliacoes.tipo.data-da-correcao</td>
                    <td app-table-cell>
                      <span *ngIf="!!attempt?.data_correcao; else emptyTemplate">
                        {{ attempt.data_correcao | date : 'short' }}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td app-table-cell translate>gestor-avaliacoes.tipo.questoes-respondida</td>
                    <td app-table-cell>
                      <ng-container *ngIf="!!attempt; else emptyTemplate">
                        {{ attempt.qtd_questoes_respondidas }}
                      </ng-container>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <hr app-divider class="mb-5" />
            <app-accordion *ngIf="dataApplication.avaliacao.orientacoes">
              <app-accordion-item>
                <div app-accordion-header class="flex truncate font-bold" translate>
                  gestor-avaliacoes.orientacoes-preparo
                </div>
                <div app-accordion-content class="p-5" [innerHTML]="dataApplication.avaliacao.orientacoes"></div>
              </app-accordion-item>
            </app-accordion>
          </div>
        </ng-template>

        <ng-template app-sidenav-outer>
          <ng-container *ngIf="!!dataApplication.avaliacao.questoes?.length; else emptyGabarito">
            <div class="flex justify-end p-5 py-5">
              <button
                app-flat-button
                class="bg-base-status-info text-white hover:bg-base-status-info"
                (click)="onExpandAll(ctrlAccordion ? 'hide' : 'expand')"
                type="button"
              >
                <span class="tw-font-sans tw-text-base tw-font-normal tw-text-gravel-500">
                  <ng-container *ngIf="!ctrlAccordion">
                    {{ 'gestor-avaliacoes.expandir-tudo' | translate }}
                  </ng-container>
                  <ng-container *ngIf="ctrlAccordion">
                    {{ 'gestor-avaliacoes.recolher-tudo' | translate }}
                  </ng-container>
                </span>
                <svg-icon
                  class="flex h-6 w-6 text-white"
                  src="assets/icons/icon-arrow-down-green.svg"
                  *ngIf="!ctrlAccordion"
                ></svg-icon>
                <svg-icon
                  class="flex h-6 w-6 text-white"
                  src="assets/icons/icon-arrow-up-green.svg"
                  *ngIf="ctrlAccordion"
                ></svg-icon>
              </button>
            </div>

            <div class="h-[74.5vh] overflow-y-auto p-5 pt-0">
              <app-accordion #accordion [multi]="true">
                <app-accordion-item
                  *ngFor="let questao of dataApplication.avaliacao.questoes; let i = index"
                  [headerClass]="onGetHeaderClass(questao)"
                >
                  <div app-accordion-header class="grid items-center justify-between md:flex">
                    <div class="grid md:flex md:truncate">
                      <div class="flex">
                        <div class="flex items-center justify-center truncate px-5">
                          <div
                            class="flex h-14 w-14 flex-col items-center justify-center rounded-full bg-base-gray-light-500 shadow-inner shadow-base-gray-200"
                          >
                            <span class="text-md font-semibold leading-none">
                              {{ i + 1 }}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div>
                        <ng-container *ngIf="questao">
                          <ng-container [ngSwitch]="questao.tipo">
                            <div *ngSwitchCase="tipoQuestao.Objetiva">
                              <span class="rounded-full bg-gray-300 px-2 text-white" translate>
                                gestor-avaliacoes.tipo.objetiva
                              </span>
                            </div>
                            <div *ngSwitchCase="tipoQuestao.Discursiva">
                              <span class="rounded-full bg-gray-300 px-2 text-white" translate>
                                gestor-avaliacoes.tipo.discursiva
                              </span>
                            </div>
                          </ng-container>
                        </ng-container>
                        <div class="grid items-center text-[14px] font-light md:!max-h-[4rem]">
                          <span class="grid w-full" [innerHTML]="onSliceStatement(questao.enunciado) | safe : 'html'">
                          </span>
                        </div>
                      </div>
                      <app-badge-annuled-question
                        *ngIf="!!questao.anulado || !!questao.resposta_anulado"
                        [motive]="questao.anulado_motivo || questao.resposta_anulado_motivo"
                        [isAnswerAnnulled]="!!questao.resposta_anulado"
                        [isQuestionAnnulled]="!!questao.anulado"
                        [cancelAnnulment]="false"
                        class="flex items-center px-5"
                      ></app-badge-annuled-question>
                    </div>
                    <app-badge
                      *ngIf="!!questao.sociodemografica; else noteTemplate"
                      class="mr-6"
                      [badgeStyle]="badgeStyle"
                    >
                      {{ 'gestor-avaliacoes.novas-questoes.sociodemografica' | translate }}
                    </app-badge>
                    <ng-template #noteTemplate>
                      <div *ngIf="attempt?.status === 3" class="mt-5 flex md:mt-0">
                        <ng-container>
                          <span
                            [ngClass]="{
                              '!bg-base-mantis-500': questao.nota === questao.peso,
                              '!bg-base-status-danger': !questao.nota || !!questao.resposta_anulado,
                              '!bg-base-status-warning':
                                (questao.nota > 0 && questao.nota !== questao.peso) || !!questao.anulado
                            }"
                            class="mr-5 whitespace-nowrap rounded-full bg-orange-300 px-3 text-white shadow-sm"
                          >
                            <div class="flex h-full min-h-[1.4rem] items-center gap-2">
                              <ng-container *ngIf="!questao.anulado && !questao.resposta_anulado">
                                <svg-icon
                                  class="flex h-3"
                                  [src]="
                                    questao.nota > 0 ? 'assets/icons/icon-check.svg' : 'assets/icons/icon-delete.svg'
                                  "
                                ></svg-icon>
                                <div
                                  *ngIf="
                                    dataApplication.avaliacao.peso_tipo !== 0 || questao.tipo === tipoQuestao.Discursiva
                                  "
                                  class="flex h-[1.4rem] w-[1px] bg-white"
                                ></div>
                              </ng-container>
                              <span
                                *ngIf="
                                  dataApplication.avaliacao.peso_tipo !== 0 || questao.tipo === tipoQuestao.Discursiva
                                "
                                class="font-semibold"
                              >
                                <ng-container *ngIf="{ value: onGetScore(questao) } as score">
                                  {{ score.value | number : '1.2-2' }}
                                </ng-container>
                                /
                                {{ questao.peso | number : '1.2-2' }}
                              </span>
                            </div>
                          </span>
                        </ng-container>
                      </div>
                    </ng-template>
                  </div>
                  <ng-container [ngSwitch]="questao.tipo">
                    <ng-container *ngSwitchCase="tipoQuestao.Objetiva">
                      <ng-template app-accordion-lazy>
                        <div app-accordion-content class="mb-4 rounded-md border border-gray-200">
                          <div class="p-4">
                            <span [innerHTML]="questao.enunciado"></span>
                          </div>
                          <div>
                            <div
                              class="rounded-b-xl rounded-br-none border-x border-b border-base-gray-100 bg-base-gray-light-200 p-4 shadow-inner-bg"
                            >
                              <div
                                class="mb-3 grid w-full"
                                *ngFor="let alternativa of questao.alternativas; let i = index"
                              >
                                <button
                                  [ngClass]="{
                                    '!bg-base-mantis-500':
                                      !questao.anulado &&
                                      alternativa.resposta &&
                                      !questao.resposta_anulado &&
                                      attempt?.status === 3,
                                    '!bg-base-status-danger':
                                      !questao.sociodemografica &&
                                      ((!!alternativa.resposta &&
                                        !alternativa.alternativa_correta &&
                                        !questao.anulado) ||
                                        (!!alternativa.resposta && !!questao.resposta_anulado)),
                                    '!bg-base-status-warning': !!questao.anulado && alternativa.resposta,
                                    '!bg-base-status-info':
                                      !questao.anulado && alternativa.resposta && attempt?.status !== 3,
                                    '!text-white !shadow-inner !shadow-black/25': !!alternativa.resposta
                                  }"
                                  app-button
                                  class="border-1 flex w-full justify-start gap-4 rounded-md bg-white p-1 shadow-lg transition duration-300"
                                >
                                  <svg-icon
                                    [ngClass]="{
                                      'text-white': alternativa.resposta,
                                      'text-base-mantis-500': !alternativa.resposta
                                    }"
                                    *ngIf="alternativa.alternativa_correta"
                                    class="flex h-8 w-6 cursor-pointer text-white"
                                    src="assets/icons/icon-check-outline.svg"
                                  ></svg-icon>

                                  <span
                                    [ngClass]="{
                                          'ml-10': !alternativa.alternativa_correta,
                                          '!border-base-mantis-500 ' : !!alternativa.resposta && alternativa.alternativa_correta,
                                          '!border-base-status-danger ' : !questao.sociodemografica && (!!alternativa.resposta && !alternativa.alternativa_correta && attempt?.status === 3),
                                          '!border-base-status-info':
                                    !questao.anulado && alternativa.resposta && attempt?.status !== 3,
                                        }"
                                    class="flex w-max !rounded-lg border border-solid border-base-mantis-500 bg-white !p-1 shadow-sm shadow-black/40"
                                  >
                                    <span
                                      class="rounded-lg px-2 text-[1.2rem] text-base-mantis-500"
                                      [ngClass]="{
                                          ' !text-base-mantis-500' : !!alternativa.resposta && alternativa.alternativa_correta,
                                          ' !text-base-status-danger' : !questao.sociodemografica && (!!alternativa.resposta && !alternativa.alternativa_correta && attempt?.status === 3),
                                          '!text-base-status-info':
                                    !questao.anulado && alternativa.resposta && attempt?.status !== 3,
                                        }"
                                    >
                                      {{ toLetter(i) }}
                                    </span>
                                  </span>

                                  <div class="flex w-full truncate">
                                    <span
                                      class="mb-1 flex w-full items-center text-base"
                                      [innerHTML]="alternativa.descricao"
                                    ></span>
                                  </div>
                                </button>
                              </div>

                              <ng-template #loadTemplate>
                                <app-skeleton></app-skeleton>
                              </ng-template>
                              <ng-template #emptyTemplate>
                                <app-jumbotron translate> geral.filtro-vazio </app-jumbotron>
                              </ng-template>
                            </div>
                          </div>
                        </div>
                      </ng-template></ng-container
                    >
                    <ng-container *ngSwitchCase="tipoQuestao.Discursiva">
                      <ng-template app-accordion-lazy>
                        <div app-accordion-content class="mb-2 rounded-md border border-gray-200">
                          <div class="p-4">
                            <span [innerHTML]="questao.enunciado"></span>
                            <div class="mt-5">
                              <h6 class="font-semibold" translate>gestor-avaliacoes.resposta-do-aluno-dois-ponto</h6>
                              <p
                                *ngIf="questao.resposta; else noAnswer"
                                class="whitespace-pre-wrap"
                                [innerHTML]="questao.resposta"
                              ></p>
                            </div>
                          </div>
                          <div *ngIf="questao.feedback" class="bg-yellow-100 p-4">
                            <table>
                              <tbody>
                                <ng-container>
                                  <tr>
                                    <td>
                                      <div>
                                        <h6 class="font-semibold" translate>
                                          gestor-avaliacoes.correcao-do-professor-dois-ponto
                                        </h6>
                                        <p [innerHTML]="questao.feedback"></p>
                                      </div>
                                    </td>
                                  </tr>
                                </ng-container>
                              </tbody>
                            </table>
                          </div>
                        </div> </ng-template
                    ></ng-container>
                  </ng-container>
                </app-accordion-item>
              </app-accordion>
            </div>
          </ng-container>
          <ng-template #emptyGabarito>
            <app-jumbotron translate>gestor-avaliacoes.gabarito-indisponivel</app-jumbotron>
          </ng-template>
        </ng-template>
      </ng-template>
    </ng-container>
  </app-sidenav-group>
</div>

<ng-template #emptyTemplate> - </ng-template>
<ng-template #noAnswer>
  <span class="font-semibold italic">O aluno não respondeu!</span>
</ng-template>
