import { BreakpointObserver } from '@angular/cdk/layout';
import { ComponentPortal, Portal, TemplatePortal } from '@angular/cdk/portal';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ComponentRef,
  ElementRef,
  EventEmitter,
  Injector,
  Input,
  OnDestroy,
  Output,
  StaticProvider,
  ViewChild,
} from '@angular/core';
import { notNull } from '@app/shared/utils';
import { BehaviorSubject, Observable, Subject, combineLatest, map, take, takeUntil, tap } from 'rxjs';
import {
  RESPONSIVE_BREAKPOINT,
  SIDENAV_DATA,
  SIDENAV_REF,
  SidenavDirective,
  SidenavMode,
  SidenavRef,
  SidenavStatus,
} from './sidenav';
import { SidenavContainerComponent } from './sidenav-container.component';
import { CLOSED_SIZE, POSITION, PositionProp } from './sidenav-position';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: SIDENAV_REF, useExisting: SidenavRef, multi: true }],
})
export class SidenavComponent extends SidenavRef implements AfterViewInit, OnDestroy {
  @Output() public margin = new EventEmitter<string>();
  @Output() public status = new EventEmitter<SidenavStatus>();
  @ViewChild(SidenavContainerComponent, { static: false, read: ElementRef })
  private _elementRef: ElementRef<HTMLElement>;

  public componentInstance$: Observable<any>;
  public contentRef$: Observable<any>;
  public config$: Observable<any>;
  public position$: Observable<PositionProp>;
  public closed$: Observable<any>;
  public view$: Observable<any>;
  public open$ = new BehaviorSubject<boolean>(false);
  public mode$ = new BehaviorSubject(SidenavMode.Side);
  public data$ = new BehaviorSubject<any>(null);
  private _componentInstance = new BehaviorSubject<any>(null);
  private _config$ = new BehaviorSubject<SidenavDirective>(null);
  private _size$ = new BehaviorSubject(null);
  private _stop$ = new Subject<void>();
  private _destroy$ = new BehaviorSubject<boolean>(false);
  private _closed$ = new Subject<any>();
  private _container$ = new BehaviorSubject<any>(null);

  constructor(private _breakpointObserver: BreakpointObserver) {
    super();
    this.componentInstance$ = this._componentInstance.asObservable();
    this.loadData();
  }

  @Input() public set config(config: SidenavDirective) {
    if (config) {
      this._config$.next(config);
      this.mode$.next(config.mode);
      this.data$.next(config.data);
    }
    this.open$.next(config?.opened);
    this._destroy$.next(!config);
  }

  public ngAfterViewInit() {
    this.loadListeners();
  }

  public ngOnDestroy() {
    this._stop$.next();
    this.mode$.complete();
    this._size$.complete();
    this.data$.complete();
    this.open$.complete();
    this._closed$.complete();
    this._stop$.complete();
    this._destroy$.complete();
    this._container$.complete();
  }

  public onContainerAttached(attached: boolean) {
    this._container$.next(attached);
  }

  public onAttached(data: any, event: ComponentRef<any>) {
    this._componentInstance.next(event?.instance);
    this.markOpened(data.config, data.position);
  }

  public onToggleMode() {
    if (this.isSide(this.mode$.value)) {
      this.setMode(SidenavMode.Over);
    } else {
      this.setMode(SidenavMode.Side);
    }
  }

  public onToggle(config: SidenavDirective, position: PositionProp) {
    if (this.open$.value) {
      this.onClose(config, position);
    } else {
      this.onOpen();
    }
  }

  public onClose(config: SidenavDirective, position: PositionProp, data?: any) {
    this._componentInstance.next(null);
    this._closed$.next(data);
    this.open$.next(false);
    this.markClosed(config, position);
  }

  public onOpen() {
    this.open$.next(true);
  }

  public containerClass(view: any): string {
    return `${view.config.bgColor} ${view.position?.host}`;
  }

  public isSide(mode: SidenavMode) {
    return mode === SidenavMode.Side;
  }

  public setMode(mode: SidenavMode) {
    this.mode$.next(mode);
  }

  public close = (data?: any) =>
    combineLatest([this._config$, this.position$])
      .pipe(take(1))
      .subscribe(([config, position]) => this.onClose(config, position, data));

  public open = (value?: any) => {
    this.data$.next(value);
    this.open$.next(true);
  };

  public isShowAnchor(data?: any) {
    if (data.config?.closedAnchor) {
      return !!data.template;
    }
    return true;
  }

  private loadData() {
    this.config$ = this._config$.asObservable().pipe(notNull());
    this.position$ = this.loadPosition();
    this.contentRef$ = this.loadContentRef();
    this.closed$ = this._closed$.asObservable();
    this.view$ = combineLatest({
      config: this._config$,
      template: this.contentRef$,
      position: this.position$,
      destroy: this._destroy$,
    }).pipe(
      takeUntil(this._stop$),
      map((data) => (data.destroy ? null : data)),
    );
  }

  private loadContentRef(): Observable<Portal<any>> {
    return combineLatest([this.open$, this._config$, this.data$, this.position$]).pipe(
      takeUntil(this._stop$),
      map(([open, config, data]) => {
        if (config && open) {
          return this.build(config, data);
        }
        return null;
      }),
    );
  }

  private minSize(config: SidenavDirective) {
    return config?.closedAnchor ? '0' : CLOSED_SIZE;
  }

  private loadPosition(): Observable<any> {
    return this._config$.pipe(
      notNull(),
      map((ref) => POSITION[ref.direction]),
    );
  }

  private build = (content: any, data: any): Portal<any> => {
    const injector = this.injector(data);
    if (content.componentRef) {
      return new ComponentPortal(content.componentRef, null, injector);
    }
    return new TemplatePortal(content?.templateRef, null, null, injector);
  };

  private injector(data: any): Injector {
    const providers: StaticProvider[] = [
      { provide: SIDENAV_DATA, useValue: data },
      { provide: SIDENAV_REF, useValue: this },
    ];
    return Injector.create({ providers });
  }

  private markOpened(config: SidenavDirective, position: PositionProp) {
    const elementRef = this._elementRef?.nativeElement.firstChild;
    if (elementRef?.firstChild) {
      const res = position.markOpened(this._elementRef.nativeElement.firstChild, config.panelWidth);
      this._size$.next(res);
    }
  }

  private markClosed(config: SidenavDirective, position: PositionProp) {
    const elementRef = this._elementRef?.nativeElement.firstChild;
    if (elementRef?.firstChild) {
      const res = position.markClosed(this._elementRef.nativeElement.firstChild, this.minSize(config));
      this._size$.next(res);
    }
  }

  private loadListeners() {
    this.listenerMargin();
    this.listenerStatus();
  }

  private listenerStatus() {
    combineLatest([this.open$, this._config$, this.position$, this._container$])
      .pipe(
        takeUntil(this._stop$),
        tap(([open, config, position]) => {
          if (open) {
            this.markOpened(config, position);
          } else {
            this.markClosed(config, position);
          }
        }),
      )
      .subscribe(([open, config]) => this.status.emit({ open, config }));
  }

  private listenerMargin() {
    const size$ = this._size$.asObservable().pipe(notNull());
    const small$ = this._breakpointObserver.observe(RESPONSIVE_BREAKPOINT).pipe(map(({ matches }) => matches));
    combineLatest([this.mode$, size$, this._config$, small$, this._destroy$])
      .pipe(
        takeUntil(this._stop$),
        map(([mode, size, config, small, destroy]) => {
          if (destroy) {
            return '0';
          }
          if (!small && (mode === SidenavMode.Side || !config)) {
            return size;
          }
          return this.minSize(config);
        }),
      )
      .subscribe((res) => this.margin.emit(res));
  }
}
