import { ChangeDetectionStrategy, Component, DestroyRef } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { notNull } from '@app/shared';
import { AppSelectors } from '@app/store';
import { AppState } from '@app/store/app.state';
import { Store } from '@ngrx/store';
import { Observable, merge, startWith } from 'rxjs';
import { distinctUntilChanged, map, tap } from 'rxjs/operators';
import { LoadingCpbComponent } from '../loading-cpb/loading-cpb.component';
import { LoadingWeeklyComponent } from '../loading-weekly/loading-weekly.component';
import { ComponentPortal } from '@angular/cdk/portal';
import { LoadingEclassComponent } from '../loading-eclass/loading-eclass.component';

export enum Loading {
  Cpb,
  Eclass,
  Weekly,
}

export const Products = {
  [Loading.Cpb]: LoadingCpbComponent,
  [Loading.Eclass]: LoadingEclassComponent,
  [Loading.Weekly]: LoadingWeeklyComponent,
};

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadingComponent {
  public loading$: Observable<ComponentPortal<any>>;

  constructor(private store: Store<AppState>, private destroyRef: DestroyRef) {
    const isExternal = this.store.select(AppSelectors.ActiveUser).pipe(
      notNull(),
      map(({ escola_externa }) => escola_externa),
    );

    this.loading$ = merge(isExternal).pipe(
      takeUntilDestroyed(this.destroyRef),
      map((res) => (res ? Loading.Weekly : Loading.Eclass)),
      startWith(this.startLoading(new URL(window.location.href).hostname)),
      distinctUntilChanged(),
      map((loading) => Products[loading]),
      map((component) => new ComponentPortal(component)),
    );
  }

  private startLoading(hostname: string): Loading {
    if (hostname.includes('sistemainterativo.com.br')) {
      return Loading.Weekly;
    }
    if (hostname.includes('cpbedu.me')) {
      return Loading.Cpb;
    }
    return Loading.Eclass;
  }
}
