import { ConnectionPositionPair } from '@angular/cdk/overlay';

const TOP: ConnectionPositionPair = {
  originX: 'center',
  originY: 'top',
  overlayX: 'center',
  overlayY: 'bottom',
};

const TOP_START: ConnectionPositionPair = {
  originX: 'center',
  originY: 'top',
  overlayX: 'start',
  overlayY: 'bottom',
  offsetX: -20,
};

const TOP_END: ConnectionPositionPair = {
  originX: 'center',
  originY: 'top',
  overlayX: 'end',
  overlayY: 'bottom',
  offsetX: 20,
};

const RIGHT: ConnectionPositionPair = {
  originX: 'end',
  originY: 'center',
  overlayX: 'start',
  overlayY: 'center',
};

const RIGHT_TOP: ConnectionPositionPair = {
  originX: 'end',
  originY: 'top',
  overlayX: 'start',
  overlayY: 'top',
};

const RIGHT_BOTTOM: ConnectionPositionPair = {
  originX: 'end',
  originY: 'bottom',
  overlayX: 'start',
  overlayY: 'bottom',
};

const BOTTOM: ConnectionPositionPair = {
  originX: 'center',
  originY: 'bottom',
  overlayX: 'center',
  overlayY: 'top',
};

const BOTTOM_START: ConnectionPositionPair = {
  originX: 'center',
  originY: 'bottom',
  overlayX: 'start',
  overlayY: 'top',
  offsetX: -20,
};

const BOTTOM_END: ConnectionPositionPair = {
  originX: 'center',
  originY: 'bottom',
  overlayX: 'end',
  overlayY: 'top',
  offsetX: 20,
};

const LEFT: ConnectionPositionPair = {
  originX: 'start',
  originY: 'center',
  overlayX: 'end',
  overlayY: 'center',
};

const LEFT_TOP: ConnectionPositionPair = {
  originX: 'start',
  originY: 'top',
  overlayX: 'end',
  overlayY: 'top',
};

const LEFT_BOTTOM: ConnectionPositionPair = {
  originX: 'start',
  originY: 'bottom',
  overlayX: 'end',
  overlayY: 'bottom',
};

export const Strategy = {
  TOP,
  TOP_START,
  TOP_END,
  RIGHT,
  RIGHT_TOP,
  RIGHT_BOTTOM,
  BOTTOM,
  BOTTOM_START,
  BOTTOM_END,
  LEFT,
  LEFT_TOP,
  LEFT_BOTTOM,
};
