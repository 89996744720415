import { Injectable } from '@angular/core';
import { portalConst } from '@app/config';
import { Observable } from 'rxjs';
import { ApiService, Resp } from './api.service';
import { PaisEnum } from '../models';

@Injectable({
  providedIn: 'root',
})
export class ReaderLevelService {
  private readonly resource = `${portalConst.api.didaticos}/api/admin/v1/nivel-leitor`;

  constructor(private api: ApiService) {}

  public index(country: PaisEnum): Observable<Resp<any[]>> {
    return this.api.get(`${this.resource}/${country}`);
  }
}
