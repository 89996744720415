import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { LoadingService, UserService } from '@app/core/services';
import { ENTITY_KEY, compareEntity, isProfileActived } from '@app/shared/utils';
import { AppActions, AppSelectors } from '@app/store';
import { AppState } from '@app/store/app.state';
import { Store } from '@ngrx/store';
import { isEmpty } from 'lodash';
import { BehaviorSubject, Subject, combineLatest } from 'rxjs';
import { map, take, takeUntil, tap } from 'rxjs/operators';
@Component({
  selector: 'app-popover-tab-entity',
  templateUrl: './popover-tab-entity.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PopoverTabEntityComponent implements OnInit, OnDestroy {
  @Input({ required: true }) public store: AppState;

  public searchCtrl = new FormControl();
  public entities$ = new BehaviorSubject<any[]>(undefined);
  public destroy$ = new Subject<void>();
  public loading$ = new Subject<boolean>();

  constructor(
    private userService: UserService,
    private storeState: Store<AppState>,
    private loadingService: LoadingService,
  ) {}

  public ngOnInit() {
    const store$ = this.storeState.select(AppSelectors.appFeature);
    combineLatest([this.userService.entidadeContexto(), store$])
      .pipe(
        tap(() => this.loading$.next(true)),
        takeUntil(this.destroy$),
        map(([entities, store]) => {
          const activeProfile = store.funcoes.find((f) => isProfileActived(f, store));
          const key = ENTITY_KEY.find((k) => !!activeProfile[k]);
          return entities.filter((entity) => compareEntity(entity, store.usuario_ativo, [key]));
        }),
        tap(() => this.loading$.next(false)),
      )
      .subscribe((units) => this.entities$.next(units));
  }

  public ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public onChange(entity: any) {
    if (!this.isActived(entity, this.store)) {
      this.storeState
        .select(AppSelectors.appFeature)
        .pipe(
          take(1),
          tap(() => this.loadingService.start()),
          map((store) => ({ ...store, unidade: entity })),
        )
        .subscribe((user) => this.storeState.dispatch(AppActions.InitDataEffect({ user })));
    }
  }

  public isEmpty(data: any[]): boolean {
    return isEmpty(data);
  }

  public isActived(entitiy: any, store: AppState): boolean {
    return ['entidade_id', 'campo_id', 'uniao_id', 'editora_id'].every((k) => entitiy[k] === store.usuario_ativo[k]);
  }
}
