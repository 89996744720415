<app-popover>
  <div app-popover-content>
    <p [innerHtml]="data | safe : 'html'"></p>
  </div>
  <hr app-divider />
  <div app-popover-footer>
    <button app-icon-button type="button" [popoverClose]="true" class="!rounded-full bg-theme-500 text-white">
      <svg-icon class="h-5 w-5" src="assets/icons/icon-close-fine.svg"></svg-icon>
    </button>
  </div>
</app-popover>
