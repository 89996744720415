<ng-container *ngIf="{ store: store$ | async } as config">
  <button
    *ngIf="!!data"
    class="cursor-pointer clear-both grid group items-center min-h-[3rem] grid-cols-[1fr_auto] gap-1 rounded-xl border-l-4 !border-solid bg-white px-4 py-2 text-left text-gray-900  transition duration-300 hover:!bg-base-status-warning-50 w-full {{
      data?.style?.border
    }}"
    [ngClass]="{ '!bg-base-status-warning-100': !data.isRead }"
    (click)="onRead(data, config.store)"
  >
    <div class="flex items-center gap-4">
      <div
        *ngIf="!summary"
        class="flex h-12 w-12 flex-col items-center justify-center rounded-full bg-base-gray-light-500 shadow-inner shadow-base-gray-200"
      >
        <svg-icon [ngClass]="data?.style?.color" class="w-8 group-hover:hidden" [src]="data.icon"></svg-icon>
        <svg-icon
          [ngClass]="data?.style?.color"
          class="hidden w-8 group-hover:block"
          src="assets/icons/icon-read.svg"
        ></svg-icon>
      </div>
      <div>
        <span class="font-semibold text-gray-700" [innerHTML]="data.message"></span>
        <span class="text-gray-500" app-tooltip [tooltip]="data.createAt | date : 'short'">
          {{ data.createAt | timeAgo }}
        </span>
      </div>
    </div>
    <svg-icon class="h-5 w-5 text-base-status-warning" src="assets/icons/icon-link.svg"></svg-icon>
  </button>
</ng-container>
