import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { isDate } from 'date-fns';
import { isArray, isBoolean, isNil, isObject, isString } from 'lodash';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

interface Link {
  url: string;
  label: string;
  active: boolean;
}
export interface Page<T> {
  current_page: number;
  data: T[];
  first_page_url: string;
  from: number;
  last_page: number;
  last_page_url: string;
  links: Link[];
  next_page_url: string;
  path: string;
  per_page: number;
  prev_page_url: string;
  to: number;
  total: number;
}
export interface Resp<T> {
  ret: number;
  data: T;
  msg?: string;
}

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(public readonly http: HttpClient) {}

  public get<T>(url: string, req?: any | HttpParams, header?: any | HttpHeaders): Observable<Resp<T>> {
    return this.list(url, req, header);
  }

  public page<T>(url: string, req?: any | HttpParams, header?: any | HttpHeaders): Observable<Resp<Page<T>>> {
    return this.list(url, req, header);
  }

  public list<T>(url: string, req?: any | HttpParams, header?: any | HttpHeaders): Observable<T> {
    const params = this.resolveParams(req);
    const headers = this.resolveHeaders(header);

    return this.http.get<T>(url, { params, headers }).pipe(take(1));
  }

  public put<T>(url: string, body: any = {}, req?: any | HttpParams): Observable<Resp<T>> {
    const params = this.resolveParams(req);

    return this.http.put<Resp<T>>(url, body, { params }).pipe(take(1));
  }

  public patch<T>(url: string, body: any = {}, req?: any | HttpParams): Observable<T> {
    const params = this.resolveParams(req);
    return this.http.patch<T>(url, body, { params }).pipe(take(1));
  }

  public post<T>(url: string, body: any = {}, req?: any | HttpParams): Observable<Resp<T>> {
    const params = this.resolveParams(req);

    return this.http.post<Resp<T>>(url, body, { params }).pipe(take(1));
  }

  public upload<T>(url: string, file: any = {}, body: any = {}, req?: any | HttpParams): Observable<T> {
    const params = this.resolveParams(req);
    const form: FormData = new FormData();
    Object.entries(body).forEach(([key, value]) => form.append(key, value as any));
    form.append('upload', file);
    return this.http.post<T>(url, form, { params }).pipe(take(1));
  }

  public delete<T>(url: string, req?: any | HttpParams): Observable<Resp<T>> {
    const params = this.resolveParams(req);

    return this.http.delete<Resp<T>>(url, { params }).pipe(take(1));
  }

  public resolveHeaders(req: any = {}): HttpHeaders {
    if (req instanceof HttpHeaders) {
      return req;
    }
    let headers = new HttpHeaders();
    Object.entries(req)
      .filter(([key, value]) => !isNil(value))
      .forEach(([key, value]) => (headers = headers.append(key, String(value))));
    return headers;
  }

  public resolveParams(req: any = {}): HttpParams {
    if (req instanceof HttpParams) {
      return req;
    }
    let params = new HttpParams();

    Object.entries(req)
      .map(([key, value]) => ({ key, value: this.convertExtraValue(value) }))
      .filter(({ value }) => !isNil(value))
      .forEach(({ key, value }) => (params = params.append(key, value)));
    return params;
  }

  public convertExtraValue(value: any): any {
    if (`${value}`.match(/^(?!0\d)\d*(\.\d+)?$/)) {
      return Number(value);
    } else if (isBoolean(value)) {
      return String(value);
    } else if (value && isString(value)) {
      return value;
    } else if (isArray(value)) {
      const param = value.join(',');
      return param ? param : null;
    } else if (isObject(value)) {
      return JSON.stringify(value);
    } else if (isDate(value)) {
      return value?.toJSON();
    }
  }
}
