import { Injectable } from '@angular/core';
import { portalConst } from '@app/config';
import { Observable } from 'rxjs';

import { Daily, DaySummary } from '../models';
import { AcademicTree } from '../models/academic-tree';
import { ApiService, Resp } from './api.service';
import { CrudService } from './crud.service';

@Injectable({ providedIn: 'root' })
export class DailyService extends CrudService<any> {
  constructor(api: ApiService) {
    super(api, `${portalConst.api.eclass}/v1/dailies`);
  }

  public day(date: any, req?: any, headers?: any): Observable<Resp<Daily[]>> {
    return this.api.get(`${this.url}/day/${date}`, req, headers);
  }

  public dayDashboard(req?: any, headers?: any): Observable<Resp<DaySummary>> {
    return this.api.get(`${this.url}/days-dashboard`, req, headers);
  }

  public dayCalendar(req: any, headers?: any): Observable<Resp<any>> {
    return this.api.get(`${this.url}/days-calendar`, req, headers);
  }

  public exist(req: any): Observable<Resp<Daily>> {
    return this.api.get(`${this.url}/exist`, req);
  }

  public academicTree(req?: any, headers?: any): Observable<Resp<AcademicTree>> {
    return this.api.get(`${portalConst.api.eclass}/v1/academic-tree`, req, headers);
  }

  public getTrailElementsProgress(dailyId: number) {
    return this.api.get(`${this.url}/trails-progress/${dailyId}`);
  }

  public markElementAsRead(elementSlug: string, dailyId: number | string) {
    return this.api.post(`${this.url}/trail-element/${dailyId}/elemento/${elementSlug}/concluido`);
  }
}
