export enum TabsEnum {
  Course = 1,
  TargetAudience = 2,
}

export enum SubTabsEnum {
  Group = 1,
  User = 2,
  Function = 3,
  Importation = 4,
}
