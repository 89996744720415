<app-popover>
  <div class="grid h-auto px-2">
    <button
      app-outline-button
      #anullAnswer
      (click)="onOpenAnullQuestion(anullAnswer)"
      class="group my-1 h-9 w-full min-w-[140px] !justify-start border-none bg-black/5 text-left text-black/80 hover:bg-base-status-danger hover:font-semibold hover:text-white"
    >
      <svg-icon
        class="mr-2 w-5 text-base-status-danger group-hover:text-white"
        src="assets/icons/icon-clipboard-check.svg"
      ></svg-icon>
      <span class="group-hover:text-theme-contrast md:inline-block" translate> Anular </span>
    </button>

    <button
      app-outline-button
      *ngIf="data.question.tipo === assessmentQuestionType.Objetiva"
      (click)="onCleanQuestionAlternative()"
      class="group my-1 h-9 w-full min-w-[140px] !justify-start border-none bg-black/5 text-left text-black/80 hover:bg-base-status-info hover:font-semibold hover:text-white"
    >
      <svg-icon
        class="mr-2 w-5 text-base-status-info group-hover:text-white"
        src="assets/icons/icon-comments-disable.svg"
      ></svg-icon>
      <span class="group-hover:text-theme-contrast md:inline-block" translate> gestor-avaliacoes.em-branco </span>
    </button>
  </div>
</app-popover>
