<app-popover>
  <div app-popover-content class="max-w-xs py-3 md:max-w-md">
    <div *ngIf="data" class="max-h-40 overflow-y-auto p-2">
      <ng-container *ngIf="data.anulado_motivo">
        <hr />
        <div
          class="whitespace-pre-wrap break-words text-justify"
          [innerHTML]="data.anulado_motivo | safe : 'html'"
        ></div>
      </ng-container>
    </div>
  </div>
</app-popover>
