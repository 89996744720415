import { Dialog } from '@angular/cdk/dialog';
import { Component, DestroyRef, OnDestroy } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ApplicationStatusExecutionEnum, AssessmentApplicationMode, AssessmentOrigin } from '@app/core/models';
import { ModalAssessmentsReportComponent } from '@app/modules/assessments-manager/shared/components/modal-evaluation-report/modal-assessments-report.component';
import { ModalAssessmentExecutionService } from '@app/modules/assessments-manager/shared/modal-assessment-execution/modal-assessment-execution.service';
import { safeEmptyList, switchDelay } from '@app/shared';
import { AppSelectors } from '@app/store';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { format } from 'date-fns';
import { Observable, startWith, Subject, switchMap, tap } from 'rxjs';
import { AssessmentApplicationService } from '../../../../core/services/assessment-application.service';
import { notNull } from '../../../../shared/utils/operators';

@Component({
  selector: 'app-card-evaluation',
  templateUrl: './card-evaluation.component.html',
  styles: [],
})
export class CardEvaluationComponent implements OnDestroy {
  public readonly applicationStatusExecutionEnum = ApplicationStatusExecutionEnum;
  public evaluations$: Observable<any>;
  public entityId$: Observable<number>;
  public load$: Observable<boolean>;
  private _load$ = new Subject<boolean>();
  public modos = [
    {
      id: AssessmentApplicationMode.Online,
      label: this._translateService.instant('gestor-avaliacoes.modos.online'),
    },
    {
      id: AssessmentApplicationMode.Impressa,
      label: this._translateService.instant('gestor-avaliacoes.modos.impressa'),
    },
    {
      id: AssessmentApplicationMode.Hibrido,
      label: this._translateService.instant('gestor-avaliacoes.modos.hibrido'),
    },
  ];

  public isStudent: boolean = false;
  public readonly badgeStyle = {
    background: 'bg-green-400 text-white',
  };

  constructor(
    private _dialog: Dialog,
    private _assessmentApplication: AssessmentApplicationService,
    private _store: Store,
    private _translateService: TranslateService,
    private _destroy: DestroyRef,
    private _modalAssessmentExecutionService: ModalAssessmentExecutionService,
  ) {
    this.load$ = this._load$.asObservable().pipe(switchDelay(), startWith(true));

    this.evaluations$ = this._store.select(AppSelectors.ActiveUser).pipe(
      takeUntilDestroyed(this._destroy),
      notNull(),
      tap((activeUser) => {
        this.isStudent = !!activeUser.aluno_id;
        this._load$.next(true);
      }),
      switchMap((activeUser) => {
        const data: { except_status: ApplicationStatusExecutionEnum.Closed; year?: string; month?: string } = {
          except_status: 2,
        };
        if (!activeUser.aluno_id) {
          data.year = format(new Date(), 'yyyy');
          data.month = format(new Date(), 'MM');
        }
        return this._assessmentApplication.assessmentEclass(data).pipe(safeEmptyList());
      }),
      tap(() => this._load$.next(false)),
    );
  }

  public ngOnDestroy() {
    this._load$.complete();
  }

  public onExecuteAssessment(applicationUuid: string) {
    this._modalAssessmentExecutionService.open(AssessmentOrigin.GDA, {
      data: { aplicacao_uuid: applicationUuid },
    });
  }

  public onModalEvaluation(assessmentUuid: string) {
    this._dialog.open(ModalAssessmentsReportComponent, {
      data: assessmentUuid,
    });
  }

  public onAssessmentClick(applicationUuid: string) {
    if (this.isStudent) {
      this.onExecuteAssessment(applicationUuid);
    } else {
      this.onModalEvaluation(applicationUuid);
    }
  }
}
