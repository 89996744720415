import { Dialog } from '@angular/cdk/dialog';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { storageKeys } from '@app/config';
import { Login } from '@app/core/guards';
import { FunctionsEnum, PaisEnum } from '@app/core/models';
import { AlertService, LoadingService, UserService } from '@app/core/services';
import { INIT_DATA } from '@app/core/services/core.service';
import { TAB_STYLE } from '@app/shared/modules/template/components/tab/tab.interfaces';
import { Checker, notNull, safeEmptyList, userPhoto } from '@app/shared/utils';
import { AppActions, AppSelectors } from '@app/store';
import { AppState } from '@app/store/app.state';
import { Store } from '@ngrx/store';
import { head, isEmpty } from 'lodash';
import { LocalStorageService } from 'ngx-webstorage';
import { Observable, Subject, of } from 'rxjs';
import { exhaustMap, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import packageJson from '../../../../../package.json';
import { ModalLoginFromUserComponent } from '../modal-login-from-user/modal-login-from-user.component';
import { TranslateService } from '@ngx-translate/core';
import { PopoverRef } from '@app/shared/modules/template/components/popover';

@Component({
  templateUrl: './popover-profile.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PopoverProfileComponent implements OnInit, OnDestroy {
  public readonly version: string = packageJson.version;
  public readonly slim = TAB_STYLE.Slim;
  public store$: Observable<AppState>;
  public origin$: Observable<string>;
  private _destroy$ = new Subject<void>();

  constructor(
    private _dialog: Dialog,
    private _store: Store<AppState>,
    private _userService: UserService,
    private _alertService: AlertService,
    private _loadingService: LoadingService,
    private _localStorageService: LocalStorageService,
    private _translate: TranslateService,
    private _popoverRef: PopoverRef,
  ) {}

  public ngOnInit() {
    this.loadData();
  }

  public ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

  public onOpenDialog() {
    this._dialog.open(ModalLoginFromUserComponent, { width: '70vw' });
  }

  public onLogout() {
    this._loadingService.start();
    this._popoverRef.close();
    Login.navigateToLogin(this._store);
  }

  public onGetOutSafe() {
    this.store$
      .pipe(
        take(1),
        switchMap((store) => {
          if (!store.token) {
            this.onLogout();
            return of([]);
          }
          return this._userService.getListOfDevicesGoogle(store.usuario_id).pipe(safeEmptyList());
        }),
        map((devices) => {
          let msg = '';
          if (!isEmpty(devices)) {
            msg =
              '<p class="font-semibold">' +
              this._translate.instant('geral.alertas.dispositivos-deslogados') +
              '</p>' +
              '<br>';
            msg +=
              '<br>' +
              '<table>' +
              '<thead>' +
              '<tr>' +
              '<th class="font-semibold pr-4 border border-gray-300 text-center">' +
              'Modelo' +
              '</th>' +
              '<th class="font-semibold pr-4 border border-gray-300 text-center">' +
              'Sistema' +
              '</th>' +
              '<th class="font-semibold pr-4 border border-gray-300 text-center">' +
              'Última sincronização' +
              '</th>' +
              '</tr>' +
              '</thead>' +
              '<tbody>';
            devices.forEach((element) => {
              const model =
                element.model !== null && element.model !== 'NULL' && element.model !== 'null' ? element.model : '-';
              msg +=
                '<tr><td class="border border-gray-300 text-center">' +
                model +
                '</td><td class="border border-gray-300 text-center">' +
                element.os +
                '</td><td class="border border-gray-300 text-center">' +
                new Date(element.last_sync).toLocaleString() +
                '</td></tr>';
            });
            msg += '</tbody></table><br>';
          }
          msg +=
            '<p class="justify-end font-semibold flex">' +
            this._translate.instant('geral.alertas.deseja-sair') +
            '</p>';

          return msg;
        }),
        switchMap((msg) => this._alertService.confirm({ innerHtml: msg })),
        notNull(),
        switchMap(() =>
          this.store$.pipe(
            take(1),
            switchMap((store) => this._userService.expireGoogleSession(store.usuario_id)),
          ),
        ),
      )
      .subscribe(() => this.onLogout());
  }

  public onReturnOrigin(token: string) {
    this._loadingService.start();
    this._localStorageService.clear(storageKeys.ORIGIN);

    this._store.dispatch(AppActions.TokenEffect({ token }));
    this._store
      .select(AppSelectors.token)
      .pipe(
        take(1),
        takeUntil(this._destroy$),
        exhaustMap(() => this._userService.get(INIT_DATA)),
        tap((user) => this._store.dispatch(AppActions.InitDataEffect({ user }))),
      )
      .subscribe();
  }

  private loadData() {
    this.origin$ = this._store
      .select(AppSelectors.token)
      .pipe(map(() => this._localStorageService.retrieve(storageKeys.ORIGIN)));
    this.store$ = this._store.select(AppSelectors.appFeature).pipe(
      map((store) => ({
        ...store,
        photo: userPhoto(store.photo),
        name: store.nome,
        lastName: store.sobrenome,
        profiles: this.loadProfiles(store),
        dependents: this.loadDependents(store),
        funcao_padrao: store.funcao_padrao,
      })),
    );
  }

  private loadProfiles(store: AppState): any[] {
    const HIDDEN = [FunctionsEnum.advisor, FunctionsEnum.supervisorAdvisor];
    const profiles = store.funcoes.filter(({ funcao_id }) => !HIDDEN.includes(funcao_id));
    return profiles.map((profile) => ({
      ...profile,
      name: store.usuario_ativo.pais_id === PaisEnum.Brasil ? profile.funcao : profile.funcao_es,
      entity: head<any>(profile.escola_aluno)?.nome_escola,
    }));
  }

  private loadDependents(store: AppState): any[] {
    if (Checker.isResposible(store.funcao_padrao)) {
      return store.dependentes.map((dependent) => ({
        ...dependent,
        name: dependent.name,
        lastName: dependent.sobrenome,
      }));
    }
    return [];
  }
}
