import { LearningTrailsMenuEnum, PaisEnum } from '@app/core/models';
import { Policies } from '..';
import { RoleFn } from './role';

const general: RoleFn = ({ usuario_ativo }) => usuario_ativo?.pais_id === PaisEnum.Brasil;

const studio: RoleFn = ({ policies, usuario_ativo }, module: LearningTrailsMenuEnum) => {
  switch (module) {
    case LearningTrailsMenuEnum.trails:
      return !!usuario_ativo?.editora_id && Policies.isAllow([18, 53], policies);
    case LearningTrailsMenuEnum.uc:
      return (
        (!!usuario_ativo?.editora_id || !!usuario_ativo?.uniao_id || !!usuario_ativo?.divisao_id) &&
        Policies.isAllow([31, 56], policies)
      );
    default:
      return usuario_ativo?.editora_id === 1 && Policies.isAllow([30, 54], policies);
  }
};

const showcase: RoleFn = ({ policies }, module: LearningTrailsMenuEnum) => {
  switch (module) {
    case LearningTrailsMenuEnum.trails:
      return Policies.isAllow([18, 31], policies);
    case LearningTrailsMenuEnum.uc:
      return Policies.isAllow([31, 57], policies);
    default:
      return Policies.isAllow([30, 59], policies);
  }
};

const course: RoleFn = ({ policies, usuario_ativo }, module: LearningTrailsMenuEnum) => {
  switch (module) {
    case LearningTrailsMenuEnum.trails:
      return true;
    case LearningTrailsMenuEnum.uc:
      return !usuario_ativo?.escola_externa && Policies.isAllow([31, 58], policies);
    default:
      return Policies.isAllow([30, 60], policies);
  }
};

export default { general, studio, showcase, course };
