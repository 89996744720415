import { DialogRef } from '@angular/cdk/dialog';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NOTIFICATION_STYLE, NotificationData, OriginEnum } from '@app/core/models';
import { NotificationHistoryService, WebSocketService } from '@app/core/services';
import { PortalNotificationService } from '@app/core/services/portal-notification.service';
import { notNull, switchDelay } from '@app/shared';
import { first } from 'lodash';
import { BehaviorSubject, Observable, Subject, finalize, startWith, switchMap, tap, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

interface CounterItem {
  label: string;
  class: any;
  origin: OriginEnum;
  key: OriginEnum;
  value: number;
}
@Component({
  templateUrl: './modal-notification.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalNotificationComponent implements OnInit, AfterViewInit, OnDestroy {
  public load$: Observable<boolean>;
  public throttle = 300;
  public scrollDistance = 1;
  public scrollUpDistance = 2;
  public counters: CounterItem[] = [];
  public filtered = false;
  public current: CounterItem;
  public notification$ = new BehaviorSubject<NotificationData[]>([]);
  private nextPage = null;
  private _load$ = new Subject<boolean>();
  private _refresh$ = new Subject<any>();

  constructor(
    private _destroy: DestroyRef,
    private _dialogRef: DialogRef,
    private _cdref: ChangeDetectorRef,
    private _webSocketService: WebSocketService,
    private _portalNotificationService: PortalNotificationService,
    private _notificationHistoryService: NotificationHistoryService,
  ) {}

  public ngOnInit() {
    this.loadData();
    this.load$ = this._load$.asObservable().pipe(switchDelay(), startWith(true));
  }

  public ngAfterViewInit() {
    this.loadListeners();
  }

  public ngOnDestroy() {
    this._load$.complete();
    this._refresh$.complete();
    this._notificationHistoryService.refresh();
  }

  public onAfterRead(notification: NotificationData) {
    if (!notification.isRead) {
      this.updateCounter(notification);
    }
    this._dialogRef.close();
  }

  public onScrollDown() {
    if (!!this.nextPage) {
      this._refresh$.next({ nextPage: this.nextPage, origin: this.current?.origin });
    }
  }

  public onFilter(counter: CounterItem) {
    if (this.current.key === counter.key) {
      return;
    }
    this.current = counter;
    this._load$.next(true);
    this.nextPage = null;
    this.notification$.next([]);
    this._refresh$.next({ origin: counter.origin });
  }

  public styleButton(counter: CounterItem): string {
    if (this.current?.key === counter.key) {
      return counter.class.background;
    }
    return `${counter.class.hover} bg-white`;
  }

  public stylePointer(counter: CounterItem): string {
    if (this.current?.key === counter.key) {
      return 'text-white';
    }
    return counter.class.color;
  }

  public title(counter: CounterItem): string {
    return `notificacoes.origem.${counter.origin || OriginEnum.All}`;
  }

  private loadListeners() {
    this._refresh$
      .pipe(
        startWith({}),
        switchMap((req) =>
          this._portalNotificationService
            .list({ ...req, nextPage: this.nextPage })
            .pipe(finalize(() => this._load$.next(false))),
        ),
        notNull(),
        tap(({ nextPage }) => (this.nextPage = nextPage)),
      )
      .subscribe(({ data }) => this.notification$.next([...this.notification$.value, ...data]));
  }

  private updateCounter(notification: NotificationData) {
    const origin = Object.values(OriginEnum).find((origin) => notification.origin === origin) || OriginEnum.Outras;
    this.counters = this.counters.map((counter) => {
      if (OriginEnum.All === counter.key || counter.key === origin) {
        return { ...counter, value: counter.value - 1 };
      }
      return counter;
    });
  }

  private loadData() {
    this._webSocketService.message$
      .pipe(
        takeUntilDestroyed(this._destroy),
        catchError(() => of({ data: null })),
        tap((res) => this.updateNotification(res?.data)),
        switchMap(() => this._portalNotificationService.counters()),
      )
      .subscribe((counter) => {
        this.counters = Object.entries(counter).map(([key, value]) => ({
          label: `notificacoes.origem.${key}`,
          class: NOTIFICATION_STYLE[key],
          origin: key === OriginEnum.All ? null : (key as OriginEnum),
          key: key as OriginEnum,
          value,
        }));
        this.current ||= first(this.counters);
        this._cdref.markForCheck();
      });
  }

  private updateNotification(notification: NotificationData) {
    if (!!notification && !!this.current) {
      const includes = [notification.origin, OriginEnum.All].includes(this.current.key);
      if (includes) {
        this.notification$.next([notification, ...this.notification$.value]);
      }
    }
  }
}
