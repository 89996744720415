import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'icon',
})
export class IconPipe implements PipeTransform {
  public transform(name: string): string {
    return `assets/icons/${name}.svg`;
  }
}
