import { DIALOG_DATA } from '@angular/cdk/dialog';
import { ChangeDetectionStrategy, Component, DestroyRef, Inject, OnDestroy } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { portalConst } from '@app/config';
import { notNull, safeEmptyList } from '@app/shared';
import { AppSelectors } from '@app/store';
import { AppState } from '@app/store/app.state';
import { Store } from '@ngrx/store';
import { isEqual } from 'lodash';
import { BehaviorSubject, distinctUntilChanged, filter, map, Observable, switchMap, tap } from 'rxjs';
import { AssessmentIndentifierService } from './assessment-indentifier.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-modal-body-assessments-print-settings',
  templateUrl: './modal-body-assessments-print-settings.component.html',
  styleUrls: ['./modal-body-assessments-print-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalBodyAssessmentsPrintSettingsComponent implements OnDestroy {
  public readonly baseUrl = `${portalConst.web.assessments}/prova/${this.data.application_uuid}`;
  public form: FormGroup;
  public identifiers$: Observable<any>;
  public urlParam$ = new BehaviorSubject<string>(undefined);
  public printTypesMargin = [
    {
      id: 'small',
      name: 'Pequena',
      icon: 'assets/icons/icon-page-size-1.svg',
    },
    { id: 'regular', name: 'Normal', icon: 'assets/icons/icon-page-size-2.svg' },
    { id: 'large', name: 'Grande', icon: 'assets/icons/icon-page-size-3.svg' },
  ];

  public printTypesColumns = [
    {
      id: 1,
      name: this._translateService.instant('gestor-avaliacoes.coluna'),
      icon: 'assets/icons/icon-one-column.svg',
    },
    {
      id: 2,
      name: this._translateService.instant('gestor-avaliacoes.colunas'),
      icon: 'assets/icons/icon-two-columns.svg',
    },
  ];

  public printTypesFont = [
    { id: 'arial', name: 'Arial' },
    { id: 'times', name: 'Times New Roman' },
    { id: 'couriernew', name: 'Courier New' },
    { id: 'opendyslexic', name: 'Open Dyslexic' },
    { id: 'calibri', name: 'Calibri' },
  ];

  public fontSizes = [
    { id: '8px', name: 'Pequeno' },
    { id: '11px', name: 'Regular' },
    { id: '14px', name: 'Grande' },
  ];

  constructor(
    @Inject(DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private _assessmentIndentifierService: AssessmentIndentifierService,
    private store: Store<AppState>,
    private _destroyRef: DestroyRef,
    private _translateService: TranslateService,
  ) {
    this.initForm();
    this.loadAssessmentPrint();
  }

  public ngOnDestroy(): void {
    this.urlParam$.complete();
  }

  public getFontClass(fontName: string): string {
    switch (fontName.toLowerCase()) {
      case 'arial':
        return 'font-arial';
      case 'times new roman':
        return 'font-times';
      case 'courier new':
        return 'font-couriernew';
      case 'open dyslexic':
        return 'font-opendyslexic';
      case 'calibri':
        return 'font-calibri';
      default:
        return '';
    }
  }

  public loadAssessmentPrint() {
    this.identifiers$ = this.store.select(AppSelectors.ActiveUser).pipe(
      takeUntilDestroyed(this._destroyRef),
      notNull(),
      switchMap((activeUser) =>
        this._assessmentIndentifierService.index({
          status: 1,
          entidade: { id: activeUser.entidade_id, tipo: 'entidade' },
        }),
      ),
      safeEmptyList(),
      map((data: any) => ({
        header: data.filter((item) => item.tipo === 0),
        footer: data.filter((item) => item.tipo === 1),
      })),
      tap((data) => {
        this.form.get('header_id').setValue(data.header.find((item) => !!item.padrao)?.id);
        this.form.get('footer_id').setValue(data.footer.find((item) => !!item.padrao)?.id);
      }),
    );
  }

  public onPrintQuest() {
    const url = this.urlParam$.value;

    if (this.data.shuffle_questions) {
      for (let i = 1; i <= 4; i++) {
        const urlWithParams = `${url}&modo_rand=${i}`;
        window.open(urlWithParams, '_blank');
      }
    } else {
      window.open(url, '_blank');
    }
  }

  private initForm() {
    this.form = this.formBuilder.group({
      margin: ['regular', Validators.required],
      font_size: ['11px', Validators.required],
      font_family: ['arial', Validators.required],
      columns: [1, Validators.required],
      header_id: undefined,
      footer_id: undefined,
      show_score: false,
      show_subject: false,
      economic: false,
      show_reference: false,
    });

    this.form.valueChanges
      .pipe(
        distinctUntilChanged(isEqual),
        filter(() => this.form.valid),
        map((value) => ({
          ...value,
          show_score: value.show_score ? 1 : 0,
          show_subject: value.show_subject ? 1 : 0,
          economic: value.economic ? 1 : 0,
          show_reference: value.show_reference ? 1 : 0,
        })),
      )
      .subscribe((value) => this.urlParam$.next(this.getUrlParams(value)));
  }

  private getUrlParams(value: any): string {
    const urlParams = Object.keys(value)
      .map((key) => {
        if (value[key] !== undefined && value[key] !== null) {
          return `${encodeURIComponent(key)}=${encodeURIComponent(value[key])}`;
        }
        return '';
      })
      .filter((param) => param !== '')
      .join('&');

    return this.baseUrl + '?' + urlParams;
  }
}
