<app-card class="h-full">
  <div app-card-header>
    <div class="flex flex-row justify-between">
      <h1 class="text-lg font-medium uppercase" translate>geral.calendario</h1>
    </div>
  </div>
  <hr app-divider class="my-5" />
  <div app-card-content class="h-full" *ngIf="{ employee: isEmployee$ | async } as profile">
    <app-calendar [data]="calendar$ | async" (clickDate)="onSelectDate($event, profile.employee)"></app-calendar>
  </div>
</app-card>
