import { Dialog } from '@angular/cdk/dialog';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { AnnouncementService } from '@app/core/services/announcement.service';
import { notNull, safeEmpty } from '@app/shared';
import { AppSelectors } from '@app/store';
import { Store } from '@ngrx/store';
import { isEmpty, orderBy, sortBy } from 'lodash';
import { Subject, filter, switchMap, takeUntil } from 'rxjs';
import { ModalAnnouncementReadComponent } from '../modal-announcement-read/modal-announcement-read.component';

@Component({
  selector: 'app-card-announcement',
  templateUrl: './card-announcement.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardAnnouncementComponent implements OnDestroy {
  private _destroy$ = new Subject<void>();
  public throttle = 300;
  public scrollDistance = 1;
  public scrollUpDistance = 2;
  public currentPage = 1;

  constructor(
    private _dialog: Dialog,
    public _announcementService: AnnouncementService,
    private _store: Store,
    private _cdRef: ChangeDetectorRef,
  ) {
    this.loadAnnouncements();
  }

  public loadAnnouncements() {
    this._store
      .select(AppSelectors.ActiveUser)
      .pipe(
        takeUntil(this._destroy$),
        notNull(),
        filter((user) => !!user.entidade_id),
        switchMap((user) =>
          this._announcementService.getAnnouncements(user.entidade_id, 15, this.currentPage).pipe(safeEmpty()),
        ),
      )
      .subscribe((res) => {
        this.currentPage = res.current_page;
        if (this.currentPage === 1) {
          this._announcementService.announcement$.next([]);
        }
        const currentAnnouncements = this._announcementService.announcement$.value;
        const newAnnouncements = orderBy(sortBy(res.data, 'date').reverse(), ['date'], ['desc']);

        this._announcementService.announcement$.next([...currentAnnouncements, ...newAnnouncements]);
        this._cdRef.markForCheck();
      });
  }

  public isEmpty(data: any[]): boolean {
    return isEmpty(data);
  }

  public ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
    this._announcementService.announcement$.next([]);
  }

  public onReadAnnouncement(announcement: any) {
    this._dialog.open(ModalAnnouncementReadComponent, {
      hasBackdrop: true,
      data: { communication: announcement },
    });
  }

  public onScrollDown() {
    this.currentPage = ++this.currentPage;
    this.loadAnnouncements();
  }
}
