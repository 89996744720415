import { ElementRef, Injectable } from '@angular/core';
import { Popover, PopoverRef, Strategy } from '@app/shared/modules/template/components/popover';
import { PopoverNotificationComponent } from '../components/popover-notification/popover-notification.component';

@Injectable({
  providedIn: 'root',
})
export class PopoverNotificationService {
  constructor(private popover: Popover) {}

  public open(elementRef: ElementRef): PopoverRef<any, PopoverNotificationComponent> {
    return this.popover.open(PopoverNotificationComponent, {
      panelClass: ['border-base-status-warning'],
      positionStrategy: [Strategy.BOTTOM_END],
      elementRef,
    });
  }
}
