import { NgModule } from '@angular/core';
import { TemplateModule } from '../template/template.module';
import { SelectEmployeeComponent } from './select-employee.component';

@NgModule({
  declarations: [SelectEmployeeComponent],
  imports: [TemplateModule],
  exports: [SelectEmployeeComponent],
})
export class SelectEmployeeModule {}
