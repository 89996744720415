import { Injectable } from '@angular/core';
import { portalConst } from '@app/config';
import { Observable, of } from 'rxjs';
import { InvoiceResponse } from '../models/invoice.model';
import { PaymentAutomatic } from '../models/payment-automatic.model';
import { PaymentParams } from '../models/payment.model';
import { ApiService, Resp } from './api.service';

@Injectable({ providedIn: 'root' })
export class PaymentsService {
  private url = portalConst.api.iatec_api;
  private cron = portalConst.api.cron_api;
  private payment = portalConst.api.payment;
  public paymentAutoEnabled$: Observable<boolean>;

  constructor(private api: ApiService) {
    this.paymentAutoEnabled$ = of(true);
  }

  public schoolInstallments(req?: any): Observable<any> {
    return this.api.get<any>(`${this.url}/monthly-payment`, req);
  }

  public pix(req?: any): Observable<any> {
    return this.api.get<any>(`${this.cron}/pagamentos-adems/pix`, req);
  }

  public allowsCard(req: any): Observable<Resp<PaymentParams[]>> {
    return this.api.get<PaymentParams[]>(`${this.url}/schooling/cartao`, req);
  }

  public loadPayment(req?: any): Observable<any> {
    return this.api.get(`${this.url}/entity`, req);
  }

  public sendPayment(req: any): Observable<any> {
    return this.api.post(`${this.url}/create-automatic-payment`, req);
  }

  public newRecurrence(req: any): Observable<any> {
    return this.api.post(`${this.payment}/recorrencias/nova`, req);
  }

  public updateStatusIatecRecurrence(id, req: any): Observable<any> {
    return this.api.put(`${this.payment}/recorrencias/${id}`, req);
  }

  public loadRecurrence(req: any): Observable<PaymentAutomatic> {
    return this.api.list<PaymentAutomatic>(`${this.payment}/recorrencia`, req);
  }

  public deleteRecurrence(id: number): Observable<any> {
    return this.api.delete(`${this.payment}/recorrencias/${id}`);
  }

  public updateRecurrence(req: any): Observable<any> {
    return this.api.put(`${this.payment}/recorrencia`, req);
  }

  public cancelPaymentAutomatic(req: any): Observable<any> {
    return this.api.post(`${this.url}/cancel-automatic-payment`, req);
  }

  public generateToken(req: any): Observable<any> {
    return this.api.post(`${this.payment}/transacao/tokenizar`, req);
  }

  public receipt(req: any): Observable<InvoiceResponse> {
    return this.api.list(`${this.url}/tax-invoices`, req);
  }

  public openOrder(): Observable<any> {
    return this.api.post(`${this.payment}/pedidos/novo`);
  }

  public pay(req: any): Observable<any> {
    return this.api.post(`${this.payment}/transacao/autorizacao`, req);
  }

  public catch(req: any): Observable<any> {
    return this.api.post(`${this.payment}/transacao/captura`, req);
  }

  public registerPaymentIatec(req: any): Observable<any> {
    return this.api.post(`${this.url}/create-payment`, req);
  }

  public registerPaymentPortal(req: any): Observable<any> {
    return this.api.post(`${this.payment}/mensalidade`, req);
  }
}
