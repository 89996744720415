import { Injectable } from '@angular/core';
import { portalConst } from '@app/config';
import { ApiService } from './api.service';
import { CrudService } from './crud.service';
import { Period } from '../models';

@Injectable({
  providedIn: 'root',
})
export class PeriodService extends CrudService<Period> {
  constructor(api: ApiService) {
    super(api, `${portalConst.api.usuario}/v2/bimestres`);
  }
}
