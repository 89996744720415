import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { portalConst } from '@app/config';
import { Content } from '@app/core/models';
import { Page } from '@app/core/services/api.service';
import { SchoolWebsiteService } from '@app/core/services/school-website.service';
import { PAGE_DEFAULT, PageData, notNull, safeEmpty, switchDelay } from '@app/shared';
import { AppSelectors } from '@app/store';
import { Store } from '@ngrx/store';
import { isEqual, startsWith } from 'lodash';
import {
  BehaviorSubject,
  Observable,
  Subject,
  combineLatest,
  distinctUntilChanged,
  map,
  startWith,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs';

@Component({
  templateUrl: './modal-contents.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalContentsComponent implements OnInit, OnDestroy {
  public contents$: Observable<Page<Content>>;
  public load$: Observable<boolean>;
  private _destroy$ = new Subject<void>();
  private _load$ = new Subject<boolean>();
  private _page$ = new BehaviorSubject<any>(PAGE_DEFAULT);

  constructor(
    @Inject(DIALOG_DATA) public data: string,
    public dialogRef: DialogRef<string>,
    private _schoolService: SchoolWebsiteService,
    private _store: Store,
  ) {
    this.load$ = this._load$.asObservable().pipe(switchDelay(), startWith(true));
  }

  public ngOnInit() {
    this.loadData();
  }

  public ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
    this._load$.complete();
    this._page$.complete();
  }

  public onChangePage(data: PageData) {
    this._page$.next(data);
  }

  private loadData() {
    const activeUser$ = this._store.select(AppSelectors.ActiveUser);
    const page$ = this._page$.pipe(
      distinctUntilChanged(isEqual),
      map((page) => ({ page: page.current, qtd: page.size })),
    );
    this.contents$ = combineLatest([activeUser$, page$]).pipe(
      takeUntil(this._destroy$),
      notNull(),
      tap(() => this._load$.next(true)),
      switchMap(([activeUser, page]) =>
        this._schoolService
          .list(activeUser.entidade_id, page)
          .pipe(safeEmpty({ data: [], total: 0, last_page: 1, current: 1 } as any)),
      ),
      map((res: Page<Content>) => ({
        ...res,
        data: res.data.map((item) => ({ ...item, imagem: this.image(item.imagem) })),
      })),
      tap(() => this._load$.next(false)),
    );
  }

  private image(src: string): string {
    const prefix = `${portalConst.aws.s3}/escola/conteudos`;
    return startsWith(src, prefix) ? src : `${prefix}/${src}`;
  }
}
