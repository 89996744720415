<div [ngClass]="{ 'rounded-md border border-red-500 p-1': invalid }">
  <div
    class="dropzone relative flex w-full items-center justify-center"
    [dropzone]="config"
    (error)="onUploadError($event)"
    (success)="onUploadSuccess($event)"
    (queueComplete)="onQueueComplete()"
    (processing)="onProcessing($event)"
    (addedFile)="onAddFile($event)"
  >
    <label
      class="dz-message flex w-full cursor-pointer flex-col items-center justify-around rounded-lg border-[1px] border-dashed border-gray-300 bg-white p-2 hover:bg-gray-100"
      [ngClass]="class"
    >
      <div class="flex w-full flex-col items-center justify-center">
        <div class="dropzone__preview" *ngIf="!!image; else empty">
          <img class="dropzone__img" [src]="image" [src-fallback]="ept" alt="img" />
        </div>
        <ng-template #empty>
          <svg-icon class="w-10 stroke-2 text-gray-500" src="assets/icons/icon-upload.svg"></svg-icon>
          <p class="mb-2 text-base font-semibold text-gray-500" translate>eclass.arraste-arquivos</p>
          <ng-container *ngIf="!!config?.maxFiles">
            <p class="mb-2 text-sm text-gray-500" translate>
              eclass.num-permitido
              <span class="text-sm text-gray-500">{{ config?.maxFiles }}</span>
            </p>
          </ng-container>
          <ng-container *ngIf="!!config?.maxFilesize">
            <p class="mb-2 text-sm text-gray-500" translate>
              eclass.tam-arquivos
              <span class="text-sm text-gray-500">{{ formtaSizeFile(config.maxFilesize) }}</span>
            </p>
          </ng-container>
          <ng-container *ngIf="config?.params?.altura || config?.params?.largura">
            <p class="mb-2 text-sm text-gray-500" translate>
              geral.dimensao
              <span class="text-sm text-gray-500"
                >{{ config.params?.altura || '∞' }} X {{ config.params?.largura || '∞' }}</span
              >
            </p>
          </ng-container>
          <p class="mb-2 text-center text-sm uppercase text-gray-500" style="word-break: break-word">
            {{ config.acceptedFiles }}
          </p>
        </ng-template>
      </div>
    </label>

    <app-skeleton *ngIf="loading$ | async" class="absolute" [showLabel]="false"></app-skeleton>
  </div>
</div>
