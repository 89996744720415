<!-- > -->
<app-modal-header (closeEvent)="onClose()" [automaticClose]="false">
  <div class="flex w-full grid-cols-[90px_auto_1fr_auto_auto] flex-col gap-5 md:grid">
    <div class="flex">
      <svg-icon
        class="w-10 text-theme-500"
        src="assets/icons/icon-gestor-avaliacoes.svg"
        alt="Materiais Didaticos"
      ></svg-icon>
      <h1 class="ml-3 text-sm font-semibold leading-tight text-gray-800" translate>
        gestor-avaliacoes.e-class
        <br />
        <b class="text-lg text-black" translate> gestor-avaliacoes.prova </b>
      </h1>
    </div>

    <hr app-modal-divider />

    <div class="mr-5 flex w-full items-center justify-center text-black md:grid md:w-fit">
      <span class="whitespace-nowrap text-lg" translate> gestor-avaliacoes.auditoria-respostas </span>
    </div>
  </div>
</app-modal-header>
