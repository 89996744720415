<div class="flex h-full w-full flex-col items-center">
  <div class="h-full w-full px-3">
    <div class="absolute z-10 h-[74%]">
      <div class="flex h-full items-center justify-center">
        <svg-icon class="w-8 text-base-post-plan" src="assets/icons/icon-arrow-left-fine.svg"></svg-icon>
      </div>
    </div>
    <div class="absolute right-3 z-10 h-[74%]">
      <div class="flex h-full items-center justify-center">
        <svg-icon class="w-8 text-base-post-plan" src="assets/icons/icon-arrow-right-fine.svg"></svg-icon>
      </div>
    </div>

    <div class="flex w-full snap-x snap-mandatory flex-row gap-3 overflow-x-auto">
      <app-card-carousel
        class="snap-card snap-center"
        *ngFor="let slide of slides; let i = index"
        [index]="i"
        [imageUrl]="slide.url"
      ></app-card-carousel>
    </div>
  </div>

  <!-- indicadores -->
  <div class="flex h-1/6 w-full flex-row items-center justify-center gap-3 bg-white">
    <div *ngFor="let slide of slides" class="h-3 w-3 rounded-full bg-modules-eclass-300/50"></div>
  </div>
</div>
