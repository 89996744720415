export enum ItemGroup {
  Group,
  Subject,
}

export interface AcademicTree {
  counters: any[];
  groups: any[];
  levels: any[];
  subjects: any[];
}
