import { RunActions } from '@app/shared';
import { createAction, props } from '@ngrx/store';

export const ClearStageReducer = createAction('[ModalNewAssessment] ClearStageReducer');

export const InitDataEffect = createAction('[ModalNewAssessment] InitDataEffect', props<ModalNewAssessmentData>());

export const UpdateStateEffect = createAction('[ModalNewAssessment] UpdateStateEffect', props<UpdateStateEffect>());

export const UpdateStateReducer = createAction('[ModalNewAssessment] UpdateStateReducer', props<{ value: any }>());

export const DataLoadedReducer = createAction(
  '[ModalNewAssessment] DataLoadedReducer',
  props<{ isUpdate: boolean; hasChanges: boolean; data: any; extra: any }>(),
);

export const RemoveQuestionEffect = createAction(
  '[ModalAvaliacaoFormulario] RemoveQuestionEffect',
  props<{ uuid: string }>(),
);

export const DuplicateQuestionEffect = createAction(
  '[ModalAvaliacaoFormulario] DuplicateQuestionEffect',
  props<{ uuid: string }>(),
);
export interface UpdateStateEffect {
  value?: any;
  actions?: RunActions;
}

export interface ModalNewAssessmentData {
  aplicacao_uuid?: string;
  avaliacao_uuid?: string;
  extra?: any;
}
