import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-modal-create-response-card',
  templateUrl: './modal-create-response-card.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalCreateResponseCardComponent implements OnInit {
  public printResponseCard = [
    {
      id: 'full',
      name: 'Folha Inteira | 100 Questões',
      icon: 'assets/icons/icon-answer-card-1.svg',
    },
    { id: 'regular', name: 'Meia Folha | 80 Questõesmal', icon: 'assets/icons/icon-answer-card-2.svg' },
  ];

  public form: FormGroup;

  constructor(private formBuilder: FormBuilder) {}

  public ngOnInit() {
    this.initForm();
  }

  private initForm() {
    this.form = this.formBuilder.group({
      select: undefined,
    });
  }
}
