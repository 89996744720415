import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { AssessmentWeightType, Entidade } from '@app/core/models';
import { ReportApiRoutesEnum } from '@app/core/models/report-api-routes.enum';
import { TipoEntidadeEnum } from '@app/core/models/tipo-entidade.enum';
import { AssessmentApplicationService } from '@app/core/services/assessment-application.service';
import { notNull, selectedEntityActive } from '@app/shared';
import { AppSelectors } from '@app/store';
import { AppState } from '@app/store/app.state';
import { Store } from '@ngrx/store';
import { first, isEqual, uniqBy } from 'lodash';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, finalize, map, switchMap } from 'rxjs/operators';

interface StudentNotes {
  nome: string;
  turma: string;
  turma_id: number;
  ra: string;
  serie: string;
  serie_id: number;
  entidadeId: string;
  disciplinas: {
    [disciplina: string]: {
      nome: string;
      notas: number[];
      qtd_questoes: number;
      qtd_acertos: number;
      percentual: number;
      qtd_questoes_geral: number;
    };
  };
  totalNotas: number;
  pesoTipo: AssessmentWeightType;
  percentual_geral: number;
  total_acertos_geral: number;
}

@Component({
  selector: 'app-tab-note',
  templateUrl: './tab-note.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabNoteComponent implements OnInit {
  public notesGrouped$: Observable<StudentNotes[]>;
  public entityActive: Entidade;
  public uniqueDisciplines: any[] = [];
  public singleClasses: string[] = [];
  public entityIdCtrl = new FormControl(undefined);
  public entities$ = new BehaviorSubject<any>([]);
  public searchTerm$ = new BehaviorSubject<string>('');
  public searchTurmaCtrl = new FormControl(null);
  public loading$ = new BehaviorSubject<boolean>(true);
  public assessmentWeightType = AssessmentWeightType;
  public switchNotes = new FormControl(true);
  private _activeUser$ = new BehaviorSubject<any>(undefined);
  private application$ = new BehaviorSubject<string | null>(null);
  private originalStudents: StudentNotes[] = [];
  private filteredStudents$ = new BehaviorSubject<StudentNotes[]>([]);

  constructor(
    private _store: Store<AppState>,
    private _assessmentService: AssessmentApplicationService,
    private _assessmentApplicationService: AssessmentApplicationService,
  ) {
    this.application$
      .pipe(
        switchMap((applicationUuid) => {
          if (!applicationUuid || !this.entityIdCtrl.value) {
            this.loading$.next(false);
            this.filteredStudents$.next([]);
            return of([]);
          }

          this.loading$.next(true);

          return this._assessmentService.getGradesByDiscipline(applicationUuid, this.entityIdCtrl.value).pipe(
            map((response) => {
              const notas = response?.data || [];
              const groupedNotes = this.groupNotesByStudent(notas);

              this.originalStudents = groupedNotes;
              this.filteredStudents$.next(groupedNotes);

              this.uniqueDisciplines = Array.from(
                new Map(
                  groupedNotes
                    .flatMap((s) => Object.values(s.disciplinas))
                    .map((disciplina) => [disciplina.nome, disciplina]),
                ).values(),
              );
              this.singleClasses = Array.from(new Set(groupedNotes.map((s) => s.turma).filter(Boolean))).sort();
              if (this.singleClasses.length > 0) {
                this.searchTurmaCtrl.setValue(this.singleClasses[0], { emitEvent: false });
                this.applyFilters();
              }

              return groupedNotes;
            }),
            catchError(() => of([])),
            finalize(() => this.loading$.next(false)),
          );
        }),
      )
      .subscribe();

    this.entityIdCtrl.valueChanges
      .pipe(
        debounceTime(100),
        switchMap((entidadeId) => {
          if (!this.application$.value || !entidadeId) {
            this.filteredStudents$.next([]);
            return of([]);
          }

          this.loading$.next(true);

          return this._assessmentService.getGradesByDiscipline(this.application$.value, entidadeId).pipe(
            map((response) => {
              const notas = response?.data || [];
              const groupedNotes = this.groupNotesByStudent(notas);

              this.originalStudents = groupedNotes;
              this.applyFilters();

              this.uniqueDisciplines = Array.from(
                new Map(
                  groupedNotes
                    .flatMap((s) => Object.values(s.disciplinas))
                    .map((disciplina) => [disciplina.nome, disciplina]),
                ).values(),
              );

              this.singleClasses = Array.from(new Set(groupedNotes.map((s) => s.turma).filter(Boolean)));

              return groupedNotes;
            }),
            catchError(() => of([])),
            finalize(() => this.loading$.next(false)),
          );
        }),
      )
      .subscribe();

    this.switchNotes.valueChanges.subscribe((value) => value);
    this._store
      .select(AppSelectors.ActiveUser)
      .pipe(notNull(), distinctUntilChanged(isEqual))
      .subscribe((activeUser) => {
        this._activeUser$.next(activeUser);
        this.entityActive = first(selectedEntityActive(activeUser));
        if (this.entityActive.tipo === TipoEntidadeEnum.Escola) {
          this.entityIdCtrl.setValue(this.entityActive.id);
        }
      });
  }

  @Input()
  public set application(value: any) {
    if (value?.uuid) {
      this.application$.next(value.uuid);
      this.entities$.next(
        uniqBy(
          value.publico_alvo.map((target) => target.entidade),
          'id',
        ),
      );
    }
    if (this.entities$.value.length === 1) {
      this.entityIdCtrl.setValue((first(this.entities$.value) as any).id);
    }
  }

  @Input() public set entityId(value) {
    this.entityIdCtrl.setValue(value);
  }

  public ngOnInit() {
    this.notesGrouped$ = this.filteredStudents$.asObservable();

    this.searchTurmaCtrl.valueChanges.pipe(debounceTime(100)).subscribe(() => this.applyFilters());

    this.searchTerm$.pipe(debounceTime(100)).subscribe(() => this.applyFilters());
  }

  public onSearchChange(search: string): void {
    this.searchTerm$.next(search.toLowerCase().trim());
  }

  public onExport() {
    let groupId: number | null = null;
    let serieId: number | null = null;

    if (!!this.searchTurmaCtrl.value) {
      const selectedStudent = this.originalStudents.find((student) => student.turma === this.searchTurmaCtrl.value);

      if (selectedStudent) {
        groupId = selectedStudent.turma_id;
        serieId = selectedStudent.serie_id;
      }
    }

    const data = {
      turma_id: groupId ?? null,
      serie_id: serieId ?? null,
      entity_id: this.entityActive.tipo === TipoEntidadeEnum.Escola ? this.entityActive.id : this.entityIdCtrl.value,
      specific_students: !!this.searchTurmaCtrl.value && !groupId ? 1 : 0,
      acertos: !!this.switchNotes.value ? 1 : 0,
      api_route: ReportApiRoutesEnum.ReportByGrades,
    };

    const applicationUuid = this.application$.getValue();
    if (applicationUuid) {
      this._assessmentApplicationService.reportExport(applicationUuid, data, 'by-grades');
    }
  }

  private applyFilters(): void {
    const selectedEntityId = this.entityIdCtrl.value;
    const searchTurma = this.searchTurmaCtrl.value;
    const searchTerm = this.searchTerm$.getValue();

    let filteredData = [...this.originalStudents];

    if (selectedEntityId) {
      filteredData = filteredData.filter((student) =>
        student.entidadeId ? student.entidadeId === selectedEntityId : false,
      );
    }

    if (searchTurma) {
      filteredData = filteredData.filter((student) => student.turma === searchTurma);
    }

    if (searchTerm) {
      filteredData = filteredData.filter((student) => student.nome.toLowerCase().includes(searchTerm));
    }

    this.filteredStudents$.next(filteredData);
  }

  private groupNotesByStudent(notas: any[]): StudentNotes[] {
    const grouped: { [aluno: string]: StudentNotes } = {};
    notas.forEach(({ user, proficiencia, aplicacao_tentativa, nota_calculada, tentativa_questoes }) => {
      const aluno = `${user?.name} ${user?.sobrenome || ''}`.trim();
      const ra = `${user?.alunos?.entidade_alunos?.[0]?.ra || ''}`.trim();

      // Garante que proficiencia seja um array vazio se vier null
      const proficienciaArray = Array.isArray(proficiencia) ? proficiencia : [];

      const disciplinas = proficienciaArray.map((prof: any) => ({
        id: prof?.padrao_disciplina?.id,
        nome: prof?.padrao_disciplina?.descricao,
      }));

      const notaCalculada = nota_calculada || 0;
      const serie = aplicacao_tentativa?.aplicacao_publico_alvo?.padrao_serie?.descricao;
      const serieId = aplicacao_tentativa?.aplicacao_publico_alvo?.padrao_serie?.id;
      const pesoTipo = aplicacao_tentativa?.aplicacao?.avaliacao?.peso_tipo;
      const turma =
        aplicacao_tentativa?.aplicacao_publico_alvo?.turma?.descricao ??
        aplicacao_tentativa?.aplicacao_publico_alvo?.turma?.cod_turma ??
        user?.alunos?.aluno_turma_regular?.turma?.descricao ??
        user?.alunos?.aluno_turma_regular?.turma?.cod_turma;

      const turmaId =
        aplicacao_tentativa?.aplicacao_publico_alvo?.turma?.id ?? user?.alunos?.aluno_turma_regular?.turma?.id;

      if (!grouped[aluno]) {
        grouped[aluno] = {
          nome: aluno,
          ra,
          turma,
          turma_id: turmaId,
          serie,
          serie_id: serieId,
          disciplinas: {},
          totalNotas: notaCalculada,
          entidadeId: aplicacao_tentativa?.aplicacao_publico_alvo?.entidade_id || '',
          pesoTipo,
          percentual_geral: 0,
          total_acertos_geral: 0,
        };
      }

      disciplinas.forEach(({ id: disciplinaId, nome: disciplinaNome }, index) => {
        const percentual = proficienciaArray[index]?.percentual || 0;

        if (!grouped[aluno].disciplinas[disciplinaNome]) {
          grouped[aluno].disciplinas[disciplinaNome] = {
            nome: disciplinaNome,
            notas: [],
            qtd_questoes: 0,
            qtd_acertos: 0,
            percentual: 0,
            qtd_questoes_geral: 0,
          };
        }

        const tentativas = tentativa_questoes || [];

        grouped[aluno].disciplinas[disciplinaNome].notas.push(percentual);
        grouped[aluno].disciplinas[disciplinaNome].qtd_questoes_geral = tentativas.length;
        grouped[aluno].total_acertos_geral = tentativas.filter((t) => t.alternativa?.alternativa_correta === 1).length;

        grouped[aluno].percentual_geral =
          (grouped[aluno].total_acertos_geral / grouped[aluno].disciplinas[disciplinaNome].qtd_questoes_geral) * 100 ||
          0;

        tentativas.forEach((tentativa: any) => {
          const tentativaDisciplinaId = tentativa.questao?.padrao_disciplina_id;

          if (tentativaDisciplinaId === disciplinaId) {
            grouped[aluno].disciplinas[disciplinaNome].qtd_questoes++;
            if (tentativa.alternativa?.alternativa_correta === 1) {
              grouped[aluno].disciplinas[disciplinaNome].qtd_acertos++;
            }
          }
        });

        grouped[aluno].disciplinas[disciplinaNome].percentual =
          grouped[aluno].disciplinas[disciplinaNome].qtd_questoes > 0
            ? (grouped[aluno].disciplinas[disciplinaNome].qtd_acertos /
                grouped[aluno].disciplinas[disciplinaNome].qtd_questoes) *
              100
            : 0;
      });
    });

    return Object.values(grouped);
  }
}
