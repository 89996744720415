import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { ApiService } from './api.service';
import { portalConst } from '@app/config';

@Injectable({
  providedIn: 'root',
})
export class EntityService {
  constructor(private apiService: ApiService) {}

  public index(data: any): Observable<any> {
    return this.apiService.get(`${portalConst.api.usuario}/v1/list-entity`, data);
  }
}
