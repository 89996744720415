<app-card class="h-full">
  <div app-card-header class="rounded-t-lg bg-modules-learning-trails-500 !py-6">
    <div class="flex w-full flex-row items-center justify-between">
      <h1 class="text-lg uppercase text-white" translate>menu.trilhas-aprendizagem</h1>
      <svg-icon class="flex h-6 w-6 text-white" src="assets/icons/icon-degree.svg"></svg-icon>
    </div>
  </div>

  <div app-card-content class="h-full !px-0">
    <ng-container *ngIf="{ course: course$ | async, load: load$ | async } as data">
      <ng-container *ngIf="!data.load; else loadTemplate">
        <ng-container *ngIf="!(data.course | isEmpty); else emptyTemplate">
          <app-horizontal-scroll class="!absolute bottom-5 h-[calc(100%_-_5rem)] w-full">
            <div class="flex h-full w-fit gap-2 px-5">
              <app-card-course
                *ngFor="let cardCourse of data.course"
                class="h-full cursor-pointer"
                redirect="/trilhas-de-aprendizagem/cursos"
                [course]="cardCourse"
              ></app-card-course>
            </div>
          </app-horizontal-scroll>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
  <ng-template #emptyTemplate>
    <div class="h-full w-full px-5 pt-5">
      <app-jumbotron translate>trilhas.lista-vazia</app-jumbotron>
    </div>
  </ng-template>
  <ng-template #loadTemplate>
    <app-horizontal-scroll class="!absolute bottom-5 h-[calc(100%_-_5rem)] w-full">
      <div class="flex h-full w-fit gap-2 px-5">
        <app-skeleton class="!w-80"></app-skeleton>
        <app-skeleton class="!w-80"></app-skeleton>
        <app-skeleton class="!w-80"></app-skeleton>
      </div>
    </app-horizontal-scroll>
  </ng-template>
</app-card>
