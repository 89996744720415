import { NgModule } from '@angular/core';
import { TemplateModule } from '../template/template.module';
import { SelectMatrixContentsComponent } from './select-matrix-contents.component';

@NgModule({
  declarations: [SelectMatrixContentsComponent],
  imports: [TemplateModule],
  exports: [SelectMatrixContentsComponent],
})
export class SelectMatrixContentsModule {}
