import { Dialog } from '@angular/cdk/dialog';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CourseService } from '@app/core/services/course.service';
import { notNull } from '@app/shared';
import { AppSelectors } from '@app/store';
import { AppState } from '@app/store/app.state';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, take, tap } from 'rxjs';
import { Course } from '../../models';
import { TrailsService } from '../../services';
import { ModalEventsComponent } from '../modal-events/modal-events.component';
import { ModalVoucherComponent } from '../modal-voucher/modal-voucher.component';

@Component({
  selector: 'app-card-course',
  templateUrl: './card-course.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardCourseComponent implements OnInit {
  @Input({ required: true }) public course: Course;
  @Input() public isShowcase = false;
  @Input() public redirect: string;
  @Input() public cardWidth = 80;
  @Input() public openCourseId: number;

  @Output() public openCourse = new EventEmitter<Course>();

  public readonly empty = 'assets/icons/icon-default.svg';
  public formSubmitAttempt: boolean;
  public token$: Observable<string>;
  public activeUser$ = new BehaviorSubject<any[]>([]);

  public voucher = new FormControl(undefined, [
    Validators.required,
    Validators.maxLength(18),
    Validators.minLength(18),
  ]);

  constructor(
    private router: Router,
    private _dialog: Dialog,
    private trailsService: TrailsService,
    private _courseService: CourseService,
    private store: Store<AppState>,
  ) {}

  public ngOnInit(): void {
    this.token$ = this.store.select(AppSelectors.token).pipe(take(1));
    this.store
      .select(AppSelectors.ActiveUser)
      .pipe(
        notNull(),
        tap((activeUser) => this.activeUser$.next(activeUser)),
      )
      .subscribe({});
  }

  public onOpenModal() {
    this._dialog
      .open<boolean>(ModalVoucherComponent, {
        data: { course: this.course },
      })
      .closed.pipe(notNull())
      .subscribe(() => this.onOpenCourse(this.course));
  }

  public onOpenReportTemp(slugCourse: string, token: string): void {
    this.router.navigate([`/${this.getUrlUcIvca()}/relatorio`, slugCourse]);
  }

  public onOpenCourse(course: Course): void {
    if (this.redirect) {
      this.router.navigateByUrl(`/${this.getUrlUcIvca()}/cursos`);
    } else if (!!course?.subdivisoes?.length || !!course?.trilhas?.length) {
      this.openCourse.emit(course);
    } else if (this.isShowcase) {
      this.router.navigate([this.getUrlUcIvca(), 'vitrine', course.slug]);
    } else {
      this.router.navigate([this.getUrlUcIvca(), 'cursos', course.evento?.id, course.slug]);
    }
  }

  public onOpenEvents() {
    return this._dialog
      .open(ModalEventsComponent, {
        width: '95vw',
        height: '95vh',
        data: { course: { ...this.course, selected: true } },
      })
      .closed.subscribe();
  }

  public withdrawCertificate(eventoId: number, cursoSlug: string, token: string) {
    this._courseService.withdrawCertificate(eventoId, cursoSlug, token, this.activeUser$.value);
  }

  private getUrlUcIvca(): string {
    if (this.trailsService.uc$.value) return 'universidade-corporativa';
    if (this.trailsService.ivca$.value) return 'ivca';
    return 'trilhas-de-aprendizagem';
  }
}
