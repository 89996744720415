import { SchoolSubject } from './academic-school-subject.model';
import { PerformanceMetric } from './performance-metric.model';
import { StudentPerformanceMetric } from './student-performance-metric.model';

export interface GroupPerformanceMetric {
  id: number;
  entity_id: number;
  grade_id: number;
  subject_id: number;
  subject?: SchoolSubject;
  description: string;
  index: number;
  performance_metrics: PerformanceMetric[];
  student_performance_metrics: StudentPerformanceMetric[];
}
