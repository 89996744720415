import { DIALOG_DATA } from '@angular/cdk/dialog';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AssessmentType } from '@app/core/models';
import { AssessmentApplicationService } from '@app/core/services/assessment-application.service';
import { notNull, safeEmpty, selectedEntityActive } from '@app/shared';
import { REDUCED } from '@app/shared/modules/text-editor/config';
import { AppSelectors } from '@app/store';
import { AppState } from '@app/store/app.state';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { first, uniqBy } from 'lodash';
import { BehaviorSubject, map, Observable, switchMap, take } from 'rxjs';

@Component({
  selector: 'app-modal-create-response-card',
  templateUrl: './modal-create-response-card.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalCreateResponseCardComponent implements OnDestroy {
  public readonly editorConfigToolbar = REDUCED;
  public loading$ = new BehaviorSubject<boolean>(false);
  public form: FormGroup;
  public groups: any[] = [];
  public cardText: string;
  public filteredStudents = [];
  public responseCardText$: Observable<string> = new BehaviorSubject<any>([]);
  public printResponseCard: any[] = [
    {
      id: 1,
      name: this.translate.instant('gestor-avaliacoes.modelo-cartao-resposta-folha-inteira'),
      icon: 'assets/icons/icon-answer-card-1.svg',
    },
  ];

  public searchCtrl = this.formBuilder.control('');

  constructor(
    @Inject(DIALOG_DATA) public data,
    private translate: TranslateService,
    private formBuilder: FormBuilder,
    private _assessmentApplicationService: AssessmentApplicationService,
    private _store: Store<AppState>,
    private cdRef: ChangeDetectorRef,
  ) {
    this.initForm();

    this._assessmentApplicationService
      .getResponseCardText(this.data.application_uuid)
      .pipe(
        take(1),
        map((response) => response.data || this.translate.instant('gestor-avaliacoes.texto-cartao-resposta')),
      )
      .subscribe((text) => {
        this.form.get('text_instruction').setValue(text);
      });

    if (this.data.dataStudent) {
      this.groups = uniqBy(
        this.data.dataStudent
          .filter((student) => student.turma_id && student.turma_descricao)
          .map((student) => ({
            id: student.turma_id,
            descricao: student.turma_descricao,
          })),
        'id',
      );

      if (this.data.dataStudent.some((student) => !student.turma_descricao)) {
        this.groups.push({
          id: 'alunosEspecificos',
          descricao: this.translate.instant('gestor-avaliacoes.alunos-especificos'),
        });
      }

      this.form.get('turmas')?.setValue(first(this.groups)?.id, { emitEvent: false });

      this.filterStudents('');
    } else {
      this.groups = [];
    }
    if (this.data.modo_aplicacao === AssessmentType.Redacao) {
      this.printResponseCard = [
        {
          id: 3,
          name: this.translate.instant('gestor-avaliacoes.modelo-cartao-resposta-folha-redacao'),
          icon: 'assets/icons/icon-answer-card-2.svg',
        },
      ];
    }
    if (!this.data.dataStudent) {
      this.loading$.next(true);
      this._store
        .select(AppSelectors.ActiveUser)
        .pipe(
          take(1),
          notNull(),
          map((activeUser) => first(selectedEntityActive(activeUser))),
          switchMap((entityActive) =>
            this._assessmentApplicationService
              .getStudent(this.data.application_uuid, { entity_id: entityActive.id })
              .pipe(take(1), safeEmpty()),
          ),
        )
        .subscribe({
          next: (response: any) => {
            this.loading$.next(false);
            if (response?.alunos) {
              this.data.dataStudent = [...response.alunos];

              this.groups = uniqBy(
                this.data.dataStudent
                  .filter((student) => student.turma_id && student.turma_descricao)
                  .map((student) => ({
                    id: student.turma_id,
                    descricao: student.turma_descricao,
                  })),
                'id',
              );

              if (this.data.dataStudent.filter((student) => !student.turma_descricao).length > 0) {
                this.groups.push({
                  id: 'alunosEspecificos',
                  descricao: this.translate.instant('gestor-avaliacoes.alunos-especificos'),
                });
              }

              this.form.get('turmas')?.setValue(first(this.groups)?.id, { emitEvent: false });

              this.filterStudents('');
              this.cdRef.detectChanges();
            }
          },
        });
    }
  }

  public get totalSelectedStudents(): number {
    return this.filteredStudents.filter((aluno) => aluno.isSelected).length > 0
      ? this.filteredStudents.filter((aluno) => aluno.isSelected).length
      : 1;
  }

  public ngOnDestroy(): void {
    this.loading$.complete();
  }

  public formattedNecessidadesEspeciais(necessidades_especiais): string {
    return necessidades_especiais.map((necessidade) => necessidade.descricao).join('\n');
  }

  public selected(aluno: any) {
    aluno.isSelected = !aluno.isSelected;
  }

  public print() {
    this.form.markAllAsTouched();
    if (this.form.invalid) {
      return;
    }

    const selectedStudents = this.filteredStudents.filter((aluno) => aluno.isSelected);

    const data = {
      text_instruction: this.form.value.text_instruction,
      students:
        selectedStudents.length > 0
          ? selectedStudents.map((aluno) =>
              JSON.stringify({
                usuario_id: aluno.usuario_id,
                turma_id: aluno.turma_id,
              }),
            )
          : undefined,
      template: this.form.get('modelo')?.value,
    };

    this._assessmentApplicationService.sendLeafTemplate(this.data.application_uuid, data);
  }

  public markAll(selecionar: boolean) {
    this.filteredStudents = this.filteredStudents.map((aluno) => ({
      ...aluno,
      isSelected: selecionar,
    }));
  }

  private initForm() {
    this.form = this.formBuilder.group({
      turmas: undefined,
      modelo: [1, Validators.required],
      text_instruction: undefined,
      busca: undefined,
    });
    this.filterStudents('');

    this.form.get('turmas')?.valueChanges.subscribe((turmaId) => {
      this.form.get('busca')?.setValue('');
      this.filterStudents('');
    });

    this.form.get('busca')?.valueChanges.subscribe((search) => {
      this.filterStudents(search);
    });
  }

  private filterStudents(search: string) {
    if (!this.data?.dataStudent) {
      this.filteredStudents = [];
      return;
    }

    this.filteredStudents = this.data.dataStudent
      .filter((aluno) =>
        this.form.get('turmas')?.value === 'alunosEspecificos'
          ? !aluno.turma_descricao && aluno.nome.toLowerCase().includes(search.toLowerCase())
          : aluno.turma_id === this.form.get('turmas')?.value &&
            aluno.turma_descricao &&
            aluno.nome.toLowerCase().includes(search.toLowerCase()),
      )
      .map((aluno) => ({
        ...aluno,
        isSelected:
          search.length > 0
            ? this.filteredStudents.find((a) => a.usuario_id === aluno.usuario_id)?.isSelected || false
            : true,
      }));
  }
}
