export interface Period {
  id: number;
  entidade_id: number;
  ano: number;
  tipo_periodo: number;
  bimestre: number;
  descricao: string;
  data_fim: string;
  data_inicio: string;
  data_liberacao: string;
  dias_letivos: number;
  created_at: string;
  updated_at: string;
}
