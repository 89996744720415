import { NgModule } from '@angular/core';
import { TemplateModule } from '../template/template.module';
import { BadgeComponent } from './badge.component';

@NgModule({
  declarations: [BadgeComponent],
  imports: [TemplateModule],
  exports: [BadgeComponent],
})
export class BadgeModule {}
