<app-popover>
  <div app-popover-content>
    <div class="datepicker" *ngIf="value$ | async as date">
      <div class="datepicker__toolbar">
        <span class="datepicker__month">{{ month(date) | translate }}</span>
        <span class="datepicker__year">{{ date | date : 'YYYY' }}</span>
        <button type="button" (click)="onPrevMonth(date)" app-icon-button class="datepicker__navigate-prev">
          <svg-icon class="h-4 w-4 select-none" src="assets/icons/icon-arrow-left-fine.svg"></svg-icon>
        </button>
        <button type="button" (click)="onNextMonth(date)" app-icon-button class="datepicker__navigate-after">
          <svg-icon class="h-4 w-4 select-none" src="assets/icons/icon-arrow-right-fine.svg"></svg-icon>
        </button>
      </div>

      <div class="flex justify-center">
        <div class="datepicker__calendar" [ngClass]="{ weekend: data?.weekend }">
          <ng-container *ngIf="weekDays('geral.dias-semana' | translate) as days">
            <span *ngFor="let day of days" class="datepicker__week" [ngClass]="{ weekend: data.weekend }">
              {{ day }}
            </span>
          </ng-container>
          <div *ngFor="let blank of blankDays$ | async">&nbsp;</div>
          <div
            *ngFor="let day of days$ | async"
            [attr.selected]="isEquals(day, selected)"
            [attr.disabled]="disabled || !betweenPeriod(day)"
            class="datepicker__day"
            [ngClass]="{ weekend: data.weekend }"
            (click)="onSelectDate(day, selected)"
          >
            {{ day.getDate() }}
          </div>
        </div>
        <div *ngIf="data.showTime" class="flex flex-col pl-5">
          <label class="text-xs font-semibold" translate>geral.selecione-um-horario</label>
          <div class="flex">
            <div class="no-scrollbar mt-4 h-44 overflow-y-auto">
              <ng-container *ngFor="let hour of hours">
                <div class="mb-1 w-16 items-center text-center">
                  <button
                    [id]="'h-' + hour"
                    (click)="onSelectHour(hour, selected)"
                    class="h-8 w-8 rounded-full hover:bg-gray-200"
                    [ngClass]="{ 'bg-blue-500/30': selected.getHours() === hour }"
                  >
                    {{ hour | number : '2.0' }}
                  </button>
                </div>
              </ng-container>
            </div>
            <div class="no-scrollbar mt-4 h-44 overflow-y-auto">
              <ng-container *ngFor="let minute of minutes">
                <div class="mb-1 w-16 items-center text-center">
                  <button
                    [id]="'m-' + minute"
                    (click)="onSelectMinute(minute, selected)"
                    class="h-8 w-8 rounded-full hover:bg-gray-200"
                    [ngClass]="{ 'bg-blue-500/30': selected.getMinutes() === minute }"
                  >
                    {{ minute | number : '2.0' }}
                  </button>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="data.showTime" class="flex justify-center">
        <button app-button type="button" [popoverClose]="selected" class="bg-theme-500 text-theme-contrast">
          {{ 'geral.confirmar' | translate }}
        </button>
      </div>
    </div>
  </div>
</app-popover>
