<div app-modal-content class="!h-[96vh] !w-[95vw] !p-0 !pb-8">
  <app-modal-header>
    <div class="flex">
      <div
        class="h-16 w-16 flex-shrink-0 self-start rounded-tl-lg bg-green-700 p-2 text-base-status-warning [box-shadow:0px_2px_4px_0px_rgba(0,_0,_0,_0.15)]"
      >
        <svg-icon src="assets/icons/icon-new-course.svg"></svg-icon>
      </div>
      <hr app-modal-divider />
      <div class="flex items-center gap-2">
        <p class="text-3xl font-medium not-italic leading-[normal]">
          {{ 'trilhas.evento.eventos' | translate }}: {{ data.course.titulo }}
        </p>
      </div>
    </div>
  </app-modal-header>
  <div class="h-[85vh] !p-0">
    <app-sidenav-group>
      <ng-template app-sidenav-start panelWidth="340px" [opened]="true">
        <div class="flex flex-col !rounded-l-3xl p-5">
          <div class="flex flex-col items-start gap-2 self-stretch pb-6 pt-2">
            <img
              class="!mx-2 !w-full !max-w-[95%] !rounded-xl"
              [src]="data.course.thumbnail"
              onerror="src='assets/images/default.png'"
            />
          </div>
          <div translate class="text-lg text-theme-900">
            <span translate class="text-lg font-semibold text-theme-900">trilhas.evento.eventos</span>
            trilhas.evento.agendados
          </div>
          <div class="no-scrollbar mt-5 h-[calc(100%-17rem)] overflow-y-auto pb-60 md:pb-0">
            <div>
              <button
                app-outline-button
                class="mb-2 w-full border-theme-500 text-theme-500 hover:[&:not([disabled])]:bg-theme-500 hover:[&:not([disabled])]:text-theme-contrast"
                type="button"
                (click)="resetForm()"
              >
                <span class="font-medium uppercase" translate>trilhas.evento.cadastrar</span>
              </button>
            </div>
            <ng-container *ngIf="events$ | async as events">
              <div
                *ngFor="let event of events"
                type="button"
                class="mb-2 w-full rounded-xl border px-5 py-2 shadow-md"
                [ngClass]="editId === event.id ? 'border-2 border-theme-500 bg-theme-50' : 'border-2 border-white'"
              >
                <div class="flex items-center justify-between gap-6">
                  <div class="flex w-20 flex-col items-center justify-center rounded-full bg-[#fafafa] shadow-inner">
                    <div class="text-md text-theme-900">{{ getDay(event.data_inicio) }}</div>
                    <div class="text-[8px] md:text-[9px]">{{ getMonth(event.data_inicio) }}</div>
                  </div>
                  <div class="flex w-auto flex-col justify-center">
                    <div>{{ event.titulo }}</div>
                    <div *ngIf="event.data_fim" class="text-theme-400">
                      {{ 'trilhas.evento.data-final' | translate
                      }}<span class="text-theme-400">{{ transformDate(event.data_fim) }}</span>
                    </div>
                    <div class="flex items-center justify-start gap-2">
                      <div class="w-[81px] rounded-full border bg-theme-700 px-1 text-theme-50">
                        <div class="flex items-center justify-between">
                          <p class="w-3/4 border-r border-theme-50 text-center text-sm" translate>
                            trilhas.evento.grupos
                          </p>
                          <p class="w-1/4 pl-1 text-center text-sm">
                            {{ event.grupos.length + event.usuarios.length }}
                          </p>
                        </div>
                      </div>
                      <div class="w-[81px] rounded-full border bg-theme-400 px-1 text-theme-50">
                        <div class="flex items-center justify-between">
                          <p class="w-3/4 border-r border-theme-50 text-center text-sm" translate>
                            trilhas.evento.cursos
                          </p>
                          <p class="w-1/4 pl-1 text-center text-sm">{{ event.cursos.length }}</p>
                        </div>
                      </div>
                      <button
                        app-tooltip
                        (click)="onEdit(event.id)"
                        [tooltip]="'trilhas.evento.editar' | translate"
                        type="button"
                      >
                        <svg-icon
                          class="flex h-8 w-8 p-1 text-theme-500 duration-300 ease-in-out hover:text-theme-950"
                          src="assets/icons/icon-pencil.svg"
                        ></svg-icon>
                      </button>
                      <button
                        app-tooltip
                        (click)="onDelete(event.id, $event)"
                        [tooltip]="'trilhas.evento.excluir' | translate"
                        type="button"
                      >
                        <svg-icon
                          class="flex h-8 w-8 p-1 text-theme-500 duration-300 ease-in-out hover:text-theme-950"
                          src="assets/icons/icon-trash.svg"
                        ></svg-icon>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </ng-template>
      <ng-template app-sidenav-outer>
        <div class="lx:h-[80vh] h-[75vh] overflow-y-auto">
          <div class="flex h-[70vh] !w-full flex-col gap-8 px-6 pt-4">
            <ng-container>
              <form [formGroup]="form" class="flex flex-col md:w-full">
                <app-form-field>
                  <label translate app-label class="!text-md text-theme-900">trilhas.evento.titulo</label>
                  <input app-input formControlName="titulo" placeholder="Pesquise por título do evento" />
                </app-form-field>
                <app-form-field>
                  <label translate app-label class="!text-md text-theme-900">trilhas.evento.professor</label>
                  <input app-input formControlName="autor" [placeholder]="'trilhas.evento.pesquise-por' | translate" />
                </app-form-field>
                <div class="flex items-center justify-between gap-5">
                  <app-form-field class="w-full">
                    <label translate app-label class="!text-md text-theme-900">trilhas.evento.data-inicial</label>
                    <app-input-datepicker formControlName="data_inicio" />
                  </app-form-field>
                  <app-form-field class="w-full">
                    <label translate app-label class="!text-md text-theme-900"
                      >trilhas.evento.data-final-opcional</label
                    >
                    <app-input-datepicker [minDate]="form.value.data_inicio" formControlName="data_fim" />
                  </app-form-field>
                </div>
                <div class="flex items-center justify-between">
                  <div class="flex w-full items-start gap-2">
                    <app-switch formControlName="status" [colorTrue]="'bg-theme-300'"></app-switch>
                    <label app-label class="!p-0 !text-base" translate>trilhas.evento.publicado</label>
                  </div>
                  <div class="flex w-full items-start gap-2">
                    <app-switch formControlName="auto_matricula" [colorTrue]="'bg-theme-300'"></app-switch>
                    <label app-label class="!p-0 !text-base" translate>trilhas.evento.auto-matricula</label>
                  </div>
                </div>
                <div *ngIf="hasCertificate" class="mt-5">
                  <div class="flex flex-col gap-1">
                    <label app-label translate>
                      <div class="flex items-center gap-2">
                        <div translate class="!text-md text-theme-950">trilhas.evento.certificado</div>
                        <div
                          app-tooltip
                          [tooltip]="'trilhas.evento.tooltip-certificado' | translate"
                          class="flex h-[13px] w-[13px] cursor-pointer items-center justify-center rounded-full border border-black"
                        >
                          <span class="text-center text-[10px]">&quest;</span>
                        </div>
                      </div>
                    </label>
                    <div class="flex w-full items-center gap-2">
                      <app-switch formControlName="auto_resgate" [colorTrue]="'bg-theme-300'"></app-switch>
                      <label app-label class="!p-0 !text-md text-theme-950" translate
                        >trilhas.evento.auto-resgate</label
                      >
                    </div>
                  </div>
                  <div class="flex w-full items-center justify-center">
                    <button
                      (click)="onAddCertificate()"
                      translate
                      app-outline-button
                      class="my-8 w-full rounded-lg bg-[#003249] px-5 py-2 text-theme-50"
                      type="button"
                    >
                      trilhas.evento.escolher-certificado
                    </button>
                  </div>
                  <div class="group/section relative w-full rounded-lg border border-dashed border-theme-500 px-2 pt-5">
                    <div>
                      <div class="absolute -top-8 left-5 flex items-center justify-start bg-white py-4">
                        <div translate class="px-4 text-base text-theme-900 md:text-md">
                          <span translate class="text-md font-semibold text-theme-900 md:text-lg"
                            >trilhas.evento.certificado</span
                          >
                        </div>
                      </div>
                    </div>
                    <div class="w-full !overflow-y-auto p-4">
                      <div
                        *ngIf="!!form.value.certificado; else noCertificate"
                        class="flex items-center justify-between rounded-lg p-5 shadow"
                      >
                        <div class="w-44 p-2">
                          <app-preview-certificado [data]="onData()"></app-preview-certificado>
                        </div>
                        <div class="flex flex-col items-center justify-center">
                          <div class="p-2 text-base text-theme-900 md:text-md">
                            {{ form.value.certificado.titulo }}
                          </div>
                          <div
                            *ngIf="form.value.usa_sugestao_certificado"
                            translate
                            class="rounded-full bg-base-status-info-500 px-3 text-sm font-semibold text-theme-50"
                          >
                            trilhas.evento.sugestao-autor
                          </div>
                        </div>
                        <div class="info-right flex w-auto flex-row items-center gap-5">
                          <button
                            app-button
                            app-tooltip
                            [tooltip]="'trilhas.evento.tooltip-visualizar-certificado' | translate"
                            (click)="onOpenModalCertificate(form.value.certificado_id)"
                          >
                            <svg-icon
                              class="w-7 text-green-700"
                              src="assets/icons/icon-eyes.svg"
                              alt="icon-eyes.svg"
                            ></svg-icon>
                          </button>
                          <button
                            *ngIf="!form.value.usa_sugestao_certificado"
                            app-button
                            app-tooltip
                            [tooltip]="'trilhas.evento.tooltip-editar-certificado' | translate"
                            (click)="editCertificate()"
                          >
                            <svg-icon
                              class="w-7 text-yellow-500"
                              src="assets/icons/icon-edit.svg"
                              alt="icon-edit.svg"
                            ></svg-icon>
                          </button>
                          <button
                            app-button
                            app-tooltip
                            [tooltip]="'trilhas.evento.tooltip-remover-certificado' | translate"
                            (click)="removeCertificate()"
                          >
                            <svg-icon
                              class="w-7 text-red-600"
                              src="assets/icons/icon-trash.svg"
                              alt="icon-trash.svg"
                            ></svg-icon>
                          </button>
                        </div>
                      </div>
                      <ng-template #noCertificate>
                        <app-jumbotron translate class="p-5">
                          <ng-container translate *ngIf="true">
                            <p translate class="text-base text-theme-900">trilhas.evento.sem-certificado</p>
                          </ng-container>
                        </app-jumbotron>
                      </ng-template>
                    </div>
                  </div>
                </div>
              </form>
              <hr app-divider />
              <div class="flex max-h-[35vh] flex-col justify-between gap-10 px-6 xl:flex-row xl:gap-5">
                <div
                  class="group/section relative order-2 mt-16 h-full w-full rounded-lg border border-dashed border-theme-500 px-2 pb-4 pt-8 xl:order-1 xl:!w-7/12"
                >
                  <div>
                    <div class="absolute -top-8 left-5 flex items-center justify-start bg-white py-4">
                      <div translate class="px-4 text-base text-theme-900 md:text-md">
                        <span translate class="text-md font-semibold text-theme-900 md:text-lg"
                          >trilhas.evento.adicionar</span
                        >
                        trilhas.evento.grupos-usuario
                      </div>
                    </div>
                    <div
                      class="group absolute -top-5 right-4 flex justify-center opacity-0 duration-100 group-hover/section:opacity-100"
                    >
                      <button
                        translate
                        type="button"
                        app-tooltip
                        [tooltip]="'trilhas.evento.adicionar-publico' | translate"
                        (click)="openSidenav(2)"
                        class="rounded-full bg-[#fafafa] p-2 shadow-md ring-1 ring-theme-500 group-hover/section:bg-theme-800 group-hover:ring-4 group-hover:ring-theme-200"
                      >
                        <svg-icon
                          class="flex h-6 w-6 p-1 text-theme-500 group-hover/section:text-theme-50"
                          src="assets/icons/icon-plus.svg"
                        ></svg-icon>
                      </button>
                    </div>
                  </div>
                  <div class="h-full w-full !overflow-y-auto p-4">
                    <ng-container
                      *ngIf="itemsTargetAudienceSelected$ | async as itemsTargetAudienceSelected; else noTargetAudience"
                    >
                      <div
                        *ngIf="!!itemsTargetAudienceSelected.length; else noTargetAudience"
                        #dataTemplate
                        class="overflow-y-auto"
                      >
                        <div class="w-full">
                          <table app-table class="w-full border-separate border-spacing-0.5 border-spacing-y-0.5">
                            <thead class="text-center">
                              <th
                                translate
                                class="w-2/5 whitespace-nowrap !bg-theme-500 px-4 py-2 text-left !text-base uppercase text-theme-contrast first:rounded-l-lg last:rounded-r-lg [&:is(th)]:bg-theme-500 [&:is(th)]:text-theme-contrast [&:not(th)]:bg-white"
                              >
                                trilhas.evento.grupos-usuario-titulo
                              </th>
                              <th
                                translate
                                class="w-2/5 whitespace-nowrap !bg-theme-500 px-4 py-2 text-left !text-base uppercase text-theme-contrast first:rounded-l-lg last:rounded-r-lg [&:is(th)]:bg-theme-500 [&:is(th)]:text-theme-contrast [&:not(th)]:bg-white"
                              >
                                trilhas.evento.entidade-titulo
                              </th>
                              <th
                                translate
                                app-table-cell
                                class="w-1/12 whitespace-nowrap !bg-theme-500 px-4 py-2 text-center !text-base uppercase text-theme-contrast first:rounded-l-lg last:rounded-r-lg [&:is(th)]:bg-theme-500 [&:is(th)]:text-theme-contrast [&:not(th)]:bg-white"
                              >
                                trilhas.evento.acao-titulo
                              </th>
                            </thead>
                            <tbody>
                              <tr
                                *ngFor="let item of itemsTargetAudienceSelected"
                                class="group odd:!bg-white even:!bg-theme-50"
                              >
                                <td class="border-b border-b-theme-200 !p-4 text-left group-hover:!bg-theme-200">
                                  <p class="text-base">{{ item.nome }}</p>
                                </td>
                                <td
                                  *ngIf="item.entidade"
                                  class="border-b border-b-theme-200 !p-4 text-left group-hover:!bg-theme-200"
                                >
                                  {{ item.entidade }}
                                </td>
                                <td
                                  *ngIf="item.campo"
                                  class="border-b border-b-theme-200 !p-4 text-left group-hover:!bg-theme-200"
                                >
                                  {{ item.campo }}
                                </td>
                                <td
                                  *ngIf="item.uniao"
                                  class="border-b border-b-theme-200 !p-4 text-left group-hover:!bg-theme-200"
                                >
                                  {{ item.uniao }}
                                </td>
                                <td
                                  *ngIf="!item.entidade && !item.campo && !item.uniao"
                                  class="border-b border-b-theme-200 !p-4 text-left group-hover:!bg-theme-200"
                                >
                                  -
                                </td>
                                <td
                                  class="whitespace-nowrap border-b border-b-theme-200 !p-4 text-center group-hover:!bg-theme-200"
                                >
                                  <button app-button type="button" (click)="onToggleSelection(item, false)">
                                    <svg-icon
                                      class="flex h-5 w-5 text-base-status-danger"
                                      src="assets/icons/icon-trash.svg"
                                    ></svg-icon>
                                  </button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </ng-container>
                    <ng-template #noTargetAudience>
                      <app-jumbotron translate class="p-5">
                        <ng-container translate *ngIf="true">
                          <p translate class="text-base text-theme-900">trilhas.evento.sem-eventos</p>
                        </ng-container>
                      </app-jumbotron>
                    </ng-template>
                  </div>
                </div>
                <div class="order-1 h-full w-full xl:order-2 xl:!w-5/12">
                  <div class="-pt-2 flex items-center gap-2 px-4 pb-8">
                    <div translate class="text-lg text-theme-900">
                      <span translate class="text-lg font-semibold text-theme-900">trilhas.evento.cursos</span>
                    </div>
                    <div
                      app-tooltip
                      [tooltip]="'trilhas.evento.tooltip-curso-certificado' | translate"
                      class="flex h-[13px] w-[13px] cursor-pointer items-center justify-center rounded-full border border-black"
                    >
                      <span class="text-center text-[10px]">&quest;</span>
                    </div>
                  </div>
                  <ng-container *ngIf="course$ | async as selectedCourse">
                    <div
                      class="group/section relative h-full w-full rounded-lg border border-dashed border-theme-500 px-2 pt-5"
                    >
                      <div>
                        <div class="absolute -top-8 left-5 flex items-center justify-start bg-white py-4">
                          <div translate class="px-4 text-base text-theme-900 md:text-md">
                            <span translate class="text-md font-semibold text-theme-900 md:text-lg"
                              >trilhas.evento.cursos
                            </span>
                            trilhas.evento.adicionados
                          </div>
                        </div>
                        <div
                          class="group absolute -top-5 right-4 flex justify-center opacity-0 duration-100 group-hover/section:opacity-100"
                        >
                          <button
                            translate
                            type="button"
                            app-tooltip
                            [tooltip]="'trilhas.evento.adicionar-curso' | translate"
                            (click)="openSidenav(1)"
                            class="rounded-full bg-[#fafafa] p-2 shadow-md ring-1 ring-theme-500 group-hover/section:bg-theme-800 group-hover:ring-4 group-hover:ring-theme-200"
                          >
                            <svg-icon
                              class="flex h-6 w-6 p-1 text-theme-500 group-hover/section:text-theme-50"
                              src="assets/icons/icon-plus.svg"
                            ></svg-icon>
                          </button>
                        </div>
                      </div>
                      <div class="h-full w-full !overflow-y-auto p-4">
                        <ng-container *ngIf="itemsCourseSelected$ | async as itemsCourseSelected; else noCourse">
                          <ng-container *ngIf="!!itemsCourseSelected.length; else noCourse">
                            <div
                              *ngFor="let item of itemsCourseSelected"
                              class="group/delete relative my-4 flex h-36 w-full items-center justify-start rounded-lg px-5 shadow hover:bg-[#f6fcfd]"
                              [ngClass]="
                                item.selected && itemsCourseSelected.length > 1
                                  ? 'mb-10 border border-theme-500'
                                  : 'border-none'
                              "
                            >
                              <div class="flex w-full items-center justify-between">
                                <div class="flex items-center justify-start">
                                  <div class="flex w-20 items-center justify-center">
                                    <img
                                      class="h-20 w-20 rounded-full xl:h-14 xl:w-14"
                                      [src]="!!item.thumbnail ? item.thumbnail : 'assets/images/default.png'"
                                      (error)="onImageError($event)"
                                    />
                                  </div>
                                  <div class="flex flex-col items-start justify-center">
                                    <p class="px-4 text-md font-semibold text-theme-900">
                                      {{ item.nome ?? item.titulo }}
                                    </p>
                                    <p class="text-semibold px-4 font-semibold text-theme-500">
                                      {{ item.categoria.nome }}
                                    </p>
                                    <p class="px-4 text-base font-normal text-theme-500">{{ item.colecao.nome }}</p>
                                  </div>
                                </div>
                                <div class="flex items-center justify-end gap-5">
                                  <div *ngIf="hasCertificate && item.emite_certificado">
                                    <div
                                      *ngIf="form.value.certificado_id !== item.sugestao_certificado_id; else sugestao"
                                    >
                                      <button
                                        app-tooltip
                                        [tooltip]="'trilhas.evento.tooltip-usar-certificado' | translate"
                                        class="flex items-center justify-center !rounded-full !border-r-0 focus:!border-gray-200"
                                        (click)="useCertificate(item.certificado_sugerido)"
                                      >
                                        <svg-icon
                                          class="w-8 text-base-status-info"
                                          src="assets/icons/icon-certified.svg"
                                        ></svg-icon>
                                      </button>
                                    </div>
                                    <ng-template #sugestao>
                                      <div>
                                        <button
                                          app-tooltip
                                          [tooltip]="'trilhas.evento.tooltip-certificado-em-uso' | translate"
                                          class="flex items-center justify-center !rounded-full !border-r-0 focus:!border-gray-200"
                                        >
                                          <div class="relative flex items-center justify-center gap-5">
                                            <svg-icon
                                              class="absolute -left-3 top-0 w-5 rounded-full bg-base-status-info p-1 text-base-status-info-50"
                                              src="assets/icons/icon-check.svg"
                                            ></svg-icon>
                                            <svg-icon
                                              class="w-8 text-base-status-info-900"
                                              src="assets/icons/icon-certified.svg"
                                            ></svg-icon>
                                          </div>
                                        </button>
                                      </div>
                                    </ng-template>
                                  </div>
                                  <div
                                    *ngIf="!item.selected"
                                    class="group/manager opacity-0 group-hover/delete:opacity-100"
                                  >
                                    <button
                                      (click)="onManageItem(item)"
                                      app-tooltip
                                      [tooltip]="'trilhas.evento.gerenciar' | translate"
                                      class="flex !h-9 !w-9 items-center justify-center"
                                    >
                                      <svg-icon
                                        class="w-9 text-base-genoa-700 group-hover/manager:text-base-genoa-900"
                                        src="assets/icons/icon-manager.svg"
                                      ></svg-icon>
                                    </button>
                                  </div>
                                  <div *ngIf="!item.selected" class="opacity-0 group-hover/delete:opacity-100">
                                    <button
                                      (click)="onRemoveSelectedItem(item)"
                                      app-input
                                      app-tooltip
                                      [tooltip]="'trilhas.evento.tooltip-remover-curso' | translate"
                                      class="flex !h-10 !w-10 items-center justify-center !rounded-full !border-r-0 focus:!border-gray-200"
                                    >
                                      <svg-icon
                                        class="w-4 text-base-status-danger"
                                        src="assets/icons/icon-delete.svg"
                                      ></svg-icon>
                                    </button>
                                  </div>
                                </div>
                              </div>
                              <div
                                *ngIf="item.selected && itemsCourseSelected.length > 1"
                                class="absolute -bottom-12 -left-3 -right-3 mb-6 h-1 border-t border-theme-300"
                              ></div>
                            </div>
                          </ng-container>
                        </ng-container>
                        <ng-template #noCourse>
                          <app-jumbotron translate class="p-5">
                            <ng-container translate *ngIf="true">
                              <p translate class="text-base text-theme-900">trilhas.evento.sem-cursos</p>
                            </ng-container>
                          </app-jumbotron>
                        </ng-template>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
        <div class="flex justify-end gap-5 pr-12 pt-5">
          <button
            app-outline-button
            class="!h-10 !w-48 border-base-status-danger text-base-status-danger hover:[&:not([disabled])]:bg-base-status-danger hover:[&:not([disabled])]:text-theme-contrast"
            type="button"
            (click)="onClose()"
          >
            <span class="font-medium uppercase" translate>trilhas.estudio.curso.cancelar</span>
          </button>
          <button
            app-outline-button
            class="!h-10 !w-48 border-theme-500 text-theme-500 hover:[&:not([disabled])]:bg-theme-500 hover:[&:not([disabled])]:text-theme-contrast"
            type="button"
            (click)="onSubmit()"
          >
            <!-- <ng-container>
              <app-skeleton class="!h-5 !w-5" baseStyle="bg-theme-500" [showLabel]="false"></app-skeleton>
              </ng-container> -->
            <span class="font-medium uppercase" translate>trilhas.estudio.curso.salvar</span>
          </button>
        </div>
      </ng-template>
      <div *ngIf="opened$ | async">
        <ng-template app-sidenav-end panelWidth="375px" [opened]="true" [mode]="1" class="!overflow-y-hidden">
          <div *ngIf="activeTab === 2; else cursos" class="w-full p-6">
            <ng-container>
              <div class="pb-2 pt-4">
                <span translate class="text-lg text-theme-900"
                  ><b translate class="text-lg font-semibold text-theme-900">trilhas.evento.adicionar</b>
                  trilhas.evento.grupos-usuario-sidenav</span
                >
              </div>
              <div
                class="relative -ml-7 -mr-7 mt-8 flex items-center justify-center rounded-xl border border-solid border-theme-500"
              >
                <div class="absolute -top-6 z-9999 rounded-full border-theme-500">
                  <button
                    translate
                    app-outline-button
                    (click)="onSetSubTab(subTabEnum.Group)"
                    [ngClass]="{ '!bg-theme-500 text-theme-50': activeSubTab$.value === subTabEnum.Group }"
                    class="!rounded-full !rounded-r-none bg-white px-4 py-2 hover:bg-theme-500 hover:text-theme-50"
                  >
                    trilhas.evento.grupos
                  </button>
                  <button
                    translate
                    app-outline-button
                    (click)="onSetSubTab(subTabEnum.User)"
                    [ngClass]="{ '!bg-theme-500 text-theme-50': activeSubTab$.value === subTabEnum.User }"
                    class="!rounded-l-none !rounded-r-none bg-white px-4 py-2 hover:bg-theme-500 hover:text-theme-50"
                  >
                    trilhas.evento.usuarios
                  </button>
                  <button
                    translate
                    app-outline-button
                    (click)="onSetSubTab(subTabEnum.Function)"
                    [ngClass]="{ '!bg-theme-500 text-theme-50': activeSubTab$.value === subTabEnum.Function }"
                    class="!rounded-l-none !rounded-r-none bg-white px-4 py-2 hover:bg-theme-500 hover:text-theme-50"
                  >
                    trilhas.evento.funcoes
                  </button>
                  <button
                    translate
                    app-outline-button
                    (click)="onSetSubTab(subTabEnum.Importation)"
                    [ngClass]="{ '!bg-theme-500 text-theme-50': activeSubTab$.value === subTabEnum.Importation }"
                    class="!rounded-full !rounded-l-none bg-white px-4 py-2 hover:bg-theme-500 hover:text-theme-50"
                  >
                    trilhas.evento.importacao
                  </button>
                </div>
              </div>
              <div class="mt-10">
                <form [formGroup]="form" class="flex w-full flex-col items-start justify-center gap-4">
                  <app-form-field *ngIf="activeSubTab$.value !== subTabEnum.Importation" class="w-full">
                    <label translate app-label class="!text-md text-theme-900">trilhas.evento.unidade</label>
                    <ng-select
                      app-select
                      [loading]="loading.units$ | async"
                      [placeholder]="'trilhas.evento.selecione-uma-unidade' | translate"
                      (change)="onUnitSelected()"
                      [items]="units$ | async"
                      bindLabel="nome"
                      [formControl]="selectUnit"
                    >
                    </ng-select>
                  </app-form-field>
                  <app-form-field
                    class="w-full"
                    *ngIf="activeSubTab$.value === subTabEnum.Group || activeSubTab$.value === subTabEnum.User"
                  >
                    <label translate app-label class="!text-md text-theme-900">trilhas.evento.tipo-funcao</label>
                    <ng-select
                      app-select
                      [loading]="loading.functions$ | async"
                      [placeholder]="'trilhas.evento.selecione-uma-função' | translate"
                      (change)="onFunctionSelected()"
                      [items]="functions$ | async"
                      [bindLabel]="label$ | async"
                      [formControl]="selectFunction"
                    >
                    </ng-select>
                  </app-form-field>
                  <app-form-field class="w-full" *ngIf="activeSubTab$.value === subTabEnum.User">
                    <label translate app-label class="!text-md text-theme-900">trilhas.evento.grupo</label>
                    <ng-select
                      app-select
                      [loading]="loading.groups$ | async"
                      [placeholder]="'trilhas.evento.selecione-grupo' | translate"
                      (change)="loadUsers()"
                      [items]="groups$ | async"
                      bindLabel="nome"
                      [formControl]="selectGroup"
                    >
                    </ng-select>
                  </app-form-field>
                  <app-form-field class="w-full" *ngIf="activeSubTab$.value === subTabEnum.Function">
                    <label translate app-label class="!text-md text-theme-900">trilhas.evento.funcao</label>
                    <ng-select
                      app-select
                      [loading]="loading.functionsByType$ | async"
                      [placeholder]="'trilhas.evento.selecione-função' | translate"
                      (change)="loadUsersByFunction()"
                      [items]="functionsByType$ | async"
                      bindLabel="descricao"
                      [formControl]="selectFunctionByType"
                    >
                    </ng-select>
                  </app-form-field>
                  <app-form-field class="w-full" *ngIf="activeSubTab$.value === subTabEnum.Importation">
                    <label translate app-label class="!text-md text-theme-900">trilhas.evento.grupo-eventos</label>
                    <ng-select
                      app-select
                      [placeholder]="'trilhas.evento.selecione-evento' | translate"
                      (change)="onEventSelected($event, true)"
                      [items]="eventsImportation$ | async"
                      bindValue="id"
                      bindLabel="titulo"
                    ></ng-select>
                  </app-form-field>
                  <app-form-field class="w-full" *ngIf="activeSubTab$.value === subTabEnum.Importation">
                    <label translate app-label class="!text-md text-theme-900">trilhas.evento.migrar</label>
                    <ng-select
                      app-select
                      [placeholder]="'trilhas.evento.selecione-evento' | translate"
                      (change)="onEventSelected($event, false)"
                      [items]="eventsMigrate$ | async"
                      bindValue="id"
                      bindLabel="titulo"
                    ></ng-select>
                  </app-form-field>
                </form>
              </div>
              <div
                *ngIf="showList(); else listEmpty"
                class="group/section relative mt-10 flex w-full flex-col items-center justify-start rounded-lg border border-dashed border-theme-500 p-5"
              >
                <div>
                  <div
                    class="absolute left-5 flex items-center justify-start bg-white"
                    [ngClass]="{
                      '-top-6': activeSubTab$.value !== subTabEnum.Function,
                      '-top-8': activeSubTab$.value === subTabEnum.Function
                    }"
                  >
                    <div translate class="px-2 text-md text-theme-900" *ngIf="activeSubTab$.value === subTabEnum.Group">
                      <span translate class="text-md font-semibold text-theme-900">trilhas.evento.listagem</span>
                      trilhas.evento.dos-grupos
                    </div>
                    <div translate class="px-2 text-md text-theme-900" *ngIf="activeSubTab$.value === subTabEnum.User">
                      <span translate class="text-md font-semibold text-theme-900">trilhas.evento.listagem</span>
                      trilhas.evento.dos-usuarios
                    </div>
                    <div
                      translate
                      class="max-w-[150px] px-2 text-center text-md text-theme-900"
                      *ngIf="activeSubTab$.value === subTabEnum.Function"
                    >
                      <span translate class="text-md font-semibold text-theme-900">trilhas.evento.listagem</span>
                      trilhas.evento.dos-usuarios-funcao
                    </div>
                    <div class="flex">
                      <button
                        app-input
                        [ngClass]="{ '!rounded-r-none': showInput }"
                        class="flex !h-10 !w-10 items-center justify-center !rounded-full !border-r-0 focus:!border-gray-200"
                        (click)="onToggleInput()"
                      >
                        <svg-icon class="w-4 text-theme-500" src="assets/icons/icon-search.svg"></svg-icon>
                      </button>
                    </div>
                    <div class="rounded-full rounded-l-none" [ngClass]="{ hidden: !showInput }">
                      <input
                        app-input
                        class="block !h-10 !w-full !rounded-full !rounded-l-none !border-l-0 !leading-4 focus:!border-gray-200"
                        type="text"
                        [placeholder]="'trilhas.evento.pesquisar' | translate"
                        [formControl]="searchCtrl"
                      />
                    </div>
                  </div>
                </div>
                <div class="max-h-[35rem] w-full !overflow-y-auto p-2">
                  <ng-container *ngIf="activeSubTab$.value === subTabEnum.Group">
                    <ng-container *ngIf="filteredGroups$ | async as filteredGroups">
                      <ng-container
                        *ngTemplateOutlet="
                          buttonTemplate;
                          context: { filteredItems: filteredGroups, noItemsTemplate: noGroups }
                        "
                      ></ng-container>
                      <ng-template #noGroups>
                        <app-jumbotron translate>
                          <div class="p-5">
                            <p translate class="text-base text-theme-900">trilhas.evento.no-groups</p>
                          </div>
                        </app-jumbotron>
                      </ng-template>
                      <div *ngIf="filteredGroups.length > 0">
                        <ng-container *ngIf="filteredGroups$ | async as filteredGroups">
                          <div
                            *ngFor="let group of filteredGroups"
                            class="my-1 flex w-full items-center justify-start rounded-md p-3 shadow-inner hover:bg-[#fcfcfd]"
                          >
                            <input
                              app-checkbox
                              class="text-theme-900"
                              type="checkbox"
                              (click)="onToggleGroupSelection(group, false, $event)"
                              [checked]="onIsGroupInSelectedItems(group)"
                            />
                            <label class="ml-4 cursor-pointer text-md text-theme-900">{{ group.nome }}</label>
                          </div>
                        </ng-container>
                      </div>
                    </ng-container>
                  </ng-container>
                  <ng-container
                    *ngIf="activeSubTab$.value === subTabEnum.User || activeSubTab$.value === subTabEnum.Function"
                  >
                    <ng-container *ngIf="filteredUsers$ | async as filteredUsers">
                      <ng-container
                        *ngTemplateOutlet="
                          buttonTemplate;
                          context: { filteredItems: filteredUsers, noItemsTemplate: noUser }
                        "
                      ></ng-container>
                      <ng-template #noUser>
                        <app-jumbotron translate>
                          <div class="p-5">
                            <p translate class="text-base text-theme-900">trilhas.evento.no-user</p>
                          </div>
                        </app-jumbotron>
                      </ng-template>
                      <div *ngIf="filteredUsers.length > 0">
                        <div
                          *ngFor="let user of filteredUsers"
                          class="my-1 flex w-full items-center justify-start rounded-md p-3 shadow-inner hover:bg-[#fcfcfd]"
                        >
                          <input
                            app-checkbox
                            class="text-theme-900"
                            type="checkbox"
                            (click)="onTogglUserSelection(user, false, $event)"
                            [checked]="onIsUserInSelectedItems(user)"
                          />
                          <label class="ml-4 cursor-pointer text-md text-theme-900">{{ user.nome }}</label>
                        </div>
                      </div>
                    </ng-container>
                  </ng-container>
                  <ng-template #buttonTemplate let-filteredItems="filteredItems" let-noItemsTemplate="noItemsTemplate">
                    <div
                      *ngIf="filteredItems.length > 0; else noItemsTemplate"
                      class="mb-5 mt-2 flex items-center justify-start"
                    >
                      <button
                        *ngIf="!isMarked(activeSubTab$.value)"
                        (click)="onMarckAll(activeSubTab$.value)"
                        type="button"
                        app-icon-button
                        translate
                        class="group border border-theme-800 text-theme-800 shadow-md hover:bg-theme-800 hover:text-theme-contrast"
                      >
                        trilhas.evento.marcar-todos
                      </button>
                      <button
                        *ngIf="isMarked(activeSubTab$.value)"
                        (click)="onUnmarckAll(activeSubTab$.value)"
                        type="button"
                        app-icon-button
                        translate
                        class="group border border-base-status-danger text-base-status-danger shadow-md hover:bg-base-status-danger hover:text-theme-contrast"
                      >
                        trilhas.evento.desmarcar-todos
                      </button>
                    </div>
                  </ng-template>
                </div>
              </div>
              <ng-template #listEmpty>
                <div
                  *ngIf="activeSubTab$.value !== subTabEnum.Importation"
                  class="group/section relative mt-10 flex w-full flex-col items-center justify-start rounded-lg border border-dashed border-theme-500 p-5"
                >
                  <div
                    class="absolute left-5 flex items-center justify-start bg-white"
                    [ngClass]="{
                      '-top-6': activeSubTab$.value !== subTabEnum.Function,
                      '-top-8': activeSubTab$.value === subTabEnum.Function
                    }"
                  >
                    <div translate class="px-2 text-md text-theme-900" *ngIf="activeSubTab$.value === subTabEnum.Group">
                      <span translate class="text-md font-semibold text-theme-900">trilhas.evento.listagem</span>
                      trilhas.evento.dos-grupos
                    </div>
                    <div translate class="px-2 text-md text-theme-900" *ngIf="activeSubTab$.value === subTabEnum.User">
                      <span translate class="text-md font-semibold text-theme-900">trilhas.evento.listagem</span>
                      trilhas.evento.dos-usuarios
                    </div>
                    <div
                      translate
                      class="max-w-[150px] px-2 text-center text-md text-theme-900"
                      *ngIf="activeSubTab$.value === subTabEnum.Function"
                    >
                      <span translate class="text-md font-semibold text-theme-900">trilhas.evento.listagem</span>
                      trilhas.evento.dos-usuarios-funcao
                    </div>
                    <div class="flex">
                      <button
                        app-input
                        [ngClass]="{ '!rounded-r-none': showInput }"
                        class="flex !h-10 !w-10 items-center justify-center !rounded-full !border-r-0 focus:!border-gray-200"
                        (click)="onToggleInput()"
                      >
                        <svg-icon class="w-4 text-theme-500" src="assets/icons/icon-search.svg"></svg-icon>
                      </button>
                    </div>
                    <div class="rounded-full rounded-l-none" [ngClass]="{ hidden: !showInput }">
                      <input
                        app-input
                        class="block !h-10 !w-full !rounded-full !rounded-l-none !border-l-0 !leading-4 focus:!border-gray-200"
                        type="text"
                        [placeholder]="'trilhas.evento.pesquisar' | translate"
                        [formControl]="searchCtrl"
                      />
                    </div>
                  </div>
                  <div
                    *ngIf="
                      activeSubTab$.value === subTabEnum.Group ||
                      activeSubTab$.value === subTabEnum.User ||
                      activeSubTab$.value === subTabEnum.Function
                    "
                    class="max-h-[35rem] w-full !overflow-y-auto p-2"
                  >
                    <div *ngIf="shouldShowSkeleton(activeSubTab$.value); else jumbotron" class="h-44 w-full py-5">
                      <app-skeleton></app-skeleton>
                    </div>
                    <ng-template #jumbotron>
                      <app-jumbotron translate class="p-2">
                        <ng-container translate>
                          <p translate class="text-base text-theme-900">trilhas.evento.campo-filtro</p>
                        </ng-container>
                      </app-jumbotron>
                    </ng-template>
                  </div>
                </div>
              </ng-template>
            </ng-container>
          </div>
          <ng-template #cursos>
            <div class="w-full p-6">
              <div translate class="text-lg text-theme-900">
                <span translate class="text-lg font-semibold text-theme-900">trilhas.evento.adicionar</span>
                trilhas.evento.curso
              </div>
              <div class="mt-10 flex flex-col items-start justify-center gap-5">
                <div class="w-full">
                  <label translate app-label class="!text-md text-theme-900">trilhas.evento.categoria</label>
                  <ng-select
                    [loading]="loading.categories$ | async"
                    app-select
                    [placeholder]="'trilhas.estudio.curso.selecione-categoria' | translate"
                    [items]="categories$ | async"
                    [formControl]="selectCategory"
                    bindValue="id"
                    bindLabel="nome"
                  >
                  </ng-select>
                </div>
                <div class="w-full">
                  <label translate app-label class="!text-md text-theme-900">trilhas.evento.colecao</label>
                  <ng-select
                    [loading]="loading.collections$ | async"
                    app-select
                    [placeholder]="'trilhas.estudio.curso.selecione-colecao' | translate"
                    [items]="collections$ | async"
                    [formControl]="selectCollection"
                    bindValue="id"
                    bindLabel="nome"
                  >
                  </ng-select>
                </div>
              </div>
              <div
                class="group/section relative mt-10 flex w-full flex-col items-center justify-start rounded-lg border border-dashed border-theme-500 p-5"
              >
                <div>
                  <div class="absolute -top-6 left-5 flex items-center justify-start bg-white">
                    <div translate class="px-2 text-md text-theme-900">
                      <span translate class="text-md font-semibold text-theme-900">trilhas.evento.listagem</span>
                      trilhas.evento.dos-cursos
                    </div>
                    <div class="flex">
                      <button
                        app-input
                        [ngClass]="{ '!rounded-r-none': showInput }"
                        class="flex !h-10 !w-10 items-center justify-center !rounded-full !border-r-0 focus:!border-gray-200"
                        (click)="onToggleInput()"
                      >
                        <svg-icon class="w-4 text-theme-500" src="assets/icons/icon-search.svg"></svg-icon>
                      </button>
                    </div>
                    <div class="rounded-full rounded-l-none" [ngClass]="{ hidden: !showInput }">
                      <input
                        app-input
                        class="block !h-10 !w-48 !rounded-full !rounded-l-none !border-l-0 !leading-4 focus:!border-gray-200"
                        type="text"
                        [placeholder]="'trilhas.evento.pesquisar-curso' | translate"
                        [formControl]="searchCtrl"
                      />
                    </div>
                  </div>
                </div>
                <ng-container *ngIf="courses$ | async as courses">
                  <ng-container *ngIf="selectCategory.value && selectCollection.value; else noCourses">
                    <ng-container *ngIf="filteredCourses$ | async as filteredCourses">
                      <div class="max-h-[40rem] w-full !overflow-y-auto p-2">
                        <ng-container *ngIf="filteredCourses.length > 0; else noResults">
                          <div
                            *ngFor="let course of filteredCourses"
                            class="group/check my-1 flex h-24 w-full items-center justify-start rounded-md p-3 shadow-inner hover:bg-[#fcfcfd]"
                          >
                            <div class="flex w-full items-center justify-between">
                              <div class="flex items-center justify-start">
                                <img
                                  class="w-16 rounded-full"
                                  [src]="!!course.nome ? course.nome : 'assets/images/default.png'"
                                />
                                <p class="md:text-[14px]font-medium pl-4 text-center text-[11px]">{{ course.nome }}</p>
                              </div>
                              <div *ngIf="!onIsCourseInSelectedCourses(course)" class="hidden group-hover/check:block">
                                <button
                                  (click)="onAddToSelectedCourses(course)"
                                  app-input
                                  class="flex !h-10 !w-10 items-center justify-center !rounded-full !border-r-0 focus:!border-gray-200"
                                >
                                  <svg-icon
                                    class="w-4 text-base-status-danger"
                                    src="assets/icons/icon-plus.svg"
                                  ></svg-icon>
                                </button>
                              </div>
                              <div *ngIf="onIsCourseInSelectedCourses(course)" class="group-hover/check:hidden">
                                <button
                                  app-input
                                  class="flex !h-10 !w-10 items-center justify-center !rounded-full !border-r-0 focus:!border-gray-200"
                                >
                                  <svg-icon class="w-4 text-theme-500" src="assets/icons/icon-check.svg"></svg-icon>
                                </button>
                              </div>
                              <div *ngIf="onIsCourseInSelectedCourses(course)" class="hidden group-hover/check:block">
                                <button
                                  (click)="onRemoveSelectedItem(course)"
                                  app-input
                                  class="flex !h-10 !w-10 items-center justify-center !rounded-full !border-r-0 focus:!border-gray-200"
                                >
                                  <svg-icon
                                    class="w-4 text-base-status-danger"
                                    src="assets/icons/icon-delete.svg"
                                  ></svg-icon>
                                </button>
                              </div>
                            </div>
                          </div>
                        </ng-container>
                      </div>
                    </ng-container>
                    <div *ngIf="!selectCategory.value || !selectCollection.value">
                      <ng-container *ngFor="let course of courses">
                        <div
                          *ngIf="onIsCourseInSelectedCourses(course)"
                          class="group/check my-1 flex h-24 w-full items-center justify-start rounded-md p-3 shadow-inner hover:bg-[#fcfcfd]"
                        >
                          <div class="flex w-full items-center justify-between">
                            <div class="flex items-center justify-start">
                              <img
                                class="w-16 rounded-full"
                                [src]="!!course.nome ? course.nome : 'assets/images/default.png'"
                              />
                              <p class="md:text-[14px]font-medium pl-4 text-center text-[11px]">{{ course.nome }}</p>
                            </div>
                            <div *ngIf="onIsCourseInSelectedCourses(course)" class="group-hover/check:hidden">
                              <button
                                app-input
                                class="flex !h-10 !w-10 items-center justify-center !rounded-full !border-r-0 focus:!border-gray-200"
                              >
                                <svg-icon class="w-4 text-theme-500" src="assets/icons/icon-check.svg"></svg-icon>
                              </button>
                            </div>
                            <div *ngIf="onIsCourseInSelectedCourses(course)" class="hidden group-hover/check:block">
                              <button
                                (click)="onRemoveSelectedItem(course)"
                                app-input
                                class="flex !h-10 !w-10 items-center justify-center !rounded-full !border-r-0 focus:!border-gray-200"
                              >
                                <svg-icon
                                  class="w-4 text-base-status-danger"
                                  src="assets/icons/icon-delete.svg"
                                ></svg-icon>
                              </button>
                            </div>
                          </div>
                        </div>
                      </ng-container>
                    </div>
                  </ng-container>
                </ng-container>
                <ng-template #noResults>
                  <div
                    *ngIf="selectCollection.value && !searchCtrl.value; else jumbotronCursos"
                    class="h-44 w-full py-5"
                  >
                    <app-skeleton></app-skeleton>
                  </div>
                  <ng-template #jumbotronCursos>
                    <app-jumbotron translate class="p-5">
                      <ng-container translate *ngIf="true">
                        <p translate class="text-base text-theme-900">trilhas.evento.no-courses</p>
                      </ng-container>
                    </app-jumbotron>
                  </ng-template>
                </ng-template>
                <ng-template #noCourses>
                  <app-jumbotron translate class="p-5">
                    <ng-container translate *ngIf="true">
                      <p translate class="text-base text-theme-900">trilhas.evento.campo-filtro</p>
                    </ng-container>
                  </app-jumbotron>
                </ng-template>
              </div>
            </div>
          </ng-template>
        </ng-template>
      </div>
    </app-sidenav-group>
  </div>
</div>
