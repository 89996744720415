import { Injectable } from '@angular/core';
import { portalConst } from '@app/config';
import { Observable } from 'rxjs';
import { Games } from '../models/games.model';
import { ApiService, Resp } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class GamesService {
  private readonly _url = portalConst.api.jogos;

  constructor(private api: ApiService) {}

  public discipline(data: any): Observable<any[]> {
    return this.api.list<any[]>(`${this._url}/discipline`, data);
  }

  public series(req?: any): Observable<any[]> {
    return this.api.list<any[]>(`${this._url}/serie`, req);
  }

  public bimonths(): Observable<any[]> {
    return this.api.list<any[]>(`${this._url}/bimonth`);
  }

  public gamesForClass(req: any): Observable<any> {
    return this.api.get<any>(`${this._url}/gameLesson`, req);
  }

  public games(req: any): Observable<Resp<Games[]>> {
    return this.api.get<Games[]>(`${this._url}/games`, req);
  }
}
