import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppSelectors } from '@app/store';
import { AppState } from '@app/store/app.state';
import { Store } from '@ngrx/store';
import { isNil } from 'lodash';
import { exhaustMap, map } from 'rxjs';

const headersKey = {
  entidade: 'entity',
  campo: 'association',
  uniao: 'union',
  editora: 'publisher',
  divisao: 'division',
};
@Injectable({ providedIn: 'root' })
export class EntityHeaderInterceptor implements HttpInterceptor {
  constructor(private store: Store<AppState>) {}

  public intercept(req: HttpRequest<any>, next: HttpHandler) {
    return this.store.select(AppSelectors.entityKey).pipe(
      map((entityKey) => {
        let headers = req.headers;
        if (entityKey) {
          const identifiers = Object.entries<string>(entityKey);
          for (const [key, value] of identifiers) {
            if (!isNil(value)) {
              headers = headers.append(`X-portal-${headersKey[key.slice(0, -3)]}`, String(value));
            }
          }
        }
        return headers;
      }),
      map((headers) => req.clone({ headers })),
      exhaustMap((custom) => next.handle(custom)),
    );
  }
}
