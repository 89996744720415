import { Entidade } from '@app/core/models/entidade.model';
import { Checker } from '@app/shared/utils';
import { flattenDeep, isEmpty, sortBy, uniqBy } from 'lodash';

export function selectedEntity(entities) {
  const array = [];
  entities.forEach((entity: any) => {
    if (entity.entidade_id) {
      array.push({ id: entity.entidade_id, nome: entity.entidade?.nome, tipo: 1 });
    } else if (entity.campo_id) {
      array.push({ id: entity.campo_id, nome: entity.campo?.nome, tipo: 2 });
    } else if (entity.uniao_id) {
      array.push({ id: entity.uniao_id, nome: entity.uniao?.nome, tipo: 3 });
    } else if (entity.editora_id) {
      array.push({ id: entity.editora_id, nome: entity.editora?.nome, tipo: 4 });
    } else if (entity.divisao_id) {
      array.push({ id: entity.divisao_id, nome: entity.divisao?.nome, tipo: 5 });
    } else if (entity.tipo) {
      array.push(entity);
    }
  });
  return array;
}

export function selectedEntityActive(user: any): Entidade[] {
  if (user.entidade_id) {
    return [{ id: user.entidade_id, nome: user.nome_escola, tipo: 1, externo: user.escola_externa }];
  }
  if (user.campo_id) {
    return [{ id: user.campo_id, nome: user.nome_escola, tipo: 2, externo: user.escola_externa }];
  }
  if (user.uniao_id) {
    return [{ id: user.uniao_id, nome: user.nome_escola, tipo: 3, externo: user.escola_externa }];
  }
  if (user.editora_id) {
    return [{ id: user.editora_id, nome: user.nome_escola, tipo: 4, externo: user.escola_externa }];
  }
  if (user.divisao_id) {
    return [{ id: user.divisao_id, nome: user.nome_escola, tipo: 5, externo: user.escola_externa }];
  }
  return [];
}

export function permission(user: any) {
  const externo = { externo: user.escola_externa === 1 ?? 0 };
  if (user.entidade_id) {
    return { ...externo, entidade_id: user.entidade_id };
  }
  if (user.campo_id) {
    return { ...externo, campo_id: user.campo_id };
  }
  if (user.uniao_id) {
    return { ...externo, uniao_id: user.uniao_id };
  }
  if (user.editora_id) {
    return { ...externo, editora_id: user.editora_id };
  }
  if (user.divisao_id) {
    return { ...externo, divisao_id: user.divisao_id };
  }
  return null;
}

export function buildEntities(store: any): any[] {
  const currentProfile = store.funcoes.filter(
    ({ funcao_id }) => funcao_id === store.funcao_padrao && Checker.isEmployee(funcao_id),
  );
  const entities = currentProfile
    .filter(({ entidade_id }) => !!entidade_id)
    .map(({ escola_aluno }) =>
      escola_aluno.map(({ entidade_id, nome_escola, secretaria_sistema_id }) => ({
        id: entidade_id,
        name: nome_escola,
        systemType: secretaria_sistema_id,
      })),
    );
  if (isEmpty(entities)) {
    return [
      {
        id: store.usuario_ativo.entidade_id,
        name: store.usuario_ativo.nome_escola,
        systemType: store.usuario_ativo.secretaria_sistema_id,
      },
    ];
  }
  return sortBy(uniqBy(flattenDeep(entities), 'id'), 'name');
}
