<div class="grid h-auto">
  <app-accordion>
    <app-accordion-item [expanded]="true">
      <div app-accordion-header class="text-lg font-semibold text-gray-600" translate>
        gestor-avaliacoes.configuracao
      </div>
      <div app-accordion-content class="mb-2">
        <app-card>
          <div app-card-content>
            <form [formGroup]="form" class="grid gap-4 p-4">
              <div class="grid gap-3 md:flex">
                <app-form-field class="w-full">
                  <label app-label class="font-semibold" translate> gestor-avaliacoes.titulo-questao</label>
                  <input
                    app-input
                    type="text"
                    [formControl]="onGetFieldControlAvaliacao('titulo')"
                    [placeholder]="'gestor-avaliacoes.digite-titulo' | translate"
                    [ngClass]="{ 'is-invalid': isInvalidControlAvaliacao('titulo') }"
                    maxlength="70"
                    required
                  />
                </app-form-field>

                <app-form-field class="w-full">
                  <label app-label class="font-semibold" translate> gestor-avaliacoes.modo-aplicacao </label>
                  <ng-select
                    app-select
                    [formControl]="onGetFieldControlAplicacao('modo_aplicacao')"
                    [items]="applicationModes"
                    bindValue="id"
                    bindLabel="description"
                    [clearable]="false"
                    [searchable]="false"
                  ></ng-select>
                </app-form-field>

                <app-form-field class="w-full" *ngIf="isDivisionId">
                  <label app-label class="font-semibold" translate> geral.pais </label>
                  <app-select-country-context app-select [formControl]="onGetFieldControlAvaliacao('pais_id')">
                  </app-select-country-context>
                </app-form-field>

                <div class="w-full" *ngIf="isEntityId">
                  <app-form-field class="w-full">
                    <label app-label class="font-semibold" translate> gestor-avaliacoes.gerenciada </label>
                    <ng-select
                      app-select
                      [formControl]="onGetFieldControlAvaliacao('tipo_gerenciada')"
                      [placeholder]="'eclass.selecione' | translate"
                      bindValue="id"
                      [multiple]="false"
                      [searchable]="false"
                      [clearable]="false"
                      [ngClass]="{ 'is-invalid': isInvalidControlAvaliacao('peso_tipo') }"
                      [items]="managed$ | async"
                      bindLabel="descricao"
                    >
                    </ng-select>
                  </app-form-field>
                </div>
              </div>

              <div class="grid gap-3 md:flex">
                <app-form-field class="w-full">
                  <label app-label class="font-semibold" translate> gestor-avaliacoes.tipo-avaliacao </label>
                  <ng-select
                    app-select
                    [formControl]="onGetFieldControlAvaliacao('tipo')"
                    [items]="tipos"
                    bindValue="id"
                    [searchable]="false"
                    [multiple]="false"
                    [clearable]="false"
                  >
                  </ng-select>
                </app-form-field>

                <app-form-field class="w-full">
                  <label app-label class="font-semibold" translate> gestor-avaliacoes.tipo-calculo </label>
                  <ng-select
                    app-select
                    [formControl]="onGetFieldControlAvaliacao('peso_tipo')"
                    [items]="pesoTipos"
                    bindValue="id"
                    [multiple]="false"
                    [searchable]="false"
                    [clearable]="false"
                    [ngClass]="{ 'is-invalid': isInvalidControlAvaliacao('peso_tipo') }"
                  >
                  </ng-select>
                </app-form-field>

                <app-form-field
                  *ngIf="onGetFieldControlAvaliacao('peso_tipo').value !== weightType.Porcentagem"
                  class="w-full"
                >
                  <label app-label class="font-semibold" translate> gestor-avaliacoes.peso </label>
                  <input
                    app-input
                    type="text"
                    [formControl]="onGetFieldControlAvaliacao('peso')"
                    currencyMask
                    [options]="scoreConfig"
                  />
                </app-form-field>
              </div>
            </form>
          </div>
        </app-card>
      </div>
    </app-accordion-item>
  </app-accordion>
</div>
