import { Clipboard } from '@angular/cdk/clipboard';
import { DialogRef } from '@angular/cdk/dialog';
import { AfterViewInit, ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { UserService } from '@app/core';
import { NotificationService } from '@app/core/services/notification.service';
import { LoginFromUserService } from '@app/layouts/services/login-from-user.service';
import { debounceDelay, userPhoto } from '@app/shared/utils';
import { AppSelectors } from '@app/store';
import { AppState } from '@app/store/app.state';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { head, isEmpty } from 'lodash';
import { Observable, Subject, switchMap, takeUntil } from 'rxjs';
import { map, startWith, take } from 'rxjs/operators';

@Component({
  selector: 'app-login-from-user-tab-students',
  templateUrl: './login-from-user-tab-students.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginFromUserTabStudentsComponent implements OnInit, AfterViewInit, OnDestroy {
  public form: FormGroup;
  public entityId$: Observable<number>;
  public students$: Observable<any[]>;
  private _destroy$ = new Subject<void>();

  constructor(
    private dialogRef: DialogRef,
    private clipboard: Clipboard,
    private store: Store<AppState>,
    private formBuilder: FormBuilder,
    private userService: UserService,
    private translateService: TranslateService,
    private notificationService: NotificationService,
    private loginFromUserService: LoginFromUserService,
  ) {}

  public ngOnInit() {
    this.initForm();
  }

  public ngAfterViewInit() {
    this.loadlisteners();
  }

  public ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

  public onLoginFromUser(studentId: number) {
    this.loginFromUserService.with(studentId).subscribe(() => this.dialogRef.close());
  }

  public onCopy(text: string) {
    this.clipboard.copy(text);
    const message = this.translateService.instant('geral.copiado');
    this.notificationService.success(message);
  }

  public isEmpty(data: any[]): boolean {
    return isEmpty(data);
  }

  private initForm() {
    this.form = this.formBuilder.group({
      turma: undefined,
      busca: undefined,
      qtd: 9999,
    });
  }

  private loadlisteners() {
    const activeUser$ = this.store.select(AppSelectors.ActiveUser).pipe(take(1));
    this.entityId$ = activeUser$.pipe(map(({ entidade_id }) => entidade_id));
    this.students$ = this.form.valueChanges.pipe(
      takeUntil(this._destroy$),
      debounceDelay(),
      switchMap(() => activeUser$),
      switchMap((activeUser) => this.userService.schoolStudents(activeUser.entidade_id, this.form.value)),
      map(({ data }) => data.map(this.transform)),
      startWith(undefined),
    );
  }

  private transform = (data: any): any => ({
    ...data,
    photo: userPhoto(data.photo),
    name: data.name,
    lastName: data.sobrenome,
    celular: head<any>(data.aluno?.aluno_responsavel)?.responsavel?.celular,
  });
}
