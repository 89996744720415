<app-popover *ngIf="store$ | async as store" class="block w-[300px]">
  <div app-popover-content class="!p-0 !pt-5">
    <div class="mb-3 px-5">
      <div class="flex gap-4">
        <div>
          <button
            app-icon-button
            app-outline-button
            type="button"
            class="border-1 w-max !rounded-full bg-white/80 !p-1 shadow-sm shadow-black/40"
          >
            <app-user-photo class="h-16 w-16" [src]="store.photo"></app-user-photo>
          </button>
        </div>

        <div class="flex flex-col overflow-hidden text-ellipsis">
          <strong class="text-md">{{ store | orderName | async }}</strong>
          <span class="text-sm">{{ store.email }}</span>

          <ng-container *isAllow="[2, 11]; else retornarTemplate">
            <button
              app-button
              type="button"
              [disabled]="!store.usuario_ativo.entidade_id"
              (click)="onOpenDialog()"
              class="mt-3 !rounded-full bg-gray-100 text-black hover:brightness-95 active:brightness-90"
            >
              <svg-icon class="mr-2 h-5 w-5 text-black" src="assets/icons/icon-shuffle.svg"></svg-icon>
              <span translate>perfil.mudar-conta</span>
            </button>
          </ng-container>
          <ng-template #retornarTemplate>
            <button
              *ngIf="origin$ | async as origin"
              app-button
              type="button"
              (click)="onReturnOrigin(origin)"
              class="mt-3 !rounded-full bg-gray-100 text-black hover:brightness-95 active:brightness-90"
            >
              <svg-icon class="mr-2 h-5 w-5 text-black" src="assets/icons/icon-arrow-right-return.svg"></svg-icon>
              <span translate>perfil.retornar-original</span>
            </button>
          </ng-template>
        </div>
      </div>
    </div>

    <app-tab-group>
      <!-- Perfil -->
      <ng-container>
        <app-tab *ngIf="store.profiles | noEmpty" headerClass="px-5">
          <button app-tab-header [tabStyle]="slim" type="button" translate>perfil.perfil</button>
          <ng-template app-tab-content>
            <app-popover-tab-profile [store]="store" app-tab-content [tabStyle]="slim"></app-popover-tab-profile>
          </ng-template>
        </app-tab>
      </ng-container>

      <!-- Dependentes -->
      <ng-container>
        <app-tab *ngIf="store.dependents | noEmpty" headerClass="px-5">
          <button app-tab-header [tabStyle]="slim" type="button" translate>perfil.dependentes</button>
          <ng-template app-tab-content>
            <app-popover-tab-dependents [store]="store" app-tab-content [tabStyle]="slim"></app-popover-tab-dependents>
          </ng-template>
        </app-tab>
      </ng-container>

      <!-- Unidade -->
      <ng-container *isAllow="[2, 12]">
        <app-tab headerClass="px-5" [active]="!store.usuario_ativo?.entidade_id">
          <button app-tab-header [tabStyle]="slim" type="button" translate>perfil.unidade</button>
          <ng-template app-tab-content>
            <app-popover-tab-entity [store]="store" app-tab-content [tabStyle]="slim"></app-popover-tab-entity>
          </ng-template>
        </app-tab>
      </ng-container>

      <!-- Aluno -->
      <ng-container *isAllow="[2, 13]">
        <app-tab *ngIf="!!store.usuario_ativo.entidade_id" headerClass="px-5">
          <button app-tab-header [tabStyle]="slim" type="button" translate>perfil.aluno</button>
          <ng-template app-tab-content>
            <app-popover-tab-student [store]="store" app-tab-content [tabStyle]="slim"></app-popover-tab-student>
          </ng-template>
        </app-tab>
      </ng-container>
    </app-tab-group>
  </div>

  <hr app-divider />

  <div app-popover-footer class="pb-3">
    <ng-container *ngIf="!!store.access_token; else notLoggedGoogleTemplate">
      <div *ngIf="store.funcao_padrao === 15; else notDev">
        <div class="flex w-full pt-0">
          <div class="flex w-full flex-col gap-4">
            <button
              app-button
              type="button"
              class="w-full !justify-start gap-3 !rounded-full bg-theme-200 p-3 text-theme-700"
              (click)="onLogout()"
            >
              <svg-icon class="h-6 w-6" src="assets/icons/icon-sign-out.svg"></svg-icon>
              <span class="text-md font-semibold" translate>geral.sair</span>
            </button>

            <button
              app-button
              type="button"
              class="w-full !justify-start gap-3 !rounded-full bg-theme-500 p-3 text-white"
              (click)="onGetOutSafe()"
            >
              <svg-icon class="h-8 w-8" src="assets/icons/icon-power-setting.svg"></svg-icon>
              <span class="text-md font-semibold" translate>geral.sair-seguro</span>
            </button>
          </div>
        </div>
      </div>
      <ng-template #notDev>
        <button
          app-button
          type="button"
          class="w-full !justify-start gap-3 !rounded-full bg-theme-500 p-3 text-white"
          (click)="onGetOutSafe()"
        >
          <svg-icon class="h-8 w-8" src="assets/icons/icon-power-setting.svg"></svg-icon>
          <span class="text-md font-semibold" translate>geral.sair-seguro</span>
        </button>
      </ng-template>
    </ng-container>
    <ng-template #notLoggedGoogleTemplate>
      <button
        app-button
        type="button"
        class="w-full !justify-start gap-3 !rounded-full bg-theme-200 p-3 text-theme-700"
        (click)="onLogout()"
      >
        <svg-icon class="h-8 w-8" src="assets/icons/icon-sign-out.svg"></svg-icon>
        <span class="text-md font-semibold" translate>geral.sair</span>
      </button>
    </ng-template>

    <div class="flex justify-between px-3 pt-3 text-xs text-gray-400">
      <span>© {{ 'geral.portal-educacional' | translate }}</span>
      <span>{{ 'geral.versao' | translate }} {{ version }}</span>
    </div>
  </div>
</app-popover>
