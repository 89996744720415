import { CdkAccordionModule } from '@angular/cdk/accordion';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { SchoolWebsiteModule } from '@app/modules/school-website/school-website.module';
import { ServiceModule } from '@app/modules/services/services.module';
import { SharedModule } from '@app/shared';
import { CalendarModule, SelectGroupModule, UserPhotoModule } from '@app/shared/modules';
import { SubmenuModule } from '../shared/modules/submenu/submenu.module';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';

import { CardCarouselComponent } from './components/card-carousel/card-carousel.component';
import { CarouselComponent } from './components/carousel/carousel.component';
import { ItemAnnouncementComponent } from './components/item-announcement/item-announcement.component';
import { LoginFromUserTabResponsiblesComponent } from './components/login-from-user-tab-responsibles/login-from-user-tab-responsibles.component';
import { LoginFromUserTabStudentsComponent } from './components/login-from-user-tab-students/login-from-user-tab-students.component';
import { MainHeaderComponent } from './components/main-header/main-header.component';
import { DropdownMenuComponent } from './components/main-menu/dropdown-menu.component';
import { MainMenuComponent } from './components/main-menu/main-menu.component';
import { ModalLoginFromUserComponent } from './components/modal-login-from-user/modal-login-from-user.component';
import { PopoverProfileComponent } from './components/popover-profile/popover-profile.component';
import { PopoverTabDependentsComponent } from './components/popover-tab-dependents/popover-tab-dependents.component';
import { PopoverTabEntityComponent } from './components/popover-tab-entity/popover-tab-entity.component';
import { PopoverTabProfileComponent } from './components/popover-tab-profile/popover-tab-profile.component';
import { PopoverTabStudentComponent } from './components/popover-tab-student/popover-tab-student.component';
import { SideRightActivityDashboardComponent } from './components/side-right-activity-dashboard/side-right-activity-dashboard.component';
import { LayoutDashboardComponent } from './layouts/layout-dashboard/layout-dashboard.component';
import { LayoutDocsComponent } from './layouts/layout-docs/layout-docs.component';
import { LayoutGamesComponent } from './layouts/layout-games/layout-games.component';
import { LayoutModuleComponent } from './layouts/layout-module/layout-module.component';
import { GroupNamePipe } from './pipes/group-name.pipe';
import { LoginFromUserService } from './services/login-from-user.service';
import { NotificationModule } from '@app/modules/notification/notification.module';
import { ModalGamesComponent } from './components/modal-games/modal-games.component';

@NgModule({
  declarations: [
    LayoutModuleComponent,
    LayoutDocsComponent,
    LayoutDashboardComponent,
    MainHeaderComponent,
    MainMenuComponent,
    BreadcrumbComponent,
    ModalLoginFromUserComponent,
    ModalGamesComponent,
    LoginFromUserTabStudentsComponent,
    LoginFromUserTabResponsiblesComponent,
    PopoverProfileComponent,
    PopoverTabProfileComponent,
    PopoverTabDependentsComponent,
    PopoverTabEntityComponent,
    PopoverTabStudentComponent,
    GroupNamePipe,
    SideRightActivityDashboardComponent,
    ItemAnnouncementComponent,
    CarouselComponent,
    CardCarouselComponent,
    LayoutGamesComponent,
    DropdownMenuComponent,
  ],
  providers: [LoginFromUserService],
  exports: [LayoutModuleComponent, LayoutDocsComponent, LayoutDashboardComponent],
  imports: [
    ServiceModule,
    NotificationModule,
    SharedModule,
    RouterModule,
    UserPhotoModule,
    SelectGroupModule,
    CdkAccordionModule,
    SchoolWebsiteModule,
    SubmenuModule,
    CalendarModule,
  ],
})
export class LayoutsModule {}
