import { Injectable } from '@angular/core';
import { portalConst } from '@app/config';
import { GroupAudience } from '@app/core/models/GroupAudience';
import { FunctionAudience, UnitsAudience, UserAudience } from '@app/core/models/target-audience';
import { ApiService } from '@app/core/services/api.service';
import { CrudService } from '@app/core/services/crud.service';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class TargetAudienceService extends CrudService<GroupAudience> {
  constructor(api: ApiService) {
    super(api, `${portalConst.api.trilhas}/`);
  }

  public getGroups(entity: any, typeFunctionId: number): Observable<any> {
    return this.api.get<GroupAudience>(`${this.url}v1/grupos`, { ...entity, tipofuncao_id: typeFunctionId });
  }

  public getUsers(entity: any, id: number): Observable<any> {
    return this.api.get<UserAudience>(`${this.url}v1/grupos-usuarios/${id}`, entity);
  }

  public getUsersByFunction(entity: any, functionId: number): Observable<any> {
    return this.api.get<UserAudience>(`${this.url}v1/grupos-usuarios/funcao/${functionId}`, entity);
  }

  public getAllUnits(country_id, all: boolean = false, withoutExternal: boolean = false): Observable<any> {
    const params = { country_id };
    let aux: string;
    if (all) {
      params['all'] = all;
    }
    if (withoutExternal) {
      params['without_external'] = true;
    }
    return this.api.get<UnitsAudience>(`${this.url}v1/user/unidades${aux ? aux : ''}`, params);
  }

  public getTypes(): Observable<any> {
    return this.api.get<FunctionAudience>(`${this.url}v1/grupos-tipos`);
  }
}
