<app-card class="my-2 w-64 border-2 border-transparent bg-red-100">
  <div app-card-content>
    <div
      class="relative h-0 w-full bg-gray-300 bg-contain bg-center bg-no-repeat pt-[calc((1/1)*100%)]"
      style="background-image:url('{{ imageUrl }}');"
    ></div>
    <div class="flex h-16 flex-col justify-center text-center">
      <h4 class="pt-4 text-sm font-bold text-base-whisper-900">Bimestre 2 {{ index }}</h4>
    </div>
  </div>
</app-card>
