export enum FunctionsEnum {
  direcaoEscolar = 1,
  tesouraria = 2,
  secretaria = 3,
  coordPedagogico = 4,
  orientPedagogico = 5,
  professor = 6,
  tecnologia = 7,
  apoioEscolar = 8,
  administradorSite = 9,
  aluno = 10,
  colaborador = 11,
  departamentalCampo = 12,
  departamentalDivisao = 13,
  departamentalUniao = 14,
  desenvolvedorPortal = 15,
  funcionarioDidaticos = 16,
  funcionarioPortal = 17,
  presidente = 18,
  representante = 19,
  responsavel = 20,
  suportePortal = 21,
  tesoureiroUniao = 22,
  tesoureirocampo = 23,
  visitante = 24,
  diretor = 32,
  viceDiretor = 33,
  diretorAcademico = 34,
  tesoureiro = 35,
  tesoureiroAssistente = 36,
  secretario = 37,
  auxiliarSecretaria = 38,
  coordPedagogicoEducacaoInfantil = 39,
  coordPedagogicoEnsinoFundamentalI = 40,
  coordPedagogicoEnsinoFundamentalII = 41,
  coordPedagogicoEnsinoMedio = 42,
  coordPedagogicoTurnoIntegral = 43,
  orientPedagogicoEducacaoInfantil = 44,
  orientPedagogicoEnsinoFundamentalII = 46,
  orientPedagogicoEnsinoMedio = 47,
  professorEducacaoInfantil = 48,
  professorEnsinoFundamentalI = 49,
  professorEnsinoFundamentalII = 50,
  professorEnsinoMedio = 51,
  tecnicoInformatica = 52,
  funcionario = 53,
  monitorEscolar = 54,
  promotorEscolar = 55,
  capelania = 56,
  capelao = 57,
  turnoIntegral = 58,
  diretorDisciplinar = 59,
  monitorDisciplinar = 60,
  bibliotecario = 61,
  bilingue = 62,
  cursosTecnicos = 63,
  coordenador = 64,
  coordenadorDisciplinar = 65,
  semFuncao = 66,
  treinamentos = 67,
  marketing = 69,
  auxiliarSOESOP = 70,
  responsavelInconcluso = 72,
  atendimentoPortal = 73,
  advisor = 84,
  supervisorAdvisor = 85,
}

function teacherIds() {
  return [
    FunctionsEnum.professorEducacaoInfantil,
    FunctionsEnum.professorEnsinoFundamentalI,
    FunctionsEnum.professorEnsinoFundamentalII,
    FunctionsEnum.professorEnsinoMedio,
    FunctionsEnum.professor,
  ];
  // [48, 49, 50, 51, 6]
}

function teacherLevel(funcao_id: any): number {
  switch (funcao_id) {
    case FunctionsEnum.professorEducacaoInfantil:
      return 1;
    case FunctionsEnum.professorEnsinoFundamentalI:
      return 2;
    case FunctionsEnum.professorEnsinoFundamentalII:
      return 3;
    case FunctionsEnum.professorEnsinoMedio:
      return 4;
    default:
      return null;
  }
}

export { teacherIds, teacherLevel };
