import { Injectable } from '@angular/core';
import { portalConst } from '@app/config';
import { FunctionByTypeAudience } from '@app/core/models/target-audience';
import { ApiService } from '@app/core/services/api.service';
import { CrudService } from '@app/core/services/crud.service';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class FunctionsService extends CrudService<FunctionByTypeAudience> {
  constructor(api: ApiService) {
    super(api, `${portalConst.api.usuario}/`);
  }

  public getFunctionsByType(type_entity: number, entity_id?: number): Observable<any> {
    return this.api.get<FunctionByTypeAudience>(`${this.url}v1/hub-educacional/grupo-usuarios-funcoes`, {
      tipo_entidade: type_entity,
      entity_id,
    });
  }
}
