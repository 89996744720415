<div class="flex h-full bg-theme-700 pt-10 transition-all duration-300 lg:pl-10 lg:pt-0">
  <div
    class="relative z-200 flex h-10 w-full items-center justify-center border-r-theme-300 bg-white shadow-sm shadow-black/30 lg:z-90 lg:h-full lg:flex-col lg:rounded-tl-3xl lg:border-0 lg:border-dashed"
  >
    <button
      app-icon-button
      type="button"
      class="absolute left-[-50%] top-8 z-50 hidden w-min !rounded-full bg-white px-3 py-[10px] shadow-submenu-active transition-all duration-300 hover:shadow-submenu-active-hover lg:block"
      (click)="location.back()"
    >
      <svg-icon class="block w-5 text-theme-700" src="assets/icons/icon-back.svg"></svg-icon>
    </button>

    <div class="relative -top-1/2 flex h-full w-full items-center justify-center lg:left-[-50%] lg:top-0 lg:flex-col">
      <ng-container *ngFor="let item of items$ | async">
        <div
          app-tooltip
          [tooltip]="item.title | translate"
          [ngClass]="{ active: isRouterLinkActive(item) }"
          class="group !rounded-full p-1.5 transition-all duration-300 active:bg-white lg:p-2.5 lg:active:shadow-submenu-active lg:active:hover:shadow-submenu-active-hover"
          (click)="onLink(item)"
        >
          <button
            app-icon-button
            type="button"
            class="relative h-[42px] w-[42px] !rounded-full bg-theme-700 p-3 shadow-submenu-inner-default hover:!brightness-100 group-active:!bg-theme-500"
          >
            <svg-icon
              class="absolute left-1/2 top-1/2 block -translate-x-1/2 -translate-y-1/2 text-white group-hover:text-white/70 group-active:!text-white"
              [src]="item.icon | icon"
              [ngClass]="item.dimension || 'w-8'"
            ></svg-icon>
          </button>
        </div>
      </ng-container>
    </div>
  </div>
</div>
