import { RunActions } from '@app/shared';
import { createAction, props } from '@ngrx/store';
import { ModelAssessmentExecutionResource } from './modal-assessment-execution.state';

export const InitDataEffect = createAction(
  '[ModalAssessmentExecution] InitDataEffect',
  props<ModalAssessmentExecutionInit>(),
);

export const ClearStageReducer = createAction('[ModalAssessmentExecution] ClearStageReducer');

export const DataLoadedReducer = createAction('[ModalAssessmentExecution] DataLoadedReducer', props<any>());

export const StartResolucaoEffect = createAction(
  '[ModalAssessmentExecution] StartResolucaoEffect',
  props<{ actions?: RunActions }>(),
);

export const FinalizeResolucaoEffect = createAction(
  '[ModalAssessmentExecution] FinalizeResolucaoEffect',
  props<{ actions?: RunActions }>(),
);

export const UpdateStateReducer = createAction(
  '[ModalAssessmentExecution] UpdateStateReducer',
  props<{ value: any }>(),
);

export const AnswerReducer = createAction(
  '[ModalAssessmentExecution] AnswerReducer',
  props<{ questaoUuid: string; alternativaUuid: string; resposta: string; finishedTime: boolean }>(),
);

export const QuestionLoadingReducer = createAction(
  '[ModalAssessmentExecution] QuestionLoadingReducer',
  props<{ questaoUuid: string; loading: boolean }>(),
);

export const AnswerEffect = createAction(
  '[ModalAssessmentExecution] AnswerEffect',
  props<{ questaoUuid: string; alternativaUuid?: string; resposta?: string }>(),
);

export interface ModelAssessmentExecutionData {
  aplicacao_uuid: string;
  avaliacao_uuid?: string;
  assessment_type?: number;
}

export interface ModalAssessmentExecutionInit {
  data: ModelAssessmentExecutionData;
  resource: ModelAssessmentExecutionResource;
  extra?: any;
}
