import { ChangeDetectionStrategy, Component, DestroyRef } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Policies, Role } from '@app/shared';
import { AppSelectors } from '@app/store';
import { AppState } from '@app/store/app.state';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-card-resume-dashboard',
  templateUrl: './card-resume-dashboard.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardResumeDashboardComponent {
  public readonly now = new Date();
  public allow$: Observable<any>;

  constructor(private store: Store<AppState>, private destroyRef: DestroyRef) {
    this.allow$ = this.store.select(AppSelectors.appFeature).pipe(
      takeUntilDestroyed(this.destroyRef),
      map((store) => ({
        highlight:
          Policies.isAllow([1, 5], store.policies) &&
          Role.learningTrails.general(store) &&
          this.validGrade(store) &&
          !store.usuario_ativo.escola_externa,
        educationalMaterials: Policies.isAllow([1, 1], store.policies) && Role.educationalMaterials(store),
        learningTrails: Policies.isAllow([1, 5], store.policies) && Role.learningTrails.general(store),
        sitFinance: Role.sitFinance(store),
        evaluation: Policies.isAllow([1, 4], store.policies),
        serviceMeetings: Policies.isAllow([1, 66], store.policies),
        lives: Policies.isAllow([1, 69], store.policies),
      })),
    );
  }

  private validGrade(store: AppState): boolean {
    const gradeId = store.usuario_ativo.padrao_serie_id;
    return !gradeId || gradeId >= 60;
  }
}
