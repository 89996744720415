<div app-tab-content class="!w-full border-t-2 border-t-base-mantis-500">
  <div class="mt-5 grid w-full md:flex md:gap-5">
    <ng-container *ngIf="entities$ | async as entities">
      <app-form-field *ngIf="entities.length > 1" class="flex w-full">
        <label app-label translate> Unidade escolar </label>
        <ng-select
          app-select
          [items]="entities$ | async"
          [formControl]="entityIdCtrl"
          bindLabel="nome"
          bindValue="id"
          [clearable]="true"
          [placeholder]="'eclass.selecione' | translate"
          [notFoundText]="'geral.nao-encontrado' | translate"
          [multiple]="false"
        >
        </ng-select>
      </app-form-field>
    </ng-container>
    <app-form-field class="flex w-full">
      <label app-label translate> gestor-avaliacoes.pesquisar-nome </label>
      <input
        app-input
        [formControl]="searchStudentNameCtrl"
        [placeholder]="'gestor-avaliacoes.digite-nome' | translate"
      />
    </app-form-field>
    <app-form-field class="flex w-full">
      <label app-label translate> gestor-avaliacoes.filtro-turma-aluno </label>
      <ng-select
        app-select
        [formControl]="searchGroupCtrl"
        [items]="groups"
        bindLabel="name"
        [loading]="loading$ | async"
        [placeholder]="'geral.selecione' | translate"
        [loadingText]="'geral.carregando' | translate"
      >
      </ng-select>
    </app-form-field>
  </div>
  <ng-container *ngIf="{ value: dataStudent$ | async } as dataStudents">
    <ng-container *ngIf="{ value: data$ | async } as data">
      <div
        *ngIf="!!data.value?.tentativas_sem_alunos"
        class="flex items-center justify-between rounded-lg border border-yellow-200 bg-yellow-50 px-5 py-2"
      >
        <div class="flex items-center">
          <svg-icon class="flex w-7 text-yellow-300" src="assets/icons/icon-notify-fine.svg" alt="icon"></svg-icon>
          <p class="ml-2 text-md text-zinc-900">Existem cartões resposta não atribuídos aos alunos.</p>
        </div>
        <button
          app-flat-button
          (click)="onModalAnswersAuditComponent()"
          type="button"
          class="bg-base-status-danger text-white hover:bg-base-status-danger"
          translate
        >
          <svg-icon class="ml-1 flex h-6 w-6 text-white" src="assets/icons/icon-clipboard-check.svg"></svg-icon>
          gestor-avaliacoes.auditoria-respostas
        </button>
      </div>
      <div class="flex items-center justify-between">
        <div class="grid w-full gap-2 md:flex md:flex-wrap md:gap-0">
          <button
            *ngIf="isSchool"
            app-flat-button
            type="button"
            (click)="onOpenReleaseStudents()"
            class="mr-3 border-theme bg-white"
          >
            <svg-icon class="flex h-6 w-6 text-green-500" src="assets/icons/icon-user-clock-solid.svg"></svg-icon>
            <span class="ml-3 whitespace-nowrap" translate> gestor-avaliacoes.liberar-alunos </span>
            <span *ngIf="!!studentChecked" class="ml-1"> ({{ studentChecked }}) </span>
          </button>
          <button
            [disabled]="!dataStudents?.value.length"
            *ngIf="canPreview && isOnline"
            app-flat-button
            (click)="onModalResolutionEvaluation()"
            class="mr-3 border-theme bg-white"
          >
            <svg-icon
              class="flex h-6 w-6 text-base-status-warning"
              src="assets/icons/icon-clipboard-check.svg"
            ></svg-icon>
            <span class="ml-3 whitespace-nowrap" translate> gestor-avaliacoes.ver-respostas </span>
          </button>

          <button
            [disabled]="!dataStudents?.value.length"
            *ngIf="!isOnline"
            app-flat-button
            (click)="onModalAnswersAuditComponent()"
            class="mr-3 border-theme bg-white"
          >
            <svg-icon
              class="flex h-6 w-6 text-base-status-danger"
              src="assets/icons/icon-clipboard-check.svg"
            ></svg-icon>
            <span class="ml-3 whitespace-nowrap" translate> gestor-avaliacoes.auditoria </span>
          </button>

          <button
            #popoverBottom
            app-flat-button
            type="button"
            class="mr-3 border-theme bg-white"
            (click)="onPrintPopover(popoverBottom)"
            *ngIf="(!isOnline && (isPreviewer || canPreview)) || (isEntity && !isOnline)"
          >
            <svg-icon class="flex h-6 w-7" src="assets/icons/icon-print.svg"></svg-icon>
            <span class="ml-3 whitespace-nowrap" translate> gestor-avaliacoes.imprimir </span>
          </button>
          <button
            *ngIf="isEntity && !isOnline"
            app-flat-button
            (click)="onUploadFiles()"
            class="mr-3 border-theme bg-white"
          >
            <svg-icon class="flex h-6 w-6 text-purple-400" src="assets/icons/icon-upload-small.svg"></svg-icon>
            <span class="ml-3 whitespace-nowrap" translate> gestor-avaliacoes.fazer-upload </span>
          </button>
          <ng-container *ngIf="isDivision">
            <button app-flat-button (click)="onAssessmentsImportFileComponent()" class="mr-3 border-theme bg-white">
              <svg-icon class="flex h-6 w-6 text-purple-400" src="assets/icons/icon-upload-small.svg"></svg-icon>
              <span class="ml-3 whitespace-nowrap" translate> gestor-avaliacoes.importacao-arquivos </span>
            </button>

            <button
              app-tooltip
              [tooltip]="'gestor-avaliacoes.exportar-relatorios' | translate"
              app-flat-button
              (click)="onExportFile()"
              class="mr-3 border-theme bg-white"
            >
              <svg-icon class="flex h-6 w-6 text-base-status-info" src="assets/icons/icon-download.svg"></svg-icon>
              <span class="ml-3 whitespace-nowrap" translate> gestor-avaliacoes.exportar-dados </span>
            </button>
          </ng-container>

          <button
            app-flat-button
            *ngIf="canViewEscalaProficiencia"
            (click)="onModalScaleProficiencia()"
            [disabled]="!dataStudents?.value.length"
            class="mr-3 border-theme bg-white"
          >
            <div class="flex h-6 w-6 text-base-status-warning">
              <svg-icon class="flex h-6 w-7" src="assets/icons/icon-clipboard-check.svg"></svg-icon>
            </div>
            <span class="ml-3 hidden md:inline-block" translate> gestor-avaliacoes.escala-proficiencia </span>
          </button>
          <button
            app-flat-button
            type="button"
            app-tooltip
            [tooltip]="'gestor-avaliacoes.exportar-dados-tela' | translate"
            (click)="onExportReportByStudent()"
            class="mr-3 border-theme bg-white"
            [disabled]="!dataStudents?.value.length"
          >
            <svg-icon class="flex h-6 w-7 text-green-500" src="assets/icons/icon-download.svg"></svg-icon>
            <span class="ml-3 hidden md:flex" translate> gestor-avaliacoes.exportacao </span>
          </button>
        </div>

        <div *ngIf="!!data.value?.media" class="flex items-center gap-2">
          <span class="text-[14px] font-semibold" translate> gestor-avaliacoes.media-geral</span>
          <div class="flex items-center justify-center truncate">
            <div
              class="flex h-10 w-14 flex-col items-center justify-center rounded-lg bg-base-gray-light-500 shadow-inner shadow-base-gray-200"
            >
              <span *ngIf="!!assesmentWeightType" class="text-md font-semibold leading-none">
                {{ data.value?.media | number : '1.2-2' }}
              </span>
              <span *ngIf="!assesmentWeightType" class="text-md font-semibold leading-none">
                {{ data.value?.media | number : '1.0-0' }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="!dataStudents?.value.length; else dataTemplate">
      <app-card class="mt-5">
        <div app-card-content class="h-[200px]">
          <app-skeleton *ngIf="loading$ | async; else emptyTemplate" class="h-full w-full"></app-skeleton>
          <ng-template #emptyTemplate>
            <app-jumbotron translate>gestor-avaliacoes.selecione-unidade</app-jumbotron>
          </ng-template>
        </div>
      </app-card>
    </ng-container>
    <ng-template #dataTemplate>
      <div class="mt-5 grid gap-4 !overflow-x-auto">
        <ng-container *ngIf="application$ | async as application">
          <!-- TAB DE ALUNOS -->
          <table app-table>
            <thead>
              <tr>
                <th
                  *ngIf="application.modo_aplicacao !== assessmentApplicationMode.Impressa"
                  app-table-cell
                  class="!bg-theme-500 p-2 !text-base font-semibold uppercase !text-theme-50 first:rounded-l-lg first:pl-3 last:rounded-r-lg last:pr-3 [&:is(th)]:bg-theme-500 [&:is(th)]:text-theme-contrast [&:not(th)]:bg-white"
                >
                  <div app-form class="flex flex-col gap-2">
                    <div class="flex gap-1">
                      <input
                        app-checkbox
                        (click)="onSelectedAll(!hasSelected)"
                        class="text-green-950"
                        type="checkbox"
                        [checked]="hasSelected"
                      />
                    </div>
                  </div>
                </th>
                <th
                  *ngIf="isPreview"
                  app-table-cell
                  class="!bg-theme-500 p-2 !text-sm font-semibold uppercase !text-theme-50 first:rounded-l-lg first:pl-3 last:rounded-r-lg last:pr-3 md:!text-base [&:is(th)]:bg-theme-500 [&:is(th)]:text-theme-contrast [&:not(th)]:bg-white"
                  translate
                >
                  gestor-avaliacoes.cartao-resposta
                </th>
                <th
                  app-table-cell
                  class="!bg-theme-500 p-2 !text-sm font-semibold uppercase !text-theme-50 first:rounded-l-lg first:pl-3 last:rounded-r-lg last:pr-3 md:!text-base [&:is(th)]:bg-theme-500 [&:is(th)]:text-theme-contrast [&:not(th)]:bg-white"
                  translate
                >
                  gestor-avaliacoes.desempenho
                </th>
                <th
                  app-table-cell
                  class="!bg-theme-500 p-2 text-left !text-sm font-semibold uppercase !text-theme-50 first:rounded-l-lg first:pl-3 last:rounded-r-lg last:pr-3 md:!text-base [&:is(th)]:bg-theme-500 [&:is(th)]:text-theme-contrast [&:not(th)]:bg-white"
                  translate
                >
                  gestor-avaliacoes.participante
                </th>
                <th
                  *ngIf="application.modo_aplicacao !== assessmentApplicationMode.Impressa"
                  app-table-cell
                  class="min-w-[100px] !bg-theme-500 p-2 !text-sm font-semibold uppercase !text-theme-50 first:rounded-l-lg first:pl-3 last:rounded-r-lg last:pr-3 md:!text-base [&:is(th)]:bg-theme-500 [&:is(th)]:text-theme-contrast [&:not(th)]:bg-white"
                  translate
                >
                  gestor-avaliacoes.tempo
                </th>
                <th
                  app-table-cell
                  *ngIf="isPAAEB"
                  class="!bg-theme-500 p-2 !text-sm font-semibold uppercase !text-theme-50 first:rounded-l-lg first:pl-3 last:rounded-r-lg last:pr-3 md:!text-base [&:is(th)]:bg-theme-500 [&:is(th)]:text-theme-contrast [&:not(th)]:bg-white"
                  translate
                >
                  gestor-avaliacoes.proficiencia
                </th>
                <th
                  app-table-cell
                  class="!bg-theme-500 p-2 !text-sm font-semibold uppercase !text-theme-50 first:rounded-l-lg first:pl-3 last:rounded-r-lg last:pr-3 md:!text-base [&:is(th)]:bg-theme-500 [&:is(th)]:text-theme-contrast [&:not(th)]:bg-white"
                  translate
                >
                  gestor-avaliacoes.resultado
                </th>
                <th
                  app-table-cell
                  class="!bg-theme-500 p-2 !text-sm font-semibold uppercase !text-theme-50 first:rounded-l-lg first:pl-3 last:rounded-r-lg last:pr-3 md:!text-base [&:is(th)]:bg-theme-500 [&:is(th)]:text-theme-contrast [&:not(th)]:bg-white"
                  translate
                >
                  gda.compartilhadas.acoes
                </th>
              </tr>
            </thead>
            <tbody app-table-body class="overflow-auto text-center">
              <ng-container *ngFor="let group of dataStudents.value">
                <!-- Exibir o nome da turma -->
                <tr *ngIf="group[0]">
                  <td colspan="9" class="rounded-lg !bg-gray-100 py-5 text-lg font-semibold uppercase">
                    {{ group[0] }}
                  </td>
                </tr>

                <!-- Alunos da turma -->
                <tr *ngFor="let student of group[1]">
                  <td *ngIf="application.modo_aplicacao !== assessmentApplicationMode.Impressa" app-table-cell>
                    <div app-form class="flex flex-col gap-2">
                      <div class="flex gap-1">
                        <input
                          app-checkbox
                          [(ngModel)]="student.isSelected"
                          (change)="onSelectedCheck()"
                          class="text-green-950"
                          type="checkbox"
                        />
                      </div>
                    </div>
                  </td>
                  <td *ngIf="isPreview" class="w-1/12 !p-4 !text-center group-hover:!bg-theme-200">
                    <div class="mx-4 flex h-14 w-14 items-center justify-center !bg-gray-300 md:h-20 md:w-32">
                      <p class="!mb-0 text-md font-semibold text-white" translate>gestor-avaliacoes.preview</p>
                    </div>
                  </td>
                  <td class="w-1/12 !p-4 group-hover:!bg-theme-200">
                    <div class="flex items-center justify-center truncate px-5">
                      <div
                        app-tooltip
                        [tooltip]="'gestor-avaliacoes.porcentagem-acerto' | translate"
                        class="flex h-14 w-14 flex-col items-center justify-center rounded-full bg-base-gray-light-500 shadow-inner shadow-base-gray-200"
                      >
                        <span class="text-md font-semibold leading-none">
                          {{ onCalculatePerformance(student.qtd_acertos) }}%
                        </span>
                      </div>
                    </div>
                  </td>
                  <td app-table-cell>
                    <div class="mx-4 flex flex-col whitespace-nowrap">
                      <div class="flex items-center gap-2">
                        <span class="text-sm font-semibold md:text-md"> {{ student.nome }} </span>
                        <div class="flex gap-2">
                          <svg-icon
                            *ngIf="student.liberar_data_inicio"
                            app-tooltip
                            [tooltip]="
                              ('gestor-avaliacoes.liberacao-extra' | translate) +
                              ' ' +
                              (student.liberar_data_inicio | date : 'dd/MM/yyyy HH:mm') +
                              ' ' +
                              ('geral.ate' | translate) +
                              ' ' +
                              (student.liberar_data_fim | date : 'dd/MM/yyyy HH:mm')
                            "
                            class="flex w-5 text-green-500"
                            src="assets/icons/icon-user-clock-solid.svg"
                          >
                          </svg-icon>

                          <svg-icon
                            *ngIf="student.necessidades_especiais?.length"
                            app-tooltip
                            [tooltip]="formattedNecessidadesEspeciais(student.necessidades_especiais)"
                            class="active:brightness-85 h-6 w-6 cursor-pointer text-gray-400 hover:brightness-110"
                            src="assets/icons/icon-info-rounded.svg"
                          >
                          </svg-icon>
                        </div>
                      </div>
                      <div class="flex items-center gap-2 pr-2">
                        <div *ngIf="!!student.turma_descricao" class="flex items-center">
                          <span class="md:txt-base flex items-center text-sm font-light">
                            {{ student.turma_descricao }}
                          </span>
                        </div>
                        <div *ngIf="!isNil(student.qtd_questoes_respondidas)" class="flex items-center">
                          <svg-icon class="flex h-2 w-2 text-gray-700" src="assets/icons/icon-ellipse.svg"></svg-icon>
                          <span class="md:txt-base ml-2 flex items-start text-sm font-light">
                            {{ student.qtd_questoes_respondidas }}/{{ qtdQuestao }}
                            {{ 'gestor-avaliacoes.questoes-respondidas' | translate }}
                          </span>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td app-table-cell *ngIf="application.modo_aplicacao !== assessmentApplicationMode.Impressa">
                    <div class="flex justify-center">
                      <span class="md:txt-base whitespace-nowrap text-sm font-light">
                        {{ onTimeAssessment(student.data_inicio, student.data_fim) }}</span
                      >
                      <svg-icon
                        *ngIf="!!student.data_inicio || !!student.data_fim"
                        class="ml-3 flex h-5 w-5"
                        app-tooltip
                        [tooltip]="getTimeRange(student)"
                        src="assets/icons/icon-help.svg"
                      ></svg-icon>
                    </div>
                  </td>
                  <td *ngIf="isPAAEB" app-table-cell>
                    <ng-container *ngIf="!!student.tentativa_proficiencia?.length; else emptyTemplate">
                      <div *ngFor="let proficiencia of student.tentativa_proficiencia">
                        {{ proficiencia.padrao_disciplina.descricao }} -
                        {{ proficiencia.proficiencia | number : '1.2-2' }}
                      </div>
                    </ng-container>
                  </td>
                  <td app-table-cell>
                    <div class="flex w-[10rem] flex-col lg:w-full">
                      <ng-container
                        *ngIf="
                          [assessmentStatusAttemptEnum.Finish, assessmentStatusAttemptEnum.WaitDateResult].includes(
                            student.status
                          )
                        "
                      >
                        <span *ngIf="!!assesmentWeightType" class="font-bold">
                          {{ student.nota_calculada | currency : '' : '' : '1.2-2' }}
                        </span>
                        <span *ngIf="!assesmentWeightType" class="font-bold">
                          {{ student.nota_calculada | currency : '' : '' : '1.0-0' }} %
                        </span>
                      </ng-container>
                      <ng-container [ngSwitch]="student.status">
                        <span *ngSwitchCase="null" class="w-full rounded-full bg-gray-200 px-9 md:px-0">
                          <span *ngIf="application.modo_aplicacao === assessmentApplicationMode.Online" translate>
                            gestor-avaliacoes.status.nao-iniciado
                          </span>
                          <span *ngIf="application.modo_aplicacao !== assessmentApplicationMode.Online" translate
                            >gestor-avaliacoes.status.sem-respostas</span
                          >
                        </span>
                        <span
                          *ngSwitchCase="assessmentStatusAttemptEnum.InProgress"
                          class="w-full rounded-full bg-base-status-info px-9 text-white md:px-2"
                          translate
                        >
                          gestor-avaliacoes.status.em-andamento
                        </span>
                        <span
                          *ngSwitchCase="assessmentStatusAttemptEnum.Review"
                          class="w-full rounded-full bg-red-500 px-9 text-white md:px-2"
                          translate
                        >
                          gestor-avaliacoes.status.revisar
                        </span>
                        <span
                          *ngSwitchCase="assessmentStatusAttemptEnum.ModelUndefined"
                          class="w-full rounded-full bg-red-500 px-9 text-white md:px-2"
                          translate
                        >
                          gestor-avaliacoes.status.modelo-nao-identificado
                        </span>
                        <span
                          *ngSwitchCase="assessmentStatusAttemptEnum.ResponseCardInvalid"
                          class="w-full rounded-full bg-red-500 px-9 text-white md:px-2"
                          translate
                        >
                          gestor-avaliacoes.status.cartao-invalido
                        </span>
                        <span
                          *ngSwitchCase="assessmentStatusAttemptEnum.Wait"
                          class="w-full rounded-full bg-base-status-warning px-9 text-white md:px-2"
                          translate
                        >
                          gestor-avaliacoes.status.aguardando-correcao
                        </span>
                        <span
                          *ngSwitchCase="
                            assessmentStatusAttemptEnum.Finish || assessmentStatusAttemptEnum.WaitDateResult
                          "
                          class="w-full rounded-full bg-base-mantis-500 px-9 text-white md:px-2"
                          translate
                        >
                          gestor-avaliacoes.finalizada
                        </span>
                      </ng-container>
                    </div>
                  </td>
                  <td app-table-cell>
                    <div class="mx-4 flex items-center justify-center gap-2">
                      <ng-container *ngIf="!!onPreviewer(application)">
                        <button
                          *ngIf="application.modo_aplicacao === assessmentApplicationMode.Online; else auditTemplate"
                          app-flat-button
                          app-tooltip
                          [tooltip]="'gestor-avaliacoes.ver-respostas' | translate"
                          type="button"
                          (click)="onModalResolutionEvaluation(student.usuario_id)"
                          class="bg-base-status-warning hover:bg-base-status-warning"
                        >
                          <svg-icon
                            class="ml-1 flex h-6 w-6 text-white"
                            src="assets/icons/icon-clipboard-check.svg"
                          ></svg-icon>
                        </button>
                        <ng-template #auditTemplate>
                          <button
                            *ngIf="application.modo_aplicacao !== assessmentApplicationMode.Online"
                            app-flat-button
                            app-tooltip
                            [tooltip]="'gestor-avaliacoes.auditoria-respostas' | translate"
                            type="button"
                            (click)="onModalAnswersAuditComponent(student.usuario_id)"
                            class="bg-base-status-danger hover:bg-base-status-danger"
                          >
                            <svg-icon
                              class="ml-1 flex h-6 w-6 text-white"
                              src="assets/icons/icon-clipboard-check.svg"
                            ></svg-icon>
                          </button>
                        </ng-template>
                        <button
                          app-flat-button
                          app-tooltip
                          [tooltip]="'gestor-avaliacoes.espelho-avaliacao' | translate"
                          type="button"
                          (click)="onModalAssessmentsMirror(student.usuario_id, student.nome)"
                          class="my-1 bg-white"
                        >
                          <svg-icon
                            class="flex h-6 w-6 text-base-status-info"
                            src="assets/icons/icon-eyes.svg"
                          ></svg-icon>
                        </button>
                      </ng-container>
                      <button
                        *ngIf="application.modo_aplicacao !== assessmentApplicationMode.Impressa && isSchool"
                        app-flat-button
                        app-tooltip
                        [tooltip]="'gestor-avaliacoes.liberar-aluno' | translate"
                        type="button"
                        (click)="onOpenReleaseStudents(student)"
                        class="bg-white"
                      >
                        <svg-icon
                          class="flex h-6 w-6 text-green-500"
                          src="assets/icons/icon-user-clock-solid.svg"
                        ></svg-icon>
                      </button>
                    </div>
                  </td>
                </tr>
              </ng-container>
            </tbody>
          </table>
        </ng-container>
      </div>
    </ng-template>
  </ng-container>
</div>
<ng-template #emptyTemplate> - </ng-template>
