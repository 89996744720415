import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Page } from '@app/core/services/api.service';
import { PAGE_DEFAULT, PAGE_SIZE, PageData, Pagination } from '@app/shared/utils';

import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaginationComponent implements OnDestroy {
  @Output() public pageChange = new EventEmitter<Pagination>();
  public readonly pageSize = PAGE_SIZE;
  public page$ = new BehaviorSubject<Pagination>(undefined);

  @Input()
  public set page(data: PageData | Page<any>) {
    const sanitized = this.helper(data);
    this.page$.next({ ...data, ...sanitized });
  }

  public ngOnDestroy() {
    this.page$.complete();
  }

  public onPage(page: number, pages: Pagination): void {
    if (page !== pages.current) {
      const sanitized = this.helper({ ...pages, current: page });
      this.page = sanitized;
      this.pageChange.emit(sanitized);
    }
  }

  public onNext(pages: Pagination) {
    const page = pages.current + 1;
    this.onPage(page > pages.pages ? pages.pages : page, pages);
  }

  public onPrevious(pages: Pagination) {
    const page = pages.current - 1;
    this.onPage(page < 0 ? 1 : page, pages);
  }

  public onFirst(pages?: Pagination) {
    const data = pages || this.page$.value;
    this.onPage(1, data);
  }

  public onLast(pages: Pagination) {
    this.onPage(pages.pages, pages);
  }

  public onPageSize(size: number, pages: Pagination) {
    const sanitized = this.helper({ ...pages, size, current: 1 });
    this.page = sanitized;
    this.pageChange.emit(sanitized);
  }

  public pagination(pages: Pagination) {
    const start = pages.current * pages.size - pages.size + 1;
    const end = start + pages.size - 1;
    return {
      start: start > pages.total ? pages.total : start,
      end: end > pages.total ? pages.total : end,
      total: pages.total,
    };
  }

  public scaffold(data: Pagination): number[] {
    if (data.current - 1 <= 0) {
      return this.sanitize([data.current, data.current + 1, data.current + 2], data);
    }
    if (data.current + 1 > data.pages) {
      return this.sanitize([data.current - 2, data.current - 1, data.current], data);
    }
    return this.sanitize([data.current - 1, data.current, data.current + 1], data);
  }

  private sanitize(pages: number[], data: Pagination): number[] {
    return pages.filter((page) => page > 0 && page <= data.pages);
  }

  private helper(data: any): Pagination {
    const size = data?.size || +data?.per_page || PAGE_DEFAULT.size;
    const pages = +data?.last_page || Math.ceil(data.total / size);
    const current = data.current || +data?.current_page || PAGE_DEFAULT.current;
    return { total: +data?.total, size, current, pages };
  }
}
