import { Injectable } from '@angular/core';
import { NotificationSummary } from '@app/core/models';
import { PortalNotificationService } from '@app/core/services';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class NotificationHistoryService {
  private _history$ = new BehaviorSubject<NotificationSummary>(null);

  constructor(private _portalNotificationService: PortalNotificationService) {}

  public unread(): Observable<boolean> {
    return this._history$.pipe(
      switchMap((summary) => (!!summary ? of(summary) : this.summary())),
      map(({ unread }) => !!unread),
    );
  }

  public refresh() {
    this.summary().subscribe();
  }

  public summary(): Observable<NotificationSummary> {
    return this._portalNotificationService.summary().pipe(tap((summary) => this._history$.next(summary)));
  }

  public markAsRead(id: string): Observable<any> {
    return this._portalNotificationService.markAsRead(id, { read: true });
  }
}
