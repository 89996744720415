import { Activity } from './eclass-activity.model';

export enum ActivityTypeEnum {
  TaskSchedule = 1,
  TaskOnline = 2,
  AssessmentSchedule = 3,
  AssessmentOnline = 4,
  Chat = 5,
  Meet = 6,
}
interface helperActivity {
  evaluative: boolean;
  submit: boolean;
}

export function isOnlineActivity(activity: Activity): boolean {
  return [ActivityTypeEnum.AssessmentOnline, ActivityTypeEnum.TaskOnline].includes(activity.type);
}

export function helperTypeActivity(data: helperActivity): ActivityTypeEnum {
  if (data.evaluative && data.submit) {
    return ActivityTypeEnum.AssessmentOnline;
  }
  if (data.evaluative && !data.submit) {
    return ActivityTypeEnum.AssessmentSchedule;
  }
  if (!data.evaluative && data.submit) {
    return ActivityTypeEnum.TaskOnline;
  }
  if (!data.evaluative && !data.submit) {
    return ActivityTypeEnum.TaskSchedule;
  }
}
