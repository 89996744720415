import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { ChangeDetectionStrategy, Component, Inject, OnDestroy } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { NotificationService } from '@app/core';
import { VoucherService } from '@app/core/services/voucher.service';
import { notNull } from '@app/shared';
import { AppActions, AppSelectors } from '@app/store';
import { AppState } from '@app/store/app.state';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, catchError, finalize, of, switchMap, take } from 'rxjs';

@Component({
  templateUrl: './modal-voucher.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalVoucherComponent implements OnDestroy {
  public submitted$ = new BehaviorSubject(false);

  public voucher = new FormControl(undefined, [
    Validators.required,
    Validators.maxLength(16),
    Validators.minLength(16),
  ]);

  constructor(
    private _dialog: DialogRef,
    private store: Store<AppState>,
    private translate: TranslateService,
    @Inject(DIALOG_DATA) private data: any,
    private _voucherService: VoucherService,
    private notificationService: NotificationService,
  ) {}

  public ngOnDestroy() {
    this.submitted$.complete();
  }

  public onSave() {
    if (this.voucher.valid) {
      if (!this.submitted$.value) {
        this.submitted$.next(true);
        this.save();
      }
    } else {
      this.voucher.markAllAsTouched();
      this.notificationService.error(this.translate.instant('materiais-didaticos.voucher-incorreto'));
    }
  }

  public onEnterKeyPressed(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.onSave();
    }
  }

  private save() {
    this.store
      .select(AppSelectors.ActiveUser)
      .pipe(
        notNull(),
        take(1),
        switchMap(({ aluno_id }) =>
          this._voucherService.activate(this.voucher.value, aluno_id).pipe(
            finalize(() => this.submitted$.next(false)),
            catchError((resp) => of({ ret: false, msg: resp.error.msg })),
          ),
        ),
      )
      .subscribe((resp) => {
        if (!!resp.ret) {
          this.store.dispatch(AppActions.ProductReducer(resp.data));
          this.notificationService.success(resp.msg);
          this._dialog.close(this.data?.course?.produtos[0]?.id);
        } else {
          const msg = this.translate.instant('materiais-didaticos.voucher-incorreto');
          this.notificationService.error(resp.msg || msg);
        }
      });
  }
}
