<div
  class="mb-1 flex min-h-[80px] items-center justify-between rounded-lg bg-base-malachite-50 px-5 shadow-md"
  [ngClass]="typeActivity === 1 ? 'bg-base-malachite-100' : 'bg-modules-educational-materials-100'"
>
  <div class="info-left flex items-center gap-4">
    <div
      class="date flex h-[40px] w-[40px] flex-col items-center justify-center rounded-full bg-white shadow-inner shadow-base-gray-red-200"
    >
      <span class="text-2xl font-semibold leading-none">{{ day }}</span>
      <span class="leading-none">{{ month }}</span>
    </div>
    <div class="info-activity flex flex-col">
      <div class="flex gap-4">
        <h1 class="text-md font-semibold">{{ title }}</h1>
        <ng-container *ngIf="onLine">
          <div class="flex items-center justify-center rounded-2xl bg-gray-500 text-white">
            <span class="px-2 leading-none">ONLINE</span>
          </div>
        </ng-container>
      </div>
      <div class="flex gap-4">
        <h1 class="text-md">{{ subject }}</h1>
        <div class="flex gap-1">
          <svg-icon class="flex w-3 text-base-status-danger" src="assets/icons/icon-check.svg" alt="icon"></svg-icon>
          <svg-icon class="flex w-3 text-base-status-warning" src="assets/icons/icon-check.svg" alt="icon"></svg-icon>
        </div>
      </div>
    </div>
  </div>
  <div class="info-right">
    <svg-icon
      class="flex w-7 cursor-pointer"
      [ngClass]="{
        'text-base-malachite-500': typeActivity === 1,
        'text-modules-academic-500': typeActivity === 2
      }"
      [src]="
        actionActivity === 1
          ? 'assets/icons/icon-view-fine.svg'
          : actionActivity === 2
          ? 'assets/icons/icon-play-fine.svg'
          : 'assets/icons/icon-upload-fine.svg'
      "
      alt="icon-view.svg"
    ></svg-icon>
  </div>
</div>
