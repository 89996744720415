import { Injectable } from '@angular/core';
import { portalConst } from '@app/config';
import { Observable } from 'rxjs';
import { ApiService, Resp } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class EclassAttachmentService {
  private readonly _baseUrl = `${portalConst.api.eclass}/v1/attachments`;

  constructor(private api: ApiService) {}

  public viewed(attachmentId: number): Observable<Resp<string>> {
    return this.api.get<string>(`${this._baseUrl}/viewed/${attachmentId}`);
  }
}
