import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ModalAssessmentExecutionState } from './modal-assessment-execution.state';

export const featureKey = 'modalAssessmentExecution';

export const ModalAssessmentExecutionFeature = createFeatureSelector<ModalAssessmentExecutionState>(featureKey);

export const selectState = createSelector(
  ModalAssessmentExecutionFeature,
  (state: ModalAssessmentExecutionState) => state,
);
export const selectQuestionActived = createSelector(
  ModalAssessmentExecutionFeature,
  ({ question_actived_uuid, questoes }: ModalAssessmentExecutionState) => {
    const index = questoes.findIndex(({ uuid }) => uuid === question_actived_uuid);
    return { ...questoes[index], index };
  },
);
export const selectAssessment = createSelector(
  ModalAssessmentExecutionFeature,
  ({ avaliacao }: ModalAssessmentExecutionState) => avaliacao,
);
export const selectAttempt = createSelector(
  ModalAssessmentExecutionFeature,
  ({ tentativa }: ModalAssessmentExecutionState) => tentativa,
);
export const selectApplication = createSelector(
  ModalAssessmentExecutionFeature,
  ({ aplicacao }: ModalAssessmentExecutionState) => aplicacao,
);
export const selectQuestions = createSelector(
  ModalAssessmentExecutionFeature,
  ({ questoes }: ModalAssessmentExecutionState) => questoes,
);
export const selectIsPreview = createSelector(
  ModalAssessmentExecutionFeature,
  ({ extra }: ModalAssessmentExecutionState) => !!extra?.preview,
);
export const selectFinishUntil = createSelector(
  ModalAssessmentExecutionFeature,
  ({ finish_until }: ModalAssessmentExecutionState) => finish_until,
);
export const selectStartedAt = createSelector(
  ModalAssessmentExecutionFeature,
  ({ started_at }: ModalAssessmentExecutionState) => started_at,
);
export const selectFinishedTime = createSelector(
  ModalAssessmentExecutionFeature,
  ({ finished_time }: ModalAssessmentExecutionState) => finished_time,
);
