import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-card-carousel',
  templateUrl: './card-carousel.component.html',
})
export class CardCarouselComponent {
  @Input() public imageUrl: string;
  @Input() public index: number;
}
