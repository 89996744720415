import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-side-right-activity-dashboard',
  templateUrl: './side-right-activity-dashboard.component.html',
  styles: [],
})
export class SideRightActivityDashboardComponent {
  @Input() public typeActivity?: number;
  @Input() public day?: number;
  @Input() public month?: string;
  @Input() public title?: string;
  @Input() public subject?: string;
  @Input() public onLine?: boolean;
  @Input() public actionActivity?: number;
}
