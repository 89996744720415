<div class="container__logo w-[10rem]">
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 135.84 166.97">
    <g>
      <path
        d="m67.87,40.17c-4.34.02-8.61-4.19-8.42-8.95.4-9.47,3.7-7.69,7.84-27.06.04-.16.42-.52.62-.46.19-.06.65.3.68.45,4.29,19.46,7.57,17.69,8.03,27.06.23,4.76-4.41,8.97-8.75,8.95m16.05,6.75c2.17-4.02,5.86-13.46-5.17-27.99C69.66,6.94,69.39.68,67.91.05V0c-.07,0-.13,0-.19,0-.06,0-.12,0-.18,0v.05c-1.43.64-1.43,6.92-10.54,18.88-11.04,14.51-7.37,23.96-5.21,27.98,2.86,5.32,8.03,8.64,12.62,9.61v109.53c0,.49.4.9.9.9h5.2c.5,0,.9-.41.9-.9V56.48c4.57-1.05,9.7-4.35,12.51-9.56"
        fill="currentColor"
      />
      <path
        d="m131.73,69.48c-19.45,4.32-17.68,7.6-27.05,8.07-4.75.24-8.97-4.39-8.96-8.73-.03-4.34,4.18-8.62,8.93-8.43,9.47.38,7.69,3.69,27.08,7.8.16.03.52.42.46.61.06.19-.3.65-.45.69m-14.84-11.41c-14.53-11.02-23.97-7.33-27.99-5.17-2.04,1.09-6.42,4.59-8.6,9.42-.92,2.03-2.97,7.14-2.49,14.69v89.17c.01.43.41.77.91.77h33.31c.67,0,1.21-.55,1.21-1.22s-.69-1.16-1.21-1.22c-30.1-4.04-28.91-65.28-27.96-82.59.03-.44.46-.15.59,0,0,0,0,0,.01,0,1.24,1.2,2.67,2.27,4.26,3.12,4.03,2.17,13.47,5.83,27.99-5.21,12.9-9.82,19.19-9.04,18.92-10.9.28-1.86-6.02-1.08-18.94-10.87"
        fill="currentColor"
      />
      <path
        d="m31.16,77.56c-9.38-.47-7.6-3.74-27.05-8.07-.16-.04-.51-.49-.45-.68-.07-.19.3-.58.45-.62,19.39-4.11,17.61-7.42,27.08-7.79,4.75-.19,8.96,4.09,8.93,8.43.01,4.34-4.21,8.97-8.96,8.73m15.79-24.65c-4.02-2.17-13.46-5.85-27.99,5.17C6.03,67.88-.27,67.09,0,68.95c-.28,1.86,6.01,1.08,18.92,10.9,14.51,11.04,23.96,7.37,27.98,5.21,1.59-.86,3.02-1.93,4.26-3.12,0,0,0,0,0,0,.12-.16.56-.44.59,0,.95,17.31,2.13,78.55-27.96,82.59-.53.05-1.22.55-1.22,1.21s.55,1.22,1.22,1.22h33.31c.5,0,.9-.35.9-.78v-89.17c.5-7.55-1.56-12.67-2.48-14.69-2.18-4.83-6.56-8.33-8.6-9.43"
        fill="currentColor"
      />
    </g>
  </svg>
</div>
