import { Pipe, PipeTransform } from '@angular/core';
import { isEmpty } from 'lodash';

@Pipe({
  name: 'filterEmpty',
})
export class FilterEmptyPipe implements PipeTransform {
  public transform(values: any[] = [], fields: string): any[] {
    if (fields && !isEmpty(values)) {
      return values.filter((value) => !!value[fields]);
    }
    return values;
  }
}
