import { saveAs } from 'file-saver';
import { Vimeo } from '@app/shared/utils';

export function downloadFile(file: any) {
  const ext = ['pdf', 'jpg', 'jpeg', 'xls', 'xlsx', 'ppt', 'pptx', 'doc', 'docx', 'mp4', 'png'];

  const data = {
    title: file?.titulo || file?.title,
    extension: file?.extension || file?.extensao,
    url: file?.url || file?.file,
  };

  if (ext.indexOf('html') !== -1 || file?.html) {
    const body = '<html><body>' + file.html + '</body></html>';
    const blob = new Blob([body], { type: 'text/plain;charset=utf-8' });
    saveAs(blob, data.title + '.html');
    return;
  }
  const documentTitle = file.titulo ? file.titulo : file.title;

  if (ext.indexOf(data.extension) === -1) {
    window.open(data.url, '_blank');
  } else if (!!file.vimeo_hash) {
    const videoUrl = Vimeo.getVideoUrl(file);
    saveAs(videoUrl, documentTitle);
  } else {
    saveAs(data.url, documentTitle);
  }
}
