import { Injectable } from '@angular/core';
import { portalConst } from '@app/config';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class CpbServicesAcademicService {
  private readonly baseUrl = `${portalConst.api.cpb_services}/v1/academic`;

  constructor(private _api: ApiService) {}

  public getIncidentReports(data: any): Observable<any> {
    return this._api.get(`${this.baseUrl}/incident-reports`, data);
  }

  public getStudentSchedule(data: any): Observable<any> {
    return this._api.get(`${this.baseUrl}/class-schedules`, data);
  }

  public getGradesChilds(data: any): Observable<any> {
    return this._api.get(`${this.baseUrl}/grades-childs`, data);
  }

  public getAvaliations(data: any): Observable<any> {
    return this._api.get(`${this.baseUrl}/avaliations`, data);
  }

  public getGrades(data: any): Observable<any> {
    return this._api.get(`${this.baseUrl}/grades`, data);
  }

  public getAverage(data: any): Observable<any> {
    return this._api.get(`${this.baseUrl}/average`, data);
  }

  public getAbsences(data: any): Observable<any> {
    return this._api.get(`${this.baseUrl}/absences`, data);
  }

  public getActivity(data: any): Observable<any> {
    return this._api.get(`${this.baseUrl}/tasks`, data);
  }

  public getProgramContents(data: any): Observable<any> {
    return this._api.get(`${this.baseUrl}/contents`, data);
  }
}
