import { DIALOG_DATA } from '@angular/cdk/dialog';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { AlertService, NotificationService } from '@app/core';
import { AssessmentType } from '@app/core/models';
import { AssessmentApplicationService } from '@app/core/services/assessment-application.service';
import { notNull, safeEmpty, safeEmptyList } from '@app/shared';
import { TranslateService } from '@ngx-translate/core';
import { isBefore } from 'date-fns';
import { now } from 'lodash';
import { DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { BehaviorSubject, combineLatest, finalize, mergeMap, Subject, switchMap, take, tap } from 'rxjs';

@Component({
  selector: 'app-modal-upload-files',
  templateUrl: './modal-upload-files.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalUploadFilesComponent implements OnDestroy {
  public uploadHistory$ = new BehaviorSubject<any>(undefined);
  public loading$ = new BehaviorSubject<boolean>(false);
  public loadingHistory$ = new BehaviorSubject<boolean>(false);
  public resetDropzone$ = new Subject<any>();
  public modelCtrl = new FormControl(undefined, Validators.required);
  public files: any[] = [];
  public printResponseCard = [
    {
      id: 1,
      name: this._translate.instant('gestor-avaliacoes.modelo-cartao-resposta-folha-inteira'),
      icon: 'assets/icons/icon-answer-card-1.svg',
    },
    // {
    //   id: 2,
    //   name: this._translate.instant('gestor-avaliacoes.modelo-cartao-resposta-meia-folha'),
    //   icon: 'assets/icons/icon-answer-card-2.svg',
    // },
  ];

  public config: DropzoneConfigInterface = {
    clickable: true,
    url: 'dummy-url',
    createImageThumbnails: false,
    maxFiles: 80,
    maxFilesize: 200,
    acceptedFiles: '.pdf,.jpg,.jpeg,.png',
    previewsContainer: false,
    autoProcessQueue: false,
  };

  constructor(
    @Inject(DIALOG_DATA) public data: any,
    private _alertService: AlertService,
    private _changeDetectionRef: ChangeDetectorRef,
    private _notificationService: NotificationService,
    private _translate: TranslateService,
    private _assessmentApplicationService: AssessmentApplicationService,
  ) {
    this.loadingHistory$.next(true);
    this._assessmentApplicationService
      .historyUploadResponseCard(data.application.uuid)
      .pipe(
        take(1),
        finalize(() => this.loadingHistory$.next(false)),
        safeEmptyList(),
      )
      .subscribe((data) => this.uploadHistory$.next(data));

    if (this.data.modo_aplicacao === AssessmentType.Redacao) {
      this.printResponseCard = [
        {
          id: 3,
          name: this._translate.instant('gestor-avaliacoes.modelo-cartao-resposta-folha-redacao'),
          icon: 'assets/icons/icon-answer-card-2.svg',
        },
      ];
    }
  }

  public ngOnDestroy(): void {
    this.loading$.complete();
  }

  public onUpload(data: any) {
    this.resetDropzone$.next(now());
    this.files.push(data);
  }

  public save() {
    this.modelCtrl.markAllAsTouched();
    if (this.modelCtrl.valid) {
      this._alertService
        .confirm({
          message: this._translate.instant('eclass.salvar-importacao'),
        })
        .pipe(
          notNull(),
          mergeMap(() =>
            combineLatest(
              this.files.map((file) => {
                this.loading$.next(true);
                file.formData.append('modelo', this.modelCtrl.value);
                return this._assessmentApplicationService.uploadResponseCard(this.data.application.uuid, file.formData);
              }),
            ),
          ),
        )
        .subscribe({
          next: (res) => {
            this.loading$.next(false);
            this._changeDetectionRef.detectChanges();
            const updatedHistory = [...res.map((res) => res.data), ...(this.uploadHistory$.value || [])];
            this.uploadHistory$.next(updatedHistory);
            this.files = [];
            this._notificationService.success(this._translate.instant('geral.sucesso'));
          },
          error: () => {
            this.loading$.next(false);
            this._notificationService.error(this._translate.instant('geral.upload-erro'));
          },
        });
    }
  }

  public onRemove(historyId: string) {
    this._alertService
      .confirm({
        message: this._translate.instant('gestor-avaliacoes.confirmar-apagar-cartao-resposta'),
      })
      .pipe(
        notNull(),
        tap(() => this.loading$.next(true)),
        switchMap(() =>
          this._assessmentApplicationService
            .removeResponseCard(this.data.application.uuid, historyId)
            .pipe(safeEmpty()),
        ),
      )
      .subscribe({
        next: () => {
          this.loading$.next(false);
          this.uploadHistory$.next(this.uploadHistory$.value.filter((history) => history.id !== historyId));
          this._notificationService.success(this._translate.instant('geral.sucesso'));
        },
        error: () => {
          this.loading$.next(false);
          this._notificationService.error(this._translate.instant('geral.erro-ao-salvar'));
        },
      });
  }

  public removeFile(index: number): void {
    this.files.splice(index, 1);
  }

  public downloadFile(file: { id: number; file_url: string }): void {
    if (file?.file_url) {
      window.open(file.file_url, '_blank');
    }
  }
}
