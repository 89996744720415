<ng-select
  app-select
  [placeholder]="placeholder | translate"
  [items]="employees$ | async"
  bindValue="usuario_id"
  bindLabel="nome"
  [notFoundText]="'geral.nao-encontrado' | translate"
  [clearable]="!disable"
>
</ng-select>
