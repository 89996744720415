import { DIALOG_DATA } from '@angular/cdk/dialog';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AlertService, NotificationService } from '@app/core';
import { AssessmentApplicationService } from '@app/core/services/assessment-application.service';
import { notNull, safeEmptyList } from '@app/shared';
import { TranslateService } from '@ngx-translate/core';
import { now } from 'lodash';
import { DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { BehaviorSubject, combineLatest, mergeMap, Subject } from 'rxjs';

@Component({
  selector: 'app-modal-upload-files',
  templateUrl: './modal-upload-files.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalUploadFilesComponent implements OnDestroy {
  public uploadHistory$ = new BehaviorSubject<any>([]);
  public loading$ = new BehaviorSubject<boolean>(false);
  public resetDropzone$ = new Subject<any>();
  public modelCtrl = new FormControl(undefined, Validators.required);
  public files: any[] = [];
  public printResponseCard = [
    {
      id: 1,
      name: this._translate.instant('gestor-avaliacoes.modelo-cartao-resposta-folha-inteira'),
      icon: 'assets/icons/icon-answer-card-1.svg',
    },
    {
      id: 2,
      name: this._translate.instant('gestor-avaliacoes.modelo-cartao-resposta-meia-folha'),
      icon: 'assets/icons/icon-answer-card-2.svg',
    },
    {
      id: 3,
      name: this._translate.instant('gestor-avaliacoes.modelo-cartao-resposta-folha-redacao'),
      icon: 'assets/icons/icon-answer-card-2.svg',
    },
  ];

  public config: DropzoneConfigInterface = {
    clickable: true,
    url: 'dummy-url',
    createImageThumbnails: false,
    maxFiles: 80,
    maxFilesize: 200,
    acceptedFiles: '.pdf,.jpg,.jpeg,.png',
    previewsContainer: false,
    autoProcessQueue: false,
  };

  constructor(
    @Inject(DIALOG_DATA) public data: any,
    private _alertService: AlertService,
    private _changeDetectionRef: ChangeDetectorRef,
    private _notificationService: NotificationService,
    private _translate: TranslateService,
    private _assessmentApplicationService: AssessmentApplicationService,
  ) {
    this._assessmentApplicationService
      .historyUploadResponseCard(data.application_uuid)
      .pipe(safeEmptyList())
      .subscribe((res) => {
        this.uploadHistory$.next(res);
      });
  }

  public ngOnDestroy(): void {
    this.loading$.complete();
  }

  public onUpload(data: any) {
    this.resetDropzone$.next(now());
    this.files.push(data);
  }

  public save() {
    this.modelCtrl.markAllAsTouched();
    if (this.modelCtrl.valid) {
      this._alertService
        .confirm({
          message: this._translate.instant('eclass.salvar-importacao'),
        })
        .pipe(
          notNull(),
          mergeMap(() =>
            combineLatest(
              this.files.map((file) => {
                this.loading$.next(true);
                file.formData.append('modelo', this.modelCtrl.value);
                return this._assessmentApplicationService.uploadResponseCard(this.data.application_uuid, file.formData);
              }),
            ),
          ),
        )
        .subscribe({
          next: (res) => {
            this.loading$.next(false);
            this._changeDetectionRef.detectChanges();
            const updatedHistory = [...this.uploadHistory$.value, ...res.map((res) => res.data)];
            this.uploadHistory$.next(updatedHistory);
            this.files = [];
            this._notificationService.success(this._translate.instant('geral.sucesso'));
          },
          error: () => {
            this.loading$.next(false);
            this._notificationService.error(this._translate.instant('geral.upload-erro'));
          },
        });
    }
  }

  public resend(id: string): void {
    this._assessmentApplicationService.resendResponseCard(this.data.application_uuid, id).subscribe({
      next: () => {
        this._notificationService.success(this._translate.instant('geral.sucesso'));
      },
      error: () => {
        this._notificationService.error(this._translate.instant('gestor-avaliacoes.aviso-cartao-resposta'));
      },
    });
  }

  public removeFile(index: number): void {
    this.files.splice(index, 1);
  }

  public downloadFile(file: { id: number; file_url: string }): void {
    if (file?.file_url) {
      window.open(file.file_url, '_blank');
    }
  }
}
