import { Dialog } from '@angular/cdk/dialog';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Content } from '@app/core/models';
import { ModalReadContentComponent } from './modal-read-content.component';
@Component({
  selector: 'app-card-cover-summary',
  templateUrl: './card-cover-summary.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { class: 'relative w-60 cursor-pointer rounded-xl border border-base-gray-light-600 shadow-lg' },
})
export class CardCoverSummaryComponent {
  @Input({ required: true }) public content: Content;

  constructor(public dialog: Dialog) {}

  public onPreview() {
    this.dialog.open<string>(ModalReadContentComponent, {
      data: this.content,
    });
  }
}
