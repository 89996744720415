import { Directive, Inject, Optional, TemplateRef } from '@angular/core';
import { SIDENAV_GROUP, SidenavGroupComponent } from './sidenav-group.component';

@Directive({
  selector: '[app-sidenav-outer]',
})
export class SidenavOuterDirective {
  constructor(
    @Optional() public templateRef: TemplateRef<any>,
    @Inject(SIDENAV_GROUP) sidenavGroup: SidenavGroupComponent,
  ) {
    sidenavGroup.setContent(this);
  }
}
