import { NgModule } from '@angular/core';
import { TemplateModule } from '../template/template.module';
import { CardCoverSummaryComponent } from './card-cover-summary.component';
import { ContentsPhotoComponent } from './contents-photo.component';
import { ModalReadContentComponent } from './modal-read-content.component';
import { PipesModule } from '@app/shared/pipes/pipes.module';

@NgModule({
  declarations: [CardCoverSummaryComponent, ContentsPhotoComponent, ModalReadContentComponent],
  imports: [TemplateModule, PipesModule],
  exports: [CardCoverSummaryComponent],
})
export class CardCoverSummaryModule {}
