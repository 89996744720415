import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, NavigationEnd, Params, Router } from '@angular/router';
import {
  STANDARD_SIDENAV,
  SidenavData,
  SidenavDirection,
} from '@app/shared/modules/template/components/sidenav/sidenav';
import { isEqual, omit } from 'lodash';
import { Observable, Subject, distinctUntilChanged, filter, map, startWith, tap } from 'rxjs';

export interface SidenavResponse {
  data: SidenavData;
  snapshot: Params;
  route: ActivatedRouteSnapshot;
}
@Injectable({
  providedIn: 'root',
})
export class SidenavService {
  public _start$ = new Subject<SidenavResponse>();
  public _end$ = new Subject<SidenavResponse>();
  public start$: Observable<SidenavResponse>;
  public end$: Observable<SidenavResponse>;

  constructor(private router: Router) {
    this.start$ = this.route(SidenavDirection.Start);
    this.end$ = this.route(SidenavDirection.End);
  }

  private route(position: SidenavDirection): Observable<SidenavResponse> {
    return this.router.events.pipe(
      distinctUntilChanged(isEqual),
      filter((event) => event instanceof NavigationEnd),
      startWith(this.router.routerState.snapshot.root),
      tap((sidenav) => ({ sidenav })),
      map(() => this.sidenav(this.router.routerState.snapshot.root, position)),
      map(this.dataBuilder),
    );
  }

  private sidenav(route: ActivatedRouteSnapshot, position: SidenavDirection): SidenavResponse {
    const sidenav = this.sidenavData(route, position);
    if (!route.firstChild) {
      return sidenav;
    }
    return this.sidenav(route.firstChild, position) || sidenav;
  }

  private sidenavData(route: ActivatedRouteSnapshot, position: SidenavDirection): SidenavResponse {
    const data = route?.data[position];
    if (!!data?.componentRef) {
      return { data, snapshot: omit(route.data, position), route };
    }
    return null;
  }

  private dataBuilder(sidedata: SidenavResponse): any {
    if (!!sidedata) {
      return {
        ...STANDARD_SIDENAV,
        ...sidedata?.data,
        ...sidedata,
        data: { ...STANDARD_SIDENAV, ...sidedata?.data, snapshot: sidedata?.snapshot, route: sidedata?.route },
      };
    }
    return null;
  }
}
