import { AcademicAbsence } from './academic-absence.model';
import { SchoolSubject } from './academic-school-subject.model';

export interface AcademicSchedule {
  id: number;
  subject_id: number;
  date: string;
  group_schedule: number;
  note_content: string;
  status_absence: number;
  subject?: SchoolSubject;
  absences?: AcademicAbsence[];
}
