import { lowerCase, startsWith } from 'lodash';

export enum LanguageEnum {
  Portugues = 1,
  English = 2,
  Espanol = 3,
}

export enum Lang {
  ptBr = 'pt-br',
  en = 'en',
  es = 'es',
}

export interface Language {
  id: number;
  nome: string;
  sigla: Lang;
  img_flag: string;
}

export function langHelper(language: string): string {
  return Object.values(Lang).find((lang) => startsWith(lowerCase(language), lowerCase(lang)));
}
