import { ChangeDetectionStrategy, Component, OnDestroy, TemplateRef } from '@angular/core';
import { GuardValidationService, LayoutTemplateService, SidenavResponse, SidenavService } from '@app/core/services';
import { STANDARD_SIDENAV } from '@app/shared/modules/template/components/sidenav/sidenav';
import { Observable, Subject, map, takeUntil } from 'rxjs';

@Component({
  templateUrl: './layout-games.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [GuardValidationService],
})
export class LayoutGamesComponent implements OnDestroy {
  public start$: Observable<SidenavResponse>;
  public end$: Observable<SidenavResponse>;
  public template$: Observable<TemplateRef<any>>;
  public validation$: Observable<any>;
  private _destroy$ = new Subject<void>();

  constructor(
    private sidenavService: SidenavService,
    private layoutTemplateService: LayoutTemplateService,
    private guardValidationService: GuardValidationService,
  ) {
    this.start$ = this.sidenavService.start$.pipe(takeUntil(this._destroy$), map(this.dataBuilder));
    this.end$ = this.sidenavService.end$.pipe(takeUntil(this._destroy$), map(this.dataBuilder));
    this.template$ = this.layoutTemplateService.template$.pipe(takeUntil(this._destroy$));
    this.validation$ = this.guardValidationService.validation$.pipe(takeUntil(this._destroy$));
  }

  public ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

  public label(validation: string): string {
    return `geral.guards.${validation}`;
  }

  public dataBuilder(sidedata: SidenavResponse): any {
    if (!!sidedata) {
      return { ...STANDARD_SIDENAV, ...sidedata.data, data: { ...sidedata.data.data, snapshot: sidedata.snapshot } };
    }
    return null;
  }
}
