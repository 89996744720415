import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import localeEn from '@angular/common/locales/en';
import localeEs from '@angular/common/locales/es';
import localePt from '@angular/common/locales/pt';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { StoreDevTools } from './config';
import { CoreModule } from './core/core.module';
import { PermissionsComponent } from './core/guards';
import { LayoutsModule } from './layouts/layouts.module';
import { AppStoreModule } from './store/app-store.module';
registerLocaleData(localePt);
registerLocaleData(localeEn);
registerLocaleData(localeEs);

@NgModule({
  declarations: [AppComponent, PermissionsComponent],
  imports: [
    CoreModule,
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    LayoutsModule,
    AppStoreModule,
    BrowserAnimationsModule,
    StoreModule.forRoot(
      {},
      {
        runtimeChecks: {
          strictStateImmutability: true,
          strictActionImmutability: true,
        },
      },
    ),
    EffectsModule.forRoot([]),
    StoreDevTools,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
