import { AfterViewInit, ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { portalConst } from '@app/config';
import { ContentsCategories } from '@app/core/models';
import { ContentsCategoriesService } from '@app/core/services';
import { PAGE_DEFAULT, PageData, compareEquals, switchDelay } from '@app/shared/utils';
import { isEmpty, isEqual } from 'lodash';
import {
  BehaviorSubject,
  Observable,
  Subject,
  combineLatest,
  distinctUntilChanged,
  map,
  startWith,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs';

const CATEGORY = [
  { id: 22, label: 'servicos.planos-aula' },
  { id: 23, label: 'servicos.apoio-alfabetizacao' },
  { id: 24, label: 'servicos.referenciais-curriculares' },
  { id: 21, label: 'servicos.apoio-pedagogico' },
  { id: 3, label: 'servicos.audiovisuais' },
  { id: 4, label: 'servicos.jogos' },
  { id: 10, label: 'servicos.manuais' },
  { id: 2, label: 'servicos.multimidia' },
];

@Component({
  templateUrl: './modal-pedagogical-content.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalPedagogicalContentComponent implements OnInit, AfterViewInit, OnDestroy {
  public readonly compareEquals = compareEquals;
  public readonly category = CATEGORY;
  public form: FormGroup;
  public data$: Observable<any[]>;
  public load$: Observable<boolean>;
  public categories$: Observable<ContentsCategories>;
  private _destroy$ = new Subject<void>();
  private _load$ = new Subject<boolean>();
  private _page$ = new BehaviorSubject<any>(PAGE_DEFAULT);

  constructor(private formBuilder: FormBuilder, private contentsCategoriesService: ContentsCategoriesService) {
    this.load$ = this._load$.asObservable().pipe(switchDelay(), startWith(true));
  }

  public ngOnInit() {
    this.listData();
    this.initForm();
  }

  public ngAfterViewInit() {
    this.loadListeners();
  }

  public ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
    this._load$.complete();
    this._page$.complete();
  }

  public onChangePage(data: PageData) {
    this._page$.next(data);
  }

  public filterBy(data = [], yearId: number, disciplineId: number): any[] {
    return data.filter(({ ano_id, disciplina_id }) => ano_id === yearId && disciplina_id === disciplineId);
  }

  private listData() {
    this.categories$ = this.contentsCategoriesService.categories().pipe(map((data) => data.result?.categorias));
  }

  private initForm() {
    this.form = this.formBuilder.group({
      nivel_id: undefined,
      ano_id: [{ value: undefined, disabled: true }],
      disciplina_id: [{ value: undefined, disabled: true }],
      fasciculo_id: [{ value: undefined, disabled: true }],
      busca: undefined,
      subfiltro: undefined,
    });
  }

  private loadListeners() {
    this.inputListener('nivel_id', 'ano_id');
    this.inputListener('ano_id', 'disciplina_id');
    this.inputListener('disciplina_id', 'fasciculo_id');
    this.searchListeners();
  }

  private searchListeners() {
    const form$ = this.form.valueChanges.pipe(
      switchDelay(),
      startWith(this.form.value),
      map((res) => ({ ...res, subfiltro: !!res.subfiltro ? res.subfiltro : 1 })),
    );
    const page$ = this._page$.pipe(
      distinctUntilChanged(isEqual),
      map((page) => ({ page: page.current, qtde: page.size })),
    );
    this.data$ = combineLatest([form$, page$]).pipe(
      takeUntil(this._destroy$),
      tap(() => this._load$.next(true)),
      switchMap(([form, page]) => this.contentsCategoriesService.content({ ...form, ...page })),
      map(({ result }) => result?.conteudos),
      map((res) => {
        if (isEmpty(res)) {
          return { data: [], total: 0, last_page: 1, current: 1 };
        }
        return {
          ...res,
          data: res.data.map((item) => ({
            ...item,
            title: item.titulo,
            author: item.autor,
            publishing_date: item.updated_at,
            description: item.descricao,
            imagem: `${portalConst.aws.cpbedu}/images/cms/${item.imagem_url}`,
          })),
        };
      }),
      tap(() => this._load$.next(false)),
    );
  }

  private inputListener(from: string, target: string) {
    this.form
      .get(from)
      .valueChanges.pipe(takeUntil(this._destroy$))
      .subscribe((value) => {
        if (!!value) {
          this.form.get(target).enable();
        } else {
          this.form.get(target).setValue(null);
          this.form.get(target).disable();
        }
      });
  }
}
