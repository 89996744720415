<div
  class="block h-[calc(100vh_-_25rem)] w-[300px] overflow-y-auto bg-base-whisper-400 pt-28 lg:h-full lg:pt-0"
  *ngIf="{ academicTree: academicTree$ | async, loading: loading$ | async } as data"
>
  <ng-container *ngIf="!data.loading && !!data.academicTree; else loadTemplate">
    <div
      class="relative z-20 flex w-full items-center justify-center bg-white px-3 shadow-md"
      *ngIf="!(data.academicTree.levels | isEmpty); else emptyLevelTemplate"
    >
      <ng-select
        app-select
        class="ng-select-modern w-full lg:!pt-10"
        [clearable]="false"
        [searchable]="false"
        [formControl]="levelCtrl"
      >
        <ng-option *ngFor="let item of data.academicTree.levels" [value]="item.id">
          {{ item.description }}
        </ng-option>
      </ng-select>
    </div>
    <ng-template #emptyLevelTemplate>
      <div class="block lg:pt-10"></div>
    </ng-template>

    <div class="relative z-10 bg-white shadow-inner-bg" *ngIf="isShow(data.academicTree.labels)">
      <app-filter-badge [badges]="data.academicTree.labels" (selected)="onFilter($event)"> </app-filter-badge>
    </div>

    <ng-container *ngIf="data.academicTree.items as items">
      <ng-container *ngIf="!(items | isEmpty); else emptyTemplate">
        <ng-container *ngIf="isShow(items); else singleTemplate">
          <app-accordion>
            <app-accordion-item
              *ngFor="let item of items"
              headerClass="!mb-0 !rounded-none shadow-md !bg-inherit border"
              class="border-b-zinc-300 bg-white last:border-none"
              [ngClass]="{ 'border-none !bg-theme-100 ': accordion.expanded }"
              #accordion
              [expanded]="isExpanded(item, data.academicTree.filter)"
            >
              <div
                app-accordion-header
                class="flex items-center gap-2 text-zinc-700"
                [ngClass]="{ 'text-theme-700': accordion.expanded }"
              >
                {{ item.label }}
                <span
                  class="flex h-6 w-auto min-w-[1.5rem] items-center justify-center rounded-full bg-zinc-500 px-2 text-white"
                  [ngClass]="{ '!bg-theme-500 ': accordion.expanded }"
                >
                  {{ item.value.length }}
                </span>
              </div>
              <div app-accordion-content>
                <ng-container
                  [ngTemplateOutlet]="itemTemplate"
                  [ngTemplateOutletContext]="{ $implicit: item.value }"
                ></ng-container>
              </div>
            </app-accordion-item>
          </app-accordion>
        </ng-container>
        <ng-template #singleTemplate>
          <ng-container
            [ngTemplateOutlet]="itemTemplate"
            [ngTemplateOutletContext]="{ $implicit: (items | first).value }"
          ></ng-container>
        </ng-template>
        <ng-template #itemTemplate let-item>
          <div class="flex flex-col gap-2 px-4 py-3">
            <ng-container *ngFor="let element of item">
              <a
                app-button
                (click)="onUpdate(element.query)"
                *ngIf="{ item: isActive(element.query, data.academicTree.filter) } as active"
                class="ng-star-inserted flex h-20 items-center justify-between gap-2 bg-white px-5 py-2 !brightness-100 hover:bg-theme-100"
                [ngClass]="{
                  'mr-5 !rounded-l-full !bg-theme-600 pr-0 text-white after:absolute after:-right-[15px] after:border-y-[15px] after:border-l-[15px] after:border-y-transparent after:border-l-theme-600':
                    active.item,
                  '!rounded-full ': !active.item
                }"
              >
                <span class="truncate">{{ element.name }}</span>
                <svg-icon
                  class="flex h-2 w-2"
                  [ngClass]="element.pinColor"
                  src="assets/icons/icon-ellipse.svg"
                ></svg-icon>
              </a>
            </ng-container>
          </div>
        </ng-template>
      </ng-container>
      <ng-template #emptyTemplate>
        <div class="absolute top-0 h-[calc(100vh_-_13rem)] w-full">
          <app-jumbotron class="px-4" translate>geral.nenhum-dado</app-jumbotron>
        </div>
      </ng-template>
    </ng-container>
  </ng-container>
  <ng-template #loadTemplate>
    <app-skeleton class="block"></app-skeleton>
  </ng-template>
</div>
