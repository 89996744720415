import { isArray, isBoolean, isNil, isNumber, isObject, isString, flattenDeep, split } from 'lodash';
import { reduce } from './commons';
import { isDate } from 'date-fns';

function serialize(value: any): string {
  if (isNumber(value)) {
    return String(value);
  } else if (isBoolean(value)) {
    return String(value);
  } else if (isArray(value)) {
    const param = value.join(',');
    return !!param ? param : undefined;
  } else if (value && isString(value)) {
    return value;
  } else if (isDate(value)) {
    return value?.toJSON();
  } else if (isObject(value)) {
    return JSON.stringify(value);
  }
  return value;
}

function sanitize(value: any = {}): any {
  return reduce(
    Object.entries(value)
      .filter(([key, value]) => !isNil(value))
      .map(([key, value]) => ({ [key]: value })),
  );
}

function deserialize(value: any): any {
  if (`${value}`.match(/^(?!0\d)\d*(\.\d+)?$/)) {
    return Number(value);
  }
  return value;
}

function deserializeArray(data: any): any[] {
  if (!isNil(data)) {
    const value = split(data, ',');
    return flattenDeep([value]).map(deserialize);
  }
  return [];
}

function deserializeParams(value: any = {}): any {
  return reduce(Object.entries(value).map(([key, value]) => ({ [key]: HttpParamsUtils.deserialize(value) })));
}

export const HttpParamsUtils = {
  serialize,
  sanitize,
  deserialize,
  deserializeArray,
  deserializeParams,
};
