import { Pipe, PipeTransform } from '@angular/core';
import { isEmpty } from 'lodash';
import { compareLike } from '../utils/commons';

@Pipe({
  name: 'filter',
})
export class FilterPipe implements PipeTransform {
  public transform(values: any[] = [], term: string, fields: any[] = [], compare = compareLike): any[] {
    if (term && !isEmpty(values)) {
      return values.filter((value) => fields.some((field) => compare(term, value[field])));
    }
    return values;
  }
}
