import { ComponentPortal } from '@angular/cdk/portal';
import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  HostListener,
  Inject,
  Injector,
  OnDestroy,
  Renderer2,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { IsActiveMatchOptions, Router } from '@angular/router';
import { DataEnrollmentService, MenuService } from '@app/core';
import { MENU, Menu, MenuItem, MenuRef, SUBMENU } from '@app/core/menu';
import { notNull } from '@app/shared';
import { AppSelectors } from '@app/store';
import { AppState } from '@app/store/app.state';
import { Store } from '@ngrx/store';
import { first, flattenDeep, isEmpty, isEqual, isNil } from 'lodash';
import {
  BehaviorSubject,
  Observable,
  Subject,
  combineLatest,
  isObservable,
  merge,
  of,
  pairwise,
  scan,
  switchMap,
  take,
} from 'rxjs';
import { distinctUntilChanged, startWith, tap, map } from 'rxjs/operators';
import { DropdownMenuComponent } from './dropdown-menu.component';

const DEFAULT = { item: null, status: true };
@Component({
  selector: 'app-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainMenuComponent implements OnDestroy {
  public submenu$: Observable<ComponentPortal<any>>;
  public open$: Observable<string>;
  public menu$: Observable<Menu[]>;
  private _open$ = new BehaviorSubject<any>(DEFAULT);
  private _submenu$ = new Subject<any>();
  private _backgroundContainer: HTMLElement;

  constructor(
    private router: Router,
    private render: Renderer2,
    private destroy: DestroyRef,
    private store: Store<AppState>,
    private menuService: MenuService,
    private enrollmentService: DataEnrollmentService,
    @Inject(MENU) private menu: MenuRef[],
  ) {
    this.loadListeners();
  }

  public ngOnDestroy() {
    this.onClose();
    this._submenu$.complete();
  }

  public onDropdown(item: Menu, status: boolean) {
    this._open$.next({ item, status });
  }

  @HostListener('document:keydown.escape')
  public onClose = () => this._open$.next({ ...this._open$.value, status: true });

  public onClassLink(linkActive: any): boolean {
    const currentUrlSegment = this.router.url.split('/')[1];
    return linkActive.includes('/' + currentUrlSegment);
  }

  public onActiveRoute(item: any, active: boolean) {
    if (active) {
      this.menuService.menu(item.submenu);
    }
  }

  public routerLink(item): string {
    return first(flattenDeep([item.url]));
  }

  public isRouterLinkActive(item: Menu, paths: 'exact' | 'subset' = 'subset'): boolean {
    const params: IsActiveMatchOptions = {
      matrixParams: 'ignored',
      queryParams: 'ignored',
      fragment: 'ignored',
      paths,
    };
    return flattenDeep([item.url]).some((url) => this.router.isActive(url, params));
  }

  private loadListeners() {
    this.listenersMenu();
    this.listenersSubmenu();
  }

  private listenersMenu() {
    this.position();
    const activeUser$ = this.store
      .select(AppSelectors.ActiveUser)
      .pipe(takeUntilDestroyed(this.destroy), notNull(), distinctUntilChanged(isEqual));
    const finance$ = this.enrollmentService.enrollment$;
    this.menu$ = merge(activeUser$, finance$).pipe(
      takeUntilDestroyed(this.destroy),
      map(() =>
        this.menu.map((ref) => ref.menu.map((item) => ({ ...item(), submenu: this.submenu(ref.submenu), ref }))),
      ),
      map(flattenDeep),
      map((items) =>
        items.map(({ submenu, ...menu }) =>
          combineLatest({ submenu, menu: of(menu) }).pipe(
            take(1),
            map((item) => ({ ...item.menu, submenu: item.submenu })),
          ),
        ),
      ),
      switchMap((items) => merge(...items).pipe(scan((acc, curr) => [...acc, curr], []))),
      map(this.buildItems),
      switchMap(this.menuItems),
      map((item) => {
        const module = item.find((item) => this.isRouterLinkActive(item, item.exact ? 'exact' : 'subset'));
        this.menuService.menu(module?.submenu);
        return item.filter((item) => item.icon);
      }),
      tap((item) => this.preventMenuOpened(item)),
    );
  }

  private preventMenuOpened(item: Menu[]) {
    if (!!this._open$.value.item) {
      const submenu = item.find((item) => item.ref.identifier === this._open$.value.item.ref.identifier);
      if (submenu) {
        this._submenu$.next(submenu);
      } else {
        this.onClose();
      }
    }
  }

  private buildItems = (menu: Menu[]): Observable<any>[] =>
    menu.map((item) =>
      combineLatest({ show: this.helperShow(item), permission: this.helperAllow(item), item: of(item) }).pipe(
        take(1),
        map(({ show, permission, item }) => ({ ...item, show, permission })),
      ),
    );

  private helperShow(item: Menu): Observable<boolean> {
    if (isNil(item.show)) {
      return of(true);
    }
    if (isObservable(item.show)) {
      return item.show;
    }
    return of(item.show);
  }

  private helperAllow(item: Menu): Observable<boolean> {
    if (isNil(item.permission)) {
      return of(true);
    }
    return this.store.select(AppSelectors.isAllow(item.permission));
  }

  private menuItems = (items: Observable<Menu>[]): Observable<Menu[]> => {
    if (isEmpty(items)) {
      return of([]);
    }
    return merge(...items).pipe(
      scan((acc, curr) => [...acc, curr], []),
      map((items) => items.filter((item) => item.permission && item.show)),
    );
  };

  private submenu = (menu: MenuItem[]): Observable<Menu[]> => {
    const items = this.buildItems(menu.map((item) => item()) || []);
    return this.menuItems(items);
  };

  private position() {
    const viewHeight = () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };
    viewHeight();
    window.addEventListener('resize', viewHeight);
  }

  private listenersSubmenu() {
    this.open$ = this._open$.pipe(
      startWith(DEFAULT),
      tap(({ item }) => this._submenu$.next(item)),
      pairwise(),
      map(([prev, curr]) => this.statusOpen(prev, curr)),
      tap((show) => (show ? this.initBackground() : this.finishBackground())),
    );
    const activeUser$ = this.store.select(AppSelectors.ActiveUser);
    const submenu$ = this._submenu$.pipe(distinctUntilChanged(), notNull());
    this.submenu$ = combineLatest([submenu$, activeUser$]).pipe(
      takeUntilDestroyed(this.destroy),
      map(([item]) => {
        const injector = this.injector(item.submenu);
        return new ComponentPortal(DropdownMenuComponent, null, injector);
      }),
    );
  }

  private statusOpen(prev: any, curr: any): string {
    if (prev?.item?.ref.identifier === curr.item?.ref.identifier && curr?.status) {
      return null;
    }
    return curr.item?.ref.identifier;
  }

  private injector(ref: MenuRef): Injector {
    return Injector.create({
      providers: [
        {
          provide: SUBMENU,
          useValue: { ref, close: this.onClose },
        },
      ],
    });
  }

  private initBackground() {
    if (!this._backgroundContainer) {
      this._backgroundContainer = document.createElement('div');
      this.render.setAttribute(this._backgroundContainer, 'class', 'absolute bottom-0 left-0 z-80 right-0 top-0');
      this.render.listen(this._backgroundContainer, 'click', this.onClose);
      document.body.appendChild(this._backgroundContainer);
    }
  }

  private finishBackground = () => {
    if (this._backgroundContainer) {
      document.body.removeChild(this._backgroundContainer);
      this._backgroundContainer = null;
    }
  };
}
