import { Injectable } from '@angular/core';
import { portalConst } from '@app/config';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class SubjectService {
  private url = `${portalConst.api.usuario}/v1`;

  constructor(private api: ApiService) {}

  public subjectByGroup(groupId: number, req?: any): Observable<any[]> {
    return this.api.list<any[]>(`${this.url}/lista-disciplinas-turma/${groupId}`, req);
  }

  public subjectByGrade(gradeId: number, req?: any): Observable<any[]> {
    return this.api.list<any[]>(`${this.url}/lista-disciplinas-serie/${gradeId}`, req);
  }
}
