import { DIALOG_DATA } from '@angular/cdk/dialog';
import { Component, ElementRef, Inject, OnDestroy } from '@angular/core';
import { AssessmentQuestionService } from '@app/core';
import { Popover, Strategy } from '@app/shared/modules/template/components/popover';
import Highcharts from 'highcharts';
import Exporting from 'highcharts/modules/exporting';
import { BehaviorSubject, Observable } from 'rxjs';
import { finalize, map, take } from 'rxjs/operators';
import { safeEmpty } from '../../../../shared/utils/operators';
import { PopoverReadingChartComponent } from './popover-reading-chart/popover-reading-chart.component';
import { TranslateService } from '@ngx-translate/core';

Exporting(Highcharts);

interface GraphData {
  letter: string;
  media: number;
  result: any;
  adjustments: { acertos: number; alunos: number }[];
  totalStudents: number;
}

@Component({
  selector: 'app-modal-graph',
  templateUrl: './modal-graph.component.html',
})
export class ModalGraphComponent implements OnDestroy {
  public data$: Observable<GraphData>;
  public Highcharts: typeof Highcharts = Highcharts;
  public chartOptions: Highcharts.Options;
  public loading$ = new BehaviorSubject<boolean>(false);
  public textAlternative = this._translateService.instant('gestor-avaliacoes.alternativa-correta');
  public textAnswer = this._translateService.instant('gestor-avaliacoes.quantidade-respostas');
  public textAnalized = this._translateService.instant('gestor-avaliacoes.analisadas');

  constructor(
    @Inject(DIALOG_DATA) public data: any,
    private _graphService: AssessmentQuestionService,
    private _popover: Popover,
    private _translateService: TranslateService,
  ) {
    this.loading$.next(true);

    this.data$ = this._graphService.getGraph(data.applicationUuid, data.questionUuid).pipe(
      take(1),
      safeEmpty(),
      map((data: any) => {
        const graphData: GraphData = {
          letter: this.toLetter(data.alternativas.find((alternativa) => !!alternativa.alternativa_correta)?.ordem - 1),
          media: this.averageCalculation(data.total_acertos, data.total_alunos),
          result: this.transformData(data),
          adjustments: data.dados
            .map((item: any) => ({
              acertos: Math.trunc(item.acertos),
              alunos: Math.trunc(item.alunos),
            }))
            .sort((a: any, b: any) => a.acertos - b.acertos),
          totalStudents: data.total_alunos,
        };

        const chartTitle = `
      <div class="text-3xl items-center justify-center h-full">
      ${this.textAlternative}: <b class="text-3xl px-1">${graphData.letter}</b> (${graphData.media.toFixed(2)}%)
      </div>`;

        const chartSubtitle = `
      <div class="flex items-center justify-center h-full">
      ${this.textAnswer} ${this.textAnalized}: <b class="pl-[0.1rem]">${graphData.totalStudents}</b>
      </div>`;

        this.chartOptions = this.createChartOptions(graphData.result, chartTitle, chartSubtitle);
        return graphData;
      }),
      finalize(() => this.loading$.next(false)),
    );
  }

  public ngOnDestroy(): void {
    this.loading$.complete();
  }

  public transformData(data: any) {
    const sortedData = data.dados.sort((a: any, b: any) => a.acertos - b.acertos);

    return data.alternativas.map((alternative) => ({
      name: this.toLetter(alternative.ordem - 1),
      data: sortedData.map((dados) => {
        const totalRespondida = dados.alternativa.reduce((acc, alt) => acc + alt.qtd_respondida, 0);
        const alternativa = dados.alternativa.find((a) => a.uuid === alternative.uuid);
        const percentualAcerto = alternativa ? (alternativa.qtd_respondida / totalRespondida) * 100 : 0;
        return {
          x: dados.acertos,
          y: parseFloat(percentualAcerto.toFixed(2)),
        };
      }),
    }));
  }

  public createChartOptions(seriesData: any[], chartTitle: string, chartSubtitle: string): Highcharts.Options {
    const textSuccesses = this._translateService.instant('gestor-avaliacoes.acertos');
    return {
      chart: {
        type: 'spline',
        backgroundColor: '#f8f9fa',
        borderColor: '#dee2e6',
        borderWidth: 1,
        plotBackgroundColor: '#ffffff',
        style: {
          fontFamily: "'Arial', sans-serif",
        },
      },
      title: {
        text: chartTitle,
        style: {
          color: '#333333',
          fontSize: '20px',
          fontWeight: 'bold',
        },
        useHTML: true,
      },
      subtitle: {
        text: chartSubtitle,
        style: {
          color: '#666666',
          fontSize: '14px',
        },
        useHTML: true,
      },
      xAxis: {
        title: {
          text: this._translateService.instant('gestor-avaliacoes.acertos'),
          style: {
            color: '#333333',
            fontSize: '14px',
          },
        },
        labels: {
          style: {
            color: '#666666',
          },
        },
        gridLineColor: '#e5e5e5',
        tickInterval: 1,
        allowDecimals: false,
      },
      yAxis: {
        title: {
          text: this._translateService.instant('gestor-avaliacoes.porcentagem-alternativa'),
          style: {
            color: '#333333',
            fontSize: '14px',
          },
        },
        labels: {
          format: '{value}%',
          style: {
            color: '#666666',
          },
        },
        gridLineColor: '#e5e5e5',
      },
      tooltip: {
        backgroundColor: '#ffffff',
        borderColor: '#cccccc',
        borderRadius: 10,
        shared: true,
        style: {
          color: '#333333',
        },
        formatter() {
          return (
            `<b> ${textSuccesses}: ${this.points[0].key}</b><br/>` +
            this.points
              .map(
                (point) =>
                  `<span style="color:${point.color}"> \u25CF</span> ${point.series.name}: <b> ${point.y}%</b><br/>`,
              )
              .join('')
          );
        },
      },
      series: seriesData.map((series) => ({
        ...series,
        lineWidth: 4,
        marker: {
          radius: 4,
          symbol: 'circle',
          lineColor: '#ffffff',
          lineWidth: 4,
        },
      })),
      legend: {
        layout: 'horizontal',
        align: 'center',
        verticalAlign: 'bottom',
        itemStyle: {
          color: '#333333',
          fontSize: '14px',
        },
      },
      exporting: {
        enabled: true,
        buttons: {
          contextButton: {
            symbolStroke: '#666666',
            theme: {
              fill: '#ffffff',
            },
          },
        },
      },
      credits: {
        enabled: false,
      },
      lang: {
        viewFullscreen: this._translateService.instant('gestor-avaliacoes.exibir-tela'),
        exitFullscreen: this._translateService.instant('gestor-avaliacoes.sair-tela'),
        printChart: this._translateService.instant('gestor-avaliacoes.imprimir-grafico'),
        downloadPNG: this._translateService.instant('gestor-avaliacoes.baixar-png'),
        downloadJPEG: this._translateService.instant('gestor-avaliacoes.baixar-jpeg'),
        downloadPDF: this._translateService.instant('gestor-avaliacoes.baixar-pdf'),
        downloadSVG: this._translateService.instant('gestor-avaliacoes.baixar-svg'),
      },
    };
  }

  public toLetter(index: number): string {
    return String.fromCharCode(65 + index);
  }

  public averageCalculation(totalAcertos: number, totalAlunos: number): number {
    if (totalAlunos === 0) {
      return 0;
    }
    return (totalAcertos * 100) / totalAlunos;
  }

  public onOpenPopoverReadingChart(elementRef: ElementRef) {
    this._popover.open(PopoverReadingChartComponent, {
      positionStrategy: [Strategy.BOTTOM_END],
      elementRef,
    });
  }
}
