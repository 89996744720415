<div app-modal-content class="relative !p-0">
  <button type="button" app-icon-button class="!absolute right-5 top-5 z-20 !p-0" dialogClose>
    <svg-icon class="h-6 w-6 select-none" src="assets/icons/icon-close-fine.svg"></svg-icon>
  </button>

  <app-tab-group>
    <app-tab headerClass="px-5 pt-5">
      <button app-tab-header [tabStyle]="slim" type="button" class="font-semibold" translate>geral.aluno</button>
      <ng-template app-tab-content>
        <app-login-from-user-tab-students app-tab-content [tabStyle]="slim"></app-login-from-user-tab-students>
      </ng-template>
    </app-tab>

    <app-tab headerClass="px-5 pt-5">
      <button app-tab-header [tabStyle]="slim" type="button" class="font-semibold" translate>geral.responsavel</button>
      <ng-template app-tab-content>
        <app-login-from-user-tab-responsibles app-tab-content [tabStyle]="slim"></app-login-from-user-tab-responsibles>
      </ng-template>
    </app-tab>
  </app-tab-group>
</div>
