import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { portalConst } from '@app/config';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class HighlightsCourseService {
  private url = `${portalConst.api.trilhas}`;

  constructor(private api: ApiService) {}

  public getAllCardCurso(pais_id, entidade_id): Observable<any> {
    return this.api.get<any>(`${this.url}/site/v1/curso?pais_id=${pais_id}&entidade_id=${entidade_id}`);
  }
}
