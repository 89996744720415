import { DIALOG_DATA, Dialog, DialogRef } from '@angular/cdk/dialog';
import { AfterViewInit, Component, DestroyRef, HostListener, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { portalConst } from '@app/config';
import { AlertService, LearningTrailsService, NotificationService } from '@app/core';
import { GroupAudience } from '@app/core/models/GroupAudience';
import { CourseContent } from '@app/core/models/course-content';
import { SubTabsEnum } from '@app/core/models/tabs.enum';
import {
  FunctionAudience,
  FunctionByTypeAudience,
  UnitsAudience,
  UserAudience,
} from '@app/core/models/target-audience';
import { CertificateService } from '@app/core/services/certificate.service';
import { LearningTrailsCollectionService } from '@app/core/services/learning-trails-collection.service';
import { notNull, safeEmpty, selectedEntityActive } from '@app/shared';
import { formatDateStr } from '@app/shared/modules/keyboard-shortcuts/utils';
import { SidenavGroupComponent } from '@app/shared/modules/template/components/sidenav';
import { AppSelectors } from '@app/store';
import { AppState } from '@app/store/app.state';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { deburr, isEmpty } from 'lodash';
import { DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { BehaviorSubject, Observable, Subject, combineLatest, map, startWith, switchMap, tap } from 'rxjs';
import { EventsService } from '../../services/events.service';
import { FunctionsService } from '../../services/functions.service';
import { TargetAudienceService } from '../../services/target-audience.service';
import { ModalCertificateFormComponent } from '../modal-certificate-form/modal-certificate-form.component';
import { ModalCertificatePreviewComponent } from '../modal-certificate-preview/modal-certificate-preview.component';
import { ModalCertificateComponent } from '../modal-certificate/modal-certificate.component';

@Component({
  selector: 'app-modal-events',
  templateUrl: './modal-events.component.html',
  styles: [],
})
export class ModalEventsComponent implements OnInit, AfterViewInit {
  @ViewChild(SidenavGroupComponent, { static: true }) public sidenav: SidenavGroupComponent;
  @Input() public subdivision: CourseContent;
  private _activeUser: any;
  public subTabEnum = SubTabsEnum;
  public maxDate = new Date(new Date().getFullYear(), 11, 31);

  public form: FormGroup;
  public selectUnit = new FormControl(undefined);
  public selectFunction = new FormControl(undefined);
  public selectFunctionByType = new FormControl(undefined);
  public selectGroup = new FormControl(undefined);
  public selectCategory = new FormControl(undefined);
  public selectCollection = new FormControl(undefined);
  public searchCtrl = new FormControl(undefined);

  public loading$ = new Subject<boolean>();
  public activeSubTab$ = new BehaviorSubject<SubTabsEnum>(SubTabsEnum.Group);
  public opened$ = new BehaviorSubject<boolean>(false);
  public label$ = new BehaviorSubject<string>('nome');

  public events$ = new BehaviorSubject<any[]>([]);
  public eventsImportation$ = new BehaviorSubject<any[]>([]);
  public eventsMigrate$ = new BehaviorSubject<any[]>([]);
  public units$ = new BehaviorSubject<UnitsAudience[]>(undefined);
  public functions$ = new BehaviorSubject<FunctionAudience[]>(undefined);
  public functionsByType$ = new BehaviorSubject<FunctionByTypeAudience[]>(undefined);
  public groups$ = new BehaviorSubject<GroupAudience[]>(undefined);
  public users$ = new BehaviorSubject<UserAudience[]>(undefined);

  public categories$ = new BehaviorSubject<any[]>([]);
  public collections$ = new BehaviorSubject<any[]>([]);
  public course$: Observable<any>;
  public courses$ = new BehaviorSubject<any[]>(undefined);

  public itemsTargetAudienceSelected$ = new BehaviorSubject<any[]>([]);
  public itemsGroupSelected$ = new BehaviorSubject<any[]>([]);
  public itemsUserSelected$ = new BehaviorSubject<any[]>([]);
  public itemsCourseSelected$ = new BehaviorSubject<any[]>([]);

  public activeTab: number;
  public certificateId: number;
  public showInput: boolean = false;
  public isScreenHeightLessThan780: boolean = false;
  public editId: any;
  public filteredGroups$: Observable<any[]>;
  public filteredUsers$: Observable<any[]>;
  public filteredCourses$: Observable<any[]>;

  public loading = {
    categories$: new BehaviorSubject<boolean>(true),
    collections$: new BehaviorSubject<boolean>(false),
    units$: new BehaviorSubject<boolean>(true),
    functions$: new BehaviorSubject<boolean>(false),
    groups$: new BehaviorSubject<boolean>(false),
    functionsByType$: new BehaviorSubject<boolean>(false),
  };

  public get hasCertificate(): boolean {
    return this.itemsCourseSelected$.value.some((c) => c.emite_certificado === 1);
  }

  public onImageError(event: Event) {
    const target = event.target as HTMLImageElement;
    target.src = 'assets/images/default.png';
  }

  public config: DropzoneConfigInterface = {
    clickable: true,
    url: `${portalConst.api.usuario}/temp-upload/v1/file`,
    params: {
      savePhoto: true,
      largura: 500,
      altura: 500,
      limiteMaximo: true,
      pesoMaximo: 150,
      stringKbMb: 'kb',
    },
    createImageThumbnails: false,
    maxFiles: 1,
    maxFilesize: 0.15,
    acceptedFiles: '.jpg, .jpeg, .png',
    previewsContainer: false,
  };

  constructor(
    @Inject(DIALOG_DATA) public data: any,
    private _dialog: Dialog,
    private _dialogRef: DialogRef,
    private _store: Store<AppState>,
    private _destroyRef: DestroyRef,
    private _formBuilder: FormBuilder,
    private _alertService: AlertService,
    private _eventsService: EventsService,
    private _functionsService: FunctionsService,
    private _translateService: TranslateService,
    private _certificateService: CertificateService,
    private _notificationService: NotificationService,
    private _learningTrailsService: LearningTrailsService,
    private _targetAudienceService: TargetAudienceService,
    private _learningTrailsCollectionService: LearningTrailsCollectionService,
  ) {
    this._store
      .select(AppSelectors.ActiveUser)
      .pipe(
        takeUntilDestroyed(this._destroyRef),
        notNull(),
        tap(() => this.loading$.next(true)),
        tap((user) => (this._activeUser = user)),
        switchMap((user) => this._eventsService.listEvents(selectedEntityActive(user)[0], this.data.course.id, 100)),
        tap(({ data }) => {
          const eventsList = data.data.filter((item) => item.cursos.some((curso) => curso.id === this.data.course.id));
          if (eventsList.length > 0) {
            this.onEdit(eventsList[0].id);
            this.events$.next(eventsList);
          } else {
            this.events$.next([]);
          }
        }),
        tap(() => this.loading$.next(false)),
      )
      .subscribe();

    combineLatest([this.itemsGroupSelected$, this.itemsUserSelected$])
      .pipe(
        takeUntilDestroyed(this._destroyRef),
        map(([groups, users]) => {
          this.itemsTargetAudienceSelected$.next([...groups, ...users]);
        }),
      )
      .subscribe();

    this._certificateService.certificate$.pipe(notNull()).subscribe((certificate) => this.AddCertificate(certificate));
  }

  public ngOnInit() {
    this.initForm();
    this.checkScreenHeight();
    this.loadCourseData();

    const mapNormalizeValue = (items, searchValue) => {
      if (!searchValue) {
        return items;
      }
      const trimmedSearchValue = searchValue.trim();
      const cleanedSearchValue = trimmedSearchValue.replace(/\./g, '');
      if (cleanedSearchValue === '') {
        return items;
      }
      const normalizedSearchValue = deburr(cleanedSearchValue.toLowerCase());
      return items.filter((item) => deburr(item.nome.toLowerCase()).includes(normalizedSearchValue));
    };

    this.filteredGroups$ = combineLatest([this.groups$, this.searchCtrl.valueChanges.pipe(startWith(''))]).pipe(
      map(([groups, searchValue]) => mapNormalizeValue(groups, searchValue)),
    );
    this.filteredUsers$ = combineLatest([this.users$, this.searchCtrl.valueChanges.pipe(startWith(''))]).pipe(
      map(([users, searchValue]) => mapNormalizeValue(users, searchValue)),
    );
    this.filteredCourses$ = combineLatest([this.courses$, this.searchCtrl.valueChanges.pipe(startWith(''))]).pipe(
      map(([courses, searchValue]) => mapNormalizeValue(courses, searchValue)),
    );
  }

  public ngAfterViewInit() {
    this.sidenav.end.closed$.pipe(takeUntilDestroyed(this._destroyRef)).subscribe(() => this.opened$.next(false));
  }

  @HostListener('window:resize', ['$event'])
  public onResize() {
    this.checkScreenHeight();
  }

  public checkScreenHeight() {
    this.isScreenHeightLessThan780 = window.innerHeight < 780;
  }

  private initForm() {
    this.form = this._formBuilder.group({
      id: [undefined],
      titulo: [undefined, [Validators.required]],
      auto_matricula: [false, [Validators.required]],
      auto_resgate: [false, [Validators.required]],
      status: [false, [Validators.required]],
      data_inicio: [new Date(), Validators.required],
      data_fim: [undefined, Validators.required],
      autor: [undefined, Validators.maxLength(70)],
      evento_curso: [undefined, [Validators.required]],
      publico_alvo: [undefined, [Validators.required]],
      publico_alvo_usuarios: [undefined, [Validators.required]],
      entidades: selectedEntityActive(this._activeUser),
      usa_sugestao_certificado: undefined,
      certificado_id: undefined,
      certificado: undefined,
    });

    this.form.valueChanges.subscribe((value) => {
      if (value?.publico_alvo?.length > 0) {
        this.form.get('publico_alvo_usuarios').clearValidators();
      } else {
        this.form.get('publico_alvo_usuarios').setValidators(Validators.required);
      }

      if (value?.publico_alvo_usuarios?.length > 0) {
        this.form.get('publico_alvo').clearValidators();
      } else {
        this.form.get('publico_alvo').setValidators(Validators.required);
      }

      this.form.get('publico_alvo_usuarios').updateValueAndValidity({ emitEvent: false });
      this.form.get('publico_alvo').updateValueAndValidity({ emitEvent: false });
    });

    this.form.value.certificado_id?.valueChanges.subscribe((value) => {
      if (value !== this.certificateId) {
        this.form.patchValue({
          usa_sugestao_certificado: 0,
        });
      }
    });

    this.selectCategory.valueChanges.subscribe((categoryId) => {
      this._learningTrailsCollectionService
        .listCollection(selectedEntityActive(this._activeUser)[0], categoryId, true)
        .pipe(
          takeUntilDestroyed(this._destroyRef),
          notNull(),
          tap(() => this.loading.collections$.next(false)),
        )
        .subscribe({
          next: (resp) => {
            this.collections$.next(resp.data);
            this.courses$.next([]);
          },
          error: (e) => this._alertService.alert({ message: e.error.msg }),
        });
      this.loading.collections$.next(true);
      this.selectCollection.reset(undefined, { emitEvent: false });
    });

    this.selectCollection.valueChanges.subscribe((collectionId) => {
      this._learningTrailsService
        .coursesByCollection(
          collectionId,
          selectedEntityActive(this._activeUser)[0].id,
          selectedEntityActive(this._activeUser)[0].tipo,
        )
        .pipe(takeUntilDestroyed(this._destroyRef), notNull())
        .subscribe({
          next: (resp) => {
            this.courses$.next(resp.data);
          },
          error: (e) => this._alertService.alert({ message: e.error.msg }),
        });
    });
  }

  public loadCourseData() {
    this.course$ = this._learningTrailsService.showcaseIndividual(this.data.course.id).pipe(
      safeEmpty(),
      map((course) => ({ ...course, selected: true })),
      tap((course) => {
        this.form.controls.evento_curso.setValue([course], { emmitEvent: false });
        this.itemsCourseSelected$.next([...this.itemsCourseSelected$.value, course]);
      }),
    );
  }

  public loadUnits() {
    this._targetAudienceService
      .getAllUnits(this._activeUser.paises, true, false)
      .pipe(
        tap(() => this.loading.units$.next(false)),
        takeUntilDestroyed(this._destroyRef),
        notNull(),
      )
      .subscribe({
        next: (resp) => {
          this.units$.next(resp.data);
        },
        error: (e) => this._alertService.alert({ message: e.error.msg }),
      });
    this.loading.units$.next(true);
  }

  public loadFunctions() {
    this._targetAudienceService
      .getTypes(this.data.context)
      .pipe(
        tap(() => this.loading.functions$.next(false)),
        takeUntilDestroyed(this._destroyRef),
        safeEmpty(),
        notNull(),
      )
      .subscribe({
        next: (resp) => {
          this.functions$.next(resp);
          if (resp.length === 1) {
            this.selectFunction.setValue(resp[0]);
            this.selectFunction.disable();
            this.onFunctionSelected();
          }
        },
        error: (e) => this._alertService.alert({ message: e.error.msg }),
      });
    this.loading.functions$.next(true);
  }

  public onUnitSelected() {
    if (this.activeSubTab$.value === SubTabsEnum.Function) {
      this.selectFunctionByType.reset();
      this.loadFunctionsByType();
      this.functionsByType$.next(undefined);
    } else if (this.activeSubTab$.value === SubTabsEnum.User) {
      this.selectGroup.reset();
      this.loadGroups();
      this.users$.next(undefined);
    } else {
      this.loadGroups();
    }
  }

  public onFunctionSelected() {
    if (this.activeSubTab$.value === SubTabsEnum.User) {
      this.selectGroup.reset();
      this.loadGroups();
      this.users$.next(undefined);
    } else {
      this.loadGroups();
    }
  }

  public shouldShowSkeleton(tabValue: number): boolean {
    if (tabValue === 1) return this.selectFunction.getRawValue();
    if (tabValue === 2) return this.selectGroup.value;
    if (tabValue === 3) return this.selectFunctionByType.value;
    return false;
  }

  public loadGroups() {
    if (!!this.selectUnit.value && !!this.selectFunction.getRawValue()) {
      this._targetAudienceService
        .getGroups(this.selectUnit.value, this.selectFunction.getRawValue().id)
        .pipe(
          tap(() => this.loading.groups$.next(false)),
          takeUntilDestroyed(this._destroyRef),
          notNull(),
        )
        .subscribe({
          next: (resp) => {
            this.groups$.next(resp.data);
          },
          error: (e) => this._alertService.alert({ message: e.error.msg }),
        });
      this.loading.groups$.next(true);
    } else {
      this.groups$.next(undefined);
    }
  }

  public loadUsersByFunction() {
    if (!!this.selectFunctionByType.value) {
      this._targetAudienceService
        .getUsersByFunction(this.selectUnit.value, this.selectFunctionByType.value.id)
        .pipe(
          tap(() => this.loading.functionsByType$.next(false)),
          takeUntilDestroyed(this._destroyRef),
          notNull(),
        )
        .subscribe({
          next: (resp) => {
            this.users$.next(resp.data);
          },
          error: (e) => this._alertService.alert({ message: e.error.msg }),
        });
      this.loading.functionsByType$.next(true);
    } else {
      this.users$.next(undefined);
    }
  }

  public loadUsers() {
    if (!!this.selectGroup.value) {
      this._targetAudienceService
        .getUsers(this.selectUnit.value, this.selectGroup.value.id)
        .pipe(takeUntilDestroyed(this._destroyRef), notNull())
        .subscribe({
          next: (resp) => {
            this.users$.next(resp.data);
          },
          error: (e) => this._alertService.alert({ message: e.error.msg }),
        });
    } else {
      this.users$.next(undefined);
    }
  }

  public loadFunctionsByType() {
    this._functionsService
      .getFunctionsByType(this.selectUnit.value.tipo, this.selectUnit.value.id)
      .pipe(takeUntilDestroyed(this._destroyRef), notNull())
      .subscribe({
        next: (resp) => {
          this.functionsByType$.next(resp.data);
        },
        error: (e) => this._alertService.alert({ message: e.error.msg }),
      });
  }

  public loadCategories() {
    this._learningTrailsService
      .categorys(selectedEntityActive(this._activeUser)[0], true)
      .pipe(
        tap(() => this.loading.categories$.next(true)),
        takeUntilDestroyed(this._destroyRef),
        notNull(),
        tap(() => this.loading.categories$.next(false)),
      )
      .subscribe({
        next: (resp) => {
          this.categories$.next(resp.data);
        },
        error: (e) => this._alertService.alert({ message: e.error.msg }),
      });
  }

  public loadEvents() {
    this._eventsService
      .listEvents(selectedEntityActive(this._activeUser)[0], null, 1000, true)
      .pipe(
        safeEmpty(),
        map((res: any) => res.data),
      )
      .subscribe({
        next: (data) => {
          const currentYear = new Date().getFullYear();

          const eventsThisYear = data.filter((evento) => {
            const eventDate = new Date(evento.data_inicio);
            return eventDate.getFullYear() === currentYear;
          });

          const eventsLastYear = data.filter((evento) => {
            const eventDate = new Date(evento.data_inicio);
            return eventDate.getFullYear() === currentYear - 1;
          });

          this.eventsImportation$.next(eventsThisYear);
          this.eventsMigrate$.next(eventsLastYear);
        },
        error: (e) => this._alertService.alert({ message: e.error.msg }),
      });
  }

  public onEventSelected(event: any, actual: boolean) {
    if (!!event) {
      this._alertService
        .confirm(
          actual
            ? { message: this._translateService.instant('trilhas.evento.importar') }
            : { message: this._translateService.instant('trilhas.evento.importar-antigo') },
        )
        .pipe(
          notNull(),
          switchMap(() => {
            const currentYear = new Date().getFullYear();
            const eventYear = new Date(event.data_inicio).getFullYear();

            if (eventYear === currentYear) {
              return this._eventsService.showEvent(event.id);
            }
            return this._eventsService.showEvent(event.id, true);
          }),
        )
        .subscribe({
          next: (resp: any) => {
            const data = resp.data;

            const publicoAlvo = data.publico_alvo.filter((grupo) =>
              this.itemsGroupSelected$.value.every((g) => g.id !== grupo.id),
            );
            this.itemsGroupSelected$.next([...this.itemsGroupSelected$.value, ...publicoAlvo]);

            const publicoAlvoUsuario = data.publico_alvo_usuarios.filter((user) =>
              this.itemsUserSelected$.value.every((u) => u.usuario_id !== user.usuario_id),
            );
            this.itemsUserSelected$.next([...this.itemsUserSelected$.value, ...publicoAlvoUsuario]);
            this._notificationService.success('geral.sucesso');
          },
          error: (e) => this._alertService.alert({ message: e.error.msg }),
        });
    }
  }

  public resetForm() {
    const resetEvent = {
      id: undefined,
      titulo: undefined,
      auto_matricula: false,
      auto_resgate: false,
      status: false,
      data_inicio: new Date(),
      data_fim: undefined,
      autor: undefined,
      evento_curso: undefined,
      publico_alvo: undefined,
      publico_alvo_usuarios: undefined,
      entidades: selectedEntityActive(this._activeUser),
      usa_sugestao_certificado: 0,
      certificado_id: undefined,
      certificado: undefined,
    };
    this.opened$.next(false);
    this.editId = undefined;
    this.form.reset(resetEvent, { emitEvent: false });
    this.itemsGroupSelected$.next([]);
    this.itemsCourseSelected$.next([]);
    this.loadCourseData();
  }

  public onSubmit() {
    this.validateCertificadoId();
    this.form.patchValue({
      evento_curso: this.itemsCourseSelected$.value,
      publico_alvo: this.itemsGroupSelected$.value,
      publico_alvo_usuarios: this.itemsUserSelected$.value,
      entidades: selectedEntityActive(this._activeUser),
    });
    if (this.form.valid) {
      const value = this.resolveValue(this.form.getRawValue());
      this.save(value);
    } else if (
      !this.form.get('titulo').valid ||
      !this.form.get('data_inicio').valid ||
      !this.form.get('data_fim').valid ||
      !this.form.get('status').valid ||
      !this.form.get('auto_matricula').valid ||
      !this.form.get('auto_resgate').valid
    ) {
      this.form.markAllAsTouched();
      this._alertService.alert({ message: this._translateService.instant('trilhas.evento.campos-incorretos') });
    } else if (!this.form.get('publico_alvo').valid && !this.form.get('publico_alvo_usuarios').valid) {
      this._alertService.alert({ message: this._translateService.instant('trilhas.evento.sem-publico') });
    } else if (!this.form.get('evento_curso').valid) {
      this._alertService.alert({ message: this._translateService.instant('trilhas.evento.sem-curso') });
    }
  }

  private validateCertificadoId() {
    if (this.hasCertificate && (!this.form.value.id || (!!this.form.value.id && !!this.form.value.certificado_id))) {
      this.form.get('certificado_id').enable();
    } else {
      this.form.get('certificado_id').disable();
    }
  }

  private resolveValue(value: any) {
    value = {
      ...value,
      data_inicio: formatDateStr(value.data_inicio, 'yyyy-MM-dd'),
      data_fim: formatDateStr(value.data_fim, 'yyyy-MM-dd'),
    };
    return value;
  }

  private save(data: any) {
    this._alertService
      .confirm({ message: this._translateService.instant(`trilhas.estudio.curso.deseja-salvar`) })
      .pipe(
        notNull(),
        switchMap(() =>
          !!data.id ? this._eventsService.updateEvent(data.id, data) : this._eventsService.postEvent(data),
        ),
        safeEmpty(),
      )
      .subscribe({
        next: (event: any) => {
          this._notificationService.success('geral.sucesso');
          if (!!data.id) {
            const updatedEvents = this.events$.value.map((e) => (e.id === event.id ? event : e));
            this.events$.next(updatedEvents);
          } else if (event.cursos.some((curso) => curso.id === this.data.course.id)) {
            this.events$.next([...this.events$.value, event]);
          }
          this.resetForm();
        },
        error: (e) => {
          this._alertService.alert({ message: e.error.msg });
        },
      });
  }

  public onEdit(eventId: number) {
    this.editId = eventId;
    this._eventsService.showEvent(eventId).subscribe({
      next: (event) => {
        const events = event.data['cursos']
          .map((c) => ({ ...c, selected: c.id === this.data.course.id }))
          .sort((a, b) => b.selected - a.selected);
        const editEvent = {
          id: event.data['id'],
          titulo: event.data['titulo'],
          auto_matricula: !!event.data['auto_matricula'],
          auto_resgate: !!event.data['auto_resgate'],
          status: !!event.data['status'],
          data_inicio: event.data['data_inicio'],
          data_fim: event.data['data_fim'],
          autor: event.data['autor'],
          evento_curso: events,
          publico_alvo: event.data['publico_alvo'],
          publico_alvo_usuarios: event.data['publico_alvo_usuarios'],
          entidades: event.data['entidades'],
          usa_sugestao_certificado: !!event.data['usa_sugestao_certificado'],
          certificado_id: event.data['certificado_id'],
          certificado: event.data['certificado'],
        };

        this.itemsGroupSelected$.next(editEvent.publico_alvo);
        this.itemsUserSelected$.next(editEvent.publico_alvo_usuarios);
        this.itemsCourseSelected$.next(editEvent.evento_curso);

        this.groups$.next(editEvent.publico_alvo);
        this.users$.next(editEvent.publico_alvo_usuarios);
        this.courses$.next(editEvent.evento_curso);

        this.form.patchValue(editEvent);
      },
      error: (e) => this._alertService.alert({ message: e.error.msg }),
    });
  }

  public onDelete(eventId: number, event?: any) {
    event.stopPropagation();
    this._alertService
      .confirm({ message: this._translateService.instant(`trilhas.estudio.curso.deseja-excluir`) })
      .pipe(
        notNull(),
        switchMap(() => this._eventsService.destroyEvent(eventId)),
      )
      .subscribe({
        next: () => {
          const updatedEvents = this.events$.value.filter((event) => event.id !== eventId);
          this.events$.next(updatedEvents);
          this._notificationService.success('geral.deletado');
          if (this.editId === eventId) {
            this.resetForm();
          }
        },
        error: (e) => this._alertService.alert({ message: e.error.msg }),
      });
  }

  public onAddCertificate() {
    this._dialog
      .open(ModalCertificateComponent, {
        hasBackdrop: true,
      })
      .closed.pipe(notNull())
      .subscribe();
  }

  private AddCertificate(certificate) {
    this.form.patchValue({
      certificado_id: certificate.id,
      certificado: certificate,
    });
  }

  public onOpenModalCertificate(certificadoId: any) {
    this._dialog
      .open(ModalCertificatePreviewComponent, {
        hasBackdrop: true,
        data: { certificadoId, data: this.onData() },
      })
      .closed.pipe(notNull())
      .subscribe();
  }

  public editCertificate() {
    this._dialog
      .open(ModalCertificateFormComponent, {
        hasBackdrop: true,
        data: this.onData(),
      })
      .closed.pipe(notNull())
      .subscribe();
  }

  public removeCertificate() {
    this.form.patchValue({
      certificado_id: undefined,
      certificado: undefined,
    });
  }

  public useCertificate(cetificate: any) {
    this.certificateId = cetificate.id;
    this.form.patchValue({
      usa_sugestao_certificado: 1,
      certificado_id: cetificate.id,
      certificado: cetificate,
    });
  }

  public onData() {
    return {
      id: this.form.value.certificado_id,
      title: this.form.value.certificado.titulo,
      text: this.form.value.certificado.texto,
      backgroundImage:
        this.form.value.certificado.background || 'assets/images/certificate-default/certificado-padrao.jpg',
      assinaturas: [
        {
          assinatura: this.form.value.certificado.file_assinatura_1,
          nome: this.form.value.certificado.nome_assinatura_1,
          linha_1: this.form.value.certificado.linha_1_assinatura_1,
          linha_2: this.form.value.certificado.linha_2_assinatura_1,
        },
        {
          assinatura: this.form.value.certificado.file_assinatura_2,
          nome: this.form.value.certificado.nome_assinatura_2,
          linha_1: this.form.value.certificado.linha_1_assinatura_2,
          linha_2: this.form.value.certificado.linha_2_assinatura_2,
        },
        {
          assinatura: this.form.value.certificado.file_assinatura_3,
          nome: this.form.value.certificado.nome_assinatura_3,
          linha_1: this.form.value.certificado.linha_1_assinatura_3,
          linha_2: this.form.value.certificado.linha_2_assinatura_3,
        },
      ],
    };
  }

  public showList() {
    if (this.activeSubTab$.value === SubTabsEnum.Group && !!this.groups$.value) return true;
    if (
      (this.activeSubTab$.value === SubTabsEnum.User || this.activeSubTab$.value === SubTabsEnum.Function) &&
      !!this.users$.value
    )
      return true;
    return false;
  }

  public onClose() {
    if (!this.form.dirty) {
      this._dialogRef.close();
      return;
    }

    this._alertService
      .confirm({ message: this._translateService.instant(`trilhas.evento.deseja-fechar`) })
      .pipe(notNull())
      .subscribe(() => this._dialogRef.close());
  }

  public onToggleInput() {
    this.showInput = !this.showInput;
  }

  public getDay(date: string) {
    const dateParts = date.split('-');
    return dateParts[2];
  }

  public getMonth(date: string) {
    const dateParts = date.split('-');
    const monthNames = [
      'janeiro',
      'fevereiro',
      'março',
      'abril',
      'maio',
      'junho',
      'julho',
      'agosto',
      'setembro',
      'outubro',
      'novembro',
      'dezembro',
    ];
    const monthIndex = parseInt(dateParts[1], 10) - 1;
    return this._translateService.instant(`geral.meses-iniciais.${monthNames[monthIndex]}`);
  }

  public transformDate(dateString: string): string {
    return formatDateStr(dateString, 'dd/MM/yyyy');
  }

  public openSidenav(activeTab: number) {
    if (activeTab === 1 && isEmpty(this.categories$.value)) {
      this.loadCategories();
    } else if (activeTab === 2 && (isEmpty(this.units$.value) || isEmpty(this.functions$.value))) {
      this.resetTargetAudience();
      this.loadUnits();
      this.loadFunctions();
    }
    this.opened$.next(true);
    this.activeTab = activeTab;
  }

  public onSetSubTab(option: SubTabsEnum) {
    this.resetTargetAudience();
    this.activeSubTab$.next(option);

    if (this.activeSubTab$.value === SubTabsEnum.Importation && isEmpty(this.eventsImportation$.value)) {
      this.loadEvents();
    }
  }

  public resetTargetAudience() {
    this.selectUnit.reset();
    this.selectFunctionByType.reset();
    this.selectGroup.reset();
    this.searchCtrl.reset();
    this.groups$.next(undefined);
    this.users$.next(undefined);
    if (!this.functions$.value || this.functions$.value?.length > 1) {
      this.selectFunction.reset();
    }
  }

  public onMarckAll(activeSubtab: number): void {
    if (activeSubtab === 1) {
      const updatedGroups = this.groups$.value.map((group) => ({
        ...group,
        isSelected: true,
      }));
      this.groups$.next(updatedGroups);

      if (this.itemsGroupSelected$.value) {
        this.itemsGroupSelected$.next([...this.itemsGroupSelected$.value, ...this.groups$.value]);
      } else {
        this.itemsGroupSelected$.next([...this.groups$.value]);
      }
    } else {
      const updatedUsers = this.users$.value.map((user) => ({
        ...user,
        isSelected: true,
      }));
      this.users$.next(updatedUsers);

      if (this.itemsUserSelected$.value) {
        this.itemsUserSelected$.next([...this.itemsUserSelected$.value, ...this.users$.value]);
      } else {
        this.itemsUserSelected$.next([...this.users$.value]);
      }
    }
  }

  public onUnmarckAll(activeSubtab: number): void {
    if (activeSubtab === 1) {
      const updatedGroups = this.groups$.value.map((group) => ({
        ...group,
        isSelected: false,
      }));
      this.groups$.next(updatedGroups);

      const updatedItemsGroupSelected = this.itemsGroupSelected$.value.filter(
        (selectedGroup) => !updatedGroups.some((group) => group.id === selectedGroup.id && !group.isSelected),
      );
      this.itemsGroupSelected$.next(updatedItemsGroupSelected);
    } else {
      const updatedUsers = this.users$.value.map((user) => ({
        ...user,
        isSelected: false,
      }));
      this.users$.next(updatedUsers);

      const updatedItemsUserSelected = this.itemsUserSelected$.value.filter(
        (selectedUser) => !updatedUsers.some((user) => user.id === selectedUser.id && !user.isSelected),
      );
      this.itemsUserSelected$.next(updatedItemsUserSelected);
    }
  }

  public isMarked(activeSubtab: number): boolean {
    if (activeSubtab === 1) {
      return this.groups$.value?.some((group) => group.isSelected === true);
    }
    return this.users$.value?.some((user) => user.isSelected === true);
  }

  public onIsGroupInSelectedItems(group: any): boolean {
    return this.itemsGroupSelected$.value?.some((selectedItem) => selectedItem.id === group.id);
  }

  public onIsUserInSelectedItems(user: any): boolean {
    return this.itemsUserSelected$.value?.some((selectedItem) => selectedItem.id === user.id);
  }

  public onToggleSelection(item: any, isChecked: boolean) {
    if (item.tipo === 1) {
      this.onToggleGroupSelection(item, isChecked);
    } else {
      this.onTogglUserSelection(item, isChecked);
    }
  }

  public onToggleGroupSelection(item: any, isChecked: boolean, event = null): void {
    if (this.groups$.value) {
      const updatedGroups = this.groups$.value.map((group) => {
        if (group.id === item.id) {
          return {
            ...group,
            isSelected: isChecked || event?.target.checked,
          };
        }
        return group;
      });
      this.groups$.next(updatedGroups);
    }

    if (isChecked || event?.target.checked) {
      this.itemsGroupSelected$.next([...this.itemsGroupSelected$.value, item]);
    } else {
      this.itemsGroupSelected$.next(this.itemsGroupSelected$.value.filter((group) => group.id !== item.id));
    }
  }

  public onTogglUserSelection(item: any, isChecked: boolean, event = null): void {
    if (this.users$.value) {
      const updatedUsers = this.users$.value.map((user) => {
        if (user.usuario_id === item.usuario_id) {
          return {
            ...user,
            isSelected: isChecked || event?.target.checked,
          };
        }
        return user;
      });
      this.users$.next(updatedUsers);
    }

    if (isChecked || event?.target.checked) {
      this.itemsUserSelected$.next([...this.itemsUserSelected$.value, item]);
    } else {
      this.itemsUserSelected$.next(this.itemsUserSelected$.value.filter((user) => user.usuario_id !== item.usuario_id));
    }
  }

  public onAddToSelectedCourses(course: any): void {
    this.itemsCourseSelected$.next([...this.itemsCourseSelected$.value, course]);
  }

  public onIsCourseInSelectedCourses(course: any): boolean {
    return this.itemsCourseSelected$.value?.some((selectedItem) => selectedItem.id === course.id);
  }

  public onRemoveSelectedItem(course: any): void {
    if (course.sugestao_certificado_id === this.form.value.certificado_id) {
      this.form.get('usa_sugestao_certificado').reset();
      this.form.get('certificado_id').reset();
      this.form.get('certificado').reset();
    }
    this.itemsCourseSelected$.next(this.itemsCourseSelected$.value.filter((c) => course.id !== c.id));
  }

  public onManageItem(course: any): void {
    this.data.course = course;
    this._eventsService
      .listEvents(selectedEntityActive(this._activeUser)[0], this.data.course.id, 100)
      .pipe(safeEmpty())
      .subscribe((data: any) => {
        const eventsList = data.data.filter((item) => item.cursos.some((curso) => curso.id === this.data.course.id));
        if (eventsList.length > 0) {
          this.events$.next(eventsList);
        } else {
          this.events$.next([]);
        }
      });

    const updatedCourses = this.itemsCourseSelected$.value
      .map((c) => ({ ...c, selected: c.id === course.id }))
      .sort((a, b) => b.selected - a.selected);
    this.itemsCourseSelected$.next(updatedCourses);
  }
}
