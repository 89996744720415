<app-sidenav
  #start
  [config]="start$ | async"
  (margin)="onLeftMargin($event)"
  (status)="onStatus($event, start)"
></app-sidenav>

<div #content class="relative h-full w-full overflow-auto transition-all duration-300">
  <ng-container *ngIf="{ background: showBackground$ | async } as data">
    <div *ngIf="data.background" class="absolute z-90 block h-full w-full bg-black/30" (click)="onClose()"></div>
    <div class="h-full" [ngClass]="{ 'overflow-hidden': data.background }">
      <ng-template *ngIf="content$ | async as template" [ngTemplateOutlet]="template.templateRef"></ng-template>
    </div>
  </ng-container>
</div>

<app-sidenav
  #end
  [config]="end$ | async"
  (margin)="onRightMargin($event)"
  (status)="onStatus($event, end)"
></app-sidenav>
