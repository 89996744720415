import { AssessmentApplicationMode, AssessmentOrigin, AssessmentType, AssessmentWeightType } from '@app/core/models';

export interface AssessmentState {
  uuid: string;
  parent_uuid: string;
  titulo: string;
  orientacoes: string;
  orientacao_inicial: string;
  orientacao_cartao: string;
  origem: AssessmentOrigin;
  editora_id: number;
  tipo: AssessmentType;
  peso_tipo: AssessmentWeightType;
  peso: number;
  padrao_disciplina_id: number;
  pais_id: number;
  criado_por: number;
  tipo_gerenciada: undefined;
  ano: undefined;
}
export interface ApplicationState {
  uuid: string;
  parent_uuid: string;
  qtd_minimo: number;
  qtd_questao: number;
  embaralhar_questoes: boolean;
  embaralhar_alternativas: boolean;
  mostrar_gabarito: boolean;
  modo_aplicacao: AssessmentApplicationMode;
  status: boolean;
  pre_aplicacao: boolean;
  publico_alvo: Target[];
  entidades: any;
  tentativa_count: number;
}

export interface Target {
  user_id: number;
  turma_id: number;
  padrao_serie_id?: number;
  entidade_id?: number;
  turma?: any;
  user?: any;
  padrao_serie?: any;
  entidade?: any;
}

export interface ModalNewAssessmentState {
  disabled: boolean;
  question_actived_uuid: string;
  avaliacao: AssessmentState;
  aplicacao: ApplicationState;
  questoes: any[];
  extra: any;
  isUpdate: boolean;
  hasChanges: boolean;
  pressSubmit: boolean;
}

export const initialState: ModalNewAssessmentState = {
  disabled: true,
  question_actived_uuid: undefined,
  avaliacao: {
    orientacoes: undefined,
    orientacao_inicial: undefined,
    orientacao_cartao: undefined,
    origem: undefined,
    titulo: undefined,
    uuid: undefined,
    editora_id: undefined,
    tipo: AssessmentType.Avaliacao,
    peso_tipo: AssessmentWeightType.Porcentagem,
    peso: undefined,
    parent_uuid: undefined,
    padrao_disciplina_id: undefined,
    pais_id: undefined,
    criado_por: undefined,
    tipo_gerenciada: undefined,
    ano: undefined,
  },
  aplicacao: {
    uuid: undefined,
    parent_uuid: undefined,
    qtd_minimo: undefined,
    qtd_questao: undefined,
    embaralhar_questoes: false,
    embaralhar_alternativas: false,
    mostrar_gabarito: false,
    modo_aplicacao: AssessmentApplicationMode.Online,
    status: false,
    pre_aplicacao: false,
    publico_alvo: [],
    entidades: [],
    tentativa_count: undefined,
  },
  questoes: [],
  extra: undefined,
  isUpdate: false,
  hasChanges: true,
  pressSubmit: false,
};
