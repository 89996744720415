import { ChangeDetectionStrategy, Component, DestroyRef, TemplateRef } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { GuardValidationService, LayoutTemplateService, SidenavResponse, SidenavService } from '@app/core/services';
import { AppState } from '@app/store/app.state';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

@Component({
  templateUrl: './layout-module.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [GuardValidationService],
})
export class LayoutModuleComponent {
  public start$: Observable<SidenavResponse>;
  public end$: Observable<SidenavResponse>;
  public template$: Observable<TemplateRef<any>>;
  public validation$: Observable<any>;
  public isIVCA$: Observable<boolean>;

  constructor(
    private _destroy: DestroyRef,
    private _store: Store<AppState>,
    private sidenavService: SidenavService,
    private layoutTemplateService: LayoutTemplateService,
    private guardValidationService: GuardValidationService,
  ) {
    this.start$ = this.sidenavService.start$.pipe(takeUntilDestroyed(this._destroy));
    this.end$ = this.sidenavService.end$.pipe(takeUntilDestroyed(this._destroy));
    this.template$ = this.layoutTemplateService.template$.pipe(takeUntilDestroyed(this._destroy));
    this.validation$ = this.guardValidationService.validation$.pipe(takeUntilDestroyed(this._destroy));
    this.isIVCA$ = this._store.select('app', 'ivca');
  }

  public label(validation: string): string {
    return `geral.guards.${validation}`;
  }
}
