import { Component, ElementRef, HostListener, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
})
export class CarouselComponent {
  public slides = [
    { url: 'assets/images/learning-trails/vitrine-estrutura-sistema.jpg' },
    { url: 'assets/images/ads/slider/banner1.png' },
    { url: 'assets/images/ads/slider/banner2.png' },
    { url: 'assets/images/ads/slider/banner3.png' },
    { url: 'assets/images/learning-trails/vitrine-estrutura-sistema.jpg' },
  ];
}
