import { inject } from '@angular/core';
import { CanActivateFn, Params, Router } from '@angular/router';
import { storageKeys } from '@app/config';
import { AppActions } from '@app/store';
import { AppState } from '@app/store/app.state';
import { Store } from '@ngrx/store';
import { LocalStorageService } from 'ngx-webstorage';
import { catchError, exhaustMap, of, take, tap } from 'rxjs';
import { LoadingService, TokenService } from '../services';
import { Login } from './login';

export const LoginGuard: CanActivateFn = (route) => {
  const store = inject(Store<AppState>);
  const router = inject(Router);
  const storage = inject(LocalStorageService);
  const tokenService = inject(TokenService);
  const loading = inject(LoadingService);
  const { t, g } = route.queryParams;
  const token = t || storage.retrieve(storageKeys.AUTHENTICATION_TOKEN);
  const access_token = !!t && !g ? undefined : g || storage.retrieve(storageKeys.ACCESS_TOKEN);
  loading.start();

  if (!!t) {
    storage.clear(storageKeys.ENTITY);
    storage.clear(storageKeys.ACCESS_TOKEN);
  }

  return tokenService.refresh(token).pipe(
    take(1),
    tap((data: any) => store.dispatch(AppActions.TokenEffect({ token: data.token, access_token }))),
    exhaustMap(() => sanitizeUrl(router, route.queryParams)),
    catchError(() => error(store)),
  );
};

function error(store: Store<AppState>) {
  Login.navigateToLogin(store);
  return of(true);
}

function sanitizeUrl(router: Router, { t, g, ...params }: Params = {}) {
  if (!!t || !!g) {
    return router.navigate([new URL(window.location.href).pathname], { queryParams: params });
  }
  return of(true);
}
