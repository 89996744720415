import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DirectivesModule } from '../../directives/directives.module';
import { PipesModule } from '../../pipes/pipes.module';
import { TemplateModule } from '../template/template.module';
import { SubmenuComponent } from './submenu.component';

@NgModule({
  imports: [CommonModule, TemplateModule, DirectivesModule, PipesModule],
  declarations: [SubmenuComponent],
  exports: [SubmenuComponent],
})
export class SubmenuModule {}
