import { Injectable } from '@angular/core';
import { portalConst } from '@app/config/portal.constants';
import { Store } from '@ngrx/store';
import { LocalStorageService } from 'ngx-webstorage';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
@Injectable({
  providedIn: 'root',
})
export class VoucherService {
  private readonly baseUrl = `${portalConst.api.vouchers}v1`;

  constructor(private api: ApiService, private store: Store<any>, public storageService: LocalStorageService) {}

  public list(alunoId: number): Observable<any> {
    return this.api.post(`${this.baseUrl}/student/${alunoId}/vouchers`);
  }

  public activate(voucher: string, student_id: number): Observable<any> {
    return this.api.post(`${this.baseUrl}/voucher/activate/${voucher}`, { student_id });
  }

  public activateBook(livro_id: number, voucher: string, student_id: number): Observable<any> {
    return this.api.post(`${this.baseUrl}/voucher/activate-book/${livro_id}/${voucher}`, { student_id });
  }
}
