import { Injectable } from '@angular/core';
import { portalConst } from '@app/config';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { CrudService } from './crud.service';

@Injectable({
  providedIn: 'root',
})
export class UploadService extends CrudService<any> {
  constructor(api: ApiService) {
    super(api, `${portalConst.api.school}/v1/upload`);
  }

  public latexToSvg(req?: any): Observable<any> {
    return this.api.post(`${portalConst.api.upload}`, req);
  }
}
