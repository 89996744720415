import { Resp } from '@app/core/services/api.service';
import { MonoTypeOperatorFunction, Observable, OperatorFunction, of } from 'rxjs';
import { catchError, debounceTime, delay, filter, map, switchMap } from 'rxjs/operators';

export function notNull<T>(): MonoTypeOperatorFunction<T> {
  return filter((value) => !!value);
}

export function keysNotNull<T>(...keys: (keyof T)[]): MonoTypeOperatorFunction<T> {
  return filter((value: T) => keys.every((key) => value[key] !== null && value[key] !== undefined));
}

export function onlySuccessful<T>(): MonoTypeOperatorFunction<Resp<T>> {
  return filter((resp) => resp.ret === 1);
}

export function switchDelay<T>(time = 500): OperatorFunction<T, T> {
  return switchMap((v) => (v ? of(v).pipe(delay(time)) : of(v)));
}

export function debounceDelay<T>(value = 500): MonoTypeOperatorFunction<T> {
  return debounceTime(value);
}

export function safeEmptyList<T>(value: T[] = []) {
  return (source: Observable<Resp<T[]>>): Observable<T[]> =>
    source.pipe(
      catchError(() => of({ ret: 0, data: value })),
      map((res) => (res.ret === 1 ? res.data : value)),
    );
}

export function safeEmpty<T>(value: T = null) {
  return (source: Observable<Resp<T>>): Observable<T> =>
    source.pipe(
      catchError(() => of({ ret: 0, data: value })),
      map((res) => (res.ret === 1 ? res.data : value)),
    );
}
