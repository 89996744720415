import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { portalConst } from '@app/config';
import { AttachmentService } from '@app/core/services/attachment.service';
import { AttachmentTypeEnum } from '@app/modules/eclass/attachment-type.enum';
import { Vimeo, downloadFile } from '@app/shared';
import { BehaviorSubject, map, take } from 'rxjs';

@Component({
  templateUrl: './modal-announcement-read.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalAnnouncementReadComponent {
  public editor = new FormControl();
  public dataViewer$ = new BehaviorSubject<any>(undefined);

  constructor(
    @Inject(DIALOG_DATA) public data: any,
    public dialogRef: DialogRef<string>,
    private _attachmentService: AttachmentService,
  ) {
    this.editor.setValue(this.data.communication.description);

    this.setDataViewer();
  }

  public setDataViewer() {
    if (this.data.communication?.attachment?.type === AttachmentTypeEnum.Video) {
      const videoUrl = Vimeo.getVideoUrl(this.data.communication?.attachment);
      if (!!videoUrl) {
        this.dataViewer$.next({
          name: this.data.communication?.attachment?.title,
          url: videoUrl,
        });
        return;
      }

      const params = {
        id: this.data.communication?.attachment?.id,
        video: '/videos/' + Vimeo.getVideoId(this.data.communication?.attachment),
        conta: portalConst.vimeo_account,
      };

      this._attachmentService
        .checkVideo(params)
        .pipe(
          take(1),
          map((res) => Vimeo.getVideoUrl({ ...this.data.communication?.attachment, vimeo_hash: res.vimeo_hash })),
        )
        .subscribe((url) =>
          this.dataViewer$.next({
            name: this.data.communication?.attachment?.title,
            url,
          }),
        );

      return;
    }

    this.dataViewer$.next({
      name: this.data.communication?.attachment?.title,
      url: this.data.communication?.attachment?.url
        ? this.data.communication?.attachment?.url
        : this.data.communication?.attachment?.file,
    });
  }

  public thereText(htmlString: string): boolean {
    const doc = new DOMParser().parseFromString(htmlString, 'text/html');
    return (doc.body.textContent || '').trim().length > 0;
  }

  public onDownload(file: any): void {
    downloadFile(file);
  }
}
