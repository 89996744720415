export interface RunActions {
  success?: () => void;
  error?: () => void;
  finalize?: () => void;
}

export const EMPTY_ACTIONS: RunActions = {
  success: () => undefined,
  error: () => undefined,
  finalize: () => undefined,
};
