<app-modal-header class="border-b-2 border-theme-500">
  <div class="flex w-full items-center gap-2">
    <div class="flex">
      <svg-icon
        class="w-10 text-theme-500"
        src="assets/icons/icon-gestor-avaliacoes.svg"
        alt="Materiais Didaticos"
      ></svg-icon>
      <h1 class="ml-3 text-sm font-semibold leading-tight text-gray-800" translate>
        gestor-avaliacoes.e-class
        <br />
        <b class="text-lg text-black" translate>gestor-avaliacoes.prova</b>
      </h1>
    </div>

    <hr app-modal-divider />
    <h1 class="text-lg" translate>gestor-avaliacoes.reportar-questao</h1>
  </div>
</app-modal-header>

<div app-modal-content class="h-auto w-[70rem] overflow-y-auto p-4">
  <form [formGroup]="form">
    <app-form-field [required]="false" class="w-full">
      <label app-label translate="">gestor-avaliacoes.comentario </label>
      <app-text-editor [url]="urlUploadCkEditorImage" formControlName="feedback" class="block"></app-text-editor>
    </app-form-field>
  </form>
</div>

<div class="flex h-[5rem] w-full justify-end bg-white px-5 py-4 shadow-up md:bottom-0">
  <div class="flex justify-end">
    <button
      (click)="onSave()"
      app-flat-button
      class="flex w-full items-center justify-end bg-theme-500 uppercase text-theme-contrast text-white"
      type="button"
      [disabled]="submitted$ | async"
    >
      <ng-container *ngIf="submitted$ | async; else saveTemplate">
        <app-skeleton class="h-5 w-5" baseStyle="bg-theme-500" [showLabel]="false"></app-skeleton>
      </ng-container>
      <ng-template #saveTemplate>
        <span translate>geral.salvar</span>
      </ng-template>
    </button>
  </div>
</div>
