import { ChangeDetectionStrategy, Component, DestroyRef, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AlertService, NotificationService } from '@app/core';
import { AssessmentAnnulTypeEnum } from '@app/core/models';
import { AssessmentApplicationService } from '@app/core/services/assessment-application.service';
import { notNull, safeEmpty } from '@app/shared';
import { Popover, Strategy } from '@app/shared/modules/template/components/popover';
import { switchMap } from 'rxjs';
import { PopoverDescriptionAnnulQuestionComponent } from './popover-description-annul-question.component';

@Component({
  selector: 'app-badge-annuled-question',
  templateUrl: './badge-annuled-question.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BadgeAnnuledQuestionComponent {
  public readonly CANCEL_ANNULMENT = {
    [AssessmentAnnulTypeEnum.Question]: (applicationUuid: string, questionUuid: string) =>
      this._annulQuestion.annulQuestion(applicationUuid, questionUuid, { anulado: 0 }),
    [AssessmentAnnulTypeEnum.Answer]: (tentativaUuid: string, questionUuid: string) =>
      this._annulQuestion.printedCorrection(tentativaUuid, questionUuid, { anulado: 0 }),
  };

  public readonly assessmentAnnulTypeEnum = AssessmentAnnulTypeEnum;
  @Input() public motive: string;
  @Input() public isAnswerAnnulled: boolean;
  @Input() public isQuestionAnnulled: boolean;
  @Input() public applicationUuid: any;
  @Input() public questionUuid: any;
  @Input() public tentativaUuid: any;
  @Input() public cancelAnnulment: boolean = true;
  @Output() public updateQuestion = new EventEmitter<any>();

  constructor(
    private _popover: Popover,
    private _annulQuestion: AssessmentApplicationService,
    private _alertService: AlertService,
    private _notificationService: NotificationService,
    private _destroyRef: DestroyRef,
  ) {}

  public get type(): AssessmentAnnulTypeEnum {
    return this.isQuestionAnnulled ? AssessmentAnnulTypeEnum.Question : AssessmentAnnulTypeEnum.Answer;
  }

  public get tooltip(): string {
    const options = {
      [AssessmentAnnulTypeEnum.Question]: 'Questão anulada',
      [AssessmentAnnulTypeEnum.Answer]: 'Resposta do aluno anulada',
    };

    let tooltip = options[this.type] || undefined;
    if (!!this.motive) {
      tooltip += ' - Clique para ver detalhes';
    }

    return tooltip;
  }

  public onOpenPopoverAnnull(elementRef: ElementRef): void {
    if (!this.motive) {
      return;
    }

    this._popover.open(PopoverDescriptionAnnulQuestionComponent, {
      positionStrategy: [Strategy.RIGHT],
      elementRef,
      data: { anulado_motivo: this.motive },
    }).closed;
  }

  public onQuestAnulado(): void {
    this._alertService
      .confirm({
        title: 'geral.confirmacao',
        message: 'gestor-avaliacoes.confirmacao-cancelar-anulacao',
      })
      .pipe(
        takeUntilDestroyed(this._destroyRef),
        notNull(),
        switchMap(() =>
          this.type === AssessmentAnnulTypeEnum.Question
            ? this.CANCEL_ANNULMENT[AssessmentAnnulTypeEnum.Question](this.applicationUuid, this.questionUuid).pipe(
                safeEmpty(),
              )
            : this.CANCEL_ANNULMENT[AssessmentAnnulTypeEnum.Answer](this.tentativaUuid, this.questionUuid).pipe(
                safeEmpty(),
              ),
        ),
      )
      .pipe(takeUntilDestroyed(this._destroyRef), notNull())
      .subscribe((data) => {
        this.type === AssessmentAnnulTypeEnum.Question
          ? this.updateQuestion.emit({ anulado: 0 })
          : this.updateQuestion.emit(data);
        this._notificationService.success('gestor-avaliacoes.anulacao-cancelada');
      });
  }
}
