import { AbstractControl, ValidatorFn } from '@angular/forms';
import { isAfter } from 'date-fns';
import { isEmpty } from 'lodash';

export function updateValueAndValidity(control: AbstractControl, error: string) {
  if (control.getError(error)) {
    control.updateValueAndValidity();
  }
}

export function loadError(control: AbstractControl, invalid: boolean, error: string, value: any = true) {
  if (invalid) {
    control.setErrors({ ...control.errors, [error]: value });
  } else {
    updateValueAndValidity(control, error);
  }
}

const nonEmpty: ValidatorFn = (control: AbstractControl) => (isEmpty(control.value) ? { nonEmpty: true } : null);

function biggerThen(x: string, y: string): ValidatorFn {
  return (form: AbstractControl) => {
    const isBigger = Number(form.get(x).value) > Number(form.get(y).value);
    loadError(form.get(x), isBigger, 'lte', { value: form.get(y).value });
    return isBigger ? { lte: { value: form.get(y).value } } : null;
  };
}

function lesserThen(x: string, y: string): ValidatorFn {
  return (form: AbstractControl) => {
    const isLesser = Number(form.get(x).value) < Number(form.get(y).value);
    loadError(form.get(x), isLesser, 'gte', { value: form.get(y).value });
    return isLesser ? { gte: { value: form.get(y).value } } : null;
  };
}

function endPeriod(startField: string, endField: string, error = 'invalidDate'): ValidatorFn {
  return (control: AbstractControl) => {
    const start = control.get(startField);
    const end = control.get(endField);
    const invalid = isAfter(start.value, end.value);
    loadError(end, invalid, error);
    return invalid ? { [error]: null } : null;
  };
}

export const CustomValidators = { nonEmpty, biggerThen, lesserThen, endPeriod };
