<ng-select
  app-select
  [placeholder]="'materiais-didaticos.nivel-ensino' | translate"
  [multiple]="multiple"
  (focus)="onFocus()"
  [formControl]="ctrl"
  class="w-full"
  [items]="data$ | async"
  bindLabel="descricao"
  bindValue="id"
  [loading]="loading$ | async"
>
</ng-select>
