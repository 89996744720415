<div class="flex h-screen flex-col overflow-hidden">
  <!-- Header -->
  <app-main-header [ngStyle]="{ display: (isIVCA$ | async) ? 'none' : 'block' }"></app-main-header>

  <!-- Content -->
  <div class="grid h-full grid-rows-[5rem_1fr] overflow-hidden overflow-x-auto lg:grid-cols-[60px_1fr] lg:grid-rows-1">
    <app-submenu></app-submenu>

    <ng-container *ngIf="{ validation: validation$ | async } as guard">
      <ng-container *ngIf="!guard.validation; else requiredTemplate">
        <app-sidenav-group class="w-screen lg:w-[calc(100vw_-_60px)]">
          <ng-template app-sidenav-start [update]="start$ | async"> </ng-template>

          <ng-template app-sidenav-outer>
            <div class="flex h-[calc(100vh_-_157px)] overflow-hidden overflow-y-auto lg:h-[calc(100vh_-_52px)]">
              <ng-template *ngIf="template$ | async as template" [ngTemplateOutlet]="template"></ng-template>
              <div class="w-full bg-base-whisper-500 px-4 lg:p-7">
                <!-- Breadcrumb -->
                <app-breadcrumb [isIVCA]="isIVCA$ | async" class="pb-3 pt-2"></app-breadcrumb>

                <!-- Content -->
                <router-outlet></router-outlet>
                <div class="h-[20rem] lg:hidden"></div>
              </div>
            </div>
          </ng-template>

          <ng-template app-sidenav-end [update]="end$ | async"> </ng-template>
        </app-sidenav-group>
      </ng-container>

      <ng-template #requiredTemplate>
        <div class="layout-content__module">
          <div class="grid h-auto gap-4 p-5 pt-14">
            <app-card>
              <div app-card-content class="h-72">
                <app-jumbotron [showIcon]="false">
                  <div class="flex w-auto flex-col items-center">
                    <svg-icon src="assets/icons/icon-alert-fill-fine.svg" class="w-20 text-gray-400"></svg-icon>
                    <ng-container *ngFor="let validation of guard.validation | keyvalue">
                      <div class="text-xl font-semibold text-gray-400">
                        {{ label(validation.key) | translate : validation.value }}
                      </div>
                    </ng-container>
                  </div>
                </app-jumbotron>
              </div>
            </app-card>
          </div>
        </div>
      </ng-template>
    </ng-container>
  </div>
</div>
