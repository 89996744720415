import { NgModule } from '@angular/core';
import { TemplateModule } from '../template/template.module';
import { SelectDefaultSubjectComponent } from './select-default-subject.component';

@NgModule({
  declarations: [SelectDefaultSubjectComponent],
  imports: [TemplateModule],
  exports: [SelectDefaultSubjectComponent],
})
export class SelectDefaultSubjectModule {}
