import { Dialog } from '@angular/cdk/dialog';
import { Component, DestroyRef, Inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AssessmentApplicationMode, Entidade, FunctionsEnum } from '@app/core/models';
import { notNull, selectedEntityActive } from '@app/shared';
import { POPOVER_DATA } from '@app/shared/modules/template/components/popover';
import { AppSelectors } from '@app/store';
import { AppState } from '@app/store/app.state';
import { Store } from '@ngrx/store';
import { first, isEqual } from 'lodash';
import { combineLatest, distinctUntilChanged } from 'rxjs';
import { ModalAssessmentsPrintSettingsComponent } from '../../../modal-assessments-print-settings/modal-assessments-print-settings.component';
import { ModalCreateResponseCardComponent } from '@app/modules/assessments-manager/shared/modal-create-response-card/modal-create-response-card.component';

@Component({
  selector: 'app-popover-print-options',
  templateUrl: './popover-print-options.component.html',
  styles: ``,
})
export class PopoverPrintOptionsComponent {
  public canPreview = false;
  public entityActive: Entidade;
  constructor(
    @Inject(POPOVER_DATA) public data: any,
    private _store: Store<AppState>,
    private _destroyRef: DestroyRef,
    private _dialog: Dialog,
  ) {
    combineLatest([
      this._store.select(AppSelectors.ActiveUser).pipe(takeUntilDestroyed(this._destroyRef), notNull()),
      this._store
        .select(AppSelectors.DefaultFunction)
        .pipe(takeUntilDestroyed(this._destroyRef), notNull(), distinctUntilChanged(isEqual)),
    ]).subscribe(([activeUser, defaultFunction]) => {
      this.entityActive = first(selectedEntityActive(activeUser));
      this.canPreview =
        this.data.application.previewer ||
        this.data.application.criado_por === activeUser.usuario_id ||
        this.permission();
    });
  }

  public get isOnline(): boolean {
    return this.data.application.modo_aplicacao === AssessmentApplicationMode.Online;
  }

  public get isPreviewer(): boolean {
    return !!this.data.application.previewer;
  }

  public get isEntity(): boolean {
    return this.entityActive?.tipo === 1;
  }

  public onAssessmentsPrintSettingsComponent() {
    this._dialog.open<boolean>(ModalAssessmentsPrintSettingsComponent, {
      data: {
        application_uuid: this.data.application.uuid,
        shuffle_questions:
          !!this.data.application.embaralhar_questoes || !!this.data.application.embaralhar_alternativas,
      },
    });
  }

  public onCreateResponseCard() {
    this._dialog.open(ModalCreateResponseCardComponent, {
      data: {
        application_uuid: this.data.application.uuid,
        dataStudent: this.data.dataStudent,
      },
    });
  }

  private permission(): boolean {
    const options = {
      [1]: () => 'entidade_id',
      [2]: () => 'campo_id',
      [3]: () => 'uniao_id',
      [4]: () => 'editora_id',
      [5]: () => 'divisao_id',
    };
    const existePermissao = this.data.application.avaliacao.entidade_permissao.some(
      (permissao) => permissao[options[this.entityActive.tipo]()] === this.entityActive.id,
    );
    return existePermissao;
  }
}
