import { Injectable } from '@angular/core';
import { notNull } from '@app/shared';
import { AppSelectors } from '@app/store';
import { AppState } from '@app/store/app.state';
import { Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { PaisEnum } from '../models';
import { MenuItem, MenuRef } from './menu';

@Injectable({ providedIn: 'root' })
export class GamesMenu implements MenuRef {
  public identifier = 'games';

  public readonly menu: MenuItem[] = [
    () => ({
      title: 'menu.jogos',
      url: '/jogos',
      icon: 'icon-dice',
      dimension: 'h-7 w-7',
      permission: [1, 6],
      show: this.store.select(AppSelectors.ActiveUser).pipe(
        notNull(),
        map(
          (activeUser) =>
            activeUser?.nivel_id === 2 || activeUser?.pais_id !== PaisEnum.Brasil || !activeUser?.aluno_id,
        ),
      ),
    }),
  ];

  public readonly submenu: MenuItem[] = [
    () => ({
      title: 'menu.jogos',
      url: '/jogos',
      icon: 'icon-home',
      group: 'menu.jogos',
      exact: true,
    }),
    () => ({
      title: 'jogos.1-ano',
      url: '/jogos/1-year',
      icon: 'icon-jogos-N1',
      group: 'menu.jogos',
      show: this.store.select(AppSelectors.ActiveUser).pipe(
        notNull(),
        map((activeUser) => activeUser?.editora_id === 1),
      ),
    }),
    () => ({
      title: 'jogos.2-ano',
      url: '/jogos/2-year',
      icon: 'icon-jogos-N2',
      group: 'menu.jogos',
      show: this.store.select(AppSelectors.ActiveUser).pipe(
        notNull(),
        map((activeUser) => activeUser?.editora_id === 1),
      ),
    }),
    () => ({
      title: 'jogos.3-ano',
      url: '/jogos/3-year',
      icon: 'icon-jogos-N3',
      group: 'menu.jogos',
      show: this.store.select(AppSelectors.ActiveUser).pipe(
        notNull(),
        map((activeUser) => activeUser?.editora_id === 1),
      ),
    }),
    () => ({
      title: 'jogos.4-ano',
      url: '/jogos/4-year',
      icon: 'icon-jogos-N4',
      group: 'menu.jogos',
      show: this.store.select(AppSelectors.ActiveUser).pipe(
        notNull(),
        map((activeUser) => activeUser?.editora_id === 1),
      ),
    }),
    () => ({
      title: 'jogos.5-ano',
      url: '/jogos/5-year',
      icon: 'icon-jogos-N5',
      group: 'menu.jogos',
      show: this.store.select(AppSelectors.ActiveUser).pipe(
        notNull(),
        map((activeUser) => activeUser?.editora_id === 1),
      ),
    }),
    () => ({
      title: 'jogos.nivel-1',
      url: '/jogos/1-year',
      icon: 'icon-jogos-N1',
      group: 'menu.jogos',
      show: this.store.select(AppSelectors.ActiveUser).pipe(
        notNull(),
        map((activeUser) => activeUser?.editora_id === 2),
      ),
    }),
    () => ({
      title: 'jogos.nivel-2',
      url: '/jogos/2-year',
      icon: 'icon-jogos-N2',
      group: 'menu.jogos',
      show: this.store.select(AppSelectors.ActiveUser).pipe(
        notNull(),
        map((activeUser) => activeUser?.editora_id === 2),
      ),
    }),
    () => ({
      title: 'jogos.nivel-3',
      url: '/jogos/3-year',
      icon: 'icon-jogos-N3',
      group: 'menu.jogos',
      show: this.store.select(AppSelectors.ActiveUser).pipe(
        notNull(),
        map((activeUser) => activeUser?.editora_id === 2),
      ),
    }),
  ];

  constructor(private store: Store<AppState>) {}
}
