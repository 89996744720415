<ckeditor
  class="block"
  [ngClass]="{ readonly: readonlyEditor }"
  [editor]="editor"
  [config]="config"
  [disabled]="disabled || readonlyEditor"
  [ngModel]="value"
  (ngModelChange)="onChange($event)"
  (focus)="onFocusChange(true)"
  (blur)="onBlur()"
  (ready)="onReady($event)"
></ckeditor>
