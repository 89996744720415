import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { portalConst } from '@app/config';
import { CertificadoService } from '@app/core/services/certificado.service';
import { DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { filter } from 'rxjs';

export interface PreviewCertificadoAssinaturaData {
  assinatura: string;
  nome: string;
  linha_1: string;
  linha_2: string;
}

export interface PreviewCertificadoData {
  text: string;
  backgroundImage: string;
  assinaturas: PreviewCertificadoAssinaturaData[];
}

@Component({
  selector: 'app-preview-certificado',
  templateUrl: './preview-certificado.component.html',
  styleUrls: ['./preview-certificado.component.scss'],
  styles: ``,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => PreviewCertificadoComponent),
    },
  ],
})
export class PreviewCertificadoComponent implements OnInit, ControlValueAccessor {
  @Input() public data: PreviewCertificadoData;

  public form: FormGroup;
  private _api: any = portalConst.api;
  private readonly _pathTempFile: string = portalConst.s3_path_temp;
  private _modalReference;

  public config: DropzoneConfigInterface = {
    clickable: true,
    url: `${this._api.usuario}/temp-upload/v1/file`,
    params: {
      savePhoto: true,
      largura: 1024,
      altura: 740,
      limiteMaximo: true,
      pesoMaximo: 500,
      stringKbMb: 'kb',
    },
    createImageThumbnails: false,
    maxFiles: 1,
    maxFilesize: 0.5,
    acceptedFiles: '.jpg,.jpeg',
    previewsContainer: false,
  };

  constructor(public certificadosService: CertificadoService, public formBuilder: FormBuilder) {
    this.certificadosService.showCertificadoDefault.pipe(filter((value) => !value)).subscribe(() => this.form.reset());
  }

  public writeValue(obj: any): void {
    this.form.patchValue(obj);
  }

  public registerOnChange(fn: (v: any) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  private onChange = (_: any) => {
    //vazio
  };

  private onTouched = () => {
    //vazio
  };

  private returnValue() {
    this.onTouched();
    this.onChange(this.form.value.hash);
  }

  public ngOnInit(): void {
    this.initForm();
  }

  public backgroundImage(): string {
    return this.form.value.file ? `url('${this.form.value.file}')` : `url('${this.data?.backgroundImage}')`;
  }

  public get assinaturas(): PreviewCertificadoAssinaturaData[] {
    return this.data?.assinaturas?.filter((a) => !!a.nome || !!a.assinatura);
  }

  public get showCertificadoDefault(): boolean {
    return this.certificadosService.showCertificadoDefault.value;
  }

  public onUpload(event) {
    if (event.success) {
      this.form.get('hash').setValue(event.hash);
      this.form.get('file').setValue(this._pathTempFile + event.file);
      this.certificadosService.setShowCertificadoDefault(!this.certificadosService.showCertificadoDefault.value);
      this.returnValue();
    }
  }

  private initForm() {
    this.form = this.formBuilder.group({
      file: undefined,
      hash: undefined,
    });
  }
}
