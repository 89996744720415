import { Component, Inject } from '@angular/core';
import { POPOVER_DATA } from '@app/shared/modules/template/components/popover';

@Component({
  selector: 'app-popover-scale-proficiencia',
  templateUrl: './popover-scale-proficiencia.component.html',
  styles: ``,
})
export class PopoverScaleProficienciaComponent {
  constructor(@Inject(POPOVER_DATA) public data: string) {}
}
