import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TAB_STYLE } from '@app/shared/modules/template/components/tab/tab.interfaces';

@Component({
  templateUrl: './modal-login-from-user.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalLoginFromUserComponent {
  public readonly slim = TAB_STYLE.Slim;
}
