<app-modal-header>
  <div class="flex py-2">
    <svg-icon class="ml-6 w-7 text-base-post-plan" src="assets/icons/icon-student-book.svg"></svg-icon>
    <p class="ml-6 text-lg font-semibold" translate>site-escola.conteudos</p>
  </div>
</app-modal-header>
<div app-modal-content class="h-[90vh] w-[90vw] !p-0 md:h-[calc(100vh_-_15rem)] xl:h-[50rem] xl:w-[80rem]">
  <div class="p-4" *ngIf="{ page: contents$ | async, load: load$ | async } as data">
    <div class="mb-4 h-[67vh] overflow-y-auto py-5 md:h-[calc(100vh_-_25rem)] xl:h-[40rem]">
      <ng-container *ngIf="!data.load; else loadTemplate">
        <ng-container *ngIf="!(data.page.data | isEmpty); else emptyTemplate">
          <div class="flex flex-wrap justify-center gap-10">
            <app-card-cover-summary
              class="!w-full border-2 border-transparent transition duration-500 hover:box-border hover:border-modules-eclass-500 hover:bg-modules-eclass-200 md:!w-60"
              *ngFor="let content of data.page.data"
              [content]="content"
            ></app-card-cover-summary>
          </div>
        </ng-container>
        <ng-template #emptyTemplate>
          <app-jumbotron translate>geral.filtro-vazio</app-jumbotron>
        </ng-template>
      </ng-container>
      <ng-template #loadTemplate>
        <div class="flex flex-wrap justify-center gap-10">
          <app-skeleton class="block !h-60 !w-60"></app-skeleton>
          <app-skeleton class="block !h-60 !w-60"></app-skeleton>
          <app-skeleton class="block !h-60 !w-60"></app-skeleton>
          <app-skeleton class="block !h-60 !w-60"></app-skeleton>
        </div>
      </ng-template>
    </div>
    <app-pagination *ngIf="!data.load" [page]="data.page" (pageChange)="onChangePage($event)"></app-pagination>
  </div>
</div>
