import { Injectable } from '@angular/core';
import { storageKeys } from '@app/config';
import { LoadingService, TokenService, UserService } from '@app/core/services';
import { INIT_DATA } from '@app/core/services/core.service';
import { AppActions, AppSelectors } from '@app/store';
import { AppState } from '@app/store/app.state';
import { Store } from '@ngrx/store';
import { LocalStorageService } from 'ngx-webstorage';
import { Observable } from 'rxjs';
import { exhaustMap, switchMap, take, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class LoginFromUserService {
  constructor(
    private userService: UserService,
    private tokenService: TokenService,
    private store: Store<AppState>,
    private loadingService: LoadingService,
    private localStorageService: LocalStorageService,
  ) {}

  public with(user_id: number): Observable<any> {
    this.loadingService.start();
    return this.store.select(AppSelectors.token).pipe(
      take(1),
      tap((token) => this.localStorageService.store(storageKeys.ORIGIN, token)),
      switchMap(() => this.tokenService.token({ user_id })),
      tap(({ token }) => this.store.dispatch(AppActions.TokenEffect({ token }))),
      exhaustMap(() => this.store.select(AppSelectors.token).pipe(take(1))),
      exhaustMap(() => this.userService.get(INIT_DATA)),
      tap((user) => this.store.dispatch(AppActions.InitDataEffect({ user }))),
    );
  }
}
