import { NgModule } from '@angular/core';
import { BadgeModule } from '../badge/badge.module';
import { TemplateModule } from '../template/template.module';
import { FilterSubjectComponent } from './filter-subject.component';
import { PipesModule } from '@app/shared/pipes/pipes.module';
import { FilterBadgeModule } from '../filter-badge/filter-badge.module';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [FilterSubjectComponent],
  imports: [TemplateModule, BadgeModule, PipesModule, FilterBadgeModule, RouterModule],
  exports: [FilterSubjectComponent],
})
export class FilterSubjectModule {}
