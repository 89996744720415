import { Pipe, PipeTransform } from '@angular/core';
import { AppSelectors } from '@app/store';
import { AppState } from '@app/store/app.state';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export interface OrderName {
  name?: string;
  lastName?: string;
}
@Pipe({
  name: 'orderName',
})
export class OrderNamePipe implements PipeTransform {
  constructor(private store: Store<AppState>) {}

  public transform(value: OrderName): Observable<string> {
    return this.store.select(AppSelectors.isBrasil).pipe(
      map((isBrasil) => this.reverseName(value, isBrasil)),
      map((term) => term.filter((name) => !!name).join(' ')),
    );
  }

  private reverseName(value: OrderName, isBrasil: boolean): string[] {
    if (isBrasil) {
      return [value.name, value.lastName];
    }
    return [value.lastName, value.name];
  }
}
