import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { ChangeDetectionStrategy, Component, DestroyRef, Inject, OnDestroy, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { portalConst } from '@app/config';
import { AssessmentQuestionService, NotificationService } from '@app/core';
import { AssessmentQuestion } from '@app/core/models';
import { BehaviorSubject, finalize, Observable } from 'rxjs';

@Component({
  selector: 'app-modal-report',
  templateUrl: './modal-report.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalReportComponent implements OnInit, OnDestroy {
  public readonly urlUploadCkEditorImage = `${portalConst.api.assessments}/v1/upload/ckeditor-image?path=avaliacoes`;
  public submitted$ = new BehaviorSubject(false);
  public form: FormGroup;

  constructor(
    @Inject(DIALOG_DATA) public data: any,
    public destroyRef: DestroyRef,
    private _dialogRef: DialogRef<AssessmentQuestion>,
    private _formBuilder: FormBuilder,
    private _notificationService: NotificationService,
    private _assessmentQuestionService: AssessmentQuestionService,
  ) {}

  public ngOnInit() {
    this.form = this._formBuilder.group({
      feedback: [undefined, Validators.required],
    });
  }

  public ngOnDestroy(): void {
    this.submitted$.complete();
  }

  public onSave() {
    this.form.markAllAsTouched();
    if (this.form.valid) {
      this.observable(this._assessmentQuestionService.feedback(this.data.questionUuid, this.form.value.feedback));
    }
  }

  public observable(observable: Observable<any>) {
    this.submitted$.next(true);
    observable
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        finalize(() => this.submitted$.next(false)),
      )
      .subscribe(
        () => {
          this._notificationService.success('gestor-avaliacoes.questao-reportada');
          this.onClose();
        },
        () => this._notificationService.error('gestor-avaliacoes.questao-erro-reportar'),
      );
  }

  public onClose() {
    this._dialogRef.close();
  }
}
