import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ACADEMIC_YEAR_KEY } from '@app/config';
import { DailyService } from '@app/core';

import { helperAcademicYear, notNull, preventWeekend, safeDate, safeEmptyList, switchDelay } from '@app/shared';
import { AppSelectors } from '@app/store';
import { Store } from '@ngrx/store';
import {
  addMonths,
  endOfMonth,
  endOfYear,
  format,
  getDay,
  getDaysInMonth,
  isAfter,
  isBefore,
  isEqual,
  isFuture,
  isWeekend,
  setDate,
  startOfDay,
  startOfMonth,
  startOfYear,
  subMonths,
  subYears,
} from 'date-fns';
import { isEqual as equal, head, isEmpty, last, size, first, sortBy } from 'lodash';
import { BehaviorSubject, Observable, Subject, combineLatest, merge } from 'rxjs';
import { distinctUntilChanged, filter, map, startWith, switchMap, tap } from 'rxjs/operators';

export interface CalendarData {
  date: Date;
  entityId: number;
}
@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CalendarComponent implements OnInit, OnDestroy {
  @Output() public date = new EventEmitter<Date>();
  @Output() public clickDate = new EventEmitter<Date>();
  public week: any = ['segunda', 'terca', 'quarta', 'quinta', 'sexta'];
  public calendar$: Observable<CalendarData>;
  public blankDays$: Observable<any[]>;
  public days$: Observable<any[]>;
  public date$: Observable<Date>;
  public period$: Observable<Date[]>;
  public loading$: Observable<boolean>;
  private _refresh$ = new Subject<void>();
  private _loading$ = new BehaviorSubject(true);
  private _date$ = new BehaviorSubject<Date>(null);
  private _entityId$ = new BehaviorSubject<number>(null);

  constructor(private _dailyService: DailyService, private _store: Store, private _destroy: DestroyRef) {
    this.period$ = this.loadPeriod();
    this.loading$ = this._loading$.pipe(switchDelay());
    this.date$ = this._date$.asObservable().pipe(
      takeUntilDestroyed(this._destroy),
      notNull(),
      distinctUntilChanged((previous, current) => isEqual(previous, current)),
    );
    this.loadCalendar();
  }

  @Input()
  public set data(data: CalendarData) {
    if (!!data) {
      if (isWeekend(data.date)) {
        this.changeDay(data.date);
      } else {
        this._date$.next(data.date);
      }
      this._entityId$.next(data.entityId);
    }
  }

  public ngOnInit() {
    this.loadDate();
  }

  public ngOnDestroy() {
    this._date$.complete();
    this._loading$.complete();
    this._entityId$.complete();
  }

  public onChangeDay(date: Date) {
    this.clickDate.emit(date);
    this.changeDay(date);
  }

  public onRefresh() {
    this._refresh$.next();
  }

  public prevMonth(params: any) {
    const threshold = head<Date>(params.period);
    const target = subMonths(params.date, 1);
    if (isBefore(threshold, target)) {
      const data = { date: target, entityId: params.calendar?.entityId };
      this.changeDay(data.date);
    }
  }

  public nextMonth(params: any) {
    const threshold = last<Date>(params.period);
    const target = addMonths(params.date, 1);
    if (isAfter(threshold, target)) {
      const data = { date: target, entityId: params.calendar?.entityId };
      this.changeDay(data.date);
    }
  }

  public classStatus(day: Date, current: Date, dailies: string[]): string {
    if (isEqual(startOfDay(day), startOfDay(current))) {
      return '!bg-modules-eclass-500  rounded-r-none text-white  after:absolute after:-right-4 after:border-y-[15px] after:border-l-[12px] after:border-y-transparent after:border-l-theme-500';
    }
    if (dailies?.some((date) => format(day, 'yyyy-MM-dd') === date)) {
      return isFuture(day) ? 'bg-base-skyblue-300' : 'bg-base-amber-200';
    }
  }

  private loadDate() {
    this.days$ = this.date$.pipe(
      takeUntilDestroyed(this._destroy),
      map((date) => Array.from({ length: getDaysInMonth(date) }).map((_, i) => startOfDay(setDate(date, i + 1)))),
      map((days) => days.filter((day) => !isWeekend(day))),
    );
    this.blankDays$ = this.date$.pipe(
      takeUntilDestroyed(this._destroy),
      map((date) => getDay(startOfMonth(date))),
      map((length) => Array.from({ length: length - 1 })),
      map((length) => (size(length) === 5 ? [] : length)),
    );
  }

  private loadCalendar() {
    const date$ = this.date$.pipe(
      distinctUntilChanged((previus, current) => isEqual(startOfMonth(previus), startOfMonth(current))),
    );
    const activeUser$ = this._store.select(AppSelectors.ActiveUser);
    const entityId$ = this._entityId$.pipe(distinctUntilChanged(isEqual));
    const calendar$ = merge(date$, entityId$, this._refresh$).pipe(
      takeUntilDestroyed(this._destroy),
      map(() => ({ date: this._date$.value, entityId: this._entityId$.value })),
      filter(({ date, entityId }) => !!date && !!entityId),
      distinctUntilChanged(equal),
    );
    const refresh$ = this._refresh$.pipe(startWith(null));
    this.calendar$ = combineLatest([calendar$, activeUser$, refresh$]).pipe(
      takeUntilDestroyed(this._destroy),
      tap(() => this._loading$.next(true)),
      map(([calendar, activeUser]) => ({ calendar, activeUser })),
      switchMap(({ calendar, activeUser }) =>
        this._dailyService
          .dayCalendar(
            {
              date: format(calendar.date, 'yyyy-MM-dd'),
              entity_id: calendar.entityId,
            },
            {
              [ACADEMIC_YEAR_KEY.year]: helperAcademicYear(calendar.date, activeUser),
            },
          )
          .pipe(
            safeEmptyList(),
            map((dailies) => ({ date: calendar.date, entityId: calendar.entityId, dailies })),
          ),
      ),
      tap(() => this._loading$.next(false)),
    );
  }

  private loadPeriod(): Observable<Date[]> {
    return this._store.select(AppSelectors.ActiveUser).pipe(
      map(({ bimestres }) => {
        if (!isEmpty(bimestres)) {
          const start = startOfMonth(safeDate(first<any>(sortBy(bimestres, 'data_inicio')).data_inicio));
          const end = endOfMonth(safeDate(last<any>(sortBy(bimestres, 'data_fim')).data_fim));
          return [start, end];
        }
        return [startOfYear(subYears(new Date(), 1)), endOfYear(new Date())];
      }),
    );
  }

  private changeDay(date: Date) {
    const safe = preventWeekend(date);
    this._date$.next(safe);
    this.date.emit(safe);
  }
}
