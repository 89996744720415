import { Component, DestroyRef, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Router } from '@angular/router';
import { Policies, notNull } from '@app/shared';
import { AppSelectors } from '@app/store';
import { AppState } from '@app/store/app.state';
import { Store } from '@ngrx/store';
import { isEmpty, isEqual } from 'lodash';
import { combineLatest, distinctUntilChanged, filter, map, share, startWith } from 'rxjs';

@Component({
  templateUrl: './permissions.component.html',
})
export class PermissionsComponent implements OnInit {
  constructor(
    private router: Router,
    private store: Store<AppState>,
    private destroy: DestroyRef,
    private activatedRoute: ActivatedRoute,
  ) {}

  public ngOnInit(): void {
    const store$ = this.store.select(AppSelectors.appFeature).pipe(
      notNull(),
      filter(({ usuario_ativo }) => !!usuario_ativo),
      takeUntilDestroyed(this.destroy),
      share(),
      distinctUntilChanged((previous: AppState, current: AppState) => previous.usuario_ativo === current.usuario_ativo),
    );

    const route$ = this.router.events.pipe(
      takeUntilDestroyed(this.destroy),
      distinctUntilChanged(isEqual),
      filter((event) => event instanceof NavigationEnd),
      map(() => this.router.routerState.snapshot.root),
      startWith(this.router.routerState.snapshot.root),
    );

    combineLatest([store$, route$]).subscribe(([store, route]) => {
      if (!this.validPermission(route, store.policies)) {
        return this.router.navigate(['/'], { relativeTo: this.activatedRoute });
      }
      if (!this.validRole(route, store)) {
        return this.router.navigate(['/'], { relativeTo: this.activatedRoute });
      }
    });
  }

  private validPermission(route: ActivatedRouteSnapshot, policies: Policies): boolean {
    if (!route.firstChild) {
      if (route.data?.permission) {
        return Policies.isAllow(route.data.permission, policies);
      }
      return true;
    }
    return this.validPermission(route.firstChild, policies);
  }

  private validRole(route: ActivatedRouteSnapshot, store: AppState): boolean {
    if (!route.firstChild) {
      if (!isEmpty(route.data?.role)) {
        return route.data.role.every((role) => role(store, route.data?.module));
      }
      return true;
    }
    return this.validRole(route.firstChild, store);
  }
}
