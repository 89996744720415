import { NgModule } from '@angular/core';
import { IsAllowDirective } from './is-allow.directive';
import { ClipboardDirective } from './clipboard.directive';
import { LayoutTemplateDirective } from './layout-template.directive';

@NgModule({
  declarations: [IsAllowDirective, ClipboardDirective, LayoutTemplateDirective],
  exports: [IsAllowDirective, ClipboardDirective, LayoutTemplateDirective],
})
export class DirectivesModule {}
