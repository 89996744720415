<div class="flex gap-1"><ng-content select="[app-label]"></ng-content> <span *ngIf="isRequired">*</span></div>
<div class="min-h-[3rem]" [ngClass]="{ 'form-field__error': showInvalid }">
  <ng-content
    select="[app-form], [app-input], [app-textarea], [app-input-suffix], [app-select], [app-checkbox], [app-custom], app-input-datepicker, app-switch, app-radio-group, app-checkbox, app-text-editor, app-upload-file, div, span"
  ></ng-content>
</div>
<div class="flex gap-1"><ng-content select="[app-suffix]"></ng-content></div>
<div class="relative pb-6 transition duration-500">
  <div [hidden]="!showInvalid">
    <ng-container *ngIf="!!error; else dynamicTemplate">
      <ng-content select="[app-error]"></ng-content>
    </ng-container>
    <ng-template #dynamicTemplate>
      <ng-container *ngFor="let error of control?.errors | keyvalue">
        <div class="flex flex-wrap" app-error>{{ label(error.key) | translate : error.value }}</div>
      </ng-container>
    </ng-template>
  </div>
</div>
