import { ChangeDetectionStrategy, Component, Inject, OnDestroy } from '@angular/core';
import { SkyEnglishService } from '@app/core/services/sky-english.service';
import { POPOVER_DATA, PopoverRef } from '@app/shared/modules/template/components/popover';
import { AppSelectors } from '@app/store';
import { AppState } from '@app/store/app.state';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { map, Subject, take, takeUntil } from 'rxjs';

@Component({
  selector: 'app-popover-download-observation-report',
  templateUrl: './popover-download-observation-report.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PopoverDownloadObservationReportComponent implements OnDestroy {
  private _destroy$ = new Subject<void>();

  constructor(
    @Inject(POPOVER_DATA) public data: any,
    private store: Store<AppState>,
    private popoverRef: PopoverRef,
    private translate: TranslateService,
    private skyEnglishService: SkyEnglishService,
  ) {}

  public ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

  public onDownloadObservationReport(report: any, teacher: number = 0) {
    this.store
      .select(AppSelectors.appFeature)
      .pipe(
        take(1),
        takeUntil(this._destroy$),
        map(({ token }) => {
          const params = {
            token,
            teacher,
          };

          return this.skyEnglishService.downloadReport(
            report.id,
            'observation-report',
            this.reportName(report, teacher),
            params,
          );
        }),
      )
      .subscribe();
    this.popoverRef.close();
  }

  private reportName(report: any, teacher: number): string {
    if (!!teacher) {
      return (
        'prof_' +
        this.sanitizeString(report?.teacher?.name || '') +
        '_' +
        (report?.title ?? this.translate.instant('servicos.sky-english.relatorio-pdf'))
      );
    }

    return report?.title;
  }

  private sanitizeString(text: string): string {
    return text
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .replace(/[^a-zA-Z0-9 ]/g, '')
      .toLowerCase()
      .replace(/\s+/g, '_');
  }
}
