<ng-container *ngIf="active$ | async as active">
  <app-horizontal-scroll>
    <ul class="relative flex h-full items-end px-3 pt-2">
      <li
        *ngFor="let tab of tabs"
        (click)="onChangeTo(tab)"
        [ngClass]="{ 'active ': tab.id === active.id }"
        class="group flex h-full cursor-pointer"
      >
        <ng-template [ngTemplateOutlet]="tab.header"></ng-template>
      </li>
    </ul>
  </app-horizontal-scroll>
  <div class="relative flex">
    <div
      class="flex"
      *ngFor="let tab of tabs; let i = index; trackBy: trackById"
      [ngClass]="{ ' w-full': tab.id === active.id }"
    >
      <ng-template
        *ngIf="tab.id === active.id"
        [ngTemplateOutlet]="tab.contentRef.templateRef || tab.content"
      ></ng-template>
    </div>
  </div>
</ng-container>
