import { ChangeDetectionStrategy, Component, DestroyRef } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TranslateService } from '@ngx-translate/core';

@Component({
  templateUrl: './loading-eclass.component.html',
  styleUrls: ['./loading-eclass.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadingEclassComponent {
  constructor(public translateService: TranslateService, public destroyRef: DestroyRef) {
    const title: HTMLTitleElement = document.querySelector('title');
    const icon: HTMLLinkElement = document.querySelector('link');
    icon.href = 'assets/favicon/eclass/favicon.ico';
    this.translateService
      .get('geral.portal-educacional')
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((res) => (title.innerHTML = res));
  }
}
