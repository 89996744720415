export interface PaymentAutomatic {
  merchant_id: string;
  merchant_key: string;
  nome: string;
  numero_cartao: string;
  validade: string;
  codigo_seguranca: string;
  bandeira: FlagCard;
}

export enum FlagCard {
  Visa = 'Visa',
  Mastercard = 'Master',
  Elo = 'Elo',
  Amex = 'Amex',
  Aura = 'Aura',
  Hipercard = 'Hipercard',
  Diners = 'Diners',
}

export interface CardFormatter {
  key: FlagCard;
  icon: string;
  regex: RegExp;
  mask: string;
}

export const CARDS_FORMATTER: CardFormatter[] = [
  {
    key: FlagCard.Visa,
    icon: 'assets/images/cards/icon-card-visa.svg',
    regex: /^4[0-9]{12}(?:[0-9]{3})?$/,
    mask: '0000-0000-0000-0000',
  },
  {
    key: FlagCard.Mastercard,
    icon: 'assets/images/cards/icon-card-mastercard.svg',
    regex: /^5[1-5][0-9]{14}$/,
    mask: '0000-0000-0000-0000',
  },
  {
    key: FlagCard.Elo,
    icon: 'assets/images/cards/icon-card-elo.svg',
    regex:
      /^(6(?:2212|2290|2358|2620|2621|2699|2704|2706|2789|2869|2899|3056|3095|3096|3635|4311|4375|4514|4573|5041|6277|6363|6364|6365|6369)[0-9]{12,16})$/,
    mask: '0000-0000-0000-0000',
  },
  {
    key: FlagCard.Amex,
    icon: 'assets/images/cards/icon-card-amex.svg',
    regex: /^3[47][0-9]{13}$/,
    mask: '0000-000000-00000',
  },
  {
    key: FlagCard.Aura,
    icon: 'assets/images/cards/icon-card-aura.svg',
    regex: /^5078\d{12}(\d{3})?$/,
    mask: '0000-0000-0000-0000',
  },
  {
    key: FlagCard.Hipercard,
    icon: 'assets/images/cards/icon-card-hipercard.svg',
    regex: /^(3841\d{15}|606282\d{10}(\d{3})?)$/,
    mask: '0000-0000-0000-0000',
  },
  {
    key: FlagCard.Diners,
    icon: 'assets/images/cards/icon-card-dinersclub.svg',
    regex: /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/,
    mask: '0000-000000-0000',
  },
];
