import { Directive } from '@angular/core';

@Directive({
  selector: '[app-outline-button]',
  host: {
    class:
      '[&:not([disabled])]:active:brightness-85 relative inline-flex cursor-pointer select-none items-center justify-center rounded-lg border p-2 outline-none transition duration-300 disabled:pointer-events-none disabled:cursor-not-allowed disabled:opacity-50 hover:[&:not([disabled])]:brightness-110',
  },
})
export class OutlineButtonDirective {}
