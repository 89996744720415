import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  template: `<div [innerHTML]="text"></div>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'bg-base-whisper-900 rounded-lg px-2 py-1 text-sm text-white m-2',
  },
})
export class TooltipComponent {
  @Input() public text: string;
}
