import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  InjectionToken,
  OnDestroy,
  Output,
} from '@angular/core';
import { head, remove } from 'lodash';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TabComponent } from './tab.component';

export const TABGROUP = new InjectionToken<TabGroupComponent>('TabGroup');

@Component({
  selector: 'app-tab-group',
  templateUrl: './tab-group.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: TABGROUP, useExisting: TabGroupComponent }],
})
export class TabGroupComponent implements OnDestroy {
  @Output() public activeId = new EventEmitter<number | string>();
  public tabs: TabComponent[] = [];

  public active$: Observable<TabComponent>;
  private _active$ = new BehaviorSubject<TabComponent>(null);

  constructor(private cdref: ChangeDetectorRef) {
    this.active$ = this._active$.asObservable().pipe(map((tab) => tab || head(this.tabs)));
  }

  public ngOnDestroy(): void {
    this._active$.complete();
  }

  public onChangeTo(tab: TabComponent): void {
    this._active$.next(tab);
    this.activeId.emit(tab.id);
  }

  public push(value: TabComponent) {
    this.tabs.push(value);
    this._active$.next(this._active$.value || value);
  }

  public remove(value: TabComponent) {
    remove(this.tabs, ({ id }) => id === value.id);
    this._active$.next(head(this.tabs));
  }

  public setActive(value: string | number) {
    const candidate = this.tabs.find((tab) => tab.id === value);
    this._active$.next(candidate);
  }

  public isActive(value: string | number) {
    return this._active$.value?.id === value;
  }

  public trackById = (index: any, data: any) => data.id;
  public changes = () => this.cdref.markForCheck();
}
