import { Policies } from '@app/shared/utils';

export interface AppState {
  [k: string]: any;
  token: string;
  access_token: string;
  funcao_padrao: number;
  usuario_ativo: any;
  dependente_padrao: number;
  dependentes: any[];
  funcoes: any[];
  policies: Policies;
  aluno_id?: number;
  responsavel_id?: number;
}

export const initialState: AppState = {
  token: null,
  access_token: null,
  funcao_padrao: null,
  usuario_ativo: null,
  dependente_padrao: null,
  dependentes: [],
  funcoes: [],
  policies: {},
};
