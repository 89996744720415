export interface File {
  id?: number;
  mimetype: string;
  hash: string;
  type: TypeFile;
  file: string;
  title: string;
  extension: string;
  size: string;
  url: string;
  vimeo_account?: string;
}

export enum TypeFile {
  Video = 1,
  Audio = 2,
  Image = 3,
  Pdf = 4,
  Office = 5,
}
