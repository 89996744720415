import { DIALOG_DATA } from '@angular/cdk/dialog';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { portalConst } from '@app/config';
import { AlertService, NotificationService } from '@app/core';
import { AssessmentFileType } from '@app/core/models';
import { AssessmentApplicationService } from '@app/core/services/assessment-application.service';
import { notNull } from '@app/shared';
import { TranslateService } from '@ngx-translate/core';
import { now } from 'lodash';
import { DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { BehaviorSubject, EMPTY, Subject, switchMap } from 'rxjs';

@Component({
  selector: 'app-modal-assessments-import-file',
  templateUrl: './modal-assessments-import-file.component.html',
})
export class ModalAssessmentsImportFileComponent implements OnInit, OnDestroy {
  public readonly fileType = AssessmentFileType;
  public form: FormGroup;
  public isFileTypeSelected: boolean = false;
  public loading$ = new BehaviorSubject<boolean>(false);
  public resetDropzone$ = new Subject<any>();
  public file: any;
  public isSaveEnabled: boolean = false;
  public config: DropzoneConfigInterface = {
    clickable: true,
    url: `${portalConst.api.assessments}/v1/import/${this.data.application_uuid}/tri`,
    createImageThumbnails: false,
    maxFiles: 1,
    maxFilesize: 30,
    acceptedFiles: '.txt, .prm, .sco',
    previewsContainer: false,
    autoProcessQueue: false,
  };

  constructor(
    @Inject(DIALOG_DATA) public data: any,
    private _alertService: AlertService,
    private notificationService: NotificationService,
    private _translate: TranslateService,
    private formBuilder: FormBuilder,
    private assessmentApplicationService: AssessmentApplicationService,
  ) {}

  public ngOnInit(): void {
    this.form = this.formBuilder.group({
      fileType: [undefined, Validators.required],
    });

    this.form.get('fileType').valueChanges.subscribe((value) => {
      setTimeout(() => (this.isFileTypeSelected = !!value), 100);
    });
  }

  public ngOnDestroy() {
    this.loading$.complete();
  }

  public get isSaveButtonDisabled(): boolean {
    return this.loading$.value || !this.isSaveEnabled;
  }

  public importTri(data: any) {
    return this.assessmentApplicationService.importTri(this.data.application_uuid, data);
  }

  public importProficiencia(data: any) {
    return this.assessmentApplicationService.importProficiencia(this.data.application_uuid, data);
  }

  public onUpload(data: any) {
    this.resetDropzone$.next(now());
    this.file = { ...data, type: this.form.get('fileType').value };

    this.form.get('fileType').setValue(undefined);
    this.isSaveEnabled = true;
  }

  public removeFile() {
    this.file = undefined;
    this.isSaveEnabled = false;
  }

  public save() {
    this._alertService
      .confirm({
        message: this._translate.instant('eclass.salvar-importacao'),
      })
      .pipe(
        notNull(),
        switchMap(() => {
          this.loading$.next(true);
          const QUERY = {
            [AssessmentFileType.TRI]: (formData: any) => this.importTri(formData),
            [AssessmentFileType.ProfAlunos]: (formData: any) => this.importProficiencia(formData),
          };

          return QUERY[this.file.type](this.file.formData);
        }),
      )
      .subscribe({
        next: () => {
          this.loading$.next(false);
          this.notificationService.success(this._translate.instant('eclass.upload-sucesso'));
          this.file = undefined;
          this.isSaveEnabled = false;
        },
        error: () => {
          this.loading$.next(false);
          this.notificationService.error(this._translate.instant('eclass.upload-falha'));
        },
      });
  }
}
