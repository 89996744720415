import { Injectable } from '@angular/core';
import { portalConst } from '@app/config';
import { Observable } from 'rxjs';
import { ApiService, Resp } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class MdPadraoSerieService {
  private _baseUrl = `${portalConst.api.usuario}/v1/hub-educacional/padrao`;

  constructor(private apiService: ApiService) {}

  public teacher(entityId: number, nivel_id?: number[]): Observable<Resp<any>> {
    return this.apiService.get<any>(`${this._baseUrl}/${entityId}/dados-professor`, { nivel_id });
  }
}
