<app-card class="h-full">
  <ng-container *ngIf="{ evaluation: evaluations$ | async, load: load$ | async } as evaluations">
    <div app-card-header class="relative flex w-full !flex-row justify-between px-5 pt-5">
      <div class="flex items-center gap-2">
        <h1 class="pl-2 text-lg uppercase" translate>gestor-avaliacoes.avaliacoes-em-andamento</h1>
      </div>
      <div>
        <button
          class="h-9 w-9 bg-green-300 transition duration-300 hover:bg-green-400"
          app-flat-button
          app-tooltip
          [tooltip]="'gestor-avaliacoes.ir-para-minhas' | translate"
          [routerLink]="['/eclass-prova']"
        >
          <svg-icon class="w-full p-1 text-white" src="assets/icons/icon-arrow-entry.svg"></svg-icon>
        </button>
      </div>
    </div>
    <ng-template #emptyTemplate>
      <div app-card-content class="h-full">
        <app-jumbotron translate class="py-8">gestor-avaliacoes.nenhuma-avaliacao</app-jumbotron>
      </div>
    </ng-template>
    <ng-template #loadTemplate>
      <app-horizontal-scroll class="h-full w-full">
        <div class="flex h-full px-5">
          <app-skeleton class="!w-full py-8"></app-skeleton>
        </div>
      </app-horizontal-scroll>
    </ng-template>
    <hr app-divider class="my-4" />
    <ng-container *ngIf="!evaluations.load; else loadTemplate">
      <ng-container *ngIf="!!evaluations.evaluation.length; else emptyTemplate">
        <div class="flex h-[22.5rem] flex-col gap-2 overflow-auto px-2">
          <div
            *ngFor="let evaluation of evaluations.evaluation"
            (click)="onAssessmentClick(evaluation.uuid)"
            class="relative flex items-center justify-between rounded-lg bg-base-mantis-50 px-5 py-3 shadow"
          >
            <div
              class="absolute left-0 top-0 h-full w-full cursor-pointer transition duration-300 hover:bg-white/50"
            ></div>
            <div class="flex w-full items-center gap-4">
              <div>
                <div
                  class="flex h-[40px] w-[40px] flex-col items-center justify-center rounded-full bg-base-gray-light-500 shadow-inner shadow-base-gray-200"
                >
                  <span class="text-2xl leading-none text-theme-900">{{ evaluation.data_inicio | date : 'dd' }}</span>
                </div>
              </div>

              <div class="flex w-full flex-col gap-1 truncate">
                <div class="flex items-center gap-4">
                  <h1 class="truncate text-md font-medium text-zinc-900 first-letter:uppercase">
                    {{ evaluation.avaliacao.titulo }}
                  </h1>
                </div>
                <div>
                  <ng-container [ngSwitch]="evaluation.status_execution">
                    <span
                      *ngSwitchCase="applicationStatusExecutionEnum.Scheduled"
                      class="w-full rounded-full bg-base-status-warning px-2 text-white"
                      translate
                    >
                      gda.minhas.agendada
                    </span>
                    <span
                      *ngSwitchCase="applicationStatusExecutionEnum.InProgress"
                      class="w-full rounded-full bg-base-status-info px-2 text-white"
                      translate
                    >
                      gda.minhas.andamento
                    </span>
                    <span
                      *ngSwitchCase="applicationStatusExecutionEnum.Closed"
                      class="w-full rounded-full bg-base-mantis-500 px-2 text-white"
                      translate
                    >
                      gda.minhas.encerrada
                    </span>
                  </ng-container>
                </div>
              </div>
              <div class="flex w-fit flex-col">
                <ng-container [ngSwitch]="evaluation.modo_aplicacao">
                  <ng-container *ngFor="let modo of modos">
                    <ng-container *ngSwitchCase="modo.id"
                      >{{ 'gestor-avaliacoes.avaliacao' | translate }} {{ modo.label }}
                    </ng-container>
                  </ng-container>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>
</app-card>
