<div
  (click)="onPreview()"
  class="absolute left-0 top-0 h-full w-full cursor-pointer transition duration-300 hover:bg-white/50"
  app-tooltip
  [tooltip]="content.title"
></div>

<app-contents-photo class="!h-60 rounded-t-xl" [src]="content.imagem"></app-contents-photo>

<div class="truncate rounded-b-xl bg-base-whisper-50 px-3 py-2 font-semibold">
  {{ content.title }}
</div>
