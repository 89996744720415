import { Injectable } from '@angular/core';
import { ApiService, Resp } from './api.service';
import { CrudService } from './crud.service';
import { portalConst } from '@app/config';
import { Observable } from 'rxjs';
import { AnswerSummary } from '../models';

@Injectable({ providedIn: 'root' })
export class AnswerService extends CrudService<any> {
  constructor(api: ApiService) {
    super(api, `${portalConst.api.eclass}/v1/answers`);
  }

  public sendAnswer(req?: any): Observable<Resp<any>> {
    return this.api.post(`${this.url}`, req);
  }

  public resume(req?: any): Observable<Resp<AnswerSummary[]>> {
    return this.api.get(`${this.url}/resume`, req);
  }

  public updateAnswer(id: string, req?: any): Observable<Resp<any>> {
    return this.api.put(`${this.url}/${id}`, req);
  }
}
