import { ConnectedPosition, OverlayRef } from '@angular/cdk/overlay';
import { ComponentRef, ElementRef, InjectionToken, ViewContainerRef } from '@angular/core';
import { Observable, Subject, merge, take } from 'rxjs';
import { PopoverCloseDirective } from './popover-close.directive';
import { PopoverContentDirective } from './popover-content.directive';
import { PopoverFooterDirective } from './popover-footer.directive';
import { PopoverHeaderDirective } from './popover-header.directive';
import { PopoverComponent } from './popover.component';
import { PopoverDirective } from './popover.directive';

export * from './popover';
export * from './popover.service';
export * from './strategy';

export const POPOVER_DATA = new InjectionToken<any>('CdkPopover');

export class PopoverData<D = unknown> {
  public elementRef: ElementRef;
  public viewContainerRef?: ViewContainerRef;
  public positionStrategy?: ConnectedPosition[];
  public panelClass?: string | string[];
  public data?: D | null;
}

export class PopoverRef<R = unknown, C = unknown> {
  public readonly componentInstance: C | null;
  public readonly panelClass: string | string[] = [];
  public readonly componentRef: ComponentRef<C> | null;
  public readonly closed: Observable<R | undefined>;
  private _close$ = new Subject<R | undefined>();

  constructor(public readonly overlayRef: OverlayRef) {
    this.closed = merge(this._close$.asObservable(), overlayRef.backdropClick()).pipe(take<any>(1));
  }

  public close(value?: any) {
    if (this.componentInstance) {
      this._close$.next(value);
      this.overlayRef.dispose();
      this._close$.complete();
    }
  }
}

export const POPOVER = [
  PopoverDirective,
  PopoverComponent,
  PopoverCloseDirective,
  PopoverHeaderDirective,
  PopoverContentDirective,
  PopoverFooterDirective,
];
