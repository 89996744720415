<label app-label *ngIf="label">{{ 'materiais-didaticos.serie' | translate }}</label
><ng-select
  app-select
  [placeholder]="label ? ('eclass.selecione' | translate) : ('materiais-didaticos.serie' | translate)"
  [multiple]="multiple"
  (focus)="onFocus()"
  [formControl]="ctrl"
  [notFoundText]="'geral.nao-encontrado' | translate"
  [loading]="loading$ | async"
>
  <ng-option *ngFor="let item of data$ | async" [value]="item.id">
    <span class="block w-full whitespace-normal break-words"> {{ item.descricao }} </span>
  </ng-option>
</ng-select>
