import { Injectable } from '@angular/core';
import { notNull, selectedEntityActive } from '@app/shared';
import { AppSelectors } from '@app/store';
import { AppState } from '@app/store/app.state';
import { Store } from '@ngrx/store';
import { first } from 'lodash';
import { map } from 'rxjs';
import { MenuItem, MenuRef } from './menu';

@Injectable({ providedIn: 'root' })
export class AssessmentsManagerMenu implements MenuRef {
  public identifier = 'assessmentsManager';

  public readonly menu: MenuItem[] = [
    () => ({
      title: 'menu.gestor-avaliacoes',
      url: ['/eclass-prova', 'prova-diagnostica'],
      icon: 'icon-gestor-avaliacoes',
      dimension: 'h-7 w-7',
      permission: [1, 4],
      //   show: false,
    }),
  ];

  public readonly submenu: MenuItem[] = [
    () => ({
      title: 'breadcrumb.assessment-manager.avaliacoes',
      url: '/eclass-prova',
      icon: 'icon-course-edit',
      group: 'menu.gestor-avaliacoes',
      exact: true,
    }),
    () => ({
      title: 'breadcrumb.assessment-manager.minhas-questoes',
      url: '/eclass-prova/questoes',
      icon: 'icon-text',
      group: 'menu.gestor-avaliacoes',
      permission: [3, 15],
    }),
    () => ({
      title: 'breadcrumb.assessment-manager.avaliacoes',
      url: '/prova-diagnostica',
      icon: 'icon-course-edit',
      group: 'menu.paaeb',
      exact: true,
      permission: [3, 73],
      show: this.store.select(AppSelectors.ActiveUser).pipe(
        notNull(),
        map((activeUser) => !activeUser?.escola_externa),
      ),
    }),
    () => ({
      title: 'breadcrumb.assessment-manager.minhas-questoes',
      url: '/prova-diagnostica/questoes',
      icon: 'icon-text',
      group: 'menu.paaeb',
      permission: [3, 15],
      show: this.store.select(AppSelectors.ActiveUser).pipe(
        notNull(),
        map((activeUser) => first(selectedEntityActive(activeUser))?.tipo === 5),
      ),
    }),
  ];

  constructor(private store: Store<AppState>) {}
}
