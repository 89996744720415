import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, distinctUntilChanged } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  public loading$: Observable<boolean>;

  private _loading = new BehaviorSubject(true);

  constructor() {
    this.loading$ = this._loading.asObservable().pipe(distinctUntilChanged());
  }

  public start() {
    this._loading.next(true);
  }

  public finish() {
    this._loading.next(false);
  }
}
