export interface LevelCategory {
  id: number;
  descricao: string;
}

export interface YearCategory {
  id: number;
  descricao: string;
  nivel_id: number;
}

export interface SubjectCategory {
  id: number;
  descricao: string;
  ano_id: number;
}

export interface FascicleCategory {
  id: number;
  descricao: string;
  disciplina_id: number;
  ano_id: number;
}

export interface ContentsCategories {
  niveis: LevelCategory[];
  anos: YearCategory[];
  disciplinas: SubjectCategory[];
  fasciculos: FascicleCategory[];
}

export interface RequestContents<T> {
  status: number;
  result: {
    status: boolean;
    categorias: T;
  };
}
