import { Directive, Host, HostListener, Inject, Input, Optional, SkipSelf } from '@angular/core';
import { SIDENAV_REF, SidenavRef } from './sidenav';

@Directive({
  selector: '[sidenavClose]',
})
export class SidenavCloseDirective {
  @Input() public sidenavClose: any;

  constructor(
    @Optional() @Inject(SIDENAV_REF) @Host() private templateRef: SidenavRef,
    @Optional() @Inject(SIDENAV_REF) @SkipSelf() private componentRef: SidenavRef,
  ) {}

  @HostListener('click') public click() {
    this?.templateRef?.close(this.sidenavClose);
    this?.componentRef?.close(this.sidenavClose);
  }
}
