import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

const DEFAULT_STYLE = {
  background: 'bg-theme-100 text-theme-500 hover:bg-theme-300 hover:text-theme-500',
  active: 'bg-theme-500 text-theme-contrast',
};
@Component({
  selector: 'app-badge',
  templateUrl: './badge.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BadgeComponent {
  @Input() public badgeStyle: any;
  @Input() public active = true;

  public activeStyle(active): string {
    const style = this.badgeStyle || DEFAULT_STYLE;
    const base = style?.background;
    if (active) {
      return `${base} ${style?.active}`;
    }
    return base;
  }
}
