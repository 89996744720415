export enum AlertDataType {
  Warning,
  Success,
}
export interface AlertData {
  title?: string;
  message?: string;
  innerHtml?: string;
  type?: AlertDataType;
  textPositiveButton?: string;
  textNegativeButton?: string;
}
export interface AlertParams extends AlertData {
  confirm: boolean;
}
