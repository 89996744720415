import { Directive } from '@angular/core';

@Directive({
  selector: '[app-input]',
  host: {
    class:
      'block w-full h-12 p-2 bg-white border border-gray-200 rounded-md text-sm shadow-inner outline-none placeholder:text-slate-400 placeholder:text-sm focus:border-gray-400 !ring-offset-0 !ring-0 transition duration-500',
  },
})
export class InputDirective {}
