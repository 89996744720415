import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ModalNewAssessmentState } from './modal-new-assessment.state';

export const featureKey = 'modalAvaliacaoGDA';

export const ModalNewAssessmentFeature = createFeatureSelector<ModalNewAssessmentState>(featureKey);

export const selectState = createSelector(ModalNewAssessmentFeature, (state: ModalNewAssessmentState) => state);
export const selectDisabled = createSelector(
  ModalNewAssessmentFeature,
  ({ disabled }: ModalNewAssessmentState) => disabled,
);
export const selectHasChanges = createSelector(
  ModalNewAssessmentFeature,
  ({ hasChanges }: ModalNewAssessmentState) => hasChanges,
);
export const selectPressSubmit = createSelector(
  ModalNewAssessmentFeature,
  ({ pressSubmit }: ModalNewAssessmentState) => pressSubmit,
);
export const selectAssessment = createSelector(
  ModalNewAssessmentFeature,
  ({ avaliacao }: ModalNewAssessmentState) => avaliacao,
);
export const selectApplication = createSelector(
  ModalNewAssessmentFeature,
  ({ aplicacao }: ModalNewAssessmentState) => aplicacao,
);
export const selectQuestions = createSelector(
  ModalNewAssessmentFeature,
  ({ questoes }: ModalNewAssessmentState) => questoes,
);
export const selectAttemptCount = createSelector(
  ModalNewAssessmentFeature,
  ({ aplicacao }: ModalNewAssessmentState) => aplicacao?.tentativa_count,
);

export const selectCountryId = createSelector(
  ModalNewAssessmentFeature,
  ({ avaliacao }: ModalNewAssessmentState) => avaliacao?.pais_id,
);

export const selectQuestionActived = createSelector(
  ModalNewAssessmentFeature,
  ({ question_actived_uuid }: ModalNewAssessmentState) => question_actived_uuid,
);

export const selectAssessmentOrigin = createSelector(
  ModalNewAssessmentFeature,
  ({ avaliacao }: ModalNewAssessmentState) => avaliacao.origem,
);
