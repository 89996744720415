import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject, takeUntil } from 'rxjs';
import { Breadcrumb } from './breadcrumb';
import { BreadcrumbService } from './breadcrumb.service';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [BreadcrumbService],
  host: {
    class: 'flex items-center',
  },
})
export class BreadcrumbComponent implements OnInit, OnDestroy {
  @Input() public isIVCA = false;
  public breadcrumbs$: Observable<Breadcrumb[]>;
  private destroy$ = new Subject<void>();

  constructor(private breadcrumbService: BreadcrumbService) {}

  public ngOnInit() {
    this.breadcrumbs$ = this.breadcrumbService.breadcrumbs$.pipe(takeUntil(this.destroy$));
  }

  public ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
