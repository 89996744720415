import { NgModule } from '@angular/core';
import { DirectivesModule } from './directives/directives.module';
import { TemplateModule } from './modules';
import { PipesModule } from './pipes/pipes.module';
import { FilterObjectPipe } from './utils/filter-object.pipe';
import { StripHtmlPipe } from './utils/strip-html.pipe';

@NgModule({
  declarations: [FilterObjectPipe, StripHtmlPipe],
  imports: [TemplateModule, PipesModule, DirectivesModule],
  exports: [TemplateModule, PipesModule, DirectivesModule, FilterObjectPipe, StripHtmlPipe],
})
export class SharedModule {}
