import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { AppState } from '@app/store/app.state';
import { Store } from '@ngrx/store';
import { tap } from 'rxjs';
import { CoreService } from '../services/core.service';
import { Login } from './login';

export const AuthGuard: CanActivateFn = () => {
  const coreService = inject(CoreService);
  const store = inject(Store<AppState>);

  return coreService.isAuthenticated().pipe(
    tap({
      next: (authenticated) => !authenticated && Login.navigateToLogin(store),
      error: () => Login.navigateToLogin(store),
    }),
  );
};
