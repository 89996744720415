<div class="flex w-fit flex-col-reverse items-center lg:flex-col" *ngIf="{ open: open$ | async, } as data">
  <ul
    class="relative z-999 flex h-fit w-fit flex-row gap-2 rounded-full bg-theme-600 p-2 px-7 shadow-md shadow-black/30 transition-all duration-300 lg:gap-4"
  >
    <li *ngFor="let item of menu$ | async">
      <ng-container *ngIf="!!item.redirect; else internalTemplate">
        <a
          app-icon-button
          app-tooltip
          [tooltip]="item.title | translate"
          [href]="item.redirect"
          target="_blank"
          class="flex h-12 w-12 items-center !rounded-full text-white/75 shadow-sm shadow-black/40 hover:bg-white/20 hover:text-white/80"
        >
          <svg-icon [src]="item.icon | icon" class="flex" [ngClass]="item.dimension || 'h-6 w-6'"></svg-icon>
        </a>
      </ng-container>

      <ng-template #internalTemplate>
        <ng-container *ngIf="item.submenu.length < 2; else dropdownTemplate">
          <a
            app-icon-button
            app-tooltip
            [tooltip]="item.title | translate"
            [routerLink]="routerLink(item)"
            [routerLinkActive]="['active']"
            [routerLinkActiveOptions]="{ exact: item.exact }"
            (isActiveChange)="onActiveRoute(item, $event)"
            (click)="onClose()"
            class="flex h-12 w-12 items-center !rounded-full bg-black bg-opacity-1 text-white/60 shadow-sm shadow-black/40 !brightness-100 transition duration-300 hover:bg-white/20 hover:text-white/80"
          >
            <svg-icon [src]="item.icon | icon" class="flex" [ngClass]="item.dimension || 'h-6 w-6'"></svg-icon>
          </a>
        </ng-container>
        <ng-template #dropdownTemplate>
          <button
            type="button"
            app-icon-button
            app-tooltip
            [tooltip]="item.title | translate"
            (click)="item.click ? onActionClick(item) : onDropdown(item, data.open)"
            [ngClass]="{
              active: isRouterLinkActive(item),
              current: data.open === item.ref.identifier
            }"
            class="flex h-12 w-12 items-center !rounded-full bg-black bg-opacity-1 text-white/60 shadow-sm shadow-black/40 !brightness-100 transition duration-300 hover:bg-white/20 hover:text-white/80"
          >
            <svg-icon [src]="item.icon | icon" class="flex" [ngClass]="item.dimension || 'h-6 w-6'"></svg-icon>
          </button>
        </ng-template>
      </ng-template>
    </li>
  </ul>
  <div
    class="relative -bottom-14 mx-8 h-fit w-full min-w-[19.5rem] overflow-hidden px-2 py-5 lg:top-[-1.9rem] lg:pb-5 lg:pt-0"
  >
    <div
      class="relative z-90 rounded-2xl bg-white p-2 shadow-full shadow-black/25 transition duration-300 lg:shadow-md"
      [ngClass]="{ 'translate-y-[100vh] lg:-translate-y-[125%]': !data.open }"
    >
      <ng-template [cdkPortalOutlet]="submenu$ | async"></ng-template>
    </div>
  </div>
</div>
