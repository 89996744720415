<app-modal-header [automaticClose]="false" class="border-b-2 border-theme-500" (closeEvent)="onClose()">
  <div class="flex w-full items-center gap-2">
    <svg-icon class="w-10 text-theme-500" src="assets/icons/icon-gestor-avaliacoes.svg" alt="E-Class Prova"></svg-icon>

    <div class="hidden text-sm font-semibold leading-tight text-gray-800 md:grid">
      <span translate>gestor-avaliacoes.e-class</span>
      <span class="text-lg font-semibold text-black" translate> gestor-avaliacoes.prova </span>
    </div>

    <ng-container *ngIf="state$ | async as assessments">
      <hr app-modal-divider />
      <div class="flex w-full items-center justify-between">
        <div class="grid truncate text-ellipsis">
          <span class="flex truncate text-[14px] font-semibold leading-tight text-gray-800">
            {{ assessments.avaliacao.titulo }}
          </span>
          <span *ngIf="assessments.avaliacao.code" class="mt-1 text-base font-semibold uppercase text-gray-500">
            #{{ assessments.avaliacao.code }}
          </span>
        </div>
        <div *ngIf="!!assessments.aplicacao.uuid && !!onPreviewer(assessments)" class="ml-3 flex justify-end md:ml-0">
          <button
            app-flat-button
            app-tooltip
            (click)="onPreviewAssessment(assessments.aplicacao.uuid)"
            [disabled]="!!assessments.hasChanges"
            [tooltip]="
              !!assessments.hasChanges ? ('gestor-avaliacoes.salve-alteracoes-pre-visualizar' | translate) : undefined
            "
            class="hidden bg-base-status-info px-5 uppercase text-white md:flex"
            type="button"
            translate
          >
            <svg-icon class="flex h-6 w-6" src="assets/icons/icon-view-fine.svg"></svg-icon>
            <span class="ml-2 hidden md:flex" translate> gestor-avaliacoes.pre-visualizar </span>
          </button>
        </div>
      </div>
    </ng-container>
  </div>
</app-modal-header>

<div app-modal-content class="!w-[98vw] !p-0">
  <app-sidenav-group class="!h-[92vh]">
    <ng-template app-sidenav-outer>
      <app-card *ngIf="!!loading.value; else options">
        <div app-card-content class="!h-[92vh]">
          <app-skeleton [showLabel]="false"></app-skeleton>
        </div>
      </app-card>
      <ng-template #options>
        <div class="h-[80vh] overflow-y-auto bg-base-whisper-500 md:h-[87vh] lg:h-[84vh] xl:h-[86vh]">
          <div class="grid h-auto w-full gap-4 p-3">
            <app-modal-new-assessment-general-settings></app-modal-new-assessment-general-settings>
            <app-modal-new-assessment-questions class="relative"></app-modal-new-assessment-questions>
            <app-modal-new-assessment-application
              (closeModal)="onCloseModal($event)"
            ></app-modal-new-assessment-application>
          </div>
        </div>
      </ng-template>
    </ng-template>
  </app-sidenav-group>

  <div class="absolute bottom-0 z-999 flex w-full items-center bg-white px-6 py-5 shadow-up">
    <div class="hidden w-full md:flex">
      <button app-button (click)="onClose()" class="bg-red-500 px-5 uppercase text-white" type="button" translate>
        <span *ngIf="!!(state$ | async).hasChanges; else closeTemplate" translate> gestor-avaliacoes.cancelar </span>
        <ng-template #closeTemplate> {{ 'gestor-avaliacoes.fechar' | translate }} </ng-template>
      </button>
    </div>

    <div class="flex w-full items-center gap-4">
      <div *ngIf="!isTrailOrigin" class="w-full items-center md:flex md:px-5 lg:justify-end">
        <span app-suffix class="font-semibold md:mr-2" translate> gestor-avaliacoes.avaliacao-publicada </span>
        <app-switch
          (click)="onStopPropagation($event)"
          class="flex items-center"
          [formControl]="statusCtrl"
        ></app-switch>
      </div>
      <div class="flex">
        <button
          app-button
          (click)="onSave()"
          [disabled]="!state$.value.hasChanges || isLoading"
          class="w-full gap-3 whitespace-nowrap bg-theme-950 px-5 uppercase text-white"
          type="button"
          translate
        >
          gestor-avaliacoes.salvar-alteracoes

          <app-skeleton *ngIf="isLoading" baseStyle="!h-1 bg-none" [showLabel]="false"></app-skeleton>
        </button>
      </div>
    </div>
  </div>
</div>
