import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Observable } from 'rxjs';
import { LoadingService, StatisticService, ThemeService } from './core/services';
import { switchDelay } from './shared/utils/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent {
  public isLoading$: Observable<boolean>;

  constructor(
    private themeService: ThemeService,
    private loadingService: LoadingService,
    private statisticService: StatisticService,
  ) {
    this.themeService.changeTheme('eclass');
    this.statisticService.startAnalytics();
    this.isLoading$ = this.loadingService.loading$.pipe(switchDelay());
  }
}
