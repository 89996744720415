import { Injectable } from '@angular/core';
import { portalConst } from '@app/config';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EscolarTempService {
  private readonly _baseUrl = `${portalConst.api.portal}/v1`;

  constructor(private _api: ApiService) {}

  public getOccurrences(data: any): Observable<any> {
    return this._api.post(`${this._baseUrl}/filtro-academico-notifi`, data);
  }
}
