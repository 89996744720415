<app-modal-header class="border-b-2 border-theme-500">
  <div class="flex w-full grid-cols-[150px_auto_1fr_auto_auto] flex-col gap-5 md:grid">
    <div class="flex w-full items-center gap-2">
      <svg-icon
        class="w-10 text-theme-500"
        src="assets/icons/icon-gestor-avaliacoes.svg"
        alt="E-Class Prova"
      ></svg-icon>
      <h1 class="text-sm font-semibold leading-tight text-gray-800" translate>
        gestor-avaliacoes.e-class
        <br />
        <b class="text-lg text-black" translate>gestor-avaliacoes.prova</b>
      </h1>
    </div>
    <hr app-modal-divider />

    <div class="mr-5 flex w-full items-center justify-center text-black md:grid md:w-fit">
      <span class="whitespace-nowrap text-lg font-semibold" translate> gestor-avaliacoes.escala-proficiencia </span>
    </div>
  </div>
</app-modal-header>

<div app-modal-content class="h-[80vh] !p-0 md:h-[90vh] lg:w-[95vw] xl:h-[70vh] xl:w-[65vw]">
  <div class="h-full overflow-hidden p-5">
    <app-tab-group>
      <app-tab class="flex w-full">
        <button
          app-tab-header
          class="!bg-white text-black group-[.active]:!bg-base-mantis-500 group-[.active]:!text-white"
          type="button"
          translate
        >
          Geral
        </button>
        <ng-template app-tab-content>
          <div
            class="grid h-[73vh] !w-full overflow-y-auto border-t-2 border-t-base-mantis-500 !p-0 md:h-[83vh] xl:h-[63vh]"
          >
            <div *ngFor="let subject of subjectsGeneral$ | async" class="flex w-full flex-col">
              <p class="mt-2 text-lg">{{ subject.descricao }}</p>
              <div class="flex w-full">
                <div class="w-1/2 p-1">
                  <table app-table>
                    <thead>
                      <tr>
                        <td app-table-cell colspan="3" translate>gestor-avaliacoes.escala-niveis</td>
                      </tr>
                      <tr>
                        <th app-table-cell translate>servicos.nivel</th>
                        <th app-table-cell translate>gestor-avaliacoes.pontos</th>
                        <th app-table-cell>{{ 'gestor-avaliacoes.percentual' | translate }} (%)</th>
                      </tr>
                    </thead>
                    <tbody app-table-body>
                      <tr *ngFor="let scale of subject.scales">
                        <td
                          app-table-cell
                          class="text-center"
                          [ngClass]="{ 'cursor-pointer': !!scale.descricao }"
                          app-tooltip
                          [tooltip]="
                            !!scale.descricao ? ('gestor-avaliacoes.clique-para-ver-detalhes' | translate) : undefined
                          "
                          #nivel
                          (click)="onOpenDescription(nivel, scale.descricao)"
                        >
                          {{ scale.name }}
                        </td>
                        <td app-table-cell class="text-center">{{ scale.min_score }} - {{ scale.max_score }}</td>
                        <td app-table-cell class="text-center">{{ scale.percent || 0 | number : '1.2-2' }}%</td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr>
                        <td app-table-cell colspan="3">
                          {{ 'gestor-avaliacoes.seu-nivel' | translate }}: {{ subject.nivel }}
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
                <div class="w-1/2 p-1">
                  <table app-table>
                    <thead>
                      <tr>
                        <td app-table-cell colspan="3" translate>gestor-avaliacoes.escala-adequacao-titulo</td>
                      </tr>
                      <tr>
                        <th app-table-cell translate>servicos.nivel</th>
                        <th app-table-cell translate>gestor-avaliacoes.pontos</th>
                        <th app-table-cell translate>gestor-avaliacoes.media</th>
                      </tr>
                    </thead>
                    <tbody app-table-body>
                      <tr *ngFor="let adequacao of subject.adequacao">
                        <td app-table-cell class="text-center">{{ adequacao.name }}</td>
                        <td app-table-cell class="text-center">
                          {{ adequacao.min_score }} - {{ adequacao.max_score }}
                        </td>
                        <td app-table-cell class="text-center">{{ adequacao.media || 0 | number : '1.2-2' }}</td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr>
                        <td app-table-cell colspan="3">
                          {{ 'gestor-avaliacoes.seu-nivel' | translate }}: {{ subject.nivel_adequacao }}
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </app-tab>

      <app-tab *ngIf="isNotEntity$ | async" class="flex w-full">
        <button
          app-tab-header
          class="!bg-white text-black group-[.active]:!bg-base-mantis-500 group-[.active]:!text-white"
          type="button"
          translate
        >
          gestor-avaliacoes.por-unidade
        </button>
        <ng-template app-tab-content>
          <div
            class="grid h-[73vh] !w-full overflow-y-auto overflow-x-hidden border-t-2 border-t-base-mantis-500 !p-0 md:h-[83vh] xl:h-[63vh]"
          >
            <div *ngFor="let subject of subjectsEntities$ | async" class="flex w-full flex-col">
              <p class="mt-2 text-lg">{{ subject.descricao }}</p>
              <div class="flex w-full flex-col">
                <div class="w-full !overflow-x-hidden p-1">
                  <span class="mt-4 block" translate>gestor-avaliacoes.escala-niveis</span>
                  <div class="block h-auto w-[96vw] overflow-x-auto lg:w-[92vw] xl:w-[63vw]">
                    <table app-table class="mb-4 w-full border-separate border-spacing-0.5 border-spacing-y-0.5">
                      <thead>
                        <tr>
                          <th app-table-cell class="min-w-[6rem]" translate>servicos.nivel</th>
                          <th app-table-cell class="min-w-[8rem]" translate>gestor-avaliacoes.pontos</th>
                          <ng-container *ngFor="let entity of subject.entities">
                            <th
                              *ngIf="onGetEntity(entity) as entity"
                              app-table-cell
                              app-tooltip
                              [tooltip]="entity.nome"
                            >
                              <span>{{ entity.abreviatura || entity.nome }}</span>
                            </th>
                          </ng-container>
                        </tr>
                      </thead>
                      <tbody app-table-body>
                        <tr *ngFor="let scale of subject.scales">
                          <td
                            app-table-cell
                            class="text-center"
                            [ngClass]="{ 'cursor-pointer': !!scale.descricao }"
                            app-tooltip
                            [tooltip]="
                              !!scale.descricao ? ('gestor-avaliacoes.clique-para-ver-detalhes' | translate) : undefined
                            "
                            #nivel
                            (click)="onOpenDescription(nivel, scale.descricao)"
                          >
                            {{ scale.name }}
                          </td>
                          <td app-table-cell class="text-center">{{ scale.min_score }} - {{ scale.max_score }}</td>
                          <td *ngFor="let count of scale.count" app-table-cell class="text-center">
                            {{ count || 0 | number : '1.2-2' }}%
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="w-full !overflow-x-hidden p-1">
                  <span class="mt-4 block" translate>gestor-avaliacoes.escala-adequacao-titulo</span>
                  <div class="block h-auto w-[96vw] overflow-x-auto lg:w-[92vw] xl:w-[63vw]">
                    <table app-table class="mb-4 w-full border-separate border-spacing-0.5 border-spacing-y-0.5">
                      <thead>
                        <tr>
                          <th app-table-cell translate>servicos.nivel</th>
                          <th app-table-cell translate>gestor-avaliacoes.pontos</th>
                          <th *ngFor="let entity of subject.entities" app-table-cell>{{ entity }}</th>
                        </tr>
                      </thead>
                      <tbody app-table-body>
                        <tr *ngFor="let adequacao of subject.adequacao">
                          <td app-table-cell class="text-center">{{ adequacao.name }}</td>
                          <td app-table-cell class="text-center">
                            {{ adequacao.min_score }} - {{ adequacao.max_score }}
                          </td>
                          <td *ngFor="let media of adequacao.media" app-table-cell class="text-center">
                            {{ media || 0 | number : '1.2-2' }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </app-tab>
    </app-tab-group>
  </div>
</div>
