import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { Menu, SUBMENU } from '@app/core/menu';
import { MenuService } from '@app/core/services';
import { Dictionary, groupBy } from 'lodash';

@Component({
  templateUrl: './dropdown-menu.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DropdownMenuComponent {
  public items: Dictionary<Menu[]>;

  constructor(private menuService: MenuService, @Inject(SUBMENU) private submenuRef: any) {
    this.items = groupBy(this.submenuRef.ref, 'group');
  }

  public onClose(item: Menu) {
    if (!item.redirect) {
      this.menuService.menu(this.submenuRef.ref);
    }
    this.submenuRef.close();
  }

  public onActionClick(item: Menu) {
    item.click();
    this.onClose(item);
  }
}
