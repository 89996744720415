import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'document',
})
export class DocumentPipe implements PipeTransform {
  public transform(document: string): string {
    const sanitized = (document || '').replace(/\D/g, '');
    if (sanitized.length === 11) {
      return sanitized.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
    }
    return '';
  }
}
