import { ConnectionPositionPair } from '@angular/cdk/overlay';
import { ChangeDetectionStrategy, Component, ElementRef, Input } from '@angular/core';
import { Popover, Strategy } from '@app/shared/modules/template/components/popover';
import { PopoverDownloadObservationReportComponent } from '../popover-download-observation-report/popover-download-observation-report.component';

@Component({
  selector: 'app-obervation-report-table',
  templateUrl: './obervation-report-table.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ObervationReportTableComponent {
  public readonly strategy = Strategy;
  @Input() public activedYear: number;
  @Input() public observationReports: any;
  public expandedIndex: number | null = null;

  constructor(private popover: Popover) {}

  public onOpenPopover(elementRef: ElementRef, positionStrategy: ConnectionPositionPair[] = [], report: any): void {
    this.popover.open(PopoverDownloadObservationReportComponent, {
      panelClass: ['border-modules-sky-english-500'],
      positionStrategy,
      elementRef,
      data: { title: 'Popover', report },
    }).closed;
  }

  public toggleExpand(index: number) {
    this.expandedIndex = this.expandedIndex === index ? null : index;
  }
}
