import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { PaisEnum } from '@app/core/models';
import { UserService } from '@app/core/services';
import { LoginFromUserService } from '@app/layouts/services/login-from-user.service';
import { PopoverRef } from '@app/shared/modules/template/components/popover';
import { debounceDelay } from '@app/shared/utils';
import { AppState } from '@app/store/app.state';
import { isEmpty } from 'lodash';
import { Observable, Subject, of } from 'rxjs';
import { catchError, exhaustMap, map, takeUntil } from 'rxjs/operators';
@Component({
  selector: 'app-popover-tab-student',
  templateUrl: './popover-tab-student.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PopoverTabStudentComponent implements OnInit, OnDestroy {
  @Input({ required: true }) public store: AppState;

  public searchCtrl = new FormControl();
  public students$: Observable<any[]>;
  public destroy$ = new Subject<void>();

  constructor(
    private popoverRef: PopoverRef,
    private userService: UserService,
    private loginFromUserService: LoginFromUserService,
  ) {}

  public ngOnInit() {
    this.students$ = this.searchCtrl.valueChanges.pipe(
      takeUntil(this.destroy$),
      debounceDelay(),
      exhaustMap((search) => {
        if (search) {
          return this.userService
            .schoolStudents(this.store.usuario_ativo.entidade_id, {
              busca: search,
              qtd: 9999,
              hispanos: this.store.usuario_ativo.pais_id === PaisEnum.Brasil ? null : true,
            })
            .pipe(
              catchError(() => of({ data: [] })),
              map((student) =>
                student.data.map((res) => ({
                  ...res,
                  lastName: res.sobrenome,
                  group: res.aluno.aluno_turmas[0].turma.cod_turma,
                  grade: res.aluno.aluno_turmas[0].turma.serie.descricao,
                })),
              ),
            );
        }
        return of([]);
      }),
    );
  }

  public ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public onChange(student: any) {
    this.loginFromUserService.with(student.usuario_id).subscribe(() => this.popoverRef.close());
  }

  public isEmpty(data: any[]): boolean {
    return isEmpty(data);
  }
}
