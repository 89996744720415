import { Injectable } from '@angular/core';
import { portalConst } from '@app/config';
import { AppSelectors } from '@app/store';
import { AppState } from '@app/store/app.state';
import { Store } from '@ngrx/store';
import { Observable, take } from 'rxjs';
import { ApiService, Resp } from './api.service';
import { CrudService } from './crud.service';

@Injectable({
  providedIn: 'root',
})
export class AssessmentApplicationService extends CrudService<any> {
  private readonly _baseUrl = `${portalConst.api.assessments}/v1/`;
  constructor(api: ApiService, private _store: Store<AppState>) {
    super(api, `${portalConst.api.assessments}/v1/aplicacao-avaliacao`);
  }

  public updateStatus(uuid: string, status) {
    return this.api.patch<Resp<any>>(`${this._baseUrl}aplicacao-avaliacao/${uuid}/update-status`, { status });
  }

  public deleteClasses(AplicacaoUuid: string, data: any): Observable<Resp<any>> {
    return this.api.delete(`${this._baseUrl}aplicacao-avaliacao/${AplicacaoUuid}/pre-aplicacao-config`, data);
  }

  public getGeneralData(uuid: string) {
    return this.api.get(`${this._baseUrl}relatorio-dados-avaliacao/${uuid}`);
  }

  public getStudent(applicationUuid: string, data: any) {
    return this.api.get(`${this._baseUrl}relatorio-por-aluno/${applicationUuid}`, data);
  }

  public getReportEntity(applicationUuid: string, data: any): Observable<Resp<any>> {
    return this.api.get(`${this._baseUrl}relatorio-por-entidade/${applicationUuid}`, data);
  }

  public getProficiency(applicationUuid: string, data: any): Observable<Resp<any>> {
    return this.api.get(`${this._baseUrl}relatorio-proficiencia/${applicationUuid}`, data);
  }

  public getQuestions(uuid: string) {
    return this.api.get(`${this._baseUrl}por-questao/${uuid}`);
  }

  public getResult(applicationUuid: string, userId: number) {
    return this.api.get(`${this._baseUrl}gabarito-avaliacao/${applicationUuid}/${userId}`);
  }

  public annulQuestion(applicationUuid: string, questionUuid: string, data: any) {
    return this.api.post(`${this._baseUrl}aplicacao/${applicationUuid}/annul-question/${questionUuid}`, data);
  }

  public preview(aplicacaoUuid: string): Observable<Resp<any>> {
    return this.api.get(`${this._baseUrl}aplicacao/${aplicacaoUuid}/preview`);
  }

  public generalData(aplicacaoUuid: string): Observable<Resp<any>> {
    return this.api.get(`${this._baseUrl}aplicacao/${aplicacaoUuid}/general-data`);
  }

  public correction(tentativaQuestaoUuid: string, data: any): Observable<Resp<any>> {
    return this.api.post(`${this._baseUrl}aplicacao/tentativa/correction/${tentativaQuestaoUuid}`, data);
  }

  public noStudentslinked(tentativaUuid: string, userId: number): Observable<Resp<any>> {
    return this.api.put(`${this._baseUrl}aplicacao/tentativa/${tentativaUuid}/set-user`, { user_id: userId });
  }

  public printedCorrection(tentativaUuid: string, questionUuid: string, data: any): Observable<Resp<any>> {
    return this.api.post(`${this._baseUrl}attempt-audit/${tentativaUuid}/question/${questionUuid}`, data);
  }

  public printedCorrectionAllblank(tentativaUuid: string, data: any): Observable<Resp<any>> {
    return this.api.post(`${this._baseUrl}attempt-audit/${tentativaUuid}/all-blank`, data);
  }

  public indexReleaseStudents(aplicacaoUuid: string): Observable<Resp<any>> {
    return this.api.get(`${this._baseUrl}aplicacao/${aplicacaoUuid}/release-students`);
  }

  public storeReleaseStudents(aplicacaoUuid: string, data: any): Observable<Resp<any>> {
    return this.api.post(`${this._baseUrl}aplicacao/${aplicacaoUuid}/release-students`, data);
  }

  public storePreApplicationConfig(aplicacaoUuid: string, data: any): Observable<Resp<any>> {
    return this.api.put(`${this._baseUrl}aplicacao-avaliacao/${aplicacaoUuid}/pre-aplicacao-config`, data);
  }

  public questionUsage(questionUuid: string): Observable<Resp<any>> {
    return this.api.get(`${this._baseUrl}uso-questao/${questionUuid}`);
  }

  public assessmentEclass(data: any): Observable<any> {
    return this.api.get(`${this._baseUrl}eclass/aplicacoes`, data);
  }

  public evaluationMap(uuid: string): Observable<any> {
    return this.api.get(`${this._baseUrl}mapa-avaliacao/${uuid}`);
  }

  public abilitySubject(uuid: string, data: any): Observable<any> {
    return this.api.get(`${this._baseUrl}relatorio-habilidade-disciplina/${uuid}`, data);
  }

  public importTri(applicationUuid: string, data): Observable<Resp<any>> {
    return this.api.post(`${this._baseUrl}import/${applicationUuid}/tri`, data);
  }

  public importProficiencia(applicationUuid: string, data): Observable<Resp<any>> {
    return this.api.post(`${this._baseUrl}import/${applicationUuid}/proficiencia`, data);
  }

  public uploadResponseCard(applicationUuid: string, data: any): Observable<Resp<any>> {
    return this.api.post(`${this._baseUrl}aplicacao/${applicationUuid}/response-card/upload`, data);
  }

  public historyUploadResponseCard(applicationUuid: string): Observable<Resp<any>> {
    return this.api.get(`${this._baseUrl}aplicacao/${applicationUuid}/response-card`);
  }

  public removeResponseCard(applicationUuid: string, responseCardId: string): Observable<Resp<any>> {
    return this.api.delete(`${this._baseUrl}aplicacao/${applicationUuid}/response-card/${responseCardId}/remove`);
  }

  public sendLeafTemplate(applicationUuid: string, data) {
    this._store
      .select(AppSelectors.token)
      .pipe(take(1))
      .subscribe((token) => {
        const params = `token=${token}&` + this.api.resolveParams(data);
        window.open(`${portalConst.web.assessments}/leaf-template/${applicationUuid}?${params}`).focus();
      });
  }

  public questions(applicationUuid: string, modelo: number): Observable<Resp<any>> {
    return this.api.get(`${this._baseUrl}aplicacao/${applicationUuid}/questoes/modelo/${modelo}`);
  }

  public getResponseCardText(applicationUuid: string): Observable<Resp<any>> {
    return this.api.get(`${this._baseUrl}aplicacao/${applicationUuid}/response-card/text`);
  }

  public exportGabaritoStudents(applicationUuid: string, data: any) {
    this._store
      .select(AppSelectors.token)
      .pipe(take(1))
      .subscribe((token) => {
        const params = `token=${token}&` + this.api.resolveParams(data);
        window.open(`${this._baseUrl}export/${applicationUuid}/gabarito-aluno?${params}`).focus();
      });
  }

  public getGradesByDiscipline(applicationUuid: string, entity_id?: string): Observable<Resp<any>> {
    let url = `${this._baseUrl}relatorio-notas-por-disciplina/${applicationUuid}`;
    if (entity_id) {
      url += `?entity_id=${entity_id}`;
    }

    return this.api.get(url);
  }

  public reportExport(applicationUuid: string, data: any, url: string) {
    this._store
      .select(AppSelectors.token)
      .pipe(take(1))
      .subscribe((token) => {
        const params = `token=${token}&` + this.api.resolveParams(data);
        window.open(`${this._baseUrl}export/${applicationUuid}/${url}?${params}`).focus();
      });
  }
}
