import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { ConnectionPositionPair } from '@angular/cdk/overlay';
import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Inject, OnDestroy } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { portalConst } from '@app/config';
import { AssessmentApplicationService, NotificationService } from '@app/core';
import {
  ApplicationStatusExecutionEnum,
  AssessmentAnnulTypeEnum,
  AssessmentQuestionType,
  AssessmentStatusAttemptEnum,
  AssessmentWeightType,
} from '@app/core/models';
import { SCORE_CONFIG } from '@app/modules/eclass/components/modal-daily/base';
import { notNull, safeEmpty, selectedEntityActive } from '@app/shared';
import { KeyboardShortcutsService } from '@app/shared/modules/keyboard-shortcuts/keyboard-shortcuts.service';
import { Popover, Strategy } from '@app/shared/modules/template/components/popover';
import { REDUCED } from '@app/shared/modules/text-editor/config';
import { AppSelectors } from '@app/store';
import { AppState } from '@app/store/app.state';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { first, isEmpty, isNil } from 'lodash';
import { BehaviorSubject, filter, finalize, map, switchMap, take, tap } from 'rxjs';
import { PopoverAnnulQuestionComponent } from '../modal-evaluation-report/components/popover-annul-question/popover-annul-question.component';
import { PopoverShortcutsComponent } from './popover-shortcuts/popover-shortcuts.component';

enum TypeForm {
  Avaliacao = 'avaliacao',
  Aplicacao = 'aplicacao',
}
@Component({
  selector: 'app-modal-answers',
  templateUrl: './modal-answers.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalAnswersComponent implements AfterViewInit, OnDestroy {
  public readonly badgeStyleWarning = {
    background: 'bg-base-status-warning-500 text-white !cursor-default',
    active: 'bg-base-status-warning-500 text-white',
  };

  public readonly badgeStyleInfo = {
    background: 'bg-base-status-info-500 text-base-status-info-500 !cursor-default',
    active: 'bg-base-status-info-500 text-white',
  };

  public readonly badgeStyleRed = {
    background: 'bg-red-100 text-red-500 !cursor-default',
    active: 'bg-red-500 text-white',
  };

  public readonly badgeStyleSociodemografica = {
    background: 'bg-blue-100 text-white hover:bg-blue-300 hover:text-blue-500',
    active: 'bg-blue-500 text-blue',
  };

  public scoreConfig = SCORE_CONFIG;

  public readonly strategy = Strategy;
  public readonly applicationStatusExecutionEnum = ApplicationStatusExecutionEnum;
  public readonly assessmentStatusAttemptEnum = AssessmentStatusAttemptEnum;
  public readonly assessmentQuestionType = AssessmentQuestionType;
  public readonly assessmentWeightType = AssessmentWeightType;
  public readonly editorUrl = `${portalConst.api.assessments}/v1/upload/ckeditor-image?path=avaliacoes`;
  public readonly editorConfigToolbar = REDUCED;
  public data$ = new BehaviorSubject<any>(undefined);
  public changeColor: string;
  public selectedUser$ = new BehaviorSubject<any>(undefined);
  public dataStudents$ = new BehaviorSubject<any[]>([]);
  public assessment$ = new BehaviorSubject<any>(undefined);
  public selectedQuestion$ = new BehaviorSubject<any>(undefined);
  public form: FormGroup;
  public pendingCtrl = new FormControl(false);
  public loading$ = new BehaviorSubject<boolean>(false);
  public submitted$ = new BehaviorSubject<boolean>(false);
  public inputComentarioFocus$ = new BehaviorSubject<boolean>(false);
  private _saveAnular: boolean = false;

  constructor(
    @Inject(DIALOG_DATA) public data: any,
    private _assessmentApplicationService: AssessmentApplicationService,
    private _notificationService: NotificationService,
    private _translateService: TranslateService,
    private _keyboard: KeyboardShortcutsService,
    private _formBuilder: FormBuilder,
    private _popover: Popover,
    private _dialogRef: DialogRef,
    private _store: Store<AppState>,
  ) {
    this.data$.next(this.data);
    this._store
      .select(AppSelectors.ActiveUser)
      .pipe(
        take(1),
        notNull(),
        tap(() => this.loading$.next(true)),
        map((activeUser) => first(selectedEntityActive(activeUser))),
        switchMap((entityActive) =>
          this._assessmentApplicationService
            .getStudent(data.applicationUuid, { entity_id: entityActive.id, full: 1 })
            .pipe(take(1), safeEmpty()),
        ),
        finalize(() => this.loading$.next(false)),
      )
      .subscribe((data: any) => {
        this.dataStudents$.next(data.alunos);
        this.loadData(this.data.applicationUuid);
      });

    this.pendingCtrl.valueChanges.subscribe((pending) => {
      this.refreshUsersList(pending);
      this.onSetSelectedUser(this.dataStudents$.value[0], 0);
      this.findUserByIndex(0);
    });
  }

  public scales = [
    { value: 0, shortcut: 'q' },
    { value: 0.25, shortcut: 'w' },
    { value: 0.5, shortcut: 'e' },
    { value: 0.75, shortcut: 'r' },
    { value: 1, shortcut: 't' },
  ];

  public get evaluationByWeight(): boolean {
    return this.assessment$.value?.peso_tipo === AssessmentWeightType.Peso;
  }

  public isEmpty(items: any): boolean {
    return isEmpty(items);
  }

  public ngAfterViewInit(): void {
    this.initShortcuts();
  }

  public initShortcuts() {
    const shortcuts = [
      { key: 'shift + left', command: () => this.onPreviousUser(), preventDefault: true },
      { key: 'shift + right', command: () => this.onNextUser(), preventDefault: true },
      { key: 'shift + up', command: () => this.previousQuestion(), preventDefault: true },
      { key: 'shift + down', command: () => this.nextQuestion(), preventDefault: true },
      { key: 'shift + n', command: () => this.focusInputNota(), preventDefault: true },
      { key: 'shift + c', command: () => this.inputComentarioFocus$.next(true), preventDefault: true },
      { key: 'shift + enter', command: () => this.clickBtnSalvar(), preventDefault: true },
      { key: 'esc', command: () => this.blurInputNota(), preventDefault: true },
    ];

    this.scales.forEach((s) => {
      shortcuts.push({
        key: 'shift + ' + s.shortcut,
        command: () => this.clickBtnScale(s.shortcut),
        preventDefault: true,
      });
    });

    this._keyboard.add(shortcuts);
  }

  public ngOnDestroy(): void {
    this.data$.complete();
    this.selectedUser$.complete();
    this.dataStudents$.complete();
    this.assessment$.complete();
    this.selectedQuestion$.complete();
    this._dialogRef.close(this._saveAnular);
  }

  public onSetSelectedUser(user: any, index: number) {
    const data = !!user ? { data: user, index } : undefined;
    this.selectedUser$.next(data);
    if (!!this.selectedQuestion$.value) {
      this.onSelectQuestion(this.selectedQuestion$.value.data, this.selectedQuestion$.value.index);
    }
  }

  public onOpenPopover(elementRef: ElementRef, positionStrategy: ConnectionPositionPair[] = [], question: any): void {
    this._popover
      .open(PopoverAnnulQuestionComponent, {
        positionStrategy,
        elementRef,
        data: {
          aplicacao_uuid: this.data.applicationUuid,
          question,
          tentativa_uuid: this.selectedUser$.value.data.tentativa_uuid,
          type: AssessmentAnnulTypeEnum.Answer,
        },
      })
      .closed.pipe(notNull())
      .subscribe((res: any) => {
        this.updateQuestionStudentAnula(res);
        this._saveAnular = true;
      });
  }

  public onSelectUserById(userId: number) {
    let index = this.dataStudents$.value.findIndex((a) => a.usuario_id === userId);
    index = index < 0 ? 0 : index;
    const user = this.dataStudents$.value[index] || undefined;
    this.onSetSelectedUser(user, index);
    this.findUserByIndex(index);
  }

  public onSelectQuestion(questao: any, index: number = 0) {
    const answer = this.onGetAnswer(questao.uuid);
    this.selectedQuestion$.next({ data: questao, index, answer: answer.data });

    this.form = this._formBuilder.group({
      feedback: [undefined],
      nota: [undefined, [Validators.required, Validators.min(0), Validators.max(questao.peso)]],
    });

    this.scoreConfig.max = questao.peso;

    this.form.patchValue(answer.data);
  }

  public onNextUser() {
    const index = (this.selectedUser$.value.index + 1) % this.data$.value.dataStudent.length;
    this.findUserByIndex(index, true);
  }

  public onPreviousUser() {
    const index =
      (this.selectedUser$.value.index - 1 + this.data$.value.dataStudent.length) % this.data$.value.dataStudent.length;
    this.findUserByIndex(index, true);
  }

  public onGetAnswer(questaoUuid: string) {
    const answer = this.selectedUser$.value?.data.tentativa_questoes?.find((t) => t.questao?.uuid === questaoUuid);
    return { data: answer };
  }

  public onGetAlternativa(uuid: string) {
    const index = this.selectedQuestion$.value.data.alternativas.findIndex((a) => a.uuid === uuid);
    return { index, data: this.selectedQuestion$.value.data.alternativas[index] };
  }

  public onGetNota(answer: any) {
    return isNil(answer.data.nota) ? '-' : answer.data?.nota;
  }

  public toLetter(index: number): string {
    return String.fromCharCode(65 + index);
  }

  public isNil(note) {
    return isNil(note);
  }

  public onCalcNota(nota, scale) {
    const result = nota * scale;
    const notaResult = result.toFixed(2);
    return { nota: notaResult };
  }

  public onSetNota(nota: number) {
    this.form.controls.nota.setValue(nota);
  }

  public onGetFieldControlAvaliacao(field: string): AbstractControl {
    return this.form.get(TypeForm.Avaliacao).get(field);
  }

  public get textEditorToolbar(): any {
    return REDUCED;
  }

  public onSaveNota(tentativaQuestaoUuid: string) {
    this.form.markAllAsTouched();
    if (this.form.invalid) {
      return;
    }

    const lastCorrection = this.getLastCorrectionValue();
    this.submitted$.next(true);
    this._assessmentApplicationService
      .correction(tentativaQuestaoUuid, { ...this.form.value, last: lastCorrection })
      .pipe(
        safeEmpty(),
        filter((data) => !!data),
        finalize(() => this.submitted$.next(false)),
      )
      .subscribe((data) => {
        this.updateStudentData(tentativaQuestaoUuid, data);
        this.refreshUsersList(this.pendingCtrl.value);
        this.onSelectUserById(this.selectedUser$.value.data.usuario_id);
        this._notificationService.success(this._translateService.instant('geral.salvo-com-sucesso'));
        this._saveAnular = true;
      });
  }

  public onOpenPopoverShortcuts(elementRef: ElementRef) {
    this._popover.open(PopoverShortcutsComponent, {
      positionStrategy: [Strategy.BOTTOM_END],
      elementRef,
    });
  }

  public getButtonClass(selectedAlternative: any): object {
    const isSelected = this.selectedQuestion$.value?.answer?.alternativa?.uuid === selectedAlternative.uuid;
    const isCorrect = !!selectedAlternative.alternativa_correta || !!this.selectedQuestion$.value.data.sociodemografica;

    return {
      '!bg-base-mantis-500': isSelected && isCorrect,
      '!bg-base-status-danger': isSelected && (!isCorrect || !!this.selectedQuestion$.value.answer?.anulado),
      '!bg-base-status-warning': isSelected && !!this.selectedQuestion$.value.data?.anulado,
      'text-white !shadow-inner !shadow-black/40': isSelected,
    };
  }

  public getIconClass(selectedAlternative: any): object {
    const isSelected = this.selectedQuestion$.value?.answer?.alternativa?.uuid === selectedAlternative.uuid;
    const isCorrect = !!selectedAlternative.alternativa_correta;
    const isAnulado = this.selectedQuestion$.value?.data?.anulado || this.selectedQuestion$.value?.answer?.anulado;

    return {
      '!text-base-mantis-500': !isSelected || !isCorrect || isAnulado,
      '!text-white': isSelected && isCorrect && isAnulado,
    };
  }

  public getSpanClass(selectedAlternative: any): object {
    const isSelected = this.selectedQuestion$.value?.answer?.alternativa?.uuid === selectedAlternative.uuid;
    const isCorrect = !!selectedAlternative.alternativa_correta || !!this.selectedQuestion$.value.data.sociodemografica;

    return {
      '!border-base-mantis-500 text-base-mantis-500':
        (isSelected && isCorrect) || selectedAlternative.alternativa_correta,
      '!border-base-status-danger text-base-status-danger': isSelected && !isCorrect,
    };
  }

  public onGetScoreData(question: any, answer: any) {
    let score = isNil(answer?.nota) ? undefined : answer.nota;
    if (!!question.anulado) {
      score = question.peso;
    } else if (!!answer?.anulado) {
      score = 0;
    }

    let correct = answer?.nota === question.peso;
    if (question.tipo === this.assessmentQuestionType.Objetiva) {
      correct = answer?.alternativa?.uuid === question.alternativas.find((a) => !!a.alternativa_correta)?.uuid;
    }

    return {
      correct,
      score,
    };
  }

  private updateStudentData(tentativaQuestaoUuid: string, data: any): void {
    const selectedUserId = this.selectedUser$.value.data.usuario_id;
    const updatedStudents = this.data$.value.dataStudent.map((a) => {
      if (a.usuario_id === selectedUserId) {
        return {
          ...a,
          status: data.status,
          nota_calculada: data.nota_calculada,
          tentativa_questoes: a.tentativa_questoes.map((t) =>
            t.uuid === tentativaQuestaoUuid
              ? {
                  ...t,
                  nota: parseFloat(this.form.value.nota),
                  feedback: this.form.value.feedback,
                }
              : t,
          ),
        };
      }
      return a;
    });
    this.data$.next({ ...this.data$.value, dataStudent: updatedStudents });
  }

  private getLastCorrectionValue(): number {
    const selectedUserId = this.selectedUser$.value.data.usuario_id;
    const student = this.data$.value.dataStudent.find((a) => a.usuario_id === selectedUserId);
    const numTentativasSemNota = student?.tentativa_questoes?.filter((t) => isNil(t.nota))?.length || 0;
    return numTentativasSemNota < 2 ? 1 : 0;
  }

  public updateQuestionStudentAnula(data: any): void {
    const selectedUser = {
      ...this.selectedUser$.value,
      data: {
        ...this.selectedUser$.value.data,
        status: data?.status,
        nota_calculada: data.nota_calculada,
        tentativa_questoes: data.tentativa_questoes,
      },
    };
    const updatedStudents = this.data$.value.dataStudent.map((a) =>
      a.usuario_id === selectedUser.data.usuario_id ? selectedUser.data : a,
    );

    this.data$.next({ ...this.data$.value, dataStudent: updatedStudents });
    this.dataStudents$.next(updatedStudents);
    this.selectedUser$.next(selectedUser);
  }

  private loadData(applicationUuid: string) {
    this.onSelectUserById(this.data$.value.userId || first(this.dataStudents$.value)?.usuario_id);
    this.loading$.next(true);
    this._assessmentApplicationService
      .show(applicationUuid)
      .pipe(
        map((res) => (!!res?.ret ? res.data : undefined)),
        finalize(() => this.loading$.next(false)),
      )
      .subscribe(({ avaliacao }) => {
        this.assessment$.next(avaliacao);
        this.onSelectQuestion(avaliacao.questoes[0], 0);
      });
  }

  private findUserByIndex(index: number, setSelectedUser = false) {
    const user = this.dataStudents$.value[index];
    if (user) {
      setTimeout(() => document.getElementById('user_' + user.usuario_id)?.scrollIntoView({ behavior: 'smooth' }), 100);
    }

    if (setSelectedUser) {
      this.onSetSelectedUser(user, index);
    }
  }

  private refreshUsersList(pending: boolean) {
    const users = !!pending
      ? this.data$.value.dataStudent.filter((a) => a.status === AssessmentStatusAttemptEnum.Wait)
      : this.data$.value?.dataStudent;
    this.dataStudents$.next(users);
  }

  private previousQuestion() {
    let index = this.selectedQuestion$.value.index - 1;
    if (index < 0) {
      index = this.assessment$.value.questoes.length - 1;
    }
    this.findQuestionByIndex(index);
  }

  private nextQuestion() {
    let index = this.selectedQuestion$.value.index + 1;
    if (index > this.assessment$.value.questoes.length - 1) {
      index = 0;
    }
    this.findQuestionByIndex(index);
  }

  private findQuestionByIndex(index: number) {
    const question = this.assessment$.value.questoes[index];

    if (question) {
      setTimeout(
        () => document.getElementById('question_' + question.uuid).scrollIntoView({ behavior: 'smooth' }),
        200,
      );
    }

    this.onSelectQuestion(question, index);
  }

  private focusInputNota() {
    const inputNota = document.getElementById('inputNota') as HTMLInputElement;
    if (inputNota) {
      inputNota.focus();
      if (inputNota.tagName === 'INPUT') {
        inputNota.select();
      }
    }
  }

  private clickBtnSalvar() {
    const btn = document.getElementById('btnSalvar') as HTMLButtonElement;
    if (btn) {
      btn.click();
    }
  }

  private blurInputNota() {
    const inputNota = document.getElementById('inputNota') as HTMLInputElement;
    if (inputNota) {
      inputNota.blur();
    }
  }

  private clickBtnScale(shortcut: string) {
    const btn = document.getElementById('btn_' + shortcut) as HTMLButtonElement;
    if (btn) {
      btn.click();
    }
  }
}
