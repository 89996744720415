import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'extensionIcon',
})
export class ExtensionIconPipe implements PipeTransform {
  public transform(extension: string): string {
    return extension === 'pasta' ? 'assets/icons/icon-folder.svg' : 'assets/icons/icon-download.svg';
  }
}
