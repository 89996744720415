import { Injectable } from '@angular/core';
import { portalConst } from '@app/config';

import { EnrollmentData } from '@app/modules/finance/utils/enrollment';
import { Checker, notNull, safeEmptyList } from '@app/shared/utils';
import { AppSelectors } from '@app/store';
import { AppState } from '@app/store/app.state';
import { Store } from '@ngrx/store';
import { endOfDay, isWithinInterval, parseISO, startOfDay } from 'date-fns';
import { head, isNil } from 'lodash';
import { BehaviorSubject, map, MonoTypeOperatorFunction } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { PaisEnum } from '../models';
import { DataEnrollmentService } from '../services';
import { PaymentsService } from '../services/payments.service';
import { MenuItem, MenuRef } from './menu';

@Injectable({ providedIn: 'root' })
export class FinanceMenu implements MenuRef {
  public showAutomaticPayment$ = new BehaviorSubject(false);
  public identifier = 'finance';

  public readonly menu: MenuItem[] = [
    () => ({
      title: 'menu.financeiro',
      url: '/financeiro',
      icon: 'icon-money_search',
      permission: [1, 7],
      show: this.store.select(AppSelectors.ActiveUser).pipe(
        notNull(),
        map((activeUser) => this.enableFinance(activeUser)),
      ),
    }),
    () => ({
      title: 'menu.financeiro',
      url: '/financeiro',
      icon: 'icon-money_search',
      permission: [1, 7],
      show: this.store.select(AppSelectors.ActiveUser).pipe(
        notNull(),
        map((activeUser) => this.enableFinance7edu(activeUser)),
      ),
    }),
    () => ({
      title: 'menu.financeiro',
      icon: 'icon-money_search',
      redirect: 'https://7edu-pe.educadventista.org/dashboard',
      show: this.store.select(AppSelectors.ActiveUser).pipe(
        notNull(),
        map((activeUser) => this.enableFinanceForCountry(activeUser, PaisEnum.Peru)),
      ),
    }),
    () => ({
      title: 'menu.financeiro',
      icon: 'icon-money_search',
      redirect: 'https://7edu-cl.educadventista.org/',
      show: this.store.select(AppSelectors.ActiveUser).pipe(
        notNull(),
        map((activeUser) => this.enableFinanceForCountry(activeUser, PaisEnum.Chile)),
      ),
    }),
    () => ({
      title: 'menu.lirmi',
      icon: 'icon-finance-chile',
      redirect: 'https://inicio.lirmi.com/home',
      show: this.store.select(AppSelectors.ActiveUser).pipe(
        notNull(),
        map((activeUser) => activeUser?.pais_id === PaisEnum.Chile),
      ),
    }),
    () => ({
      title: 'menu.financeiro',
      icon: 'icon-money_search',
      redirect: 'https://eschool.educacionadventista.org.ar/inicio/login.php',
      show: this.store.select(AppSelectors.ActiveUser).pipe(
        notNull(),
        map((activeUser) => this.enableFinanceForCountry(activeUser, PaisEnum.Argentina)),
      ),
    }),
  ];

  public readonly submenu: MenuItem[] = [
    () => ({
      title: 'breadcrumb.payment-history.titulo',
      url: '/financeiro/historico-de-pagamento',
      icon: 'icon-menu-payment-history',
      group: 'menu.financeiro',
      show: this.store.select(AppSelectors.ActiveUser).pipe(
        notNull(),
        map((activeUser) => activeUser?.secretaria_sistema_id !== 3),
      ),
    }),
    () => ({
      title: 'breadcrumb.invoice.titulo',
      url: '/financeiro/nota-fiscal',
      icon: 'icon-menu-invoice',
      group: 'menu.financeiro',
      show: this.store.select(AppSelectors.ActiveUser).pipe(
        notNull(),
        map((activeUser) => activeUser?.secretaria_sistema_id !== 3),
      ),
    }),
    () => ({
      title: 'breadcrumb.automatic-payment.titulo',
      url: '/financeiro/pagamento-automatico',
      icon: 'icon-menu-automatic-payment',
      group: 'menu.financeiro',
      show: this.showAutomaticPayment$,
    }),
    () => ({
      title: 'breadcrumb.enrollment.titulo',
      url: '/financeiro/matricula',
      icon: 'icon-menu-enrollment',
      group: 'menu.financeiro',
      show: this.dataEnrollmentService.enrollment$.pipe(map((enrollment) => this.allow(enrollment, true))),
    }),
    () => ({
      title: 'breadcrumb.reenrollment.titulo',
      url: '/financeiro/rematricula',
      icon: 'icon-menu-enrollment',
      group: 'menu.financeiro',
      show: this.dataEnrollmentService.enrollment$.pipe(map((enrollment) => this.allow(enrollment, false))),
    }),
    () => ({
      title: 'breadcrumb.payments.titulo',
      redirect: portalConst.web.moduleFinancial7Edu,
      icon: 'icon-menu-payment-history',
      group: 'menu.financeiro',
      show: this.store.select(AppSelectors.ActiveUser).pipe(
        notNull(),
        map((activeUser) => this.enableFinance7edu(activeUser)),
      ),
    }),
    () => ({
      title: 'breadcrumb.enrollment-reenrollment.titulo',
      redirect: portalConst.web.moduleEnrollment7Edu,
      icon: 'icon-menu-enrollment',
      group: 'menu.financeiro',
      show: this.store.select(AppSelectors.ActiveUser).pipe(
        notNull(),
        map((activeUser) => this.enableFinance7edu(activeUser)),
      ),
    }),
  ];

  constructor(
    private store: Store<AppState>,
    private dataEnrollmentService: DataEnrollmentService,
    private paymentService: PaymentsService,
  ) {
    this.loadListeners();
  }

  private loadListeners() {
    this.listenersEnrollment();
    this.listenersPayment();
  }

  private listenersEnrollment() {
    this.store
      .select(AppSelectors.appFeature)
      .pipe(
        filter((store) => !!store.usuario_ativo),
        filter(({ usuario_ativo }) => this.enableFinance(usuario_ativo)),
      )
      .subscribe((store) => this.dataEnrollmentService.start(store));
  }

  private listenersPayment() {
    this.store
      .select(AppSelectors.ActiveUser)
      .pipe(
        notNull(),
        filter((activeUser) => this.enableFinance(activeUser)),
        map(({ cod_escola, entidade }) => ({ cod_escola, entidade })),
        switchMap((data) => this.paymentService.allowsCard(data)),
        safeEmptyList(),
        map(head),
        map(({ permite_recorrencia_cartao }) => !!permite_recorrencia_cartao),
      )
      .subscribe((response) => this.showAutomaticPayment$.next(response));
  }

  private allow = (allow: EnrollmentData, isEnrollment: boolean): boolean =>
    this.betweenPeriod(allow) && this.enablePeriod(allow) && allow.isEnrollment === isEnrollment;

  private enableFinance(activeUser: any): boolean {
    const is7Edu = activeUser?.secretaria_sistema_id === 3;
    const isBrasil = activeUser?.pais_id === PaisEnum.Brasil;
    return !is7Edu && isBrasil && this.notRegular(activeUser);
  }

  private enableFinance7edu(activeUser: any): boolean {
    const is7Edu = activeUser?.secretaria_sistema_id === 3;
    const isBrasil = activeUser?.pais_id === PaisEnum.Brasil;
    return is7Edu && isBrasil;
  }

  private enableFinanceForCountry(activeUser: any, country: PaisEnum): boolean {
    const isCountry = activeUser?.pais_id === country;
    const isExternal = activeUser?.escola_externa === 1;

    let isResponsavel: boolean;
    let isTechnical: boolean;

    this.store.select(AppSelectors.DefaultFunction).subscribe((defaultFunction) => {
      isResponsavel = Checker.isResposible(defaultFunction);
      isTechnical = Checker.isTechnical(defaultFunction);
    });

    return isCountry && !isExternal && (isResponsavel || isTechnical);
  }

  private notRegular(activeUser: any): boolean {
    if (isNil(activeUser?.tipoResponsavel)) {
      return false;
    }
    return activeUser?.tipoResponsavel !== 'R';
  }

  private betweenPeriod(enrollment: EnrollmentData): boolean {
    const school = enrollment?.iatec?.school?.dadosEscola;
    if (!!school) {
      const start = startOfDay(parseISO(school.data_ini_matricula_online));
      const end = endOfDay(parseISO(school.data_fim_matricula_online));
      if (isWithinInterval(new Date(), { start, end })) {
        return !!school.usa_aceite;
      }
    }
    return false;
  }

  private enablePeriod(enrollment: EnrollmentData): boolean {
    return enrollment?.iatec?.data?.dataRematricula?.status !== 7;
  }
}
