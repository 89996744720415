import { NgModule } from '@angular/core';
import { SharedModule } from '@app/shared';
import { ModalNotificationComponent } from './components/modal-notification/modal-notification.component';
import { PopoverNotificationComponent } from './components/popover-notification/popover-notification.component';
import { ModalNotificationService } from './services/modal-notification.service';
import { RouterModule } from '@angular/router';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ItemNotificationComponent } from './components/item-notification/item-notification.component';

@NgModule({
  declarations: [ModalNotificationComponent, PopoverNotificationComponent, ItemNotificationComponent],
  imports: [SharedModule, RouterModule, InfiniteScrollModule],
  providers: [ModalNotificationService],
})
export class NotificationModule {}
