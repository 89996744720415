import { Injectable } from '@angular/core';
import { ApiService, Resp } from './api.service';
import { portalConst } from '@app/config';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EclassScaleService {
  private _baseUrl = `${portalConst.api.usuario}/v1/hub-educacional`;
  constructor(private api: ApiService) {}

  public index(entityId: number): Observable<Resp<any[]>> {
    return this.api.get<any[]>(`${this._baseUrl}/escalas/${entityId}`);
  }
}
