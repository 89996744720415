import { NgModule } from '@angular/core';
import { DocumentPipe } from './document.pipe';
import { FilterEmptyPipe } from './filter-empty.pipe';
import { FilterPipe } from './filter.pipe';
import { FirstPipe } from './first.pipe';
import { IconPipe } from './icon.pipe';
import { IsEmptyPipe } from './is-empty.pipe';
import { NoEmptyPipe } from './no-empty.pipe';
import { OrderNamePipe } from './order-name.pipe';
import { PhonePipe } from './phone.pipe';
import { PreserveOrderPipe } from './preserve-order.pipe';
import { SafeDatePipe } from './safe-date.pipe';
import { SafePipe } from './safe.pipe';
import { TimeAgoPipe } from './time-ago.pipe';

const PIPES = [
  SafePipe,
  OrderNamePipe,
  FilterPipe,
  DocumentPipe,
  PhonePipe,
  IsEmptyPipe,
  NoEmptyPipe,
  FilterEmptyPipe,
  IconPipe,
  FirstPipe,
  PreserveOrderPipe,
  SafeDatePipe,
  TimeAgoPipe,
];
@NgModule({
  declarations: PIPES,
  exports: PIPES,
})
export class PipesModule {}
