import { DIALOG_DATA } from '@angular/cdk/dialog';
import { Component, DestroyRef, ElementRef, Inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AssessmentApplicationService } from '@app/core';
import { notNull, safeEmptyList, selectedEntityActive } from '@app/shared';
import { Popover, Strategy } from '@app/shared/modules/template/components/popover';
import { AppSelectors } from '@app/store';
import { AppState } from '@app/store/app.state';
import { Store } from '@ngrx/store';
import { first, flatMap, flattenDeep, groupBy, map as mapLodash, sumBy, uniq } from 'lodash';
import { BehaviorSubject, switchMap, tap } from 'rxjs';
import { PopoverScaleProficienciaComponent } from './popover-scale-proficiencia.component';

@Component({
  selector: 'app-modal-scale-proficiencia',
  templateUrl: './modal-scale-proficiencia.component.html',
})
export class ModalScaleProficienciaComponent {
  public subjectsGeneral$ = new BehaviorSubject<any>([]);
  public subjectsEntities$ = new BehaviorSubject<any>([]);
  public isNotEntity$ = new BehaviorSubject<boolean>(false);
  public entities: any[] = [];

  constructor(
    @Inject(DIALOG_DATA) public data: any,
    private popover: Popover,
    private _store: Store<AppState>,
    private _destroyRef: DestroyRef,
    private _assessmentApplicationService: AssessmentApplicationService,
  ) {
    this._store
      .select(AppSelectors.ActiveUser)
      .pipe(
        takeUntilDestroyed(this._destroyRef),
        notNull(),
        tap((activeUser) =>
          this.isNotEntity$.next(this.data.entity_id ? true : first(selectedEntityActive(activeUser))?.tipo !== 1),
        ),
        switchMap((activeUser) =>
          this._assessmentApplicationService
            .getProficiency(this.data.application.uuid, {
              entidade: this.data.entity_id
                ? { id: this.data.entity_id, tipo: 1 }
                : first(selectedEntityActive(activeUser)),
            })
            .pipe(safeEmptyList()),
        ),
      )
      .subscribe((data: any) => {
        this.entities = data.entities;
        const items = data.items.map((item: any) => ({ ...item, subject_scales: JSON.parse(item.subject_scales) }));

        this.setGeneralResult(items);
        this.setResulByEntity(items);
      });
  }

  public onOpenDescription(elementRef: ElementRef, description: string) {
    if (!description) {
      return;
    }

    this.popover.open(PopoverScaleProficienciaComponent, {
      positionStrategy: [Strategy.RIGHT],
      elementRef,
      data: description,
    });
  }

  public onGetEntity(entityId: number) {
    return this.entities.find((entity) => entity.id === entityId);
  }

  private setGeneralResult(items: any[]) {
    const subjectsGrouped = groupBy(flattenDeep(items.map((item: any) => item.subject_scales)), 'id');
    let subjects = mapLodash(subjectsGrouped, (items, id) => ({
      id: parseInt(id),
      descricao: (first(items) as any).descricao,
      count: sumBy(items, 'count'),
      media: sumBy(items, 'media') / items.length,
      adequacao: [],
      scales: [],
      nivel: 0,
      nivel_adequacao: 0,
    }));

    subjects = subjects.map((subject: any) => {
      const scalesGrouped = groupBy(
        flattenDeep(
          items.map((item: any) =>
            item.subject_scales.map((subjectScale: any) =>
              subjectScale.id === subject.id ? subjectScale.scales : undefined,
            ),
          ),
        ).filter((item) => !!item),
        'id',
      );

      subject.scales = mapLodash(scalesGrouped, (items, id) => ({
        id,
        name: (first(items) as any)?.name,
        min_score: (first(items) as any)?.min_score,
        max_score: (first(items) as any)?.max_score,
        count: sumBy(items, 'count'),
        percent: (sumBy(items, 'count') * 100) / subject.count,
      }));

      const adequacaoGrouped = groupBy(
        flattenDeep(
          items.map((item: any) =>
            item.subject_scales.map((subjectScale: any) =>
              subjectScale.id === subject.id ? subjectScale.adequacao : undefined,
            ),
          ),
        ).filter((item) => !!item),
        'id',
      );

      subject.adequacao = mapLodash(adequacaoGrouped, (items, id) => ({
        id,
        name: (first(items) as any)?.name,
        min_score: (first(items) as any)?.min_score,
        max_score: (first(items) as any)?.max_score,
        media:
          subject.media >= (first(items) as any)?.min_score && subject.media < (first(items) as any)?.max_score
            ? subject.media
            : 0,
      }));

      subject.nivel = subject.scales.reduce((prev, current) => (current.percent > prev.percent ? current : prev))?.name;
      subject.nivel_adequacao = subject.adequacao.reduce((prev, current) =>
        current.media > prev.media ? current : prev,
      )?.name;

      return subject;
    });

    this.subjectsGeneral$.next(subjects);
  }

  private setResulByEntity(items: any[]) {
    const subjectByEntity = Object.values(
      groupBy(
        items
          .map((item: any) => ({
            ...item,
            subject_scales: item.subject_scales.map((subjectScale) => ({
              ...subjectScale,
              scales: subjectScale.scales.map((scale) => ({
                ...scale,
                count: (scale.count * 100) / subjectScale.count,
              })),
            })),
          }))
          .flatMap((item) =>
            item.subject_scales.map((scale) => ({
              id: scale.id,
              descricao: scale.descricao,
              entity_id: item.entity_id,
              count: scale.count,
              adequacao: scale.adequacao,
              scales: scale.scales,
            })),
          ),
        'id',
      ),
    ).map((items, id) => {
      const mergedAdequacao = mapLodash(groupBy(flatMap(items, 'adequacao'), 'id'), (adequacoes, adequacaoId) => ({
        id: parseInt(adequacaoId, 10),
        name: adequacoes[0].name,
        min_score: adequacoes[0].min_score,
        max_score: adequacoes[0].max_score,
        media: adequacoes.map((adequacao) => adequacao.media),
      }));

      const mergedScales = mapLodash(groupBy(flatMap(items, 'scales'), 'id'), (scales, scaleId) => ({
        id: parseInt(scaleId, 10),
        name: scales[0].name,
        min_score: scales[0].min_score,
        max_score: scales[0].max_score,
        count: scales.map((scale) => scale.count),
      }));

      return {
        id,
        descricao: items[0].descricao,
        entities: uniq(items.map((item) => parseInt(item.entity_id, 10))),
        count: sumBy(items, 'count'),
        adequacao: mergedAdequacao,
        scales: mergedScales,
      };
    });

    this.subjectsEntities$.next(subjectByEntity);
  }
}
