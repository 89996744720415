import { Dialog } from '@angular/cdk/dialog';
import { Injectable } from '@angular/core';
import { Observable, Subject, take } from 'rxjs';
import { FormBase } from './base';
import { ModalNewAssessmentComponent } from './modal-new-assessment.component';
import { ModalNewAssessmentData } from './store/modal-new-assessment.actions';
import { ModalNewAssessmentState } from './store/modal-new-assessment.state';

@Injectable({
  providedIn: 'root',
})
export class ModalNewAssessmentService {
  private _components: FormBase[] = [];
  private _closed$ = new Subject<ModalNewAssessmentState>();

  constructor(private _dialog: Dialog) {}

  public open(params?: ModalNewAssessmentData) {
    this._dialog
      .open<ModalNewAssessmentState>(ModalNewAssessmentComponent, {
        disableClose: true,
        hasBackdrop: true,
        data: params,
      })
      .closed.pipe(take(1))
      .subscribe((state) => this._closed$.next(state));
  }

  public get closed$(): Observable<ModalNewAssessmentState> {
    return this._closed$.asObservable();
  }

  public registerComponent(component: FormBase) {
    this._components.push(component);
  }

  public isValid(): boolean {
    return this._components.map((component) => component.isValid()).every((valid) => !!valid);
  }

  public clearCheckers() {
    this._components = [];
  }
}
