import { Grade } from './grade.model';

export interface Group {
  id: number;
  entity_id: number;
  description: string;
  shift: string;
  count_students: number;
  grade: Grade;
}
