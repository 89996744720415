import { environment } from '@env/environment';

// urls portal //
export const portalConst = {
  ...environment,
  s3_path: `${environment.protocol}s.${environment.integration}/`,
  s3_path_snv: `${environment.protocol}snv.${environment.integration}/`,
  s3_path_temp: `${environment.protocol}snv.${environment.integration}/temp-upload-files/`,
  vimeo_url: 'https://player.vimeo.com/video/',
  vimeo_account: 'his_21_1',
  api: {
    ...environment.api,
    usuario: `${environment.protocol}api-usuario.${environment.resource}/api`,
    portal: `${environment.protocol}api-portal.${environment.resource}/api`,
    notification: `https://21g66u0eri.execute-api.sa-east-1.amazonaws.com/develop/notification`,
    drive: `https://qm6i60eno6.execute-api.sa-east-1.amazonaws.com/develop/drive`,
    upload: `https://ti5v892yac.execute-api.sa-east-1.amazonaws.com/develop/upload`,
    academic: `${environment.protocol}api-academic.${environment.resource}/api`,
    permissao: `${environment.protocol}api-permissao.${environment.resource}/api/v2`,
    vouchers: `${environment.protocol}api-vouchers.${environment.resource}/api/`,
    didaticos: `${environment.protocol}api-didaticos.${environment.resource}`,
    integrations: `${environment.protocol}api-integrations.${environment.resource}/api`,
    integracoes: `${environment.protocol}apis.${environment.integration}/integracao`,
    payment: `${environment.protocol}api-pagamentos.${environment.resource}/api`,
    school: `${environment.protocol}api-school.${environment.resource}/api`,
    assessments: `${environment.protocol}api-avaliacoes.${environment.resource}/api`,
    categories: `${environment.protocol}api-categories.${environment.resource}/api`,
    eclass: `${environment.protocol}api-eclass.${environment.resource}/api`,
    jogos: `${environment.protocol}api-jogos.${environment.resource}/api`,
    cpb_services: 'https://cpb-services.sdasystems.org/api',
    contents: `${environment.protocol}api-conteudos.${environment.resource}/api`,
    trilhas: `${environment.protocol}api-trilhas.${environment.resource}/api`,
    matricula: `${environment.protocol}api-matricula.${environment.resource}/api`,
    aulas: `${environment.protocol}api-aulas.${environment.resource}/api`,
    vimeo: `${environment.protocol}api-vimeo.${environment.resource}/api`,
  },
  web: {
    integration7EduStudent: `${environment.integration7Edu}studentportal/Login/Google`,
    integration7EduTeacher: `${environment.integration7Edu}teacherportal/Login/Google`,
    moduleFinancial7Edu: `${environment.integration7Edu}studentportal/#/StudentFinancialAccount`,
    moduleEnrollment7Edu: `${environment.integration7Edu}studentportal/OnlineEnrollmentSchool`,
    assessments: `${environment.protocol}api-avaliacoes.${environment.resource}`,
  },
  wss: {
    ...environment.wss,
  },
  aws: {
    s3: `${environment.protocol}s.${environment.integration}`,
    cpbedu: `https://s.cpbedu.me`,
    s3Snv: `${environment.protocol}snv.${environment.integration}`,
  },
};
