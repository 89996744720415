<app-modal-header>
  <div class="flex text-lg font-semibold" translate>gestor-avaliacoes.liberacao-alunos</div>
</app-modal-header>

<div app-modal-content class="h-[60vh] w-[100vw] !p-0 md:w-[80vw]">
  <app-sidenav-group>
    <ng-template app-sidenav-start [opened]="(dataStudents$ | async).length > 0" class="rounded-bl-lg">
      <div class="h-full bg-white p-6">
        <span class="text-md font-semibold" translate> gestor-avaliacoes.lista-alunos </span>
        <form [formGroup]="form">
          <div class="h-[20vh] overflow-y-auto">
            <ng-container *ngIf="dataStudents$ | async as students">
              <ng-container *ngIf="!!students.length; else emptyList">
                <ng-container *ngFor="let student of students">
                  <div class="flex">
                    <span>{{ student.nome }}</span>
                    <span
                      class="ml-3"
                      (click)="onRemove(student.usuario_id)"
                      *ngIf="(dataStudents$ | async).length > 1"
                      placement="top"
                      triggers="mouseenter:mouseleave"
                    >
                      <svg-icon class="mr-3 flex h-6 w-6 text-red-500" src="assets/icons/icon-close.svg"></svg-icon>
                    </span>
                  </div>
                </ng-container>
              </ng-container>
              <ng-template #emptyList>
                <div class="h-[200px]">
                  <app-jumbotron translate> gestor-avaliacoes.aplicacao.nenhum-aluno </app-jumbotron>
                </div>
              </ng-template>
            </ng-container>
          </div>
          <div class="mt-5 grid w-full">
            <app-form-field class="w-full px-2">
              <label app-label class="font-semibold" translate> gestor-avaliacoes.data-inicial</label>
              <app-input-datepicker
                formControlName="liberar_data_inicio"
                [weekend]="false"
                [showTime]="true"
                [minDate]="minDate"
                [maxDate]="maxDate"
                [defaultDateStartOfDay]="true"
                class="mb-5"
              />
            </app-form-field>
            <app-form-field class="w-full px-2">
              <label app-label class="font-semibold" translate> gestor-avaliacoes.data-fim </label>
              <app-input-datepicker
                formControlName="liberar_data_fim"
                [weekend]="false"
                [showTime]="true"
                [minDate]="form.value.liberar_data_inicio || minDate"
                [maxDate]="maxDate"
              />
            </app-form-field>
            <div class="mt-5 flex w-full">
              <button
                app-button
                class="w-full bg-theme-950 text-white hover:[&:not([disabled])]:bg-theme-950 hover:[&:not([disabled])]:text-white"
                type="button"
                [disabled]="submitted$ | async"
                (click)="onSave()"
              >
                <svg-icon class="mr-3 flex h-6 w-6" src="assets/icons/icon-user-clock-solid.svg"></svg-icon>
                <span translate> gestor-avaliacoes.liberar-alunos </span>
              </button>
            </div>
          </div>
        </form>
      </div>
    </ng-template>

    <ng-template app-sidenav-outer>
      <div class="h-full overflow-auto">
        <div class="p-5 text-dark">
          <span class="text-3xl font-semibold" translate> gestor-avaliacoes.historico </span>
        </div>
        <div class="p-5">
          <ng-container *ngIf="dataList$ | async as data; else empty">
            <table app-table class="text-center">
              <thead>
                <tr>
                  <th
                    app-table-cell
                    class="!bg-theme-500 p-2 !text-base uppercase !text-theme-50 first:rounded-l-lg first:pl-3 last:rounded-r-lg last:pr-3 [&:is(th)]:bg-theme-500 [&:is(th)]:text-theme-contrast [&:not(th)]:bg-white"
                    translate
                  >
                    gestor-avaliacoes.horarios
                  </th>
                  <th
                    app-table-cell
                    class="!bg-theme-500 p-2 !text-base uppercase !text-theme-50 first:rounded-l-lg first:pl-3 last:rounded-r-lg last:pr-3 [&:is(th)]:bg-theme-500 [&:is(th)]:text-theme-contrast [&:not(th)]:bg-white"
                    translate
                  >
                    gestor-avaliacoes.turma
                  </th>
                  <th
                    app-table-cell
                    class="!bg-theme-500 p-2 !text-base uppercase !text-theme-50 first:rounded-l-lg first:pl-3 last:rounded-r-lg last:pr-3 [&:is(th)]:bg-theme-500 [&:is(th)]:text-theme-contrast [&:not(th)]:bg-white"
                    translate
                  >
                    gestor-avaliacoes.alunos
                  </th>
                </tr>
              </thead>
              <tbody app-table-body>
                <tr *ngFor="let aplication of data">
                  <td app-table-cell>
                    {{ aplication.liberar_data_inicio | date : 'dd/MM/yyyy HH:mm' }} -
                    {{ aplication.liberar_data_fim | date : 'dd/MM/yyyy HH:mm' }}
                  </td>
                  <td app-table-cell>{{ aplication.user?.aluno_min?.aluno_turma_ano_individual?.turma?.descricao }}</td>
                  <td app-table-cell>{{ aplication.user.nome }}</td>
                </tr>
              </tbody>
            </table>
          </ng-container>
          <ng-template #empty>
            <app-card>
              <div app-card-content class="h-[200px]">
                <app-jumbotron translate> gestor-avaliacoes.nao-existe-liberacao-cadastrada </app-jumbotron>
              </div>
            </app-card>
          </ng-template>
        </div>
        <div *ngIf="false" class="h-[200px]">
          <app-jumbotron translate> gestor-avaliacoes.aplicacao.nenhum-aluno </app-jumbotron>
        </div>
      </div>
    </ng-template>
  </app-sidenav-group>
</div>
