import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

export interface FilterBadgeData {
  id: number;
  name: string;
  active?: boolean;
}
@Component({
  selector: 'app-filter-badge',
  templateUrl: './filter-badge.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterBadgeComponent implements OnInit {
  @Input() public badgeStyle: any;
  @Input() public badges: FilterBadgeData[] = [];
  @Output() public selected = new EventEmitter<number[]>();

  public get isAllActive(): boolean {
    return this.badges?.every((item) => item.active);
  }

  public ngOnInit() {
    this.notify();
  }

  public onToogle(item: FilterBadgeData) {
    this.badges.forEach((item) => (item.active = false));
    item.active = !item.active;
    this.notify();
  }

  public onToogleBatch() {
    const isActive = this.isAllActive;
    if (!isActive) {
      this.badges.forEach((item) => (item.active = !isActive));
      this.notify();
    }
  }

  private notify() {
    const result = this.badges.filter((item) => item.active).map((item) => item.id);
    this.selected.emit(result);
  }
}
