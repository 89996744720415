<app-modal-header>
  <div class="flex py-2">
    <svg-icon class="ml-6 w-7 text-modules-pedagogical-500" src="assets/icons/icon-cont-pedagogico.svg"></svg-icon>
    <p class="ml-6 text-lg font-semibold" translate>servicos.conteudos-pedagogicos</p>
  </div>
</app-modal-header>
<div app-modal-content class="h-[90vh] w-[90vw] !p-0 md:h-[calc(100vh_-_15rem)] xl:h-[50rem] xl:w-[80rem]">
  <app-sidenav-group class="h-full w-full rounded-b-lg bg-base-whisper-300">
    <ng-container *ngIf="categories$ | async as categories">
      <ng-template app-sidenav-start panelWidth="250px" buttonAnchor="bg-modules-pedagogical-300" [opened]="true">
        <div class="h-full rounded-bl-lg bg-base-whisper-300 p-4">
          <form [formGroup]="form" class="flex flex-col justify-between">
            <div class="mb-5 flex flex-col">
              <!-- Level -->
              <app-form-field>
                <ng-select
                  app-select
                  [placeholder]="'servicos.nivel' | translate"
                  formControlName="nivel_id"
                  [items]="categories.niveis"
                  [searchable]="false"
                  bindLabel="descricao"
                  bindValue="id"
                >
                </ng-select>
              </app-form-field>

              <!-- Year -->
              <app-form-field>
                <ng-select
                  app-select
                  [placeholder]="'servicos.ano' | translate"
                  formControlName="ano_id"
                  [items]="
                    categories.anos
                      | filterEmpty : 'descricao'
                      | filter : form.value.nivel_id : ['nivel_id'] : compareEquals
                  "
                  [searchable]="false"
                  bindLabel="descricao"
                  bindValue="id"
                >
                </ng-select>
              </app-form-field>

              <!-- Discipline -->
              <app-form-field>
                <ng-select
                  app-select
                  [placeholder]="'servicos.disciplina' | translate"
                  formControlName="disciplina_id"
                  [items]="
                    categories.disciplinas
                      | filterEmpty : 'descricao'
                      | filter : form.value.ano_id : ['ano_id'] : compareEquals
                  "
                  [searchable]="false"
                  bindLabel="descricao"
                  bindValue="id"
                >
                </ng-select>
              </app-form-field>

              <!-- Fascicle -->
              <app-form-field>
                <ng-select
                  app-select
                  [placeholder]="'servicos.fasciculo' | translate"
                  formControlName="fasciculo_id"
                  [searchable]="false"
                >
                  <ng-option
                    *ngFor="let item of filterBy(categories.fasciculos, form.value.ano_id, form.value.disciplina_id)"
                    [value]="item.id"
                  >
                    {{ item.descricao }}
                  </ng-option>
                </ng-select>
              </app-form-field>

              <!-- Category -->
              <app-form-field>
                <ng-select
                  app-select
                  [searchable]="false"
                  [placeholder]="'servicos.categoria' | translate"
                  formControlName="subfiltro"
                >
                  <ng-option *ngFor="let item of category" [value]="item.id">{{ item.label | translate }}</ng-option>
                </ng-select>
              </app-form-field>

              <div class="relative">
                <input
                  app-input
                  type="text"
                  class="rounded-xl pr-10"
                  [placeholder]="'servicos.buscar-por' | translate"
                  formControlName="busca"
                />
                <svg-icon
                  src="assets/icons/icon-search.svg"
                  class="absolute right-4 top-0 flex h-full w-4 text-gray-400"
                ></svg-icon>
              </div>
            </div>

            <button
              app-outline-button
              class="w-full border-modules-pedagogical-500 uppercase text-modules-pedagogical-500 hover:[&:not([disabled])]:bg-modules-pedagogical-500 hover:[&:not([disabled])]:text-modules-pedagogical-contrast"
              type="button"
            >
              {{ 'servicos.filtrar' | translate }}
            </button>
          </form>
        </div>
      </ng-template>
    </ng-container>

    <ng-template app-sidenav-outer>
      <div class="p-4" *ngIf="{ page: data$ | async, load: load$ | async } as data">
        <div class="mb-4 h-[67vh] overflow-y-auto py-5 md:h-[calc(100vh_-_25rem)] xl:h-[40rem]">
          <div class="flex h-full flex-wrap justify-center gap-10">
            <ng-container *ngIf="!data.load; else loadTemplate">
              <ng-container *ngIf="data.page?.data | isEmpty; else dataTemplate">
                <app-jumbotron translate>geral.filtro-vazio</app-jumbotron>
              </ng-container>
              <ng-template #dataTemplate>
                <app-card-cover-summary
                  class="!w-full rounded-2xl border-2 border-transparent transition duration-500 hover:box-border hover:border-modules-pedagogical-500 hover:bg-modules-pedagogical-50 md:!w-60"
                  *ngFor="let content of data.page?.data"
                  [content]="content"
                ></app-card-cover-summary>
              </ng-template>
            </ng-container>
            <ng-template #loadTemplate>
              <app-skeleton class="block !h-72 w-full md:w-60"></app-skeleton>
              <app-skeleton class="block !h-72 w-full md:w-60"></app-skeleton>
              <app-skeleton class="block !h-72 w-full md:w-60"></app-skeleton>
            </ng-template>
          </div>
        </div>
        <app-pagination *ngIf="!data.load" [page]="data.page" (pageChange)="onChangePage($event)"></app-pagination>
      </div>
    </ng-template>
  </app-sidenav-group>
</div>
