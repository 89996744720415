import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-jumbotron',
  templateUrl: './jumbotron.component.html',
  host: { class: 'flex h-full w-full flex-col items-center justify-center rounded-lg bg-base-gray-light-400' },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JumbotronComponent {
  @Input() public showIcon = true;
}
