<ng-container *ngIf="data$ | async as data">
  <ng-container *ngIf="!data.load; else loadTemplate">
    <table app-table *ngIf="!!data.storage?.length; else emptyTemplate">
      <thead>
        <tr>
          <th app-table-cell class="!bg-modules-drive-500 !text-left !text-white" translate>servicos.drive.nome</th>
          <th app-table-cell class="w-24 !bg-modules-drive-500"></th>
        </tr>
      </thead>
      <tbody app-table-body>
        <tr *ngFor="let element of data.storage" class="rounded-lg shadow">
          <td app-table-cell>{{ nameElement(element.caminho) }}</td>
          <td app-table-cell class="!text-center">
            <button
              app-icon-button
              type="button"
              class="!rounded-full"
              [ngClass]="color(element.extensao)"
              (click)="onView(element, data.external)"
            >
              <svg-icon class="flex h-7 w-7 text-white" [src]="element.extensao | extensionIcon"></svg-icon>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <ng-template #emptyTemplate>
      <app-jumbotron>{{ 'geral.nenhum-dado' | translate }}</app-jumbotron>
    </ng-template>
  </ng-container>
  <ng-template #loadTemplate>
    <app-skeleton></app-skeleton>
  </ng-template>
</ng-container>
