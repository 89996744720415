<ng-container *ngIf="data$ | async as questions; else elseTemplate">
  <div app-tab-content class="grid h-auto gap-4 border-t-2 border-t-base-mantis-500">
    <ng-container *ngIf="!isEmpty(questions); else jumbotron">
      <div class="flex justify-end py-4">
        <button app-flat-button type="button" (click)="onExport()" class="mr-3 border-theme bg-white">
          <svg-icon class="flex h-6 w-6 text-green-500" src="assets/icons/icon-download.svg"></svg-icon>
          <span class="ml-3 hidden md:flex" translate> gestor-avaliacoes.exportacao </span>
        </button>
      </div>
      <table app-table>
        <thead>
          <tr>
            <th app-table-cell translate class="min-w-[94px]">gestor-avaliacoes.questao</th>
            <th app-table-cell translate>gestor-avaliacoes.disciplina</th>
            <th app-table-cell translate>gestor-avaliacoes.habilidade-conteudo</th>
            <th *ngIf="isPAAEB" app-table-cell translate>gestor-avaliacoes.sociodemografico</th>
            <th app-table-cell translate>gestor-avaliacoes.dificuldade</th>
            <th app-table-cell translate>gestor-avaliacoes.gabarito</th>
            <th app-table-cell translate>gestor-avaliacoes.peso</th>
            <ng-container *ngIf="isDivision">
              <th app-table-cell translate>TRI A</th>
              <th app-table-cell translate>TRI B</th>
              <th app-table-cell translate>TRI C</th>
            </ng-container>
            <th app-table-cell translate>gestor-avaliacoes.anulada</th>
          </tr>
        </thead>
        <tbody app-table-body>
          <tr *ngFor="let data of questions; let index = index">
            <td app-table-cell class="group flex justify-center text-center">
              <div class="grid">
                <div class="flex">
                  <span class="text-md font-semibold">
                    {{ 'gestor-avaliacoes.questao' | translate }} {{ index + 1 }}
                  </span>
                </div>
                <div
                  class="flex cursor-pointer"
                  app-clipboard
                  app-tooltip
                  [tooltip]="'geral.copiar' | translate"
                  [clipboard]="data.questao.code ?? '-' | uppercase"
                >
                  <div class="relative flex text-xs font-normal">#{{ data.questao.code ?? '-' | uppercase }}</div>
                  <div class="flex items-center">
                    <svg-icon
                      class="absolute ml-1 hidden w-3 group-hover:flex"
                      src="assets/icons/icon-copy.svg"
                    ></svg-icon>
                  </div>
                </div>
              </div>
            </td>
            <td app-table-cell class="text-center">
              {{ !!data.questao.padrao_disciplina_id ? data.questao.padrao_disciplina.descricao : '-' }}
            </td>
            <td app-table-cell class="text-center">
              <div *ngIf="data.questao.categories.length > 0; else empty">
                <div *ngFor="let categoria of data.questao.categories">
                  <span *ngIf="!!categoria.parent"> {{ categoria.parent.name }} - </span>
                  {{ categoria.name }}
                </div>
              </div>
            </td>
            <td *ngIf="isPAAEB" app-table-cell class="text-center" [ngSwitch]="data.questao.sociodemografica">
              <span *ngSwitchCase="1">{{ 'geral.sim' | translate }}</span>
              <span *ngSwitchDefault>{{ 'geral.nao' | translate }}</span>
            </td>

            <td app-table-cell class="text-center" [ngSwitch]="data.questao.nivel_complexidade">
              <span *ngSwitchCase="levelContent.Baixo">{{ 'gestor-avaliacoes.facil' | translate }}</span>
              <span *ngSwitchCase="levelContent.Medio">{{ 'gestor-avaliacoes.medio' | translate }}</span>
              <span *ngSwitchCase="levelContent.Alto">{{ 'gestor-avaliacoes.dificil' | translate }}</span>
              <span *ngSwitchDefault>-</span>
            </td>

            <td app-table-cell class="text-center font-semibold">
              <div *ngIf="data.questao.alternativas.length; else empty">
                <div *ngFor="let gabarito of data.questao.alternativas">
                  {{ toLetter(gabarito.ordem - 1) ?? '-' }}
                </div>
              </div>
            </td>

            <td app-table-cell class="text-center font-semibold">
              <ng-container *ngIf="application$ | async as application">
                <ng-container *ngIf="application.avaliacao.peso_tipo === assessmentWeightType.Peso; else percent">
                  {{ data.peso ?? '-' }}
                </ng-container>
                <ng-template #percent>
                  {{ data.peso ? (data.peso | currency : '' : '' : '1.2-2') + '%' : '-' }}
                </ng-template>
              </ng-container>
            </td>

            <ng-container *ngIf="isDivision">
              <td app-table-cell class="text-center">
                <ng-container *ngIf="data.questao.tri_a; else empty">
                  {{ data.questao.tri_a | number : '1.3-3' }}
                </ng-container>
              </td>
              <td app-table-cell class="text-center">
                <ng-container *ngIf="data.questao.tri_b; else empty">
                  {{ data.questao.tri_b | number : '1.3-3' }}
                </ng-container>
              </td>
              <td app-table-cell class="text-center">
                <ng-container *ngIf="data.questao.tri_c; else empty">
                  {{ data.questao.tri_c | number : '1.3-3' }}
                </ng-container>
              </td>
            </ng-container>

            <td *ngIf="!data.anulado; else emptyTemplate" app-table-cell class="text-center font-semibold">
              <button
                *ngIf="onAnular(data.questao)"
                #popoverTop
                app-outline-button
                type="button"
                class="!border-none p-2 text-black hover:[&:not([disabled])]:bg-black hover:[&:not([disabled])]:text-theme-contrast"
                (click)="onOpenPopover(popoverTop, data)"
              >
                <svg-icon class="flex h-5" src="assets/icons/icon-three-dots.svg"></svg-icon>
              </button>
            </td>
            <ng-template #emptyTemplate>
              <td app-table-cell>
                <div class="flex w-full justify-center">
                  <ng-container *ngIf="onAnular(data.questao); else emptyAnular">
                    <app-badge-annuled-question
                      [motive]="data.anulado_motivo"
                      [isQuestionAnnulled]="!!data.anulado"
                      [applicationUuid]="(application$ | async).uuid"
                      [questionUuid]="data.questao.uuid"
                      (updateQuestion)="onUpdateQuestionAnula($event, data)"
                      *ngIf="data.anulado"
                    ></app-badge-annuled-question>
                  </ng-container>
                </div>
              </td>
            </ng-template>
            <ng-template #emptyAnular>
              <td app-table-cell>
                <div class="flex w-full justify-center">
                  <ng-container>
                    <app-badge-annuled-question
                      [motive]="data.anulado_motivo"
                      [isQuestionAnnulled]="!!data.anulado"
                      *ngIf="data.anulado"
                    ></app-badge-annuled-question>
                  </ng-container>
                </div>
              </td>
            </ng-template>
          </tr>
        </tbody>
      </table>
    </ng-container>
  </div>
</ng-container>
<ng-template #elseTemplate>
  <app-card class="mt-5">
    <div app-card-content class="h-[200px]">
      <app-skeleton *ngIf="loading$ | async" class="h-full w-full"></app-skeleton>
    </div>
  </app-card>
</ng-template>

<ng-template #jumbotron>
  <div class="grid h-auto gap-4">
    <app-card>
      <div app-card-content class="h-[200px]">
        <app-jumbotron translate> geral.filtro-vazio </app-jumbotron>
      </div>
    </app-card>
  </div>
</ng-template>
<ng-template #empty> - </ng-template>
