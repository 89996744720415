import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { notNull } from '@app/shared/utils/operators';
import {
  addMonths,
  endOfDay,
  getDay,
  getDaysInMonth,
  isAfter,
  isBefore,
  isEqual,
  isWeekend,
  parse,
  set,
  setDate,
  startOfDay,
  startOfMonth,
  subMonths,
} from 'date-fns';
import { isString, size, slice } from 'lodash';
import { BehaviorSubject, Observable, delay, take } from 'rxjs';
import { map } from 'rxjs/operators';
import { POPOVER_DATA, PopoverRef } from '../popover/index';

const HOURS = Array.from({ length: 24 }).map((_, i) => i);
const MINUTES = Array.from({ length: 60 }).map((_, i) => i);

export interface DatepickerData {
  showTime?: boolean;
  weekend?: boolean;
  minDate?: Date;
  maxDate?: Date;
  date?: Date;
  defaultDateStartOfDay?: boolean;
}
@Component({
  selector: 'app-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DatepickerComponent implements ControlValueAccessor, OnInit, AfterViewInit, OnDestroy {
  @Output() public selectDate = new EventEmitter<Date>();
  public readonly hours = HOURS;
  public readonly minutes = MINUTES;
  public selected: Date;
  public value$: Observable<Date>;
  public blankDays$: Observable<any[]>;
  public days$: Observable<any[]>;
  private _value$ = new BehaviorSubject(null);
  private _disabled: boolean;

  constructor(private popoverRef: PopoverRef, @Inject(POPOVER_DATA) public data: DatepickerData = {}) {}

  public get disabled(): boolean {
    return this._disabled;
  }

  @Input() public set date(date: any) {
    const defaultDate = !!this.data.defaultDateStartOfDay ? startOfDay(new Date()) : endOfDay(new Date());
    this.writeValue(date || defaultDate);
  }

  public ngOnInit() {
    this.date = this.data.date;
    this.value$ = this._value$.asObservable();
    this.days$ = this.value$.pipe(
      map((date) => Array.from({ length: getDaysInMonth(date) }).map((_, i) => startOfDay(setDate(date, i + 1)))),
      map((days) => (this.data?.weekend ? days : days.filter((day) => !isWeekend(day)))),
    );
    this.blankDays$ = this.value$.pipe(
      map((date) => getDay(startOfMonth(date))),
      map((length) => Array.from({ length: this.data?.weekend ? length : length - 1 })),
      map((length) => (!this.data.weekend && size(length) === 5 ? [] : length)),
    );
    this.touched(this._value$.value);
  }

  public ngAfterViewInit(): void {
    this._value$.pipe(notNull(), take(1), delay(300)).subscribe((date) => {
      this.scrollToElement(`h-${date.getHours()}`);
      this.scrollToElement(`m-${date.getMinutes()}`);
    });
  }

  public ngOnDestroy() {
    this._value$.complete();
  }

  public writeValue(date: any) {
    if (this.data.showTime) {
      this.selected = this.safePeriod(this.safeDate(date, 'yyyy-MM-dd HH:mm'));
    } else {
      this.selected = this.safePeriod(this.safeDate(date, 'yyyy-MM-dd'));
    }
    this._value$.next(this.selected);
  }

  private safePeriod(date: Date): Date {
    const validMin = this.data.minDate ? isBefore(startOfDay(this.data.minDate), endOfDay(date)) : true;
    if (!validMin) {
      return this.data.minDate;
    }
    const validMax = this.data.maxDate ? isAfter(endOfDay(this.data.maxDate), startOfDay(date)) : true;
    if (!validMax) {
      return this.data.maxDate;
    }
    return date;
  }

  public betweenPeriod(date: Date): boolean {
    const min = this.data.minDate ? isBefore(startOfDay(this.data.minDate), endOfDay(date)) : true;
    const max = this.data.maxDate ? isAfter(endOfDay(this.data.maxDate), startOfDay(date)) : true;
    return min && max;
  }

  public weekDays(days: string[]): string[] {
    if (this.data?.weekend) {
      return days;
    }
    return slice(days, 1, -1);
  }

  public registerOnChange(fn: any): void {
    this.change = fn;
  }

  public registerOnTouched(fn: any): void {
    this.touched = fn;
  }

  public setDisabledState?(isDisabled: boolean): void {
    this._disabled = coerceBooleanProperty(isDisabled);
  }

  public isEquals(day: Date, date: Date): boolean {
    return isEqual(startOfDay(day), startOfDay(date));
  }

  public month(value: Date): string {
    return `geral.meses.${value.getMonth()}`;
  }

  public onPrevMonth(value: Date) {
    this._value$.next(subMonths(value, 1));
  }

  public onNextMonth(value: Date) {
    this._value$.next(addMonths(value, 1));
  }

  public onSelectDate(value: Date, current: Date) {
    const date = set(value, { hours: current.getHours(), minutes: current.getMinutes() });
    if (this.betweenPeriod(date)) {
      this.selected = date;
      this._value$.next(date);
      this.change(date);
      this.selectDate.emit(date);
    }
    if (!this.data.showTime) {
      this.popoverRef.close(this.selected);
    }
  }

  public onSelectHour(hours: number, current: Date) {
    const date = set(current, { hours });
    if (this.betweenPeriod(date)) {
      this.selected = date;
      this._value$.next(date);
      this.change(date);
      this.selectDate.emit(date);
    }
  }

  public onSelectMinute(minutes: number, current: Date) {
    const date = set(current, { minutes });
    if (this.betweenPeriod(date)) {
      this.selected = date;
      this._value$.next(date);
      this.change(date);
      this.selectDate.emit(date);
    }
  }

  private scrollToElement(idElement: string): void {
    document.getElementById(idElement)?.scrollIntoView({ block: 'center' });
  }

  private safeDate(date: string | Date, format: string): Date {
    return isString(date) ? parse(date, format, new Date()) : date;
  }

  private change = (_: any) => true;
  private touched = (_: any) => true;
}
