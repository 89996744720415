import { ItemGroup } from '@app/core/models/academic-tree';
import { EclassResult } from '@app/core/services';
import { size } from 'lodash';

export function helperItemGroup(academicTree: EclassResult): ItemGroup {
  const groups = size(academicTree.groups);
  const subjects = size(academicTree.subjects);
  if (groups >= subjects) {
    return ItemGroup.Group;
  }
  return ItemGroup.Subject;
}
