import { Injectable } from '@angular/core';
import { portalConst } from '@app/config';
import { Observable } from 'rxjs';
import { Entidade, PaisEnum } from '../models';
import { ApiService, Resp } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class DefaultService {
  private readonly resource = `${portalConst.api.usuario}/v1/hub-educacional/padrao`;

  constructor(private api: ApiService) {}

  public grades(country: PaisEnum, entity: Entidade, nivel_id?: number[]): Observable<Resp<any[]>> {
    return this.api.get(`${this.resource}/${country}/series`, { entity, nivel_id });
  }

  public disciplines(country: PaisEnum, entity: any, strictUser = false): Observable<Resp<any[]>> {
    return this.api.get(`${this.resource}/${country}/disciplinas`, { entity, strict_user: strictUser || undefined });
  }

  public disciplinesByTeacher(entityId: number): Observable<any> {
    return this.api.get(`${this.resource}/${entityId}/disciplinas-professor`);
  }
}
