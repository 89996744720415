import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { format } from 'date-fns';
import { toLower } from 'lodash';
import { Observable } from 'rxjs';
import { Lang, langHelper } from '../models';
import { switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class I18nService {
  public langChange$: Observable<any>;

  constructor(public translateService: TranslateService) {
    this.loadlisteners();
    this.change(langHelper(navigator.language) || Lang.ptBr);
  }

  public change(lang: string = Lang.ptBr) {
    this.translateService.setDefaultLang(toLower(lang));
  }

  public translation(lang: string): Observable<any> {
    return this.translateService.getTranslation(toLower(lang));
  }

  public date(date: Date, formatDate: string): string {
    return format(date, formatDate);
  }

  private loadlisteners() {
    this.langChange$ = this.translateService.onDefaultLangChange.pipe(
      switchMap(({ lang }) => this.translateService.use(lang)),
    );
  }
}
