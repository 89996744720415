import { Injectable } from '@angular/core';
import { portalConst } from '@app/config';
import { Observable } from 'rxjs';
import { ApiService, Resp } from './api.service';
import { CrudService } from './crud.service';
import { Category } from '../models';

@Injectable({
  providedIn: 'root',
})
export class DriveService extends CrudService<any> {
  constructor(api: ApiService) {
    super(api, `${portalConst.api.school}/v1/drive`);
  }

  public categories(countryId: number): Observable<Resp<Category[]>> {
    return this.api.get<Category[]>(`${this.url}/categories/${countryId}`);
  }

  public files(req?: any): Observable<any> {
    return this.api.get(`${this.url}/storage`, req);
  }

  public accessControl(req?: any): Observable<any> {
    return this.api.post(`${portalConst.api.drive}`, req);
  }
}
