import { DIALOG_DATA } from '@angular/cdk/dialog';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';

@Component({
  templateUrl: './modal-games.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalGamesComponent {
  public gameTitle: string;
  public gamePath: string;
  constructor(@Inject(DIALOG_DATA) public data: { gamePath: string; gameTitle: string }) {
    this.gameTitle = data.gameTitle;
    this.gamePath = data.gamePath;
  }
}
