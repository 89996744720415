import { Overlay, PositionStrategy, ScrollStrategy } from '@angular/cdk/overlay';
import { ComponentPortal, ComponentType } from '@angular/cdk/portal';
import { Injectable, Injector, OnDestroy, StaticProvider } from '@angular/core';
import { isEmpty } from 'lodash';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { POPOVER_DATA, PopoverData, PopoverRef } from '.';
import { Strategy } from './strategy';

@Injectable()
export class Popover implements OnDestroy {
  private _destroy$ = new Subject<void>();

  constructor(private overlay: Overlay) {}

  public ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

  public open<R = unknown, D = unknown, C = unknown>(
    component: ComponentType<C>,
    config: PopoverData<D>,
  ): PopoverRef<R, C> {
    const overlayRef = this.initOverlay(config);
    const popoverRef = new PopoverRef<R, C>(overlayRef);
    const injector = this.injector(config, popoverRef);
    const portal = new ComponentPortal(component, null, injector);
    const containerRef = overlayRef.attach(portal);
    (popoverRef as any).componentInstance = containerRef.instance;
    (popoverRef as any).panelClass = isEmpty(config.panelClass) ? ['border-theme-500'] : config.panelClass;
    return popoverRef;
  }

  private initOverlay(config: PopoverData) {
    const overlayRef = this.overlay.create({
      positionStrategy: this.positionStrategy(config),
      scrollStrategy: this.scrollStrategy(),
      hasBackdrop: true,
      backdropClass: '',
    });

    overlayRef
      .backdropClick()
      .pipe(
        takeUntil(this._destroy$),
        filter(() => overlayRef.hasAttached()),
      )
      .subscribe(() => overlayRef.detach());
    return overlayRef;
  }

  private positionStrategy(config: PopoverData): PositionStrategy {
    const positions = isEmpty(config?.positionStrategy) ? [Strategy.TOP] : config.positionStrategy;
    return this.overlay.position().flexibleConnectedTo(config.elementRef).withPositions(positions).withPush(false);
  }

  private scrollStrategy(): ScrollStrategy {
    return this.overlay.scrollStrategies.reposition();
  }

  private injector<R, C>(config: PopoverData, ref: PopoverRef<R, C>): Injector {
    const providers: StaticProvider[] = [
      { provide: POPOVER_DATA, useValue: config.data },
      { provide: PopoverRef, useValue: ref },
    ];
    return Injector.create({ providers });
  }
}
