import { DIALOG_DATA, Dialog, DialogRef } from '@angular/cdk/dialog';
import { ChangeDetectionStrategy, Component, DestroyRef, Inject, OnDestroy } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AssessmentStatusAttemptEnum } from '@app/core/models';
import { notNull } from '@app/shared';
import { Store } from '@ngrx/store';
import { isEmpty } from 'lodash';
import { BehaviorSubject, Observable, take } from 'rxjs';
import { AssessmentExecutionSettingsComponent } from './components/assessment-execution-settings/assessment-execution-settings.component';
import { ModalFinalizeComponent } from './components/modal-finalize/modal-finalize.component';
import { ModalAssessmentExecutionActions, ModalAssessmentExecutionSelectors } from './store';
import { ModalAssessmentExecutionInit } from './store/modal-assessment-execution.actions';
import { ModalAssessmentExecutionState } from './store/modal-assessment-execution.state';

export enum Screen {
  Init,
  Running,
  Finalize,
  Wait,
  Approved,
  Failed,
}

@Component({
  selector: 'app-modal-assessment-execution',
  templateUrl: './modal-assessment-execution.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalAssessmentExecutionComponent implements OnDestroy {
  public readonly generalSettingsRef = AssessmentExecutionSettingsComponent;
  public readonly screen = Screen;
  public state$: Observable<ModalAssessmentExecutionState>;
  public submit$ = new BehaviorSubject<boolean>(false);

  constructor(
    @Inject(DIALOG_DATA) private _data: ModalAssessmentExecutionInit,
    private _dialogRef: DialogRef,
    private _store: Store<ModalAssessmentExecutionState>,
    private _dialog: Dialog,
    private _destroyRef: DestroyRef,
  ) {
    this._store.dispatch(ModalAssessmentExecutionActions.InitDataEffect(this._data));
    this.state$ = this._store
      .select(ModalAssessmentExecutionSelectors.selectState)
      .pipe(takeUntilDestroyed(this._destroyRef));
  }

  public ngOnDestroy(): void {
    this._store.dispatch(ModalAssessmentExecutionActions.ClearStageReducer());
  }

  public onConfirmFinalize() {
    this._dialog
      .open<boolean>(ModalFinalizeComponent)
      .closed.pipe(notNull(), take(1))
      .subscribe(() => this.finalize());
  }

  public onClose() {
    this._store
      .select(ModalAssessmentExecutionSelectors.selectState)
      .pipe(take(1))
      .subscribe((state) => {
        this._store.dispatch(ModalAssessmentExecutionActions.ClearStageReducer());
        this._dialogRef.close(state);
      });
  }

  public onShowScreen(state: any): Screen {
    if (state.status === AssessmentStatusAttemptEnum.Finish && state?.tentativa?.aprovado === 1) {
      return Screen.Approved;
    }
    if (state.status === AssessmentStatusAttemptEnum.Finish && state?.tentativa?.aprovado === 0) {
      return Screen.Failed;
    }
    if (
      state.status === AssessmentStatusAttemptEnum.Wait ||
      state.status === AssessmentStatusAttemptEnum.Finish ||
      state.status === AssessmentStatusAttemptEnum.WaitDateResult ||
      state.status === AssessmentStatusAttemptEnum.Review
    ) {
      return Screen.Wait;
    }
    if (isEmpty(state.questoes) && !this.finishStatus(state.status)) {
      return Screen.Init;
    }
    return Screen.Running;
  }

  public onDisableSave(state: ModalAssessmentExecutionState): boolean {
    return state.questoes.some((question) => !!question.loading);
  }

  private finishStatus(status: AssessmentStatusAttemptEnum): boolean {
    return [(AssessmentStatusAttemptEnum.Wait, AssessmentStatusAttemptEnum.Finish)].includes(status);
  }

  private finalize() {
    const actions = {
      error: () => this.submit$.next(false),
    };
    this._store.dispatch(ModalAssessmentExecutionActions.FinalizeResolucaoEffect({ actions }));
  }
}
