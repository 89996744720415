export interface PageData {
  size?: number;
  total: number;
  current: number;
}
export interface Pagination extends PageData {
  pages: number;
}

export const PAGE_SIZE = [10, 25, 50, 100];

export const PAGE_DEFAULT = { current: 1, size: PAGE_SIZE[0] };
