import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AssessmentApplicationService } from '@app/core';
import { AssessmentManagedType, AssessmentQuestionType, AssessmentStatusAttemptEnum } from '@app/core/models';
import { AccordionComponent } from '@app/shared/modules/template/components/accordion/accordion.component';
import { Strategy } from '@app/shared/modules/template/components/popover';
import { millisecondsToHours, millisecondsToMinutes } from 'date-fns';
import { first, isEmpty, isNil } from 'lodash';
import { BehaviorSubject, Subject, finalize, takeUntil, tap } from 'rxjs';
interface Data {
  uuid: string;
  avaliacao: any;
  tentativas: any;
}

@Component({
  selector: 'app-modal-body-assessments-mirror',
  templateUrl: './modal-body-assessments-mirror.component.html',
})
export class ModalBodyAssessmentsMirrorComponent implements OnInit, OnDestroy {
  public readonly badgeStyle = {
    background: 'bg-blue-100 text-white hover:bg-blue-300 hover:text-blue-500',
    active: 'bg-blue-500 text-blue',
  };

  public readonly assessmentStatusAttemptEnum = AssessmentStatusAttemptEnum;
  public readonly strategy = Strategy;
  public readonly tipoQuestao = AssessmentQuestionType;
  public dataApplication$ = new BehaviorSubject<Data>(undefined);
  public hasAttempt: boolean;
  public loading$ = new BehaviorSubject<boolean>(false);
  public ctrlAccordion: boolean;
  private _destroy$ = new Subject<void>();

  @Input() public evaluationUuid: string;
  @Input() public userName: string;
  @Input() public userId: number;
  @ViewChild('accordion') public accordion: AccordionComponent;

  constructor(private _assessmentApplicationService: AssessmentApplicationService) {}

  public get attempt(): any {
    return first(this.dataApplication$.value.tentativas);
  }

  public get isPAAEB(): boolean {
    return [AssessmentManagedType.PAAEB, AssessmentManagedType.PAEE].includes(
      this.dataApplication$.value.avaliacao.tipo_gerenciada,
    );
  }

  public ngOnInit(): void {
    this.loadResult([this.userId, this.evaluationUuid]);
  }

  public ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
    this.loading$.complete();
    this.dataApplication$.complete();
  }

  public onTempoProva(dateStart: Date, dateEnd: Date) {
    const ms = new Date(dateEnd).getTime() - new Date(dateStart).getTime();

    if (ms < 0) {
      return 0 + 'h' + 0 + 'm';
    }
    const hours = millisecondsToHours(ms);
    const minutes = millisecondsToMinutes(ms);

    const rests = hours + 'h ' + minutes + 'm';

    return rests;
  }

  public isEmpty(items: any): boolean {
    return isEmpty(items);
  }

  public onHasNote(note) {
    return !isNil(note) && note >= 0;
  }

  public onSliceStatement(statement: string) {
    if (!statement) {
      return '';
    }

    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = statement;

    const plainText = tempDiv.textContent?.replace(/\s+/g, ' ').trim() || '';

    const imgElement = tempDiv.querySelector('img');

    if (plainText.length > 0) {
      return plainText.slice(0, 100) + (plainText.length > 100 ? '...' : '');
    }

    if (imgElement) {
      imgElement.classList.add('w-24');
      imgElement.style.height = 'auto';
      return imgElement.outerHTML;
    }

    return '';
  }

  public toLetter(index: number): string {
    return String.fromCharCode(65 + index);
  }

  public onExpandAll(action) {
    if (action === 'expand') {
      this.accordion.openAll();
      this.ctrlAccordion = true;
    } else if (action === 'hide') {
      this.accordion.closeAll();
      this.ctrlAccordion = false;
    }
  }

  public onGetHeaderClass(question: any): string {
    if (isEmpty(this.attempt) || this.attempt.status !== 3) {
      return '!bg-white';
    }

    if (!!question.sociodemografica) {
      return '!bg-base-mantis-100';
    }

    if (!!question.anulado) {
      return '!bg-base-status-warning/10';
    }

    if (!!question.resposta_anulado) {
      return '!bg-base-status-danger/10';
    }

    return question.nota > 0 ? '!bg-base-mantis-100' : '!bg-base-status-danger/10';
  }

  public isNil(nota: number) {
    return isNil(nota);
  }

  public onGetScore(question: any) {
    let score = question?.nota || 0;
    if (!!question.anulado) {
      score = question.peso;
    } else if (!!question.resposta_anulado) {
      score = 0;
    }

    return score;
  }

  private loadResult = ([userId, evaluationUuid]) => {
    this._assessmentApplicationService
      .getResult(evaluationUuid, userId)
      .pipe(
        tap(() => this.loading$.next(true)),
        takeUntil(this._destroy$),
        finalize(() => this.loading$.next(false)),
      )
      .subscribe(({ data }: any) => {
        this.dataApplication$.next(data);
        this.hasAttempt = !!this.dataApplication$.value.tentativas[0];
      });
  };
}
