<div class="grid py-5 md:p-5">
  <ng-container *ngIf="data$ | async as data; else loadingTemplate">
    <div class="flex w-full">
      <div class="flex w-full items-center justify-start gap-2">
        <span class="font-semibold" translate> gestor-avaliacoes.total-participantes </span>
        <span> {{ tentativas }}/{{ totalPublicoAlvo }} </span>
      </div>
      <!-- <div class="flex items-center justify-end w-full gap-2 mr-5 truncate md:mr-0">
      <span class="text-[14px] font-semibold" translate> gestor-avaliacoes.media-geral</span>
      <div
        class="flex flex-col items-center justify-center h-10 rounded-lg shadow-inner w-14 bg-base-gray-light-500 shadow-base-gray-200"
      >
        <span class="font-semibold leading-none text-md"> 62.0 </span>
      </div>
    </div> -->
    </div>

    <hr app-divider class="my-5" />
    <app-accordion class="grid w-full gap-3">
      <app-accordion-item *ngFor="let union of data$ | async" headerClass=" !mb-0">
        <div app-accordion-header class="flex w-full">
          <div class="flex w-full justify-start">
            <div class="mr-4 flex items-center justify-center truncate md:mr-0 md:px-5">
              <div
                class="flex h-14 w-14 flex-col items-center justify-center rounded-full bg-base-gray-light-500 shadow-inner shadow-base-gray-200"
              >
                <!-- <span class="font-semibold leading-none text-md"> {{ union.percent }}% </span> -->
                <span class="text-md font-semibold leading-none"> {{ union.mediaTaxaAcertosUnion }}% </span>
              </div>
            </div>
            <span class="flex w-full items-center"> {{ union.descricao }} </span>
          </div>
          <!-- <span class="flex items-center justify-center w-full">
              <span
                app-tooltip
                [tooltip]="'quantos alunos realizaram a prova' | translate"
                class="flex items-center w-2/12 xl:flex"
              >
                {{ union.tentativas }}/{{ union.total_publico_alvo }}
              </span>

              <div class="grid gap-2 md:flex">
              <div class="flex">
                <span class="font-semibold">3.089</span>/
                <span> 4.657</span>
              </div>
              <span translate> gestor-avaliacoes.no-total </span>
            </div>
            </span> -->
          <span class="mr-10 flex w-full items-center justify-end gap-12 whitespace-nowrap">
            <span app-tooltip [tooltip]="'gda.qtd-alunos-prova' | translate" class="flex items-center">
              {{ union.tentativas }}/{{ union.total_publico_alvo }}
            </span>
            <div class="grid gap-2 xl:flex">
              <!-- <div class="flex justify-center gap-2">
                <span class="text-sm" translate> gestor-avaliacoes.acerto </span>
                <span class="font-semibold">45,00%</span>
              </div> -->
              <ng-container [ngSwitch]="union.status">
                <span
                  *ngSwitchCase="undefined"
                  class="flex w-full justify-center rounded-full bg-gray-200 px-2"
                  translate
                >
                  gda.sem-agendamento
                </span>
                <span
                  *ngSwitchCase="applicationStatusExecutionEnum.Scheduled"
                  class="flex w-full justify-center rounded-full bg-base-status-warning px-10 text-white"
                  translate
                >
                  gda.minhas.agendada
                </span>
                <span
                  *ngSwitchCase="applicationStatusExecutionEnum.InProgress"
                  class="flex w-full justify-center rounded-full bg-base-status-info px-6 text-white"
                  translate
                >
                  gda.minhas.andamento
                </span>
                <span
                  *ngSwitchCase="applicationStatusExecutionEnum.Closed"
                  class="flex w-full justify-center rounded-full bg-base-mantis-500 px-10 text-white"
                  translate
                >
                  gda.minhas.encerrada
                </span>
              </ng-container>
            </div>
          </span>
        </div>
        <div app-accordion-content>
          <div
            class="border-t-none grid max-h-[60rem] w-[60rem] gap-4 overflow-auto rounded-b-lg rounded-t-none border border-gray-300 bg-base-whisper-500 p-5 shadow-inner shadow-black/40 lg:w-full"
          >
            <!-- SEGUNDO ACCORDION -->
            <div class="mr-5 grid w-full px-3 md:mr-0">
              <app-accordion #accordion>
                <app-accordion-item *ngFor="let field of union.fields">
                  <div app-accordion-header class="flex w-full justify-between">
                    <div class="flex w-full justify-start">
                      <div class="mr-4 flex items-center justify-center truncate md:mr-0 md:px-5">
                        <div
                          class="flex h-14 w-14 flex-col items-center justify-center rounded-full bg-base-gray-light-500 shadow-inner shadow-base-gray-200"
                        >
                          <span class="text-md font-semibold leading-none"> {{ field.mediaTaxaAcertos }}% </span>
                        </div>
                      </div>
                      <span class="flex w-full items-center"> {{ field.descricao }} </span>
                    </div>

                    <span class="mr-10 flex items-center justify-end gap-12 whitespace-nowrap">
                      <span class="flex w-full items-center justify-center">
                        <span
                          app-tooltip
                          [tooltip]="'gda.qtd-alunos-prova' | translate"
                          class="flex w-2/12 items-center"
                        >
                          {{ field.tentativas }}/{{ field.total_publico_alvo }}
                        </span>
                        <!-- <div class="flex gap-2">
                          <span class="font-semibold">3.089</span>/
                          <span> 4.657</span>
                          <span> no total</span>
                        </div> -->
                      </span>
                      <div class="grid gap-2 xl:flex">
                        <!-- <div class="flex justify-center gap-2">
                          <span class="text-sm" translate> gestor-avaliacoes.acerto </span>
                          <span class="font-semibold">{{field.totalAcerto}}%</span>
                        </div> -->
                        <ng-container [ngSwitch]="field.status">
                          <span
                            *ngSwitchCase="undefined"
                            class="flex w-full justify-center rounded-full bg-gray-200 px-2"
                            translate
                          >
                            gda.sem-agendamento
                          </span>
                          <span
                            *ngSwitchCase="applicationStatusExecutionEnum.Scheduled"
                            class="flex w-full justify-center rounded-full bg-base-status-warning px-10 text-white"
                            translate
                          >
                            gda.minhas.agendada
                          </span>
                          <span
                            *ngSwitchCase="applicationStatusExecutionEnum.InProgress"
                            class="flex w-full justify-center rounded-full bg-base-status-info px-6 text-white"
                            translate
                          >
                            gda.minhas.andamento
                          </span>
                          <span
                            *ngSwitchCase="applicationStatusExecutionEnum.Closed"
                            class="flex w-full justify-center rounded-full bg-base-mantis-500 px-10 text-white"
                            translate
                          >
                            gda.minhas.encerrada
                          </span>
                        </ng-container>
                      </div>
                    </span>
                  </div>
                  <div app-accordion-content>
                    <!-- TERCEIRO  -->
                    <div class="grid gap-2 px-3">
                      <app-card
                        class="flex w-full border-l-4 last:mb-5"
                        *ngFor="let entity of field.entities"
                        [ngClass]="{
                          'border-base-status-warning': entity.status === applicationStatusExecutionEnum.Scheduled,
                          'border-base-status-info': entity.status === applicationStatusExecutionEnum.InProgress,
                          'border-base-mantis-500': entity.status === applicationStatusExecutionEnum.Closed
                        }"
                      >
                        <div app-card-content class="flex w-full items-center justify-between !rounded-lg !shadow-lg">
                          <div class="flex w-[30%] justify-start">
                            <div class="flex justify-start">
                              <div class="mr-4 flex items-center justify-center truncate md:mr-0 md:px-5 xl:px-10">
                                <div
                                  class="flex h-10 w-10 flex-col items-center justify-center rounded-full bg-base-gray-light-500 shadow-inner shadow-base-gray-200"
                                >
                                  <span class="text-[10px] font-semibold leading-none">
                                    {{ entity.taxa_acertos | number : '1.0-0' }}%
                                  </span>
                                </div>
                              </div>
                            </div>

                            <span class="flex items-center pr-4"> {{ entity.nome }} </span>
                            <!-- <button app-icon-button app-tooltip
                              >

                            </button> -->

                            <!-- <button app-icon-button app-tooltip
                              class="border group border-theme-500 hover:bg-theme-500">
                              <svg-icon class="block text-black w-6 transform scale-x-[-1]"
                                src="assets/icons/icon-back.svg"></svg-icon>
                            </button> -->

                            <button
                              *ngIf="!!entity.data_inicio"
                              app-flat-button
                              type="button"
                              class="bg-white"
                              (click)="onSchoolClick(entity.id)"
                              app-tooltip
                              [tooltip]="'gda.ver-alunos' | translate"
                            >
                              <svg-icon
                                class="block w-4 scale-x-[-1] transform text-black"
                                src="assets/icons/icon-back.svg"
                              ></svg-icon>
                            </button>
                          </div>
                          <!--  -->
                          <div class="flex w-[40%] justify-center gap-8">
                            <div class="flex">
                              <span *ngIf="!!entity.data_inicio">
                                {{ entity.data_inicio | date : 'dd/MM/yyyy HH:mm' }} até
                                {{ entity.data_fim | date : 'dd/MM/yyyy HH:mm' }}
                              </span>
                            </div>
                          </div>
                          <div class="flex w-[30%] justify-end gap-12">
                            <div>
                              <span
                                app-tooltip
                                [tooltip]="'gda.qtd-alunos-prova' | translate"
                                class="flex w-2/12 items-center xl:flex"
                              >
                                <span class="font-semibold">
                                  {{ entity.tentativas || 0 }}
                                </span>
                                <span>/{{ entity.total_publico_alvo }}</span>
                              </span>
                            </div>
                            <span class="mr-10 flex items-center justify-end whitespace-nowrap">
                              <div class="grid gap-2 xl:flex">
                                <!-- <div class="flex justify-center gap-2 whitespace-nowrap">
                                    <span class="text-sm" translate> gestor-avaliacoes.acerto </span>
                                    <span class="font-semibold">{{ entity.taxa_acertos || '-' }} %</span>
                                  </div> -->

                                <ng-container [ngSwitch]="entity.status">
                                  <span
                                    *ngSwitchCase="undefined"
                                    class="flex justify-center rounded-full bg-gray-200 px-2"
                                    translate
                                  >
                                    gda.sem-agendamento
                                  </span>
                                  <span
                                    *ngSwitchCase="applicationStatusExecutionEnum.Scheduled"
                                    class="flex w-full justify-center rounded-full bg-base-status-warning px-10 text-white"
                                    translate
                                  >
                                    gda.minhas.agendada
                                  </span>
                                  <span
                                    *ngSwitchCase="applicationStatusExecutionEnum.InProgress"
                                    class="flex w-full justify-center rounded-full bg-base-status-info px-6 text-white"
                                    translate
                                  >
                                    gda.minhas.andamento
                                  </span>
                                  <span
                                    *ngSwitchCase="applicationStatusExecutionEnum.Closed"
                                    class="flex w-full justify-center rounded-full bg-base-mantis-500 px-10 text-white"
                                    translate
                                  >
                                    gda.minhas.encerrada
                                  </span>
                                </ng-container>
                              </div>
                            </span>
                          </div>
                        </div>
                      </app-card>
                    </div>
                  </div>
                </app-accordion-item>
              </app-accordion>
            </div>
          </div>
        </div>
      </app-accordion-item>
    </app-accordion>
  </ng-container>
</div>

<ng-template #loadingTemplate>
  <app-card>
    <div app-card-content class="h-[200px]">
      <app-skeleton></app-skeleton>
    </div>
  </app-card>
</ng-template>
