<app-popover>
  <div app-popover-header>
    <ng-container [ngSwitch]="form.value.type">
      <div *ngSwitchCase="assessmentAnnulTypeEnum.Answer">
        <span class="text-lg font-semibold" translate>gestor-avaliacoes.anular-resposta</span>
      </div>
      <div *ngSwitchCase="assessmentAnnulTypeEnum.Question">
        <span class="text-lg font-semibold" translate>gestor-avaliacoes.anular-questao</span>
      </div>
    </ng-container>
  </div>
  <hr app-divider />
  <div app-popover-content class="w-[300px]">
    <div class="grid w-full">
      <ng-container [ngSwitch]="form.value.type">
        <div *ngSwitchCase="assessmentAnnulTypeEnum.Answer" class="mb-2">
          <span class="md:txt-base text-sm font-light" translate>gestor-avaliacoes.resposta-anulada</span>
        </div>
        <div *ngSwitchCase="assessmentAnnulTypeEnum.Question" class="mb-2">
          <span class="md:txt-base text-sm font-light" translate>gestor-avaliacoes.questao-anulada-correta</span>
        </div>
      </ng-container>
      <form [formGroup]="form">
        <!-- <app-form-field class="w-full">
          <ng-select app-select formControlName="type" [placeholder]="'gestor-avaliacoes.opcao-anular' | translate">
            <ng-option [value]="1" translate>gestor-avaliacoes.anular-considerar</ng-option>
            <ng-option [value]="2" translate>gestor-avaliacoes.anular-questao-aluno</ng-option>
          </ng-select>
        </app-form-field> -->

        <app-form-field class="w-full">
          <textarea
            app-input
            formControlName="anulado_motivo"
            [placeholder]="'gestor-avaliacoes.motivo-anulacao' | translate"
          ></textarea>
        </app-form-field>
      </form>
    </div>
  </div>
  <hr app-divider />
  <div app-popover-footer>
    <button app-icon-button (click)="onAnnul()" type="button" class="!rounded-full bg-red-500 text-white" translate>
      gestor-avaliacoes.anular
      <div *ngIf="loading$ | async" class="ml-2 flex h-6 w-6 items-center justify-center">
        <app-skeleton class="flex h-4 w-4" baseStyle="bg-red-500" [showLabel]="false"></app-skeleton>
      </div>
    </button>
  </div>
</app-popover>
