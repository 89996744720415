<div *ngIf="showIcon" class="mx-auto w-40 rounded-md p-4">
  <div class="flex animate-pulse items-center space-x-2">
    <div class="h-10 w-10 rounded-full bg-base-gray-light-600/80"></div>
    <div class="flex-1 space-y-3 py-1">
      <div class="h-2 rounded bg-base-gray-light-600/80"></div>
      <div class="space-y-2">
        <div class="grid grid-cols-3 gap-2">
          <div class="col-span-1 ml-1 h-2 rounded bg-base-gray-light-600/80"></div>
          <div class="col-span-2 h-2 rounded bg-base-gray-light-600/80"></div>
        </div>
        <div class="h-2 rounded bg-base-gray-light-600/80"></div>
      </div>
    </div>
  </div>
</div>
<ng-content></ng-content>
