import { ChangeDetectionStrategy, Component, DestroyRef, Input, OnInit, forwardRef } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DefaultService } from '@app/core/services';
import { notNull, selectedEntityActive } from '@app/shared/utils';
import { AppSelectors } from '@app/store';
import { AppState } from '@app/store/app.state';
import { Store } from '@ngrx/store';
import { first, head, size } from 'lodash';
import { BehaviorSubject, Observable, combineLatest, map, switchMap, tap } from 'rxjs';

@Component({
  selector: 'app-select-default-subject',
  templateUrl: './select-default-subject.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectDefaultSubjectComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectDefaultSubjectComponent implements OnInit, ControlValueAccessor {
  @Input() public multiple = true;
  @Input() public label: boolean = true;
  @Input() public strictUser: boolean = false;
  public data$: Observable<any[]>;
  public ctrl = new FormControl([]);
  public countryId$ = new BehaviorSubject<any>(undefined);
  public entities: any;
  private _nivelId$ = new BehaviorSubject<number>(undefined);

  constructor(private store: Store<AppState>, private defaultService: DefaultService, private destroyRef: DestroyRef) {}

  @Input()
  public set countryId(value: number) {
    this.countryId$.next(value);
  }

  @Input('entities')
  public set setEntities(value: any) {
    this.entities = value;
    this.loadData();
  }

  @Input()
  public set defaultLevelFilter(value: number) {
    this._nivelId$.next(value);
    this.loadData();
  }

  public ngOnInit(): void {
    const activeUser$ = this.store.select(AppSelectors.ActiveUser).pipe(takeUntilDestroyed(this.destroyRef), notNull());
    this.data$ = combineLatest({
      countryId: this.countryId$,
      activeUser: activeUser$,
      defaultLevelId: this._nivelId$,
    }).pipe(
      switchMap(({ countryId, activeUser, defaultLevelId }) => {
        const entity = selectedEntityActive(activeUser);
        return this.defaultService.disciplines(
          countryId || activeUser.pais_id,
          head(entity),
          this.strictUser,
          defaultLevelId,
        );
      }),
      map((res) => res?.data || (this.multiple ? [] : undefined)),
    );

    this.ctrl.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((value) => this.onChange(value));
  }

  public writeValue(obj: any): void {
    this.ctrl.setValue(obj);
  }

  public registerOnChange(fn: any): void {
    this.changeValue = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
    this.ctrl.markAllAsTouched();
  }

  public setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.ctrl.disable();
    } else {
      this.ctrl.enable();
    }
  }

  public onFocus() {
    this.onTouched();
  }

  private onChange(value: any) {
    const defaultValue = this.multiple ? [] : undefined;
    this.changeValue(value ? value : defaultValue);
  }

  private loadData() {
    this.data$ = this.store.select(AppSelectors.ActiveUser).pipe(
      takeUntilDestroyed(this.destroyRef),
      notNull(),
      switchMap((user) => {
        const entity = this.entities ?? head(selectedEntityActive(user));
        return this.defaultService.disciplines(
          user.pais_id,
          JSON.stringify(entity),
          this.strictUser,
          this._nivelId$.value,
        );
      }),
      map((res) => {
        const data = res?.data || (this.multiple ? [] : undefined);
        return data;
      }),
      tap(this.reset),
    );
  }

  private reset = (res: any[] = []) => {
    const value = this.multiple
      ? this.ctrl.value?.map((id) => res.find((item) => item.id === id)?.id).filter((id) => id)
      : res.find((item) => item.id === this.ctrl.value)?.id;

    if (!value) {
      this.ctrl.reset();
    }

    this.onChange(value);
  };

  private onTouched = () => true;
  private changeValue = (_: any) => true;
}
