<app-modal-header>
  <h1 class="text-2xl font-semibold" translate>gestor-avaliacoes.finalizar-avaliacao</h1>
</app-modal-header>

<div
  app-modal-content
  *ngIf="state$ | async as state"
  class="grid min-h-[100px] grid-cols-[auto_1fr] items-center gap-2"
>
  <svg-icon src="assets/icons/icon-alert-fill.svg" class="w-20 self-start text-base-status-warning"></svg-icon>
  <div class="text-base" translate>
    gestor-avaliacoes.atencao-finalizar
    <p translate>
      <span
        class="text-md font-medium"
        [ngClass]="{ 'text-base-status-danger': countQuestionsAnswered < countQuestions }"
      >
        {{ countQuestionsAnswered }}/{{ countQuestions }}
      </span>
      gestor-avaliacoes.questoes-respondidas
    </p>
    <div *ngIf="state.started_at" class="mt-5 w-full" translate>
      gestor-avaliacoes.iniciado-em
      <span class="font-medium">
        {{ state.started_at | date : 'dd/MM/yyyy HH:mm:ss' }}
      </span>
    </div>
    <div *ngIf="!!time" class="mt-5 w-full" translate>
      gestor-avaliacoes.duracao
      <span class="font-medium">
        {{ time }}
      </span>
    </div>
  </div>
</div>
<div app-modal-footer class="flex w-full gap-2">
  <div class="flex w-full justify-start">
    <button
      app-outline-button
      (click)="onSave()"
      type="button"
      class="border-none px-2 font-semibold uppercase text-red-500 hover:[&:not([disabled])]:bg-red-500 hover:[&:not([disabled])]:text-white"
      dialogClose
      translate
    >
      gestor-avaliacoes.continuar
    </button>
  </div>
  <div class="flex w-full justify-end">
    <button
      app-button
      type="button"
      (click)="onClose()"
      class="mr-2 bg-base-status-success px-5 font-semibold uppercase text-white"
      [dialogClose]="true"
      translate
    >
      gestor-avaliacoes.finalizar-salvar
    </button>
  </div>
</div>
