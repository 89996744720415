import { Observable } from 'rxjs';

import { ApiService, Resp } from './api.service';

export class CrudService<T> {
  constructor(protected api: ApiService, protected url: string) {}

  public list(req?: any): Observable<T[]> {
    return this.api.list<T[]>(this.url, req);
  }

  public index(req?: any): Observable<Resp<T[]>> {
    return this.api.get<T[]>(this.url, req);
  }

  public show(id: any, data?: any): Observable<Resp<T>> {
    return this.api.get<T>(`${this.url}/${id}`, data);
  }

  public showBySlug(slug: any): Observable<Resp<T>> {
    return this.api.get<T>(`${this.url}/${slug}`);
  }

  public store(body: any, req?: any): Observable<Resp<T>> {
    return this.api.post<T>(this.url, body, req);
  }

  public update(id: any, body: any, req?: any): Observable<Resp<T>> {
    return this.api.put<T>(`${this.url}/${id}`, body, req);
  }

  public partialUpdate(id: any, body: any = {}, req?: any): Observable<any> {
    return this.api.patch(`${this.url}/${id}`, body, req);
  }

  public destroy(id: any): Observable<any> {
    return this.api.delete(`${this.url}/${id}`);
  }
}
