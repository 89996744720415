import { Injectable } from '@angular/core';
import { portalConst } from '@app/config';
import { BehaviorSubject, Observable } from 'rxjs';
import { ShowcaseEntity, ShowcaseType } from '../models/showcase.model';
import { ApiService, Resp } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class LearningTrailsShowcaseService {
  public entities$ = new BehaviorSubject<any[]>([]);
  public showcaseTypes$ = new BehaviorSubject<any[]>([]);

  constructor(private api: ApiService) {}

  public listShowcase(courseId: number): Observable<Resp<any>> {
    return this.api.get(`${portalConst.api.trilhas}/v1/vitrine`, { curso_id: courseId });
  }

  public postShowcase(body: any): Observable<Resp<any>> {
    return this.api.post(`${portalConst.api.trilhas}/v1/vitrine`, { ...body });
  }

  public updateShowcase(showcaseId: number, body: any) {
    return this.api.put(`${portalConst.api.trilhas}/v1/vitrine/${showcaseId}`, { ...body });
  }

  public deleteShowcase(showcaseId) {
    return this.api.delete(`${portalConst.api.trilhas}/v1/vitrine/${showcaseId}`);
  }

  public getEntities(countryId: number): Observable<Resp<ShowcaseEntity[]>> {
    return this.api.get(`${portalConst.api.trilhas}/v1/user/unidades`, { country_id: countryId, all: true });
  }

  public getShowcaseTypes(): Observable<Resp<ShowcaseType[]>> {
    return this.api.get(`${portalConst.api.trilhas}/v1/tipo-vitrine`);
  }
}
