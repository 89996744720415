import { NgModule } from '@angular/core';
import { BadgeModule } from '../badge/badge.module';
import { TemplateModule } from '../template/template.module';
import { CardListComponent } from './card-list.component';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [CardListComponent],
  imports: [TemplateModule, BadgeModule, RouterModule],
  exports: [CardListComponent],
})
export class CardListModule {}
