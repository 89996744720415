<div class="p-5">
  <div class="flex flex-col gap-2">
    <app-card
      *ngFor="let dependent of store.dependents"
      class="border-theme-500 duration-300"
      [ngClass]="{
        'border-l-[6px] bg-theme-50': isActived(dependent, store),
        'cursor-pointer hover:bg-theme-50': !isActived(dependent, store),
      }"
      (click)="onChange(dependent, store)"
    >
      <div app-card-content class="!px-3 !py-2 text-start">
        <span class="font-semibold">{{ dependent | orderName | async }}</span>
        <div class="text-gray-500">{{ dependent.serie || '-' }}</div>
      </div>
    </app-card>
  </div>
</div>
