<div
  (click)="onToggle()"
  class="relative mb-2 flex cursor-pointer items-center justify-between bg-white px-6 py-4 shadow"
  [ngClass]="{
    'rounded-b-lg': colIndex === 0,
    'rounded-lg': colIndex !== 0
  }"
>
  <div class="flex w-full flex-col justify-start">
    <ng-content select="[app-accordion-header]"></ng-content>
  </div>
  <div class="w-6 transition duration-500" [ngClass]="{ 'rotate-180': expanded }">
    <svg-icon class="h-6 w-6 select-none self-start" src="assets/icons/icon-arrow-down-fine.svg"></svg-icon>
  </div>
</div>

<div
  #container
  class="relative flex flex-col py-0 {{ contentClass }}"
  [ngClass]="{ 'accordion--hidden': !expanded }"
  [@expansion]="expanded"
>
  <ng-content></ng-content>
  <ng-template [cdkPortalOutlet]="item"></ng-template>
</div>
