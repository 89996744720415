export interface Book {
  id: number;
  slug: string;
  title: string;
  fasciculo: string;
  serie: string;
  nivel_id: number | null;
  serie_id: number | null;
  disciplina_id: number | null;
  image: string;
  has_lock: boolean;
  tags: any[];
  type: TypeBookEnum;
}

export interface BookResponse {
  id: number;
  books: Book[];
  name: string;
  nivel_id: number;
  nivel_leitor_id: number;
}

export enum TypeBookEnum {
  Didatico,
  Paradidatico,
}
