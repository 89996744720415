import { Dialog } from '@angular/cdk/dialog';
import { ChangeDetectionStrategy, Component, DestroyRef, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { DomSanitizer } from '@angular/platform-browser';
import { AssessmentApplicationService } from '@app/core';
import { AssessmentAnnulTypeEnum, Entidade } from '@app/core/models';
import { ModalGraphComponent } from '@app/modules/assessments-manager/shared/modal-graph/modal-graph.component';
import { ModalReportComponent } from '@app/modules/assessments-manager/shared/modal-report/modal-report.component';
import { notNull, safeEmpty, selectedEntityActive } from '@app/shared';
import { Popover, Strategy } from '@app/shared/modules/template/components/popover';
import { AppSelectors } from '@app/store';
import { AppState } from '@app/store/app.state';
import { Store } from '@ngrx/store';
import { first } from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { AssessmentQuestionType } from '../../../../../../../core/models/assessment-question-type.enum';
import { PopoverDescriptionAnnulQuestionComponent } from '../../../badge-annuled-question/popover-description-annul-question.component';
import { PopoverAnnulQuestionComponent } from '../popover-annul-question/popover-annul-question.component';

@Component({
  selector: 'app-tab-questions',
  templateUrl: './tab-questions.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabQuestionsComponent implements OnInit, OnDestroy {
  public readonly assessmentQuestionType = AssessmentQuestionType;
  public readonly badgeStyle = {
    background: 'bg-blue-100 text-white hover:bg-blue-300 hover:text-blue-500',
    active: 'bg-blue-500 text-blue',
  };

  public dataQuest$ = new BehaviorSubject<any>(undefined);
  public application$ = new BehaviorSubject<any>(undefined);
  public activeUser$ = new BehaviorSubject<any>(undefined);
  public loading$ = new BehaviorSubject<boolean>(false);
  public entityActive: Entidade;

  constructor(
    private _gdaReportService: AssessmentApplicationService,
    private _popover: Popover,
    private _store: Store<AppState>,
    private _destroyRef: DestroyRef,
    private _dialog: Dialog,
    private sanitizer: DomSanitizer,
  ) {
    this._store
      .select(AppSelectors.ActiveUser)
      .pipe(takeUntilDestroyed(this._destroyRef), notNull())
      .subscribe((activeUser) => {
        this.entityActive = first(selectedEntityActive(activeUser));
        this.activeUser$.next(activeUser);
      });
  }

  @Input()
  public set application(value) {
    this.application$.next(value);
  }

  public ngOnInit(): void {
    this.loadData();
  }

  public ngOnDestroy(): void {
    this.dataQuest$.complete();
    this.application$.complete();
    this.loading$.complete();
  }

  public minhaQuestao(quest: any): boolean {
    if (this.entityActive?.tipo === 1) {
      return quest?.criado_por === this.activeUser$?.value?.usuario_id;
    }
    return this.isSameEntity(quest);
  }

  public isSameEntity(quest: any): boolean {
    const options = {
      [1]: () => 'entidade_id',
      [2]: () => 'campo_id',
      [3]: () => 'uniao_id',
      [4]: () => 'editora_id',
      [5]: () => 'divisao_id',
    };
    const hasSameEntity = quest?.entidade_permissao?.some(
      (permissao) => permissao[options[this.entityActive.tipo]()] === this.entityActive.id,
    );
    return hasSameEntity;
  }

  public onSliceStatement(statement: string) {
    if (!statement) {
      return '';
    }

    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = statement;

    const plainText = tempDiv.textContent?.replace(/\s+/g, ' ').trim() || '';

    const imgElement = tempDiv.querySelector('img');

    if (plainText.length > 0) {
      return plainText.slice(0, 100) + (plainText.length > 100 ? '...' : '');
    }

    if (imgElement) {
      imgElement.classList.add('w-24');
      imgElement.style.height = 'auto';
      return imgElement.outerHTML;
    }

    return '';
  }

  public onReport(questionUuid: string): void {
    this._dialog.open<boolean>(ModalReportComponent, {
      data: {
        questionUuid,
      },
    });
  }

  public onRightPercentage(question: any) {
    const alternative = question.alternativas.find((a) => a.alternativa_correta === 1);
    let percentualAcerto = 0;
    let percentualErro = 0;
    if (question.count > 0) {
      percentualAcerto = ((alternative?.count || 0) / question.count) * 100;
      percentualErro = 100 - percentualAcerto;
    }
    return { acerto: percentualAcerto.toFixed(0), erro: percentualErro.toFixed(0) };
  }

  public onOpenPopoverAnnull(elementRef: ElementRef, questaoAnuladoMotivo: string): void {
    if (questaoAnuladoMotivo.length > 0) {
      return;
    }
    this._popover.open(PopoverDescriptionAnnulQuestionComponent, {
      positionStrategy: [Strategy.LEFT],
      elementRef,
      data: { anulado_motivo: questaoAnuladoMotivo },
    }).closed;
  }

  public onOpenPopover(elementRef: ElementRef, question: any): void {
    this._popover
      .open(PopoverAnnulQuestionComponent, {
        positionStrategy: [Strategy.LEFT],
        elementRef,
        data: {
          aplicacao_uuid: this.application$.value.uuid,
          type: AssessmentAnnulTypeEnum.Question,
          question,
        },
      })
      .closed.subscribe((res: any) => this.onUpdateQuestionAnula(res, question));
  }

  public toLetter(index: number): string {
    return String.fromCharCode(65 + index);
  }

  public onModalGraph(questionUuid: string): void {
    this._dialog.open<boolean>(ModalGraphComponent, {
      data: {
        applicationUuid: this.application$.value.uuid,
        questionUuid,
      },
    });
  }

  public onUpdateQuestionAnula(data: any, question: any): void {
    const currentDataQuest = this.dataQuest$.getValue();
    const updatedQuestoes = currentDataQuest.map((q: any) => {
      if (q.uuid === question.uuid) {
        return {
          ...q,
          anulado: data.anulado,
          anulado_motivo: data.anulado_motivo,
        };
      }
      return q;
    });
    this.dataQuest$.next(updatedQuestoes);
  }

  private loadData() {
    this.loading$.next(true);
    this._gdaReportService
      .getQuestions(this.application$.value.uuid)
      .pipe(safeEmpty())
      .subscribe((data) => {
        this.loading$.next(false);
        this.dataQuest$.next(data);
      });
  }
}
