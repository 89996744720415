import { Injectable } from '@angular/core';
import { portalConst } from '@app/config';
import { Trail } from '@app/core/models/trail';
import { ApiService, Resp } from '@app/core/services/api.service';
import { CrudService } from '@app/core/services/crud.service';
import { BehaviorSubject, Observable, take } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TrailsService extends CrudService<Trail> {
  public uc$ = new BehaviorSubject<boolean>(false);
  public ivca$ = new BehaviorSubject<boolean>(false);

  constructor(api: ApiService) {
    super(api, `${portalConst.api.trilhas}/v1/trilha`);
  }

  public destroyTrail(trailId: number) {
    return this.api.delete<Trail>(`${this.url}/${trailId}`);
  }

  public setOrder(id: number, reordenar: number): Observable<Resp<any>> {
    return this.api.put<Resp<any>>(`${this.url}/${id}/reordenar`, { reordenar }).pipe(take(1));
  }
}
