import { SidenavDirection } from './sidenav';
export type PositionConfig = { [Key in SidenavDirection]: PositionProp };

export const CLOSED_SIZE = '16px';
export interface PositionProp {
  host: string;
  toggle: string;
  markOpened: (elementRef: any, size: string) => any;
  markClosed: (elementRef: any, minSize?: string) => any;
}

export const POSITION: PositionConfig = {
  [SidenavDirection.Start]: {
    host: 'left-0 justify-self-start shadow-right',
    toggle: 'right-0 rounded-l-3xl',
    markOpened: (elementRef: any, size: string) => {
      const containerRef = elementRef.firstChild;
      containerRef.style.transform = `none`;
      elementRef.style.width = size;
      return elementRef.style.width;
    },
    markClosed: (elementRef: any, minSize = CLOSED_SIZE) => {
      const containerRef = elementRef.firstChild;
      containerRef.style.transform = `-${containerRef.clientWidth}px`;
      elementRef.style.width = minSize;
      return elementRef.style.width;
    },
  },
  [SidenavDirection.End]: {
    host: 'right-0 justify-self-end shadow-left',
    toggle: 'left-0 rounded-r-3xl',
    markOpened: (elementRef: any, size: string) => {
      const containerRef = elementRef.firstChild;
      containerRef.style.transform = size;
      elementRef.style.width = size;
      elementRef.style.width = size;
      return elementRef.style.width;
    },
    markClosed: (elementRef: any, minSize = CLOSED_SIZE) => {
      const containerRef = elementRef.firstChild;
      containerRef.style.transform = `none`;
      elementRef.style.width = minSize;
      return elementRef.style.width;
    },
  },
};
