import { Injectable } from '@angular/core';
import { portalConst } from '@app/config';
import { CourseResponse, ElementData } from '../models/courses.model';
import { ApiService } from './api.service';
import { CrudService } from './crud.service';

@Injectable({
  providedIn: 'root',
})
export class CourseService extends CrudService<CourseResponse> {
  constructor(api: ApiService) {
    super(api, `${portalConst.api.trilhas}/`);
  }

  public getTrail(eventId: number, trailSlug: string) {
    return this.api.get<CourseResponse>(`${this.url}site/v1/trilhas/${eventId}/${trailSlug}`);
  }

  public getElement(eventId: number, trailSlug: string, elementSlug) {
    return this.api.get<ElementData>(`${this.url}site/v1/trilhas/${eventId}/${trailSlug}/elemento/${elementSlug}`);
  }

  public getTrailByVitrine(trailSlug: string) {
    return this.api.get<CourseResponse>(`${this.url}site/v1/trilhas/preview/${trailSlug}`);
  }

  public getElementByVitrine(trailSlug: string, elementSlug: string) {
    return this.api.get<ElementData>(`${this.url}site/v1/trilhas/preview/${trailSlug}/elemento/${elementSlug}`);
  }

  public markAsRead(eventId: number, trailSlug: string, elementSlug: string) {
    return this.api.put(`${this.url}site/v1/trilhas/${eventId}/${trailSlug}/elemento/${elementSlug}/concluido`);
  }

  public withdrawCertificate(eventoId: number, cursoSlug: string, token: string, activeUser: any) {
    window.open(
      `${this.url}site/v1/eventos/${eventoId}/${cursoSlug}/imprimir-certificado?uuids=${activeUser.user_uuid}&token=${token}&locale=${activeUser.lingua}`,
    );
  }

  public printCertificate(eventoId: number, cursoSlug: string, token: string, uuid: string, activeUser: any) {
    window.open(
      `${this.url}site/v1/eventos/${eventoId}/${cursoSlug}/imprimir-certificado?uuids=${uuid}&token=${token}&locale=${activeUser.lingua}`,
    );
  }
}
