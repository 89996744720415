import { Injectable } from '@angular/core';
import { storageKeys } from '@app/config';
import { LoadingService, LoggedUserService } from '@app/core/services';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { LocalStorageService } from 'ngx-webstorage';
import { exhaustMap, map, tap } from 'rxjs/operators';
import * as AppActions from './app.actions';

@Injectable({ providedIn: 'root' })
export class AppEffects {
  public initData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActions.InitDataEffect),
      exhaustMap(({ user }) => this.loggedUserService.build(user)),
      map((result) => AppActions.DataLoadedReducer(result)),
      tap(() => this.loadingService.finish()),
    ),
  );

  public token$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActions.TokenEffect),
      tap(({ token, access_token }) => {
        this.localStorageService.store(storageKeys.AUTHENTICATION_TOKEN, token);
        if (!!access_token) {
          this.localStorageService.store(storageKeys.ACCESS_TOKEN, access_token);
        }
      }),
      map((result) => AppActions.TokenReducer(result)),
    ),
  );

  public entity$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActions.EntityEffect),
      tap(({ entity }) => this.localStorageService.store(storageKeys.ENTITY, entity)),
      map((result) => AppActions.EntityReducer(result)),
    ),
  );

  public clearToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActions.TokenClearEffect),
      tap(() => Object.keys(storageKeys).forEach((key) => this.localStorageService.clear(key))),
      map(() => AppActions.TokenClearReducer()),
    ),
  );

  constructor(
    private actions$: Actions,
    private loadingService: LoadingService,
    private loggedUserService: LoggedUserService,
    private localStorageService: LocalStorageService,
  ) {}
}
