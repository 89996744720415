import { ChangeDetectionStrategy, Component, DestroyRef, Input, OnDestroy } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { notNull, selectedEntityActive } from '@app/shared';
import { AppSelectors } from '@app/store';
import { AppState } from '@app/store/app.state';
import { Store } from '@ngrx/store';
import { first, isEqual } from 'lodash';
import { BehaviorSubject, distinctUntilChanged } from 'rxjs';
import { FormInstructionsReportComponent } from '../form-instructions-report/form-instructions-report.component';
import { AssessmentType, Entidade, FunctionsEnum } from '@app/core/models';

@Component({
  selector: 'app-modal-body-report',
  templateUrl: './modal-body-report.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalBodyReportComponent implements OnDestroy {
  public readonly formInstructionsReportComponentRef = FormInstructionsReportComponent;
  public readonly assessmentType = AssessmentType;
  public application$ = new BehaviorSubject<any>(undefined);
  public isNotEntity$ = new BehaviorSubject<boolean>(false);
  public defaultFunction: number = 0;
  public entityActive: Entidade;
  public entityId: number;
  private _activeUser$ = new BehaviorSubject<any>(undefined);

  constructor(private _store: Store<AppState>, private _destroyRef: DestroyRef) {
    this._store
      .select(AppSelectors.ActiveUser)
      .pipe(takeUntilDestroyed(this._destroyRef), notNull(), distinctUntilChanged(isEqual))
      .subscribe((activeUser) => {
        this._activeUser$.next(activeUser);
        this.entityActive = first(selectedEntityActive(activeUser));
        this.isNotEntity$.next(first(selectedEntityActive(activeUser))?.tipo !== 1);
      });

    this._store
      .select(AppSelectors.DefaultFunction)
      .pipe(takeUntilDestroyed(this._destroyRef), notNull(), distinctUntilChanged(isEqual))
      .subscribe((defaultFunction) => {
        this.defaultFunction = defaultFunction;
      });
  }

  @Input('application') public set setApplication(application) {
    this.application$.next(application);
  }

  public ngOnDestroy(): void {
    this.application$.complete();
  }

  public onPreviewer(application): boolean {
    return (
      !!application.previewer ||
      application.avaliacao.criado_por === this._activeUser$.value.usuario_id ||
      this.permissionView(application.avaliacao) ||
      [FunctionsEnum.desenvolvedorPortal].includes(this.defaultFunction)
    );
  }

  public activateStudentTab(entityId: number): void {
    this.entityId = entityId;
  }

  private permissionView(assessment): boolean {
    const options = {
      [1]: () => 'entidade_id',
      [2]: () => 'campo_id',
      [3]: () => 'uniao_id',
      [4]: () => 'editora_id',
      [5]: () => 'divisao_id',
    };
    const existePermissao = assessment.entidade_permissao.some(
      (permissao) => permissao[options[this.entityActive.tipo]()] === this.entityActive.id,
    );
    return existePermissao;
  }
}
