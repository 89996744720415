import { Injectable } from '@angular/core';
import { portalConst } from '@app/config';
import { ApiService } from '@app/core/services/api.service';
import { BookDetailResponse } from '@app/modules/educational-materials/pages/educational-materials-details/book.details.interface';
import { BookResponse } from '../models/books.model';
import { CrudService } from './crud.service';
import { Observable, map, switchMap, tap } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppSelectors } from '@app/store';
import { notNull } from '@app/shared';

@Injectable({
  providedIn: 'root',
})
export class BooksService extends CrudService<BookResponse> {
  private readonly _baseUrl = portalConst.api.didaticos;
  constructor(api: ApiService, private _store: Store) {
    super(api, `${portalConst.api.didaticos}/api/site/v1/livro`);
  }

  public getBook(livro_slug: string, req?: any) {
    return this.api.get<BookDetailResponse>(`${this.url}/${livro_slug}`, req);
  }

  public getBooks(data): Observable<any> {
    return this.api.get(`${this.url}`, { ...this.isPermission, ...data });
  }

  public bookFullTree(livroId: number): Observable<any> {
    const url = `${portalConst.api.didaticos}`;
    return this.api.get(`${url}/api/admin/v1/livros/${livroId}/summaries/full-tree`);
  }

  public matrixContent(padrao_disciplina_id?: number) {
    const url = `${portalConst.api.didaticos}`;
    return this.api.get(`${url}api/v1/categories/matrix-content/tree`, { padrao_disciplina_id });
  }

  // public verifyProduct(livro_id: number, voucher: string) {
  //   return this.api.get<any>(`${this.resourceQuery}/livro/${livro_id}/${voucher}`);
  // }

  private isPermission() {
    this._store.select(AppSelectors.ActiveUser).pipe(
      notNull(),
      switchMap((user) => user),
      map((user) => {
        // externo: user.escola_externa;
        // entidade_id: user.entidade_id;
        // campo_id: user.campo_id;
        // uniao_id: user.uniao_id;
        // editora_id: user.editora_id;
        // divisao_id: user.divisao_id;
      }),
    );
  }

  public summaryTree(summaryId: number): Observable<any> {
    return this.api.get(`${this._baseUrl}/api/v1/summaries/${summaryId}/tree`);
  }
}
