import { Injectable } from '@angular/core';
import { portalConst } from '@app/config';
import { notNull } from '@app/shared';
import { AppSelectors } from '@app/store';
import { AppState } from '@app/store/app.state';
import { Store } from '@ngrx/store';
import { format } from 'date-fns';
import { combineLatest, map, of } from 'rxjs';
import { catchError, finalize, switchMap, take } from 'rxjs/operators';
import { PaisEnum } from '../models';
import { MenuItem, MenuRef } from './menu';
import { LoadingService, UserService } from '../services';

@Injectable({ providedIn: 'root' })
export class AcademicMenu implements MenuRef {
  public identifier = 'academic';

  public readonly menu: MenuItem[] = [
    () => ({
      title: 'menu.academico',
      url: '/academico',
      icon: 'icon-academic',
      dimension: 'h-9 w-9',
      permission: [1, 2],
      show: this.store.select(AppSelectors.ActiveUser).pipe(
        notNull(),
        map((activeUser) => !!activeUser?.entidade_id),
      ),
    }),
  ];

  public readonly submenu: MenuItem[] = [
    () => ({
      title: 'breadcrumb.academico.apontamento-educacional',
      url: '/academico/apontamentos-educacionais',
      icon: 'icon-menu-educational',
      group: 'menu.academico',
      permission: [19, 33],
    }),
    () => ({
      title: 'breadcrumb.academico.apontamento-disciplinar',
      url: '/academico/apontamentos-disciplinares',
      icon: 'icon-menu-disciplinary',
      group: 'menu.academico',
      permission: [19, 34],
      show: this.store.select(AppSelectors.ActiveUser).pipe(
        notNull(),
        map((activeUser) => activeUser?.pais_id === PaisEnum.Brasil),
      ),
    }),
    () => ({
      title: 'breadcrumb.academico.ficha-deliberativa',
      url: '/academico/ficha-deliberativa',
      icon: 'icon-page',
      group: 'menu.academico',
      permission: [19, 45],
    }),
    () => ({
      title: 'breadcrumb.academico.modulo-professor',
      url: undefined,
      icon: 'icon-import-activity',
      group: 'menu.eclass',
      click: () => this.openAcademicUrl(),
      redirect: portalConst.web.integration7EduTeacher,
      show: this.store.select(AppSelectors.ActiveUser).pipe(
        notNull(),
        map((activeUser) => activeUser?.secretaria_sistema_id === 3),
      ),
    }),
    () => ({
      title: 'breadcrumb.eclass.titulo',
      url: ['/eclass', format(new Date(), 'yyyy-MM-dd')],
      icon: 'icon-item-edit',
      group: 'menu.academico',
      ignore: ['/eclass/lives', '/eclass/relatorios', '/eclass/observacoes', 'eclass/relatorio-geral'],
      permission: [19, 62],
      show: this.store.select(AppSelectors.ActiveUser).pipe(
        notNull(),
        map((activeUser) => activeUser?.pais_id !== PaisEnum.Brasil),
      ),
    }),
    () => ({
      title: 'breadcrumb.eclass.observacoes',
      url: '/eclass/observacoes',
      icon: 'icon-feedback-line',
      group: 'menu.academico',
      permission: [20, 35],
      show: this.store.select(AppSelectors.ActiveUser).pipe(
        notNull(),
        map((activeUser) => activeUser?.pais_id !== PaisEnum.Brasil),
      ),
    }),
    () => ({
      title: 'breadcrumb.aluno.apontamentos',
      url: '/academico/apontamentos-aluno',
      group: 'menu.academico',
      icon: 'icon-menu-notifications',
      permission: [19, 61],
      show: this.store.select(AppSelectors.ActiveUser).pipe(
        notNull(),
        map((activeUser) => activeUser?.pais_id !== PaisEnum.Brasil),
      ),
    }),
    () => ({
      title: 'breadcrumb.eclass.lives',
      url: '/eclass/lives',
      icon: 'icon-zoom',
      group: 'menu.academico',
      permission: [19, 33],
      show: combineLatest([this.store.select(AppSelectors.ActiveUser), this.store.select(AppSelectors.hasZoom)]).pipe(
        notNull(),
        map(([activeUser, hasZoom]) => hasZoom && activeUser?.pais_id !== PaisEnum.Brasil),
      ),
    }),
    () => ({
      title: 'breadcrumb.eclass.relatorio',
      url: '/eclass/relatorios',
      icon: 'icon-report-teachers',
      group: 'menu.academico',
      permission: [19, 33],
      show: this.store.select(AppSelectors.ActiveUser).pipe(
        notNull(),
        map((activeUser) => activeUser?.pais_id !== PaisEnum.Brasil),
      ),
    }),
    () => ({
      title: 'breadcrumb.eclass.relatorio-geral',
      url: '/eclass/relatorio-geral',
      icon: 'icon-report-general',
      group: 'menu.academico',
      permission: [19, 63],
    }),
  ];

  constructor(
    private store: Store<AppState>,
    private userService: UserService,
    private loadingService: LoadingService,
  ) {}

  private openAcademicUrl() {
    this.loadingService.start();
    this.store
      .select(AppSelectors.ActiveUser)
      .pipe(
        take(1),
        notNull(),
        switchMap(() =>
          this.userService.loginIatec().pipe(
            catchError(() => of(null)),
            finalize(() => this.loadingService.finish()),
          ),
        ),
      )
      .subscribe((url) => window.open(url, '_blank'));
  }
}
