import { ChangeDetectionStrategy, Component, DestroyRef } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FunctionsEnum } from '@app/core/models';
import { AppSelectors } from '@app/store';
import { AppState } from '@app/store/app.state';
import { Store } from '@ngrx/store';
import { Observable, filter, map } from 'rxjs';

@Component({
  selector: 'app-card-learning-trails-highlight',
  templateUrl: './card-learning-trails-highlight.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardLearningTrailsHighlightComponent {
  public image$: Observable<string>;

  constructor(private store: Store<AppState>, private destroyRef: DestroyRef) {
    this.image$ = this.store.select(AppSelectors.appFeature).pipe(
      takeUntilDestroyed(this.destroyRef),
      filter((store) => !!store.usuario_ativo),
      map(({ funcao_padrao, usuario_ativo }) => {
        if (funcao_padrao === FunctionsEnum.professorEnsinoMedio || usuario_ativo.nivel_id === 4) {
          return 'itinerario-formativo';
        }
        return 'projeto-replay';
      }),
      map((image) => `assets/images/learning-trails/destaque-${image}.jpg`),
    );
  }
}
