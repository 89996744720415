import { NgModule } from '@angular/core';
import { TemplateModule } from '../template/template.module';
import { SelectLevelComponent } from './select-level.component';

@NgModule({
  declarations: [SelectLevelComponent],
  imports: [TemplateModule],
  exports: [SelectLevelComponent],
})
export class SelectLevelModule {}
