export enum OriginEnum {
  All = 'all',
  Eclass = 'eclass',
  Trilhas = 'trilhas',
  Uc = 'trilhas-uc',
  Avaliacoes = 'avaliacoes',
  Outras = 'others',
}

export enum ReferenceEnum {
  Daily = 'daily',
  Activity = 'activity',
  Answers = 'answer',
  Review = 'review',
  Feedback = 'feedback',
  LearningTrails = 'learning_trails',
  CorporateUniversity = 'corporate_university',
}

export enum SocketType {
  Notification = 'NOTIFICATION',
}

export interface SocketMessage<T> {
  event: SocketType;
  data: T;
}

export interface Reference {
  id: number;
  type: ReferenceEnum;
  [key: string]: any;
}

export interface NotificationData {
  id: string;
  userId: number;
  createAt: Date;
  origin: OriginEnum;
  message: string;
  extra: {
    citedId: number;
    reference: Reference;
  };
  isRead: boolean;
}

export interface NotificationSummary {
  data: NotificationData[];
  unread: number;
}

export interface NotificationPage {
  data: NotificationData[];
  nextPage?: string;
}

export type NotificationCounter = {
  [k in OriginEnum]: number;
};

export const NOTIFICATION_STYLE = {
  [OriginEnum.All]: {
    border: 'border-gray-500',
    background: 'bg-gray-500 text-white',
    color: 'text-gray-500',
    hover: 'hover:bg-gray-300',
  },
  [OriginEnum.Eclass]: {
    border: 'border-modules-eclass-500',
    background: 'bg-modules-eclass-500 text-white',
    color: 'text-modules-eclass-500',
    hover: 'hover:bg-modules-eclass-400',
  },
  [OriginEnum.Trilhas]: {
    border: 'border-modules-learning-trails-500',
    background: 'bg-modules-learning-trails-500 text-white',
    color: 'text-modules-learning-trails-500',
    hover: 'hover:bg-modules-learning-trails-400',
  },
  [OriginEnum.Uc]: {
    border: 'border-modules-corporate-university-500',
    background: 'bg-modules-corporate-university-500 text-white',
    color: 'text-modules-corporate-university-500',
    hover: 'hover:bg-modules-corporate-university-400',
  },
  [OriginEnum.Avaliacoes]: {
    border: 'border-modules-assessments-manager-500',
    background: 'bg-modules-assessments-manager-500 text-white',
    color: 'text-modules-assessments-manager-500',
    hover: 'hover:bg-modules-assessments-manager-400',
  },
  [OriginEnum.Outras]: {
    border: 'border-gray-500',
    background: 'bg-gray-500 text-white',
    color: 'text-gray-500',
    hover: 'hover:bg-gray-300',
  },
};

export const NOTIFICATION_REFERENCE = {
  [ReferenceEnum.Daily]: {
    title: 'notificacoes.titulo.daily',
    link: (data: NotificationData) => {
      const { date, groupId, entityId, subjectId } = data.extra.reference;
      return { router: `/eclass/${date}/resumo`, queryParams: { groupId, subjectId, entityId } };
    },
  },
  [ReferenceEnum.Activity]: {
    title: 'notificacoes.titulo.activity',
    link: (data: NotificationData) => {
      const { date, groupId, entityId, subjectId } = data.extra.reference;
      return { router: `/eclass/${date}/resumo`, queryParams: { groupId, subjectId, entityId } };
    },
  },
  [ReferenceEnum.Answers]: {
    title: 'notificacoes.titulo.answers',
    link: (data: NotificationData) => {
      const { date, groupId, entityId, subjectId } = data.extra.reference;
      return { router: `/eclass/${date}/resumo`, queryParams: { groupId, subjectId, entityId } };
    },
  },
  [ReferenceEnum.Review]: {
    title: 'notificacoes.titulo.review',
    link: (data: NotificationData) => {
      const { date, groupId, entityId, subjectId } = data.extra.reference;
      return { router: `/eclass/${date}/resumo`, queryParams: { groupId, subjectId, entityId } };
    },
  },
  [ReferenceEnum.Feedback]: {
    title: 'notificacoes.titulo.feedback',
    link: (data: NotificationData) => {
      const { date, groupId, entityId, subjectId } = data.extra.reference;
      return { router: `/eclass/${date}/resumo`, queryParams: { groupId, subjectId, entityId } };
    },
  },
  [ReferenceEnum.LearningTrails]: {
    title: 'notificacoes.titulo.learning-trails',
    link: (data: NotificationData) => {
      const { eventId, courseSlug } = data.extra.reference;
      return { router: `/trilhas-de-aprendizagem/cursos`, queryParams: { eventId, courseSlug } };
    },
  },
  [ReferenceEnum.CorporateUniversity]: {
    title: 'notificacoes.titulo.corporate-university',
    link: (data: NotificationData) => {
      const { eventId, courseSlug } = data.extra.reference;
      return { router: `/universidade-corporativa/cursos`, queryParams: { eventId, courseSlug } };
    },
  },
};
