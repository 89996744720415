import { ChangeDetectionStrategy, Component, ElementRef, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DatepickerComponent } from '@app/shared/modules/template/components/datepicker/datepicker.component';
import { Popover, Strategy } from '@app/shared/modules/template/components/popover';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-modal-upload-files',
  templateUrl: './modal-upload-files.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalUploadFilesComponent implements OnInit {
  public readonly strategy = Strategy;
  public readonly range = [...Array(50).keys()];
  public items = ['Item 1', 'Item 2', 'Item 3'];
  public form: FormGroup;
  public datepickerResult$: Observable<Date>;
  public readOnly = true;
  public ckeditorCtrl = new FormControl('<p>Hello, world!</p>', [Validators.required]);

  constructor(private formBuilder: FormBuilder, private popover: Popover) {}

  public ngOnInit() {
    this.form = this.formBuilder.group({
      input: [undefined, [Validators.required, Validators.minLength(3)]],
      datepicker: [undefined, Validators.required],
      textarea: [undefined, Validators.required],
      select: undefined,
      ['multi-select']: [undefined, Validators.required],
      feedback: 1,
    });
  }

  public onForm(form: FormGroup) {
    form.markAllAsTouched();
  }

  public onDatepicker(elementRef: ElementRef) {
    this.datepickerResult$ = this.popover.open<Date>(DatepickerComponent, {
      positionStrategy: [Strategy.BOTTOM],
      elementRef,
    }).closed;
  }

  public onSetFeedback(value: number) {
    this.form.get('feedback').setValue(value);
  }
}
