<div class="flex p-5">
  <div class="w-full text-center text-xl font-bold" translate>geral.servicos</div>
  <button type="button" app-icon-button dialogClose class="!p-0">
    <svg-icon class="h-8 w-8 select-none" src="assets/icons/icon-close-fine.svg"></svg-icon>
  </button>
</div>

<div app-modal-content class="h-auto max-h-[90vh] w-[90vw] overflow-y-auto lg:max-h-[60rem] lg:w-[80rem]">
  <ng-container *ngIf="{ data: services$ | async } as services">
    <ng-container *ngIf="!!services.data; else loadTemplate">
      <div class="flex flex-wrap justify-center gap-14 pb-5">
        <ng-container *ngFor="let service of services.data">
          <ng-container *ngIf="isUrl(service.link); else buttonTemplate">
            <div class="flex w-[10rem] flex-col items-center gap-4">
              <a
                [href]="service.link"
                target="_blank"
                app-flat-button
                class="group w-fit !rounded-full !shadow-md transition-all duration-300 hover:!shadow-theme-300"
              >
                <div
                  [ngClass]="service.style.base"
                  class="flex !rounded-full p-5 transition-all duration-300 group-hover:text-white"
                >
                  <svg-icon [ngClass]="service.style.icon" class="flex h-16 w-16" [src]="service.icon"></svg-icon>
                </div>
              </a>
              <span class="text-center">{{ service.popover | translate }}</span>
            </div>
          </ng-container>
          <ng-template #buttonTemplate>
            <div class="flex w-[10rem] flex-col items-center gap-4">
              <button
                type="button"
                app-flat-button
                class="group w-fit !rounded-full !shadow-md transition-all duration-300 hover:!shadow-theme-300"
                (click)="onOpen(service)"
              >
                <div
                  [ngClass]="service.style.base"
                  class="flex !rounded-full p-5 transition-all duration-300 group-hover:text-white"
                >
                  <svg-icon [ngClass]="service.style.icon" class="flex h-16 w-16" [src]="service.icon"></svg-icon>
                </div>
              </button>
              <span class="text-center">{{ service.popover | translate }}</span>
            </div>
          </ng-template>
        </ng-container>
      </div>
      <app-jumbotron *ngIf="!services.data.length">{{ 'servicos.vazio' | translate }}</app-jumbotron>
    </ng-container>
    <ng-template #loadTemplate>
      <div class="h-[20rem] md:h-[495px]">
        <app-skeleton></app-skeleton>
      </div>
    </ng-template>
  </ng-container>
</div>
