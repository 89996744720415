import { Dialog } from '@angular/cdk/dialog';
import { ChangeDetectionStrategy, Component, DestroyRef, OnDestroy, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NotificationHistoryService, WebSocketService } from '@app/core';
import { NotificationData } from '@app/core/models';
import { notNull, switchDelay } from '@app/shared';
import { PopoverRef } from '@app/shared/modules/template/components/popover';
import { AppSelectors } from '@app/store';
import { AppState } from '@app/store/app.state';
import { Store } from '@ngrx/store';
import { Observable, Subject, catchError, map, of, startWith, switchMap } from 'rxjs';
import { ModalNotificationComponent } from '../modal-notification/modal-notification.component';

@Component({
  templateUrl: './popover-notification.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PopoverNotificationComponent implements OnInit, OnDestroy {
  public notifications: NotificationData[] = [];
  public load$: Observable<boolean>;
  public store$: Observable<AppState>;
  private _load$ = new Subject<boolean>();

  constructor(
    private _dialog: Dialog,
    private _destroy: DestroyRef,
    private _store: Store<AppState>,
    private _popoverRef: PopoverRef<any>,
    private _webSocketService: WebSocketService,
    private _notificationHistoryService: NotificationHistoryService,
  ) {}

  public ngOnInit() {
    this.loadData();
    this.load$ = this._load$.asObservable().pipe(switchDelay(), startWith(true));
    this.store$ = this._store.select(AppSelectors.appFeature).pipe(takeUntilDestroyed(this._destroy), notNull());
  }

  public ngOnDestroy() {
    this._load$.complete();
    this._notificationHistoryService.refresh();
  }

  public onSeeAll() {
    this._popoverRef.close();
    this._dialog.open(ModalNotificationComponent);
  }

  public onAfterRead() {
    this._popoverRef.close();
  }

  private loadData() {
    this._webSocketService.message$
      .pipe(
        takeUntilDestroyed(this._destroy),
        catchError(() => of({})),
        switchMap(() => this._notificationHistoryService.summary()),
        map(({ data }) => data || []),
      )
      .subscribe((res) => {
        this.notifications = res;
        this._load$.next(false);
      });
  }
}
