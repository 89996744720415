<div app-modal-content class="w-[80vw] !p-0 !pb-3">
  <app-modal-header>
    <div class="flex">
      <div
        class="h-16 w-16 flex-shrink-0 self-start rounded-tl-lg bg-green-700 p-2 text-base-status-warning [box-shadow:0px_2px_4px_0px_rgba(0,_0,_0,_0.15)]"
      >
        <svg-icon src="assets/icons/icon-new-course.svg"></svg-icon>
      </div>
      <hr app-modal-divider />
      <div class="flex items-center gap-2">
        <h1 translate class="text-xl font-medium">trilhas.estudio.curso.certificado</h1>
      </div>
    </div>
  </app-modal-header>
  <div class="h-[80vh] w-full overflow-auto p-5">
    <div *ngIf="certificados$ | async as certificados; else elseTemplate" class="flex flex-wrap">
      <div *ngFor="let certificado of certificados" class="w-full p-4 md:w-1/2 lg:w-1/3 xl:w-1/4">
        <div class="flex h-16 items-center justify-between rounded-t-xl bg-theme-500 px-4 py-4 shadow shadow-black/40">
          <h3 class="overflow-hidden text-[14px] font-medium text-theme-contrast" [title]="certificado.titulo">
            {{ certificado.titulo }}
          </h3>
          <button type="button" (click)="edit(certificado)">
            <svg-icon class="mr-4 flex w-7 text-theme-contrast" src="assets/icons/icon-edit.svg"></svg-icon>
          </button>
        </div>
        <div class="m-form" [ngClass]="{ boxMaskCertificado: safeData?.canSelect }">
          <div class="mask-certificado-preview !rounded-b-xl" (click)="onSelect(certificado)">
            <p class="text-lg" translate>{{ safeData.labelCertificado }}</p>
          </div>
          <div class="relative rounded-b-xl bg-[#fefefe] p-3 shadow shadow-black/40">
            <app-preview-certificado [data]="onFormat(certificado)"></app-preview-certificado>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ng-template #elseTemplate>
    <div class="flex h-[80vh] items-center justify-center !p-0" app-card-content>
      <app-skeleton *ngIf="loading$ | async" class="h-full w-full"></app-skeleton>
    </div>
  </ng-template>
  <div app-modal-footer class="justify-end pt-5">
    <button
      type="button"
      (click)="onCloseAdd()"
      app-outline-button
      translate
      class="border-[#11324b] text-[14px] text-[#00324a] hover:[&:not([disabled])]:bg-[#11324b] hover:[&:not([disabled])]:text-theme-contrast"
    >
      <svg-icon
        class="mr-2 flex h-5 w-5 justify-center group-hover:text-theme-contrast"
        src="assets/icons/icon-plus.svg"
      ></svg-icon>
      trilhas.estudio.curso.adicionar-certificado
    </button>
  </div>
</div>
