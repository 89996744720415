<app-modal-header *ngIf="data.title">
  <h1 class="text-2xl font-semibold" translate>{{ data.title }}</h1>
</app-modal-header>
<div app-modal-content class="grid min-h-[100px] grid-cols-[auto_1fr] items-center gap-2">
  <svg-icon
    *ngIf="isType(type.Warning)"
    src="assets/icons/icon-alert-fill.svg"
    class="self-start w-20 text-base-status-warning"
  ></svg-icon>
  <svg-icon
    *ngIf="isType(type.Success)"
    src="assets/icons/icon-checked-circle.svg"
    class="self-start w-20 text-base-status-success"
  ></svg-icon>
  <div class="text-base" *ngIf="data.message; else innerTemplate" translate>{{ data.message }}</div>
  <ng-template #innerTemplate>
    <div class="text-base" [innerHTML]="data.innerHtml"></div>
  </ng-template>
</div>
<div app-modal-footer class="justify-end gap-2">
  <ng-container *ngIf="data.confirm; else okTemplate">
    <button
      app-button
      type="button"
      class="px-5 mr-2 font-semibold text-white uppercase bg-base-status-success"
      [dialogClose]="true"
    >
      {{ textPositiveButton | translate }}
    </button>
    <button
      app-outline-button
      type="button"
      class="border-none px-2 font-semibold uppercase text-red-500 hover:[&:not([disabled])]:bg-red-500 hover:[&:not([disabled])]:text-white"
      dialogClose
    >
      {{ textNegativeButton | translate }}
    </button>
  </ng-container>
  <ng-template #okTemplate>
    <button app-button type="button" class="px-4 font-semibold text-white uppercase bg-base-status-info" dialogClose>
      {{ 'geral.ok' | translate }}
    </button>
  </ng-template>
</div>
