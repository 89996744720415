<app-popover>
  <div app-popover-header>Atalhos</div>
  <hr app-divider />
  <div app-popover-content>
    <div>
      <h6 class="font-bold">{{ 'gestor-avaliacoes.alunos' | translate }}</h6>
      <div class="flex items-center">
        <span>
          {{ 'gestor-avaliacoes.atalho.proximo' | translate }}
        </span>
        <div class="pl-[2px]">
          <svg-icon
            class="flex h-5 w-5"
            src="assets/icons/icon-arrow-long-right.svg"
            alt="Materiais Didaticos"
          ></svg-icon>
        </div>
      </div>
      <div class="flex items-center">
        <span>{{ 'gestor-avaliacoes.atalho.anterior' | translate }} </span>
        <div class="pl-[2px]">
          <svg-icon
            class="flex h-5 w-5"
            src="assets/icons/icon-arrow-long-left.svg"
            alt="Materiais Didaticos"
          ></svg-icon>
        </div>
      </div>
    </div>
    <div>
      <h6 class="font-bold">{{ 'gestor-avaliacoes.questoes' | translate }}</h6>
      <div class="flex items-center">
        <span>{{ 'gestor-avaliacoes.atalho.proxima' | translate }} </span>
        <div class="pl-[2px]">
          <svg-icon
            class="flex h-5 w-5"
            src="assets/icons/icon-arrow-long-down.svg"
            alt="Materiais Didaticos"
          ></svg-icon>
        </div>
      </div>
      <div class="flex items-center">
        <span>{{ 'gestor-avaliacoes.atalho.anterior' | translate }} </span>
        <div class="pl-[2px]">
          <svg-icon class="flex h-5 w-5" src="assets/icons/icon-arrow-long-up.svg" alt="Materiais Didaticos"></svg-icon>
        </div>
      </div>
    </div>
    <div>
      <h6 class="font-bold">{{ 'gestor-avaliacoes.notas' | translate }}</h6>
      <span>{{ 'gestor-avaliacoes.atalho.informar-nota' | translate }}</span>
      <br />
      <span>{{ 'gestor-avaliacoes.atalho.nota-maxima' | translate }}</span>
      <br />
      <span>{{ 'gestor-avaliacoes.atalho.nota-meio-certo' | translate }}</span>
      <br />
      <span>{{ 'gestor-avaliacoes.atalho.nota-errada' | translate }}</span>
      <br />
      <span>{{ 'gestor-avaliacoes.atalho.salvar-nota' | translate }}</span>
      <br />
      <span>{{ 'gestor-avaliacoes.atalho.informar-comentario' | translate }}</span>
    </div>
  </div>
</app-popover>
