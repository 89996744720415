import { ConnectedOverlayPositionChange } from '@angular/cdk/overlay';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, Optional } from '@angular/core';
import { Subject, map, takeUntil } from 'rxjs';
import { PopoverRef } from './index';
import { PositionArrow, PositionBorder } from './indicator-factory';

const SIZE = '6px';
const PADDING = '-20px';
interface Indicator {
  border: any;
  indicator: any;
  arrow: any;
}
@Component({
  selector: 'app-popover',
  templateUrl: './popover.component.html',
  styleUrls: ['./popover.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PopoverComponent implements OnDestroy {
  public pointer: Indicator;
  private _destroy$ = new Subject<void>();

  constructor(@Optional() public popoverRef: PopoverRef, private _cdref: ChangeDetectorRef) {
    this.loadListeners();
  }

  public ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

  private loadListeners() {
    const config = this.popoverRef.overlayRef.getConfig();
    const positionChanges$ = (config.positionStrategy as any)['positionChanges'];
    positionChanges$.pipe(takeUntil(this._destroy$), map(this.style)).subscribe((change: Indicator) => {
      this.pointer = change;
      this._cdref.detectChanges();
    });
  }

  private style = ({ connectionPair }: ConnectedOverlayPositionChange): Indicator => {
    const border = new PositionBorder(connectionPair).position;
    const { arrow, align } = PositionArrow[border];
    return {
      border: { [`border-${border}-width`]: SIZE },
      indicator: { [border]: PADDING, ...align(connectionPair) },
      arrow,
    };
  };
}
