import { ModuleWithProviders } from '@angular/core';
import { environment } from '@env/environment';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

export const storageKeys = {
  AUTHENTICATION_TOKEN: 'token',
  ACCESS_TOKEN: 'access_token',
  ENTITY: 'entity',
  ORIGIN: 'origin',
  ACADEMIC: 'academic',
  COURSE: 'course',
};
export const languageKeys = ['pt-br', 'en', 'es'];

export const ACADEMIC_YEAR_KEY = { year: 'academic-year' };
export const HEADER_KEY = {
  entidade: 'entity',
  campo: 'association',
  uniao: 'union',
  editora: 'publisher',
  divisao: 'division',
};

export const StoreDevTools: ModuleWithProviders<StoreDevtoolsModule>[] = !environment.production
  ? [StoreDevtoolsModule.instrument({ name: 'Portal', maxAge: 20, logOnly: environment.production })]
  : [];
