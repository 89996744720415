import { Action, createReducer, on } from '@ngrx/store';
import { cloneDeep } from 'lodash';
import * as AppActions from './app.actions';
import { AppState, initialState } from './app.state';

const AppReducer = createReducer(
  initialState,
  on(AppActions.DataLoadedReducer, (state, data) => cloneDeep({ ...state, ...data })),
  on(AppActions.TokenReducer, (state, { token, access_token }) =>
    cloneDeep({ ...state, token, access_token: access_token || state.access_token }),
  ),
  on(AppActions.TokenClearReducer, () => cloneDeep({ ...initialState })),
  on(AppActions.LanguageReducer, (state, language) =>
    cloneDeep({
      ...state,
      usuario_ativo: { ...state.usuario_ativo, lingua: language.sigla, lingua_id: language.id },
      funcoes: state.funcoes.map((funcao) => ({
        ...funcao,
        escola_aluno: funcao.escola_aluno.map((escola) => ({ ...escola, lingua: language.sigla })),
      })),
    }),
  ),
  on(AppActions.ProductReducer, (state, product) =>
    cloneDeep({
      ...state,
      usuario_ativo: {
        ...state.usuario_ativo,
        produtos: [...(state.usuario_ativo.produtos || []), product],
      },
    }),
  ),
  on(AppActions.IVCAReducer, (state, { ivca }) => cloneDeep({ ...state, ivca })),
);

export function reducer(state: AppState, action: Action) {
  return AppReducer(state, action);
}
