import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { portalConst } from '@app/config';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CountryService {
  private http: HttpClient;
  private readonly baseUrl = portalConst.api.usuario;

  constructor(private handler: HttpBackend) {
    this.http = new HttpClient(this.handler);
  }

  public index(): Observable<any[]> {
    return this.http.get<any[]>(`${this.baseUrl}/v1/paises`);
  }
}
