import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  forwardRef,
} from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { UserService } from '@app/core/services';
import { compareLike, switchDelay } from '@app/shared/utils';
import { first, flattenDeep, isNil, size } from 'lodash';
import { BehaviorSubject, Observable, catchError, exhaustMap, of, take, tap } from 'rxjs';

@Component({
  selector: 'app-select-group',
  templateUrl: './select-group.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectGroupComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectGroupComponent implements AfterViewInit, OnDestroy, ControlValueAccessor {
  @Input() public multiple = false;
  @Input() public clearable = false;
  @Input() public searchable = false;
  @Input() public strictUser = false;
  @Input() public automaticSelectDefault = true;
  @Input() public placeholder = 'geral.selecionar-turma';
  @Output() public changeGroup = new EventEmitter();
  public ctrl = new FormControl([]);
  public data$: Observable<any[]>;
  public loading$: Observable<boolean>;
  public searchFn = (term: string, item: any) => compareLike(term, item.description);
  private _disabled = false;
  private _refresh$ = new BehaviorSubject<any>(undefined);
  private _loading$ = new BehaviorSubject<boolean>(true);

  constructor(private userService: UserService) {
    this.loading$ = this._loading$.pipe(switchDelay());
  }

  @Input()
  public set entityId(id: number) {
    this._refresh$.next({ ...this._refresh$.value, entityId: id });
  }

  @Input()
  public set defaultGradeId(id: number) {
    this._refresh$.next({ ...this._refresh$.value, defaultGradeId: id });
  }

  public ngAfterViewInit() {
    this.loadListeners();
  }

  public ngOnDestroy() {
    this._refresh$.complete();
    this._loading$.complete();
  }

  public writeValue(obj: any) {
    this.ctrl.setValue(obj);
  }

  public registerOnChange(fn: any) {
    this.changeValue = fn;
  }

  public registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  public setDisabledState?(isDisabled: boolean) {
    this._disabled = isDisabled;
    if (isDisabled) {
      this.ctrl.disable();
    } else {
      this.ctrl.enable();
    }
  }

  public onFocus() {
    this.onTouched();
  }

  public onChange(result: any, elements: any[]) {
    const safe = flattenDeep([result])
      .map((value) => value?.id)
      .filter((value) => !isNil(value));
    const value = this.multiple ? safe : first(safe);
    const defaultValue = this.multiple ? [] : undefined;
    this.changeValue(value || defaultValue);
    this.changeGroup.emit({ result, elements });
  }

  public selectAll(select: any) {
    this.data$.pipe(take(1)).subscribe((data) => {
      if (data.length > 0) {
        const allIds = data.map((item) => item.id).filter((id) => id);
        this.ctrl.setValue(allIds);
        this.onChange(data, allIds);
        select.close();
      }
    });
  }

  private loadGroups(entityId: number, defaultGradeId?: number): Observable<any[]> {
    if (entityId) {
      return this.userService.getGroups(entityId, { strictUser: this.strictUser, padrao_serie_id: defaultGradeId });
    }
    return of([]);
  }

  private loadListeners() {
    this.data$ = this._refresh$.pipe(
      tap(() => this._loading$.next(true)),
      tap(() => this.ctrl.disable()),
      exhaustMap(({ entityId, defaultGradeId }) =>
        this.loadGroups(entityId, defaultGradeId).pipe(catchError(() => of([]))),
      ),
      tap(() => this._disabled || this.ctrl.enable()),
      tap(this.selectDefault),
      tap(() => this._loading$.next(false)),
    );
  }

  private selectDefault = (res: any[] = []) => {
    if (size(res) === 1 && this.automaticSelectDefault) {
      const value = first(res);
      this.ctrl.setValue(value);
      this.onChange(value, res);
    } else {
      const value = this.multiple
        ? this.ctrl.value?.map((groupId) => res.find((g) => g.id === groupId)).filter((g) => g)
        : res.find((g) => g.id === this.ctrl.value);
      if (!value) {
        this.ctrl.reset();
      }
      this.onChange(value, res);
    }
  };

  private onTouched = () => true;
  private changeValue = (_: any) => true;
}
