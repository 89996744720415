import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { portalConst } from '@app/config';

@Injectable({ providedIn: 'root' })
export class LoginService {
  private url = portalConst.api.login;

  constructor(private api: ApiService) {}

  public updateToken(data: any): Observable<any> {
    return this.api.post<any>(`${this.url}/logar-como`, data);
  }
}
