<div class="group/scroll flex w-full">
  <button
    *ngIf="isEnableScroll && !isAtScrollEndLeft"
    app-icon-button
    class="invisible !absolute left-0 top-1/2 z-90 h-1/2 translate-y-[-50%] !rounded-l-none bg-gray-200 opacity-0 shadow-md shadow-black/40 transition duration-300 group-hover/scroll:visible group-hover/scroll:opacity-100"
    (click)="onScrollLeft()"
  >
    <svg-icon class="h-5 w-5 select-none" src="assets/icons/icon-arrow-left-fine.svg"></svg-icon>
  </button>
  <div class="no-scrollbar w-0 flex-grow overflow-x-auto overflow-y-hidden scroll-smooth" #content>
    <ng-content></ng-content>
  </div>
  <button
    *ngIf="isEnableScroll && !isAtScrollEndRight"
    app-icon-button
    class="invisible !absolute right-0 top-1/2 z-90 h-1/2 translate-y-[-50%] !rounded-r-none bg-gray-200 opacity-0 shadow-md shadow-black/40 transition duration-300 group-hover/scroll:visible group-hover/scroll:opacity-100"
    (click)="onScrollRight()"
  >
    <svg-icon class="h-5 w-5 select-none" src="assets/icons/icon-arrow-right-fine.svg"></svg-icon>
  </button>
</div>
