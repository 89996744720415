import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NotificationService } from '@app/core';
import {
  AssessmentApplicationMode,
  AssessmentOrigin,
  AssessmentType,
  AssessmentWeightType,
  GenericModel,
} from '@app/core/models';
import { SCORE_CONFIG } from '@app/modules/eclass/components/modal-daily/base';
import { notNull, safeEmptyList, selectedEntityActive } from '@app/shared';
import { AppSelectors } from '@app/store';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { clone, first, isEqual } from 'lodash';
import { Observable, combineLatest, distinctUntilChanged, filter, take } from 'rxjs';
import { AssessmentService } from '../../../../../../core/services/assessment.service';
import { FormBase } from '../../base';
import { ModalNewAssessmentService } from '../../modal-new-assessment.service';
import { ModalNewAssessmentActions, ModalNewAssessmentSelectors } from '../../store';
import { ModalNewAssessmentState } from '../../store/modal-new-assessment.state';

enum TipoForm {
  Avaliacao = 'avaliacao',
  Aplicacao = 'aplicacao',
}

@Component({
  selector: 'app-modal-new-assessment-general-settings',
  templateUrl: './modal-new-assessment-general-settings.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalNewAssessmentGeneralSettingsComponent extends FormBase {
  public readonly weightType = AssessmentWeightType;
  public scoreConfig = clone(SCORE_CONFIG);
  public form: FormGroup;
  public tentativaCount = 0;
  public managed$: Observable<any>;
  public isSchool = true;
  public isDivisionId = false;
  public isBrasil$: Observable<boolean>;

  public tipos = [
    {
      id: AssessmentType.Avaliacao,
      label: this._translateService.instant('gestor-avaliacoes.tipos.avaliacao'),
    },
    { id: AssessmentType.Simulado, label: this._translateService.instant('gestor-avaliacoes.tipos.simulado') },
    {
      id: AssessmentType.ListaExercicios,
      label: this._translateService.instant('gestor-avaliacoes.tipos.lista-exercicios'),
    },
    { id: AssessmentType.Quiz, label: this._translateService.instant('gestor-avaliacoes.tipos.quiz') },
  ];

  public applicationModes: GenericModel[] = [
    {
      id: AssessmentApplicationMode.Online,
      description: this._translateService.instant('gestor-avaliacoes.modos.online'),
    },
    {
      id: AssessmentApplicationMode.Impressa,
      description: this._translateService.instant('gestor-avaliacoes.modos.impressa'),
    },
    {
      id: AssessmentApplicationMode.Hibrido,
      description: this._translateService.instant('gestor-avaliacoes.modos.hibrido'),
    },
  ];

  public pesoTipos = [
    {
      id: AssessmentWeightType.Porcentagem,
      label: this._translateService.instant('gestor-avaliacoes.modos.percentual-acerto'),
    },
    { id: AssessmentWeightType.Peso, label: this._translateService.instant('gestor-avaliacoes.modos.peso') },
  ];

  constructor(
    private _destroyRef: DestroyRef,
    private _formBuilder: FormBuilder,
    private _translateService: TranslateService,
    private _assessmentService: AssessmentService,
    private _changeDetectionRef: ChangeDetectorRef,
    private _store: Store<ModalNewAssessmentState>,
    private _notificationService: NotificationService,
    private _modalNewAssessmentService: ModalNewAssessmentService,
  ) {
    super();
    this.isBrasil$ = this._store.select(AppSelectors.isBrasil).pipe(takeUntilDestroyed(this._destroyRef));
    this._modalNewAssessmentService.registerComponent(this);
    this.getManaged();
    this.initForm();

    const activeUser = this._store
      .select(AppSelectors.ActiveUser)
      .pipe(takeUntilDestroyed(this._destroyRef), notNull(), take(1));
    const state = this._store
      .select(ModalNewAssessmentSelectors.selectState)
      .pipe(takeUntilDestroyed(this._destroyRef), take(1));
    combineLatest({ activeUser, state }).subscribe(({ activeUser, state }) => {
      this.isDivisionId = first(selectedEntityActive(activeUser))?.tipo === 5;
      this.isSchool = !!activeUser?.entidade_id;

      let avaliacao = {
        ...state.avaliacao,
        origem: AssessmentOrigin.GDA,
        pais_id: this.isDivisionId ? (state.avaliacao.uuid ? state.avaliacao.pais_id : undefined) : activeUser.pais_id,
        editora_id: this.onGetFieldControlAvaliacao('editora_id').value || activeUser.editora_id,
      };

      if (!!state.extra?.trilha_elemento) {
        avaliacao = { ...avaliacao, origem: AssessmentOrigin.Trilha };
      }

      if (!this.isSchool) {
        this.onGetFieldControlAvaliacao('tipo_gerenciada').setValidators(Validators.required);
      }

      this._store.dispatch(ModalNewAssessmentActions.UpdateStateReducer({ value: { avaliacao, hasChanges: false } }));

      this.form.get(TipoForm.Avaliacao).patchValue(avaliacao, { emitEvent: false });
    });

    this._store
      .select(ModalNewAssessmentSelectors.selectState)
      .pipe(
        takeUntilDestroyed(this._destroyRef),
        filter(({ avaliacao }) => !!avaliacao.uuid || !!avaliacao.parent_uuid),
        take(1),
      )
      .subscribe(({ avaliacao, aplicacao }) => {
        avaliacao = { ...this.form.get(TipoForm.Avaliacao).value, ...avaliacao };
        aplicacao = { ...this.form.get(TipoForm.Aplicacao).value, ...aplicacao };

        this.form.get(TipoForm.Avaliacao).patchValue(avaliacao, { emitEvent: false });
        this.form.get(TipoForm.Aplicacao).patchValue(aplicacao, { emitEvent: false });

        this._store.dispatch(
          ModalNewAssessmentActions.UpdateStateReducer({ value: { avaliacao, aplicacao, hasChanges: false } }),
        );

        if (aplicacao.tentativa_count > 0) {
          this.tentativaCount = aplicacao.tentativa_count;
          this.onGetFieldControlAvaliacao('peso_tipo').disable({ emitEvent: false });
          this.onGetFieldControlAvaliacao('peso').disable({ emitEvent: false });
        }
      });

    this._store
      .select(ModalNewAssessmentSelectors.selectState)
      .pipe(
        takeUntilDestroyed(this._destroyRef),
        filter(({ avaliacao }) => !!avaliacao.uuid),
      )
      .subscribe(({ avaliacao }) => {
        this.onGetFieldControlAvaliacao('uuid').setValue(avaliacao.uuid, { emitEvent: false });
      });

    this._store
      .select(ModalNewAssessmentSelectors.selectAssessmentOrigin)
      .pipe(
        takeUntilDestroyed(this._destroyRef),
        distinctUntilChanged(isEqual),
        filter((origin) => origin === AssessmentOrigin.Trilha),
      )
      .subscribe(() => {
        this.form.get(TipoForm.Avaliacao).patchValue(
          {
            tipo: AssessmentType.Quiz,
            peso_tipo: AssessmentWeightType.Porcentagem,
          },
          { emitEvent: false },
        );
        this.onGetFieldControlAplicacao('modo_aplicacao').setValue(AssessmentApplicationMode.Online, {
          emitEvent: false,
        });

        this.onGetFieldControlAvaliacao('tipo').disable({ emitEvent: false });
        this.onGetFieldControlAvaliacao('peso_tipo').disable({ emitEvent: false });
        this.onGetFieldControlAvaliacao('tipo_gerenciada').disable({ emitEvent: false });
        this.onGetFieldControlAplicacao('modo_aplicacao').disable({ emitEvent: false });
      });
  }

  public onGetFieldControlAvaliacao(field: string): AbstractControl {
    return this.form.get(TipoForm.Avaliacao).get(field);
  }

  public onGetFieldControlAplicacao(field: string): AbstractControl {
    return this.form.get(TipoForm.Aplicacao).get(field);
  }

  public isInvalidControlAvaliacao(ctrlName: string): boolean {
    const control = this.form.get(TipoForm.Avaliacao).get(ctrlName);
    return this.isInvalidControl(control);
  }

  public isInvalidControlAplicacao(ctrlName: string): boolean {
    const control = this.form.get(TipoForm.Aplicacao).get(ctrlName);
    return this.isInvalidControl(control);
  }

  public getManaged() {
    this.managed$ = this._assessmentService.managedTypes().pipe(safeEmptyList());
  }

  public override isValid(): boolean {
    this.form.markAllAsTouched();
    this._changeDetectionRef.detectChanges();

    if (this.form.invalid) {
      this._notificationService.error('geral.preencha-campos');
      return false;
    }

    return this.form.valid;
  }

  private initForm() {
    this.form = this._formBuilder.group({
      [TipoForm.Avaliacao]: this._formBuilder.group({
        uuid: undefined,
        titulo: [undefined, [Validators.required, Validators.maxLength(100)]],
        tipo: [AssessmentType.Avaliacao, Validators.required],
        editora_id: undefined,
        peso_tipo: [AssessmentWeightType.Porcentagem, Validators.required],
        peso: undefined,
        tipo_gerenciada: undefined,
        pais_id: [undefined, Validators.required],
        status: 1,
      }),
      [TipoForm.Aplicacao]: this._formBuilder.group({
        modo_aplicacao: [AssessmentApplicationMode.Online, Validators.required],
      }),
    });

    this.form
      .get(TipoForm.Avaliacao)
      .get('peso_tipo')
      .valueChanges.subscribe((value) => {
        let validators = undefined;
        if (value !== AssessmentWeightType.Porcentagem) {
          validators = [Validators.required, Validators.min(0.1)];
          if (!this.form.get(TipoForm.Avaliacao).value.peso) {
            this.form.get(TipoForm.Avaliacao).get('peso').setValue(10);
          }
        }

        this.form.get(TipoForm.Avaliacao).get('peso').setValidators(validators);
        this.form.get(TipoForm.Avaliacao).get('peso').updateValueAndValidity({ emitEvent: false });
      });

    this.form.valueChanges.pipe(takeUntilDestroyed(this._destroyRef)).subscribe(({ avaliacao, aplicacao }) => {
      this._store.dispatch(ModalNewAssessmentActions.UpdateStateReducer({ value: { avaliacao, aplicacao } }));
    });
  }

  private isInvalidControl(control: AbstractControl) {
    return control.touched && control.invalid;
  }
}
