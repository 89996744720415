import { Injectable } from '@angular/core';
import { portalConst } from '@app/config';
import { Observable } from 'rxjs';
import { ApiService, Resp } from './api.service';
import { CrudService } from './crud.service';

@Injectable({
  providedIn: 'root',
})
export class LearningTrailsElementsService extends CrudService<any> {
  constructor(api: ApiService) {
    super(api, `${portalConst.api.trilhas}/v1/trilhas/elemento`);
  }

  public checkVideo(data: any): Observable<Resp<any>> {
    return this.api.post(`${portalConst.api.trilhas}/v1/trilhas/elementos/anexos/video`, data);
  }

  public updateOrdem(
    sectionId: number,
    elementId: number,
    currentOrder: number,
    previousOrder: number,
  ): Observable<Resp<any>> {
    return this.api.get(
      `${portalConst.api.trilhas}/v1/trilhas/elementos/ordem/${sectionId}/${elementId}/${currentOrder}/${previousOrder}`,
    );
  }
}
