import { Injectable } from '@angular/core';
import { notNull } from '@app/shared';
import { AppSelectors } from '@app/store';
import { AppState } from '@app/store/app.state';
import { environment } from '@env/environment';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap, take, tap } from 'rxjs/operators';
import { SocketMessage } from '../models';
import { portalConst } from '@app/config';

const CHANGE_USER_EVENT = 'change_user';
@Injectable({
  providedIn: 'root',
})
export class WebSocketService<T = any> {
  public message$: Observable<SocketMessage<T>>;
  private _token$: Observable<string>;
  private _socket$ = new BehaviorSubject(null);
  private _message$ = new BehaviorSubject<SocketMessage<T>>(null);
  private _retry = 0;

  constructor(private _store: Store<AppState>) {
    this.message$ = this._message$.asObservable();
    this.loadListeners();
  }

  public next(data: any) {
    this._socket$.pipe(take(1)).subscribe((ws) => ws?.send(JSON.stringify(data)));
  }

  private loadListeners() {
    this._token$ = this._store.select(AppSelectors.appFeature).pipe(
      filter(() => !environment.production),
      map(({ token }) => token),
      notNull(),
      distinctUntilChanged(),
    );

    this._token$.pipe(switchMap((token) => this.socketHandler(this._socket$, token))).subscribe((ws) => {
      ws.onmessage = (event) => this._message$.next(JSON.parse(event.data));
      ws.onerror = (error) => this._message$.error(error);
    });
  }

  private socketHandler(socket$: BehaviorSubject<WebSocket>, token: string): Observable<WebSocket> {
    this._retry = 0;
    return socket$.pipe(
      take(1),
      tap((ws) => ws?.close(1000, CHANGE_USER_EVENT)),
      switchMap(() => this.connect(socket$, token)),
    );
  }

  private connect(socket$: BehaviorSubject<WebSocket>, token: string): Observable<WebSocket> {
    socket$.next(this.factory(socket$, token));
    return socket$.asObservable();
  }

  private factory(socket$: BehaviorSubject<WebSocket>, token: string) {
    const ws = new WebSocket(`${portalConst.wss.notification}/?t=${token}`);
    ws.onclose = (event) => {
      if ([1001, 1006].includes(event.code)) {
        this._retry = 0;
      }
      if (event.reason !== CHANGE_USER_EVENT && this._retry < 3) {
        this._retry++;
        setTimeout(() => socket$.next(this.factory(socket$, token)), 5000 * (this._retry + 1));
      }
    };
    return ws;
  }
}
