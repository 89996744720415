import { AfterViewInit, Component, EventEmitter, forwardRef, Input, OnDestroy, Output } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SubjectService } from '@app/core/services/subject.service';
import { compareLike, sanitize, switchDelay } from '@app/shared/utils';
import { first, flattenDeep, isEmpty, isNil, size } from 'lodash';
import { BehaviorSubject, map, Observable, of, tap } from 'rxjs';
import { catchError, exhaustMap } from 'rxjs/operators';

export interface SelectSubjectData {
  systemType: number;
  groupId: number;
  gradeId: number;
}
@Component({
  selector: 'app-select-subject',
  templateUrl: './select-subject.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectSubjectComponent),
      multi: true,
    },
  ],
})
export class SelectSubjectComponent implements AfterViewInit, OnDestroy, ControlValueAccessor {
  @Input() public multiple = false;
  @Input() public clearable = false;
  @Input() public searchable = false;
  @Input() public strictUser = false;
  @Input() public placeholder = 'geral.selecionar-disciplina';
  @Output() public changeSubject = new EventEmitter();
  public ctrl = new FormControl([]);
  public data$: Observable<any>;
  public loading$: Observable<boolean>;
  public searchFn = (term: string, item: any) => compareLike(term, item.descricao);
  private _disabled = false;
  private _refresh$ = new BehaviorSubject<SelectSubjectData>(undefined);
  private _loading$ = new BehaviorSubject<boolean>(true);

  constructor(private subjectService: SubjectService) {
    this.loading$ = this._loading$.pipe(switchDelay());
  }

  @Input()
  public set filter(data: SelectSubjectData) {
    this._refresh$.next(data);
  }

  public ngAfterViewInit() {
    this.loadListeners();
  }

  public ngOnDestroy() {
    this._refresh$.complete();
    this._loading$.complete();
  }

  public writeValue(obj: any): void {
    this.ctrl.setValue(obj);
  }

  public registerOnChange(fn: any) {
    this.changeValue = fn;
  }

  public registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  public setDisabledState?(isDisabled: boolean) {
    this._disabled = isDisabled;
    if (isDisabled) {
      this.ctrl.disable();
    } else {
      this.ctrl.enable();
    }
  }

  public onFocus() {
    this.onTouched();
  }

  public onChange(result: any, elements: any[]) {
    const safe = flattenDeep([result])
      .map((value) => value?.id)
      .filter((value) => !isNil(value));
    const value = this.multiple ? safe : first(safe);
    const defaultValue = this.multiple ? [] : undefined;
    this.changeValue(value || defaultValue);
    this.changeSubject.emit({ result, elements });
  }

  private loadListeners() {
    this.data$ = this._refresh$.pipe(
      tap(() => this._loading$.next(true)),
      tap(() => this.ctrl.disable()),
      exhaustMap((data) => this.loadSubject(data).pipe(catchError(() => of([])))),
      map((subject) =>
        subject.map((disciplina) => ({
          id: disciplina?.id,
          descricao: disciplina?.descricao,
          padrao_disciplina_id: disciplina?.padrao_disciplina_id,
        })),
      ),
      tap(() => this._disabled || this.ctrl.enable()),
      tap(this.selectDefault),
      tap(() => this._loading$.next(false)),
    );
  }

  private loadSubject(data: SelectSubjectData): Observable<any[]> {
    if (isEmpty(data)) {
      return of([]);
    }

    if (data.systemType !== 1) {
      return this.subjectService.subjectByGroup(data.groupId, { strictUser: this.strictUser });
    }
    return this.subjectService.subjectByGrade(data.gradeId, { strictUser: this.strictUser });
  }

  private selectDefault = (res: any[] = []) => {
    if (isEmpty(res)) {
      this.onChange(null, res);
      return;
    }
    const value = size(res) > 1 ? res.find((s) => s.id === this.ctrl.value) : first(res);
    if (value) {
      this.ctrl.setValue(value.id);
    } else {
      this.ctrl.reset();
    }

    this.onChange(value, res);
  };

  private onTouched = () => true;
  private changeValue = (_: any) => true;
}
