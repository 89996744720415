<div class="h-full bg-white p-6">
  <ng-container *ngIf="data?.application">
    <div class="grid w-full p-5">
      <span class="font-bold"> {{ data.application.avaliacao.titulo }} </span>
      <div *ngIf="candEdit$ | async" class="mt-5 flex justify-between">
        <div class="flex">
          <span class="md:txt-base text-sm font-light" translate> gestor-avaliacoes.publicada</span>
          <svg-icon
            app-tooltip
            [tooltip]="'gestor-avaliacoes.ativar-status' | translate"
            class="ml-3 flex h-5 w-5"
            src="assets/icons/icon-help.svg"
          ></svg-icon>
        </div>
        <app-switch [formControl]="statusCtrl"></app-switch>
      </div>
    </div>
    <hr app-divider />
    <span class="flex p-5 font-bold" translate> gestor-avaliacoes.mais-informacoes </span>
    <div class="grid h-auto p-5">
      <table app-table>
        <tbody app-table-body>
          <tr>
            <td app-table-cell translate>gestor-avaliacoes.tipo.data-inicio</td>
            <td app-table-cell>{{ data.application.data_inicio | date : 'dd/MM/yyyy HH:mm' }}</td>
          </tr>
          <tr
            *ngIf="data.application.data_fim && data.application.modo_aplicacao !== assessmentApplicationMode.Impressa"
          >
            <td app-table-cell translate>gestor-avaliacoes.tipo.data-fim</td>
            <td app-table-cell>{{ data.application.data_fim | date : 'dd/MM/yyyy HH:mm' }}</td>
          </tr>
          <tr>
            <td app-table-cell translate>gestor-avaliacoes.tipo.duracao-maxima</td>
            <td app-table-cell>{{ data.application.tempo_maximo || '-' }}</td>
          </tr>
          <tr>
            <td app-table-cell translate>gestor-avaliacoes.questoes</td>
            <td app-table-cell>{{ data.application.qtd_questao }}</td>
          </tr>
          <tr>
            <td app-table-cell translate>gestor-avaliacoes.aplicacao.turmas</td>
            <td app-table-cell>
              <ng-container *ngIf="!!targetGroups?.length; else emptyTemplate">
                <div *ngFor="let groupName of targetGroups">
                  {{ groupName }}
                </div>
              </ng-container>
            </td>
          </tr>
          <tr>
            <td app-table-cell translate>gestor-avaliacoes.modo-aplicacao</td>
            <td app-table-cell>
              <ng-container [ngSwitch]="data.application.modo_aplicacao">
                <ng-container *ngFor="let mode of applicationModes">
                  <ng-container *ngSwitchCase="mode.id">
                    {{ mode.description }}
                  </ng-container>
                </ng-container>
              </ng-container>
            </td>
          </tr>
          <tr>
            <td app-table-cell translate>gestor-avaliacoes.alunos-especificos</td>
            <td app-table-cell>
              <ng-container *ngIf="!!targetUsers?.length; else emptyTemplate">
                <div *ngFor="let userName of targetUsers">
                  {{ userName }}
                </div>
              </ng-container>
            </td>
          </tr>
          <ng-container *ngIf="!!targetDefaultGrades?.length">
            <tr>
              <td app-table-cell translate>gestor-avaliacoes.serie</td>
              <td app-table-cell class="grid max-h-[20rem] overflow-y-auto">
                <div *ngFor="let name of targetDefaultGrades">
                  {{ name }}
                </div>
              </td>
            </tr>
          </ng-container>
          <ng-container *ngIf="!!targetEntities?.length">
            <tr>
              <td app-table-cell translate>gestor-avaliacoes.unidades</td>
              <td app-table-cell class="grid max-h-[20rem] overflow-y-auto">
                <div *ngFor="let name of targetEntities">
                  <span class="mb-3 flex">
                    <svg-icon
                      *ngIf="targetEntities.length > 1"
                      class="mr-1 flex h-6 w-1 text-gray-700"
                      src="assets/icons/icon-ellipse.svg"
                    ></svg-icon
                    >{{ name }}
                  </span>
                </div>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>

    <hr app-divider class="mb-5" />
    <div class="grid h-auto">
      <app-accordion *ngIf="data.application.avaliacao.orientacao_inicial">
        <app-accordion-item>
          <div app-accordion-header class="flex font-bold" translate>gestor-avaliacoes.orientacoes-preparo</div>
          <div
            app-accordion-content
            class="p-5"
            [innerHTML]="data.application.avaliacao.orientacao_inicial | safe : 'html'"
          ></div>
        </app-accordion-item>
      </app-accordion>
      <app-accordion *ngIf="data.application.avaliacao.orientacoes">
        <app-accordion-item>
          <div app-accordion-header class="flex font-bold" translate>gestor-avaliacoes.orientacoes-execucao</div>
          <div
            app-accordion-content
            class="p-5"
            [innerHTML]="data.application.avaliacao.orientacoes | safe : 'html'"
          ></div>
        </app-accordion-item>
      </app-accordion>
    </div>
  </ng-container>
</div>
<ng-template #emptyTemplate> - </ng-template>
