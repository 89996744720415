import { ChangeDetectionStrategy, Component, DestroyRef, ElementRef, Input, OnDestroy } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AssessmentApplicationService } from '@app/core';
import {
  AssessmentAnnulTypeEnum,
  AssessmentLevelContent,
  AssessmentManagedType,
  AssessmentWeightType,
} from '@app/core/models';
import { notNull, safeEmpty, selectedEntityActive } from '@app/shared';
import { Popover, Strategy } from '@app/shared/modules/template/components/popover';
import { AppSelectors } from '@app/store';
import { AppState } from '@app/store/app.state';
import { Store } from '@ngrx/store';
import { compact, first, isEmpty, merge, uniq } from 'lodash';
import { BehaviorSubject, combineLatest, map, of, switchMap, tap } from 'rxjs';
import { PopoverAnnulQuestionComponent } from '../popover-annul-question/popover-annul-question.component';
import { ReportApiRoutesEnum } from '@app/core/models/report-api-routes.enum';

@Component({
  selector: 'app-tab-questions-map',
  templateUrl: './tab-questions-map.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabQuestionsMapComponent implements OnDestroy {
  public readonly assessmentWeightType = AssessmentWeightType;
  public readonly strategy = Strategy;
  public readonly levelContent = AssessmentLevelContent;
  public isDivision: boolean = false;
  public data$ = new BehaviorSubject<any>(undefined);
  public loading$ = new BehaviorSubject<boolean>(false);
  public application$ = new BehaviorSubject<any>(undefined);
  private _modeloId: number;
  private _activeUser$ = new BehaviorSubject<any>(undefined);

  constructor(
    private _evaluationService: AssessmentApplicationService,
    private _popover: Popover,
    private destroy: DestroyRef,
    private _store: Store<AppState>,
    private _destroyRef: DestroyRef,
  ) {
    this._store
      .select(AppSelectors.ActiveUser)
      .pipe(takeUntilDestroyed(this.destroy), notNull())
      .subscribe((user) => {
        this._activeUser$.next(user);
        this.isDivision = (first(selectedEntityActive(user)) as any)?.tipo === 5;
      });

    const activeUser$ = this._store.select(AppSelectors.ActiveUser).pipe(takeUntilDestroyed(this.destroy), notNull());
    const application$ = this.application$.pipe(takeUntilDestroyed(this.destroy), notNull());
    combineLatest({
      application: application$,
      activeUser: activeUser$,
    })
      .pipe(
        takeUntilDestroyed(this._destroyRef),
        tap(({ activeUser }) => {
          this.loading$.next(true);
          this._modeloId = activeUser.pais_id;
        }),
        switchMap(({ application }) => this._evaluationService.evaluationMap(application.uuid).pipe(safeEmpty())),
        map((data: any) => data.avaliacao.avaliacao_questoes),
      )
      .subscribe((data) => {
        this.data$.next(data);
        this.loading$.next(false);
      });
  }

  public isEmpty(items: any): boolean {
    return isEmpty(items);
  }

  public get isPAAEB(): boolean {
    return (
      this.application$.value?.avaliacao?.tipo_gerenciada === AssessmentManagedType.PAAEB ||
      this.application$.value?.avaliacao?.tipo_gerenciada === AssessmentManagedType.PAEE
    );
  }

  @Input()
  public set application(value) {
    this.application$.next(value);
  }

  public ngOnDestroy(): void {
    this.data$.complete();
    this.application$.complete();
    this.loading$.complete();
  }

  public toLetter(index: number): string {
    return String.fromCharCode(65 + index);
  }

  public onUpdateQuestionAnula(data: any, question: any): void {
    const currentDataQuest = this.data$.getValue();
    const updatedQuestoes = currentDataQuest.map((q: any) => {
      if (q.questao.uuid === question.questao.uuid) {
        return {
          ...q,
          anulado: data.anulado,
          anulado_motivo: data.anulado_motivo,
        };
      }
      return q;
    });
    this.data$.next(updatedQuestoes);
  }

  public onOpenPopover(elementRef: ElementRef, questao: any): void {
    this._popover
      .open(PopoverAnnulQuestionComponent, {
        positionStrategy: [this.strategy.LEFT],
        elementRef,
        data: {
          aplicacao_uuid: this.application$.value.uuid,
          question: questao.questao,
          type: AssessmentAnnulTypeEnum.Question,
        },
      })
      .closed.pipe(notNull())
      .subscribe((res: any) => {
        this.onUpdateQuestionAnula(res, questao);
      });
  }

  public onExport() {
    const data = {
      api_route: ReportApiRoutesEnum.ReportMap,
    };

    this._evaluationService.reportExport(this.application$.value.uuid, data, 'mapa-avaliacao');
  }

  public getComponentNames(categories: any[], summaries: []): string[] {
    const categoriesDisciplines = categories.map((category) => category.component?.name);
    const summaryDisciplines = summaries.map(
      (summary: any) =>
        summary.livro?.contextos?.find((context) => context.modelo_id === this._modeloId)?.padrao_disciplina?.descricao,
    );

    return uniq(compact(merge(categoriesDisciplines, summaryDisciplines)));
  }

  public onAnular(questao): boolean {
    return this._activeUser$.value.usuario_id === questao.criado_por;
  }
}
