import { Injectable } from '@angular/core';
import { Answer } from '../models';
import { CrudService } from './crud.service';
import { ApiService, Resp } from './api.service';
import { portalConst } from '@app/config';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EclassAnswerService extends CrudService<Answer> {
  private readonly _baseUrl = `${portalConst.api.eclass}/v1/answers`;
  constructor(api: ApiService) {
    super(api, `${portalConst.api.eclass}/v1/answers`);
  }

  public review(answerId: string, data: any): Observable<Resp<Answer>> {
    return this.api.put<Answer>(`${this._baseUrl}/review/${answerId}`, data);
  }

  public reviewEmpty(activityId: string, studentId: number, data: any): Observable<Resp<Answer>> {
    return this.api.post<Answer>(`${this._baseUrl}/review-empty/${activityId}/${studentId}`, data);
  }

  public viewed(answerId: string): Observable<Resp<string>> {
    return this.api.get<string>(`${this._baseUrl}/viewed/${answerId}`, {});
  }
}
