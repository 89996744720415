import { DialogRef } from '@angular/cdk/dialog';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Page } from '@app/core/services/api.service';
import { FilesService } from '@app/core/services/files.service';
import { PAGE_DEFAULT, PageData, safeEmptyList, switchDelay } from '@app/shared';
import { PaginationComponent } from '@app/shared/modules/pagination/pagination.component';
import { AppSelectors } from '@app/store';
import { Store } from '@ngrx/store';
import { isEqual } from 'lodash';
import {
  BehaviorSubject,
  Observable,
  Subject,
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  startWith,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs';

@Component({
  templateUrl: './modal-files.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalFilesComponent implements OnInit, OnDestroy {
  @ViewChild(PaginationComponent, { static: false }) private pagination: PaginationComponent;
  public file$: Observable<Page<any>>;
  public form: FormGroup;
  public categories$: Observable<any>;
  public load$: Observable<boolean>;
  private _destroy$ = new Subject<void>();
  private _load$ = new Subject<boolean>();
  private _page$ = new BehaviorSubject<any>(PAGE_DEFAULT);

  constructor(
    private _store: Store,
    private _formBuilder: FormBuilder,
    private _filesService: FilesService,
    private _dialogRef: DialogRef<boolean>,
  ) {
    this.load$ = this._load$.asObservable().pipe(switchDelay(), startWith(true));
  }

  public ngOnInit() {
    this.initForm();
    this.loadData();
  }

  public ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
    this._load$.complete();
  }

  public onClose() {
    this._dialogRef.close(false);
  }

  public onChangePage(data: PageData) {
    this._page$.next(data);
  }

  public icon(file: any): string {
    return `assets/images/files/${file.extension}.svg`;
  }

  public onCategory(category?: number) {
    this.form.get('category').setValue(category);
  }

  private initForm() {
    this.form = this._formBuilder.group({
      term: undefined,
      category: undefined,
    });
  }

  private loadData() {
    const store$ = this._store.select(AppSelectors.appFeature).pipe(filter((store) => !!store?.usuario_ativo));
    const formValue$ = this.form.valueChanges.pipe(
      tap(() => this.pagination.onFirst()),
      startWith(this.form.value),
    );
    const page$ = this._page$.pipe(
      distinctUntilChanged(isEqual),
      map((page) => ({ page: page.current, qtd: page.size })),
    );
    this.file$ = combineLatest([store$, formValue$, page$]).pipe(
      tap(() => this._load$.next(true)),
      debounceTime(500),
      takeUntil(this._destroy$),
      switchMap(([store, data, page]) => {
        const params = { ...page, category_id: data.category?.id, term: !!data.term ? data.term : undefined };
        return this._filesService.listFiles(store.usuario_ativo.entidade_id, store.funcao_padrao, params);
      }),
      tap(() => this._load$.next(false)),
    );
    this.categories$ = store$.pipe(
      takeUntil(this._destroy$),
      switchMap((store) => this._filesService.loadCategory(store.usuario_ativo.entidade_id).pipe(safeEmptyList())),
    );
  }
}
