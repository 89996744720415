import { Injectable } from '@angular/core';
import { ApiService, Resp } from './api.service';
import { CrudService } from './crud.service';
import { portalConst } from '@app/config';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AnnouncementService extends CrudService<any> {
  public announcement$ = new BehaviorSubject<any>([]);

  constructor(api: ApiService) {
    super(api, `${portalConst.api.eclass}/v1/communications`);
  }

  public getAnnouncements(entidade_id: number, page_size: number, page?: number): Observable<Resp<any>> {
    return this.api.get(`${this.url}`, { entity_id: entidade_id, page_size, page });
  }

  public getAnnouncement(announcement_id: number): Observable<Resp<any>> {
    return this.api.get(`${this.url}/${announcement_id}`);
  }

  public saveAnnoucement(req: any): Observable<Resp<any>> {
    return this.api.post(`${this.url}`, req);
  }

  public updateAnnoucement(announcement_id: number, req: any): Observable<Resp<any>> {
    return this.api.put(`${this.url}/${announcement_id}`, req);
  }

  public deleteAnnoucement(announcement_id: number): Observable<Resp<any>> {
    return this.api.delete(`${this.url}/${announcement_id}`);
  }
}
