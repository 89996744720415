export const STANDARD = [
  'bold',
  'italic',
  'underline',
  'strikethrough',
  'subscript',
  'superscript',
  '|',
  'heading',
  '|',
  'fontSize',
  '|',
  'uploadImage',
  'mediaEmbed',
  'insertTable',
  'horizontalLine',
  'specialCharacters',
  'blockQuote',
  '|',
  'bulletedList',
  'numberedList',
  '|',
  'outdent',
  'alignment',
  'indent',
  '|',
  'link',
  'undo',
  'redo',
  '|',
  'findAndReplace',
];

export const FULL = [
  'bold',
  'italic',
  'underline',
  'strikethrough',
  'code',
  'subscript',
  'superscript',
  '|',
  'heading',
  '|',
  'fontSize',
  'fontFamily',
  'fontColor',
  'fontBackgroundColor',
  'highlight',
  'removeFormat',
  '|',
  'uploadImage',
  'mediaEmbed',
  'insertTable',
  'horizontalLine',
  'specialCharacters',
  '|',
  'bulletedList',
  'numberedList',
  '|',
  'blockQuote',
  '|',
  'outdent',
  'alignment',
  'indent',
  '|',
  'link',
  'undo',
  'redo',
  '|',
  'findAndReplace',
  '|',
  'codeBlock',
  'htmlEmbed',
  'showBlocks',
  'sourceEditing',
  'MathPlugin',
];

export const REDUCED = [
  'bold',
  'italic',
  'underline',
  'strikethrough',
  'subscript',
  'superscript',
  '|',
  'undo',
  'redo',
];
