import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  HostListener,
  Inject,
  Input,
  ViewChild,
} from '@angular/core';
import { RADIO_GROUP, RadioGroupComponent } from './radio-group.component';
import { Observable, map } from 'rxjs';

@Component({
  selector: 'app-radio',
  templateUrl: './radio.component.html',
  host: { class: 'flex cursor-pointer gap-1' },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RadioComponent {
  @Input() public value: any;
  @Input() public disabled: boolean;
  @ViewChild('radio', { static: true }) public radio: ElementRef<HTMLInputElement>;
  public checked$: Observable<boolean>;

  constructor(@Inject(RADIO_GROUP) public radioGroup: RadioGroupComponent) {
    this.checked$ = this.radioGroup.value.pipe(map((value) => value === this.value));
  }

  @HostBinding('class') public get styles() {
    let styles = '';
    if (this.isDisabled()) {
      styles += '!cursor-not-allowed opacity-60';
    }
    return styles;
  }

  @HostListener('click')
  public onClick() {
    if (!this.isDisabled()) {
      this.radioGroup.send(this.value);
      this.radio.nativeElement.checked = true;
    }
  }

  public isDisabled(): boolean {
    return this.disabled || this.radioGroup.disabled;
  }
}
