import { Attachment } from './eclass-attachment.model';
import { Feedback } from './eclass-feedback.model';

export interface AnswerSummary {
  activity_id: string;
  completed: number;
  concluded_at: Date;
  date: Date;
  date_end: Date;
  group_id: number;
  group_name: string;
  student_id: number;
  student_name: string;
  subject: string;
  subject_id: number;
  title: string;
  total: number;
}

export interface Answer extends AnswerSummary {
  id: string;
  user_id: number;
  student_id: number;
  answer: string;
  score: number;
  academic_id: number;
  app: number;
  concluded: number;
  concluded_by: number;
  concluded_group: number;
  revised: number;
  revised_by: number;
  revised_at: string;
  resubmission_date: string;
  resubmission_by: number;
  viewed_at: string;
  deleted_at: string;
  created_at: string;
  updated_at: string;
  data_entrega: string;
  feedback: Feedback[];
  attachments: Attachment[];
  allow: boolean;
}
