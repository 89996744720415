import { Injectable } from '@angular/core';
import { portalConst } from '@app/config';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class IntegrationService {
  constructor(private api: ApiService) {}

  public superPro(req?: any): Observable<any> {
    return this.api.get(`${portalConst.api.usuario}/v1/integra-super-pro`, req);
  }

  public estuda(): Observable<any> {
    return this.api.get(`${portalConst.api.usuario}/v1/integra-estuda`);
  }

  public enem(): Observable<any> {
    return this.api.get(`${portalConst.api.usuario}/v1/integra-estuda`, { isEnem: true });
  }

  public faama(): Observable<any> {
    return this.api.get(`${portalConst.api.integrations}/portal/integra-faama`);
  }

  public getStudentSchedule(req): Observable<any> {
    return this.api.get(`${portalConst.api.integrations}/portal/student-schedule`, req);
  }

  public getStudentAbsences(req): Observable<any> {
    return this.api.get(`${portalConst.api.integrations}/portal/student-absences`, req);
  }

  public getStudentGrades(req): Observable<any> {
    return this.api.get(`${portalConst.api.integrations}/portal/student-grades`, req);
  }

  public getClassMeeting(req): Observable<any> {
    return this.api.get(`${portalConst.api.integrations}/portal/class-meetings`, req);
  }

  public getStudentAssignments(req): Observable<any> {
    return this.api.get(`${portalConst.api.integrations}/portal/student-assignments`, req);
  }

  public getStudentOccurrences(req): Observable<any> {
    return this.api.get(`${portalConst.api.integrations}/portal/student-occurrences`, req);
  }
}
