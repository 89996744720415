import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

export const EMPTY = 'assets/images/empty-avatar.png';

@Component({
  selector: 'app-user-photo',
  templateUrl: './user-photo.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserPhotoComponent {
  @Input() public src: string;
  @Input() public width: string = 'w-full';
  @Input() public height: string = 'h-full';
  public readonly empty = EMPTY;

  public image(src: string): string {
    return src || EMPTY;
  }
}
