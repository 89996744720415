import { Directive, Inject, Input, OnDestroy, Optional, TemplateRef } from '@angular/core';
import { SidenavData, SidenavDirection, SidenavDirective, SidenavRef } from './sidenav';
import { SIDENAV_GROUP, SidenavGroupComponent } from './sidenav-group.component';

@Directive({
  selector: '[app-sidenav-end]',
})
export class SidenavEndDirective extends SidenavDirective implements OnDestroy {
  @Input() public override panelWidth;
  @Input() public override closedAnchor;
  @Input() public override componentRef;
  @Input() public override showBackground;
  @Input() public override outerClick;
  @Input() public override opened;
  @Input() public override mode;
  @Input() public override data;
  @Input() public override buttonAnchor;
  @Input() public override bgColor;
  @Input() public override iconAnchor;

  constructor(@Optional() templateRef: TemplateRef<any>, @Inject(SIDENAV_GROUP) sidenavGroup: SidenavGroupComponent) {
    super(templateRef, sidenavGroup, SidenavDirection.End);
    sidenavGroup.setEnd(this);
  }

  @Input()
  public set update(root: SidenavData) {
    if (root) {
      this.sidenavGroup.sideEnd(root);
    } else {
      this.sidenavGroup.destroyEnd();
    }
  }

  public override register(root: SidenavRef) {
    this.sidenavGroup.registerEnd(root);
  }

  public ngOnDestroy() {
    this.sidenavGroup.destroyEnd();
  }
}
