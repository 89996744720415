import { Injectable } from '@angular/core';
import { ParamMap, Router } from '@angular/router';
import { ACADEMIC_YEAR_KEY } from '@app/config';
import { DailyService } from '@app/core/services';
import { buildEntities, helperAcademicYear, notNull, safeEmpty, safeEmptyList, switchDelay } from '@app/shared';
import { AppSelectors } from '@app/store';
import { AppState } from '@app/store/app.state';
import { Store } from '@ngrx/store';
import { format } from 'date-fns';
import { first, isEmpty, isEqual } from 'lodash';
import { BehaviorSubject, Observable, Subject, combineLatest, of, switchMap } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, shareReplay, startWith, take, tap } from 'rxjs/operators';
import { helperItemGroup } from '../../shared/utils/daily';
import { AcademicTree, ItemGroup } from '../models/academic-tree';

export interface EclassFilter {
  date: Date;
  entityId: number;
  subjectId: number;
  groupId: number;
}

export interface EclassResponse {
  academicTree: AcademicTree;
  search: EclassFilter;
  activeUser: any;
}

export interface EclassResult extends AcademicTree {
  activeUser: any;
  entities: any[];
}

@Injectable({
  providedIn: 'root',
})
export class EclassFilterService {
  public loading$: Observable<any>;
  public academicTree$: Observable<EclassResult>;
  public selected$: Observable<any>;
  public refresh$: Observable<void>;
  public academicYear$: Observable<boolean>;
  public filter$: Observable<EclassFilter>;
  private _refresh$ = new Subject<void>();
  private _loading$ = new BehaviorSubject(true);
  private _filter$ = new BehaviorSubject<EclassFilter>(null);
  private _academicYear$ = new BehaviorSubject<boolean>(false);

  constructor(private _router: Router, private store: Store<AppState>, private dailyService: DailyService) {
    this.refresh$ = this._refresh$.asObservable().pipe(shareReplay(1));
    this.academicYear$ = this._academicYear$.asObservable().pipe(shareReplay(1));
    this.loading$ = this._loading$.asObservable().pipe(switchDelay(), shareReplay(1));
    this.filter$ = this._filter$.asObservable().pipe(shareReplay(1));
    this.loadDaily();
    this.loadSelected();
  }

  public loadAcademicYear(param: Observable<ParamMap>) {
    const date$ = param.pipe(map((data: any) => data?.params?.date));
    const activeUser$ = this.store.select(AppSelectors.ActiveUser);
    combineLatest([date$, activeUser$])
      .pipe(map(([date, activeUser]) => helperAcademicYear(date, activeUser) === activeUser?.ano_letivo))
      .subscribe((value) => this._academicYear$.next(value));
  }

  public load(data: EclassFilter) {
    this._filter$.next(data);
  }

  public clear() {
    this._filter$.next(null);
  }

  public update(data: any) {
    const path = this.extractPathRoute(this._router.url);
    const { date, ...query } = { ...this._filter$.value, ...(data as EclassFilter) };
    this._router.navigate(['/eclass/', format(date, 'yyyy-MM-dd'), path], {
      replaceUrl: true,
      queryParams: query,
    });
  }

  public hardRefresh() {
    this._refresh$.next();
    this.loading(true);
  }

  private loadDaily() {
    const activeUser$ = this.store.select(AppSelectors.ActiveUser).pipe(
      notNull(),
      tap(() => this.loading(true)),
    );
    const filter$ = this.filter$.pipe(
      map((filter) => {
        if (!!filter) {
          return { date: format(filter.date, 'yyyy-MM-dd'), entity_id: filter.entityId };
        }
        return null;
      }),
      distinctUntilChanged(isEqual),
    );
    const refresh$ = this.refresh$.pipe(startWith(null));
    this.academicTree$ = combineLatest([filter$, activeUser$, refresh$]).pipe(
      tap(() => this.loading(true)),
      debounceTime(100),
      switchMap(([filter]) => this.loadAcademicTree(filter)),
      tap(() => this.loading(false)),
      shareReplay(1),
    );
    combineLatest([this.academicTree$, this.filter$])
      .pipe(filter(([academicTree, filter]) => !!academicTree && !!filter))
      .subscribe(([academicTree, filter]) => {
        this.loadEntity(filter, academicTree);
        this.loadFilter(filter, academicTree);
      });
  }

  private loading(value: boolean) {
    this._loading$.next(value);
  }

  private loadSelected() {
    const filter$ = this.filter$.pipe(
      distinctUntilChanged((previous, current) => isEqual(this.filterCompare(previous), this.filterCompare(current))),
    );
    const refresh$ = this.refresh$.pipe(startWith(null));
    const activeUser$ = this.store.select(AppSelectors.ActiveUser);
    const daily$ = combineLatest([filter$, activeUser$, refresh$]).pipe(
      switchMap(([filter, activeUser]) => {
        if (!!filter?.groupId && !!filter?.subjectId) {
          const date = format(filter.date, 'yyyy-MM-dd');
          return this.dailyService
            .day(
              date,
              { group_id: filter.groupId, subject_id: filter.subjectId, entity_id: filter.entityId },
              {
                [ACADEMIC_YEAR_KEY.year]: helperAcademicYear(filter.date, activeUser),
              },
            )
            .pipe(
              safeEmptyList(),
              map((res) => ({ res, filter })),
            );
        }
        return of(null);
      }),
    );
    this.selected$ = combineLatest([daily$, this.academicTree$]).pipe(
      map(([data, academicTree]) => {
        if (!!data && !!academicTree) {
          const header = this.helperSelected(data.filter, academicTree);
          return !!header ? { dailies: data.res, header } : null;
        }
        return null;
      }),
      shareReplay(1),
    );
  }

  private filterCompare(filter: EclassFilter): any {
    return {
      date: format(filter.date, 'yyyy-MM-dd'),
      entityId: Number(filter.entityId),
      subjectId: Number(filter.subjectId),
      groupId: Number(filter.groupId),
    };
  }

  private helperSelected(filter: EclassFilter, academicTree: EclassResult): any {
    const subject = academicTree.subjects.find(
      ({ id, group_id }) => id === Number(filter.subjectId) && group_id === Number(filter.groupId),
    );
    if (subject) {
      return {
        entities: academicTree.entities,
        entity: academicTree.entities.find(({ id }) => id === Number(filter.entityId)),
        level: academicTree.levels.find(({ id }) => id === subject.level_id),
        group: academicTree.groups.find(({ id }) => id === Number(filter.groupId)),
        date: filter.date,
        subject,
      };
    }
    return null;
  }

  private loadAcademicTree(data: any): Observable<EclassResult> {
    if (data) {
      return this.store.select(AppSelectors.appFeature).pipe(
        filter((store) => !!store.usuario_ativo),
        take(1),
        switchMap((store) =>
          this.getAcademicTree(data, store).pipe(
            map((academicTree) => ({
              ...academicTree,
              entities: buildEntities(store),
              activeUser: store.usuario_ativo,
            })),
          ),
        ),
      );
    }
    return of(null);
  }

  private getAcademicTree(data: any, store: AppState): Observable<AcademicTree> {
    return this.dailyService
      .academicTree(data, {
        [ACADEMIC_YEAR_KEY.year]: helperAcademicYear(data.date, store.usuario_ativo),
      })
      .pipe(safeEmpty());
  }

  private loadEntity(filter: EclassFilter, academicTree: EclassResult) {
    if (!isEmpty(academicTree.entities)) {
      if (filter.entityId && !academicTree.entities.some(({ id }) => id === Number(filter.entityId))) {
        this.update({ entityId: academicTree.activeUser.entidade_id });
      } else if (!filter.entityId) {
        this.update({ entityId: academicTree.activeUser.entidade_id });
      }
    }
  }

  private loadFilter(filter: EclassFilter, academicTree: EclassResult) {
    if (
      filter.subjectId &&
      !academicTree.subjects.some(
        ({ id, group_id }) => id === Number(filter.subjectId) && group_id === Number(filter.groupId),
      )
    ) {
      const item = this.firstItem(academicTree);
      this.update({ subjectId: item?.id, groupId: item?.group_id });
    } else if (!filter.subjectId || !filter.groupId) {
      const item = this.firstItem(academicTree);
      if (item) {
        this.update({ subjectId: item.id, groupId: item.group_id });
      }
    }
  }

  private firstItem(academicTree: EclassResult): any {
    if (helperItemGroup(academicTree) === ItemGroup.Group) {
      const group = first(academicTree.groups);
      return academicTree.subjects.find(({ group_id }) => group_id === group.id);
    }
    return first(academicTree.subjects);
  }

  private extractPathRoute(path: string) {
    const queryStringPattern = /\?.*/;
    const pathSegmentPattern = /^\/([^\/]+\/[^\/]+)\//;
    return path.replace(queryStringPattern, '').replace(pathSegmentPattern, '');
  }
}
