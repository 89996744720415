import { Injectable } from '@angular/core';
import { portalConst } from '@app/config';
import { ApiService } from './api.service';
import { CrudService } from './crud.service';

@Injectable({
  providedIn: 'root',
})
export class AppointmentsService extends CrudService<any> {
  private _url = `${portalConst.api.academic}/v1/occurrences`;
  private _reportUrl = `${portalConst.api.academic}/v1/report`;

  constructor(api: ApiService) {
    super(api, `${portalConst.api.academic}/v1/occurrence`);
  }

  public filterEducational(data: any) {
    return this.api.get<any[]>(`${this._url}/filter-educational`, data);
  }

  public sendEmail(data: any) {
    return this.api.get(`${this._url}/email`, data);
  }

  public print(data: any) {
    window.open(
      `${this._reportUrl}/letter-occurrence?occurrence_ids=${data.ids}&letter_id=${data.idCarta}&entity_id=${data.IdCidade}&start_date=${data.dataInicial}&end_date=${data.dataFinal}&token=${data.token}`,
      '_blank',
    );
  }

  public printGeneral(data: any) {
    window.open(
      `${this._reportUrl}/general?occurrence_ids=${data.occurrence_ids}&notification_ids=${data.notification_ids}&token=${data.token}`,
      '_blank',
    );
  }
}
