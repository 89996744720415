import { Injectable } from '@angular/core';
import { portalConst } from '@app/config';
import { notNull } from '@app/shared';
import { AppSelectors } from '@app/store';
import { AppState } from '@app/store/app.state';
import { Store } from '@ngrx/store';
import { combineLatest, map } from 'rxjs';
import { MenuItem, MenuRef } from './menu';
import { PaisEnum } from '../models';
import { format } from 'date-fns';

@Injectable({ providedIn: 'root' })
export class AcademicMenu implements MenuRef {
  public identifier = 'academic';

  public readonly menu: MenuItem[] = [
    () => ({
      title: 'menu.academico',
      url: '/academico',
      icon: 'icon-academic',
      dimension: 'h-9 w-9',
      permission: [1, 2],
      show: this.store.select(AppSelectors.ActiveUser).pipe(
        notNull(),
        map((activeUser) => !!activeUser?.entidade_id),
      ),
    }),
  ];

  public readonly submenu: MenuItem[] = [
    () => ({
      title: 'breadcrumb.academico.apontamento-educacional',
      url: '/academico/apontamentos-educacionais',
      icon: 'icon-menu-educational',
      group: 'menu.academico',
      permission: [19, 33],
    }),
    () => ({
      title: 'breadcrumb.academico.apontamento-disciplinar',
      url: '/academico/apontamentos-disciplinares',
      icon: 'icon-menu-disciplinary',
      group: 'menu.academico',
      permission: [19, 34],
      show: this.store.select(AppSelectors.ActiveUser).pipe(
        notNull(),
        map((activeUser) => activeUser?.pais_id === PaisEnum.Brasil),
      ),
    }),
    () => ({
      title: 'breadcrumb.academico.ficha-deliberativa',
      url: '/academico/ficha-deliberativa',
      icon: 'icon-page',
      group: 'menu.academico',
      permission: [19, 45],
    }),
    () => ({
      title: 'breadcrumb.academico.modulo-professor',
      url: undefined,
      icon: 'icon-import-activity',
      group: 'menu.eclass',
      redirect: portalConst.web.integration7EduTeacher,
      show: this.store.select(AppSelectors.ActiveUser).pipe(
        notNull(),
        map((activeUser) => activeUser?.secretaria_sistema_id === 3),
      ),
    }),
    () => ({
      title: 'breadcrumb.eclass.titulo',
      url: ['/eclass', format(new Date(), 'yyyy-MM-dd')],
      icon: 'icon-item-edit',
      group: 'menu.academico',
      ignore: ['/eclass/lives', '/eclass/relatorios', '/eclass/observacoes'],
      permission: [19, 62],
      show: this.store.select(AppSelectors.ActiveUser).pipe(
        notNull(),
        map((activeUser) => activeUser?.pais_id !== PaisEnum.Brasil),
      ),
    }),
    () => ({
      title: 'breadcrumb.eclass.observacoes',
      url: '/eclass/observacoes',
      icon: 'icon-feedback-line',
      group: 'menu.academico',
      permission: [20, 35],
      show: this.store.select(AppSelectors.ActiveUser).pipe(
        notNull(),
        map((activeUser) => activeUser?.pais_id !== PaisEnum.Brasil),
      ),
    }),
    () => ({
      title: 'breadcrumb.aluno.apontamentos',
      url: '/academico/apontamentos-aluno',
      group: 'menu.academico',
      icon: 'icon-menu-notifications',
      permission: [19, 61],
      show: this.store.select(AppSelectors.ActiveUser).pipe(
        notNull(),
        map((activeUser) => activeUser?.pais_id !== PaisEnum.Brasil),
      ),
    }),
    () => ({
      title: 'breadcrumb.eclass.lives',
      url: '/eclass/lives',
      icon: 'icon-zoom',
      group: 'menu.academico',
      permission: [19, 33],
      show: combineLatest([this.store.select(AppSelectors.ActiveUser), this.store.select(AppSelectors.hasZoom)]).pipe(
        notNull(),
        map(([activeUser, hasZoom]) => hasZoom && activeUser?.pais_id !== PaisEnum.Brasil),
      ),
    }),
    () => ({
      title: 'breadcrumb.eclass.relatorio',
      url: '/eclass/relatorios',
      icon: 'icon-report-teachers',
      group: 'menu.academico',
      permission: [19, 33],
      show: this.store.select(AppSelectors.ActiveUser).pipe(
        notNull(),
        map((activeUser) => activeUser?.pais_id !== PaisEnum.Brasil),
      ),
    }),
  ];

  constructor(private store: Store<AppState>) {}
}
