import { ChangeDetectionStrategy, Component, DestroyRef, EventEmitter, Input, Output } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute, Router } from '@angular/router';
import { NOTIFICATION_REFERENCE, NOTIFICATION_STYLE, NotificationData, OriginEnum, PaisEnum } from '@app/core/models';
import { LoadingService, NotificationHistoryService } from '@app/core/services';
import { Checker, notNull } from '@app/shared';
import { AppActions, AppSelectors } from '@app/store';
import { AppState } from '@app/store/app.state';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { flattenDeep, truncate } from 'lodash';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

interface NotificationItem extends NotificationData {
  icon: string;
  style: any;
}
@Component({
  selector: 'app-item-notification',
  templateUrl: './item-notification.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItemNotificationComponent {
  @Input() public summary = false;
  @Output() public afterRead = new EventEmitter<void>();
  public data: NotificationItem;
  public store$: Observable<AppState>;

  @Input()
  public set notification(notify: NotificationData) {
    this.data = {
      ...notify,
      message: this.truncate(notify),
      icon: this.icon(notify.isRead),
      style: NOTIFICATION_STYLE[notify.origin] || NOTIFICATION_STYLE[OriginEnum.Outras],
    };
  }

  constructor(
    private _router: Router,
    private _destroy: DestroyRef,
    private _store: Store<AppState>,
    private _loadingService: LoadingService,
    private _activatedRoute: ActivatedRoute,
    private _translateService: TranslateService,
    private _notificationHistoryService: NotificationHistoryService,
  ) {
    this.store$ = this._store.select(AppSelectors.appFeature).pipe(takeUntilDestroyed(this._destroy), notNull());
  }

  public onRead(notification: NotificationData, store: AppState) {
    if (this.isDependentNotication(notification, store)) {
      this.changeToResponsible(notification, store);
    } else if (
      notification?.extra?.reference?.entityId &&
      !(Checker.isStudent(store.funcao_padrao) || Checker.isResposible(store.funcao_padrao))
    ) {
      this.changeToEmployee(notification, store);
    } else {
      this.markAsRead(notification);
    }
  }

  private isDependentNotication(notification: NotificationData, store: AppState): boolean {
    return notification.extra.citedId !== store.usuario_id;
  }

  private markAsRead(notification: NotificationData) {
    if (notification.isRead) {
      this.navigate(notification);
    } else {
      this._notificationHistoryService.markAsRead(notification.id).subscribe(() => this.navigate(notification));
    }
  }

  private navigate(notification: NotificationData) {
    const link = NOTIFICATION_REFERENCE[notification.extra.reference.type]?.link(notification);
    this._router
      .navigate([link.router], {
        replaceUrl: true,
        relativeTo: this._activatedRoute,
        queryParams: link.queryParams,
      })
      .then(() => this.afterRead.emit());
  }

  private changeToResponsible(notification: NotificationData, store: AppState) {
    const matchEntity = this.matchResposible(store.usuario_ativo.usuario_id, notification);
    const isResposible = Checker.isResposible(store.funcao_padrao);
    if (matchEntity && isResposible) {
      this.markAsRead(notification);
    } else {
      this._loadingService.start();
      const profile = this.dependentUser(store, notification);
      this.changeProfile(store, profile, notification);
    }
  }

  private changeToEmployee(notification: NotificationData, store: AppState) {
    const matchEntity = this.matchEntity(store.usuario_ativo.entidade_id, notification);
    const isEmployee = Checker.isEmployee(store.funcao_padrao);
    if (matchEntity && isEmployee) {
      this.markAsRead(notification);
    } else {
      this._loadingService.start();
      const profile = this.employeeUser(store, notification);
      this.changeProfile(store, profile, notification);
    }
  }

  private changeProfile(store: AppState, profile: any, notification: NotificationData) {
    const user = {
      ...store,
      funcao_padrao: profile.funcao.funcao_id,
      profileId: profile.funcao.funcao_id,
      unidade: this.unit(profile),
      usuario_ativo: profile,
      dependentId: profile.aluno_id,
    };
    this._store.dispatch(AppActions.InitDataEffect({ user }));
    this._store
      .select(AppSelectors.ActiveUser)
      .pipe(takeUntilDestroyed(this._destroy), take(1))
      .subscribe(() => this.markAsRead(notification));
  }

  private dependentUser(store: AppState, notification: NotificationData): any {
    const roles = store.funcoes.map(({ escola_aluno, ...funcao }) =>
      escola_aluno.map((aluno) => ({ ...aluno, funcao })),
    );
    return flattenDeep(roles).find((role) => {
      const matchEntity = this.matchResposible(role.usuario_id, notification);
      const isResposible = Checker.isResposible(role.funcao.funcao_id);
      return matchEntity && isResposible;
    });
  }

  private employeeUser(store: AppState, notification: NotificationData): any {
    const roles = store.funcoes.map(({ escola_aluno, ...funcao }) =>
      escola_aluno.map((aluno) => ({ ...aluno, funcao })),
    );
    return flattenDeep(roles).find((role) => {
      const matchEntity = this.matchEntity(role.entidade_id, notification);
      const isEmployee = Checker.isEmployee(role.funcao.funcao_id);
      return matchEntity && isEmployee;
    });
  }

  private matchResposible(userId: number, notification: NotificationData) {
    return userId === notification.extra.citedId;
  }

  private matchEntity(entityId: number, notification: NotificationData) {
    return entityId === notification.extra.reference.entityId;
  }

  private unit(profile: any): any {
    return {
      ...profile.funcao,
      name: profile.pais_id === PaisEnum.Brasil ? profile.funcao : profile.funcao_es,
      entity: profile.nome_escola,
    };
  }

  private icon(read: boolean): string {
    if (read) {
      return 'assets/icons/icon-read.svg';
    }
    return 'assets/icons/icon-unread.svg';
  }

  private truncate(notify: NotificationData): string {
    let message = this._translateService.instant(`notificacoes.${notify.message}`, notify.extra.reference);
    if (!!notify.extra.reference?.isLate) {
      message = this._translateService.instant('notificacoes.answer.named-late', notify.extra.reference);
    }
    if (this.summary) {
      return truncate(message, { length: 100 });
    }

    return message;
  }
}
