import { Injectable } from '@angular/core';
import { portalConst } from '@app/config';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { ContentsCategories, RequestContents } from '../models/pedagogical-content.model';

@Injectable({
  providedIn: 'root',
})
export class ContentsCategoriesService {
  private readonly url = `${portalConst.api.contents}/v1`;

  constructor(private api: ApiService) {}

  public categories(req?: any): Observable<RequestContents<ContentsCategories>> {
    return this.api.list<RequestContents<ContentsCategories>>(`${this.url}/lista-categorias`, req);
  }

  public content(req?: any): Observable<any> {
    return this.api.get(`${this.url}/filtra-conteudos`, req);
  }
}
