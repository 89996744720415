<div #datepicker app-input class="group relative flex">
  <div class="flex w-full items-center text-sm leading-none transition duration-500" (click)="onDatepicker(datepicker)">
    <ng-container *ngIf="ctrl.value; else emptyTemplate">
      <ng-container *ngIf="showTime; else showDate">
        {{ ctrl.value | date : 'dd/MM/YY HH:mm' }}
      </ng-container>
      <ng-template #showDate>
        {{ ctrl.value | date }}
      </ng-template>
    </ng-container>
    <ng-template #emptyTemplate>
      <span class="text-slate-400 transition duration-500">
        {{ placeholder }}
      </span>
    </ng-template>
  </div>
  <div class="flex gap-1">
    <button *ngIf="ctrl.value && clearable" app-button-icon class="!h-full !p-0" type="button" (click)="onClear()">
      <svg-icon
        class="flex h-6 w-6 text-sm text-base-gray-400 transition duration-500 group-hover:!text-theme"
        src="assets/icons/icon-close.svg"
      ></svg-icon>
    </button>
    <button app-button-icon class="!h-full !p-0" type="button" (click)="onDatepicker(datepicker)">
      <svg-icon
        class="flex h-6 w-6 text-sm text-base-gray-400 transition duration-500 group-hover:!text-theme"
        src="assets/icons/icon-calendar.svg"
      ></svg-icon>
    </button>
  </div>
</div>
