import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthExpiredInterceptor } from './auth-expired-interceptor';
import { TokenHeaderInterceptor } from './token-header-interceptor';
import { EntityHeaderInterceptor } from './entity-header-interceptor';

export const httpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: TokenHeaderInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: EntityHeaderInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: AuthExpiredInterceptor, multi: true },
];
