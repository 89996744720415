import { ChangeDetectionStrategy, Component, DestroyRef } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { LiveService } from '@app/core/services/live.service';
import { notNull, safeEmpty } from '@app/shared/utils/operators';
import { AppSelectors } from '@app/store';
import { Store } from '@ngrx/store';
import { isEmpty } from 'lodash';
import { map, Observable, switchMap } from 'rxjs';


@Component({
  selector: 'app-card-live',
  templateUrl: './card-live.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardLiveComponent {
  public type = 3;
  public livesMeet$: Observable<any>;

  constructor(private _store: Store, private _liveService: LiveService, private _destroy: DestroyRef) {
    this.livesMeet$ = this._store.select(AppSelectors.ActiveUser).pipe(
      takeUntilDestroyed(this._destroy),
      notNull(),
      switchMap(({ aluno_id }) =>
        this._liveService.getLivesMeet(this.type).pipe(
          safeEmpty(),
          map((lives: any) =>
            lives.filter((live) => {
              const now = new Date();
              const liveEndDate = new Date(live.date_end);
              return (
                (live.students.length === 0 || live.students.some((student) => student.id === aluno_id)) &&
                liveEndDate >= now
              );
            }),
          ),
        ),
      ),
    );
  }

  public openLive(link) {
    window.open(link, '_blank');
  }

  public isEmpty(data): boolean {
    return isEmpty(data);
  }
}
