import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { Category, PaisEnum } from '@app/core/models';
import { DriveService } from '@app/core/services';

import { safeEmptyList } from '@app/shared/utils/operators';
import { AppSelectors } from '@app/store';
import { AppState } from '@app/store/app.state';
import { Store } from '@ngrx/store';
import { head } from 'lodash';
import { Observable, Subject } from 'rxjs';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import { BreadcrumbFolder, BreadcrumbFolderService } from './breadcrumb-folder.service';
import { DriveType, folderPath } from '../../model';

@Component({
  templateUrl: './modal-drive.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [BreadcrumbFolderService],
})
export class ModalDriveComponent implements OnInit, OnDestroy {
  public breadcrumb$: Observable<BreadcrumbFolder[]>;
  public categories$: Observable<Category[]>;
  public actived: Category;
  private _destroy$ = new Subject<void>();

  constructor(
    private store: Store<AppState>,
    private driveService: DriveService,
    private breadcrumbFolderService: BreadcrumbFolderService,
  ) {}

  public ngOnInit() {
    this.loadData();
  }

  public ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

  public onNavigate(url: string, index: number) {
    this.breadcrumbFolderService.navigate(url, index);
  }

  public loadData() {
    this.breadcrumb$ = this.breadcrumbFolderService.breadcrumb$;
    this.categories$ = this.store.select(AppSelectors.ActiveUser).pipe(
      takeUntil(this._destroy$),
      switchMap(({ pais_id, escola_externa }) => {
        const type = folderPath(pais_id, escola_externa);
        return this.driveService.categories(type);
      }),
      safeEmptyList(),
      tap((categories) => (this.actived = head(categories))),
    );
  }

  public onSelect(category: Category) {
    this.actived = category;
  }
}
