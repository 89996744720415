import { DIALOG_DATA } from '@angular/cdk/dialog';
import { Component, Inject } from '@angular/core';

@Component({
  selector: 'app-modal-assessments-mirror',
  templateUrl: './modal-assessments-mirror.component.html',
})
export class ModalAssessmentsMirrorComponent {
  public userId: number;
  public userName: string;
  public evaluationTitle: string;
  public evaluationUuid: string;
  public managedType: string;

  constructor(@Inject(DIALOG_DATA) public data: any) {
    this.userId = data.userId;
    this.evaluationTitle = data.evaluationTitle;
    this.evaluationUuid = data.evaluationUuid;
    this.userName = data.userName;
    this.managedType = data.managedType;
  }
}
