import { DIALOG_DATA } from '@angular/cdk/dialog';
import { ConnectionPositionPair } from '@angular/cdk/overlay';
import { ChangeDetectionStrategy, Component, DestroyRef, ElementRef, Inject, OnDestroy } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AbstractControl, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { AlertService, AssessmentApplicationService, NotificationService } from '@app/core';
import { AssessmentAnnulTypeEnum, AssessmentWeightType } from '@app/core/models';
import { KeyboardShortcutsService } from '@app/shared/modules/keyboard-shortcuts/keyboard-shortcuts.service';
import { Popover, Strategy } from '@app/shared/modules/template/components/popover';
import { SidenavMode } from '@app/shared/modules/template/components/sidenav/sidenav';
import { TranslateService } from '@ngx-translate/core';
import { first, isNil } from 'lodash';
import { BehaviorSubject, Observable, combineLatest, finalize, map, of, switchMap, take, tap } from 'rxjs';
import { AssessmentAttemptService } from '../../../../../../../core/services/assessment-attempt.service';
import { notNull, safeEmpty } from '../../../../../../../shared/utils/operators';
import { PopoverAnnulQuestionComponent } from '../../../modal-evaluation-report/components/popover-annul-question/popover-annul-question.component';
import { AppState } from '@app/store/app.state';
import { Store } from '@ngrx/store';
import { AppSelectors } from '@app/store';
import { selectedEntityActive } from '@app/shared';

@Component({
  selector: 'app-modal-body-answers-audit',
  templateUrl: './modal-body-answers-audit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalBodyAnswersAuditComponent implements OnDestroy {
  public readonly badgeStyle = {
    background: 'bg-red-100 text-black hover:bg-red-300 hover:text-white',
    active: 'bg-red-500 text-white',
  };

  public readonly strategy = Strategy;
  public readonly mode = SidenavMode;
  public readonly assessmentWeightType = AssessmentWeightType;
  public form: FormGroup;
  public dataStudentFilter$: Observable<any[]>;
  public data$ = new BehaviorSubject<any>(undefined);
  public dataStudents$ = new BehaviorSubject<any[]>(undefined);
  public selectedUser$ = new BehaviorSubject<any>(undefined);
  public aplication$ = new BehaviorSubject<any>(undefined);
  public loading$ = new BehaviorSubject<boolean>(false);
  public dataViewer$ = new BehaviorSubject<any>(undefined);
  public pendingCtrl = new FormControl(false);
  public dataStudentCtrl = new FormControl(undefined);
  public scales = [
    { value: 0, shortcut: 'q' },
    { value: 0.25, shortcut: 'w' },
    { value: 0.5, shortcut: 'e' },
    { value: 0.75, shortcut: 'r' },
    { value: 1, shortcut: 't' },
  ];

  constructor(
    @Inject(DIALOG_DATA) public data: any,

    private _popover: Popover,
    private _keyboard: KeyboardShortcutsService,
    private _assessmentApplicationService: AssessmentApplicationService,
    private _notificationService: NotificationService,
    private _translateService: TranslateService,
    private _alertService: AlertService,
    private _destroyRef: DestroyRef,
    private _assessmentAttemptService: AssessmentAttemptService,
    private _formBuilder: FormBuilder,
    private _store: Store<AppState>,
  ) {
    this.initForm();
    this._store
      .select(AppSelectors.ActiveUser)
      .pipe(
        take(1),
        notNull(),
        tap(() => this.loading$.next(true)),
        map((activeUser) => first(selectedEntityActive(activeUser))),
        switchMap((entityActive) =>
          combineLatest({
            application: this._assessmentApplicationService
              .show(this.data.applicationUuid, this.form.value)
              .pipe(safeEmpty()),
            data: this._assessmentApplicationService
              .getStudent(this.data.applicationUuid, { entity_id: entityActive.id, full: 1 })
              .pipe(take(1), safeEmpty()),
          }),
        ),
        finalize(() => this.loading$.next(false)),
      )
      .subscribe(({ application, data }) => {
        this.data$.next(this.data);
        this.dataStudents$.next((data as any).alunos);
        this.onSelectUserById(this.data$.value.userId || first(this.dataStudents$.value)?.usuario_id);
        this.aplication$.next(application);
        if (!!this.data.student) {
          const index = this.data.index ?? 0;
          const student = !!this.data.student ? { data: this.data.student, index } : undefined;
          this.selectedUser$.next(student);
        }
      });

    this.pendingCtrl.valueChanges.pipe(takeUntilDestroyed(_destroyRef)).subscribe((pending) => {
      this.refreshUsersList(pending);
      this.findUserByIndex(0);
    });

    this.dataStudentCtrl.valueChanges
      .pipe(
        takeUntilDestroyed(_destroyRef),
        switchMap((student) =>
          combineLatest({
            student: of(student),
            confirmed: this._alertService
              .confirm({
                title: 'Atenção',
                message: 'Você deseja realmente vincular esse aluno para a questão?',
              })
              .pipe(notNull()),
          }),
        ),
        switchMap(({ student }) =>
          combineLatest({
            student: of(student),
            data: this._assessmentApplicationService
              .noStudentslinked(this.selectedUser$.value?.data.tentativa_uuid, student?.usuario_id)
              .pipe(safeEmpty(), take(1)),
          }),
        ),
      )
      .subscribe(({ student }) => this.updateDataStudents(student));

    this.dataStudentFilter$ = this.dataStudents$.pipe(
      map((students) => students.filter((student) => !!student.usuario_id && !student.tentativa_uuid)),
    );

    this.selectedUser$.pipe(takeUntilDestroyed(this._destroyRef), notNull()).subscribe((value) => {
      this.setDataViewer(value.data.tentativa_file_url);
    });
  }

  public ngOnDestroy(): void {
    this.data$.complete();
    this.dataStudents$.complete();
    this.selectedUser$.complete();
    this.aplication$.complete();
    this.loading$.complete();
    this.dataViewer$.complete();
  }

  public onCorrectOrSociodemographic(alternative: any, question: any): boolean {
    return !!alternative.alternativa_correta || question.sociodemografica === 1;
  }

  public onIncorrectAndNotSociodemographic(alternative: any, question: any): boolean {
    return !alternative.alternativa_correta && question.sociodemografica !== 1;
  }

  public onSelectedIncorrectAndNotSociodemographic(alternative: any, answer: any, question: any): boolean {
    return (
      alternative.uuid === answer.data?.alternativa?.uuid &&
      !alternative.alternativa_correta &&
      question.sociodemografica !== 1
    );
  }

  public shouldApplyWhiteBackground(alternative: any, answer: any, question: any): boolean {
    return (
      this.onCorrectOrSociodemographic(alternative, question) ||
      this.onSelectedIncorrectAndNotSociodemographic(alternative, answer, question)
    );
  }

  public onForm(control: AbstractControl) {
    control.markAllAsTouched();
  }

  public get evaluationByWeight(): boolean {
    return this.aplication$.value?.avaliacao?.peso_tipo === AssessmentWeightType.Peso;
  }

  public setDataViewer(fileUrl: string) {
    this.dataViewer$.next({
      name: 'response_card.png',
      url: fileUrl,
    });
  }

  public initShortcuts() {
    const shortcuts = [
      { key: 'shift + left', command: () => this.onPreviousUser(), preventDefault: true },
      { key: 'shift + right', command: () => this.onNextUser(), preventDefault: true },
      { key: 'shift + n', command: this.focusInputNota, preventDefault: true },
      { key: 'shift + enter', command: this.clickBtnSalvar, preventDefault: true },
      { key: 'esc', command: this.blurInputNota, preventDefault: true },
    ];

    this.scales.forEach((s) => {
      shortcuts.push({
        key: 'shift + ' + s.shortcut,
        command: () => this.clickBtnScale(s.shortcut),
        preventDefault: true,
      });
    });

    this._keyboard.add(shortcuts);
  }

  public onGetAnswer(questaoUuid: string) {
    const answer = this.selectedUser$.value?.data.tentativa_questoes?.find((t) => t.questao?.uuid === questaoUuid);
    return { data: answer };
  }

  public updateDataStudents(student: any) {
    const updatedStudents = this.data$.value.dataStudent
      .filter((s) => s.usuario_id !== student.usuario_id)
      .map((s, index) => {
        if (this.selectedUser$.value?.data.tentativa_uuid === s.tentativa_uuid) {
          const newStudent = {
            ...s,
            nome: student.nome,
            usuario_id: student.usuario_id,
            turma_descricao: student.turma_descricao,
          };
          this.onSetSelectedUser(newStudent, index);
          return newStudent;
        }
        return s;
      });
    this.data$.next({ ...this.data$.value, dataStudent: updatedStudents });
    this.refreshUsersList(false);
  }

  public onSaveNota(questionUuid, nota) {
    this.onSaveAll(questionUuid, { nota });
  }

  public onChangeAlternativa(questionUuid: string, alternativaUuid: string) {
    this._alertService
      .confirm({
        title: 'Atenção',
        message: 'Você deseja realmente trocar a alternativa do aluno?',
      })
      .pipe(notNull())
      .subscribe(() => this.onSaveAll(questionUuid, { alternativa_uuid: alternativaUuid }));
  }

  public onSaveAll(questionUuid: string, data: any) {
    const lastCorrection = this.getLastCorrectionValue();
    const tentativaUuid$ = !this.selectedUser$.value.data.tentativa_uuid
      ? this._assessmentAttemptService
          .store({ aplicacao_uuid: this.data.applicationUuid, user_id: this.selectedUser$.value.data.usuario_id })
          .pipe(
            take(1),
            safeEmpty(),
            notNull(),
            map((data) => data.uuid),
          )
      : of(this.selectedUser$.value.data.tentativa_uuid);

    tentativaUuid$
      .pipe(
        switchMap((tentativaUuid: string) =>
          this._assessmentApplicationService
            .printedCorrection(tentativaUuid, questionUuid, {
              ...data,
              last: lastCorrection,
            })
            .pipe(safeEmpty(), notNull()),
        ),
      )
      .subscribe((data) => {
        this.updateStudentData(data);
        this.onSelectUserById(this.selectedUser$.value.data.usuario_id);
        this._notificationService.success(this._translateService.instant('geral.salvo-com-sucesso'));
        this.refreshUsersList(this.pendingCtrl.value);
        this.findUserByIndex(this.selectedUser$.value.index);
      });
  }

  public onSelectUserById(userId: number) {
    const index = this.data$.value.dataStudent.findIndex((a) => a.usuario_id === userId);

    this.onSetSelectedUser(this.data$.value.dataStudent[index], index);
    this.findUserByIndex(index);
  }

  public toLetter(index: number): string {
    return String.fromCharCode(65 + index);
  }

  public onPreviousUser() {
    const index =
      (this.selectedUser$.value.index - 1 + this.data$.value.dataStudent.length) % this.data$.value.dataStudent.length;
    this.findUserByIndex(index);
  }

  public onCalcNota(nota, scale) {
    const result = nota * scale;
    const notaResult = result.toFixed(2);
    return { nota: notaResult };
  }

  public onNextUser() {
    const index = (this.selectedUser$.value.index + 1) % this.data$.value.dataStudent.length;
    this.findUserByIndex(index);
  }

  public onSetSelectedUser(user: any, index: number) {
    const data = !!user ? { data: user, index } : undefined;
    this.selectedUser$.next(data);
  }

  public onOpenPopover(elementRef: ElementRef, positionStrategy: ConnectionPositionPair[] = [], question: any): void {
    this._popover
      .open(PopoverAnnulQuestionComponent, {
        positionStrategy,
        elementRef,
        data: {
          aplicacao_uuid: this.data.applicationUuid,
          question,
          tentativa_uuid: this.selectedUser$.value?.data.tentativa_uuid,
          type: AssessmentAnnulTypeEnum.Answer,
        },
      })
      .closed.subscribe((data) => {
        this.updateStudentData(data);
      });
  }

  public onChange() {
    // this.loadData(this.data$.value.applicationUuid);
  }

  private findUserByIndex(index: number) {
    const user = this.dataStudents$.value[index];
    if (user) {
      setTimeout(() => document.getElementById('user_' + user.usuario_id)?.scrollIntoView({ behavior: 'smooth' }), 100);
    }
    this.onSetSelectedUser(user, index);
  }

  private updateStudentData(data: any): void {
    const selectedUserId = this.selectedUser$.value.data.usuario_id;
    const updatedStudents = this.data$.value.dataStudent.map((a) => {
      if (a.usuario_id === selectedUserId) {
        return {
          ...a,
          status: data?.status,
          nota_calculada: data.nota_calculada,
          tentativa_questoes: data.tentativa_questoes,
        };
      }
      return a;
    });
    this.data$.next({ ...this.data$.value, dataStudent: updatedStudents });
  }

  private refreshUsersList(pending: boolean) {
    const users = !!pending
      ? this.data$.value.dataStudent.filter(
          (a) => !a.tentativa_questoes || a.tentativa_questoes.some((t) => isNil(t.nota)),
        )
      : this.data$.value?.dataStudent;
    this.dataStudents$.next(users);
  }

  private getLastCorrectionValue(): number {
    const selectedUserId = this.selectedUser$.value.data.usuario_id;
    const student = this.data$.value.dataStudent.find((a) => a.usuario_id === selectedUserId);
    const numTentativasSemNota = student?.tentativa_questoes?.filter((t) => isNil(t.nota))?.length || 0;
    return numTentativasSemNota < 2 ? 1 : 0;
  }

  private focusInputNota() {
    const inputNota = document.getElementById('inputNota') as HTMLInputElement;
    if (inputNota) {
      inputNota.focus();
      if (inputNota.tagName === 'INPUT') {
        inputNota.select();
      }
    }
  }

  private clickBtnSalvar() {
    const btn = document.getElementById('btnSalvar') as HTMLButtonElement;
    if (btn) {
      btn.click();
    }
  }

  private blurInputNota() {
    const inputNota = document.getElementById('inputNota') as HTMLInputElement;
    if (inputNota) {
      inputNota.blur();
    }
  }

  private clickBtnScale(shortcut: string) {
    const btn = document.getElementById('btn_' + shortcut) as HTMLButtonElement;
    if (btn) {
      btn.click();
    }
  }

  private initForm() {
    this.form = this._formBuilder.group({
      modo_rand: [undefined],
    });
  }
}
