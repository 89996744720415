import { Injectable } from '@angular/core';
import { portalConst } from '@app/config';
import { ApiService } from './api.service';
import { CrudService } from './crud.service';

@Injectable({
  providedIn: 'root',
})
export class ServicesService extends CrudService<any> {
  constructor(api: ApiService) {
    super(api, `${portalConst.api.permissao}/services`);
  }
}
