<div class="relative mx-5 my-3 justify-center">
  <input
    app-input
    class="rounded-xl pr-9"
    [placeholder]="'perfil.buscar-aluno' | translate"
    [formControl]="searchCtrl"
  />
  <svg-icon src="assets/icons/icon-search.svg" class="absolute right-4 top-4 w-4"></svg-icon>
</div>

<div class="h-[250px] overflow-auto p-5 bg-theme-100 shadow-inner-bg">
  <ng-container *ngIf="students$ | async | filter : searchCtrl.value : ['nome', 'info_escola'] as students">
    <ng-container *ngIf="isEmpty(students); else dataTemplate">
      <app-jumbotron translate>geral.filtro-vazio</app-jumbotron>
    </ng-container>
    <ng-template #dataTemplate>
      <div class="flex flex-col gap-2">
        <app-card *ngFor="let student of students" class="cursor-pointer hover:bg-theme-50" (click)="onChange(student)">
          <div app-card-content class="!px-3 !py-2 text-start">
            <span class="font-semibold">{{ student | orderName | async }}</span>
            <div class="text-gray-500">{{ student.grade || '-' }} - {{ student.group || '-' }}</div>
          </div>
        </app-card>
      </div>
    </ng-template>
  </ng-container>
</div>
