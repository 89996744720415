export enum IdentificadorTipo {
  Cabecalho,
  Rodape,
}

export enum IdentificadorConteudo {
  Dropzone,
  Ckeditor,
}

export interface Identificador {
  id: number;
  entidade_id: number;
  tipo: IdentificadorTipo;
  tipo_conteudo: IdentificadorConteudo;
  titulo: string;
  padrao: boolean;
  imagem: string;
  descricao: string;
  status: boolean;
  criado_por: number;
  alterado_por: number;
}
