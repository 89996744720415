<ng-container *ngIf="data$ | async as data">
  <app-modal-header class="border-b-2 border-theme-500">
    <div class="flex w-full grid-cols-[100px_auto_1fr_auto] flex-col justify-between gap-5 bg-white lg:grid">
      <div class="flex">
        <svg-icon class="w-10 text-theme-500" src="assets/icons/icon-gestor-avaliacoes.svg" alt="Materiais Didaticos">
        </svg-icon>
        <h1 class="ml-3 text-sm font-semibold leading-tight text-gray-800" translate>
          gestor-avaliacoes.e-class
          <br />
          <b class="text-lg text-black" translate> gestor-avaliacoes.prova </b>
        </h1>
      </div>

      <hr app-modal-divider />

      <div>
        <div class="flex w-full items-center justify-between pt-2">
          <div class="md:flex">
            <div class="mr-5">
              <span class="text-md text-black"> {{ data.evaluation.titulo }} </span>
            </div>
          </div>
          <div #iconPopoverShortcut class="flex justify-end">
            <button
              (click)="onOpenPopoverShortcuts(iconPopoverShortcut)"
              app-flat-button
              class="gap-2 bg-base-status-info-500 text-theme-contrast"
              type="button"
            >
              <svg-icon class="h-6 w-6 text-white lg:ml-1" src="assets/icons/icon-info-rounded.svg"></svg-icon>
              <span class="hidden lg:flex">
                {{ 'gestor-avaliacoes.atalho.atalhos-correcao' | translate }}
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </app-modal-header>

  <div app-modal-content *ngIf="assessment$ | async as assessment; else emptyTemplateGeneral" class="!p-0">
    <div class="overflow-x-auto overflow-y-auto">
      <app-sidenav-group class="!h-[80vh] w-full bg-base-whisper-500 md:!w-[90vw]">
        <div *ngIf="isEmpty(assessment); else dataTemplate" class="grid h-auto gap-4 p-5">
          <app-card>
            <div app-card-content class="h-[200px]">
              <app-skeleton *ngIf="loading$ | async; else jumbotron"></app-skeleton>
              <ng-template #jumbotron>
                <app-jumbotron>
                  <ng-container *ngIf="!!assessment; else emptyTemplate" translate> geral.nenhum-dado </ng-container>
                  <ng-template #emptyTemplate translate> geral.utilizar-filtros </ng-template>
                </app-jumbotron>
              </ng-template>
            </div>
          </app-card>
        </div>
        <ng-template #dataTemplate>
          <ng-container *ngIf="dataStudents$ | async as students">
            <ng-template [opened]="true" app-sidenav-start class="rounded-bl-lg">
              <div class="h-full bg-white p-6">
                <div class="grid w-full p-5">
                  <span class="font-bold" translate> gestor-avaliacoes.alunos </span>
                  <div class="flex justify-between">
                    <span class="font-light" translate> gestor-avaliacoes.pendente-de-correcao </span>
                    <app-switch [formControl]="pendingCtrl"></app-switch>
                  </div>
                  <label app-label translate> gestor-avaliacoes.filtro-turma-aluno </label>
                  <ng-select
                    app-select
                    [formControl]="searchGroupCtrl"
                    [items]="groups"
                    [loading]="loading$ | async"
                    [placeholder]="'geral.selecione' | translate"
                    [loadingText]="'geral.carregando' | translate"
                    [clearable]="false"
                  >
                  </ng-select>
                </div>
                <hr app-divider />
                <div class="px-1 pb-16 pt-0">
                  <ng-container *ngIf="!!students.length; else emptyStudents">
                    <app-card
                      *ngFor="let student of students; let index = index"
                      [id]="'user_' + student.usuario_id"
                      (click)="onSetSelectedUser(student, index)"
                      class="mb-1 mt-5 flex !flex-row items-center p-4 !shadow-black/25 hover:bg-base-whisper-300"
                      [ngClass]="{
                        '!active:border !br !active:border-solid !active:border-base-whisper-500 !bg-base-whisper-400 !shadow-inner !shadow-black/40':
                          student.usuario_id === selectedUser$.value.data.usuario_id
                      }"
                    >
                      <div class="w-3/12">
                        <img
                          *ngIf="!student.foto"
                          src="assets/images/empty-avatar.png"
                          class="border-1 !h-16 !w-max !rounded-full bg-white/80 !p-1 shadow-sm shadow-black/40"
                        />
                        <app-user-photo
                          *ngIf="!!student.foto"
                          class="h-auto !w-max md:!h-16"
                          [height]="'!h-16'"
                          [width]="'!w-max'"
                          [src]="student.foto"
                        ></app-user-photo>
                      </div>
                      <div class="ml-5 grid w-full gap-2">
                        <span class="font-bold">{{ student.nome }}</span>
                        <span class="text-gray-500">{{ student.turma_descricao }}</span>

                        <!-- Status section -->
                        <div class="mt-2 flex">
                          <ng-container [ngSwitch]="student.status">
                            <span
                              *ngSwitchCase="null"
                              class="flex justify-center rounded-full bg-gray-300 px-2 text-white"
                              translate
                            >
                              gestor-avaliacoes.status.nao-iniciado
                            </span>
                            <span
                              *ngSwitchCase="assessmentStatusAttemptEnum.InProgress"
                              class="w-full rounded-full bg-base-status-info px-2 text-white"
                              translate
                            >
                              gestor-avaliacoes.status.em-andamento
                            </span>
                            <span
                              *ngSwitchCase="assessmentStatusAttemptEnum.Wait"
                              class="rounded-full bg-base-status-warning px-2 text-white"
                              translate
                            >
                              gestor-avaliacoes.status.aguardando-correcao
                            </span>
                            <ng-container
                              *ngSwitchCase="
                                assessmentStatusAttemptEnum.Finish || assessmentStatusAttemptEnum.WaitDateResult
                              "
                            >
                              <span class="rounded-full bg-base-mantis-500 px-2 text-white" translate>
                                gda.minhas.encerrada
                              </span>
                            </ng-container>
                          </ng-container>
                        </div>
                      </div>
                      <div class="flex justify-end">
                        <svg-icon
                          *ngIf="student.necessidades_especiais?.length"
                          app-tooltip
                          [tooltip]="formattedNecessidadesEspeciais(student.necessidades_especiais)"
                          class="active:brightness-85 h-6 w-6 cursor-pointer text-gray-400 hover:brightness-110"
                          src="assets/icons/icon-info-rounded.svg"
                        >
                        </svg-icon>
                      </div>
                    </app-card>
                  </ng-container>
                </div>
              </div>
            </ng-template>

            <ng-template app-sidenav-outer>
              <div class="grid h-auto gap-4 p-5">
                <ng-container *ngIf="(selectedUser$ | async)?.data as user; else emptyList">
                  <!-- CARD DO ALUNO  -->
                  <app-card>
                    <div app-card-content class="grid w-full md:flex">
                      <div class="flex w-full gap-5">
                        <div class="flex items-center justify-start">
                          <button
                            [disabled]="user.index <= 0"
                            (click)="onPreviousUser()"
                            app-icon-button
                            app-tooltip
                            tooltip="Anterior"
                            type="button"
                            class="!rounded-full bg-white"
                          >
                            <svg-icon class="flex h-6" src="assets/icons/icon-arrow-left-fine.svg"></svg-icon>
                          </button>
                        </div>
                        <div class="flex w-full justify-center">
                          <div class="flex gap-5">
                            <img
                              *ngIf="!user.foto"
                              src="assets/images/empty-avatar.png"
                              class="border-1 h-20 !rounded-full bg-white/80 !p-1 shadow-sm shadow-black/40 md:h-24"
                            />
                            <app-user-photo
                              *ngIf="!!user.foto"
                              class="h-20 md:!h-24 xl:w-3/12"
                              [height]="'h-20'"
                              [width]="'md:!w-max'"
                              [src]="user.foto"
                            ></app-user-photo>
                            <div class="grid items-center">
                              <span class="text-md font-bold active:text-base-mantis-500 md:text-lg">
                                {{ user.nome }}</span
                              >
                              <span *ngIf="user.turma_descricao" class="active:text-base-mantis-500">
                                {{ user.turma_descricao }}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div class="flex items-center justify-end">
                          <button
                            [disabled]="user.index >= students.length - 1"
                            (click)="onNextUser()"
                            app-icon-button
                            app-tooltip
                            tooltip="Próximo"
                            type="button"
                            class="!rounded-full bg-white"
                          >
                            <svg-icon class="flex h-6" src="assets/icons/icon-arrow-right-fine.svg"></svg-icon>
                          </button>
                        </div>
                      </div>
                      <div
                        class="mt-5 flex w-full items-center justify-center md:mt-0 md:grid md:w-2/12 md:justify-end lg:w-3/12"
                      >
                        <ng-container [ngSwitch]="user.status">
                          <span
                            *ngSwitchCase="null"
                            class="flex justify-center rounded-full bg-gray-300 px-2 text-white"
                            translate
                          >
                            gestor-avaliacoes.status.nao-iniciado
                          </span>
                          <span
                            *ngSwitchCase="assessmentStatusAttemptEnum.InProgress"
                            class="w-full rounded-full bg-base-status-info px-2 text-white"
                            translate
                          >
                            gestor-avaliacoes.status.em-andamento
                          </span>
                          <span
                            *ngSwitchCase="assessmentStatusAttemptEnum.Wait"
                            class="w-full rounded-full bg-base-status-warning px-2 text-white"
                            translate
                          >
                            gestor-avaliacoes.status.aguardando-correcao
                          </span>
                          <ng-container
                            *ngSwitchCase="
                              assessmentStatusAttemptEnum.Finish || assessmentStatusAttemptEnum.WaitDateResult
                            "
                          >
                            <ng-container *ngIf="evaluationByWeight; else labelPercentual">
                              {{ 'gestor-avaliacoes.nota' | translate }}
                            </ng-container>
                            <ng-template #labelPercentual>
                              {{ 'gestor-avaliacoes.percentual-de-acerto' | translate }}
                            </ng-template>
                            <span
                              *ngIf="!!user.status && !!evaluationByWeight"
                              class="mx-2 text-md font-semibold md:text-2xl"
                              >{{ user?.nota_calculada | currency : '' : '' : '1.2-2' }}
                            </span>
                            <span
                              *ngIf="!!user.status && !evaluationByWeight"
                              class="mx-2 text-md font-semibold md:text-2xl"
                              >{{ user?.nota_calculada | currency : '' : '' : '1.0-0' }} %
                            </span>
                            <span class="rounded-full bg-base-mantis-500 px-2 text-white" translate>
                              gda.minhas.encerrada
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>
                    </div>
                  </app-card>

                  <div class="flex w-full flex-col justify-between xl:flex-row">
                    <div class="mr-5 grid h-[35vh] w-full overflow-y-auto lg:!h-[77vh] xl:!h-[65vh]">
                      <ng-container *ngIf="!isEmpty(assessment.questoes); else jumbotron">
                        <!-- PRIMEIRO CARD  -->
                        <app-card>
                          <div app-card-content class="h-auto">
                            <div
                              *ngFor="let question of assessment.questoes; let index = index"
                              (click)="onSelectQuestion(question, index)"
                              [id]="'question_' + question.uuid"
                              class="pb-5"
                            >
                              <ng-container *ngIf="onGetAnswer(question.uuid) as answer">
                                <app-card
                                  class="!active:brightness-85 mb-1 flex !flex-row items-center rounded-xl p-4 !shadow-black/25 transition-all duration-300 hover:brightness-110"
                                  [ngClass]="{
                                    '!bg-base-mantis-100':
                                      ((answer.data?.nota === question.peso || !!question.sociodemografica) &&
                                        (selectedQuestion$ | async)?.index) === index,
                                    '!bg-orange-50':
                                      (answer?.data?.nota !== 0 &&
                                        answer?.data?.nota !== question.peso &&
                                        !question.sociodemografica &&
                                        (selectedQuestion$ | async)?.index === index) ||
                                      (!!question.anulado && (selectedQuestion$ | async)?.index === index),
                                    '!bg-red-50':
                                      (answer.data?.nota === 0 &&
                                        !question.anulado &&
                                        !question.sociodemografica &&
                                        (selectedQuestion$ | async)?.index) === index ||
                                      (!!answer.data?.anulado && (selectedQuestion$ | async)?.index === index),
                                    'shadow-inner !shadow-black/40': (selectedQuestion$ | async)?.index === index
                                  }"
                                >
                                  <div class="flex w-full">
                                    <div class="flex w-full justify-start">
                                      <span class="text-md font-semibold">
                                        {{ 'gestor-avaliacoes.questao' | translate }} {{ index + 1 }}
                                      </span>
                                      <small [ngSwitch]="question.tipo" class="ml-4 flex items-center text-gray-400">
                                        <ng-container *ngSwitchCase="assessmentQuestionType.Objetiva">
                                          {{ 'gestor-avaliacoes.tipo.objetiva' | translate }}
                                        </ng-container>
                                        <ng-container *ngSwitchCase="assessmentQuestionType.Discursiva">
                                          {{ 'gestor-avaliacoes.tipo.discursiva' | translate }}
                                        </ng-container>
                                      </small>
                                      <app-badge-annuled-question
                                        *ngIf="!!answer.data?.anulado || !!question.anulado"
                                        [motive]="question.anulado_motivo ?? answer.data?.anulado_motivo"
                                        [isAnswerAnnulled]="!!answer.data?.anulado"
                                        [isQuestionAnnulled]="!!question.anulado"
                                        [applicationUuid]="data.applicationUuid"
                                        [questionUuid]="question.uuid"
                                        [tentativaUuid]="user.tentativa_uuid"
                                        (updateQuestion)="updateQuestionStudentAnula($event)"
                                        [cancelAnnulment]="!!answer.data?.anulado"
                                        class="px-5"
                                      ></app-badge-annuled-question>
                                    </div>
                                  </div>

                                  <!-- Card da questão  -->
                                  <ng-container
                                    *ngIf="!question.sociodemografica; else sociodemograficaTemplate"
                                    [ngSwitch]="question.tipo"
                                  >
                                    <ng-container *ngSwitchCase="assessmentQuestionType.Objetiva">
                                      <ng-container
                                        *ngIf="
                                          user.status !== null &&
                                            (!isNil(answer.data?.nota) || !!question.anulado || !!answer.data?.anulado);
                                          else pending
                                        "
                                      >
                                        <div *ngIf="onGetScoreData(question, answer.data) as scoreData">
                                          <div class="flex justify-end">
                                            <span
                                              [ngClass]="{
                                                '!bg-base-mantis-500': !!scoreData.correct,
                                                '!bg-base-status-danger': !scoreData.correct || !!answer.data?.anulado,
                                                '!bg-base-status-warning': !!question.anulado,
                                              }"
                                              class="whitespace-nowrap rounded-full bg-orange-300 px-3 text-white shadow-sm"
                                            >
                                              <div class="flex h-full min-h-[1.4rem] items-center gap-2">
                                                <ng-container *ngIf="!answer.data?.anulado && !question.anulado">
                                                  <svg-icon
                                                    class="flex h-3"
                                                    [src]="
                                                      scoreData.correct
                                                        ? 'assets/icons/icon-check.svg'
                                                        : 'assets/icons/icon-delete.svg'
                                                    "
                                                  ></svg-icon>
                                                  <div
                                                    *ngIf="
                                                      evaluationByWeight ||
                                                      question.tipo === assessmentQuestionType.Discursiva
                                                    "
                                                    class="h-full w-[1px] bg-white"
                                                  ></div>
                                                </ng-container>
                                                <span
                                                  *ngIf="
                                                    evaluationByWeight ||
                                                    question.tipo === assessmentQuestionType.Discursiva
                                                  "
                                                  class="font-semibold"
                                                >
                                                  {{ scoreData.score | number : '1.2-2' }}Ï /
                                                  {{ question.peso | number : '1.2-2' }}
                                                </span>
                                              </div>
                                            </span>
                                          </div>
                                        </div>
                                      </ng-container>
                                      <ng-template #pending>
                                        <span
                                          [ngClass]="{
                                            '!text-white !shadow-black/40': (selectedQuestion$ | async)?.index === index
                                          }"
                                          *ngIf="!answer.data?.alternativa.uuid"
                                          class="ml-3 whitespace-nowrap rounded-full bg-gray-300 px-2 font-semibold text-white shadow-sm"
                                          translate
                                        >
                                          gestor-avaliacoes.status.nao-respondido
                                        </span>
                                        <span
                                          [ngClass]="{
                                            '!text-white !shadow-black/40': (selectedQuestion$ | async)?.index === index
                                          }"
                                          *ngIf="!!answer.data?.alternativa.uuid"
                                          class="ml-3 whitespace-nowrap rounded-full bg-orange-300 px-2 font-semibold text-white shadow-sm"
                                          translate
                                        >
                                          gestor-avaliacoes.status.aguardando-correcao
                                        </span>
                                      </ng-template>
                                    </ng-container>
                                    <ng-container *ngSwitchCase="assessmentQuestionType.Discursiva">
                                      <div class="flex justify-end">
                                        <ng-container
                                          *ngIf="
                                            user.status !== null &&
                                              (!isNil(answer.data?.nota) ||
                                                !!question.anulado ||
                                                !!answer.data?.anulado);
                                            else pending
                                          "
                                        >
                                          <span
                                            *ngIf="onGetScoreData(question, answer.data) as scoreData"
                                            [ngClass]="{
                                              '!shadow-black/40': (selectedQuestion$ | async)?.index === index,
                                              'bg-base-mantis-500': answer.data?.nota === question.peso,
                                              'bg-base-status-danger':
                                                answer.data?.nota === 0 || (answer.data?.anulado && !question.anulado),
                                              'bg-base-status-warning':
                                                (answer.data?.nota !== 0 &&
                                                  answer.data?.nota !== question.peso &&
                                                  !answer.data?.anulado) ||
                                                !!question.anulado
                                            }"
                                            class="ml-3 whitespace-nowrap rounded-full px-3 text-white shadow-sm"
                                          >
                                            <div class="flex h-full items-center gap-2">
                                              <ng-container *ngIf="!answer.data?.anulado && !question.anulado">
                                                <svg-icon
                                                  class="flex h-3"
                                                  [src]="
                                                    answer.data?.nota === question.peso
                                                      ? 'assets/icons/icon-check.svg'
                                                      : 'assets/icons/icon-delete.svg'
                                                  "
                                                ></svg-icon>
                                                <div class="h-full w-[1px] bg-white"></div>
                                              </ng-container>
                                              <span class="font-semibold">
                                                {{ scoreData.score | number : '1.2-2' }}
                                                /
                                                {{ question.peso | number : '1.2-2' }}
                                              </span>
                                            </div>
                                          </span>
                                        </ng-container>

                                        <ng-template #pending>
                                          <span
                                            [ngClass]="{
                                              '!text-white !shadow-black/40':
                                                (selectedQuestion$ | async)?.index === index
                                            }"
                                            *ngIf="!answer.data?.resposta"
                                            class="ml-3 whitespace-nowrap rounded-full bg-gray-300 px-2 font-semibold text-white shadow-sm"
                                            translate
                                          >
                                            gestor-avaliacoes.status.nao-respondido
                                          </span>
                                          <span
                                            [ngClass]="{
                                              '!text-white !shadow-black/40':
                                                (selectedQuestion$ | async)?.index === index
                                            }"
                                            *ngIf="!!answer.data?.resposta"
                                            class="ml-3 whitespace-nowrap rounded-full bg-orange-300 px-2 font-semibold text-white shadow-sm"
                                            translate
                                          >
                                            gestor-avaliacoes.status.aguardando-correcao
                                          </span>
                                        </ng-template>
                                      </div>
                                    </ng-container>
                                  </ng-container>
                                  <ng-template #sociodemograficaTemplate>
                                    <app-badge [badgeStyle]="badgeStyleSociodemografica">
                                      {{ 'gestor-avaliacoes.novas-questoes.sociodemografica' | translate }}
                                    </app-badge>
                                  </ng-template>
                                </app-card>
                              </ng-container>
                            </div>
                          </div>
                        </app-card>
                      </ng-container>
                    </div>
                    <!-- SEGUNDO CARD  -->
                    <div
                      *ngIf="selectedQuestion$ | async as selectedQuestion"
                      class="mb-5 mt-5 grid h-auto w-full md:mb-0 xl:mt-0"
                    >
                      <app-card>
                        <div app-card-content [ngSwitch]="selectedQuestion.data.tipo" class="h-auto">
                          <div class="flex">
                            <div class="flex w-full justify-start">
                              <span class="mr-5 text-lg font-semibold">
                                {{ 'gestor-avaliacoes.questao' | translate }} {{ selectedQuestion.index + 1 }}
                              </span>
                              <span class="flex items-center text-gray-400">
                                <ng-container *ngSwitchCase="assessmentQuestionType.Objetiva">
                                  {{ 'gestor-avaliacoes.tipo.objetiva' | translate }}
                                </ng-container>
                                <ng-container *ngSwitchCase="assessmentQuestionType.Discursiva">
                                  {{ 'gestor-avaliacoes.tipo.discursiva' | translate }}
                                </ng-container>
                              </span>
                            </div>
                            <div class="flex w-full justify-end">
                              <span
                                >{{ 'gestor-avaliacoes.peso' | translate }}:
                                {{ selectedQuestion.data.peso | currency : '' : '' : '1.2-2' }}</span
                              >
                            </div>
                          </div>
                          <hr app-divider />
                          <div class="mt-5 flex w-full pb-5 pt-0">
                            <app-accordion class="w-full">
                              <app-accordion-item headerClass="!bg-theme-100 ">
                                <div app-accordion-header class="flex">
                                  <svg-icon class="flex h-6" src="assets/icons/icon-pencil.svg"></svg-icon>
                                  <span class="ml-4 w-full text-lg font-semibold active:text-base-mantis-500" translate>
                                    gestor-avaliacoes.novas-questoes.enunciado
                                  </span>
                                </div>
                                <ng-template app-accordion-lazy>
                                  <div
                                    app-accordion-content
                                    class="!border-t-none mb-4 rounded-b-xl border border-gray-200"
                                  >
                                    <div class="flex w-full px-6 pb-5">
                                      <app-text-editor
                                        *ngIf="selectedQuestion.data.enunciado"
                                        [readOnly]="true"
                                        [value]="selectedQuestion.data.enunciado"
                                        [toolbar]="editorConfigToolbar"
                                      ></app-text-editor>
                                    </div>
                                  </div>
                                </ng-template>
                              </app-accordion-item>
                            </app-accordion>
                          </div>

                          <!-- Card Resposta do Aluno -->
                          <span class="p-1 text-md font-bold" translate>
                            gestor-avaliacoes.resposta-do-aluno-dois-ponto
                          </span>
                          <hr app-divider />
                          <div class="grid gap-2 pt-5">
                            <ng-container *ngSwitchCase="assessmentQuestionType.Objetiva">
                              <div
                                class="mb-5 rounded-xl border-x border-b border-base-gray-100 bg-base-gray-light-200 p-4 shadow-inner-bg"
                              >
                                <div
                                  class="mb-3 grid w-full"
                                  *ngFor="let selectedAlternative of selectedQuestion.data.alternativas; let i = index"
                                >
                                  <button
                                    app-button
                                    class="border-1 flex w-full justify-start rounded-md bg-white p-1 shadow-lg transition-all duration-300"
                                    [ngClass]="getButtonClass(selectedAlternative)"
                                  >
                                    <div class="flex w-6">
                                      <svg-icon
                                        *ngIf="selectedAlternative.alternativa_correta"
                                        [ngClass]="getIconClass(selectedAlternative)"
                                        class="flex h-6 w-6 !text-base-mantis-500"
                                        src="assets/icons/icon-check-outline.svg"
                                      ></svg-icon>
                                    </div>
                                    <span
                                      class="ml-3 mr-3 flex rounded-lg border border-solid bg-white px-2 text-[1.2rem] font-medium shadow-sm shadow-black/40"
                                      [ngClass]="getSpanClass(selectedAlternative)"
                                    >
                                      {{ toLetter(i) }}
                                    </span>
                                    <div class="flex w-full">
                                      <span
                                        *ngIf="selectedAlternative.descricao"
                                        class="mb-1 flex w-full items-center text-base"
                                        [innerHTML]="selectedAlternative.descricao"
                                      ></span>
                                    </div>
                                  </button>
                                </div>
                              </div>
                            </ng-container>
                            <ng-container *ngSwitchCase="assessmentQuestionType.Discursiva">
                              <div class="flex w-full items-center justify-between truncate">
                                <div class="grid">
                                  <div class="flex w-full items-center justify-between gap-2">
                                    <span
                                      class="mb-1 whitespace-pre-wrap text-base"
                                      [innerHTML]="selectedQuestion?.answer?.resposta"
                                    ></span>
                                  </div>
                                </div>
                              </div>
                            </ng-container>
                            <ng-template #noAttempt>
                              <div *ngIf="selectedQuestion.data.tipo === assessmentQuestionType.Discursiva">
                                <div class="grid h-auto gap-4 p-5">
                                  <app-card>
                                    <div app-card-content class="h-[200px]">
                                      <app-jumbotron [showIcon]="false" translate>
                                        <svg-icon
                                          class="mb-5 w-10 text-gray-300"
                                          src="assets/icons/icon-info-rounded.svg"
                                          alt="Materiais Didaticos"
                                        ></svg-icon>
                                        gestor-avaliacoes.status.nao-respondido
                                      </app-jumbotron>
                                    </div>
                                  </app-card>
                                </div>
                              </div>
                            </ng-template>
                          </div>
                          <hr app-divider class="mb-5" />
                          <ng-container *ngIf="selectedQuestion.answer; else noAttempt">
                            <ng-container
                              *ngIf="selectedQuestion.data.tipo === assessmentQuestionType.Discursiva; else objetiva"
                            >
                              <div class="w-full">
                                <div class="flex">
                                  <ng-container *ngIf="evaluationByWeight">
                                    <input
                                      [ngClass]="{
                                        'border-red-500': form.controls.nota.invalid && form.controls.nota.touched,
                                        'border-gray-200': !form.controls.nota.invalid || !form.controls.nota.touched
                                      }"
                                      app-input
                                      currencyMask
                                      placeholder="Nota"
                                      id="inputNota"
                                      #inputNota
                                      [formControl]="form.controls.nota"
                                      [options]="scoreConfig"
                                      (keydown.escape)="inputNota.blur()"
                                      class="w-full !rounded-r-none md:w-3/12 lg:w-full xl:w-3/12"
                                    />
                                  </ng-container>
                                  <ng-container *ngFor="let scale of scales">
                                    <ng-container *ngIf="onCalcNota(selectedQuestion.data.peso, scale.value) as result">
                                      <div
                                        [ngClass]="{
                                          'border-red-500': form.controls.nota.invalid && form.controls.nota.touched,
                                          'border-gray-200': !form.controls.nota.invalid || !form.controls.nota.touched,
                                          '  shadow-inner shadow-black/40 ':
                                            (result.nota | currency) === (form.value.nota | currency)
                                        }"
                                        class="border-l-none flex !items-center border border-gray-200 bg-gray-200 last:rounded-r-lg"
                                      >
                                        <button
                                          (click)="onSetNota(result.nota)"
                                          [id]="'btn_' + scale.shortcut"
                                          class="mt-3 flex px-3 md:px-5"
                                        >
                                          <span
                                            *ngIf="assessment.peso_tipo === assessmentWeightType.Peso; else percent"
                                          >
                                            {{ result.nota | currency : '' : '' : '1.2-2' }}
                                          </span>
                                          <ng-template #percent>
                                            {{ scale.value | percent }}
                                          </ng-template>
                                        </button>
                                      </div>
                                    </ng-container>
                                  </ng-container>
                                </div>
                              </div>

                              <app-form-field class="mt-5 w-full">
                                <label app-label class="text-lg" translate>
                                  gestor-avaliacoes.comentario-para-aluno
                                </label>
                                <app-text-editor
                                  class="w-full"
                                  [focus]="inputComentarioFocus$ | async"
                                  [formControl]="form.controls.feedback"
                                  [toolbar]="textEditorToolbar"
                                ></app-text-editor>
                              </app-form-field>
                              <hr app-divider class="mb-5" />
                              <div class="flex justify-end">
                                <div class="flex w-full justify-end">
                                  <button
                                    *ngIf="
                                      selectedQuestion.answer.questao.sociodemografica !== 1 &&
                                      !selectedQuestion.data.anulado &&
                                      !selectedQuestion.answer?.anulado
                                    "
                                    #popoverTop
                                    app-flat-button
                                    type="button"
                                    (click)="onOpenPopover(popoverTop, [strategy.LEFT], selectedQuestion.data)"
                                    class="bg-base-status-danger text-white"
                                    translate
                                  >
                                    gestor-avaliacoes.anular-resposta
                                  </button>

                                  <button
                                    id="btnSalvar"
                                    [disabled]="submitted$ | async"
                                    (click)="onSaveNota(selectedQuestion?.answer?.uuid)"
                                    app-flat-button
                                    class="ml-5 w-6/12 bg-blue-500 text-white md:w-3/12"
                                    type="button"
                                    translate
                                  >
                                    geral.salvar

                                    <ng-container *ngIf="submitted$ | async">
                                      <app-skeleton
                                        class="ml-5 flex !h-3 !w-0"
                                        baseStyle="bg-blue-500"
                                        [showLabel]="false"
                                      ></app-skeleton>
                                    </ng-container>
                                  </button>
                                </div>
                              </div>
                            </ng-container>
                          </ng-container>

                          <!-- BOTAO ANULAR  -->
                          <ng-template #objetiva>
                            <div
                              *ngIf="
                                selectedQuestion.answer.questao.sociodemografica !== 1 &&
                                !selectedQuestion.data.anulado &&
                                !selectedQuestion.answer?.anulado
                              "
                              class="mb-5 flex w-full justify-end"
                            >
                              <button
                                #popoverTop
                                app-flat-button
                                type="button"
                                (click)="onOpenPopover(popoverTop, [strategy.LEFT], selectedQuestion.data)"
                                class="bg-base-status-danger text-white"
                                translate
                              >
                                gestor-avaliacoes.anular-resposta
                              </button>
                            </div>
                          </ng-template>
                        </div>
                      </app-card>
                    </div>
                  </div>
                </ng-container>
                <ng-template #emptyList>
                  <div class="grid h-auto gap-4 p-5">
                    <app-card>
                      <div app-card-content class="h-[200px]">
                        <app-jumbotron [showIcon]="false">
                          <svg-icon
                            class="w-10 text-gray-300"
                            src="assets/icons/icon-info-rounded.svg"
                            alt="Materiais Didaticos"
                          ></svg-icon>
                          <span class="text-md font-semibold text-gray-300" translate>
                            gestor-avaliacoes.nenhum-pendente-de-correcao
                          </span>
                        </app-jumbotron>
                      </div>
                    </app-card>
                  </div>
                </ng-template>
              </div>
            </ng-template>
          </ng-container>
          <ng-template #emptyStudents>
            <app-card>
              <div app-card-content class="w-full">
                <app-jumbotron translate> gestor-avaliacoes.sem-alunos-correcao </app-jumbotron>
              </div>
            </app-card>
          </ng-template>
        </ng-template>
      </app-sidenav-group>
    </div>
  </div>
</ng-container>

<ng-template #emptyTemplateGeneral>
  <div app-modal-content class="h-[80vh] w-[90vw] !p-0">
    <app-skeleton></app-skeleton>
  </div>
</ng-template>

<ng-template #jumbotron>
  <div class="grid h-auto gap-4">
    <app-card>
      <div app-card-content class="h-[200px]">
        <app-jumbotron translate> geral.filtro-vazio </app-jumbotron>
      </div>
    </app-card>
  </div>
</ng-template>
