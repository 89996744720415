import { ChangeDetectionStrategy, Component } from '@angular/core';
import { LoadingService } from '@app/core/services';

@Component({
  templateUrl: './layout-docs.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LayoutDocsComponent {
  constructor(private loadingService: LoadingService) {
    this.loadingService.finish();
  }
}
