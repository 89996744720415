import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { portalConst } from '@app/config';
import { LocalStorageService } from 'ngx-webstorage';
import { BehaviorSubject, Observable } from 'rxjs';
import { Entidade } from '../models';
import { ApiService, Resp } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class CertificadoService {
  public api: any = portalConst.api;

  private _showCertificadoDefault$: BehaviorSubject<boolean> = new BehaviorSubject(true);

  constructor(
    private http: HttpClient,
    private apiService: ApiService,
    private localStorageService: LocalStorageService,
  ) {}

  public getAllCertificado(entidade: Entidade, uc?: boolean, vitrine?: boolean): Observable<any> {
    let params = `?entidades=${JSON.stringify(entidade)}`;
    if (!!uc) {
      params += `&uc=${uc}`;
    }

    if (!!vitrine) {
      params += `&vitrine=${vitrine}`;
    }

    return this.http.get(`${this.api.trilhas}/v1/certificado${params}`);
  }

  public getCertificado(id: number): Observable<any> {
    return this.http.get(`${this.api.trilhas}/v1/certificado/${id}`);
  }

  public postCertificado(data): Observable<any> {
    return this.http.post(`${this.api.trilhas}/v1/certificado`, data);
  }

  public updateCertificado(data): Observable<any> {
    return this.http.put(`${this.api.trilhas}/v1/certificado/${data.id}`, data);
  }

  public deleteCertificado(id): Observable<any> {
    return this.http.delete(`${this.api.trilhas}/v1/certificado/${id}`);
  }

  public setShowCertificadoDefault(state: boolean = true) {
    this._showCertificadoDefault$.next(state);
  }

  public get showCertificadoDefault(): BehaviorSubject<boolean> {
    return this._showCertificadoDefault$;
  }

  public update(id: string, value: any): Observable<Resp<any>> {
    return this.apiService.put(`${this.api.trilhas}/v1/certificado/${id}`, value);
  }

  public store(value: any): Observable<Resp<any>> {
    return this.apiService.post(`${this.api.trilhas}/v1/certificado`, value);
  }
}
