<div class="flex w-full items-center">
  <div class="flex-grow border-t border-dotted border-gray-400"></div>
  <svg-icon
    class="ml-2 flex h-4 w-4 justify-end text-gray-400 transition-transform duration-300"
    src="assets/icons/icon-arrow-down-fine.svg"
    [ngClass]="{ 'rotate-180': showAdvancedOptions }"
  ></svg-icon>
  <span class="mx-2 cursor-pointer text-gray-700 hover:text-gray-400" (click)="toggleAdvancedOptions()" translate>
    gestor-avaliacoes.opcoes-avancada
  </span>
  <svg-icon
    class="ml-2 flex h-4 w-4 justify-end text-gray-400 transition-transform duration-300"
    src="assets/icons/icon-arrow-down-fine.svg"
    [ngClass]="{ 'rotate-180': showAdvancedOptions }"
  ></svg-icon>
  <div class="flex-grow border-t border-dotted border-gray-400"></div>
</div>

<div [@toggleContent]="showAdvancedOptions ? 'visible' : 'hidden'" class="mt-4 overflow-hidden">HELLO WORLD !!!</div>
