<app-modal-header>
  <div class="flex items-center">
    <div class="text-md font-semibold" translate>breadcrumb.trilhas-aprendizagem.cabecalho-modal</div>
  </div>
</app-modal-header>
<div app-modal-content class="h-full w-full !p-0">
  <div class="mb-5 mt-5 grid w-full px-5">
    <div class="mb-5 flex w-full justify-center">
      <span class="text-lg font-semibold" translate> breadcrumb.trilhas-aprendizagem.popover-ativacao </span>
    </div>
    <div class="flex">
      <input
        type="text"
        [placeholder]="'trilhas.curso.digite-voucher' | translate"
        [ngClass]="{
          'border-red-500': (voucher.invalid || !!voucher.errors) && voucher.touched
        }"
        (keyup.enter)="onEnterKeyPressed($event)"
        class="w-full rounded-md bg-white px-2 py-4 text-center text-md"
        maxlength="18"
        [formControl]="voucher"
        oninput="this.value = this.value.toUpperCase()"
        mask="AAAA-AAAAAA-AAAAAA"
      />
      <button
        app-button
        [disabled]="submitted$ | async"
        (click)="onSave()"
        class="ml-2 w-1/3 rounded-md bg-theme-500 px-2 py-4 text-center text-md text-theme-50"
      >
        {{ 'geral.ativar' | translate }}
      </button>
    </div>
  </div>
</div>
