import { Injectable } from '@angular/core';
import { SidenavRef } from './sidenav';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SidenavService {
  private _start$ = new BehaviorSubject<SidenavRef>(null);
  private _end$ = new BehaviorSubject<SidenavRef>(null);

  public get start$(): Observable<SidenavRef> {
    return this._start$.asObservable();
  }

  public get end$(): Observable<SidenavRef> {
    return this._end$.asObservable();
  }

  public registerStart(sidenav: SidenavRef) {
    this._start$.next(sidenav);
  }

  public registerEnd(sidenav: SidenavRef) {
    this._end$.next(sidenav);
  }
}
