import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { LoadingService } from '@app/core/services';
import { AppActions, AppSelectors } from '@app/store';
import { AppState } from '@app/store/app.state';
import { Store } from '@ngrx/store';
import { map, take, tap } from 'rxjs/operators';

@Component({
  selector: 'app-popover-tab-dependents',
  templateUrl: './popover-tab-dependents.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PopoverTabDependentsComponent {
  @Input({ required: true }) public store: AppState;

  constructor(private storeState: Store<AppState>, private loadingService: LoadingService) {}

  public onChange(dependent: any, store: AppState) {
    if (!this.isActived(dependent, store)) {
      this.storeState
        .select(AppSelectors.appFeature)
        .pipe(
          take(1),
          tap(() => this.loadingService.start()),
          map((store) => ({ ...store, dependentId: dependent.aluno_id })),
        )
        .subscribe((user) => this.storeState.dispatch(AppActions.InitDataEffect({ user })));
    }
  }

  public isActived(dependent: any, store: AppState): boolean {
    return dependent.aluno_id === store.usuario_ativo.aluno_id;
  }
}
