import { Pipe, PipeTransform } from '@angular/core';
import { compareLike } from '@app/shared/utils';
import { deburr } from 'lodash';

@Pipe({
  name: 'filterObject',
})
export class FilterObjectPipe implements PipeTransform {
  public transform(items: any[], field: string, term: string) {
    if (!items || !term) {
      return items;
    }
    return items.filter((item) => deburr(item[field].toLocaleLowerCase()).indexOf(term.toLocaleLowerCase()) !== -1);
  }
}
