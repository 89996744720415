import { DialogRef } from '@angular/cdk/dialog';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-modal-header',
  templateUrl: './modal-header.component.html',
  host: { class: 'relative flex justify-between px-5 py-3 bg-white shadow-md z-200 rounded-t-3xl' },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalHeaderComponent {
  @Input() private automaticClose = true;
  @Output() public closeEvent = new EventEmitter<void>();
  constructor(private _dialogRef: DialogRef) {}

  public onClose() {
    if (this.automaticClose) {
      this._dialogRef.close();
    } else {
      this.closeEvent.emit();
    }
  }
}
