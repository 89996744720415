import { ItemGroup } from '@app/core/models/academic-tree';
import { EclassResult } from '@app/core/services';
import { safeDate } from '@app/shared';
import { endOfMonth, endOfYear, isWithinInterval, startOfMonth, startOfYear } from 'date-fns';
import { first, groupBy, last, size, sortBy } from 'lodash';

export function helperItemGroup(academicTree: EclassResult): ItemGroup {
  const groups = size(academicTree.groups);
  const subjects = size(academicTree.subjects);
  if (groups >= subjects) {
    return ItemGroup.Group;
  }
  return ItemGroup.Subject;
}

export function helperAcademicYear(date: Date | string, activeUser: any): number {
  const safe = safeDate(date);
  const academicBimester = Object.entries(groupBy(activeUser.bimestres, 'ano'))
    .map(([year, bimesters]) => ({
      year: Number(year),
      bimesters: [startOfMonth(safeDate(first(bimesters).data_inicio)), endOfMonth(safeDate(last(bimesters).data_fim))],
    }))
    .map(({ year, bimesters }) => ({ year, bimesters: sortBy(bimesters) }))
    .find(({ bimesters }) => isWithinInterval(safe, { start: first(bimesters), end: last(bimesters) }));
  return academicBimester?.year;
}

export function currentAcademicPeriod(activeUser: any): any {
  const period = activeUser.bimestres.filter(({ ano }) => ano === activeUser.ano_letivo);
  if (!!period) {
    return {
      start: startOfMonth(safeDate(first<any>(sortBy(period, 'data_inicio')).data_inicio)),
      end: endOfMonth(safeDate(last<any>(sortBy(period, 'data_fim')).data_fim)),
    };
  }
  return { start: startOfYear(new Date()), end: endOfYear(new Date()) };
}
