<label app-label *ngIf="label">{{ 'gestor-avaliacoes.conteudo' | translate }}</label>
<ng-select
  app-select
  [items]="data$ | async"
  [multiple]="false"
  bindLabel="name"
  [formControl]="ctrl"
  [multiple]="multiple"
  (focus)="onFocus()"
  (change)="onSelect($event)"
  [placeholder]="label ? ('eclass.selecione' | translate) : ('gestor-avaliacoes.conteudo' | translate)"
  [class]="class$ | async"
  [notFoundText]="'geral.nao-encontrado' | translate"
  [ngClass]="{ 'is-invalid': ctrl.invalid && ctrl.touched }"
  [loading]="loading$ | async"
  [loadingText]="'geral.carregando' | translate"
  [groupBy]="groupByFn"
  [groupValue]="groupValueFn"
  [searchable]="true"
  [searchFn]="onSearch"
>
  <ng-template ng-optgroup-tmp let-item="item">
    <span class="block w-full whitespace-normal break-words" style="vertical-align: middle">{{ item.name }}</span>
  </ng-template>
  <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index" let-search="searchTerm">
    <div style="white-space: normal" [style.margin-left]="item.level * 10 + 'px'">
      <span class="block w-full whitespace-normal break-words" [ngOptionHighlight]="search">
        {{ item.name }}
      </span>
    </div>
  </ng-template>
</ng-select>
