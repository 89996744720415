import { Location } from '@angular/common';
import { ChangeDetectionStrategy, Component, DestroyRef } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute, ActivatedRouteSnapshot, IsActiveMatchOptions, NavigationEnd, Router } from '@angular/router';
import { Menu } from '@app/core/menu';
import { MenuService } from '@app/core/services';
import { first, flattenDeep, isEmpty, isEqual } from 'lodash';
import { Observable } from 'rxjs';
import { distinctUntilChanged, filter, map, startWith, switchMap, tap } from 'rxjs/operators';

@Component({
  selector: 'app-submenu',
  templateUrl: './submenu.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubmenuComponent {
  public items$: Observable<Menu[]>;

  constructor(
    public location: Location,
    private router: Router,
    private destroy: DestroyRef,
    private menuService: MenuService,
    private activatedRoute: ActivatedRoute,
  ) {
    this.items$ = this.route().pipe(
      takeUntilDestroyed(this.destroy),
      startWith(null),
      switchMap(() => this.menuService.submenu$),
      map((submenus) => {
        const root = this.rootUrl(this.router.routerState.snapshot.root);
        return submenus.filter(
          (submenu) => !!submenu.redirect || first(flattenDeep([submenu.url])).startsWith(root, 1),
        );
      }),
    );
  }

  private rootUrl(route: ActivatedRouteSnapshot): any {
    if (!route) {
      return null;
    }
    return isEmpty(route.url) ? this.rootUrl(route.firstChild) : first(route.url)?.path;
  }

  public onLink(item: Menu) {
    if (!this.isRouterLinkActive(item)) {
      if (item.redirect) {
        window.open(item.redirect, '_blank');
        return;
      }
      if (!isEmpty(item.defaultUrl)) {
        this.router.navigate(flattenDeep([item.defaultUrl]), { relativeTo: this.activatedRoute });
      } else {
        this.router.navigate(flattenDeep([item.url]), { relativeTo: this.activatedRoute });
      }
    }
  }

  public isRouterLinkActive(item: Menu): boolean {
    const params: IsActiveMatchOptions = {
      matrixParams: 'ignored',
      queryParams: 'ignored',
      fragment: 'ignored',
      paths: item.exact ? 'exact' : 'subset',
    };
    if (!!item.redirect) {
      return false;
    }
    if (!!item.ignore) {
      const ignore = flattenDeep([item.ignore]).some((url) => this.router.isActive(url, params));
      if (ignore) {
        return false;
      }
    }
    return flattenDeep([item.url]).some((url) => this.router.isActive(url, params));
  }

  private route(): Observable<NavigationEnd> {
    return this.router.events.pipe(
      distinctUntilChanged(isEqual),
      filter((event) => event instanceof NavigationEnd),
    );
  }
}
