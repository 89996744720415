import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { SkyEnglishService } from '@app/core/services/sky-english.service';
import { safeEmptyList } from '@app/shared';
import { Strategy } from '@app/shared/modules/template/components/popover';
import { AppSelectors } from '@app/store';
import { AppState } from '@app/store/app.state';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, Subject, switchMap, take, takeUntil, tap } from 'rxjs';

@Component({
  selector: 'app-modal-sky-english',
  templateUrl: './modal-sky-english.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalSkyEnglishComponent implements OnDestroy {
  public readonly strategy = Strategy;
  public activedYear: number;
  public attendanceReports = [];
  public observationReports = [];
  public load$ = new Observable<boolean>();
  public loading$ = new BehaviorSubject<boolean>(false);

  public years = Array.from({ length: new Date().getFullYear() - 2021 }, (_, i) => new Date().getFullYear() - i);
  private _destroy$ = new Subject<void>();

  constructor(private store: Store<AppState>, private skyEnglishService: SkyEnglishService) {
    this.load$ = this.loading$.asObservable();
  }

  public ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
    this.loading$.complete();
  }

  public onSelectObservationReportYear(year: number) {
    this.loading$.next(true);
    this.attendanceReports = [];
    this.activedYear = year;
    this.loadObservationReportData();
  }

  public loadObservationReportData() {
    this.store
      .select(AppSelectors.ActiveUser)
      .pipe(
        tap(() => this.loading$.next(true)),
        take(1),
        takeUntil(this._destroy$),
        switchMap(({ entidade_id }) => this.skyEnglishService.getObservationReports(entidade_id, this.activedYear)),
        safeEmptyList(),
        tap(() => this.loading$.next(false)),
      )
      .subscribe((reports) => (this.observationReports = reports));
  }

  public onSelectAttendanceReportYear(year: number) {
    this.loading$.next(true);
    this.observationReports = [];
    this.activedYear = year;
    this.loadAttendanceReportData();
  }

  public loadAttendanceReportData() {
    this.store
      .select(AppSelectors.ActiveUser)
      .pipe(
        tap(() => this.loading$.next(true)),
        take(1),
        takeUntil(this._destroy$),
        switchMap(({ entidade_id }) => this.skyEnglishService.getAttendanceReports(entidade_id, this.activedYear)),
        safeEmptyList(),
        tap(() => this.loading$.next(false)),
      )
      .subscribe((reports) => (this.attendanceReports = reports));
  }
}
