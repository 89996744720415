import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

const EMPTY = 'assets/icons/icon-default.svg';
// const EMPTY = 'assets/images/empty-avatar.png';

@Component({
  selector: 'app-contents-photo',
  templateUrl: './contents-photo.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentsPhotoComponent {
  @Input() public src: string;
  @Input() public class: string;

  public readonly empty = EMPTY;
}
