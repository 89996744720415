import { Component, DestroyRef, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AssessmentOrigin, Entidade, FunctionsEnum } from '@app/core/models';
import { ModalAssessmentExecutionService } from '@app/modules/assessments-manager/shared/modal-assessment-execution/modal-assessment-execution.service';
import { ModalNewAssessmentService } from '@app/modules/assessments-manager/shared/modal-new-assessment/modal-new-assessment.service';
import { notNull, selectedEntityActive } from '@app/shared';
import { AppSelectors } from '@app/store';
import { AppState } from '@app/store/app.state';
import { Store } from '@ngrx/store';
import { first, isEqual, sum } from 'lodash';
import { BehaviorSubject, Observable, combineLatest, distinctUntilChanged, map, of, switchMap, take } from 'rxjs';

@Component({
  selector: 'app-header-report',
  templateUrl: './header-report.component.html',
})
export class HeaderReportComponent implements OnDestroy {
  @Output() public refresh = new EventEmitter();
  public application$ = new BehaviorSubject<any>(undefined);
  public candEdit$: Observable<boolean>;
  public defaultFunction: number = 0;
  public entityActive: Entidade;
  private _activeUser$ = new BehaviorSubject<any>(undefined);

  constructor(
    private _modalNewAssessmentService: ModalNewAssessmentService,
    private _modalAssessmentExecutionService: ModalAssessmentExecutionService,
    private _store: Store<AppState>,
    private _destroyRef: DestroyRef,
  ) {
    const activeUser$ = this._store
      .select(AppSelectors.ActiveUser)
      .pipe(takeUntilDestroyed(this._destroyRef), notNull());

    activeUser$.pipe(takeUntilDestroyed(this._destroyRef), take(1), distinctUntilChanged(isEqual)).subscribe((user) => {
      this._activeUser$.next(user);
      this.entityActive = first(selectedEntityActive(user));
    });

    this._store
      .select(AppSelectors.DefaultFunction)
      .pipe(takeUntilDestroyed(this._destroyRef), notNull(), distinctUntilChanged(isEqual))
      .subscribe((defaultFunction) => {
        this.defaultFunction = defaultFunction;
      });

    this.candEdit$ = combineLatest({
      activeUser: activeUser$,
      application: this.application$.pipe(notNull()),
    }).pipe(
      map(({ activeUser, application }) => {
        const options = {
          [1]: 'entidade_id',
          [2]: 'campo_id',
          [3]: 'uniao_id',
          [4]: 'editora_id',
          [5]: 'divisao_id',
        };
        const entityActive = first(selectedEntityActive(activeUser));
        const sameEntity = application.avaliacao.entidade_permissao.some(
          (permissao) => permissao[options[entityActive.tipo]] === entityActive.id,
        );

        return sameEntity;
      }),
    );
  }

  @Input('application') public set setApplication(application: any) {
    this.application$.next(application);
  }

  public ngOnDestroy(): void {
    this.application$.complete();
    this._activeUser$.complete();
  }

  public onRefresh() {
    return this.refresh.emit(true);
  }

  public percentage(userAttempt, totalTargetAudience) {
    let percentage = ((userAttempt || 0) * 100) / totalTargetAudience;

    if (percentage > 100) {
      percentage = 100;
    }

    return percentage.toFixed(0);
  }

  public onGetTotal(application: any) {
    return {
      tentativas: sum(application.publico_alvo.map((pa: any) => pa.tentativas_final_count)),
      total: sum(
        application.publico_alvo.map((pa: any) => {
          if (!!application.pre_aplicacao) {
            return !!pa.padrao_serie_id && !pa.turma_id && !pa.user_id ? pa.total_publico_alvo : 0;
          }

          return pa.total_publico_alvo;
        }),
      ),
    };
  }

  public onEdit(applicationUuid?: string): void {
    this._modalNewAssessmentService.open({ aplicacao_uuid: applicationUuid });
  }

  public onPreviewAssessment(aplicationUuid: string) {
    this._modalAssessmentExecutionService.open(AssessmentOrigin.GDA, {
      data: { aplicacao_uuid: aplicationUuid },
      extra: { preview: true },
    });
  }

  public onPreviewer(assessments): boolean {
    return (
      !!assessments.previewer ||
      assessments.avaliacao.criado_por === this._activeUser$.value.usuario_id ||
      this.permissionView(assessments.avaliacao) ||
      [FunctionsEnum.desenvolvedorPortal].includes(this.defaultFunction)
    );
  }

  private permissionView(assessment): boolean {
    const options = {
      [1]: () => 'entidade_id',
      [2]: () => 'campo_id',
      [3]: () => 'uniao_id',
      [4]: () => 'editora_id',
      [5]: () => 'divisao_id',
    };
    const existePermissao = assessment.entidade_permissao.some(
      (permissao) => permissao[options[this.entityActive.tipo]()] === this.entityActive.id,
    );
    return existePermissao;
  }
}
