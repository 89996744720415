<ng-container *ngIf="{ data: data$ | async, load: loading$ | async } as value">
  <div class="border-t-2 border-t-base-mantis-500"></div>
  <app-card *ngIf="!!value.load" class="mt-5">
    <div app-card-content class="h-[200px]">
      <app-skeleton class="h-full w-full"></app-skeleton>
    </div>
  </app-card>
  <ng-container *ngIf="value.data | isEmpty; else dataTemplate">
    <app-card *ngIf="!value.load" class="mt-5">
      <div app-card-content class="h-[200px]">
        <app-jumbotron translate>geral.nenhum-dado</app-jumbotron>
      </div>
    </app-card>
  </ng-container>
  <ng-template #dataTemplate>
    <div class="flex max-h-[80vh] w-full flex-col gap-8">
      <div class="flex max-h-[40vh] w-full flex-col" [ngClass]="{ '!max-h-[80vh]': !value.data[0]?.categories.length }">
        <p class="py-6 text-xl font-semibold">{{ 'gestor-avaliacoes.percentual-acerto-disciplina' | translate }}</p>
        <div app-tab-content class="grid h-full gap-4 overflow-auto">
          <table app-table>
            <thead>
              <tr>
                <th app-table-cell translate class="min-w-[94px]">{{ 'gestor-avaliacoes.unidade' | translate }}</th>
                <ng-container *ngFor="let subject of value.data[0]?.subjects">
                  <th app-table-cell translate class="min-w-[94px]">{{ getSubjectDescription(subject.subject_id) }}</th>
                </ng-container>
                <th app-table-cell translate>{{ 'gestor-avaliacoes.participantes' | translate }}</th>
                <th app-table-cell translate>{{ 'gestor-avaliacoes.participantes-esperados' | translate }}</th>
                <th app-table-cell translate>{{ 'gestor-avaliacoes.percentual-participantes' | translate }}</th>
              </tr>
            </thead>

            <tbody app-table-body class="max-h-[40vh] w-full overflow-auto">
              <tr *ngFor="let data of value.data">
                <td app-table-cell class="text-center">
                  {{ data.entity ? data.entity : data.union || data.association }}
                </td>
                <ng-container *ngFor="let subject of data.subjects">
                  <td app-table-cell class="text-center">{{ subject.percentage_hit | number : '1.2-2' }} %</td>
                </ng-container>
                <td app-table-cell class="text-center">{{ data.student_quantity }}</td>
                <td app-table-cell class="text-center">{{ data.total_student }}</td>
                <td app-table-cell class="text-center">{{ data.percentage }} %</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <ng-container *ngIf="!!value.data[0]?.categories.length">
        <div class="flex max-h-[40vh] w-full flex-col">
          <p class="py-6 text-xl font-semibold">{{ 'gestor-avaliacoes.percentual-acerto-habilidade' | translate }}</p>
          <div app-tab-content class="grid h-full gap-4 overflow-auto">
            <table app-table>
              <thead>
                <tr>
                  <th app-table-cell translate class="min-w-[250px]">{{ 'gestor-avaliacoes.unidade' | translate }}</th>
                  <ng-container *ngFor="let category of value.data[0]?.categories">
                    <th app-table-cell translate class="min-w-[94px]">
                      {{ getCategoryDescription(category.category_id) }}
                    </th>
                  </ng-container>
                </tr>
              </thead>
              <tbody app-table-body class="max-h-[40vh] w-full overflow-auto">
                <tr *ngFor="let data of value.data">
                  <td app-table-cell class="text-center">
                    {{ data.entity ? data.entity : data.union || data.association }}
                  </td>
                  <ng-container *ngFor="let categories of data.categories">
                    <td app-table-cell class="text-center">{{ categories.percentage_hit | number : '1.2-2' }} %</td>
                  </ng-container>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </ng-container>
    </div>
  </ng-template>
</ng-container>
