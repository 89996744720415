import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, NavigationEnd, Router, UrlSegment } from '@angular/router';
import { I18nService } from '@app/core';
import { TranslateService } from '@ngx-translate/core';
import { isEqual, merge } from 'lodash';
import { Observable, distinctUntilChanged, filter, map, startWith } from 'rxjs';
import { Breadcrumb } from './breadcrumb';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class BreadcrumbService {
  public breadcrumbs$: Observable<Breadcrumb[]>;

  constructor(private router: Router, private translateService: TranslateService, private i18nService: I18nService) {
    const route$ = this.route();
    const translate$ = this.i18nService.langChange$;
    this.breadcrumbs$ = merge(route$, translate$).pipe(
      map(() => this.router.routerState.snapshot.root),
      startWith(this.router.routerState.snapshot.root),
      map((route) => this.buildBreadCrumb(route)),
    );
  }

  private route(): Observable<NavigationEnd> {
    return this.router.events.pipe(
      distinctUntilChanged(isEqual),
      filter((event) => event instanceof NavigationEnd),
    );
  }

  private buildBreadCrumb(route: ActivatedRouteSnapshot): Breadcrumb[] {
    if (!route) {
      return [];
    }
    if (route?.routeConfig?.data?.breadcrumb) {
      const breadcrumb = this.breadcrumb(route);
      return [breadcrumb, ...this.buildBreadCrumb(route.firstChild)];
    }
    return this.buildBreadCrumb(route.firstChild);
  }

  private breadcrumb(route: ActivatedRouteSnapshot): Breadcrumb {
    const breadcrumb = route?.routeConfig?.data['breadcrumb'];
    return {
      url: this.fullUrl(route),
      label: this.translateService.instant(breadcrumb, route.data),
      params: route.params || {},
    };
  }

  private fullUrl(route: ActivatedRouteSnapshot): string {
    const relativePath = (s: UrlSegment[]) => s.reduce((a, v) => (a += '/' + v.path), '');
    return route.pathFromRoot.reduce((a, v) => (a += relativePath(v.url)), '');
  }
}
