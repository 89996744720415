<app-modal-header>
  <div class="flex">
    <svg-icon class="ml-6 w-7 text-base-status-warning" src="assets/icons/icon-bell.svg"></svg-icon>
    <p class="ml-6 text-lg font-semibold" translate>notificacoes.modal-titulo</p>
  </div>
</app-modal-header>
<div app-modal-content class="h-[90vh] w-[90vw] !p-0 md:h-[calc(100vh_-_15rem)] xl:h-[50rem] xl:w-[80rem]">
  <app-sidenav-group class="h-full w-full">
    <ng-template
      app-sidenav-start
      panelWidth="250px"
      [opened]="true"
      bgColor="bg-base-whisper-500 rounded-bl-3xl !shadow-inner-horizontal"
    >
      <div class="h-full w-[250px] p-4">
        <div class="flex flex-col gap-2 overflow-y-auto px-3 pb-5">
          <ng-container *ngIf="counters | noEmpty; else loading">
            <button
              *ngFor="let counter of counters"
              app-button
              type="button"
              [ngClass]="styleButton(counter)"
              class="group flex justify-start gap-2 !rounded-full px-5 text-gray-800 transition duration-300 first:uppercase"
              (click)="onFilter(counter)"
            >
              <div class="whitespace-nowrap">{{ counter.label | translate }}</div>
              <div
                class="w-14 rounded-full bg-base-status-warning px-1 leading-4 text-white transition duration-300"
                *ngIf="!!counter.value"
              >
                {{ counter.value }}
              </div>
              <div class="flex w-full justify-end">
                <svg-icon
                  [ngClass]="stylePointer(counter)"
                  class="flex h-2 w-2 transition duration-300"
                  src="assets/icons/icon-ellipse.svg"
                ></svg-icon>
              </div>
            </button>
          </ng-container>
        </div>
      </div>
    </ng-template>

    <ng-template app-sidenav-outer>
      <div class="mb-3 flex h-[90vh] flex-col gap-2 overflow-y-auto p-4 py-5 md:h-[calc(100vh_-_16rem)] xl:h-[49rem]">
        <ng-container *ngIf="{ notifications: notification$ | async, load: load$ | async, } as data">
          <ng-container *ngIf="!data.load; else loading">
            <ng-container *ngIf="data.notifications | noEmpty; else notificationsEmpty">
              <h1 class="px-3 py-5 text-2xl font-semibold text-gray-700">
                {{ title(current) | translate }}
                <span class="text-2xl lowercase text-gray-600" translate>notificacoes.modal-titulo</span>
              </h1>
              <div
                class="flex flex-col gap-1 overflow-y-auto px-3"
                infinite-scroll
                [infiniteScrollDistance]="scrollDistance"
                [infiniteScrollUpDistance]="scrollUpDistance"
                [infiniteScrollThrottle]="throttle"
                (scrolled)="onScrollDown()"
                [scrollWindow]="false"
              >
                <app-item-notification
                  *ngFor="let notification of data.notifications"
                  [notification]="notification"
                  (afterRead)="onAfterRead(notification)"
                ></app-item-notification>
              </div>
            </ng-container>
          </ng-container>
        </ng-container>
      </div>
    </ng-template>
  </app-sidenav-group>

  <ng-template #loading>
    <div class="flex flex-wrap justify-center gap-2">
      <app-skeleton *ngFor="let skeleton of [1, 2, 3, 4, 5]" class="block !h-14 rounded-xl shadow"></app-skeleton>
    </div>
  </ng-template>

  <ng-template #notificationsEmpty>
    <app-jumbotron translate>notificacoes.vazio</app-jumbotron>
  </ng-template>
</div>
