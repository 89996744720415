import { Dialog } from '@angular/cdk/dialog';
import { Injectable } from '@angular/core';
import { ModalServicesComponent } from '../components/modal-services/modal-services.component';

@Injectable({
  providedIn: 'root',
})
export class ModalServicesService {
  constructor(private dialog: Dialog) {}

  public open() {
    this.dialog.open(ModalServicesComponent);
  }
}
