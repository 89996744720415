import { Pipe, PipeTransform } from '@angular/core';
import { head } from 'lodash';

@Pipe({
  name: 'first',
})
export class FirstPipe implements PipeTransform {
  public transform(data: any[] = []): any {
    return head(data);
  }
}
