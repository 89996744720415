import { Injectable } from '@angular/core';
import { portalConst } from '@app/config';
import { ApiService } from './api.service';
import { CrudService } from './crud.service';
import { GroupPerformanceMetric } from '../models/group-performance-metric.model';

@Injectable({
  providedIn: 'root',
})
export class GroupPerformanceMetricsService extends CrudService<GroupPerformanceMetric> {
  constructor(api: ApiService) {
    super(api, `${portalConst.api.academic}/v1/group-performance-metric`);
  }
}
