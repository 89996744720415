import { Injectable } from '@angular/core';
import { portalConst } from '@app/config';
import { Observable } from 'rxjs';
import { ApiService, Page, Resp } from './api.service';
import { Content } from '../models';

@Injectable({
  providedIn: 'root',
})
export class SchoolWebsiteService {
  constructor(private api: ApiService) {}

  public contents(entityId: number): Observable<Resp<Content[]>> {
    return this.api.get(`${portalConst.api.school}/v1/portal/contents/${entityId}`);
  }

  public list(entityId: number, req?: any): Observable<Resp<Page<Content>>> {
    return this.api.page(`${portalConst.api.school}/v1/portal/contents/${entityId}`, req);
  }
}
