export interface FinanceDocument {
  id: number;
  entity_id: number;
  category_id: number;
  user_id: number;
  description: string;
  file: string;
  file_name: string;
  size: string;
  extension: string;
  document_type: number;
  target_audience: number;
  created_at: string;
}
