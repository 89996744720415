import { Dialog } from '@angular/cdk/dialog';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AssessmentApplicationMode, GenericModel } from '@app/core/models';
import { notNull } from '@app/shared';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { isNil } from 'lodash';
import { BehaviorSubject, Observable } from 'rxjs';
import { ModalAssessmentExecutionService } from '../../modal-assessment-execution.service';
import { ModalAssessmentExecutionActions, ModalAssessmentExecutionSelectors } from '../../store';
import { ModalAssessmentExecutionState } from '../../store/modal-assessment-execution.state';
import { ModalFinalizeComponent } from '../modal-finalize/modal-finalize.component';

@Component({
  selector: 'app-assessment-execution-settings',
  templateUrl: './assessment-execution-settings.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssessmentExecutionSettingsComponent {
  public readonly assessmentApplicationMode = AssessmentApplicationMode;
  public state$: Observable<ModalAssessmentExecutionState>;
  public submit$ = new BehaviorSubject<boolean>(false);

  public readonly applicationModes: GenericModel[] = [
    {
      id: AssessmentApplicationMode.Online,
      description: this._translateService.instant('gestor-avaliacoes.modos.online'),
    },
    {
      id: AssessmentApplicationMode.Impressa,
      description: this._translateService.instant('gestor-avaliacoes.modos.impressa'),
    },
    {
      id: AssessmentApplicationMode.Hibrido,
      description: this._translateService.instant('gestor-avaliacoes.modos.hibrido-min'),
    },
  ];

  constructor(
    private _translateService: TranslateService,
    private _store: Store<ModalAssessmentExecutionState>,
    private _dialog: Dialog,
    private _modalAssessmentExecutionService: ModalAssessmentExecutionService,
  ) {
    this.state$ = this._store.select(ModalAssessmentExecutionSelectors.selectState);
  }

  public isAnswered(answer: any) {
    return !isNil(answer?.resposta) || !isNil(answer?.alternativaUuid);
  }

  public onChangeQuestion(index: string) {
    this._modalAssessmentExecutionService.scrollToQuestion$.next(index);
  }

  public onDisableSave(state: ModalAssessmentExecutionState): boolean {
    return state.questoes.some((question) => !!question.loading);
  }

  public onConfirmFinalize() {
    this._dialog
      .open<boolean>(ModalFinalizeComponent)
      .closed.pipe(notNull())
      .subscribe(() => this.finalize());
  }

  private finalize() {
    this.submit$.next(true);
    const actions = {
      error: () => this.submit$.next(false),
      success: () => this.submit$.next(false),
    };
    this._store.dispatch(ModalAssessmentExecutionActions.FinalizeResolucaoEffect({ actions }));
  }
}
