import { Directive, HostListener, Inject, Input, Optional } from '@angular/core';
import { PopoverRef } from '.';

@Directive({
  selector: '[popoverClose]',
})
export class PopoverCloseDirective {
  @Input() public popoverClose: any;

  constructor(@Optional() public popoverRef: PopoverRef) {}

  @HostListener('click') public click() {
    this?.popoverRef?.close(this.popoverClose);
  }
}
