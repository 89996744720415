<table app-table>
  <thead>
    <tr>
      <th app-table-cell class="rounded-lg !bg-modules-sky-english-500 p-3 !text-center" colspan="3">
        <p class="font-semibold">{{ 'servicos.sky-english.relatorio-observacao' | translate }} - {{ activedYear }}</p>
      </th>
    </tr>
  </thead>
  <tbody>
    <ng-container *ngFor="let group of observationReports; let i = index">
      <tr (click)="toggleExpand(i)" class="rounded-lg shadow" class="!text-center" style="cursor: pointer">
        <td class="rounded rounded-md bg-gray-200 p-2 !text-center font-bold" colspan="3">
          {{ group[0]?.teacher?.name }}
        </td>
      </tr>
      <ng-container *ngIf="expandedIndex === i">
        <tr *ngFor="let report of group; let j = index">
          <td class="!text-center">
            {{ report.visit_date | date : 'dd/MM/yyyy' }}
          </td>
          <td class="!text-center">
            {{ report.teacher.name }}
          </td>
          <td class="!text-center">
            <div class="justify-content-between relative flex">
              <button
                #popoverLeft
                app-outline-button
                app-tooltip
                [tooltip]="'servicos.sky-english.baixar-relatorio' | translate"
                type="button"
                class="border-none font-semibold"
                (click)="onOpenPopover(popoverLeft, [strategy.LEFT], report)"
              >
                <svg-icon class="flex h-7 w-7 text-base-service-sky-english" [src]="'pdf' | extensionIcon"></svg-icon>
              </button>
            </div>
          </td>
        </tr>
      </ng-container>
    </ng-container>
  </tbody>
</table>
