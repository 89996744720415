<div app-modal-content [formGroup]="form" class="w-[80vw] !p-0 !pb-3">
  <app-modal-header>
    <div class="flex">
      <div
        class="h-16 w-16 flex-shrink-0 self-start rounded-tl-lg bg-green-700 p-2 text-base-status-warning [box-shadow:0px_2px_4px_0px_rgba(0,_0,_0,_0.15)]"
      >
        <svg-icon src="assets/icons/icon-new-course.svg"></svg-icon>
      </div>
      <hr app-modal-divider />
      <div class="flex items-center gap-2">
        <h1 translate class="text-xl font-medium">trilhas.estudio.curso.certificado</h1>
      </div>
    </div>
  </app-modal-header>
  <div class="flex h-[80vh] flex-col overflow-auto p-5 md:flex-row">
    <div app-card-content class="w-full md:w-3/5">
      <div class="w-full">
        <div class="pb-4">
          <div class="flex items-baseline gap-1">
            <label class="!text-[14px]" app-label translate>trilhas.certificado.titulo</label>
            <button
              app-tooltip
              [tooltip]="'trilhas.certificado.aviso-titulo' | translate"
              class="relative right-2"
              app-icon-button
              type="button"
            >
              <svg-icon src="assets/icons/icon-interrogation.svg" class="w-4"></svg-icon>
            </button>
          </div>
          <app-form-field [required]="false">
            <input
              app-input
              formControlName="titulo"
              (change)="onChangeCharacter()"
              [placeholder]="'trilhas.certificado.nome-do-certificado' | translate"
            />
          </app-form-field>
        </div>
        <div class="pb-4">
          <div class="flex items-baseline gap-1">
            <label class="!text-[14px]" app-label translate>trilhas.certificado.texto</label>
            <button
              app-tooltip
              [tooltip]="'trilhas.certificado.aviso-texto' | translate"
              class="relative right-2"
              app-icon-button
              type="button"
            >
              <svg-icon src="assets/icons/icon-interrogation.svg" class="w-4"></svg-icon>
            </button>
          </div>
          <app-form-field [required]="false">
            <textarea
              app-textarea
              [placeholder]="'trilhas.certificado.digite-aqui-o-texto' | translate"
              name=""
              cols="30"
              rows="6"
              id="inputText"
              #inputTextCertificado
              formControlName="texto"
              class="block w-full p-4"
            ></textarea>
          </app-form-field>
        </div>

        <div class="flex items-baseline">
          <button app-tooltip [tooltip]="'trilhas.certificado.aviso-tags' | translate" app-icon-button type="button">
            <svg-icon src="assets/icons/icon-interrogation.svg" class="w-4"></svg-icon>
          </button>
          <div class="flex items-center gap-2">
            <p class="text-md">{{ 'trilhas.certificado.tags-disponiveis' | translate }}</p>
            <app-badge (click)="copyTag('[NOME_PARTICIPANTE]', inputTextCertificado)">
              <strong>{{ 'trilhas.certificado.participante' | translate }}</strong>
            </app-badge>
            <app-badge (click)="copyTag('[TITULO_CURSO]', inputTextCertificado)">
              <strong>{{ 'trilhas.certificado.curso' | translate }}</strong>
            </app-badge>
            <app-badge (click)="copyTag('[DATA_CONCLUSAO]', inputTextCertificado)">
              <strong>{{ 'trilhas.certificado.conclusao' | translate }}</strong>
            </app-badge>
          </div>
        </div>
        <hr app-divider class="my-5" />
        <div>
          <div class="pb-4">
            <label class="!text-[14px]" app-label translate>trilhas.certificado.assinatura-1</label>
            <app-upload-file
              (statuschange)="onUpload('hash_assinatura_1', 'file_assinatura_1', $event)"
              (processing)="disableSendUploadThumbnail($event)"
              (queueComplete)="enableSendOnUploadThumbnail($event)"
              [config]="config"
              [image]="form.value.file_assinatura_1"
              [invalid]="(submitted$ | async) && fileInvalid1$ | async"
              required
              [titulo]="'trilhas.dropzone.imagem' | translate"
            >
            </app-upload-file>
          </div>
          <div class="flex flex-col gap-4">
            <div>
              <app-form-field [required]="false">
                <input
                  formControlName="nome_assinatura_1"
                  app-input
                  [placeholder]="'trilhas.certificado.nome*' | translate"
                />
              </app-form-field>
            </div>
            <div>
              <app-form-field [required]="false">
                <input
                  formControlName="linha_1_assinatura_1"
                  app-input
                  [placeholder]="'trilhas.certificado.Linha_1_2' | translate"
                />
              </app-form-field>
            </div>
            <div>
              <app-form-field [required]="false">
                <input
                  formControlName="linha_2_assinatura_1"
                  app-input
                  [placeholder]="'trilhas.certificado.Linha_2_2' | translate"
                />
              </app-form-field>
            </div>
          </div>
        </div>
        <hr app-divider class="my-5" />
        <div>
          <div class="pb-4">
            <label class="!text-[14px]" app-label translate>trilhas.certificado.assinatura-2</label>
            <app-upload-file
              (statuschange)="onUpload('hash_assinatura_2', 'file_assinatura_2', $event)"
              [config]="config"
              [image]="form.value.file_assinatura_2"
              [invalid]="(submitted$ | async) && fileInvalid2$ | async"
              required
              [titulo]="'trilhas.dropzone.imagem' | translate"
            >
            </app-upload-file>
          </div>
          <div class="flex flex-col gap-4">
            <div>
              <app-form-field [required]="false">
                <input
                  formControlName="nome_assinatura_2"
                  app-input
                  [placeholder]="'trilhas.certificado.nome*' | translate"
                  (keyup)="onChangeCharacterName2()"
                />
              </app-form-field>
            </div>
            <div>
              <app-form-field [required]="false">
                <input
                  formControlName="linha_1_assinatura_2"
                  app-input
                  [placeholder]="'trilhas.certificado.Linha_1_2' | translate"
                />
              </app-form-field>
            </div>
            <div>
              <app-form-field [required]="false">
                <input
                  formControlName="linha_2_assinatura_2"
                  app-input
                  [placeholder]="'trilhas.certificado.Linha_2_2' | translate"
                />
              </app-form-field>
            </div>
          </div>
        </div>
        <hr app-divider class="my-5" />
        <div>
          <div class="pb-4">
            <label class="!text-[14px]" app-label translate>trilhas.certificado.assinatura-3</label>
            <app-upload-file
              (statuschange)="onUpload('hash_assinatura_3', 'file_assinatura_3', $event)"
              [config]="config"
              [image]="form.value.file_assinatura_3"
              [invalid]="(submitted$ | async) && fileInvalid3$ | async"
              required
              [titulo]="'trilhas.dropzone.imagem' | translate"
            >
            </app-upload-file>
          </div>
          <div class="flex flex-col gap-4">
            <div>
              <app-form-field [required]="false">
                <input
                  formControlName="nome_assinatura_3"
                  app-input
                  [placeholder]="'trilhas.certificado.nome*' | translate"
                  (keyup)="onChangeCharacterName3()"
                />
              </app-form-field>
            </div>
            <div>
              <app-form-field [required]="false">
                <input
                  formControlName="linha_1_assinatura_3"
                  app-input
                  [placeholder]="'trilhas.certificado.Linha_1_2' | translate"
                />
              </app-form-field>
            </div>
            <div>
              <app-form-field [required]="false">
                <input
                  formControlName="linha_2_assinatura_3"
                  app-input
                  [placeholder]="'trilhas.certificado.Linha_2_2' | translate"
                />
              </app-form-field>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="w-full p-4 md:w-2/5">
      <app-preview-certificado formControlName="hash_background" [data]="onData()"></app-preview-certificado>
      <div class="flex flex-col gap-4 pt-4">
        <button
          (click)="onChangesCertificadoDefault()"
          app-flat-button
          class="w-full bg-blue-500 text-theme-contrast"
          type="button"
        >
          {{ 'trilhas.certificado.imagem-base' | translate }}
        </button>
        <button app-flat-button class="w-full bg-theme-500 text-theme-contrast" type="button">
          <a type="button" class="btn btn-success btn-sm btn-block" [href]="downloadModelo" [download]="nomeModelo">
            {{ 'trilhas.certificado.baixar-modelo' | translate }}
          </a>
        </button>
      </div>
    </div>
  </div>
  <div app-card-footer class="flex items-center justify-end gap-5">
    <button
      app-outline-button
      class="!h-10 w-48 border-base-status-danger text-base-status-danger hover:[&:not([disabled])]:bg-base-status-danger hover:[&:not([disabled])]:text-theme-contrast"
      type="button"
      (click)="onCancel()"
    >
      <span class="font-medium uppercase" translate>trilhas.estudio.curso.cancelar</span>
    </button>

    <button
      (click)="onSubmit()"
      app-outline-button
      class="w-48 border-theme-500 text-theme-500 hover:[&:not([disabled])]:bg-theme-500 hover:[&:not([disabled])]:text-theme-contrast"
      type="button"
    >
      <span class="font-medium uppercase" translate>trilhas.estudio.curso.salvar</span>
    </button>
  </div>
</div>
