<div class="relative mx-5 my-3 justify-center">
  <input
    app-input
    class="rounded-xl pr-9"
    [placeholder]="'perfil.buscar-entidade' | translate"
    [formControl]="searchCtrl"
  />
  <svg-icon src="assets/icons/icon-search.svg" class="absolute right-4 top-4 w-4"></svg-icon>
</div>

<div class="h-[250px] overflow-auto bg-theme-100 p-5 shadow-inner-bg">
  <ng-container *ngIf="(loading$ | async) === false; else loading">
    <ng-container *ngIf="entities$ | async | filter : searchCtrl.value : ['nome', 'info_escola'] as entities">
      <ng-container *ngIf="isEmpty(entities); else dataTemplate">
        <app-jumbotron translate>geral.filtro-vazio</app-jumbotron>
      </ng-container>
      <ng-template #dataTemplate>
        <div class="flex flex-col gap-2">
          <app-card
            *ngFor="let entitiy of entities"
            class="border-theme-500"
            [ngClass]="{
              'border-l-[6px] bg-theme-50': isActived(entitiy, store),
              'cursor-pointer hover:bg-theme-50': !isActived(entitiy, store),
            }"
            (click)="onChange(entitiy)"
          >
            <div app-card-content class="!px-3 !py-2 text-start">
              <span class="font-semibold">{{ entitiy.info_escola || '-' }}</span>
              <div class="text-gray-500">{{ entitiy.nome || '-' }}</div>
            </div>
          </app-card>
        </div>
      </ng-template>
    </ng-container>
  </ng-container>

  <ng-template #loading>
    <div class="flex flex-col gap-2">
      <app-card *ngFor="let entitiy of [1, 2, 3, 4, 5, 6]" class="border-theme-500">
        <div app-card-content class="!px-3 !py-2 text-start">
          <app-skeleton class="block !h-[36px] w-full"></app-skeleton>
        </div>
      </app-card>
    </div>
  </ng-template>
</div>
