import { DIALOG_DATA, Dialog, DialogRef } from '@angular/cdk/dialog';
import { Component, Inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { portalConst } from '@app/config';
import { AlertService, LoadingService, NotificationService } from '@app/core';
import { CertificadoService } from '@app/core/services/certificado.service';
import { CertificateService } from '@app/core/services/certificate.service';
import { notNull, selectedEntityActive } from '@app/shared';
import { AppSelectors } from '@app/store';
import { AppState } from '@app/store/app.state';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { BehaviorSubject, finalize, switchMap, tap } from 'rxjs';
import { ModalCertificateComponent } from '../modal-certificate/modal-certificate.component';

@Component({
  selector: 'app-modal-certificate-form',
  templateUrl: './modal-certificate-form.component.html',
  styles: [],
})
export class ModalCertificateFormComponent {
  public form: FormGroup;
  public downloadModelo: string;
  public nomeModelo: string;
  public fileInvalid1$ = new BehaviorSubject<boolean>(false);
  public fileInvalid2$ = new BehaviorSubject<boolean>(false);
  public fileInvalid3$ = new BehaviorSubject<boolean>(false);
  public submitted$ = new BehaviorSubject<boolean>(false);
  public config: DropzoneConfigInterface = {
    clickable: true,
    url: `${portalConst.api.usuario}/temp-upload/v1/file`,
    params: {
      savePhoto: true,
      largura: 400,
      altura: 150,
      limiteMaximo: true,
      pesoMaximo: 50,
      stringKbMb: 'kb',
    },
    createImageThumbnails: false,
    maxFiles: 1,
    maxFilesize: 0.15,
    acceptedFiles: '.png',
    previewsContainer: false,
  };

  constructor(
    @Inject(DIALOG_DATA) public data: any,
    private _formBuilder: FormBuilder,
    private _store: Store<AppState>,
    private _certificadoService: CertificadoService,
    private _certificateService: CertificateService,
    private _alertService: AlertService,
    private _dialogRef: DialogRef,
    private _dialog: Dialog,
    private _translateService: TranslateService,
    private _notificationService: NotificationService,
    private _loadingService: LoadingService,
  ) {
    this.initForm();

    this._store
      .select(AppSelectors.ActiveUser)
      .pipe(notNull(), takeUntilDestroyed())
      .subscribe((user) => {
        this.form.get('entidades').setValue(selectedEntityActive(user));
      });

    this._store
      .select(AppSelectors.isBrasil)
      .pipe(takeUntilDestroyed())
      .subscribe((isBrazil) => {
        this.downloadModelo = isBrazil
          ? 'assets/images/certificate-default/certificados-padrao-gabarito.zip'
          : 'assets/images/certificate-default/certificados-padrao-gabarito-es.zip';
        this.nomeModelo = isBrazil ? 'certificados-padrao-gabarito.zip' : 'modelo-de-certificado.zip';
      });
  }

  private initForm() {
    this.form = this._formBuilder.group({
      id: [this.data?.id || undefined],
      titulo: [this.data?.title || undefined, [Validators.required, Validators.maxLength(70), Validators.minLength(3)]],
      texto: [this.data?.text || undefined, [Validators.required, Validators.maxLength(250), Validators.minLength(3)]],
      entidades: undefined,

      hash_assinatura_1: undefined,
      file_assinatura_1: [this.data?.assinaturas[0]?.assinatura || undefined, Validators.required],
      nome_assinatura_1: [
        this.data?.assinaturas[0]?.nome || undefined,
        [Validators.required, Validators.maxLength(40), Validators.minLength(3)],
      ],
      linha_1_assinatura_1: [
        this.data?.assinaturas[0]?.linha_1 || undefined,
        [Validators.maxLength(40), Validators.minLength(3)],
      ],
      linha_2_assinatura_1: [
        this.data?.assinaturas[0]?.linha_2 || undefined,
        [Validators.maxLength(40), Validators.minLength(3)],
      ],

      hash_assinatura_2: undefined,
      file_assinatura_2: [this.data?.assinaturas[1]?.assinatura || undefined],
      nome_assinatura_2: [
        this.data?.assinaturas[1]?.nome || undefined,
        [Validators.maxLength(40), Validators.minLength(3)],
      ],
      linha_1_assinatura_2: [
        this.data?.assinaturas[1]?.linha_1 || undefined,
        [Validators.maxLength(40), Validators.minLength(3)],
      ],
      linha_2_assinatura_2: [
        this.data?.assinaturas[1]?.linha_2 || undefined,
        [Validators.maxLength(40), Validators.minLength(3)],
      ],

      hash_assinatura_3: undefined,
      file_assinatura_3: [this.data?.assinaturas[2]?.assinatura || undefined],
      nome_assinatura_3: [
        this.data?.assinaturas[2]?.nome || undefined,
        [Validators.maxLength(40), Validators.minLength(3)],
      ],
      linha_1_assinatura_3: [
        this.data?.assinaturas[2]?.linha_1 || undefined,
        [Validators.maxLength(40), Validators.minLength(3)],
      ],
      linha_2_assinatura_3: [
        this.data?.assinaturas[2]?.linha_2 || undefined,
        [Validators.maxLength(40), Validators.minLength(3)],
      ],

      hash_background: undefined,
      background: [this.data?.backgroundImage || undefined],
    });

    this.form.controls.file_assinatura_2.valueChanges.subscribe((value) => {
      if (!!value) {
        this.form.controls.nome_assinatura_2.setValidators([Validators.required, Validators.maxLength(40)]);
      } else {
        this.form.controls.nome_assinatura_2.setValidators([]);
      }
    });

    this.form.controls.nome_assinatura_2.valueChanges.subscribe((value) => {
      if (!!value) {
        this.form.controls.file_assinatura_2.setValidators([Validators.required]);
      } else {
        this.form.controls.file_assinatura_2.setValidators([]);
      }
    });

    this.form.controls.file_assinatura_3.valueChanges.subscribe((value) => {
      if (!!value) {
        this.form.controls.nome_assinatura_3.setValidators([Validators.required]);
      } else {
        this.form.controls.nome_assinatura_3.setValidators([]);
      }
    });

    this.form.controls.nome_assinatura_3.valueChanges.subscribe((value) => {
      if (!!value) {
        this.form.controls.file_assinatura_3.setValidators([Validators.required]);
      } else {
        this.form.controls.file_assinatura_3.setValidators([]);
      }
    });

    this.form.valueChanges.subscribe(() => {
      this.form.markAllAsTouched();
      this.form.markAsPristine();
    });
  }

  public onSubmit() {
    this.submitted$.next(true);
    this.verifyFiles();
    if (this.form.valid) {
      this.save();
    } else {
      this.form.markAllAsTouched();
    }
  }

  private save() {
    this._alertService
      .confirm({ message: this._translateService.instant(`trilhas.certificado.deseja-salvar`) })
      .pipe(
        finalize(() => this.submitted$.next(false)),
        notNull(),
        tap(() => this._loadingService.start()),
        switchMap(() =>
          !!this.form.value.id
            ? this._certificadoService.update(this.form.value.id, this.form.value)
            : this._certificadoService.store(this.form.value),
        ),
        tap(() => this._loadingService.finish()),
      )
      .subscribe({
        next: (resp) => {
          this._notificationService.success('geral.sucesso');
          this._dialogRef.close();
          if (!!this.form.value.id) {
            this._certificateService.passCertificate({
              ...this.form.value,
              titulo: resp['titulo'],
              id: resp['id'],
              background: resp['background'],
            });
          } else {
            this._dialog
              .open(ModalCertificateComponent, {
                hasBackdrop: true,
              })
              .closed.pipe(notNull())
              .subscribe();
          }
        },
        error: (e) => {
          this._alertService.alert({ message: e.error.msg });
        },
      });
  }

  public onFormat(certificado: any) {
    return {
      text: certificado.texto,
      backgroundImage: certificado.background || 'assets/images/certificado-padrao.jpg',
      assinaturas: [
        {
          assinatura: certificado.file_assinatura_1,
          nome: certificado.nome_assinatura_1,
          linha_1: certificado.linha_1_assinatura_1,
          linha_2: certificado.linha_2_assinatura_1,
        },
        {
          assinatura: certificado.file_assinatura_2,
          nome: certificado.nome_assinatura_2,
          linha_1: certificado.linha_1_assinatura_2,
          linha_2: certificado.linha_2_assinatura_2,
        },
        {
          assinatura: certificado.file_assinatura_3,
          nome: certificado.nome_assinatura_3,
          linha_1: certificado.linha_1_assinatura_3,
          linha_2: certificado.linha_2_assinatura_3,
        },
      ],
    };
  }

  public onData() {
    return {
      title: this.form.value.titulo,
      text: this.form.value.texto,
      backgroundImage: this.form.value.background || 'assets/images/certificate-default/certificado-padrao.jpg',
      assinaturas: [
        {
          assinatura: this.form.value.file_assinatura_1,
          nome: this.form.value.nome_assinatura_1,
          linha_1: this.form.value.linha_1_assinatura_1,
          linha_2: this.form.value.linha_2_assinatura_1,
        },
        {
          assinatura: this.form.value.file_assinatura_2,
          nome: this.form.value.nome_assinatura_2,
          linha_1: this.form.value.linha_1_assinatura_2,
          linha_2: this.form.value.linha_2_assinatura_2,
        },
        {
          assinatura: this.form.value.file_assinatura_3,
          nome: this.form.value.nome_assinatura_3,
          linha_1: this.form.value.linha_1_assinatura_3,
          linha_2: this.form.value.linha_2_assinatura_3,
        },
      ],
    };
  }

  public copyTag(tag: string, input) {
    if (!this.form.value.texto) {
      this.form.value.texto = '';
    }
    if (this.form.value.texto?.length + tag.length <= 250) {
      const texto_array = this.form.value.texto.split('');
      let inicio = true;
      for (let i = texto_array.length - 1; i >= input.selectionStart; i--) {
        if (inicio) {
          texto_array.push(texto_array[i]);
          inicio = false;
        } else {
          texto_array[i + 1] = texto_array[i];
        }
      }
      texto_array[input.selectionStart] = tag;
      this.form.get('texto').setValue(texto_array.join('').trim());
    }
    input.focus();
  }

  public onChangeCharacter() {
    const name = this.clearStr(this.form.controls.titulo.value);
    this.form.controls.titulo.setValue(name);
  }

  public onChangeCharacterName2() {
    if (!this.form.controls.nome_assinatura_2.value) {
      this.fileInvalid2$.next(false);
    }
  }

  public onChangeCharacterName3() {
    if (!this.form.controls.nome_assinatura_3.value) {
      this.fileInvalid3$.next(false);
    }
  }

  private clearStr(str) {
    if (!!str) {
      str = str.replace(/@/gi, '');
    }
    return str;
  }

  public onCancel() {
    this._dialogRef.close();
    if (!this.data) {
      this._dialog
        .open(ModalCertificateComponent, {
          hasBackdrop: true,
        })
        .closed.pipe(notNull())
        .subscribe();
    }
  }

  public onChangesCertificadoDefault() {
    this._certificadoService.setShowCertificadoDefault(!this._certificadoService.showCertificadoDefault.value);
  }

  public onUpload(nameHash: string, nameFile: string, event) {
    if (event.success) {
      this.form.get(nameHash).setValue(event.hash);
      this.form.get(nameFile).setValue(portalConst.s3_path_temp + event.file);
      this.fileInvalid1$.next(false);
      this.fileInvalid2$.next(false);
      this.fileInvalid3$.next(false);
      this.form.markAsDirty();
      this.form.markAsTouched();
    }
  }

  public disableSendUploadThumbnail(data) {
    if (!!data.uploading) {
      this.submitted$.next(true);
    }
  }

  public enableSendOnUploadThumbnail(data) {
    if (!!data.finished) {
      this.submitted$.next(false);
    }
  }

  public verifyFiles() {
    if (!this.form.value.file_assinatura_1) {
      this.fileInvalid1$.next(true);
    }
    if (!this.form.value.file_assinatura_2 && this.form.value.nome_assinatura_2) {
      this.fileInvalid2$.next(true);
    }
    if (!this.form.value.file_assinatura_3 && this.form.value.nome_assinatura_3) {
      this.fileInvalid3$.next(true);
    }
  }
}
