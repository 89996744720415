import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectSubjectComponent } from './select-subject.component';
import { TemplateModule } from '../template/template.module';

@NgModule({
  declarations: [SelectSubjectComponent],
  imports: [CommonModule, TemplateModule],
  exports: [SelectSubjectComponent],
})
export class SelectSubjectModule {}
