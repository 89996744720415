import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { AlertService } from '@app/core';
import { File } from '@app/core/models';
import { notNull, switchDelay } from '@app/shared/utils';
import { AppSelectors } from '@app/store';
import { AppState } from '@app/store/app.state';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { DropzoneConfigInterface, DropzoneDirective } from 'ngx-dropzone-wrapper';
import { BehaviorSubject, Observable, filter } from 'rxjs';
import { EMPTY } from '../user-photo/user-photo.component';

@Component({
  selector: 'app-upload-file',
  templateUrl: './upload-file.component.html',
})
export class UploadFileComponent implements OnInit, OnDestroy {
  @ViewChild(DropzoneDirective, { static: true }) public componentRef: DropzoneDirective;
  @Input() public titulo: string;
  @Input() public image: string;
  @Input() public invalid: boolean;
  @Input() public config: DropzoneConfigInterface = {
    clickable: false,
    maxFiles: 1,
    autoReset: null,
    errorReset: null,
    cancelReset: null,
  };

  @Input() public class: boolean = false;
  @Output() public statusChange = new EventEmitter<File>();
  @Output() public processing = new EventEmitter();
  @Output() public queueComplete = new EventEmitter();
  @Output() public statuschange = new EventEmitter();
  @Output() public addedFile = new EventEmitter();

  public loading$: Observable<boolean>;
  public readonly ept = EMPTY;
  private _countFiles = 0;
  private _loading$ = new BehaviorSubject<boolean>(false);
  private _exceededLimit: boolean;

  constructor(
    private _alertService: AlertService,
    private _store: Store<AppState>,
    private translateService: TranslateService,
  ) {
    this.loading$ = this._loading$.asObservable().pipe(switchDelay());
  }

  @Input()
  public set onChangeConfig(data) {
    this.config = { ...this.config, ...data };
  }

  @Input()
  public set componentReset(value) {
    if (!!value) {
      this.componentRef.dropzone().removeAllFiles(true);
      this.componentRef.reset();
    }
  }

  public ngOnInit(): void {
    this._store
      .select(AppSelectors.appFeature)
      .pipe(
        notNull(),
        filter((storage) => !!storage?.usuario_ativo),
      )
      .subscribe(
        (app) =>
          (this.config.headers = {
            ...this.config.headers,
            Accept: 'application/json',
            Authorization: `Bearer ${app.token}`,
            ['Content-Language']: app.usuario_ativo.lingua || 'pt-BR',
            ['X-Portal-Entity']: app.usuario_ativo.entidade_id,
            ['X-Portal-Association']: app.usuario_ativo.campo_id,
            ['X-Portal-Union']: app.usuario_ativo.uniao_id,
            ['X-Portal-Division']: app.usuario_ativo.divisao_id,
            ['X-Portal-Publisher']: app.usuario_ativo.editora_id,
          }),
      );
  }

  public ngOnDestroy() {
    this._loading$.complete();
  }

  public onAddFile(file): void {
    const reader = new FileReader();
    reader.onload = (event: any) => {
      const blob = new Blob([event.target.result], { type: 'application/octet-stream' });
      const formData = new FormData();
      formData.append('file', blob, 'arquivo');
      this.addedFile.emit({
        formData,
        fileName: file.name,
      });
    };
    reader.readAsArrayBuffer(file);
  }

  public onUploadError(args: any): void {
    let errorMessage = args[1] || this.translateService.instant('geral.upload-erro');

    if (!!errorMessage.msg) {
      errorMessage = errorMessage.msg;
    }

    if (errorMessage.startsWith('File is too big')) {
      errorMessage = this.translateService.instant('eclass.tamanho-excedido', { fileSize: this.config?.maxFilesize });
    }

    if (errorMessage.startsWith("You can't upload files of this type")) {
      errorMessage = this.translateService.instant('eclass.tipo-nao-aceito');
    }

    if (
      !this._exceededLimit &&
      (errorMessage.startsWith('You can not upload') || errorMessage.startsWith('Upload canceled.'))
    ) {
      errorMessage = this.translateService.instant('eclass.limite-arquivos', { fileLimit: this.config?.maxFiles });
      this._exceededLimit = true;
    } else if (this._exceededLimit && errorMessage.startsWith('Upload canceled.')) {
      errorMessage = this.translateService.instant('eclass.upload-cancelado', { fileLimit: this.config?.maxFiles });
    }

    this._alertService.alert({ message: errorMessage });

    this.componentRef.dropzone().removeAllFiles(true);

    this.componentRef.reset();
    this._loading$.next(false);
    this.onQueueComplete();
  }

  public onUploadSuccess(args: any): void {
    const filesNumber = this.componentRef.dropzone().files.length;
    if (args[1].ret) {
      this.statusChange.emit({
        mimetype: args[1]?.mimetype,
        hash: args[1]?.hash,
        type: args[1]?.type,
        file: args[1]?.fileName || args[1]?.file,
        title: args[1]?.originalName || args[1]?.title,
        extension: args[1]?.extensao || args[1]?.extension,
        size: args[1]?.tamanho || args[1]?.size,
        vimeo_account: args[1]?.vimeo_account,
        url: args[1]?.url,
      });

      const file = this.formatData(args);

      this.statuschange.emit({ success: true, ...file });

      if (filesNumber > 1) {
        this._countFiles++;

        if (this._countFiles === filesNumber) {
          this.componentRef.reset();
          this._countFiles = 0;
          this.onQueueComplete();
        }
      } else {
        this.componentRef.reset();
        this._countFiles = 0;
        this.onQueueComplete();
      }
    }

    this._loading$.next(false);
  }

  public onQueueComplete() {
    this.queueComplete.emit({
      finished: true,
    });
  }

  public onProcessing(args: any) {
    this.processing.emit({
      uploading: true,
    });
    this._loading$.next(true);
  }

  public resetDropzone = () => {
    this.componentRef.reset();
  };

  public formtaSizeFile(megas: number): string {
    const bytes = megas * 1000;
    const sizes = ['KB', 'MB', 'GB'];
    const i = Math.floor(Math.log(bytes) / Math.log(1000));
    return `${parseFloat((bytes / Math.pow(1000, i)).toFixed(2))} ${sizes[i] || 'MB'}`;
  }

  private formatData(args: any) {
    const data = <any>{};

    data.mimetype = !!args[1].mimetype ? args[1].mimetype : null;
    data.hash = !!args[1].hash ? args[1].hash : null;

    data.file = !!args[1].file ? args[1].file : null;
    if (!!args[1].fileName) {
      data.file = args[1].fileName;
    }

    data.title = !!args[1].title ? args[1].title : null;
    if (!!args[1].originalName) {
      data.title = args[1].originalName;
    }

    data.extension = !!args[1].extension ? args[1].extension : null;
    if (!!args[1].extensao) {
      data.extension = args[1].extensao;
    }

    data.size = !!args[1].size ? args[1].size : 0;
    if (!!args[1].tamanho) {
      data.size = args[1].tamanho;
    }

    return data;
  }
}
