const protocol = 'https://';
const resource = 'testecpb.com.br';

export const environment = {
  env: 'dev',
  production: false,
  protocol,
  integration: resource,
  resource,
  integration7Edu: 'https://7edu-br-staging.educadventista.org/',
  api: {
    login: `${protocol}login.${resource}/api/v1`,
    path_image: `https://s3.amazonaws.com/snv.${resource}/escola`,
    cron_api: `${protocol}cronaws.${resource}/v1`,
    iatec_api: 'https://cpb-services.sdasystems.org/api/v1',
  },
  wss: {
    notification: 'wss://cryi69bq8a.execute-api.sa-east-1.amazonaws.com/develop',
  },
};
