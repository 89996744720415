import { AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-sidenav-container',
  templateUrl: './sidenav-container.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidenavContainerComponent implements AfterViewInit {
  @Output() public attached = new EventEmitter<boolean>();

  public ngAfterViewInit() {
    setTimeout(() => this.attached.emit(true));
  }
}
