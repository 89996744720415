import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { Content } from '@app/core/models';

@Component({
  templateUrl: './modal-read-content.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalReadContentComponent {
  constructor(public dialogRef: DialogRef<string>, @Inject(DIALOG_DATA) public content: Content) {}
}
