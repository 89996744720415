import { Injectable, TemplateRef } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LayoutTemplateService {
  public template$: Observable<TemplateRef<any>>;
  private _template$ = new Subject<TemplateRef<any>>();

  constructor() {
    this.template$ = this._template$.asObservable();
  }

  public set(template: TemplateRef<any>) {
    this._template$.next(template);
  }

  public clear() {
    this._template$.next(null);
  }
}
