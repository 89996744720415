<div app-tab-content class="!w-full border-t-2 border-t-base-mantis-500">
  <div class="mt-5 flex w-full gap-5">
    <ng-container *ngIf="entities$ | async as entities">
      <app-form-field *ngIf="entities.length > 1" class="flex w-full">
        <label app-label translate> Unidade escolar </label>
        <ng-select
          app-select
          [items]="entities$ | async"
          [formControl]="entityIdCtrl"
          bindLabel="nome"
          bindValue="id"
          [clearable]="false"
          [placeholder]="'eclass.selecione' | translate"
          [notFoundText]="'geral.nao-encontrado' | translate"
          [multiple]="false"
        >
        </ng-select>
      </app-form-field>
    </ng-container>
    <app-form-field class="flex w-full">
      <label app-label translate> gestor-avaliacoes.pesquisar-nome </label>
      <input
        app-input
        [formControl]="searchStudentNameCtrl"
        [placeholder]="'gestor-avaliacoes.digite-nome' | translate"
      />
    </app-form-field>
  </div>
  <div class="flex items-center justify-between">
    <div class="flex">
      <button
        *ngIf="isSchool"
        app-flat-button
        type="button"
        (click)="onOpenReleaseStudents()"
        class="mr-3 border-theme bg-white"
      >
        <svg-icon class="flex h-6 w-6 text-green-500" src="assets/icons/icon-user-clock-solid.svg"></svg-icon>
        <span class="ml-3 hidden md:flex" translate> gestor-avaliacoes.liberar-alunos </span>
        <span *ngIf="!!studentChecked" class="ml-1"> ({{ studentChecked }}) </span>
      </button>

      <!-- OPCOES  -->
      <button
        *ngIf="!!onPreviewer(application$ | async)"
        #popoverBottom
        app-flat-button
        type="button"
        class="transform bg-gray-400 text-white shadow-md transition duration-300 ease-in-out hover:bg-gray-500 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-opacity-50"
        (click)="onOptions(popoverBottom)"
        [disabled]="loading$ | async"
      >
        <div class="flex h-6 w-6 items-center justify-center">
          <ng-container *ngIf="loading$ | async; else elseTemplate">
            <app-skeleton class="flex h-5 w-5" baseStyle="bg-gray-400" [showLabel]="false"></app-skeleton>
          </ng-container>
          <ng-template #elseTemplate>
            <svg-icon class="flex h-6 w-7" src="assets/icons/icon-settings.svg"></svg-icon>
          </ng-template>
        </div>
        <span class="ml-3 hidden md:inline-block" translate> gestor-avaliacoes.opcoes </span>
      </button>
    </div>

    <!-- MEDIA  -->
    <ng-container *ngIf="data$ | async as data">
      <div class="flex items-center gap-2">
        <span class="text-[14px] font-semibold" translate> gestor-avaliacoes.media-geral</span>
        <div class="flex items-center justify-center truncate">
          <div
            class="flex h-10 w-14 flex-col items-center justify-center rounded-lg bg-base-gray-light-500 shadow-inner shadow-base-gray-200"
          >
            <span class="text-md font-semibold leading-none">
              {{ data.media | number : '1.2-2' }}
            </span>
          </div>
        </div>
      </div>
    </ng-container>
  </div>

  <ng-container *ngIf="{ value: dataStudent$ | async } as data">
    <ng-container *ngIf="data.value | filter : searchStudentNameCtrl.value : ['nome'] as students">
      <ng-container *ngIf="!students.length; else dataTemplate">
        <app-card class="mt-5">
          <div app-card-content class="h-[200px]">
            <app-skeleton *ngIf="loading$ | async; else emptyTemplate" class="h-full w-full"></app-skeleton>
            <ng-template #emptyTemplate>
              <app-jumbotron translate>gestor-avaliacoes.selecione-unidade</app-jumbotron>
            </ng-template>
          </div>
        </app-card>
      </ng-container>
      <ng-template #dataTemplate>
        <div class="mt-5 grid gap-4 !overflow-x-auto">
          <ng-container *ngIf="application$ | async as application">
            <!-- TABELA DE ALUNOS -->
            <table app-table>
              <thead>
                <tr>
                  <th
                    app-table-cell
                    class="!bg-theme-500 p-2 !text-base uppercase !text-theme-50 first:rounded-l-lg first:pl-3 last:rounded-r-lg last:pr-3 [&:is(th)]:bg-theme-500 [&:is(th)]:text-theme-contrast [&:not(th)]:bg-white"
                  >
                    <div app-form class="flex flex-col gap-2">
                      <div class="flex gap-1">
                        <input
                          app-checkbox
                          (click)="onSelectedAll(!hasSelected)"
                          class="text-green-950"
                          type="checkbox"
                          [checked]="hasSelected"
                        />
                      </div>
                    </div>
                  </th>
                  <th
                    *ngIf="isPreview"
                    app-table-cell
                    class="!bg-theme-500 p-2 !text-sm uppercase !text-theme-50 first:rounded-l-lg first:pl-3 last:rounded-r-lg last:pr-3 md:!text-base [&:is(th)]:bg-theme-500 [&:is(th)]:text-theme-contrast [&:not(th)]:bg-white"
                    translate
                  >
                    gestor-avaliacoes.cartao-resposta
                  </th>
                  <th
                    app-table-cell
                    class="!bg-theme-500 p-2 !text-sm uppercase !text-theme-50 first:rounded-l-lg first:pl-3 last:rounded-r-lg last:pr-3 md:!text-base [&:is(th)]:bg-theme-500 [&:is(th)]:text-theme-contrast [&:not(th)]:bg-white"
                    translate
                  >
                    gestor-avaliacoes.desempenho
                  </th>
                  <th
                    app-table-cell
                    class="!bg-theme-500 p-2 text-left !text-sm uppercase !text-theme-50 first:rounded-l-lg first:pl-3 last:rounded-r-lg last:pr-3 md:!text-base [&:is(th)]:bg-theme-500 [&:is(th)]:text-theme-contrast [&:not(th)]:bg-white"
                    translate
                  >
                    gestor-avaliacoes.participante
                  </th>
                  <th
                    *ngIf="application.modo_aplicacao !== assessmentApplicationMode.Impressa"
                    app-table-cell
                    class="min-w-[100px] !bg-theme-500 p-2 !text-sm uppercase !text-theme-50 first:rounded-l-lg first:pl-3 last:rounded-r-lg last:pr-3 md:!text-base [&:is(th)]:bg-theme-500 [&:is(th)]:text-theme-contrast [&:not(th)]:bg-white"
                    translate
                  >
                    gestor-avaliacoes.tempo
                  </th>
                  <th
                    app-table-cell
                    class="!bg-theme-500 p-2 !text-sm uppercase !text-theme-50 first:rounded-l-lg first:pl-3 last:rounded-r-lg last:pr-3 md:!text-base [&:is(th)]:bg-theme-500 [&:is(th)]:text-theme-contrast [&:not(th)]:bg-white"
                    translate
                  >
                    gestor-avaliacoes.resultado
                  </th>
                  <th
                    app-table-cell
                    class="!bg-theme-500 p-2 !text-sm uppercase !text-theme-50 first:rounded-l-lg first:pl-3 last:rounded-r-lg last:pr-3 md:!text-base [&:is(th)]:bg-theme-500 [&:is(th)]:text-theme-contrast [&:not(th)]:bg-white"
                    translate
                  >
                    gda.compartilhadas.acoes
                  </th>
                </tr>
              </thead>
              <tbody app-table-body class="overflow-auto text-center">
                <tr *ngFor="let student of students; let i = index">
                  <td app-table-cell>
                    <div app-form class="flex flex-col gap-2">
                      <div class="flex gap-1">
                        <input
                          app-checkbox
                          [(ngModel)]="student.isSelected"
                          (change)="onSelectedCheck()"
                          class="text-green-950"
                          type="checkbox"
                        />
                      </div>
                    </div>
                  </td>
                  <td *ngIf="isPreview" class="w-1/12 !p-4 !text-center group-hover:!bg-theme-200">
                    <div class="mx-4 flex h-14 w-14 items-center justify-center !bg-gray-300 md:h-20 md:w-32">
                      <p class="!mb-0 text-md font-semibold text-white" translate>gestor-avaliacoes.preview</p>
                    </div>
                  </td>
                  <td class="w-1/12 !p-4 group-hover:!bg-theme-200">
                    <div class="flex items-center justify-center truncate px-5">
                      <div
                        app-tooltip
                        [tooltip]="'gestor-avaliacoes.porcentagem-acerto' | translate"
                        class="flex h-14 w-14 flex-col items-center justify-center rounded-full bg-base-gray-light-500 shadow-inner shadow-base-gray-200"
                      >
                        <span class="text-md font-semibold leading-none">
                          {{ onCalculatePerformance(student.qtd_acertos) }}%
                        </span>
                      </div>
                    </div>
                  </td>
                  <td app-table-cell>
                    <div class="mx-4 flex flex-col whitespace-nowrap">
                      <div class="flex items-center gap-2">
                        <span class="text-sm font-bold md:text-md"> {{ student.nome }} </span>

                        <svg-icon
                          *ngIf="student.liberar_data_inicio"
                          app-tooltip
                          [tooltip]="
                            ('gestor-avaliacoes.liberacao-extra' | translate) +
                            ' ' +
                            (student.liberar_data_inicio | date : 'dd/MM/yyyy HH:mm') +
                            ' ' +
                            ('geral.ate' | translate) +
                            ' ' +
                            (student.liberar_data_fim | date : 'dd/MM/yyyy HH:mm')
                          "
                          class="flex w-5 text-green-500"
                          src="assets/icons/icon-user-clock-solid.svg"
                        ></svg-icon>
                        <!-- Aluno PCD -->
                        <div *ngIf="student.necessidades_especiais?.length > 0">
                          <span class="border-1 flex w-max !rounded-full bg-white/80 !p-1 shadow-sm shadow-black/40">
                            <svg-icon
                              app-tooltip
                              [tooltip]="formattedNecessidadesEspeciais(student.necessidades_especiais)"
                              class="h-6 w-6 cursor-pointer text-gray-500"
                              src="assets/icons/icon-info-rounded.svg"
                            ></svg-icon>
                          </span>
                        </div>
                      </div>
                      <div class="flex items-center gap-2 pr-2">
                        <div *ngIf="!!student.turma_descricao" class="flex items-center">
                          <span class="md:txt-base flex items-center text-sm font-light">
                            {{ student.turma_descricao }}
                          </span>
                        </div>
                        <div *ngIf="!isNil(student.qtd_questoes_respondidas)" class="flex items-center">
                          <svg-icon class="flex h-2 w-2 text-gray-700" src="assets/icons/icon-ellipse.svg"></svg-icon>
                          <span class="md:txt-base ml-2 flex items-start text-sm font-light">
                            {{ student.qtd_questoes_respondidas }}/{{ qtdQuestao }}
                            {{ 'gestor-avaliacoes.questoes-respondidas' | translate }}
                          </span>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td app-table-cell *ngIf="application.modo_aplicacao !== assessmentApplicationMode.Impressa">
                    <div class="flex justify-center">
                      <span class="md:txt-base whitespace-nowrap text-sm font-light">
                        {{ onTimeAssessment(student.data_inicio, student.data_fim) }}</span
                      >
                      <svg-icon
                        *ngIf="!!student.data_inicio || !!student.data_fim"
                        class="ml-3 flex h-5 w-5"
                        app-tooltip
                        [tooltip]="getTimeRange(student)"
                        src="assets/icons/icon-help.svg"
                      ></svg-icon>
                    </div>
                  </td>
                  <td app-table-cell>
                    <div class="flex w-[10rem] flex-col lg:w-full">
                      <ng-container
                        *ngIf="
                          [assessmentStatusAttemptEnum.Finish, assessmentStatusAttemptEnum.WaitDateResult].includes(
                            student.status
                          )
                        "
                      >
                        <span class="font-bold">
                          {{ student.nota_calculada | currency : '' : '' : '1.2-2' }}
                          <ng-container *ngIf="!assesmentWeightType">% </ng-container>
                        </span>
                      </ng-container>
                      <ng-container [ngSwitch]="student.status">
                        <span *ngSwitchCase="null" class="w-full rounded-full bg-gray-200 px-9 md:px-0">
                          <span *ngIf="application.modo_aplicacao === assessmentApplicationMode.Online">{{
                            'gestor-avaliacoes.status.nao-iniciado' | translate
                          }}</span>
                          <span *ngIf="application.modo_aplicacao !== assessmentApplicationMode.Online">{{
                            'gestor-avaliacoes.status.aguardando-correcao' | translate
                          }}</span>
                        </span>
                        <span
                          *ngSwitchCase="assessmentStatusAttemptEnum.InProgress"
                          class="w-full rounded-full bg-base-status-info px-9 text-white md:px-2"
                        >
                          {{ 'gestor-avaliacoes.status.em-andamento' | translate }}
                        </span>
                        <span
                          *ngSwitchCase="assessmentStatusAttemptEnum.Review"
                          class="w-full rounded-full bg-red-500 px-9 text-white md:px-2"
                        >
                          {{ 'gestor-avaliacoes.status.revisar' | translate }}
                        </span>
                        <span
                          *ngSwitchCase="assessmentStatusAttemptEnum.Wait"
                          class="w-full rounded-full bg-base-status-warning px-9 text-white md:px-2"
                        >
                          {{ 'gestor-avaliacoes.status.aguardando-correcao' | translate }}
                        </span>
                        <span
                          *ngSwitchCase="
                            assessmentStatusAttemptEnum.Finish || assessmentStatusAttemptEnum.WaitDateResult
                          "
                          class="w-full rounded-full bg-base-mantis-500 px-9 text-white md:px-2"
                        >
                          {{ 'gestor-avaliacoes.finalizada' | translate }}
                        </span>
                      </ng-container>
                    </div>
                  </td>
                  <td app-table-cell>
                    <div class="mx-4 flex items-center justify-center gap-2">
                      <button
                        *ngIf="!!onPreviewer(application)"
                        app-flat-button
                        app-tooltip
                        [tooltip]="'gestor-avaliacoes.ver-respostas' | translate"
                        type="button"
                        (click)="onModalResolutionEvaluation(student.usuario_id)"
                        class="bg-base-status-warning hover:bg-base-status-warning"
                      >
                        <svg-icon
                          class="ml-1 flex h-6 w-6 text-white"
                          src="assets/icons/icon-clipboard-check.svg"
                        ></svg-icon>
                      </button>
                      <button
                        *ngIf="!!onPreviewer(application)"
                        app-flat-button
                        app-tooltip
                        [tooltip]="'gestor-avaliacoes.espelho-avaliacao' | translate"
                        type="button"
                        (click)="onModalAssessmentsMirror(student.usuario_id, student.nome)"
                        class="my-1 bg-white"
                      >
                        <svg-icon
                          class="flex h-6 w-6 text-base-status-info"
                          src="assets/icons/icon-eyes.svg"
                        ></svg-icon>
                      </button>
                      <button
                        *ngIf="application.modo_aplicacao !== assessmentApplicationMode.Impressa && isSchool"
                        app-flat-button
                        app-tooltip
                        [tooltip]="'gestor-avaliacoes.liberar-aluno' | translate"
                        type="button"
                        (click)="onOpenReleaseStudents(student)"
                        class="bg-white"
                      >
                        <svg-icon
                          class="flex h-6 w-6 text-green-500"
                          src="assets/icons/icon-user-clock-solid.svg"
                        ></svg-icon>
                      </button>
                      <ng-container *isAllow="[3, 999]">
                        <button
                          *ngIf="application.modo_aplicacao !== assessmentApplicationMode.Online"
                          app-flat-button
                          app-tooltip
                          [tooltip]="'Auditoria das respostas'"
                          type="button"
                          (click)="onModalAnswersAuditComponent(student, i)"
                          class="bg-red-400 text-white hover:bg-red-400"
                        >
                          <svg-icon
                            class="flex h-6 w-6 justify-center"
                            src="assets/icons/icon-clipboard-check.svg"
                          ></svg-icon>
                        </button>
                      </ng-container>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </ng-container>
        </div>
      </ng-template>
    </ng-container>
  </ng-container>
</div>
