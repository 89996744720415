<app-card class="h-full">
  <div app-card-header class="relative flex w-full !flex-row justify-between px-5 pt-5">
    <h1 class="text-lg uppercase" translate>Lives</h1>
  </div>
  <hr app-divider class="my-5" />
  <ng-container *ngIf="livesMeet$ | async as lives; else loading">
    <ng-container *ngIf="!isEmpty(lives); else notLives">
      <div app-card-content class="flex h-[22.5rem] flex-col gap-1 overflow-y-scroll">
        <ng-container *ngFor="let live of lives">
          <div
            class="grid cursor-pointer grid-cols-[3.5rem_1fr] items-center justify-between gap-4 rounded-lg bg-base-gray-light-400 px-4 py-3 text-gray-700 transition-all duration-300 hover:bg-amber-100"
          >
            <div class="flex flex-col items-center justify-center rounded-lg bg-amber-500/12 py-1">
              <span class="text-2xl">{{ live.date_start | date : 'd' }}</span>
              <span class="text-sm capitalize leading-none"> {{ live.date_start | date : 'MMM' }} </span>
            </div>
            <div class="grid w-full grid-cols-5 items-center justify-between">
              <div class="col-span-4 flex h-full w-full flex-col gap-1 overflow-hidden">
                <h1 class="truncate text-md font-normal">{{ live.title }}</h1>
                <h1 class="truncate text-sm">
                  <b class="text-xs font-normal text-base-gray-500">({{ live.subject.descricao }})</b>
                </h1>
              </div>
              <div (click)="openLive(live.link)" class="col-span-1 flex w-10 justify-self-end">
                <svg-icon class="w-9 text-modules-eclass-500" src="assets/icons/icon-play-filled.svg"> </svg-icon>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </ng-container>
  </ng-container>
</app-card>

<ng-template #loading>
  <div class="h-full" app-card-content>
    <app-skeleton></app-skeleton>
  </div>
</ng-template>
<ng-template #notLives>
  <div app-card-content class="h-full">
    <app-jumbotron translate class="py-8">lives.sem-lives</app-jumbotron>
  </div>
</ng-template>

