<app-modal-header>
  <div class="flex py-2">
    <svg-icon class="ml-6 w-7 text-base-post-plan" dialogClose src="assets/icons/icon-student-book.svg"></svg-icon>
    <div class="ml-6 flex items-center gap-1">
      <p class="hidden cursor-pointer text-lg font-semibold md:block" dialogClose translate>site-escola.conteudos</p>
      <span class="hidden text-md font-medium text-base-comet-400 md:block">/</span>
      <span class="text-md font-medium text-base-comet-400">{{ content.title }}</span>
    </div>
  </div>
</app-modal-header>

<div app-modal-content class="h-[90vh] w-[90vw] !p-0 md:h-[calc(100vh_-_15rem)] xl:h-[50rem] xl:w-[80rem]">
  <app-sidenav-group class="h-full w-full rounded-b-lg bg-base-whisper-300">
    <ng-template app-sidenav-start [opened]="true" panelWidth="250px">
      <div class="flex h-full w-[250px] flex-col gap-5 rounded-bl-lg bg-base-whisper-300 p-4 transition duration-500">
        <div class="flex w-full justify-center px-4">
          <app-contents-photo class="!h-60 rounded-lg bg-white shadow-lg" [src]="content.imagem"></app-contents-photo>
        </div>
        <div class="break-words px-4 text-center text-gray-600" *ngIf="!!content.author || !!content.publishing_date">
          {{
            'site-escola.momento'
              | translate : { name: content.author || '', moment: content.publishing_date | date : 'dd/MM/YYYY' || '' }
          }}
        </div>
        <div class="break-words px-4 text-center text-md font-semibold">{{ content.title }}</div>
      </div>
    </ng-template>

    <ng-template app-sidenav-outer>
      <div class="flex h-full flex-col gap-10 overflow-y-auto p-4">
        <span> {{ content.chamada }} </span>

        <div [innerHTML]="content.description | safe : 'html'"></div>
      </div>
    </ng-template>
  </app-sidenav-group>
</div>
