import { Overlay, OverlayRef, PositionStrategy, ScrollStrategy } from '@angular/cdk/overlay';
import { ComponentPortal, TemplatePortal } from '@angular/cdk/portal';
import {
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  InjectionToken,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { PopoverOld } from './popover';
import { Strategy } from './strategy';
import { PopoverComponent } from './popover.component';

export const POPOVER = new InjectionToken<PopoverRef>('CdkPopover');

export interface PopoverRef {
  ref: OverlayRef;
  data: any;
  close: (value?: any) => void;
}

@Directive({
  selector: '[popover]',
})
export class PopoverDirective implements OnInit, OnDestroy {
  @Input() public popover: TemplateRef<any> | PopoverOld;
  @Input() public popoverData: any;
  @Output() public popoverClose = new EventEmitter();
  private _destroy$ = new Subject<void>();
  private _overlayRef: OverlayRef;

  constructor(private overlay: Overlay, private elementRef: ElementRef, private viewContainerRef: ViewContainerRef) {}

  @HostListener('click') public click() {
    if (this.popover instanceof PopoverOld) {
      //   this.attachOverlay(this.popover.popoverRef());
      // } else {
      //   const injector = this.injector(this._overlayRef);
      //   const portal = new ComponentPortal(PopoverComponent, this.viewContainerRef, injector);
      //   this._overlayRef.attach(portal);
      // this.attachOverlay(this.popover);
    }
  }

  public ngOnInit(): void {
    this.initOverlay();
  }

  public ngOnDestroy(): void {
    this.detachOverlay();
    this._destroy$.next();
    this._destroy$.complete();
  }

  private initOverlay() {
    this._overlayRef = this.overlay.create({
      positionStrategy: this.positionStrategy(),
      scrollStrategy: this.scrollStrategy(),
      hasBackdrop: true,
      backdropClass: '',
    });

    this._overlayRef
      .backdropClick()
      .pipe(takeUntil(this._destroy$))
      .subscribe(() => this.detachOverlay());
  }

  private positionStrategy(): PositionStrategy {
    const positions = [Strategy.BOTTOM, Strategy.TOP];
    return this.overlay.position().flexibleConnectedTo(this.elementRef).withPositions(positions).withPush(false);
  }

  private scrollStrategy(): ScrollStrategy {
    return this.overlay.scrollStrategies.reposition();
  }

  private attachOverlay(ref: TemplateRef<any>) {
    if (!this._overlayRef.hasAttached()) {
      const context = { $implicit: this.popoverData };
      const injector = this.injector(this._overlayRef);
      const portal = new TemplatePortal(ref, this.viewContainerRef, context, injector);
      this._overlayRef.attach(portal);
      this._overlayRef.addPanelClass([
        'relative',
        'flex',
        'flex-col',
        'justify-start',
        'm-2',
        'bg-white',
        'shadow',
        'rounded-lg',
        'border-t-[6px]',
        'border-theme-500',
      ]);
    }
  }

  private injector(ref: OverlayRef): Injector {
    return Injector.create({
      providers: [
        {
          provide: POPOVER,
          useValue: { ref, data: this.popoverData, close: (value: any) => this.detachOverlay(value) },
        },
      ],
    });
  }

  private detachOverlay(value?: any) {
    if (this._overlayRef.hasAttached()) {
      this._overlayRef.detach();
      this.popoverClose.emit(value);
    }
  }
}
