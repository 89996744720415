<form [formGroup]="form">
  <div class="mx-5 mb-5 mt-5 flex flex-col items-center justify-center gap-5 md:flex-row">
    <div class="mr-0 w-full md:mr-5 md:w-4/12">
      <app-select-group [entityId]="entityId$ | async" formControlName="turma"></app-select-group>
    </div>
    <div class="relative w-full md:w-8/12">
      <input app-input class="pr-9" formControlName="busca" [placeholder]="'geral.buscar-nome' | translate" />
      <svg-icon src="assets/icons/icon-search.svg" class="absolute right-4 top-4 w-4 cursor-pointer"></svg-icon>
    </div>
  </div>
</form>

<div class="mx-5 mb-5 grid h-[400px] overflow-auto">
  <ng-container *ngIf="{ value: students$ | async } as students">
    <ng-container *ngIf="isEmpty(students.value); else dataTemplate">
      <app-jumbotron translate>
        <ng-container *ngIf="!!students.value; else emptyTemplate">
          {{ 'geral.nenhum-dado' | translate }}
        </ng-container>
        <ng-template #emptyTemplate> {{ 'geral.utilizar-filtros' | translate }} </ng-template>
      </app-jumbotron>
    </ng-container>
    <ng-template #dataTemplate>
      <div>
        <table app-table class="w-full border-collapse">
          <thead>
            <tr>
              <th app-table-cell class="sticky top-0 z-90" translate>geral.foto</th>
              <th app-table-cell class="sticky top-0 z-90 min-w-[20rem] text-start" translate>geral.nome</th>
              <th app-table-cell class="sticky top-0 z-90" translate>geral.contato</th>
              <th app-table-cell class="sticky top-0 z-90 min-w-[10rem]" translate>geral.turma</th>
              <th app-table-cell class="sticky top-0 z-90" translate>geral.conta-google</th>
              <th app-table-cell class="sticky top-0 z-90"></th>
            </tr>
          </thead>
          <tbody app-table-body class="text-center">
            <tr *ngFor="let student of students.value">
              <td app-table-cell>
                <div class="flex justify-center">
                  <span class="border-1 flex w-max !rounded-full bg-white/80 !p-1 shadow-sm shadow-black/40">
                    <app-user-photo class="h-10 w-10" [src]="student.photo"></app-user-photo>
                  </span>
                </div>
              </td>
              <td app-table-cell class="text-start">
                <span class="cursor-pointer font-semibold">{{ student | orderName | async }}</span>
              </td>
              <td app-table-cell>
                <div class="grid justify-items-center">
                  <!-- E-mail -->
                  <div
                    *ngIf="student.aluno.usuario?.email as email"
                    class="group relative flex w-min items-center justify-center gap-1 px-8 text-gray-600"
                  >
                    <a href="mailto:{{ email }}"> {{ email }} </a>
                    <div class="absolute right-0 h-6 w-6">
                      <button
                        app-icon-button
                        app-tooltip
                        class="hidden !p-0 group-hover:flex"
                        [tooltip]="'geral.copiar' | translate"
                        (click)="onCopy(email)"
                      >
                        <svg-icon class="h-5 w-5" src="assets/icons/icon-copy-square.svg"></svg-icon>
                      </button>
                    </div>
                  </div>

                  <!-- Phone -->
                  <div
                    *ngIf="student.celular as phone"
                    class="group relative flex w-min items-center justify-center gap-1 px-8 text-gray-600"
                  >
                    <a href="tel:{{ phone }}" class="w-32"> {{ phone | mask : '(00) 00000-0000' }} </a>
                    <div class="absolute right-0 h-6 w-6">
                      <button
                        app-icon-button
                        app-tooltip
                        class="hidden !p-0 group-hover:flex"
                        [tooltip]="'geral.copiar' | translate"
                        (click)="onCopy(phone)"
                      >
                        <svg-icon class="h-5 w-5" src="assets/icons/icon-copy-square.svg"></svg-icon>
                      </button>
                    </div>
                  </div>
                </div>
              </td>
              <td app-table-cell>
                <div class="flex flex-col gap-1">
                  <span
                    *ngFor="let turma of student.aluno.aluno_turmas"
                    class="rounded-lg bg-theme-500 px-1 py-0.5 text-sm leading-none text-theme-contrast"
                  >
                    {{ turma.turma.serie.descricao }} - {{ turma.turma | groupName | async }}
                  </span>
                </div>
              </td>
              <td app-table-cell>{{ student.gsuite || '-' }}</td>
              <td app-table-cell>
                <button
                  app-icon-button
                  app-tooltip
                  [tooltip]="'geral.logar-como' | translate"
                  type="button"
                  class="group bg-white shadow-lg hover:bg-theme-500"
                  (click)="onLoginFromUser(student.aluno.usuario.id)"
                >
                  <svg-icon
                    class="flex h-6 w-6 text-theme-500 group-hover:text-theme-contrast"
                    src="assets/icons/icon-sign-in.svg"
                  ></svg-icon>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </ng-template>
  </ng-container>
</div>
