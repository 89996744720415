<div app-tab-content class="border-t-2 border-t-base-mantis-500">
  <div class="flex h-full w-full flex-col">
    <ng-container *ngIf="dataQuest$ | async as dataQuest; else elseTemplate">
      <ng-container *ngIf="!isEmpty(dataQuest); else jumbotron">
        <ng-container *ngFor="let quest of dataQuest; let i = index">
          <app-accordion>
            <app-accordion-item>
              <div app-accordion-header>
                <div class="w-full grid-cols-[150px_auto_auto_auto] gap-5">
                  <div class="grid md:flex md:max-h-[4rem] md:truncate">
                    <div class="flex w-full">
                      <div class="flex">
                        <div class="mr-4 flex items-center justify-center md:mr-0 md:px-5">
                          <div
                            class="flex h-14 w-14 flex-col items-center justify-center rounded-full bg-base-gray-light-500 shadow-inner shadow-base-gray-200"
                          >
                            <span class="text-md font-semibold leading-none">
                              {{ i + 1 }}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div class="mb-5 flex w-full items-center md:mb-0 md:ml-5">
                        <span
                          class="max-w-full items-center text-[14px] md:truncate"
                          [innerHTML]="onSliceStatement(quest.enunciado) | safe : 'html'"
                        ></span>

                        <app-badge-annuled-question
                          *ngIf="!!quest.anulado"
                          [motive]="quest.anulado_motivo"
                          [isQuestionAnnulled]="!!quest.anulado"
                          [applicationUuid]="(application$ | async).uuid"
                          [questionUuid]="quest.uuid"
                          class="flex items-center px-5"
                          (updateQuestion)="onUpdateQuestionAnula($event, quest)"
                        ></app-badge-annuled-question>
                      </div>
                    </div>

                    <!-- Parte de estatísticas -->
                    <div
                      *ngIf="quest.sociodemografica !== 1; else QuestaoSociodemografica"
                      class="mr-10 flex w-full justify-end px-6 md:px-0 lg:mr-24"
                    >
                      <ng-container *ngIf="onRightPercentage(quest) as result">
                        <div class="mr-5 grid">
                          <span class="font-bold text-theme-500">{{ result.acerto }}%</span>
                          <span class="hidden font-bold text-theme-500 md:flex" translate>
                            gestor-avaliacoes.acertaram
                          </span>
                        </div>
                        <div class="grid">
                          <span class="font-bold text-base-status-danger">{{ result.erro }}%</span>
                          <span class="hidden font-bold text-base-status-danger md:flex" translate>
                            gestor-avaliacoes.erraram
                          </span>
                        </div>
                      </ng-container>
                    </div>

                    <ng-template #QuestaoSociodemografica>
                      <div class="flex items-center pr-10">
                        <app-badge [badgeStyle]="badgeStyle">
                          {{ 'gestor-avaliacoes.novas-questoes.sociodemografica' | translate }}
                        </app-badge>
                      </div>
                    </ng-template>
                  </div>
                </div>
              </div>
              <div app-accordion-content class="mb-5 rounded-md border border-gray-200 p-4">
                <div class="flex w-full">
                  <ng-container *ngIf="!!quest.disciplina">
                    <app-badge [badgeStyle]="{ background: 'bg-gray-400 text-white' }">
                      {{ quest.disciplina }}
                    </app-badge>
                  </ng-container>
                  <div *ngIf="quest.sociodemografica !== 1" class="mb-5 flex w-full items-center justify-end gap-4">
                    <button
                      *ngIf="!minhaQuestao(quest)"
                      app-flat-button
                      app-tooltip
                      tooltip="Reportar"
                      class="bg-white"
                      (click)="onReport(quest.uuid)"
                    >
                      <svg-icon class="flex h-6 w-6 text-gray-500" src="assets/icons/icon-menu-reports.svg"></svg-icon>
                    </button>
                    <button
                      *ngIf="quest.sociodemografica !== 1 && quest.tipo === assessmentQuestionType.Objetiva"
                      app-flat-button
                      type="button"
                      class="bg-white"
                      (click)="onModalGraph(quest.uuid)"
                      app-tooltip
                      [tooltip]="'gestor-avaliacoes.analise-grafica-questao' | translate"
                    >
                      <svg-icon
                        class="flex h-6 w-6 !text-modules-assessments-manager-500"
                        src="assets/icons/icon-graph.svg"
                      ></svg-icon>
                    </button>

                    <button
                      *ngIf="!quest.anulado"
                      #popoverTop
                      app-flat-button
                      type="button"
                      class="bg-base-status-warning text-white"
                      (click)="onOpenPopover(popoverTop, quest)"
                      translate
                    >
                      gestor-avaliacoes.anular-questao
                    </button>
                  </div>
                </div>
                <div class="flex w-full pb-5">
                  <app-text-editor [readOnly]="true" [value]="quest.enunciado"></app-text-editor>
                </div>
                <ng-container *ngFor="let card of quest.alternativas; let i = index">
                  <div class="pb-2 pt-0">
                    <button
                      app-button
                      class="border-1 flex w-full justify-start gap-4 rounded-md bg-white p-1 shadow-lg transition duration-300"
                    >
                      <svg-icon
                        *ngIf="!!card.alternativa_correta && quest.sociodemografica !== 1"
                        class="flex h-8 w-6 cursor-pointer text-base-mantis-500"
                        src="assets/icons/icon-check-outline.svg"
                      ></svg-icon>

                      <span
                        [ngClass]="{
                          'ml-10': !quest.sociodemografica && !card.alternativa_correta
                        }"
                        class="flex w-max !rounded-lg border border-solid border-base-mantis-500 bg-white !p-1 text-base-mantis-500 shadow-sm shadow-black/40"
                      >
                        <span class="rounded-lg px-2 text-[1.2rem]">
                          {{ toLetter(i) }}
                        </span>
                      </span>

                      <div class="flex w-full truncate">
                        <span class="mb-1 flex w-full items-center text-base" [innerHTML]="card.descricao"></span>
                      </div>
                      <div class="flex items-center">
                        <span
                          class="mr-4 font-bold"
                          [ngClass]="{
                            'text-base-mantis-500': quest.sociodemografica === 1 || !!card.alternativa_correta,
                            'text-red-500': !quest.sociodemografica && !card.alternativa_correta
                          }"
                        >
                          {{ card.count || '0' }} marcadas
                        </span>
                      </div>
                    </button>
                  </div>
                </ng-container>
              </div>
            </app-accordion-item>
          </app-accordion>
        </ng-container>
      </ng-container>
    </ng-container>
    <ng-template #elseTemplate>
      <app-card class="mt-5">
        <div app-card-content class="h-[200px]">
          <app-skeleton *ngIf="loading$ | async" class="h-full w-full"></app-skeleton>
        </div>
      </app-card>
    </ng-template>
    <ng-template #jumbotron>
      <div class="grid h-auto gap-4">
        <app-card>
          <div app-card-content class="h-[200px]">
            <app-jumbotron translate> geral.filtro-vazio </app-jumbotron>
          </div>
        </app-card>
      </div>
    </ng-template>
  </div>
</div>
