import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { HighlightsCourseService } from '@app/core/services/highlights-course.service';
import { notNull, safeEmpty, switchDelay } from '@app/shared/utils/operators';
import { AppSelectors } from '@app/store';
import { AppState } from '@app/store/app.state';
import { Store } from '@ngrx/store';
import { isEqual } from 'lodash';
import { Observable, Subject, distinctUntilChanged, of, startWith, switchMap, takeUntil, tap } from 'rxjs';

@Component({
  selector: 'app-card-learning-trails',
  templateUrl: './card-learning-trails.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardLearningTrailsComponent implements OnInit, OnDestroy {
  public course$: Observable<any>;
  public load$: Observable<boolean>;
  private _load$ = new Subject<boolean>();
  private _destroy$ = new Subject<void>();

  constructor(private store: Store<AppState>, private highlightsCourse: HighlightsCourseService) {
    this.load$ = this._load$.asObservable().pipe(switchDelay(), startWith(true));
  }

  public ngOnInit(): void {
    this.showCourses();
  }

  public ngOnDestroy() {
    this._destroy$.next();
    this._load$.complete();
    this._destroy$.complete();
  }

  public showCourses() {
    this.course$ = this.store.select(AppSelectors.ActiveUser).pipe(
      takeUntil(this._destroy$),
      tap(() => this._load$.next(true)),
      notNull(),
      distinctUntilChanged(isEqual),
      switchMap((user) =>
        !!user.entidade_id
          ? this.highlightsCourse.getAllCardCurso(user.pais_id, user.entidade_id).pipe(safeEmpty())
          : of([]),
      ),
      tap(() => this._load$.next(false)),
    );
  }
}
