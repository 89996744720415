import { Injectable } from '@angular/core';
import { AppActions, AppSelectors } from '@app/store';
import { AppState } from '@app/store/app.state';
import { Store } from '@ngrx/store';
import { Observable, catchError, exhaustMap, filter, map, skip, take, tap, throwError } from 'rxjs';
import { UserService } from './user.service';

export const INIT_DATA = {
  tipo: 0,
  perfil: true,
  funcoes: true,
  categorias: true,
  grupos: true,
};

@Injectable({
  providedIn: 'root',
})
export class CoreService {
  constructor(private store: Store<AppState>, private userService: UserService) {}

  public isAuthenticated(): Observable<boolean> {
    return this.identify().pipe(map((account) => !!account));
  }

  private identify(): any {
    return this.store.select(AppSelectors.token).pipe(
      skip(1),
      exhaustMap(() => this.userService.get(INIT_DATA)),
      tap((user) => this.store.dispatch(AppActions.InitDataEffect({ user }))),
      exhaustMap(() => this.awaitUser()),
      catchError(() => this.error()),
    );
  }

  private awaitUser(): Observable<AppState> {
    return this.store.select(AppSelectors.appFeature).pipe(
      filter((usuario_ativo) => !!usuario_ativo),
      take(1),
    );
  }

  private error() {
    this.store.dispatch(AppActions.TokenClearEffect());
    return throwError(() => 'not authenticated');
  }
}
