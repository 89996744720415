import { ComponentType } from '@angular/cdk/portal';
import { InjectionToken, TemplateRef } from '@angular/core';
import { Observable } from 'rxjs';
import { SidenavGroupComponent } from './sidenav-group.component';

export const SIDENAV_REF = new InjectionToken<SidenavRef>('Sidenav');
export const SIDENAV_START = new InjectionToken<SidenavRef>('SidenavStart');
export const SIDENAV_END = new InjectionToken<SidenavRef>('SidenavEnd');
export const SIDENAV_DATA = new InjectionToken<any>('SidenavData');
export const RESPONSIVE_BREAKPOINT = '(max-width: 768px)';

export interface SidenavData {
  componentRef: ComponentType<any>;
  mode?: SidenavMode;
  opened?: boolean;
  panelWidth?: string;
  closedAnchor?: boolean;
  showBackground?: boolean;
  data?: any;
  outerClick?: boolean;
  buttonAnchor?: string;
  bgColor?: string;
  iconAnchor?: string;
}

export abstract class SidenavRef {
  public abstract componentInstance$: Observable<any>;
  public abstract closed$: Observable<any>;
  public abstract open$: Observable<boolean>;
  public abstract close(value?: any): void;
  public abstract open(value?: any): void;
}

export enum SidenavMode {
  Side,
  Over,
}
export enum SidenavDirection {
  Start,
  End,
}

export const STANDARD_SIDENAV: SidenavData = {
  componentRef: null,
  panelWidth: '350px',
  bgColor: 'bg-white',
  closedAnchor: false,
  buttonAnchor: 'bg-theme-300',
  iconAnchor: 'text-theme-contrast',
  showBackground: false,
  outerClick: true,
  opened: false,
  mode: SidenavMode.Side,
};

export abstract class SidenavDirective {
  public panelWidth = STANDARD_SIDENAV.panelWidth;
  public closedAnchor = STANDARD_SIDENAV.closedAnchor;
  public buttonAnchor = STANDARD_SIDENAV.buttonAnchor;
  public iconAnchor = STANDARD_SIDENAV.iconAnchor;
  public componentRef = STANDARD_SIDENAV.componentRef;
  public showBackground = STANDARD_SIDENAV.showBackground;
  public outerClick = STANDARD_SIDENAV.outerClick;
  public opened = STANDARD_SIDENAV.opened;
  public mode = STANDARD_SIDENAV.mode;
  public bgColor = STANDARD_SIDENAV.bgColor;
  public data = STANDARD_SIDENAV.data;

  constructor(
    public templateRef: TemplateRef<any>,
    public sidenavGroup: SidenavGroupComponent,
    public direction: SidenavDirection,
  ) {}

  public abstract register(root: SidenavRef): void;
}

export interface SidenavStatus {
  open: boolean;
  config: SidenavDirective;
}
