import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ACADEMIC_YEAR_KEY, HEADER_KEY } from '@app/config';
import { AppSelectors } from '@app/store';
import { AppState } from '@app/store/app.state';
import { Store } from '@ngrx/store';
import { isNil } from 'lodash';
import { combineLatest, exhaustMap, map, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class EntityHeaderInterceptor implements HttpInterceptor {
  constructor(private store: Store<AppState>) {}

  public intercept(req: HttpRequest<any>, next: HttpHandler) {
    return combineLatest([this.entityHeader(), this.academicYearHeader()]).pipe(
      map(([entity, academicYear]) => {
        let headers = req.headers;
        const identifiers = [...entity, ...academicYear];
        for (const { key, value } of identifiers) {
          if (!isNil(value)) {
            headers = headers.append(`X-portal-${key}`, headers.get(key) || String(value));
            headers.delete(key);
          }
        }
        return headers;
      }),
      map((headers) => req.clone({ headers })),
      exhaustMap((custom) => next.handle(custom)),
    );
  }

  private entityHeader(): Observable<any[]> {
    return this.store.select(AppSelectors.entityKey).pipe(
      map((entityKey) => {
        if (entityKey) {
          return Object.entries<string>(entityKey).map(([key, value]) => ({
            key: HEADER_KEY[key.slice(0, -3)],
            value,
          }));
        }
        return [];
      }),
    );
  }

  private academicYearHeader(): Observable<any[]> {
    return this.store.select(AppSelectors.ActiveUser).pipe(
      map((activeUser) => ({
        key: ACADEMIC_YEAR_KEY.year,
        value: activeUser?.ano_letivo,
      })),
      map((header) => [header]),
    );
  }
}
