<div class="flex flex-wrap justify-center gap-3 md:justify-between" *ngIf="page$ | async as pages">
  <div class="flex items-center justify-center gap-2 self-start">
    <!-- go to First -->
    <button
      app-icon-button
      app-tooltip
      [tooltip]="'geral.primeira-pagina' | translate"
      [disabled]="pages.current === 1"
      (click)="onFirst(pages)"
      type="button"
      class="group transform !rounded-full border border-solid border-gray-300 text-[0.5rem] shadow-md duration-300 hover:border-theme-300 hover:bg-theme-300"
    >
      <svg-icon
        class="flex h-5 w-5 text-gray-500 group-hover:text-white md:h-7 md:w-7"
        src="assets/icons/icon-double-arrow-left.svg"
      ></svg-icon>
    </button>

    <!-- go to Previous -->
    <button
      app-icon-button
      app-tooltip
      [tooltip]="'geral.pagina-anterior' | translate"
      *ngIf="pages.current > 1"
      (click)="onPrevious(pages)"
      type="button"
      class="group transform !rounded-full border border-solid border-gray-300 !p-1 text-[0.5rem] shadow-md duration-300 hover:border-theme-300 hover:bg-theme-300 md:!p-2"
    >
      <svg-icon
        class="m-1 flex h-5 w-5 text-gray-500 group-hover:text-white"
        src="assets/icons/icon-arrow-left-fine.svg"
      ></svg-icon>
    </button>

    <!-- Pages -->
    <ng-container *ngIf="scaffold(pages) as scaffolds">
      <span class="h-4 cursor-default self-center text-2xl leading-4 md:h-7" *ngIf="pages.current > 1">...</span>
      <button
        *ngFor="let scaffold of scaffolds"
        app-icon-button
        (click)="onPage(scaffold, pages)"
        type="button"
        class="group transform justify-center !rounded-full text-[0.5rem] duration-300 hover:bg-theme-300 hover:shadow-md"
        [ngClass]="{ '!cursor-default !bg-theme-500 shadow-md': scaffold === pages.current }"
      >
        <span
          class="h-8 w-8 text-lg text-gray-500 group-hover:text-white"
          [ngClass]="{ '!text-white': scaffold === pages.current }"
        >
          {{ scaffold }}
        </span>
      </button>
      <span class="h-4 cursor-default self-center text-2xl leading-4 md:h-7" *ngIf="pages.current < pages.pages"
        >...</span
      >
    </ng-container>

    <!-- go to Next -->
    <button
      app-icon-button
      app-tooltip
      [tooltip]="'geral.proxima-pagina' | translate"
      *ngIf="pages.current < pages.pages"
      (click)="onNext(pages)"
      type="button"
      class="group transform !rounded-full border border-solid border-gray-300 !p-1 text-[0.5rem] shadow-md duration-300 hover:border-theme-300 hover:bg-theme-300 md:!p-2"
    >
      <svg-icon
        class="m-1 flex h-5 w-5 text-gray-500 group-hover:text-white"
        src="assets/icons/icon-arrow-right-fine.svg"
      ></svg-icon>
    </button>

    <!-- go to Last -->
    <button
      app-icon-button
      app-tooltip
      [tooltip]="'geral.ultima-pagina' | translate"
      [disabled]="pages.current === pages.pages"
      (click)="onLast(pages)"
      type="button"
      class="group transform !rounded-full border border-solid border-gray-300 text-[0.5rem] shadow-md duration-300 hover:border-theme-300 hover:bg-theme-300"
    >
      <svg-icon
        class="flex h-5 w-5 text-gray-500 group-hover:text-white md:h-7 md:w-7"
        src="assets/icons/icon-double-arrow-right.svg"
      ></svg-icon>
    </button>
  </div>

  <div class="grid gap-2">
    <div class="flex items-center gap-1">
      <span translate> geral.itens-pagina </span>
      <ng-select
        app-select
        class="w-20"
        [ngModel]="pages.size"
        [clearable]="false"
        [searchable]="false"
        (change)="onPageSize($event, pages)"
      >
        <ng-option *ngFor="let size of pageSize" [value]="size">{{ size }}</ng-option>
      </ng-select>
    </div>
    <span class="md:justify-self-end"> {{ 'geral.paginacao' | translate : pagination(pages) }} </span>
  </div>
</div>
