import { Injectable } from '@angular/core';
import { portalConst } from '@app/config';

import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { CrudService } from './crud.service';

@Injectable({ providedIn: 'root' })
export class LiveService extends CrudService<any> {
  constructor(api: ApiService) {
    super(api, `${portalConst.api.aulas}/v1`);
  }

  public getLivesGestor(data: any): Observable<any> {
    return this.api.get(`${this.url}/lives-gestor`, data);
  }

  public getLivesTeacher(data: any): Observable<any> {
    return this.api.get(`${this.url}/lives-professor`, data);
  }

  public getLivesStudent(data: any): Observable<any> {
    return this.api.get(`${this.url}/lives-zoom`, data);
  }

  public getLevelsByEntity(data: any): Observable<any> {
    return this.api.get(`${this.url}/niveis-entidade`, data);
  }

  public controlLive(option: any, groupId: number): Observable<any> {
    return this.api.post(`${portalConst.api.usuario}/v1/zoom/control`, { turma_id: groupId, option });
  }

  public controlAccessLive(option: any, groupId: number, userId: number): Observable<any> {
    return this.api.post(`${portalConst.api.usuario}/v1/zoom/control`, {
      option,
      turma_id: groupId,
      usuario_id: userId,
    });
  }

  public controlLiveRecord(option: any, groupId: number, date: any): Observable<any> {
    return this.api.post(`${portalConst.api.usuario}/v1/zoom/control`, { turma_id: groupId, option, data_aula: date });
  }

  public controlLiveMassive(entidadeId: number, groupId: number, option: any): Observable<any> {
    return this.api.post(`${portalConst.api.usuario}/v1/zoom/control-masivo`, {
      entidade_id: entidadeId,
      nivel_id: groupId,
      option,
    });
  }

  public saveMeet(data: any): Observable<any> {
    return this.api.post(`${portalConst.api.eclass}/v1/links`, data);
  }

  public getLivesMeet(type: number): Observable<any> {
    return this.api.get(`${portalConst.api.eclass}/v1/links`, { type });
  }
}
