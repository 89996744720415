import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phone',
})
export class PhonePipe implements PipeTransform {
  public transform(phone: string): string {
    const sanitized = (phone || '').replace(/\D/g, '');
    if (sanitized.length === 11) {
      return sanitized.replace(/(\d{2})(\d{1})(\d{4})(\d{4})/, '($1) $2 $3-$4');
    }
    if (sanitized.length === 10) {
      return sanitized.replace(/(\d{2})(\d{4})(\d{4})/, '($1) $2-$3');
    }
    if (sanitized.length === 9) {
      return sanitized.replace(/(\d{5})(\d{4})/, '$1-$2');
    }
    return '';
  }
}
