import { Component, DestroyRef, Input, OnInit, forwardRef } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { LevelEducationService } from '@app/core/services';
import { notNull, selectedEntityActive } from '@app/shared/utils';
import { AppSelectors } from '@app/store';
import { AppState } from '@app/store/app.state';
import { Store } from '@ngrx/store';
import { head } from 'lodash';
import { BehaviorSubject, Observable, combineLatest, map, switchMap } from 'rxjs';

@Component({
  selector: 'app-select-level',
  templateUrl: './select-level.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectLevelComponent),
      multi: true,
    },
  ],
})
export class SelectLevelComponent implements OnInit, ControlValueAccessor {
  @Input() public multiple = true;
  public data$: Observable<any[]>;
  public ctrl = new FormControl([]);
  public countryId$ = new BehaviorSubject<any>(undefined);

  constructor(
    private store: Store<AppState>,
    private levelEducationService: LevelEducationService,
    private destroyRef: DestroyRef,
  ) {}

  @Input()
  public set countryId(value: number) {
    this.countryId$.next(value);
  }

  public ngOnInit(): void {
    const activeUser$ = this.store.select(AppSelectors.ActiveUser).pipe(takeUntilDestroyed(this.destroyRef), notNull());
    this.data$ = combineLatest({ countryId: this.countryId$, activeUser: activeUser$ }).pipe(
      switchMap(({ countryId, activeUser }) => {
        const entity = selectedEntityActive(activeUser);
        return this.levelEducationService.index(countryId || activeUser.pais_id, head(entity));
      }),
      map((res) => res?.data || (this.multiple ? [] : null)),
    );

    this.ctrl.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((value) => this.onChange(value));
  }

  public writeValue(obj: any): void {
    this.ctrl.setValue(obj);
  }

  public registerOnChange(fn: any): void {
    this.changeValue = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
    this.ctrl.markAllAsTouched();
  }

  public setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.ctrl.disable();
    } else {
      this.ctrl.enable();
    }
  }

  public onFocus() {
    this.onTouched();
  }

  private onChange(value: any) {
    const defaultValue = this.multiple ? [] : undefined;
    this.changeValue(value ? value : defaultValue);
  }

  private onTouched = () => true;
  private changeValue = (_: any) => true;
}
