import { ChangeDetectionStrategy, Component, DestroyRef, Input, OnDestroy } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AssessmentApplicationService } from '@app/core';
import { AssessmentLevelContent, AssessmentWeightType } from '@app/core/models';
import { notNull, safeEmpty, selectedEntityActive } from '@app/shared';
import { Strategy } from '@app/shared/modules/template/components/popover';
import { AppSelectors } from '@app/store';
import { AppState } from '@app/store/app.state';
import { Store } from '@ngrx/store';
import { compact, first, isArray, uniq } from 'lodash';
import { BehaviorSubject, combineLatest, map, switchMap, tap } from 'rxjs';

@Component({
  selector: 'app-tab-abilities-disciplines',
  templateUrl: './tab-abilities-disciplines.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabAbilitiesDisciplinesComponent implements OnDestroy {
  public readonly assessmentWeightType = AssessmentWeightType;
  public readonly strategy = Strategy;
  public readonly levelContent = AssessmentLevelContent;
  public isDivision: boolean = false;
  private _modeloId: number;
  public data$ = new BehaviorSubject<any>(undefined);
  public loading$ = new BehaviorSubject<boolean>(false);
  public application$ = new BehaviorSubject<any>(undefined);
  private _activeUser$ = new BehaviorSubject<any>(undefined);
  private subjectMap: Map<number, string> = new Map();
  private categoryMap: Map<number, string> = new Map();

  constructor(
    private _evaluationService: AssessmentApplicationService,
    private destroy: DestroyRef,
    private _store: Store<AppState>,
    private _destroyRef: DestroyRef,
  ) {
    this._store
      .select(AppSelectors.ActiveUser)
      .pipe(takeUntilDestroyed(this.destroy), notNull())
      .subscribe((user) => {
        this._activeUser$.next(user);
      });

    const activeUser$ = this._store.select(AppSelectors.ActiveUser).pipe(takeUntilDestroyed(this.destroy), notNull());
    const application$ = this.application$.pipe(takeUntilDestroyed(this.destroy), notNull());
    combineLatest({
      application: application$,
      activeUser: activeUser$,
    })
      .pipe(
        takeUntilDestroyed(this._destroyRef),
        tap(({ activeUser }) => {
          this.loading$.next(true);
          this._modeloId = activeUser.pais_id;
          const entidade = first(selectedEntityActive(activeUser));
        }),
        switchMap(({ application, activeUser }) =>
          this._evaluationService
            .abilitySubject(application.uuid, {
              entidade: first(selectedEntityActive(activeUser)),
            })
            .pipe(safeEmpty()),
        ),
        map((data: any) => ({
          ...data,
          entities: compact(uniq(data.entities.map((arr) => (isArray(arr) && !arr?.length ? undefined : arr)))),
        })),
        map((data: any) => {
          const percentstudents = data.entities.map((entity: any) => ({
            ...entity,
            percentage:
              entity.total_student > 0 ? ((entity.student_quantity / entity.total_student) * 100).toFixed(2) : 0,
          }));

          return {
            ...data,
            entities: percentstudents,
          };
        }),
      )
      .subscribe((data: any) => {
        this.loading$.next(false);
        this.data$.next(data.entities);
        this.mapSubjects(data.subjects);
        this.mapCategories(data.categories);
      });
  }

  private mapSubjects(subjects: any[]): void {
    subjects.forEach((subject) => {
      this.subjectMap.set(subject.subject_id, subject.subject_description);
    });
  }

  public getSubjectDescription(subjectId: number): string {
    return this.subjectMap.get(subjectId);
  }

  private mapCategories(categories: any[]): void {
    categories.forEach((category) => {
      this.categoryMap.set(category.category_id, category.category_name);
    });
  }

  public getCategoryDescription(categoryId: number): string {
    return this.categoryMap.get(categoryId) || '-';
  }

  @Input()
  public set application(value) {
    this.application$.next(value);
  }

  public ngOnDestroy(): void {
    this.data$.complete();
    this.application$.complete();
    this.loading$.complete();
  }
}
