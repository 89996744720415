import { ChangeDetectorRef, NgZone, OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'timeAgo',
  pure: false,
})
export class TimeAgoPipe implements PipeTransform, OnDestroy {
  private timer: number;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private translateService: TranslateService,
    private ngZone: NgZone,
  ) {}

  public transform(value: any, args?: any) {
    this.removeTimer();

    const d = new Date(value);
    const now = new Date();
    const seconds = Math.round(Math.abs((now.getTime() - d.getTime()) / 1000));

    if (!args) {
      const timeToUpdate = Number.isNaN(seconds) ? 1000 : this.getSecondsUntilUpdate(seconds) * 1000;
      this.timer = this.createTimer(timeToUpdate);
    }

    const minutes = Math.round(Math.abs(seconds / 60));
    const hours = Math.round(Math.abs(minutes / 60));
    const days = Math.round(Math.abs(hours / 24));
    const months = Math.round(Math.abs(days / 30.416));
    const years = Math.round(Math.abs(days / 365));
    if (Number.isNaN(seconds)) {
      return '';
    } else if (seconds <= 45) {
      return this.translate('segundos');
    } else if (seconds <= 90) {
      return this.translate('minuto-atras');
    } else if (minutes <= 45) {
      return this.translate('minutos-atras', { minutes });
    } else if (minutes <= 90) {
      return this.translate('hora-atras');
    } else if (hours <= 22) {
      return this.translate('horas-atras', { hours });
    } else if (hours <= 36) {
      return this.translate('dia-atras');
    } else if (days <= 25) {
      return this.translate('dias-atras', { days });
    } else if (days <= 45) {
      return this.translate('mes-atras');
    } else if (days <= 345) {
      return this.translate('meses-atras', { months });
    } else if (days <= 545) {
      return this.translate('ano-atras');
    }
    return this.translate('anos-atras', { years });
  }

  public ngOnDestroy() {
    this.removeTimer();
  }

  private translate(key: string, value?: any) {
    return this.translateService.instant(`tempo-atras.${key}`, value);
  }

  private createTimer(timeToUpdate: number) {
    return this.ngZone.runOutsideAngular(() => {
      if (typeof window !== 'undefined') {
        return window.setTimeout(() => {
          this.ngZone.run(() => this.changeDetectorRef.markForCheck());
        }, timeToUpdate);
      }
      return null;
    });
  }

  private removeTimer() {
    if (this.timer) {
      window.clearTimeout(this.timer);
      this.timer = null;
    }
  }

  private getSecondsUntilUpdate(seconds: number) {
    const min = 60;
    const hr = min * 60;
    const day = hr * 24;
    if (seconds < min) {
      return 60;
    } else if (seconds < hr) {
      return 300;
    } else if (seconds < day) {
      return 600;
    }
    return 3600;
  }
}
