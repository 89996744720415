import { Dialog } from '@angular/cdk/dialog';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  forwardRef,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { AlertService } from '@app/core';
import { ApiService } from '@app/core/services/api.service';
import { notNull } from '@app/shared/utils';
import { CKEditorComponent } from '@ckeditor/ckeditor5-angular';
import Editor from '@ckeditor/ckeditor5-build-classic';
import { TranslateService } from '@ngx-translate/core';
import { isEmpty, isNil } from 'lodash';
import { BehaviorSubject, Subject, firstValueFrom, map } from 'rxjs';
import { ModalMathFormulaComponent } from './components/modal-math-formula/modal-math-formula.component';
import { FULL } from './config';

@Component({
  selector: 'app-text-editor',
  templateUrl: './text-editor.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => TextEditorComponent),
    },
  ],
})
export class TextEditorComponent implements OnInit, OnDestroy, ControlValueAccessor, AfterViewInit {
  @Input() public url: string;
  @Input() public toolbar = FULL;
  @Input() public value: any;
  @Input() public full: boolean = false;
  @Input() public label: string = 'geral.descricao';
  @Output() public setBlur = new EventEmitter();
  @ViewChild(CKEditorComponent, { static: true }) private _editor: CKEditorComponent;
  public readonly editor: any = Editor;
  public config: any;
  public editor$ = new BehaviorSubject(undefined);
  public isEditorFocused: boolean = false;
  public readonlyEditor: boolean;
  private _disabled: boolean;
  private _destroy$ = new Subject<void>();
  private _refresh$ = new BehaviorSubject(undefined);

  constructor(
    private _ngZone: NgZone,
    private _dialog: Dialog,
    private _apiService: ApiService,
    private _alertService: AlertService,
    private _translateService: TranslateService,
  ) {}

  public get disabled(): boolean {
    return this._disabled || this.readonlyEditor;
  }

  @Input()
  public set readOnly(value: boolean) {
    this.readonlyEditor = value;
    if (this._editor) {
      this.toggleReadOnly(this._editor?.editorInstance, value);
    }
  }

  @Input()
  public set focus(value: boolean) {
    if (!!value) {
      this._editor?.editorInstance?.focus();
    }
  }

  public onBlur() {
    this.onChange(this.value);
    this.setBlur.emit(this.value);
  }

  public ngAfterViewInit() {
    this._editor?.ready.subscribe(() => {
      setTimeout(() => this._editor?.editorInstance?.focus());
    });
  }

  public ngOnInit() {
    this._refresh$.next(this.value);
    this.config = this.buildConfig();
  }

  public onFocusChange(isFocused: boolean) {
    if (isFocused) {
      this.isEditorFocused = true;
    } else {
      setTimeout(() => {
        if (!this.isEditorFocused) {
          this.isEditorFocused = false;
        }
      }, 0);
    }
  }

  public ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
    this._refresh$.complete();
  }

  public writeValue(value: any) {
    this.value = value;
    this._refresh$.next(this.value);
  }

  public registerOnChange(fn: any) {
    this.changeValue = fn;
  }

  public registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  public setDisabledState?(isDisabled: boolean) {
    this._disabled = isDisabled;
  }

  public onChange(value: any) {
    const safe = isEmpty(value) ? null : value;
    if (this.value !== safe) {
      this.changeValue(safe);
    }
    this.value = safe;
  }

  public onFocus() {
    this.onTouched();
    this.editor?.editorInstance?.focus();
  }

  public toggleReadOnly(ref: any, readOnly: boolean) {
    const toolbar = ref?.ui.view.toolbar.element;
    if (toolbar) {
      if (readOnly) {
        toolbar.style.display = 'none';
      } else {
        toolbar.style.display = 'block';
      }
    }
  }

  public onReady(editor: any) {
    if (!!editor) {
      this.toggleReadOnly(editor, this.readonlyEditor);

      this._refresh$.pipe(map((value) => (isNil(value) ? '<p></p>' : String(value)))).subscribe((res) => {
        this.value = res;
        editor.setData(res);
      });
      editor.plugins.get('MathPlugin').upload = (editor) => this.run(() => this.mathFormula(editor));
      editor.model.document.on('change:data', () => {
        const selection = editor.model.document.selection;
        const selectedElement = selection.getSelectedElement();

        if (selectedElement && selectedElement.name === 'imageInline') {
          return [];
        }
      });

      editor.plugins.get('FileRepository').createUploadAdapter = (loader) => ({
        upload: async () => {
          const file = await loader.file;

          const result = await firstValueFrom(
            this._apiService.upload<any>(this.url, file).pipe(map((res) => ({ ...res, urls: { default: res.url } }))),
          );

          return result;
        },
        abort: null,
      });

      editor.model.document.on('change:data', () => {
        const selection = editor.model.document.selection;
        const selectedElement = selection.getSelectedElement();

        if (selectedElement && selectedElement.name === 'imageInline') {
          return [];
        }
      });

      editor.plugins.get('ClipboardPipeline').on('inputTransformation', async (data) => {
        const { dataTransfer } = data;
        const htmlData = dataTransfer?.getData('text/html');

        const tempElement = document.createElement('div');

        if (htmlData) {
          tempElement.innerHTML = htmlData;

          tempElement.querySelectorAll('img').forEach((img) => {
            const imageUrl = img.src;

            if (imageUrl.includes('googleusercontent.com')) {
              img.src = imageUrl;
            }
          });

          if (tempElement.innerHTML) {
            data.content = editor.data.processor.toView(tempElement.innerHTML);
          }
        }
      });
    }
  }

  private buildConfig(): any {
    const lang = this._translateService.defaultLang;
    return {
      htmlSupport: {
        allow: [
          {
            name: /.*/,
            attributes: true,
            classes: true,
            styles: true,
          },
        ],
      },
      toolbar: {
        items: [...this.toolbar],
      },
      supportAllValues: true,
      mediaEmbed: {
        previewsInData: true,
      },
      math: {
        outputType: 'span',
      },
      image: {
        toolbar: [
          'imageStyle:inline',
          'imageStyle:block',
          'imageStyle:alignLeft',
          'imageStyle:alignRight',
          '|',
          'toggleImageCaption',
          'imageTextAlternative',
          '|',
          'linkImage',
        ],
        insert: {
          type: 'inline',
        },
        styles: ['inline', 'alignLeft', 'alignRight'],
      },
      table: {
        contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells'],
      },

      clipboard: {
        pasteAsPlainText: false,
        htmlAllowedContent: true,
        imagePaste: true,
        allowedContent: true,
      },
      extraAllowedContent: 'img[src,alt,width,height]',
      language: { ui: lang, content: lang },
      typing: {
        transformations: {
          include: ['quotes', 'typography', 'link', 'symbols'],
          extra: [
            { from: ':)', to: '🙂' },
            { from: ':tada:', to: '🎉' },
          ],
        },
      },
    };
  }

  private onTouched = () => true;
  private changeValue = (_: any) => true;

  private run = (fn: () => void) => this._ngZone.runOutsideAngular(() => this._ngZone.run(fn));

  private mathFormula(editor: any) {
    this._dialog
      .open<string>(ModalMathFormulaComponent, { disableClose: true })
      .closed.pipe(notNull())
      .subscribe((src) =>
        editor.model.change((writer) => {
          const imageElement = writer.createElement('imageInline', { src });
          writer.model.insertContent(imageElement, editor.model.document.selection);
        }),
      );
  }
}
