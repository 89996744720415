import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CertificateService {
  private _certificate$ = new BehaviorSubject<any[]>(undefined);
  private _useCertificate$ = new BehaviorSubject<boolean>(false);
  public get certificate$(): Observable<any> {
    return this._certificate$.asObservable();
  }

  public get useCertificate$(): Observable<any> {
    return this._useCertificate$.asObservable();
  }

  public passCertificate(certificate: any) {
    this._certificate$.next(certificate);
  }

  public useCertificate(value: boolean) {
    this._useCertificate$.next(value);
  }
}
