import { Dialog } from '@angular/cdk/dialog';
import { Injectable } from '@angular/core';
import {
  AssessmentApplicationService,
  AssessmentAttemptService,
  AssessmentService,
  AssessmentTrailApplicationService,
} from '@app/core';
import { AssessmentOrigin, AssessmentStatusAttemptEnum } from '@app/core/models';
import { BehaviorSubject, of, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { ModalAssessmentExecutionComponent } from './modal-assessment-execution.component';
import { ModalAssessmentExecutionInit, ModelAssessmentExecutionData } from './store/modal-assessment-execution.actions';

export interface ModalAvaliacaoFormulario {
  data?: ModelAssessmentExecutionData;
  extra?: any;
}
@Injectable({
  providedIn: 'root',
})
export class ModalAssessmentExecutionService {
  private refreshSubject = new Subject<boolean>();
  public refresh$ = this.refreshSubject.asObservable();
  public readonly helper = {
    [AssessmentOrigin.Trilha]: (params) => this.trilhaData(params),
    [AssessmentOrigin.GDA]: (params) => this.GDAData(params),
  };

  public scrollToQuestion$ = new BehaviorSubject<string>(undefined);

  constructor(
    private _dialog: Dialog,
    private _assessmentTrailApplicationService: AssessmentTrailApplicationService,
    private _assessmentApplicationService: AssessmentApplicationService,
    private _assessmentAttemptService: AssessmentAttemptService,
    private _assessmentService: AssessmentService,
  ) {}

  public open(origem: AssessmentOrigin, params?: ModalAvaliacaoFormulario) {
    this._dialog.open(ModalAssessmentExecutionComponent, {
      hasBackdrop: true,
      disableClose: true,
      data: this.helper[origem](params),
    });
  }

  private trilhaData(params: ModalAvaliacaoFormulario): ModalAssessmentExecutionInit {
    return {
      data: params.data,
      extra: params?.extra,
      resource: {
        aplicacao: {
          start: () => {
            if (params.extra?.preview) {
              return this._assessmentTrailApplicationService.vitrine(params.data.aplicacao_uuid);
            }
            return this._assessmentTrailApplicationService.start(params.data.aplicacao_uuid);
          },
          generalData: () =>
            this._assessmentTrailApplicationService.generalData(params.data.aplicacao_uuid).pipe(
              map(({ data, ret }) => {
                if (params.extra?.preview) {
                  return { data: { ...data, status: AssessmentStatusAttemptEnum.Init }, ret };
                }
                return { data, ret };
              }),
            ),
        },
        tentativa: {
          finalize: (uuid, data) => {
            if (params.extra?.preview) {
              return of({ data: null, ret: 1 });
            }
            return this._assessmentTrailApplicationService.finalize(uuid, { ...data, trilha: params.extra.trilha });
          },
        },
        questao: {
          question: (uuid, data) => {
            if (params.extra?.preview) {
              return of({ data: null, ret: 1 });
            }
            return this._assessmentTrailApplicationService.answerQuestion(uuid, data);
          },
        },
      },
    };
  }

  private GDAData(params: ModalAvaliacaoFormulario): ModalAssessmentExecutionInit {
    return {
      data: params.data,
      extra: params?.extra,
      resource: {
        aplicacao: {
          start: () => {
            if (params.extra?.preview) {
              if (params.data?.avaliacao_uuid) {
                return this._assessmentService.preview(params.data?.avaliacao_uuid);
              }

              return this._assessmentApplicationService.preview(params.data?.aplicacao_uuid);
            }
            return this._assessmentAttemptService.start(params.data?.aplicacao_uuid);
          },
          generalData: () => {
            if (!!params.data?.avaliacao_uuid) {
              return this._assessmentService
                .generalData(params.data?.avaliacao_uuid)
                .pipe(map(({ data, ret }) => ({ data: { ...data, status: AssessmentStatusAttemptEnum.Init }, ret })));
            }

            return this._assessmentApplicationService.generalData(params.data?.aplicacao_uuid).pipe(
              map(({ data, ret }) => {
                if (params.extra?.preview) {
                  return { data: { ...data, status: AssessmentStatusAttemptEnum.Init }, ret };
                }
                return { data, ret };
              }),
            );
          },
        },
        tentativa: {
          finalize: (uuid, data) => {
            if (params.extra?.preview) {
              return of({ data: null, ret: 1 });
            }
            return this._assessmentAttemptService.finalize(uuid, data);
          },
        },
        questao: {
          question: (uuid, data) => {
            if (params.extra?.preview) {
              return of({ data: null, ret: 1 });
            }
            return this._assessmentAttemptService.question(uuid, data);
          },
        },
      },
    };
  }

  public refresh() {
    this.refreshSubject.next(true);
  }
}
