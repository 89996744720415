<app-modal-header>
  <div class="grid grid-cols-[auto_1fr] items-center gap-5 lg:grid-cols-[220px_auto_1fr]">
    <div class="flex">
      <svg-icon class="h-9 w-9 text-modules-drive-800" src="assets/icons/icon-drive.svg"></svg-icon>
      <p class="hidden pl-3 pr-16 text-lg font-semibold text-gray-700 lg:block">
        {{ 'servicos.drive.portal-drive' | translate }}
      </p>
    </div>
    <hr app-modal-divider class="!m-0 hidden lg:block" />
    <div class="flex items-center overflow-hidden">
      <ng-container *ngFor="let bread of breadcrumb$ | async; let index = index; let last = last">
        <p
          class="flex cursor-pointer whitespace-nowrap px-1 text-lg font-medium"
          [ngClass]="{ 'text-base-gray-300': !last, 'text-black': last }"
          (click)="onNavigate(bread.path, index)"
        >
          {{ bread.name }}
        </p>
        <svg-icon
          *ngIf="!last"
          class="flex h-4 w-4 px-1 text-base-gray-300"
          src="assets/icons/icon-arrow-right.svg"
        ></svg-icon>
      </ng-container>
    </div>
  </div>
</app-modal-header>
<div app-modal-content class="h-[90vh] w-[90vw] !p-0 md:h-[70vh] xl:h-[50rem] xl:w-[80rem]">
  <app-sidenav-group class="h-full w-full rounded-b-lg bg-base-whisper-300">
    <ng-container *ngIf="{ data: categories$ | async } as categories">
      <ng-template app-sidenav-start [opened]="true" panelWidth="250px" buttonAnchor="bg-modules-drive-300">
        <div class="h-full w-[250px] gap-5 rounded-bl-lg bg-base-whisper-300 p-4 transition duration-500">
          <ng-container *ngIf="!!categories.data; else loadTemplate">
            <div class="flex flex-col gap-2 overflow-y-auto px-3 pb-5">
              <button
                *ngFor="let category of categories.data"
                [ngClass]="{ '!bg-modules-drive-500 !text-white': category.name === actived?.name }"
                app-flat-button
                class="bg-white text-md font-semibold transition duration-300 hover:bg-modules-drive-500 hover:text-white"
                type="button"
                (click)="onSelect(category)"
              >
                {{ category.name }}
              </button>
            </div>
          </ng-container>
          <ng-template #loadTemplate>
            <app-skeleton></app-skeleton>
          </ng-template>
        </div>
      </ng-template>

      <ng-template app-sidenav-outer>
        <div class="h-full w-full gap-5 overflow-y-auto p-4 transition duration-500">
          <ng-container *ngIf="!!categories.data; else loadTemplate">
            <app-table-storage [category]="actived" class="overflow-y-auto"></app-table-storage>
          </ng-container>
          <ng-template #loadTemplate>
            <app-skeleton></app-skeleton>
          </ng-template>
        </div>
      </ng-template>
    </ng-container>
  </app-sidenav-group>
</div>
