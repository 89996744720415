<table app-table>
  <thead>
    <tr>
      <th app-table-cell class="rounded-lg !bg-modules-sky-english-500 !text-center !font-semibold shadow" colspan="3">
        {{ 'servicos.sky-english.relatorio-atendimento' | translate }} - {{ activedYear }}
      </th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let attendanceReport of attendanceReports" class="bg-gray-100">
      <td class="!text-center">
        {{ attendanceReport.attendance_date | date : 'dd/MM/yyyy' }}
      </td>
      <td class="!text-center">
        {{ attendanceReport.advisor.name }}
      </td>
      <td class="flex !text-center">
        <button
          app-icon-button
          type="button"
          app-tooltip
          [tooltip]="'servicos.sky-english.baixar-relatorio' | translate"
          class="!rounded-full"
          (click)="onDownloadReport(attendanceReport)"
        >
          <svg-icon class="flex h-7 w-7 text-base-service-sky-english" [src]="'pdf' | extensionIcon"></svg-icon>
        </button>
      </td>
    </tr>
  </tbody>
</table>
