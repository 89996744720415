import { InjectionToken } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { portalConst } from '@app/config';
import { GenericModel } from '@app/core/models';
import { FULL } from '@app/shared/modules/text-editor/config';
import { NgxCurrencyConfig, NgxCurrencyInputMode } from 'ngx-currency';
import { DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { Observable } from 'rxjs';

export const MODAL_FORM = new InjectionToken<any>('modalForm');

export interface SidenavDailyShow {
  id?: number;
  entity: GenericModel;
  grade: GenericModel;
  group: GenericModel;
  subject: GenericModel;
  date: Date;
  alert?: string;
}

export enum ModalDailyTab {
  Daily,
  Activity,
}

export interface ModalDailyData {
  current: ModalDailyTab;
  date?: Date;
  data?: any;
  entityId?: number;
  groupId?: number;
  subjectId?: number;
}

export abstract class FormBase {
  public abstract type: ModalDailyTab;
  public abstract title: string;
  public abstract titleElement: string;
  public abstract targetsLabel: string;
  public abstract publishLabel: string;
  public abstract exist(params: SidenavDailyShow): Observable<any>;
  public abstract resetForm(): void;
  public abstract markAllAsTouched(): void;
  public abstract patchValue(data: any, target?: SidenavDailyShow): void;
  public abstract onSave(params: any): Observable<boolean>;
  public abstract form: FormGroup;
  public abstract showSidenavEnd: boolean;
  public abstract sidenavEndData: any;
  public abstract targets: SidenavDailyShow[];
  public abstract submitted$: Observable<boolean>;
}

export const DAILY_TEXT_EDITOR_URL = `${portalConst.api.usuario}/v2/upload/ckeditor-image?module=eclass`;
export const DAILY_TOOLBAR = FULL;
export const DAILY_UPLOAD: DropzoneConfigInterface = {
  clickable: true,
  url: `${portalConst.api.eclass}/v1/upload/attachment`,
  createImageThumbnails: false,
  maxFiles: 10,
  maxFilesize: 30,
  acceptedFiles: '.pdf,.mp3,.mp4,.doc,.docx,.xls,.xlsx,.ppt,.pptx,.jpg,.jpeg,.png,.pdf,.txt',
  previewsContainer: false,
};
export const SCORE_CONFIG: NgxCurrencyConfig = {
  align: 'right',
  allowNegative: false,
  allowZero: true,
  decimal: ',',
  precision: 2,
  prefix: '',
  suffix: '',
  thousands: '.',
  nullable: true,
  min: 0,
  max: 100,
  inputMode: NgxCurrencyInputMode.Financial,
};
