import { Injectable } from '@angular/core';
import { portalConst } from '@app/config';
import { Observable } from 'rxjs';
import { ApiService, Resp } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class BimesterSchoolService {
  private url = `${portalConst.api.usuario}/v1`;

  constructor(private api: ApiService) {}

  public listBimester(schoolId: any): Observable<Resp<any[]>> {
    return this.api.get<any[]>(`${this.url}/bimestres-escola/${schoolId}`);
  }

  public lastsYearsEntity(schoolId: any): Observable<Resp<any[]>> {
    return this.api.get<any[]>(`${this.url}/last-years-entity/${schoolId}`);
  }
}
