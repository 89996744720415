<div class="flex flex-col items-start gap-4 py-3 pb-12 transition duration-300 lg:pb-3 lg:pt-12">
  <ng-container *ngFor="let menu of items | keyvalue">
    <h1 class="w-full text-center text-md uppercase text-theme-700">{{ menu.key | translate }}</h1>
    <ng-container *ngFor="let item of menu.value">
      <ng-container *ngIf="!!item.click || !!item.redirect; else internalTemplate">
        <a
          app-button
          class="w-full !justify-start gap-3 !rounded-3xl px-6 py-3 transition duration-300 hover:bg-theme-100 hover:text-theme-500"
          [href]="item.redirect || null"
          (click)="item.click ? onActionClick(item) : onClose(item)"
          [attr.target]="item.redirect ? '_blank' : null"
          [attr.href]="item.click ? null : item.redirect"
        >
          <svg-icon class="flex h-6 w-6" [src]="item.icon | icon"></svg-icon>
          <span>{{ item.title | translate }}</span>
        </a>
      </ng-container>
      <ng-template #internalTemplate>
        <a
          app-button
          class="w-full !justify-start gap-3 !rounded-3xl px-6 py-3 transition duration-300 hover:bg-theme-100 hover:text-theme-500"
          (click)="onClose(item)"
          [routerLink]="item.url"
          [routerLinkActive]="['!bg-theme-500', '!text-white']"
          [routerLinkActiveOptions]="{ exact: item.exact }"
        >
          <svg-icon class="flex h-6 w-6" [src]="item.icon | icon"></svg-icon>
          <span>{{ item.title | translate }}</span>
        </a>
      </ng-template>
    </ng-container>
  </ng-container>
</div>
