<app-popover class="block w-[300px]">
  <div app-popover-content class="flex h-[25rem] max-h-[50vh] flex-col gap-1 overflow-y-auto !py-3 lg:max-h-[40rem]">
    <p class="pb-4 text-center uppercase text-gray-900" translate>notificacoes.recentes</p>
    <ng-container *ngIf="{ load: load$ | async } as data">
      <ng-container *ngIf="!data.load; else loadingTemplate">
        <ng-container *ngIf="notifications | noEmpty; else emptyTemplate">
          <app-item-notification
            *ngFor="let notification of notifications"
            [summary]="true"
            [notification]="notification"
            (afterRead)="onAfterRead()"
          ></app-item-notification>
        </ng-container>
      </ng-container>
    </ng-container>
    <ng-template #loadingTemplate>
      <div class="flex flex-wrap justify-center gap-2">
        <app-skeleton *ngFor="let skeleton of [1, 2, 3, 4, 5]" class="block !h-14 rounded-xl shadow"></app-skeleton>
      </div>
    </ng-template>
    <ng-template #emptyTemplate>
      <app-jumbotron translate>notificacoes.vazio</app-jumbotron>
    </ng-template>
  </div>

  <hr app-divider />

  <div app-popover-footer>
    <button
      app-button
      (click)="onSeeAll()"
      type="button"
      class="w-full gap-2 !rounded-full !bg-base-status-warning !py-3 font-semibold text-white"
      translate
    >
      notificacoes.ver-todas
    </button>
  </div>
</app-popover>
