import { DialogRef } from '@angular/cdk/dialog';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { intervalToDuration } from 'date-fns';
import { isNil } from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { ModalAssessmentExecutionSelectors } from '../../store';
import { ModalAssessmentExecutionState } from '../../store/modal-assessment-execution.state';

@Component({
  selector: 'app-modal-finalize',
  templateUrl: './modal-finalize.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalFinalizeComponent {
  public state$ = new BehaviorSubject<ModalAssessmentExecutionState>(undefined);

  constructor(private _store: Store, private _dialogRef: DialogRef) {
    this._store.select(ModalAssessmentExecutionSelectors.selectState).subscribe((state) => this.state$.next(state));
  }

  public get countQuestionsAnswered(): number {
    return (
      this.state$.value.questoes.filter((q) => !isNil(q.resposta?.resposta) || !isNil(q.resposta?.alternativaUuid))
        ?.length || 0
    );
  }

  public get countQuestions(): number {
    return this.state$.value.questoes?.length || 0;
  }

  public get time() {
    if (!this.state$.value.started_at) {
      return;
    }

    const duration = intervalToDuration({ start: new Date(this.state$.value.started_at), end: new Date() });
    const hours = `${duration.hours}h ${duration.minutes}m`;

    return duration.days ? `${duration.days}d ${hours}` : hours;
  }

  public onClose() {
    this._dialogRef.close(false);
  }

  public onSave() {
    this._dialogRef.close(true);
  }
}
