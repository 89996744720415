import { AnimationEvent, animate, state, style, transition, trigger } from '@angular/animations';
import { CdkAccordionItem } from '@angular/cdk/accordion';
import { UniqueSelectionDispatcher } from '@angular/cdk/collections';
import { TemplatePortal } from '@angular/cdk/portal';
import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  Inject,
  Input,
  OnDestroy,
  Optional,
  SkipSelf,
  ViewContainerRef,
} from '@angular/core';
import { Subject, filter, startWith, take, takeUntil } from 'rxjs';
import { AccordionLazyDirective } from './accordion-lazy.directive';
import { ACCORDION, AccordionComponent } from './accordion.component';

@Component({
  selector: 'app-accordion-item',
  templateUrl: './accordion-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('expansion', [
      state('false', style({ height: '0px', visibility: 'hidden' })),
      state('true', style({ height: '*', visibility: 'visible' })),
      transition('true <=> false', animate('300ms ease-in-out')),
    ]),
  ],
})
export class AccordionItemComponent extends CdkAccordionItem implements AfterContentInit, OnDestroy {
  @Input() public colIndex!: number;
  @Input() public headerClass: string;
  @Input() public contentClass: string;
  @ContentChild(AccordionLazyDirective) private _lazyContent: AccordionLazyDirective;
  public readonly bodyAnimationDone$ = new Subject<AnimationEvent>();
  public item: TemplatePortal;
  private _destroy$ = new Subject<void>();

  constructor(
    @Optional() @Inject(ACCORDION) @SkipSelf() accordion: AccordionComponent,
    changeDetectorRef: ChangeDetectorRef,
    uniqueSelectionDispatcher: UniqueSelectionDispatcher,
    private _viewContainerRef: ViewContainerRef,
  ) {
    super(accordion, changeDetectorRef, uniqueSelectionDispatcher);
  }

  public ngAfterContentInit() {
    if (this._lazyContent) {
      this.opened
        .pipe(
          startWith(null),
          takeUntil(this._destroy$),
          filter(() => this.expanded && !this.item),
          take(1),
        )
        .subscribe(() => (this.item = new TemplatePortal(this._lazyContent._template, this._viewContainerRef)));
    }
  }

  public override ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
    super.ngOnDestroy();
  }

  public onToggle() {
    super.toggle();
  }
}
