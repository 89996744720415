<ng-container *ngIf="{ items: data$ | async, loading: loading$ | async } as data">
  <ng-select
    #select
    app-select
    [placeholder]="placeholder | translate"
    [items]="data.items"
    [multiple]="multiple"
    [clearable]="clearable"
    [searchable]="searchable"
    [searchFn]="searchFn"
    [loading]="data.loading"
    (change)="onChange($event, data.items)"
    (focus)="onFocus()"
    [formControl]="ctrl"
    bindValue="id"
    bindLabel="description"
  >
    <ng-template *ngIf="multiple" ng-header-tmp>
      <button class="rounded bg-gray-600 px-4 py-2 font-semibold text-white" (click)="selectAll(select)" translate>
        geral.selecionar-todos
      </button>
    </ng-template>

    <ng-template ng-label-tmp let-item="item" let-clear="clear">
      <div class="w-auto pr-2">
        <span class="ng-value-icon left" (click)="clear(item)" aria-hidden="true">×</span>
        <span class="pl-2 text-sm font-semibold">{{ item.description }}</span>
        <div class="pl-1 text-xs font-normal text-base-gray-400">{{ item.nome }}</div>
      </div>
    </ng-template>

    <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
      <div class="w-auto">
        <span [ngOptionHighlight]="search" class="text-sm font-semibold">{{ item.description }}</span>
        <div [ngOptionHighlight]="search" class="text-xs font-normal text-base-gray-400">{{ item.nome }}</div>
      </div>
    </ng-template>
  </ng-select>
</ng-container>
