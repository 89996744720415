import { Injectable } from '@angular/core';
import { AssessmentService, NotificationService } from '@app/core';
import { Resp } from '@app/core/services/api.service';
import { AssessmentApplicationService } from '@app/core/services/assessment-application.service';
import { EMPTY_ACTIONS, notNull, safeEmpty, selectedEntityActive } from '@app/shared';
import { AppSelectors } from '@app/store';
import { AppState } from '@app/store/app.state';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { format, isDate } from 'date-fns';
import { first, isEqual, uniqWith } from 'lodash';
import { OperatorFunction, combineLatest, of } from 'rxjs';
import { exhaustMap, map, switchMap, take, tap } from 'rxjs/operators';
import { ModalNewAssessmentSelectors } from '.';
import * as ModalNewAssessmentActions from './modal-new-assessment.actions';
import { updateState } from './modal-new-assessment.reducer';
import { ModalNewAssessmentState } from './modal-new-assessment.state';
import { AssessmentApplicationMode } from '@app/core/models';

@Injectable({ providedIn: 'root' })
export class ModalNewAssessmentEffects {
  public initData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ModalNewAssessmentActions.InitDataEffect),
      switchMap(({ aplicacao_uuid, avaliacao_uuid, extra }) =>
        combineLatest({
          aplicacao_uuid: of(aplicacao_uuid),
          avaliacao_uuid: of(avaliacao_uuid),
          extra: of(extra),
          user: this._storeApp.select(AppSelectors.ActiveUser).pipe(notNull(), take(1)),
        }),
      ),
      exhaustMap(({ aplicacao_uuid, avaliacao_uuid, extra, user }) => {
        if (!!aplicacao_uuid) {
          return this._assessmentApplicationService.show(aplicacao_uuid).pipe(
            safeEmpty(),
            map((data) => ({ data, isUpdate: true, hasChanges: false, extra })),
            map((result) => {
              if (!!extra?.duplicate) {
                result.data = this.clearFields(result.data, user);
              }

              result.data.coautores = result.data.coautores?.map((coautor) => coautor.user_id) || [];

              if (result.data.publico_alvo.some((target) => !!target.padrao_serie_id)) {
                result.data.entidades = uniqWith(
                  result.data.publico_alvo.map(({ entidade_id, entidade }) => ({
                    entidade_id,
                    entidade,
                  })),
                  isEqual,
                );

                result.data.publico_alvo = uniqWith(
                  result.data.publico_alvo.map(
                    ({
                      entidade,
                      entidade_id,
                      liberar_data_inicio,
                      liberar_data_fim,
                      modo_aplicacao,
                      total_publico_alvo,
                      ...value
                    }) => value,
                  ),
                  isEqual,
                );
              }

              return result;
            }),
          );
        }

        if (!!avaliacao_uuid) {
          return this._assessmentService.show(avaliacao_uuid).pipe(
            map(({ data }) => {
              data = this.clearFields({ avaliacao: data }, user);
              return {
                data,
                isUpdate: true,
                hasChanges: false,
                extra,
              };
            }),
          );
        }

        return of({ data: null, isUpdate: false, hasChanges: false, extra });
      }),
      map((result) => ModalNewAssessmentActions.DataLoadedReducer(result)),
    ),
  );

  public updateStateEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ModalNewAssessmentActions.UpdateStateEffect),
      switchMap((data) =>
        combineLatest({
          data: this.store.select(ModalNewAssessmentSelectors.selectState).pipe(
            take(1),
            map((state) => ({ state: updateState(state, data.value), actions: data.actions })),
          ),
          user: this._storeApp.select(AppSelectors.ActiveUser).pipe(take(1), notNull()),
        }),
      ),
      exhaustMap(({ data, user }) => {
        // eslint-disable-next-line prefer-const
        let { aplicacao, avaliacao, questoes } = data.state;
        questoes = questoes.filter(({ uuid, parent_uuid }) => !!uuid || parent_uuid);
        const entidade = first(selectedEntityActive(user));

        let target = [];
        if (!!aplicacao.entidades?.length) {
          aplicacao.publico_alvo.forEach(({ turma_id, user_id, padrao_serie_id }) => {
            aplicacao.entidades.forEach((entity) =>
              target.push({ turma_id, user_id, padrao_serie_id, entidade_id: entity.entidade_id || entity.id }),
            );
          });
        } else {
          target = aplicacao.publico_alvo.map(({ turma_id, user_id, padrao_serie_id, turma, user }) => ({
            turma_id,
            user_id,
            padrao_serie_id,
            entidade_id: turma?.entidade?.id ? turma.entidade.id : user?.entidade?.id ? user.entidade.id : undefined,
          }));
        }

        avaliacao.orientacao_cartao =
          aplicacao.modo_aplicacao !== AssessmentApplicationMode.Online ? avaliacao.orientacao_cartao : null;

        avaliacao.ano = avaliacao.ano || undefined;

        aplicacao = {
          ...aplicacao,
          publico_alvo: target,
          data_inicio: isDate(aplicacao.data_inicio)
            ? format(aplicacao.data_inicio, 'yyyy-MM-dd HH:mm')
            : aplicacao.data_inicio,
          data_fim: isDate(aplicacao.data_fim) ? format(aplicacao.data_fim, 'yyyy-MM-dd HH:mm') : aplicacao.data_fim,
          data_resultado: isDate(aplicacao.data_resultado)
            ? format(aplicacao.data_resultado, 'yyyy-MM-dd HH:mm')
            : aplicacao.data_resultado,
          entidades: undefined,
        };

        if (data.state.aplicacao.uuid) {
          return this._assessmentApplicationService
            .update(data.state.aplicacao.uuid, { aplicacao, avaliacao, questoes, entidade })
            .pipe(this.afterUpdateAvaliacao(data));
        }
        return this._assessmentApplicationService
          .store({ aplicacao, avaliacao, entidade, questoes })
          .pipe(this.afterUpdateAvaliacao(data));
      }),
      map((value) => ModalNewAssessmentActions.UpdateStateReducer({ value })),
    ),
  );

  constructor(
    private actions$: Actions,
    private store: Store<ModalNewAssessmentState>,
    private _assessmentApplicationService: AssessmentApplicationService,
    private _assessmentService: AssessmentService,
    private notificationService: NotificationService,
    private _translate: TranslateService,
    private _storeApp: Store<AppState>,
  ) {}

  private afterUpdateAvaliacao = (data: any): OperatorFunction<Resp<any>, any> => {
    const safeActions = { ...EMPTY_ACTIONS, ...data.actions };
    return (observable) =>
      observable.pipe(
        tap({
          next: () => {
            safeActions.success();
            this.notificationService.success(this._translate.instant('gestor-avaliacoes.avaliacao-salva-sucesso'));
          },
          error: () => {
            safeActions.error();
            this.notificationService.error(this._translate.instant('gestor-avaliacoes.avaliacao-erro-salvar'));
          },
          complete: () => safeActions.finalize(),
        }),
        map((res) => ({
          disabled: false,
          hasChanges: false,
          aplicacao: { uuid: res.data.aplicacao_uuid },
          avaliacao: { uuid: res.data.avaliacao_uuid, code: res.data.avaliacao_code },
        })),
      );
  };

  private clearFields(data, user) {
    return {
      ...data,
      uuid: undefined,
      data_inicio: undefined,
      data_fim: undefined,
      data_resultado: undefined,
      tentativa_count: undefined,
      publico_alvo: [],
      status: 0,
      encerrada: 0,
      coautores: [],
      avaliacao: {
        ...data.avaliacao,
        parent_uuid: data.avaliacao.uuid,
        code: undefined,
        uuid: undefined,
        compartilhada: 0,
        entidade_permissao: undefined,
        questoes: data.avaliacao.questoes.map((q) =>
          this.isMyQuestion(q, user)
            ? q
            : {
                ...q,
                parent_uuid: q.uuid,
                uuid: undefined,
                code: undefined,
                criado_por: undefined,
                pais_id: user?.pais_id,
                questoes_pais: undefined,
                alternativas: q.alternativas.map((a) => ({ ...a, uuid: undefined })),
                resolucao: q.resolucao ? { ...q.resolucao, id: undefined } : q.resolucao,
              },
        ),
      },
    };
  }

  private isMyQuestion(question: any, user: any): boolean {
    if (!!user.entidade_id) {
      return question.criado_por === user.usuario_id;
    }

    const options = {
      [1]: () => 'entidade_id',
      [2]: () => 'campo_id',
      [3]: () => 'uniao_id',
      [4]: () => 'editora_id',
      [5]: () => 'divisao_id',
    };
    const entityActive = first(selectedEntityActive(user));
    return question.entidade_permissao?.some(
      (permission) => permission[options[entityActive.tipo]()] === entityActive.id,
    );
  }
}
