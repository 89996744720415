<ng-container *ngIf="{ calendar: calendar$ | async, date: date$ | async } as data">
  <div class="flex h-full flex-col">
    <div class="mb-4 flex items-center justify-between">
      <svg-icon
        app-icon-button
        (click)="prevMonth(data.date, data.calendar?.entityId)"
        class="block h-9 w-9 text-base-gray-500"
        src="assets/icons/icon-arrow-left.svg"
      ></svg-icon>
      <div class="flex gap-2">
        <app-skeleton *ngIf="loading$ | async" class="!w-fit" [showLabel]="false"></app-skeleton>
        <h1 class="text-lg font-medium capitalize">{{ data.date | date : 'MMMM' }}</h1>
      </div>
      <svg-icon
        app-icon-button
        (click)="nextMonth(data.date, data.calendar?.entityId)"
        class="block h-9 w-9 text-base-gray-500"
        src="assets/icons/icon-arrow-right.svg"
      ></svg-icon>
    </div>
    <div class="h-full">
      <ol class="grid h-full grid-cols-5 place-items-center gap-1">
        <li *ngFor="let day of week" class="text-center text-base font-medium capitalize text-base-gray-400">
          {{ 'geral.dias.' + day | translate }}
        </li>
        <div *ngFor="let blank of blankDays$ | async">&nbsp;</div>
        <li
          (click)="onChangeDay(day)"
          app-flat-button
          *ngFor="let day of days$ | async"
          class="relative flex h-10 w-10 items-center justify-center rounded-md text-center shadow-none"
          [ngClass]="classStatus(day, data?.date, data?.calendar?.dailies)"
        >
          <span class="text-lg font-medium 2lg:text-md">{{ day.getDate() }}</span>
        </li>
      </ol>
    </div>
  </div>
</ng-container>
