import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CrudService } from './crud.service';
import { ApiService, Resp } from './api.service';
import { portalConst } from '@app/config';

@Injectable({
  providedIn: 'root',
})
export class AssessmentTrailApplicationService extends CrudService<any> {
  private _baseUrl = `${portalConst.api.assessments}/v1/trilha`;

  constructor(api: ApiService) {
    super(api, `${portalConst.api.assessments}/v1/trilha`);
  }

  public generalData(aplicacaoUuid: string): Observable<Resp<any>> {
    return this.api.get(`${this._baseUrl}/aplicacao/${aplicacaoUuid}`);
  }

  public start(aplicacaoUuid: string): Observable<Resp<any>> {
    return this.api.post(`${this._baseUrl}/aplicacao/${aplicacaoUuid}`);
  }

  public vitrine(aplicacaoUuid: string): Observable<Resp<any>> {
    return this.api.get(`${this._baseUrl}/aplicacao-vitrine/${aplicacaoUuid}`);
  }

  public answerQuestion(questionUuid: string, params: any): Observable<Resp<any>> {
    return this.api.post(`${this._baseUrl}/aplicacao-resposta/${questionUuid}`, {}, params);
  }

  public finalize(tentativaUuid: string, body: any): Observable<Resp<any>> {
    return this.api.post(`${this._baseUrl}/tentativa-finalizacao/${tentativaUuid}`, body);
  }
}
