<div
  *ngIf="showCertificadoDefault || !!this.form.value.file; else uploadFile"
  #containerCertificado
  class="boxCertificadoPreview"
  [style]="'background-image:' + backgroundImage()"
>
  <p class="certificadoTexto !pt-0" [style.font-size.px]="containerCertificado.offsetWidth * 0.02">
    {{ data?.text }}
  </p>
  <div class="container-fluid w-full">
    <div class="flex w-full flex-wrap justify-center gap-[12.5%] text-center">
      <div class="w-1/4 text-center" *ngFor="let item of assinaturas">
        <div class="boxImgAssinatura">
          <img *ngIf="!!item.assinatura" src="{{ item.assinatura }}" alt="img_assinatura" />
        </div>
        <div
          class="linhaAssinatura"
          [style.font-size.px]="containerCertificado.offsetWidth * 0.018"
          [style.line-height.px]="containerCertificado.offsetWidth * 0.018"
        >
          <p
            class="mb-[5px] font-semibold"
            [style.font-size.px]="containerCertificado.offsetWidth * 0.018"
            [style.line-height.px]="containerCertificado.offsetWidth * 0.018"
          >
            <ng-container *ngIf="!!item.nome">
              {{ item.nome }}
            </ng-container>
          </p>
          <small *ngIf="!!item.linha_1" style="width: 100%; display: block">
            {{ item.linha_1 }}
          </small>
          <small *ngIf="!!item.linha_2" style="width: 100%; display: block">
            {{ item.linha_2 }}
          </small>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #uploadFile>
  <app-upload-file
    (statuschange)="onUpload($event)"
    [config]="config"
    [image]="form.value.file"
    required
    [titulo]="'trilhas.dropzone.imagem' | translate"
    [class]="'h-[36rem]'"
  >
  </app-upload-file>
</ng-template>
