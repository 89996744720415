import { Directive } from '@angular/core';

@Directive({
  selector: '[app-button]',
  host: {
    class:
      'relative inline-flex h-min cursor-pointer select-none items-center justify-center rounded-lg border-none p-2 outline-none hover:brightness-110 active:brightness-85 disabled:pointer-events-none disabled:cursor-not-allowed disabled:opacity-75 disabled:brightness-100',
  },
})
export class ButtonDirective {}
