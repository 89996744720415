import { ChangeDetectionStrategy, Component, DestroyRef, EventEmitter, Output } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl } from '@angular/forms';
import { ItemGroup } from '@app/core/models/academic-tree';
import { EclassFilter, EclassFilterService, EclassResult } from '@app/core/services';
import { helperItemGroup, sanitize } from '@app/shared/utils';
import { first, flattenDeep, groupBy, isEmpty, pick, size, sortBy, uniqBy } from 'lodash';
import { Observable, Subject, combineLatest } from 'rxjs';
import { debounceTime, map, tap } from 'rxjs/operators';
import { FilterBadgeData } from '../filter-badge/filter-badge.component';

const EMPTY_DATA = { levels: [], levelId: null, labels: [], filter: null };

interface AcademicTreeItem {
  id: number;
  name: string;
  groupKey: number;
  levelId: number;
  type: ItemGroup;
  query: any;
}
@Component({
  selector: 'app-filter-subject',
  templateUrl: './filter-subject.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterSubjectComponent {
  public academicTree$: Observable<any>;
  public loading$: Observable<boolean>;
  public levelCtrl = new FormControl();
  @Output() public toogle = new EventEmitter();
  private _labels$ = new Subject<number[]>();

  constructor(private _destroy: DestroyRef, private _eclassFilterService: EclassFilterService) {
    this.loading$ = this._eclassFilterService.loading$.pipe(takeUntilDestroyed(this._destroy));
    this.loadAcademicTree();
  }

  public onFilter(labelsIds: number[]) {
    this._labels$.next(labelsIds);
  }

  public onUpdate(data: any) {
    this._eclassFilterService.update(data);
    this.onToogle();
  }

  public label(key: any, labels: any[]): string {
    return labels.find(({ id }) => id === Number(key))?.name;
  }

  public isActive(query: any, filter: any): boolean {
    const { groupId, subjectId } = filter;
    return Number(query.groupId) === Number(groupId) && Number(query.subjectId) === Number(subjectId);
  }

  public isExpanded(item: any, filter: any): boolean {
    return item?.value.some(({ query }) => this.isActive(query, filter));
  }

  public isShow(data: any[]): boolean {
    return size(data) > 1;
  }

  public onToogle() {
    this.toogle.emit(true);
  }

  public pinColor(query: any, counters: any[]): string {
    const item = counters.find(
      ({ group_id, subject_id }) => group_id === query.groupId && subject_id === query.subjectId,
    );
    if (item.has_daily && item.has_activity) {
      return 'text-base-post-diary-light';
    }
    if (!item.has_daily && item.has_activity) {
      return 'text-base-post-task-light';
    }
    if (item.has_daily && !item.has_activity) {
      return 'text-base-post-plan-light';
    }
    return 'text-gray-400';
  }

  private loadAcademicTree() {
    const data$ = combineLatest([this._eclassFilterService.filter$, this._eclassFilterService.academicTree$]).pipe(
      takeUntilDestroyed(this._destroy),
      debounceTime(100),
      map(([filter, academicTree]) => {
        if (!!filter && !!academicTree) {
          const levelId = this.loadLevel(filter, academicTree);
          return {
            ...academicTree,
            levels: academicTree.levels,
            labels: uniqBy(this.buildLabels(academicTree, levelId), 'id'),
            filter,
            levelId,
          };
        }
        return EMPTY_DATA;
      }),
      tap(({ filter, levels, levelId, labels }) => {
        if (!isEmpty(filter)) {
          this._labels$.next(labels.filter(({ active }) => active).map(({ id }) => id));
          this.levelCtrl.setValue(levelId || first(levels)?.id);
        } else {
          this._labels$.next([]);
          this.levelCtrl.setValue(null);
        }
      }),
    );

    const academicTree$ = combineLatest([data$, this.levelCtrl.valueChanges]).pipe(
      takeUntilDestroyed(this._destroy),
      map(([data, levelId]) => {
        if (!!data?.filter) {
          const labels = sortBy(uniqBy(this.buildLabels(data as any, levelId), 'id'), 'name');
          this._labels$.next(labels.filter(({ active }) => active).map(({ id }) => id));
          return { ...data, labels, levelId };
        }
        return data;
      }),
    );

    this.academicTree$ = combineLatest([academicTree$, this._labels$]).pipe(
      takeUntilDestroyed(this._destroy),
      debounceTime(100),
      map(([academicTree, labelIds]) => {
        if (!isEmpty(academicTree?.filter)) {
          const items = this.buildItems(academicTree as any, academicTree.levelId).map((item) => ({
            ...item,
            sortKey: sanitize(item.name),
          }));
          const groupedItems = groupBy(sortBy(items, 'sortKey'), 'groupKey');
          const sanitizedItems = Object.entries(groupedItems)
            .filter(([key]) => labelIds.includes(Number(key)))
            .map(([key, value]) => {
              const label = this.label(key, academicTree.labels);
              return { label, sortKey: sanitize(label), value };
            });
          return { ...academicTree, items: sortBy(sanitizedItems, 'sortKey') };
        }
        return {};
      }),
    );
  }

  private buildItems(academicTree: EclassResult, levelId: number): AcademicTreeItem[] {
    const groups = academicTree.groups.filter(this.filterLevel(levelId));
    const subjects = academicTree.subjects.filter(this.filterLevel(levelId));
    if (helperItemGroup(academicTree) === ItemGroup.Group) {
      return flattenDeep(groups.map(this.helperGroup(subjects, academicTree)));
    }
    return subjects.map(this.helperSubject(groups, academicTree));
  }

  private filterLevel(levelId: number): any {
    return ({ level_id }) => level_id === levelId;
  }

  private helperGroup(subjects: any[], academicTree: EclassResult): (_: any) => AcademicTreeItem[] {
    return (group) =>
      subjects
        .filter(({ group_id }) => group_id === group.id)
        .map((subject) => ({
          id: group.id,
          name: `${group.grade.description} (${group.description})`,
          groupKey: subject.id,
          levelId: group.level_id,
          type: ItemGroup.Group,
          pinColor: this.pinColor({ groupId: group.id, subjectId: subject.id }, academicTree.counters),
          query: { groupId: group.id, subjectId: subject.id },
        }));
  }

  private helperSubject(groups: any[], academicTree: EclassResult): (_: any) => AcademicTreeItem {
    return (subject) => ({
      id: subject.id,
      name: subject.description,
      groupKey: subject.group_id,
      levelId: subject.level_id,
      type: ItemGroup.Subject,
      pinColor: this.pinColor({ groupId: subject.group_id, subjectId: subject.id }, academicTree.counters),
      query: { groupId: subject.group_id, subjectId: subject.id },
    });
  }

  private buildLabels(academicTree: EclassResult, levelId: number): FilterBadgeData[] {
    if (helperItemGroup(academicTree) === ItemGroup.Group) {
      return academicTree.subjects.filter(this.filterLevel(levelId)).map((subject) => ({
        id: subject.id,
        name: subject.description,
        levelId: subject.level_id,
        active: true,
      }));
    }
    return academicTree.groups.filter(this.filterLevel(levelId)).map((group) => ({
      id: group.id,
      name: `${group.grade.description} (${group.description})`,
      levelId: group.level_id,
      active: true,
    }));
  }

  private loadLevel(filter: EclassFilter, academicTree: EclassResult): number {
    if (filter.groupId && filter.subjectId) {
      return academicTree.subjects.find(({ id, group_id }) => {
        const data = { groupId: group_id, subjectId: id };
        return this.isActive(data, filter);
      })?.level_id;
    }
    return null;
  }
}
