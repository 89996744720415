import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, NavigationEnd, Router } from '@angular/router';
import { isEqual } from 'lodash';
import { distinctUntilChanged, filter, map } from 'rxjs';
import COLORS from 'tailwind/colors/modules.json';

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  constructor(private router: Router) {
    this.router.events
      .pipe(
        distinctUntilChanged(isEqual),
        filter((event) => event instanceof NavigationEnd),
        map(() => this.router.routerState.snapshot.root),
        map(this.theme),
        filter((theme) => !!theme),
      )
      .subscribe(this.changeTheme);
  }

  public changeTheme = (theme: string) => {
    const root = document.documentElement;
    const colorsTheme = COLORS as any;
    root.style.setProperty('--color-theme-50', colorsTheme[theme]['50']);
    root.style.setProperty('--color-theme-100', colorsTheme[theme]['100']);
    root.style.setProperty('--color-theme-200', colorsTheme[theme]['200']);
    root.style.setProperty('--color-theme-300', colorsTheme[theme]['300']);
    root.style.setProperty('--color-theme-400', colorsTheme[theme]['400']);
    root.style.setProperty('--color-theme-500', colorsTheme[theme]['500']);
    root.style.setProperty('--color-theme-600', colorsTheme[theme]['600']);
    root.style.setProperty('--color-theme-700', colorsTheme[theme]['700']);
    root.style.setProperty('--color-theme-800', colorsTheme[theme]['800']);
    root.style.setProperty('--color-theme-900', colorsTheme[theme]['900']);
    root.style.setProperty('--color-theme-950', colorsTheme[theme]['950']);
    root.style.setProperty('--color-theme-light', colorsTheme[theme]['light']);
    root.style.setProperty('--color-theme-lighter', colorsTheme[theme]['lighter']);
    root.style.setProperty('--color-theme-dark', colorsTheme[theme]['dark']);
    root.style.setProperty('--color-theme-darker', colorsTheme[theme]['darker']);
    root.style.setProperty('--color-theme-contrast', colorsTheme[theme]['contrast']);
  };

  private theme = (route: ActivatedRouteSnapshot): string => {
    if (!route.firstChild) {
      return route?.routeConfig?.data?.theme;
    }
    return route?.routeConfig?.data?.theme || this.theme(route.firstChild);
  };
}
