import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { portalConst } from '@app/config';

@Injectable({ providedIn: 'root' })
export class PoliciesService {
  private url = portalConst.api.permissao;

  constructor(private api: ApiService) {}

  public get(req: any): Observable<any> {
    return this.api.get<any>(`${this.url}/permissoes`, req);
  }
}
