<ng-container *ngIf="{ entities: entities$ | async, load: loading$ | async, grades: notesGrouped$ | async } as data">
  <div class="grid h-auto !w-full gap-4 border-t-2 border-t-base-mantis-500 p-5">
    <div class="grid w-full md:flex md:justify-end md:gap-5">
      <app-form-field *ngIf="data?.entities.length > 1" class="flex w-full">
        <label app-label translate> gestor-avaliacoes.unidades-escolares </label>
        <ng-select
          app-select
          [items]="data.entities"
          [formControl]="entityIdCtrl"
          bindLabel="nome"
          bindValue="id"
          [clearable]="true"
          [placeholder]="'eclass.selecione' | translate"
          [notFoundText]="'geral.nao-encontrado' | translate"
          [multiple]="false"
        >
        </ng-select>
      </app-form-field>

      <app-form-field class="w-full">
        <label app-label translate> gestor-avaliacoes.pesquisar-turma </label>
        <ng-select
          app-select
          [formControl]="searchTurmaCtrl"
          [items]="singleClasses"
          [placeholder]="'geral.selecione' | translate"
          [clearable]="true"
          [loading]="!!data?.load"
        >
          <ng-option [value]="null">Todas</ng-option>
        </ng-select>
      </app-form-field>

      <app-form-field class="w-full">
        <label app-label translate> gestor-avaliacoes.pesquisar-nome </label>
        <input app-input [placeholder]="'geral.pesquisar' | translate" (input)="onSearchChange($event.target.value)" />
      </app-form-field>
    </div>

    <div class="mt-4 flex w-full justify-between">
      <button
        app-flat-button
        type="button"
        (click)="onExport()"
        class="mr-3 border-theme bg-white"
        [disabled]="!data?.grades.length"
      >
        <svg-icon class="flex h-6 w-7 text-green-500" src="assets/icons/icon-download.svg"></svg-icon>
        <span class="ml-3 hidden md:flex" translate> gestor-avaliacoes.exportacao </span>
      </button>
      <div *ngIf="!!data.grades.length && !data.load" class="flex items-center gap-2">
        <span translate="">Notas</span>
        <app-switch [formControl]="switchNotes" colorTrue="bg-theme-500" colorFalse="bg-theme-500"></app-switch>
        <span translate="">Acertos</span>
      </div>
    </div>

    <app-card class="mt-5 !h-[200px] p-5" *ngIf="!!data.load">
      <app-skeleton class="w-full"></app-skeleton>
    </app-card>

    <ng-container *ngIf="!data?.load">
      <app-card *ngIf="data?.grades | isEmpty; else dataTemplate">
        <div app-card-content class="!h-[200px]">
          <app-jumbotron>
            <span *ngIf="data?.entities?.length > 1 && !entityIdCtrl.value" translate>
              gestor-avaliacoes.selecione-unidade
            </span>

            <span *ngIf="entityIdCtrl.value || data?.entities?.length === 1" translate>
              gestor-avaliacoes.nao-encontrado
            </span>
          </app-jumbotron>
        </div>
      </app-card>
      <ng-template #dataTemplate>
        <table app-table>
          <thead>
            <tr>
              <th app-table-cell class="text-left text-md font-semibold" translate>gestor-avaliacoes.alunos</th>
              <th app-table-cell class="text-center text-md font-semibold" translate>gestor-avaliacoes.ra</th>
              <th app-table-cell *ngFor="let discipline of uniqueDisciplines">
                <div class="flex justify-center gap-1">
                  <span class="text-md font-semibold">{{ discipline?.nome }}</span>
                  <span class="text-md font-semibold" *ngIf="!!switchNotes.value">({{ discipline.qtd_questoes }})</span>
                </div>
              </th>
              <th app-table-cell>
                <div class="flex justify-center">
                  <span class="text-md font-semibold" translate>gestor-avaliacoes.total-notas</span>
                  <span class="ml-1 text-md font-semibold" *ngIf="!!switchNotes.value"
                    >({{ uniqueDisciplines[0]?.qtd_questoes_geral }})</span
                  >
                </div>
              </th>
            </tr>
          </thead>

          <tbody app-table-body>
            <tr *ngFor="let student of data?.grades">
              <td app-table-cell class="grid font-semibold">
                {{ student.nome }}
                <span class="text-sm"> {{ student.turma }} </span>
              </td>
              <td app-table-cell class="text-center font-semibold">
                {{ student.ra }}
              </td>
              <td app-table-cell *ngFor="let discipline of uniqueDisciplines" class="text-center">
                <div class="flex justify-center">
                  <span>
                    {{
                      !!switchNotes.value
                        ? (student.disciplinas[discipline.nome]?.percentual | number : '1.2-2') || '-'
                        : (student.disciplinas[discipline.nome]?.notas?.join(', ') | number : '1.2-2') || '-'
                    }}
                  </span>
                  <span
                    *ngIf="
                      (student.pesoTipo === assessmentWeightType.Porcentagem &&
                        !!student.disciplinas[discipline.nome]?.notas) ||
                      (!!switchNotes.value && !!student.disciplinas[discipline.nome]?.percentual)
                    "
                    >%</span
                  >
                  <span class="ml-1" *ngIf="!!switchNotes.value && !!student.disciplinas[discipline.nome]?.percentual"
                    >({{ student.disciplinas[discipline.nome]?.qtd_acertos }}/{{
                      student.disciplinas[discipline.nome]?.qtd_questoes
                    }})</span
                  >
                </div>
              </td>

              <td app-table-cell class="text-center font-semibold">
                <div class="flex justify-center">
                  <span class="font-semibold">
                    {{
                      !!switchNotes.value
                        ? (student?.percentual_geral | number : '1.2-2')
                        : (student.totalNotas | number : '1.2-2')
                    }}
                  </span>
                  <span
                    class="font-semibold"
                    *ngIf="student.pesoTipo === assessmentWeightType.Porcentagem || !!switchNotes.value"
                    >%</span
                  >
                  <span class="ml-1 font-semibold" *ngIf="!!switchNotes.value">
                    ({{ student?.total_acertos_geral }}/{{ uniqueDisciplines[0]?.qtd_questoes_geral }})
                  </span>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </ng-template>
    </ng-container>
  </div>
</ng-container>
