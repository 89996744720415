import { DIALOG_DATA } from '@angular/cdk/dialog';
import { Component, DestroyRef, Inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AssessmentApplicationService } from '@app/core';
import { notNull, safeEmpty } from '@app/shared';
import { Observable, tap } from 'rxjs';

@Component({
  selector: 'app-modal-assessments-report',
  templateUrl: './modal-assessments-report.component.html',
})
export class ModalAssessmentsReportComponent {
  public application$: Observable<any>;

  constructor(
    @Inject(DIALOG_DATA) public data: string,
    private _gdaReportService: AssessmentApplicationService,
    private _destroyRef: DestroyRef,
  ) {
    this.application$ = this._gdaReportService
      .getGeneralData(data)
      .pipe(takeUntilDestroyed(this._destroyRef), safeEmpty(), notNull());
  }
}
