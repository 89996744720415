import { BreakpointObserver } from '@angular/cdk/layout';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  InjectionToken,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { notNull } from '@app/shared/utils';
import { BehaviorSubject, Observable, Subject, combineLatest, delay, map, takeUntil } from 'rxjs';
import {
  RESPONSIVE_BREAKPOINT,
  SIDENAV_END,
  SIDENAV_START,
  STANDARD_SIDENAV,
  SidenavData,
  SidenavDirection,
  SidenavDirective,
  SidenavRef,
  SidenavStatus,
} from './sidenav';
import { SidenavOuterDirective } from './sidenav-outer.directive';
import { SidenavComponent } from './sidenav.component';
import { SidenavService } from './sidenav.service';

export const SIDENAV_GROUP = new InjectionToken<SidenavGroupComponent>('SidenavGroup');

@Component({
  selector: 'app-sidenav-group',
  templateUrl: './sidenav-group.component.html',
  providers: [
    SidenavService,
    { provide: SIDENAV_GROUP, useExisting: SidenavGroupComponent },
    { provide: SIDENAV_START, useFactory: (service: SidenavService) => service.start$, deps: [SidenavService] },
    { provide: SIDENAV_END, useFactory: (service: SidenavService) => service.end$, deps: [SidenavService] },
  ],
  host: {
    class: 'relative flex w-full h-full',
  },
})
export class SidenavGroupComponent implements AfterViewInit, OnDestroy {
  @ViewChild('start', { static: false }) public start: SidenavComponent;
  @ViewChild('end', { static: false }) public end: SidenavComponent;
  @ViewChild('content', { static: true }) private contentRef: ElementRef<HTMLElement>;
  @Output() public attached = new EventEmitter<SidenavGroupComponent>();
  public showBackground$: Observable<boolean>;
  public start$ = new BehaviorSubject<SidenavDirective>(null);
  public end$ = new BehaviorSubject<SidenavDirective>(null);
  public content$ = new BehaviorSubject<SidenavOuterDirective>(null);
  private _showBackground$ = new BehaviorSubject<any>(false);
  private _destroy$ = new Subject<void>();

  constructor(private _sidenavService: SidenavService, private _breakpointObserver: BreakpointObserver) {
    this.backgroundListeners();
  }

  public ngAfterViewInit() {
    this.attached.emit(this);
  }

  public ngOnDestroy() {
    this._destroy$.next();
    this.end$.complete();
    this.start$.complete();
    this.content$.complete();
    this._destroy$.complete();
    this._showBackground$.complete();
  }

  public setStart(sidenav: SidenavDirective) {
    this.start$.next(sidenav);
  }

  public registerStart = (root: SidenavRef) => {
    this._sidenavService.registerStart({ ...root, open: this.sideStart } as any);
  };

  public sideStart = (data?: SidenavData) => {
    if (data) {
      const safe: any = {
        ...STANDARD_SIDENAV,
        ...data,
        direction: SidenavDirection.Start,
        register: this.registerStart,
      };
      this.setStart(safe);
    } else {
      this.start?.open$.next(true);
    }
  };

  public destroyStart() {
    this.start$.next(null);
    this.onLeftMargin('0');
  }

  public setEnd(sidenav: SidenavDirective) {
    this.end$.next(sidenav);
  }

  public registerEnd = (root: SidenavRef) => {
    this._sidenavService.registerEnd({ ...root, open: this.sideEnd } as any);
  };

  public sideEnd = (data?: SidenavData) => {
    if (data) {
      const safe: any = {
        ...STANDARD_SIDENAV,
        ...data,
        direction: SidenavDirection.End,
        register: this.registerEnd,
      };
      this.setEnd(safe);
    } else {
      this.end?.open$.next(true);
    }
  };

  public destroyEnd() {
    this.end$.next(null);
    this.onRightMargin('0');
  }

  public setContent(content: SidenavOuterDirective) {
    this.content$.next(content);
  }

  public removeContent() {
    this.content$.next(null);
  }

  public onClose() {
    if (this.start$?.value?.outerClick) {
      this.start.close();
    }
    if (this.end$?.value?.outerClick) {
      this.end.close();
    }
  }

  public onLeftMargin(size: string) {
    if (this.contentRef) {
      this.contentRef.nativeElement.style.marginLeft = size;
    }
  }

  public onRightMargin(size: string) {
    if (this.contentRef) {
      this.contentRef.nativeElement.style.marginRight = size;
    }
  }

  public onStatus({ open, config }: SidenavStatus, root: SidenavComponent) {
    config.register(root);
    const value = this._showBackground$.value;
    this._showBackground$.next({
      ...value,
      [config.direction]: { status: open, showBackground: config.showBackground },
    });
  }

  private backgroundListeners() {
    const showBackground$ = this._showBackground$.asObservable().pipe(
      notNull(),
      map(Object.values),
      map((sidenav) => sidenav.find(({ status }) => status)),
    );
    const small$ = this._breakpointObserver.observe(RESPONSIVE_BREAKPOINT).pipe(map(({ matches }) => matches));

    this.showBackground$ = combineLatest([showBackground$, small$]).pipe(
      delay(200),
      takeUntil(this._destroy$),
      map(([background, small]) => {
        if (!!background && !!small) {
          return true;
        }
        return background?.showBackground || false;
      }),
    );
  }
}
