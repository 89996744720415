import { AfterViewInit, ChangeDetectionStrategy, Component, DestroyRef, OnInit, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute, Router } from '@angular/router';
import { CalendarComponent } from '@app/shared/modules/calendar/calendar.component';
import { Checker } from '@app/shared/utils';
import { notNull } from '@app/shared/utils/operators';
import { AppSelectors } from '@app/store';
import { Store } from '@ngrx/store';
import { format } from 'date-fns';
import { Observable, map } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-card-calendar',
  templateUrl: './card-calendar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardCalendarComponent implements OnInit, AfterViewInit {
  public calendar$: Observable<any>;
  public isEmployee$: Observable<boolean>;
  @ViewChild(CalendarComponent, { static: false }) private _calendar: CalendarComponent;

  constructor(
    private _destroy: DestroyRef,
    private _router: Router,
    private _store: Store,
    private _activatedRoute: ActivatedRoute,
  ) {
    this.isEmployee$ = this._store.select(AppSelectors.DefaultFunction).pipe(map(Checker.isEmployee));
  }

  public onSelectDate(date: Date, employee: boolean) {
    if (employee) {
      this._router.navigate(['/eclass', format(date, 'yyyy-MM-dd'), 'listagem'], { relativeTo: this._activatedRoute });
    } else {
      this._router.navigate(['/eclass', format(date, 'yyyy-MM-dd'), 'resumo'], { relativeTo: this._activatedRoute });
    }
  }

  public ngOnInit() {
    this.calendar$ = this._store.select(AppSelectors.ActiveUser).pipe(
      takeUntilDestroyed(this._destroy),
      notNull(),
      map(({ entidade_id }) => ({ date: new Date(), entityId: entidade_id })),
    );
  }

  public ngAfterViewInit() {
    this._store
      .select(AppSelectors.ActiveUser)
      .pipe(takeUntilDestroyed(this._destroy), debounceTime(300))
      .subscribe(() => this._calendar?.onRefresh());
  }
}
