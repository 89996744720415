import { Injectable, OnDestroy } from '@angular/core';
import { LearningTrailsService } from '@app/core';
import { compareLike, selectedEntityActive } from '@app/shared';
import { AppSelectors } from '@app/store';
import { AppState } from '@app/store/app.state';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, Subject, filter, map, takeUntil } from 'rxjs';
import { Category } from '../models';

@Injectable({
  providedIn: 'root',
})
export class StudioFilterService implements OnDestroy {
  public categories$ = new BehaviorSubject<Category[]>(undefined);
  public allFilteredCategories$ = new BehaviorSubject(true);
  public countAllCourses$ = new BehaviorSubject<number>(undefined);
  public search = false;
  private destroy$ = new Subject<void>();

  private _collection$ = new BehaviorSubject<any>(undefined);
  private _subdivision$ = new BehaviorSubject<any>(undefined);
  private _items$ = new BehaviorSubject<any>(undefined);

  public get collection$(): Observable<any> {
    return this._collection$.asObservable();
  }

  public get subdivision$(): Observable<any> {
    return this._subdivision$.asObservable();
  }

  public get items$(): Observable<any> {
    return this._items$.asObservable();
  }

  constructor(private learningTrailsService: LearningTrailsService, private store: Store<AppState>) {}

  public ngOnDestroy(): void {
    this.categories$.next(undefined);
    this.categories$.complete();
    this.allFilteredCategories$.next(undefined);
    this.allFilteredCategories$.complete();
    this.countAllCourses$.next(undefined);
    this.countAllCourses$.complete();
    this.destroy$.complete();
  }

  public searchCourseInCategory(title: string): void {
    this.categories$.next(
      this.categories$.value
        .map((category) => ({
          ...category,
          colecoes: category.colecoes
            .map((collection) => ({
              ...collection,
              cursos: collection.cursos.map((course) => ({
                ...course,
                filtered: !!title && compareLike(course.titulo, title),
              })),
            }))
            .map((collection) => ({
              ...collection,
              hasCourseFiltered: collection.cursos.some((course) => course.filtered),
            })),
        }))
        .map((category) => ({
          ...category,
          hasCourseFiltered: category.colecoes.some((collection) => collection.hasCourseFiltered),
        })),
    );

    if (!!title) {
      this.search = true;
    } else {
      this.search = false;
    }
  }

  public showAllCategories(): void {
    this.allFilteredCategories$.next(true);
    this.categories$.next(
      this.categories$.value.map((category) => ({
        ...category,
        filtered: false,
      })),
    );
  }

  public filterByCategory(category: Category): void {
    category.filtered = !category.filtered;
    if (
      this.categories$.value.every((category) => !category.filtered) ||
      this.categories$.value.every((category) => category.filtered)
    ) {
      this.allFilteredCategories$.next(true);
    } else {
      this.allFilteredCategories$.next(false);
    }
  }

  public initStudio(): void {
    this.store
      .select(AppSelectors.ActiveUser)
      .pipe(
        takeUntil(this.destroy$),
        filter((user) => !!user),
      )
      .subscribe((user) => {
        this.learningTrailsService
          .studio({ entidade: selectedEntityActive(user)[0] })
          .pipe(
            map(({ data }) =>
              data.map((category) => ({
                ...category,
                numberCourses: category.colecoes.reduce(
                  (accumulator, collection) => accumulator + collection.cursos.length,
                  0,
                ),
                filtered: false,
                hasCourseFiltered: false,
              })),
            ),
          )
          .subscribe((data) => {
            this.categories$.next(data);
            this.countCourses();
          });
      });
  }

  private countCourses(): void {
    this.countAllCourses$.next(
      this.categories$.value.reduce(
        (accumulator, category) =>
          accumulator +
          category.colecoes.reduce((accumulator, collection) => accumulator + collection.cursos.length, 0),
        0,
      ),
    );
  }

  public passCourse(course: any, collection: any) {
    const existingCourseIndex = collection.cursos.findIndex((existingCourse) => existingCourse.id === course.id);
    const newCourses =
      existingCourseIndex !== -1
        ? [
            ...collection.cursos.slice(0, existingCourseIndex),
            course,
            ...collection.cursos.slice(existingCourseIndex + 1),
          ]
        : [...collection.cursos, course];

    const newCollection = { ...collection, cursos: newCourses };
    this._collection$.next(newCollection);
  }

  public removeCourse(course_id: number, collection: any) {
    const newCollection = { ...collection, cursos: collection.cursos.filter((course) => course.id !== course_id) };
    this._collection$.next(newCollection);
  }

  public removeItem(deletedItemId: number, newItems: any[], items: any[]): any[] {
    for (let i = 0; i < items.length; i++) {
      if (items[i].id === deletedItemId) {
        items.splice(i, 1);
        return this.reorder(newItems, items);
      } else if (items[i].items && items[i].items.length > 0) {
        items[i].items = this.removeItem(deletedItemId, newItems, items[i].items);
      }
    }
    return items;
  }

  private reorder(newItems: any[], items: any[]): any[] {
    newItems.forEach((i) => {
      const index = items.findIndex((j) => j.id === i.id);
      items[index] = { ...items[index], ordem: i.ordem };
    });

    return items;
  }

  public passSubdivision(subdivision: any) {
    this._subdivision$.next(subdivision);
  }

  public passItems(items: any) {
    this._items$.next(items);
  }
}
