import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  SkipSelf,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { TabContentDirective } from './tab-content.directive';
import { TABGROUP, TabGroupComponent } from './tab-group.component';

@Component({
  selector: 'app-tab',
  templateUrl: './tab.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabComponent implements OnInit, OnDestroy {
  private static nextId = 0;
  @Input() public id: string | number = TabComponent.nextId++;
  @ViewChild('header', { static: true, read: TemplateRef }) public header: TemplateRef<any>;
  @ViewChild('content', { static: true, read: TemplateRef }) public content: TemplateRef<any>;
  @ContentChild(TabContentDirective) public contentRef: TabContentDirective;

  constructor(@Optional() @Inject(TABGROUP) @SkipSelf() private tabGroup: TabGroupComponent) {}

  @Input()
  public set active(value: boolean) {
    if (value) {
      this.tabGroup.setActive(this.id);
    }
  }

  public get active(): boolean {
    return this.tabGroup.isActive(this.id);
  }

  public ngOnInit() {
    this.tabGroup.push(this);
    this.tabGroup.changes();
  }

  public ngOnDestroy() {
    this.tabGroup.remove(this);
    this.tabGroup.changes();
  }
}
