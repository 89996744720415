import { NgModule } from '@angular/core';
import { ModalContentsComponent } from './components/modal-contents/modal-contents.component';
import { ModalFilesComponent } from './components/modal-files/modal-files.component';

import { SharedModule } from '@app/shared';
import { CardCoverSummaryModule } from '@app/shared/modules/card-cover-summary/card-cover-summary.module';
import { PaginationModule } from '@app/shared/modules/pagination/pagination.module';
import { WebsiteService } from './services/website.service';

@NgModule({
  declarations: [ModalContentsComponent, ModalFilesComponent],
  imports: [SharedModule, PaginationModule, CardCoverSummaryModule],
  providers: [WebsiteService],
})
export class SchoolWebsiteModule {}
