import { Injectable } from '@angular/core';
import { EnrollmentService } from '@app/core/services';
import { EnrollmentData } from '@app/modules/finance/utils/enrollment';
import { Policies, notNull } from '@app/shared';
import { AppState } from '@app/store/app.state';
import { TranslateService } from '@ngx-translate/core';
import { isEmpty } from 'lodash';
import { BehaviorSubject, Observable, combineLatest, of } from 'rxjs';
import { catchError, map, shareReplay, switchMap } from 'rxjs/operators';
import { PaymentsService } from './payments.service';

export enum StepGuard {
  FindStudent,
  NotAllow,
  FindSchool,
  Allow,
  Forbidden,
}

@Injectable({ providedIn: 'root' })
export class DataEnrollmentService {
  public enrollment$: Observable<EnrollmentData>;
  private _enrollment$ = new BehaviorSubject<AppState>(null);

  constructor(
    private _paymentsService: PaymentsService,
    private _translateService: TranslateService,
    private _enrollmentService: EnrollmentService,
  ) {
    this.enrollment$ = this._enrollment$.asObservable().pipe(
      notNull(),
      switchMap((store: AppState) => this.load(store)),
      shareReplay(1),
    );
  }

  public start(store: AppState) {
    this._enrollment$.next(store);
  }

  public refresh() {
    this._enrollment$.next(this._enrollment$.value);
  }

  private load(store: AppState): Observable<EnrollmentData> {
    return this._enrollmentService.schoolData().pipe(
      catchError(() => of(null)),
      switchMap((school) => this.loadData(school, store)),
      map(({ user, school, scholarship, data, portal }) => ({
        activeUser: user,
        guard: this.runGuard(user, data),
        isEnrollment: this.isEnrollment(store),
        label: this.enrollmentLabel(store),
        scholarship,
        portal: {
          data: portal,
        },
        iatec: {
          school,
          data,
        },
      })),
    );
  }

  private enrollmentLabel(store: AppState): string {
    const label = this.isEnrollment(store) ? 'matricula' : 'rematricula';
    return this._translateService.instant(`financeiro.${label}`);
  }

  private loadData(school: any, store: AppState): Observable<any> {
    if (store.usuario_ativo?.aluno_id && !isEmpty(school)) {
      return combineLatest({
        user: of(store.usuario_ativo),
        school: of(school),
        scholarship: this._paymentsService.schoolInstallments({ ano: school?.dadosEscola?.ano }).pipe(
          catchError(() => of({ scholarship: null })),
          map((result) => result.scholarship),
        ),
        data: this._enrollmentService.list({ ano: school?.dadosEscola?.ano }).pipe(catchError(() => of(null))),
        portal: this._enrollmentService
          .searchReenrollmentByStudent(store.usuario_ativo.aluno_id, {
            ano: school?.dadosEscola?.ano,
          })
          .pipe(catchError(() => of(null))),
      });
    }
    return of({ user: of(store.usuario_ativo), school: of(school), scholarship: null, data: null, portal: null });
  }

  private runGuard(usuario: any, data: any) {
    if (!usuario.entidade_id) {
      return StepGuard.FindSchool;
    }
    if (!usuario.escola_externa && !usuario.aluno_id) {
      return StepGuard.FindStudent;
    }
    if (data?.dataRematricula.status === 7) {
      return StepGuard.NotAllow;
    }
    if (!data?.dataRematricula.status) {
      return StepGuard.Forbidden;
    }
    return StepGuard.Allow;
  }

  private isEnrollment(store: AppState): boolean {
    return Policies.isAllow({ moduleId: 21, elementoId: 43 }, store.policies);
  }
}
