import { Dialog } from '@angular/cdk/dialog';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AlertComponent } from '../components/alert/alert.component';
import { AlertData, AlertDataType } from '../models';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  constructor(private dialog: Dialog) {}

  public alert(data: AlertData): Observable<any> {
    return this.dialog.open(AlertComponent, { data: { ...data, type: AlertDataType.Warning }, width: '30rem' }).closed;
  }

  public success(data: AlertData): Observable<any> {
    return this.dialog.open(AlertComponent, { data: { ...data, type: AlertDataType.Success }, width: '30rem' }).closed;
  }

  public confirm(data: AlertData): Observable<any> {
    const params = { ...data, type: AlertDataType.Warning, confirm: true };
    return this.dialog.open(AlertComponent, { data: params, width: '30rem' }).closed;
  }
}
