import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { portalConst } from '@app/config';
import { CrudService } from './crud.service';
import { AssessmentQuestion } from '../models';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AssessmentQuestionService extends CrudService<AssessmentQuestion> {
  private readonly _baseUrl = `${portalConst.api.assessments}/v1/`;
  constructor(api: ApiService) {
    super(api, `${portalConst.api.assessments}/v1/questao`);
  }

  public getGraph(applicationUuid: string, questionUuid: string): Observable<any> {
    return this.api.get(`${this._baseUrl}application/${applicationUuid}/analysis-question/${questionUuid}`);
  }

  public feedback(questionUuid: string, feedback: string): Observable<any> {
    return this.api.post(`${this._baseUrl}questao/${questionUuid}/feedback`, { feedback });
  }

  public IAGenerate(data: any): Observable<any> {
    return this.api.get(`${this._baseUrl}questao/ia`, data);
  }

  public IAUserCredits(): Observable<any> {
    return this.api.get(`${this._baseUrl}questao/ia/credits`);
  }
}
