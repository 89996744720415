import { Injectable } from '@angular/core';
import { notNull } from '@app/shared';
import { AppSelectors } from '@app/store';
import { AppState } from '@app/store/app.state';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, finalize, map, switchMap, take } from 'rxjs/operators';
import { StatusEnum } from '../models/status.enum';
import { LoadingService, UserService } from '../services';
import { MenuItem, MenuRef } from './menu';

@Injectable({ providedIn: 'root' })
export class StudentMenu implements MenuRef {
  public identifier = 'student';

  public readonly menu: MenuItem[] = [
    () => ({
      title: 'menu.aluno',
      url: '/aluno',
      icon: 'icon-academic',
      dimension: 'h-9 w-9',
      permission: [1, 3],
    }),
  ];

  public readonly submenu: MenuItem[] = [
    () => ({
      title: 'breadcrumb.aluno.horario-aulas',
      url: '/aluno/horario-aulas',
      icon: 'icon-menu-timetable',
      group: 'menu.aluno',
    }),
    () => ({
      title: 'breadcrumb.aluno.tarefas',
      url: '/aluno/tarefas',
      icon: 'icon-planning',
      group: 'menu.aluno',
      show: this.store.select(AppSelectors.ActiveUser).pipe(
        notNull(),
        map((activeUser) => activeUser?.secretaria_sistema_id !== 3),
      ),
    }),
    () => ({
      title: 'breadcrumb.aluno.avaliacoes',
      url: '/aluno/avaliacoes',
      icon: 'icon-menu-evaluations',
      group: 'menu.aluno',
      show: this.store.select(AppSelectors.ActiveUser).pipe(
        notNull(),
        map((activeUser) => activeUser?.boletim_infantil !== StatusEnum.Active),
      ),
    }),
    () => ({
      title: 'breadcrumb.aluno.notas-faltas',
      url: '/aluno/notas-faltas',
      icon: 'icon-menu-absences',
      group: 'menu.aluno',
      show: this.store.select(AppSelectors.ActiveUser).pipe(
        notNull(),
        map((activeUser) => activeUser?.boletim_infantil !== StatusEnum.Active),
      ),
    }),
    () => ({
      title: 'breadcrumb.aluno.conteudo-programatico',
      url: '/aluno/conteudo-programatico',
      icon: 'icon-book-pencil',
      group: 'menu.aluno',
    }),
    () => ({
      title: 'breadcrumb.aluno.relatorios',
      url: '/aluno/relatorios',
      icon: 'icon-menu-reports',
      group: 'menu.aluno',
      show: this.store.select(AppSelectors.ActiveUser).pipe(
        notNull(),
        map(
          (activeUser) => activeUser?.secretaria_sistema_id !== 3 && activeUser?.boletim_infantil === StatusEnum.Active,
        ),
      ),
    }),
    () => ({
      title: 'breadcrumb.aluno.apontamentos',
      url: '/aluno/apontamentos',
      icon: 'icon-menu-notifications',
      group: 'menu.aluno',
    }),
    () => ({
      title: 'breadcrumb.academico.modulo-academico',
      url: undefined,
      icon: 'icon-import-activity',
      group: 'menu.aluno',
      click: () => this.openAcademicUrl(),
      show: this.store.select(AppSelectors.ActiveUser).pipe(
        notNull(),
        map((activeUser) => activeUser?.secretaria_sistema_id === 3),
      ),
    }),
  ];

  constructor(
    private store: Store<AppState>,
    private userService: UserService,
    private loadingService: LoadingService,
  ) {}

  private openAcademicUrl() {
    this.loadingService.start();
    this.store
      .select(AppSelectors.ActiveUser)
      .pipe(
        take(1),
        notNull(),
        switchMap(() =>
          this.userService.loginIatec().pipe(
            catchError(() => of(null)),
            finalize(() => this.loadingService.finish()),
          ),
        ),
      )
      .subscribe((url) => window.open(url, '_blank'));
  }
}
