import { Injectable } from '@angular/core';
import { portalConst } from '@app/config';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApiService, Resp } from './api.service';
import { CrudService } from './crud.service';

@Injectable({
  providedIn: 'root',
})
export class LearningTrailsSectionService extends CrudService<any> {
  public sections$ = new BehaviorSubject<any[]>([]);

  constructor(api: ApiService) {
    super(api, `${portalConst.api.trilhas}/v1/trilhas/sessao`);
  }

  public updateOrdem(sectionId: number, currentOrder: number, previousOrder: number): Observable<Resp<any>> {
    return this.api.get(
      `${portalConst.api.trilhas}/v1/trilhas/sessoes/ordem/${sectionId}/${currentOrder}/${previousOrder}`,
    );
  }
}
