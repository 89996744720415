import { Injectable } from '@angular/core';
import { portalConst } from '@app/config';
import { Feedback } from '../models';
import { ApiService, Resp } from './api.service';
import { CrudService } from './crud.service';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class FeedbackService extends CrudService<Feedback> {
  constructor(api: ApiService) {
    super(api, `${portalConst.api.eclass}/v1/feedback`);
  }

  public feedbackActivitiesDate(date_start?: string, date_end?: string): Observable<Resp<any>> {
    return this.api.get(`${this.url}/resume?date_start=${date_start}&date_end=${date_end}`);
  }

  public feedbackActivities(): Observable<Resp<any>> {
    return this.api.get(`${this.url}/resume`);
  }
}
