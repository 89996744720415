import { AppState } from '@app/store/app.state';
import { Policies } from '..';

const Student = (store: AppState) => (store?.usuario_ativo?.aluno_id ? null : { aluno: true });
const Entity = (store: AppState) => (store?.usuario_ativo?.entidade_id ? null : { entidade: true });
const EntityOrSuperAdmin = (store: AppState) =>
  store?.usuario_ativo?.entidade_id || Policies.isAllow({ moduleId: 3, elementoId: 52 }, store.policies)
    ? null
    : { entidade: true };
const Maintenance =
  (...entity: number[]) =>
  (store: AppState) =>
    entity.includes(store?.usuario_ativo?.entidade_id) ? { manutencao: true } : null;

export const GuardValidators = { Student, Entity, Maintenance, EntityOrSuperAdmin };
