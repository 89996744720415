import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { safeDate } from '../utils';

@Pipe({
  name: 'safeDate',
})
export class SafeDatePipe implements PipeTransform {
  constructor(protected sanitizer: DomSanitizer) {}

  public transform(value: any): Date | null {
    return safeDate(value);
  }
}
