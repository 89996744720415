import { Injectable } from '@angular/core';
import { portalConst } from '@app/config';
import { Language } from '../models';
import { ApiService } from './api.service';
import { CrudService } from './crud.service';

@Injectable({
  providedIn: 'root',
})
export class LanguageService extends CrudService<any> {
  constructor(api: ApiService) {
    super(api, `${portalConst.api.usuario}/v1/hub-educacional/language`);
  }

  public updateLanguage(body: any) {
    return this.api.put<Language>(`${this.url}/change-lang`, body);
  }
}
