<div class="relative h-[75vh] w-[40vw]">
  <app-modal-header>
    <div class="flex w-full items-center gap-2">
      <h1 class="text-xl font-semibold leading-tight text-gray-800">Upload</h1>
    </div>
  </app-modal-header>

  <div class="p-4">
    <form [formGroup]="form" class="flex flex-wrap gap-6">
      <div
        class="group flex w-full cursor-pointer flex-wrap items-center justify-center rounded-xl border-2 bg-gray-50 p-20 hover:bg-gray-100"
      >
        <svg-icon
          class="flex h-40 w-40 text-gray-300 group-hover:text-gray-500"
          src="assets/icons/icon-upload-small.svg"
        ></svg-icon>
      </div>
      <div class="flex h-[27rem] w-full flex-col gap-2 overflow-y-auto pb-4">
        <app-card class="flex w-full !flex-row items-center justify-between border p-4">
          <div class="flex items-center gap-2">
            <svg-icon
              class="flex h-8 w-8 cursor-pointer text-gray-500 hover:text-gray-600"
              src="assets/icons/icon-upload-small.svg"
            ></svg-icon>
            <div class="mx-4 flex flex-col items-start whitespace-nowrap">
              <span class="text-sm font-bold md:text-md">Report name_Q1.jpeg</span>
              <span class="md:txt-base text-sm font-light"> 15MB </span>
            </div>
          </div>
          <button app-flat-button type="button" class="bg-base-status-danger">
            <svg-icon class="flex h-5 w-5 text-white" src="assets/icons/icon-trash.svg"></svg-icon>
          </button>
        </app-card>
      </div>
      <div class="absolute bottom-0 left-0 z-999 flex w-full justify-between rounded-b-xl bg-white px-6 py-4 shadow-up">
        <button
          app-flat-button
          class="border-red-500 bg-white px-5 uppercase text-red-500 hover:[&:not([disabled])]:bg-red-500 hover:[&:not([disabled])]:text-white"
          type="button"
        >
          <span>Cancelar</span>
        </button>
        <button app-flat-button class="bg-theme-500 px-5 uppercase text-white" type="button">Fazer upload</button>
      </div>
    </form>
  </div>
</div>
