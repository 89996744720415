<app-modal-header>
  <div class="grid grid-cols-[auto_1fr] items-center gap-5 lg:grid-cols-[220px_auto_1fr]">
    <div class="flex items-center">
      <svg-icon src="assets/icons/icon-folder.svg" class="ml-6 w-7 text-modules-school-website-500"></svg-icon>
      <p class="hidden pl-3 text-lg font-semibold text-gray-700 md:block" translate>site-escola.arquivos.titulo</p>
    </div>
    <hr app-modal-divider class="!m-0 hidden lg:block" />
    <div class="flex items-center overflow-hidden">
      <p class="flex cursor-pointer whitespace-nowrap px-1 text-lg font-medium">
        {{ form?.value.category?.description || 'geral.todos' | translate }}
      </p>
    </div>
  </div>
</app-modal-header>
<div app-modal-content class="h-[90vh] w-[90vw] !p-0 md:h-[calc(100vh_-_15rem)] xl:h-[50rem] xl:w-[80rem]">
  <app-sidenav-group class="h-full w-full rounded-b-lg bg-base-whisper-300">
    <ng-template app-sidenav-start panelWidth="250px" [opened]="true">
      <ng-container *ngIf="{ categories: categories$ | async } as data">
        <div class="h-full w-[250px] gap-5 rounded-bl-lg bg-base-whisper-300 p-4 transition duration-500">
          <ng-container *ngIf="!!data.categories; else loadTemplate">
            <div class="flex flex-col gap-2 overflow-y-auto px-3 pb-5">
              <button
                app-flat-button
                (click)="onCategory()"
                type="button"
                class="bg-white text-md font-semibold transition duration-300 hover:bg-modules-school-website-500 hover:text-white"
                [ngClass]="{ '!bg-modules-school-website-500 text-white': !form.value.category }"
              >
                <span translate> geral.todos </span>
              </button>
              <button
                *ngFor="let category of data.categories"
                app-flat-button
                (click)="onCategory(category)"
                type="button"
                class="bg-white text-md font-semibold transition duration-300 hover:bg-modules-school-website-500 hover:text-white"
                [ngClass]="{ '!bg-modules-school-website-500 text-white': form.value.category?.id === category.id }"
              >
                <span> {{ category.description }} </span>
              </button>
            </div>
          </ng-container>
          <ng-template #loadTemplate>
            <app-skeleton></app-skeleton>
          </ng-template>
        </div>
      </ng-container>
    </ng-template>

    <ng-template app-sidenav-outer>
      <div class="h-full w-[calc(100%_-_16px)] p-4">
        <ng-container *ngIf="{ files: file$ | async, load: load$ | async } as data">
          <ng-container *ngIf="!data.load; else loadTemplate">
            <div class="flex h-full w-full flex-col justify-between gap-4">
              <div class="relative w-full">
                <input
                  app-input
                  [formControl]="form.controls.term"
                  class="rounded-xl pr-10"
                  [placeholder]="'site-escola.arquivos.descricao' | translate"
                />
                <svg-icon
                  src="assets/icons/icon-search.svg"
                  class="absolute right-4 top-4 w-4 text-gray-400 dark:text-gray-400"
                ></svg-icon>
              </div>

              <ng-container *ngIf="data.files.data | isEmpty; else dataTemplate">
                <app-jumbotron translate>geral.filtro-vazio</app-jumbotron>
              </ng-container>

              <ng-template #dataTemplate>
                <div class="h-full overflow-auto rounded-bl-lg">
                  <table app-table>
                    <thead>
                      <tr>
                        <th app-table-cell class="min-w-[80px] !bg-white !text-base uppercase !text-base-gray-blue-500">
                          {{ 'site-escola.arquivos.tipo' | translate }}
                        </th>
                        <th
                          app-table-cell
                          class="min-w-[300px] !bg-white !text-base uppercase !text-base-gray-blue-500"
                        >
                          {{ 'site-escola.arquivos.descricao' | translate }}
                        </th>
                        <th
                          app-table-cell
                          class="min-w-[150px] !bg-white !text-base uppercase !text-base-gray-blue-500"
                        >
                          {{ 'site-escola.arquivos.categoria' | translate }}
                        </th>
                        <th app-table-cell class="!bg-white !text-base uppercase !text-base-gray-blue-500">
                          {{ 'site-escola.arquivos.download' | translate }}
                        </th>
                      </tr>
                    </thead>
                    <tbody app-table-body class="text-center">
                      <tr *ngFor="let file of data.files.data">
                        <td app-table-cell class="flex justify-center">
                          <img [src]="icon(file)" class="w-10" alt="image" />
                        </td>
                        <td app-table-cell class="text-center">
                          {{ file.description }}
                        </td>
                        <td app-table-cell>
                          {{ file.category.description }}
                        </td>
                        <td app-table-cell>
                          <a
                            class="mx-auto flex h-9 w-9 items-center justify-center rounded-full bg-emerald-400 p-2 shadow-md hover:bg-emerald-300"
                            [href]="file.file"
                            target="_blank"
                          >
                            <svg-icon class="w-full text-white" src="assets/icons/icon-download.svg"></svg-icon>
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </ng-template>

              <app-pagination [page]="data.files" (pageChange)="onChangePage($event)"></app-pagination>
            </div>
          </ng-container>
          <ng-template #loadTemplate>
            <app-skeleton></app-skeleton>
          </ng-template>
        </ng-container>
      </div>
    </ng-template>
  </app-sidenav-group>
</div>
