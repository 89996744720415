import { Injectable } from '@angular/core';
import { portalConst } from '@app/config';
import { ApiService } from '@app/core/services/api.service';
import { CrudService } from '@app/core/services/crud.service';

@Injectable({
  providedIn: 'root',
})
export class AssessmentIndentifierService extends CrudService<any> {
  constructor(api: ApiService) {
    super(api, `${portalConst.api.assessments}/v1/identificadores`);
  }
}
