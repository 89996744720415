import { AfterViewInit, ChangeDetectionStrategy, Component, Input, ViewChild, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { UserService } from '@app/core';
import { safeEmptyList, selectedEntityActive } from '@app/shared/utils';
import { AppSelectors } from '@app/store';
import { AppState } from '@app/store/app.state';
import { NgSelectComponent } from '@ng-select/ng-select';
import { Store } from '@ngrx/store';
import { head } from 'lodash';
import { Observable, exhaustMap, filter, map } from 'rxjs';

@Component({
  selector: 'app-select-employee',
  templateUrl: './select-employee.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectEmployeeComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectEmployeeComponent implements ControlValueAccessor, AfterViewInit {
  @Input() public placeholder = 'geral.selecionar';
  @Input() public disable: boolean;
  @ViewChild(NgSelectComponent, { static: true }) private select: NgSelectComponent;
  public employees$: Observable<any[]>;

  constructor(private userService: UserService, private store: Store<AppState>) {}

  public writeValue(obj: any): void {
    this.select.writeValue(obj);
  }

  public registerOnChange(fn: any): void {
    this.select.registerOnChange(fn);
  }

  public registerOnTouched(fn: any): void {
    this.select.registerOnTouched(fn);
  }

  public setDisabledState?(isDisabled: boolean): void {
    this.select.setDisabledState(isDisabled);
  }

  public ngAfterViewInit(): void {
    this.loadData();
    if (this.disable) {
      this.setDisabledState(true);
    }
  }

  private loadData() {
    this.employees$ = this.store.select(AppSelectors.ActiveUser).pipe(
      filter((user) => !!user),
      map((user) => selectedEntityActive(user)),
      exhaustMap((entity) => this.userService.getEmployees(head(entity)).pipe(safeEmptyList())),
    );
  }
}
