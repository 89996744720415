<div
  class="relative grid transition-all duration-300 bg-white border-b-2 shadow-md main-header z-200 gap-x-4 border-theme-500 shadow-black/12"
  *ngIf="store$ | async as store"
>
  <ng-container *ngIf="{ isExternal: isExternal$ | async } as client">
    <!-- Adventist logo -->
    <button
      app-icon-button
      type="button"
      class="main-header__adventist relative z-10 !rounded-none !rounded-br-2xl bg-base-ea-500 text-white shadow-sm shadow-black/40 lg:px-6"
      [ngClass]="{ 'bg-base-weekly-500': client.isExternal, 'bg-base-ea-500': !client.isExternal }"
      [routerLink]="'/'"
    >
      <ng-container *ngIf="client.isExternal; else eaTemplate">
        <svg-icon src="assets/images/logos/logo-cpb.svg" class="w-7"></svg-icon>
      </ng-container>
      <ng-template #eaTemplate>
        <svg-icon src="assets/images/logos/logo-ea.svg" class="w-7"></svg-icon>
      </ng-template>
    </button>

    <!-- E-class logo-->
    <a app-icon-button class="hidden main-header__eclass lg:inline-flex" [routerLink]="'/'">
      <ng-container *ngIf="client.isExternal; else eclassTemplate">
        <svg-icon src="assets/images/logos/logo-weekly.svg" class="w-16 lg:w-24"></svg-icon>
      </ng-container>
      <ng-template #eclassTemplate>
        <svg-icon src="assets/images/logos/logo-eclass.svg" class="w-16 lg:w-24"></svg-icon>
      </ng-template>
    </a>
  </ng-container>

  <!-- Current entity -->
  <div
    class="flex flex-col justify-center pl-4 my-2 truncate align-middle main-header__entity border-base-gray-100 lg:border-l"
    *ngIf="store?.usuario_ativo as activedUser"
  >
    <h1 class="text-xl font-semibold leading-none truncate">
      {{ activedUser.titulo_entidade || activedUser.nome_escola }}
    </h1>
    <span class="font-normal text-base-gray-400">
      {{ activedUser.tipo_entidade }}
    </span>
  </div>

  <!-- Bilingual -->
  <ng-container *ngIf="{ languages: language$ | async, current: userLanguage$ | async } as lang">
    <div class="flex items-center justify-center gap-1 main-header__bilingual">
      <div *ngFor="let language of lang.languages" class="w-8">
        <img
          class="transition-all duration-300 rounded-lg opacity-50 cursor-pointer flag hover:scale-110 hover:opacity-100 hover:shadow-lg"
          [ngClass]="{ '!opacity-100': isEquals(language, lang.current) }"
          [src]="language.img_flag"
          [alt]="language.nome"
          (click)="onLang(language, lang.current)"
        />
      </div>
    </div>

    <!-- Dependent -->
    <div
      *ngIf="dependent(store) as dependent"
      class="main-header__dependent my-2 flex flex-col justify-center truncate border-l border-base-gray-100 pl-4 align-middle lg:!col-start-auto"
      [ngClass]="{ '!col-start-2': lang.languages | isEmpty }"
    >
      <h1 class="truncate text-md">{{ dependent | orderName | async }}</h1>
      <span class="font-normal leading-none text-base-gray-400"> {{ dependent.serie || '-' }} </span>
    </div>
  </ng-container>

  <!-- Service -->
  <div *isAllow="[1, 8]" class="col-start-4 flex w-full justify-end p-2 lg:!col-start-auto">
    <button
      type="button"
      app-tooltip
      app-outline-button
      [tooltip]="'geral.servicos' | translate"
      (click)="onService()"
      class="group !rounded-3xl border-none uppercase text-theme-500 hover:bg-theme-500 md:border-solid md:border-theme-500 md:px-4"
    >
      <span class="hidden pr-3 transition duration-300 text-md group-hover:text-theme-contrast md:block" translate>
        geral.servicos
      </span>
      <svg-icon
        class="transition duration-300 h-7 w-7 text-theme-500 group-hover:text-theme-contrast"
        src="assets/icons/icon-access.svg"
      ></svg-icon>
    </button>
  </div>

  <!-- Notification -->
  <button
    #notification
    *ngIf="isDev"
    type="button"
    app-icon-button
    class="relative main-header__notification"
    (click)="onNotification(notification)"
  >
    <ng-container *ngIf="{ show: hasNotification$ | async } as notification">
      <span *ngIf="notification.show" class="absolute right-[10px] top-[17px] flex h-4 w-4">
        <span
          [ngClass]="{ 'animate-ping': !!pulseNotification }"
          class="absolute inline-flex w-full h-full bg-red-500 rounded-full opacity-75"
        ></span>
        <span class="relative inline-flex w-4 h-4 bg-red-500 rounded-full"></span>
      </span>
    </ng-container>
    <svg-icon class="h-7 w-7 text-theme-500" src="assets/icons/icon-bell.svg"></svg-icon>
  </button>

  <!-- Toogle -->
  <button type="button" *ngIf="showToogle" app-icon-button class="main-header__toogle md:hidden" (click)="onToogle()">
    <svg-icon class="h-7 w-7 text-theme-500" [src]="icon(toogleStatus)"></svg-icon>
  </button>

  <!-- Profile -->
  <div class="flex items-center justify-end p-2 main-header__profile">
    <button
      id="menu"
      #menu
      app-icon-button
      app-outline-button
      type="button"
      class="border-1 w-max !rounded-full bg-white/80 !p-1 shadow-sm shadow-black/40"
      (click)="onMenu(menu)"
    >
      <app-user-photo class="w-10 h-10" [src]="store.photo"></app-user-photo>
      <span class="absolute bottom-0 right-0 w-4 h-4 bg-green-500 border-2 border-white rounded-full"></span>
    </button>
  </div>

  <!-- Divider -->
  <hr class="h-2 -m-2 border-none shadow-md main-header__divider shadow-gray-500/20 lg:hidden" />
</div>

<!-- Float main menu -->
<app-main-menu
  class="absolute bottom-[calc((var(--vh)_*_-100)_+_10rem)] flex w-full justify-center transition duration-300 lg:top-1/2"
></app-main-menu>
