import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { HttpParamsUtils } from '@app/shared/utils';
import { isNil } from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class RouterParamLoaderService {
  constructor(private router: Router, private location: Location) {}

  public updateReq<T>(req?: any, params?: NavigationExtras) {
    const queryParams = this.treatReq(req);
    const extras = { queryParams, replaceUrl: true, ...params };
    this.router.navigate([], extras);
  }

  public updateUrl<T>(req?: any, urlInicial?: string) {
    const queryParams = this.treatReq(req);
    this.location.replaceState(
      (!!urlInicial ? urlInicial : this.router.url) + '/' + Object.values(queryParams).join('/'),
    );
  }

  private treatReq(req?: any) {
    return Object.entries(req)
      .map(([key, value]) => ({ [key]: HttpParamsUtils.serialize(value) }))
      .filter((value) => !isNil(value))
      .reduce((p, c) => ({ ...p, ...c }), {});
  }
}
