import { Language } from '@app/core/models';
import { Product } from '@app/core/models/product.model';
import { EntityIdentifier } from '@app/shared/utils';
import { createAction, props } from '@ngrx/store';
import { AppState } from './app.state';

export const InitDataEffect = createAction('[App] InitDataEffect', props<{ user: any }>());
export const TokenEffect = createAction('[App] TokenEffect', props<{ token: string; access_token?: string }>());
export const EntityEffect = createAction('[App] EntityEffect', props<{ entity: EntityIdentifier }>());
export const TokenClearEffect = createAction('[App] TokenClearEffect');

export const TokenReducer = createAction('[App] TokenReducer', props<{ token: string; access_token?: string }>());
export const EntityReducer = createAction('[App] EntityReducer', props<{ entity: EntityIdentifier }>());
export const TokenClearReducer = createAction('[App] TokenClearReducer');
export const DataLoadedReducer = createAction('[App] DataLoadedReducer', props<AppState>());
export const LanguageReducer = createAction('[App] LanguageReducer', props<Language>());
export const ProductReducer = createAction('[App] ProductReducer', props<Product>());
export const IVCAReducer = createAction('[App] IVCAReducer', props<{ ivca: boolean }>());
