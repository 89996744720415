<!-- Date -->
<div class="flex items-center gap-2 pb-6 pt-4 lg:hidden">
  <svg-icon class="w-6" src="assets/icons/icon-clock.svg"></svg-icon>
  <h1 class="text-md first-letter:uppercase">{{ now | date : 'fullDate' }}</h1>
</div>

<ng-container *ngIf="allow$ | async as allow">
  <app-card-sit *ngIf="allow.sitFinance" class="h-[30rem] w-full"></app-card-sit>
  <app-card-learning-trails-highlight *ngIf="false" class="h-[30rem] w-full"></app-card-learning-trails-highlight>
  <app-card-educational-materials
    *ngIf="allow.educationalMaterials"
    class="h-[30rem] w-full"
  ></app-card-educational-materials>
  <app-card-learning-trails *ngIf="allow.learningTrails" class="h-[30rem] w-full"></app-card-learning-trails>
  <app-card-announcement class="h-[30rem] w-full"></app-card-announcement>
  <app-card-calendar class="h-[30rem] w-full"></app-card-calendar>
  <app-card-evaluation *ngIf="allow.evaluation" class="h-[30rem] w-full"></app-card-evaluation>
  <app-card-academic-service-report
    *ngIf="allow.serviceMeetings"
    class="h-[30rem] w-full"
  ></app-card-academic-service-report>
</ng-container>
