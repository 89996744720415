import { Injectable } from '@angular/core';
import { portalConst } from '@app/config';
import { Observable } from 'rxjs';
import { ApiService, Resp } from './api.service';

@Injectable({ providedIn: 'root' })
export class UserService {
  private url = `${portalConst.api.usuario}/v1`;

  constructor(private api: ApiService) {}

  public get(req: any): Observable<any> {
    return this.api.get<any>(`${this.url}/dados-usuario`, req);
  }

  public setFuncao(funcao_id: any): Observable<any> {
    return this.api.post<any>(`${this.url}/funcao-padrao-usuario`, { funcao_id });
  }

  public setDependente(dependente_id: any): Observable<any> {
    return this.api.post<any>(`${this.url}/dependente-padrao`, { dependente_id });
  }

  public getGroups(escola_id: any, req?: any): Observable<any[]> {
    return this.api.list<any[]>(`${this.url}/turmas-escola/${escola_id}`, req);
  }

  public entidadeContexto(req?: any): Observable<any[]> {
    return this.api.list<any[]>(`${this.url}/entidade-contexto`, req);
  }

  public searchStudents(req?: any): Observable<any[]> {
    return this.api.list<any[]>(`${this.url}/procura-nome-aluno`, req);
  }

  public schoolStudents(escolaId: number, req?: any): Observable<Resp<any[]>> {
    return this.api.get<any[]>(`${this.url}/alunos-escola/${escolaId}`, req);
  }

  public schoolResponsibles(escolaId: number, req?: any): Observable<any[] | any> {
    return this.api.list<any[] | any>(`${this.url}/responsaveis-escola/${escolaId}`, req);
  }

  public schoolYear(req: any) {
    return this.api.list<number[]>(`${this.url}/hub-educacional/anosByAluno`, req);
  }

  public getEmployees(data: any): Observable<Resp<any[]>> {
    return this.api.get<any[]>(`${this.url}/funcionarios-por-contexto`, data);
  }

  public getListOfDevicesGoogle(user_id: number): Observable<Resp<any[]>> {
    return this.api.get<any[]>(`${this.url}/google/${user_id}/devices`);
  }

  public expireGoogleSession(user_id: number): Observable<Resp<any[]>> {
    return this.api.get<any[]>(`${this.url}/google/${user_id}/expire-session`);
  }

  public loginIatec(): Observable<string> {
    return this.api.list<string>(`${portalConst.api.cron_api}/login`);
  }

  public getEntities(country_id: number) {
    return this.api.get<any[]>(`${this.url}/hub-educacional/user/entities`, { country_id, all: true });
  }
}
