import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { teacherIds, teacherLevel } from '@app/core/models';
import { TypeBookEnum } from '@app/core/models/books.model';
import { BooksService } from '@app/core/services';
import { MdPadraoSerieService } from '@app/core/services/md-padrao-serie.service';
import { safeEmpty, safeEmptyList, switchDelay } from '@app/shared';
import { notNull } from '@app/shared/utils';
import { AppSelectors } from '@app/store';
import { AppState } from '@app/store/app.state';
import { Store } from '@ngrx/store';
import { isEqual, uniqBy, flattenDeep, isEmpty } from 'lodash';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap, tap } from 'rxjs/operators';

@Component({
  selector: 'app-card-educational-materials',
  templateUrl: './card-educational-materials.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardEducationalMaterialsComponent implements OnInit, OnDestroy {
  public books$: Observable<any[]>;
  public loading$: Observable<boolean>;
  private _loading$ = new BehaviorSubject(true);

  constructor(
    private store: Store<AppState>,
    private booksService: BooksService,
    private teacherService: MdPadraoSerieService,
  ) {
    this.loading$ = this._loading$.pipe(switchDelay());
  }

  public ngOnInit() {
    this.books$ = this.store.select(AppSelectors.appFeature).pipe(
      notNull(),
      map(({ usuario_ativo, funcao_padrao }) => {
        if (!!usuario_ativo) {
          return {
            entidade_id: usuario_ativo?.entidade_id,
            campo_id: usuario_ativo?.campo_id,
            uniao_id: usuario_ativo?.uniao_id,
            divisao_id: usuario_ativo?.divisao_id,
            editora_id: usuario_ativo?.editora_id,
            modelo_id: usuario_ativo?.pais_id,
            funcao_padrao,
          };
        }
        return null;
      }),
      distinctUntilChanged(isEqual),
      tap(() => this._loading$.next(true)),
      switchMap((param) => {
        if (!!param) {
          if (teacherIds().includes(param.funcao_padrao) && !!param.entidade_id) {
            return this.teacherService.teacher(param.entidade_id, [teacherLevel(param.funcao_padrao)]).pipe(
              safeEmpty(),
              switchMap((res) => this.listBooks(param, res)),
            );
          }
          return this.listBooks(param);
        }
        return of([]);
      }),
      tap(() => this._loading$.next(false)),
    );
  }

  public ngOnDestroy() {
    this._loading$.complete();
  }

  private listBooks(param: any, filters: any = {}) {
    return this.booksService.index({ ...param, type: TypeBookEnum.Didatico }).pipe(
      safeEmptyList(),
      map((res) => this.helperBooks(res, filters)),
    );
  }

  private helperBooks(data: any[], filters: any): any {
    const books = flattenDeep(data.map(({ books }) => books)).filter((book) => {
      const levelsFilter = this.filterBy(filters.niveis, book.nivel_id);
      const gradesFilter = this.filterBy(filters.series, book.serie_id);
      const subjectFilter = this.filterBy(filters.disciplinas, book.disciplina_id);
      return levelsFilter && gradesFilter && subjectFilter;
    });
    return uniqBy(books, 'id');
  }

  private filterBy(targets: any[], key: any): boolean {
    if (!isEmpty(targets) && !!key) {
      return targets.map(Number).includes(Number(key));
    }
    return true;
  }
}
