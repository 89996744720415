<app-modal-header class="border-b-2 border-theme-500">
  <div class="flex w-full gap-5 bg-white">
    <div class="flex">
      <svg-icon
        class="w-10 text-theme-500"
        src="assets/icons/icon-gestor-avaliacoes.svg"
        alt="Materiais Didaticos"
      ></svg-icon>
      <h1 class="ml-3 hidden text-sm font-semibold leading-tight text-gray-800 md:grid" translate>
        gestor-avaliacoes.e-class
        <br />
        <b class="text-lg text-black" translate> gestor-avaliacoes.prova </b>
      </h1>
    </div>

    <hr app-modal-divider />

    <div>
      <div class="flex w-full items-center justify-between pt-2">
        <div class="md:flex">
          <div class="mr-5">
            <span class="text-md text-black" translate> gestor-avaliacoes.espelho-avaliacao </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</app-modal-header>
