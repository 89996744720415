import { Dialog } from '@angular/cdk/dialog';
import { ChangeDetectionStrategy, Component, DestroyRef, Inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AssessmentApplicationService } from '@app/core';
import { AssessmentManagedType } from '@app/core/models';
import { ModalCreateResponseCardComponent } from '@app/modules/assessments-manager/shared/modal-create-response-card/modal-create-response-card.component';
import { ModalUploadFilesComponent } from '@app/modules/assessments-manager/shared/modal-upload-files/modal-upload-files.component';
import { notNull, selectedEntityActive } from '@app/shared';
import { POPOVER_DATA } from '@app/shared/modules/template/components/popover';
import { AppSelectors } from '@app/store';
import { AppState } from '@app/store/app.state';
import { Store } from '@ngrx/store';
import { first } from 'lodash';
import { PopoverRef } from '../../../../../../../shared/modules/template/components/popover/index';
import { ModalAnswersAuditComponent } from '../../../modal-answers-audit/modal-answers-audit.component';
import { ModalAnswersComponent } from '../../../modal-answers/modal-answers.component';
import { ModalAssessmentsPrintSettingsComponent } from '../../../modal-assessments-print-settings/modal-assessments-print-settings.component';
import { ModalAssessmentsImportFileComponent } from '../modal-assessments-import-file/modal-assessments-import-file.component';
import { ModalExportFileComponent } from '../modal-export-file/modal-export-file.component';

@Component({
  selector: 'app-popover-options-button',
  templateUrl: './popover-options-button.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PopoverOptionsButtonComponent {
  public entityActive: any;

  constructor(
    @Inject(POPOVER_DATA) public data: any,
    private _dialog: Dialog,
    private _store: Store<AppState>,
    private _destroyRef: DestroyRef,
    private _popoverRef: PopoverRef,
    private assessmentApplicationService: AssessmentApplicationService,
  ) {
    this._store
      .select(AppSelectors.ActiveUser)
      .pipe(takeUntilDestroyed(this._destroyRef), notNull())
      .subscribe((activeUser) => {
        this.entityActive = first(selectedEntityActive(activeUser));
      });
  }

  public get isPAAEB(): boolean {
    return [AssessmentManagedType.PAAEB, AssessmentManagedType.PAEE].includes(
      this.data.application.avaliacao.tipo_gerenciada,
    );
  }

  public get isDivision(): boolean {
    return this.entityActive?.tipo === 5;
  }

  public get isEntity(): boolean {
    return this.entityActive?.tipo === 1;
  }

  public onModalResolutionEvaluation() {
    this._dialog
      .open(ModalAnswersComponent, {
        data: {
          dataStudent: this.data.alunos,
          applicationUuid: this.data.application.uuid,
          evaluation: this.data.avaliacao,
          status_execution: this.data.status_execucao,
        },
        disableClose: true,
        hasBackdrop: true,
      })
      .closed.pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe(() => this._popoverRef.close(true));
  }

  public onModalAnswersAuditComponent() {
    this._dialog
      .open<boolean>(ModalAnswersAuditComponent, {
        data: {
          dataStudent: this.data.alunos,
          applicationUuid: this.data.application.uuid,
          evaluation: this.data.avaliacao,
          status_execution: this.data.status_execucao,
        },
        disableClose: true,
        hasBackdrop: true,
      })
      .closed.pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe(() => this._popoverRef.close(true));
  }

  public onAssessmentsPrintSettingsComponent() {
    this._dialog.open<boolean>(ModalAssessmentsPrintSettingsComponent, {
      data: {
        application_uuid: this.data.application.uuid,
        shuffle_questions: this.data.application.embaralhar_questoes,
      },
    });
  }

  public onAssessmentsImportFileComponent() {
    this._dialog.open<boolean>(ModalAssessmentsImportFileComponent, {
      data: {
        application_uuid: this.data.application.uuid,
      },
    });
  }

  public onUploadFiles() {
    this._dialog.open(ModalUploadFilesComponent, {
      data: {
        application_uuid: this.data.application.uuid,
      },
    });
  }

  public onCreateResponseCard() {
    this._dialog.open(ModalCreateResponseCardComponent, {
      data: {
        application_uuid: this.data.application.uuid,
        dataStudent: this.data.dataStudent,
      },
    });
  }

  public onExportFile() {
    this._dialog.open(ModalExportFileComponent, {
      data: this.data.application.uuid,
    });
  }
}
