import { Injectable } from '@angular/core';
import { portalConst } from '@app/config';
import { ApiService, Resp } from './api.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AcademicStudentService {
  private readonly url = portalConst.api.academic;

  constructor(private _api: ApiService) {}

  public groupSchedule(req: any): Observable<Resp<any>> {
    return this._api.get<any>(`${this.url}/v1/group-schedule`, req);
  }
}
