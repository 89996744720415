import { animate, state, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'app-accordion-option',
  templateUrl: './accordion-option.component.html',
  animations: [
    trigger('toggleContent', [
      state(
        'hidden',
        style({
          height: '0px',
          opacity: 0,
          overflow: 'hidden',
        }),
      ),
      state(
        'visible',
        style({
          height: '*',
          opacity: 1,
        }),
      ),
      transition('hidden <=> visible', animate('300ms ease-in-out')),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccordionOptionComponent {
  public showAdvancedOptions: boolean = false;

  public toggleAdvancedOptions() {
    this.showAdvancedOptions = !this.showAdvancedOptions;
  }
}
