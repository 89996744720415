import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AcademicAttendanceReportService } from '@app/core';
import { notNull, safeEmpty } from '@app/shared';
import { AppSelectors } from '@app/store';
import { AppState } from '@app/store/app.state';
import { Store } from '@ngrx/store';
import { isAfter, isBefore, parse } from 'date-fns';
import { isEmpty, orderBy } from 'lodash';
import { Observable, switchMap } from 'rxjs';

@Component({
  selector: 'app-card-academic-service-report',
  templateUrl: './card-academic-service-report.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardAcademicServiceReportComponent implements OnInit {
  public page = 1;
  public reports: any;
  public throttle = 300;
  public pastReports = [];
  public futureReports = [];
  public scrollDistance = 1;
  public scrollUpDistance = 2;
  public next_page_url: string;
  public reports$: Observable<any>;

  constructor(
    private _academicAttendanceReportService: AcademicAttendanceReportService,
    private _store: Store<AppState>,
    private _cdRef: ChangeDetectorRef,
  ) {}

  public ngOnInit(): void {
    this.reportsList();
  }

  public onScrollDown() {
    if (!!this.next_page_url) {
      this.reportsList((this.page += 1));
    }
  }

  public get hasFutureReports(): boolean {
    return !isEmpty(this.futureReports);
  }

  public get hasPastReports(): boolean {
    return !isEmpty(this.pastReports);
  }

  private reportsList(page?: number) {
    this._store
      .select(AppSelectors.appFeature)
      .pipe(
        notNull(),
        switchMap(({ usuario_id }) =>
          this._academicAttendanceReportService.dashboardReports({ user_id: usuario_id, page }),
        ),
        safeEmpty(),
      )
      .subscribe((res: any) => {
        this.next_page_url = res.next_page_url;

        const newFutureReports = res.data.filter((report: any) =>
          isAfter(parse(report.date, 'yyyy-MM-dd HH:mm:ss', new Date()), new Date()),
        );

        const newPastReports = res.data.filter((report: any) =>
          isBefore(parse(report.date, 'yyyy-MM-dd HH:mm:ss', new Date()), new Date()),
        );

        this.futureReports = [...this.futureReports, ...newFutureReports];
        this.pastReports = [...this.pastReports, ...newPastReports];

        const orderedFutureReports = orderBy(this.futureReports, ['date'], ['asc']);

        this.reports = { future: orderedFutureReports, past: this.pastReports };
        this._cdRef.detectChanges();
      });
  }
}
