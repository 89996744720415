<app-popover>
  <div class="h-[60vh] w-[25rem] overflow-y-auto">
    <div app-popover-header translate>gestor-avaliacoes.como-ler-grafico</div>
    <hr app-divider />
    <div class="flex flex-col gap-4" app-popover-content>
      <div>
        <h6 class="font-bold" translate>gestor-avaliacoes.o-que-e</h6>
        <div class="flex items-center">
          <span> </span>
        </div>
      </div>
      <div>
        <h6 class="font-bold" translate>gestor-avaliacoes.como-ler</h6>
        <div class="flex items-center">
          <span> </span>
        </div>
      </div>
    </div>
  </div>
</app-popover>
