import { Injectable } from '@angular/core';
import { CrudService } from './crud.service';
import { ApiService, Resp } from './api.service';
import { portalConst } from '@app/config';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AssessmentService extends CrudService<any> {
  private readonly _baseUrl = `${portalConst.api.assessments}/v1/`;
  constructor(api: ApiService) {
    super(api, `${portalConst.api.assessments}/v1/avaliacao`);
  }

  public indexPublishingAssessment(params: any) {
    return this.api.get(`${this._baseUrl}avaliacao-editora`, params);
  }

  public generalData(assessmentUuid: string): Observable<Resp<any>> {
    return this.api.get(`${this._baseUrl}avaliacao/${assessmentUuid}/general-data`);
  }

  public preview(assessmentUuid: string): Observable<Resp<any>> {
    return this.api.get(`${this._baseUrl}avaliacao/${assessmentUuid}/preview`);
  }

  public managedTypes(): Observable<any> {
    return this.api.get(`${this._baseUrl}avaliacao/tipo-gerenciada`);
  }
}
