export interface Content {
  id: number;
  author: string;
  title: string;
  imagem: any;
  chamada: string;
  category: ContentCategory;
  extension: string;
  description: any;
  created_at: Date;
  updated_at: Date;
  publishing_date: Date;
}

export interface ContentCategory {
  description: string;
  entity_id: number;
  id: number;
}
