import { portalConst } from '@app/config';
import { AppState } from '@app/store/app.state';

export interface EntityIdentifier {
  entidade_id?: number;
  campo_id?: number;
  uniao_id?: number;
  editora_id?: number;
  divisao_id?: number;
}

export const ENTITY_KEY = ['entidade_id', 'campo_id', 'uniao_id', 'editora_id', 'divisao_id'];

export function userPhoto(store: string): string {
  return store && `${portalConst.aws.s3}/escola/perfil/${store}`;
}

export function isProfileActived(profile: any, store: AppState): boolean {
  const activeUser = store.usuario_ativo;
  if (activeUser.aluno_id) {
    return profile.escola_aluno.some(
      (escola: any) => escola.aluno_id === activeUser.aluno_id && escola.entidade_id === activeUser.entidade_id,
    );
  }
  if (profile.funcao_id === store.funcao_padrao) {
    return compareEntity(profile, activeUser);
  }
  return false;
}

export function compareEntity(data: EntityIdentifier, target: EntityIdentifier, keys = ENTITY_KEY): boolean {
  return keys.filter((key) => !!data[key] && !!target[key]).some((key) => data[key] === target[key]);
}

export function isExternal(): boolean {
  return new URL(window.location.href).hostname === 'sistemainterativo.com.br';
}
