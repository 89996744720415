import { DialogRef, DIALOG_DATA } from '@angular/cdk/dialog';
import { Component, Inject } from '@angular/core';

@Component({
  selector: 'app-modal-certificate-preview',
  templateUrl: './modal-certificate-preview.component.html',
  styles: ``,
})
export class ModalCertificatePreviewComponent {
  constructor(@Inject(DIALOG_DATA) public data: any, private dialogRef: DialogRef) {}

  public onClose() {
    this.dialogRef.close();
  }
}
