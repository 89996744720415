import { Dialog } from '@angular/cdk/dialog';
import { Injectable } from '@angular/core';
import { ModalNotificationComponent } from '../components/modal-notification/modal-notification.component';

@Injectable({
  providedIn: 'root',
})
export class ModalNotificationService {
  constructor(private dialog: Dialog) {}

  public open() {
    this.dialog.open(ModalNotificationComponent);
  }
}
