<div class="container__logo">
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" width="91.34" height="85.46" viewBox="0 0 91.34 85.46">
    <path
      fill="#003B71"
      d="M39.55 4.18c0-.99.33-1.81.98-2.48.65-.66 1.44-1 2.38-1 .47 0 .92.09 1.35.25.43.17.81.41 1.13.72s.59.68.78 1.11c.19.43.29.89.29 1.39V38.8c0 2.03.54 3.58 1.62 4.66s2.57 1.62 4.47 1.62c.99 0 1.8.33 2.44.99s.96 1.46.96 2.4c0 .99-.31 1.8-.94 2.44-.62.64-1.43.96-2.42.96-1.85 0-3.56-.31-5.15-.94-1.59-.62-2.96-1.52-4.13-2.67a12.504 12.504 0 01-2.75-4.13c-.66-1.6-.99-3.37-.99-5.32V4.18z"
    />
    <path
      fill="#003B71"
      d="M.73 23.5c0-2.68.49-5.19 1.47-7.53s2.33-4.38 4.06-6.12C7.98 8.1 10 6.73 12.33 5.73s4.83-1.5 7.51-1.5c2.5 0 4.87.44 7.12 1.33 2.25.88 4.19 2.15 5.83 3.78.42.44.73.91.94 1.4.21.49.31.97.31 1.44 0 .52-.12.99-.35 1.42-.23.43-.53.79-.88 1.07-.35.29-.73.51-1.13.66-.4.16-.77.23-1.11.23-.57 0-1.12-.14-1.64-.43L26.2 13.2c-1.17-.75-2.31-1.27-3.43-1.54s-2.3-.41-3.55-.41c-1.61.05-3.12.42-4.52 1.09-1.4.68-2.62 1.56-3.65 2.67a12.44 12.44 0 00-2.42 3.8c-.58 1.43-.88 2.91-.88 4.44 0 1.56.29 3.06.88 4.52.58 1.45 1.4 2.73 2.46 3.84 1.05 1.1 2.29 1.99 3.73 2.67 1.43.68 3 1.01 4.72 1.01 1.17 0 2.3-.14 3.39-.41 1.09-.27 2.18-.79 3.28-1.54l2.73-1.91c.26-.13.53-.24.82-.33.29-.09.57-.14.86-.14.34 0 .71.08 1.11.23.4.15.77.38 1.11.68.34.3.62.66.84 1.07.22.42.33.88.33 1.4 0 .49-.1.99-.29 1.49-.19.5-.5.95-.92 1.37-1.61 1.65-3.56 2.91-5.83 3.78-2.28.88-4.65 1.31-7.12 1.31-2.65 0-5.14-.49-7.45-1.46-2.31-.98-4.34-2.31-6.07-4-1.73-1.69-3.1-3.68-4.1-5.97-1.01-2.28-1.5-4.74-1.5-7.36z"
    />
    <path
      fill="#003B71"
      d="M71.7 42.34c-2.63 0-5.08-.5-7.37-1.5s-4.28-2.36-5.99-4.08c-1.7-1.72-3.05-3.74-4.04-6.05-.99-2.32-1.48-4.79-1.48-7.42s.49-5.1 1.48-7.42c.99-2.32 2.34-4.34 4.04-6.07 1.71-1.73 3.7-3.1 5.99-4.1 2.29-1 4.75-1.5 7.38-1.5s5.08.49 7.36 1.48c2.28.99 4.26 2.34 5.95 4.04 1.69 1.71 3.03 3.7 4.02 5.99.99 2.29 1.51 4.74 1.56 7.34V38.59c0 .99-.34 1.83-1.02 2.52-.68.69-1.52 1.03-2.51 1.03-.99 0-1.8-.35-2.43-1.04-.63-.69-.94-1.54-.94-2.53l-.04-.55a18.96 18.96 0 01-5.5 3.18c-2.02.76-4.18 1.14-6.46 1.14zm0-6.95c1.72 0 3.3-.32 4.76-.95a12.03 12.03 0 003.8-2.61c1.08-1.1 1.92-2.39 2.54-3.85.61-1.47.92-3.03.92-4.69 0-1.66-.31-3.22-.92-4.69-.61-1.47-1.46-2.75-2.54-3.84a12.257 12.257 0 00-3.8-2.59c-1.46-.64-3.04-.95-4.76-.95-1.69 0-3.26.32-4.72.95-1.46.64-2.72 1.5-3.78 2.59-1.07 1.09-1.91 2.37-2.54 3.84a11.94 11.94 0 00-.94 4.69c0 1.66.31 3.23.94 4.69.62 1.47 1.47 2.75 2.54 3.85s2.33 1.97 3.78 2.61c1.45.64 3.03.95 4.72.95z"
    />
    <path
      fill="#003B71"
      d="M45.66 84.51c-16.83 0-31.76-11.34-36.3-27.59a3.904 3.904 0 017.52-2.1c3.6 12.89 15.44 21.89 28.79 21.89 13.42 0 25.26-9.03 28.81-21.95A3.898 3.898 0 1182 56.82c-4.48 16.31-19.42 27.69-36.34 27.69z"
    />
  </svg>
</div>
