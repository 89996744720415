import { Observable, from, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { notNull } from './operators';

function requestNotification(): Observable<boolean> {
  if ('Notification' in window) {
    if (Notification.permission === 'granted') {
      return of(true);
    }
    if (Notification.permission !== 'denied') {
      return from(Notification.requestPermission()).pipe(map((permission) => permission === 'granted'));
    }
  }
  return of(false);
}

export const NotificationUtil = { browserNotify: requestNotification().pipe(notNull()) };
