import { ChangeDetectionStrategy, Component, Input, OnDestroy } from '@angular/core';
import { SkyEnglishService } from '@app/core/services/sky-english.service';
import { AppSelectors } from '@app/store';
import { AppState } from '@app/store/app.state';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { map, Subject, take, takeUntil } from 'rxjs';

@Component({
  selector: 'app-attendance-report-table',
  templateUrl: './attendance-report-table.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AttendanceReportTableComponent implements OnDestroy {
  @Input() public activedYear: number;
  @Input() public attendanceReports: any;

  private _destroy$ = new Subject<void>();
  constructor(
    private store: Store<AppState>,
    private translate: TranslateService,
    private skyEnglishService: SkyEnglishService,
  ) {}

  public ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

  public onDownloadReport(report: any) {
    this.store
      .select(AppSelectors.appFeature)
      .pipe(
        take(1),
        takeUntil(this._destroy$),
        map(({ token }) => {
          const params = { token };
          this.skyEnglishService.downloadReport(
            report.id,
            'attendance-report',
            report?.title ?? this.translate.instant('servicos.sky-english.relatorio-pdf'),
            params,
          );
        }),
      )
      .subscribe();
  }
}
