export interface Permissao {
  listar: boolean;
  inserir: boolean;
  editar: boolean;
  excluir: boolean;
}
export type Elemento = {
  [elemento: number]: Permissao;
};
export type Policies = {
  [module: number]: { elementos: Elemento };
};
export type Operacao = 'listar' | 'inserir' | 'editar' | 'excluir';
export type RequiredAccessObject = { moduleId: number; elementoId: number; operacao?: Operacao };
export type RequiredAccessArray = [number, number, Operacao] | [number, number];

function safeAccess(requiredAccess: RequiredAccessArray | RequiredAccessObject): RequiredAccessObject | null {
  if (requiredAccess instanceof Array) {
    const [moduleId, elementoId, operacao] = requiredAccess;
    return { moduleId, elementoId, operacao: operacao || 'listar' };
  }
  return { ...requiredAccess, operacao: requiredAccess.operacao || 'listar' };
}

function isAllow(access: RequiredAccessArray | RequiredAccessObject, policies: Policies): boolean {
  const safeAccess = Policies.safeAccess(access);
  const { elementos } = policies[safeAccess.moduleId] || { elementos: {} };
  const operacao = elementos[safeAccess.elementoId] || ({} as Permissao);
  return !!operacao[safeAccess.operacao] || false;
}

export const Policies = { isAllow, safeAccess };
