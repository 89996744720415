import { Component, DestroyRef, Input, OnInit, forwardRef } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DefaultService } from '@app/core/services';
import { notNull, selectedEntityActive } from '@app/shared/utils';
import { AppSelectors } from '@app/store';
import { AppState } from '@app/store/app.state';
import { Store } from '@ngrx/store';
import { head } from 'lodash';
import { BehaviorSubject, combineLatest, finalize, map, switchMap, tap } from 'rxjs';

@Component({
  selector: 'app-select-default-grade',
  templateUrl: './select-default-grade.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectDefaultGradeComponent),
      multi: true,
    },
  ],
})
export class SelectDefaultGradeComponent implements OnInit, ControlValueAccessor {
  @Input() public multiple = true;
  @Input() public label: boolean = true;
  @Input() public strictUser: boolean = false;
  public data$ = new BehaviorSubject<any>(undefined);
  public countryId$ = new BehaviorSubject<any>(undefined);
  private _data$ = new BehaviorSubject<any>(undefined);
  public ctrl = new FormControl();
  public loading$ = new BehaviorSubject(false);

  constructor(
    private _store: Store<AppState>,
    private defaultService: DefaultService,
    private destroyRef: DestroyRef,
  ) {}

  @Input()
  public set countryId(value: number) {
    this.countryId$.next(value);
  }

  @Input()
  public set levelFilter(levels: any[]) {
    if (levels?.length) {
      this.data$.next(this._data$.value?.filter((level) => levels.includes(level.nivel_id)));
    } else {
      this.data$.next(this._data$.value);
    }
  }

  public ngOnInit(): void {
    this.loadData();

    this.ctrl.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((value) => this.onChange(value));
  }

  public writeValue(obj: any): void {
    this.ctrl.setValue(obj);
  }

  public registerOnChange(fn: any): void {
    this.changeValue = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.ctrl.disable();
    } else {
      this.ctrl.enable();
    }
  }

  public onFocus() {
    this.onTouched();
  }

  private onChange(value: any) {
    const defaultValue = this.multiple ? [] : undefined;
    this.changeValue(value ? value : defaultValue);
  }

  private loadData() {
    const activeUser$ = this._store
      .select(AppSelectors.ActiveUser)
      .pipe(takeUntilDestroyed(this.destroyRef), notNull());

    combineLatest({ countryId: this.countryId$, activeUser: activeUser$ })
      .pipe(
        tap(() => this.loading$.next(true)),
        switchMap(({ countryId, activeUser }) =>
          this.defaultService
            .grades(countryId || activeUser.pais_id, head(selectedEntityActive(activeUser)))
            .pipe(finalize(() => this.loading$.next(false))),
        ),
        map((res) => res?.data || (this.multiple ? [] : undefined)),
      )
      .subscribe((data) => {
        this._data$.next(data);
        this.data$.next(data);
      });
  }

  private onTouched = () => true;
  private changeValue = (_: any) => true;
}
