import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { LoadingService } from '@app/core/services';
import { isProfileActived } from '@app/shared/utils';
import { AppActions, AppSelectors } from '@app/store';
import { AppState } from '@app/store/app.state';
import { Store } from '@ngrx/store';
import { map, take, tap } from 'rxjs/operators';

@Component({
  selector: 'app-popover-tab-profile',
  templateUrl: './popover-tab-profile.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PopoverTabProfileComponent {
  @Input({ required: true }) public store: AppState;

  constructor(private storeState: Store<AppState>, private loadingService: LoadingService) {}

  public onChange(profile: any, store: AppState) {
    if (!this.isActived(profile, store)) {
      this.storeState
        .select(AppSelectors.appFeature)
        .pipe(
          take(1),
          tap(() => this.loadingService.start()),
          map((store) => ({
            ...store,
            funcao_padrao: profile.funcao_id,
            profileId: profile.funcao_id,
            unidade: profile,
            usuario_ativo: profile.escola_aluno[0],
          })),
        )
        .subscribe((user) => this.storeState.dispatch(AppActions.InitDataEffect({ user })));
    }
  }

  public isActived(profile: any, store: AppState): boolean {
    return isProfileActived(profile, store);
  }
}
