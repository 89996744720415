import { DIALOG_DATA } from '@angular/cdk/dialog';
import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { AssessmentApplicationService } from '@app/core';
import { TranslateService } from '@ngx-translate/core';
import { sortBy } from 'lodash';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-modal-change-logs',
  templateUrl: './modal-change-logs.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalChangeLogsComponent implements OnInit {
  public logs = [];
  public loading$ = new BehaviorSubject<boolean>(true);
  public loading = this.loading$.asObservable();

  constructor(
    @Inject(DIALOG_DATA) public data: any,
    private _translateService: TranslateService,
    private _assessmentApplicationService: AssessmentApplicationService,
  ) {}

  public ngOnInit() {
    this.loadData();
  }

  public onLabelAction(action: string) {
    switch (action) {
      case '0':
        return this._translateService.instant('gestor-avaliacoes.criacao');
      case '1':
        return this._translateService.instant('gestor-avaliacoes.edicao');
      case '2':
        return this._translateService.instant('gestor-avaliacoes.exclusao');
    }
  }

  private loadData() {
    this.loading$.next(true);
    if (!!this.data.application_uuid) {
      this._assessmentApplicationService.getApplicationLogs(this.data.application_uuid).subscribe((res) => {
        if (!!res.ret) {
          this.logs = sortBy(res.data, 'date').reverse();
          this.loading$.next(false);
        }
      });
    }
    if (!!this.data.question_uuid) {
      this._assessmentApplicationService.getQuestionLogs(this.data.question_uuid).subscribe((res) => {
        if (!!res.ret) {
          this.logs = sortBy(res.data, 'date').reverse();
          this.loading$.next(false);
        }
      });
    }
  }
}
