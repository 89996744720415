export function fileSvg(extension: string) {
  const options = {
    mp4: 'mp4.svg',
    mov: 'mov.svg',
    m4v: 'm4v.svg',
    mp3: 'mp3.svg',
    jpg: 'jpeg.svg',
    jpeg: 'jpeg.svg',
    png: 'jpeg.svg',
    pdf: 'pdf.svg',
    doc: 'doc.svg',
    docx: 'doc.svg',
    ppt: 'ppt.svg',
    pptx: 'ppt.svg',
    xls: 'xls.svg',
    xlsx: 'xls.svg',
    html: 'ppt.svg',
  };

  return 'assets/images/files/' + options[extension] || 'files.svg';
}
