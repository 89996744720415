<ng-select
  app-select
  class="m-input"
  [placeholder]="'geral.seleciona-pais' | translate"
  (change)="onChange($event)"
  (focus)="onFocus()"
  [formControl]="ctrl"
  [multiple]="multiple"
  [items]="data$ | async"
  [clearable]="clearable"
  bindLabel="descricao"
  bindValue="id"
>
</ng-select>
