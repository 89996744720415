<app-card class="h-full">
  <div app-card-header class="relative flex w-full !flex-row justify-between px-5 pt-5">
    <h1 class="text-lg uppercase" translate>geral.comunicados</h1>
  </div>
  <hr app-divider class="my-5" />
  <ng-container *ngIf="_announcementService.announcement$ | async as announcements">
    <ng-template #jumbotron>
      <div app-card-content class="h-full">
        <app-jumbotron translate class="py-8">comunicados.lista-vazia</app-jumbotron>
      </div>
    </ng-template>
    <div
      app-card-content
      class="flex h-[22.5rem] flex-col gap-1 overflow-auto"
      *ngIf="!isEmpty(announcements); else jumbotron"
      infinite-scroll
      [infiniteScrollDistance]="scrollDistance"
      [infiniteScrollUpDistance]="scrollUpDistance"
      [infiniteScrollThrottle]="throttle"
      (scrolled)="onScrollDown()"
      [scrollWindow]="false"
    >
      <ng-container *ngFor="let announcement of announcements; let index = index">
        <div
          class="grid cursor-pointer grid-cols-[3.5rem_1fr] items-center justify-between gap-4 rounded-lg bg-base-gray-light-400 px-4 py-3 text-gray-700 transition-all duration-300 hover:bg-amber-100"
          (click)="onReadAnnouncement(announcement)"
        >
          <div class="flex flex-col items-center justify-center rounded-lg bg-amber-500/12 py-1">
            <span class="text-2xl">{{ announcement.date | date : 'dd' }}</span>
            <span class="text-xs leading-none">
              {{ announcement.date | date : 'MMMM' | slice : 0 : 3 | uppercase }}
            </span>
          </div>
          <div class="flex h-full flex-col gap-1 truncate">
            <h1 class="truncate text-base font-semibold">{{ announcement.title }}</h1>
            <h1 *ngIf="announcement.author" class="truncate text-sm">
              <b translate>geral.autor-geral</b> {{ announcement.author }}
            </h1>
          </div>
        </div>
      </ng-container>
    </div>
  </ng-container>
</app-card>
