import { Injectable } from '@angular/core';
import { portalConst } from '@app/config';
import { NotificationCounter, NotificationData, NotificationPage, NotificationSummary } from '@app/core/models';
import { ApiService } from '@app/core/services/api.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PortalNotificationService {
  private readonly _baseUrl = portalConst.api.notification;

  constructor(private api: ApiService) {}

  public summary(): Observable<NotificationSummary> {
    return this.api.list<NotificationSummary>(`${this._baseUrl}/summary`);
  }

  public markAsRead(id: string, body: any): Observable<NotificationData> {
    return this.api.patch<NotificationData>(`${this._baseUrl}/${id}`, body);
  }

  public counters(): Observable<NotificationCounter> {
    return this.api.list<NotificationCounter>(`${this._baseUrl}/counters`);
  }

  public list(req?: any): Observable<NotificationPage> {
    return this.api.list<NotificationPage>(`${this._baseUrl}`, req);
  }
}
