import { PaisEnum } from '@app/core/models/pais.enum';
import { ENTITY_KEY, Policies, RequiredAccessArray, RequiredAccessObject } from '@app/shared/utils';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { isEmpty } from 'lodash';
import { AppState } from './app.state';

export const featureKey = 'app';

export const appFeature = createFeatureSelector<AppState>(featureKey);
export const entityKey = createSelector(appFeature, ({ usuario_ativo }: AppState) =>
  usuario_ativo ? ENTITY_KEY.reduce((p, k) => ({ ...p, [k]: usuario_ativo[k] }), {}) : null,
);
export const token = createSelector(appFeature, ({ token }: AppState) => token);
export const profiles = createSelector(appFeature, ({ funcoes }: AppState) => funcoes);
export const country = createSelector(appFeature, ({ usuario_ativo }: AppState) => usuario_ativo?.pais_id);
export const ActiveUser = createSelector(appFeature, ({ usuario_ativo }: AppState) => usuario_ativo);
export const DefaultFunction = createSelector(appFeature, ({ funcao_padrao }: AppState) => funcao_padrao);
export const homeschooling = createSelector(appFeature, ({ homeschooling }: AppState) => homeschooling);
export const isPlus = createSelector(appFeature, ({ usuario_ativo }: AppState) => !isEmpty(usuario_ativo?.produtos));
export const isBrasil = createSelector(
  appFeature,
  ({ usuario_ativo }: AppState) => usuario_ativo?.pais_id === PaisEnum.Brasil,
);
export const isExternal = createSelector(appFeature, ({ usuario_ativo }: AppState) => !!usuario_ativo?.escola_externa);
export const isAllow = (access: RequiredAccessArray | RequiredAccessObject) =>
  createSelector(appFeature, ({ policies }: AppState) => Policies.isAllow(access, policies));
export const language = createSelector(appFeature, ({ usuario_ativo }: AppState) =>
  usuario_ativo?.lingua?.toLowerCase(),
);
export const hasZoom = createSelector(appFeature, ({ usuario_ativo }: AppState) => usuario_ativo?.zoom);
export const isIVCA = createSelector(appFeature, ({ ivca }: AppState) => ivca);
