import { NgModule } from '@angular/core';
import { SharedModule } from '@app/shared';
import { CardCoverSummaryModule } from '@app/shared/modules/card-cover-summary/card-cover-summary.module';
import { PaginationModule } from '@app/shared/modules/pagination/pagination.module';
import { ModalDriveComponent } from './components/modal-drive/modal-drive.component';
import { ModalPedagogicalContentComponent } from './components/modal-pedagogical-content/modal-pedagogical-content.component';
import { ModalServicesComponent } from './components/modal-services/modal-services.component';
import { TableStorageComponent } from './components/table-storage/table-storage.component';
import { ExtensionIconPipe } from './pipes/extension.pipe';
import { ModalServicesService } from './services/modal-services.service';

@NgModule({
  declarations: [
    ModalServicesComponent,
    ModalDriveComponent,
    TableStorageComponent,
    ExtensionIconPipe,
    ModalPedagogicalContentComponent,
  ],
  imports: [SharedModule, PaginationModule, CardCoverSummaryModule],
  providers: [ModalServicesService],
})
export class ServiceModule {}
