import { DialogRef } from '@angular/cdk/dialog';
import { AfterViewInit, ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { UserService } from '@app/core';
import { LoginFromUserService } from '@app/layouts/services/login-from-user.service';
import { debounceDelay, userPhoto } from '@app/shared/utils';
import { AppSelectors } from '@app/store';
import { AppState } from '@app/store/app.state';
import { Store } from '@ngrx/store';
import { isEmpty } from 'lodash';
import { Observable, Subject, switchMap, takeUntil } from 'rxjs';
import { map, startWith, take } from 'rxjs/operators';

@Component({
  selector: 'app-login-from-user-tab-responsibles',
  templateUrl: './login-from-user-tab-responsibles.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginFromUserTabResponsiblesComponent implements OnInit, AfterViewInit, OnDestroy {
  public form: FormGroup;
  public entityId$: Observable<number>;
  public responsibles$: Observable<any[]>;
  private _destroy$ = new Subject<void>();

  constructor(
    private dialogRef: DialogRef,
    private store: Store<AppState>,
    private formBuilder: FormBuilder,
    private userService: UserService,
    private loginFromUserService: LoginFromUserService,
  ) {}

  public ngOnInit() {
    this.initForm();
  }

  public ngAfterViewInit() {
    this.loadlisteners();
  }

  public ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

  public onLoginFromUser(responsibleId: number) {
    this.loginFromUserService.with(responsibleId).subscribe(() => this.dialogRef.close());
  }

  public isEmpty(data: any[]): boolean {
    return isEmpty(data);
  }

  private initForm() {
    this.form = this.formBuilder.group({
      turma: undefined,
      busca: undefined,
      qtd: 9999,
    });
  }

  private loadlisteners() {
    const activeUser$ = this.store.select(AppSelectors.ActiveUser).pipe(take(1));
    this.entityId$ = activeUser$.pipe(map(({ entidade_id }) => entidade_id));
    this.responsibles$ = this.form.valueChanges.pipe(
      takeUntil(this._destroy$),
      debounceDelay(),
      switchMap(() => activeUser$),
      switchMap((activeUser) => this.userService.schoolResponsibles(activeUser.entidade_id, this.form.value)),
      map((result) => result.data || result),
      map((data) => data.map(this.transform)),
      startWith(undefined),
    );
  }

  private transform = (data: any): any => ({
    ...data,
    photo: userPhoto(data.photo),
    name: data.name,
    lastName: data.sobrenome,
  });
}
