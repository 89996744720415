import { Activity } from './eclass-activity.model';
import { Attachment } from './eclass-attachment.model';
import { GenericModel } from './generic.model';

export interface DailySummary {
  id: number;
  subject_id: number;
  date_pin: Date;
  group_id: number;
  date: Date;
  subject: SubjectSummary;
  group: GroupSummary;
}

export interface Daily extends DailySummary {
  activities: Activity[];
  user_id: number;
  entity_id: number;
  entity: EntitySummary;
  title: string;
  content: string;
  year: number;
  workload: number;
  schedule: number;
  published: boolean;
  app: number;
  deleted_at: Date;
  created_at: Date;
  updated_at: Date;
  attachments: Attachment[];
  trail_elements?: any[];
}

export interface GroupSummary {
  id: number;
  serie_id: number;
  level_id: number;
  cod_turma: string;
  description: string;
  grade: GenericModel;
}

export interface EntitySummary {
  id: number;
  nome: string;
}
export interface LevelSummary {
  id: number;
  descricao: string;
}

export interface SubjectSummary {
  id: number;
  descricao: string;
  abreviatura: string;
}

export interface DaySummary {
  date_now?: Date;
  dailies: any[];
  groups: GroupSummary[];
  levels: LevelSummary[];
  sistematics: any[];
}
