import { ComponentType, ComponentPortal } from '@angular/cdk/portal';
import { ComponentRef } from '@angular/core';

const Dense = {
  header:
    'tab relative mx-4 bg-white px-4 py-2 shadow-lg shadow-black/40 transition duration-300 group-[.active]:z-40 group-[.active]:py-3',
  content: 'tab__content relative w-full',
};
const Slim = {
  header: 'relative border-b-2 px-3 py-2 group-[.active]:z-40 group-[.active]:border-b-theme-500',
  content: 'border-t-2 mt-[-2px] relative w-full',
};

export const TAB_STYLE = { Dense, Slim };

export interface TabInterface {
  name: string;
  componentClass: ComponentType<TabComponent>;
}

export interface TabComponent {
  onActivate: () => void;
}

export interface ActiveTabInterface {
  name: string;
  portal: ComponentPortal<TabComponent>;
  componentRef: ComponentRef<TabComponent>;
  dispose: () => void;
}
