import { DIALOG_DATA } from '@angular/cdk/dialog';
import { Component, DestroyRef, Inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AssessmentApplicationService } from '@app/core';
import { ReportApiRoutesEnum } from '@app/core/models/report-api-routes.enum';
import { SCORE_CONFIG } from '@app/modules/eclass/components/modal-daily/base';
import { safeEmptyList } from '@app/shared';
import { clone, first, flatMap, uniqBy } from 'lodash';
import { BehaviorSubject } from 'rxjs';

enum ExportType {
  Tct,
  Dicotomizado,
  GabaritoStudents,
  Boletim,
}

@Component({
  selector: 'app-modal-export-file',
  templateUrl: './modal-export-file.component.html',
})
export class ModalExportFileComponent {
  public form: FormGroup;
  public applications$ = new BehaviorSubject<any[]>([]);
  public defaultSubjects$ = new BehaviorSubject<any[]>([]);
  public scoreConfig = {
    ...clone(SCORE_CONFIG),
    suffix: '%',
    allowZero: false,
    precision: 0,
  };

  public options = [
    { id: ExportType.Tct, title: 'TCT' },
    { id: ExportType.Dicotomizado, title: 'Alunos (dicotomizado)' },
    { id: ExportType.GabaritoStudents, title: 'Gabarito' },
    { id: ExportType.Boletim, title: 'Boletim' },
  ];

  private readonly QUERY = {
    [ExportType.Tct]: (applicationUuid: string, data: any) => {
      const modifiedData = { ...data, api_route: ReportApiRoutesEnum.ReportTct };
      return this._assessmentApplicationService.reportExport(applicationUuid, modifiedData, 'tct');
    },
    [ExportType.Dicotomizado]: (applicationUuid: string, data: any) => {
      const modifiedData = { ...data, api_route: ReportApiRoutesEnum.AttemptStudents };
      this._assessmentApplicationService.reportExport(applicationUuid, modifiedData, 'attempt-students');
    },
    [ExportType.GabaritoStudents]: (applicationUuid: string, data: any) => {
      const modifiedData = { ...data, api_route: ReportApiRoutesEnum.AnswerStudents };
      this._assessmentApplicationService.reportExport(applicationUuid, modifiedData, 'gabarito-aluno');
    },
    [ExportType.Boletim]: (applicationUuid: string, data: any) => {
      const modifiedData = { ...data, api_route: ReportApiRoutesEnum.ReportCard };
      this._assessmentApplicationService.reportExport(applicationUuid, modifiedData, 'boletim');
    },
  };

  constructor(
    @Inject(DIALOG_DATA) private _applicationUuid: string,
    private _assessmentApplicationService: AssessmentApplicationService,
    private _formBuilder: FormBuilder,
    private _destroyRef: DestroyRef,
  ) {
    this.initForm();

    this._assessmentApplicationService
      .index()
      .pipe(takeUntilDestroyed(this._destroyRef), safeEmptyList())
      .subscribe((applications) => {
        this.applications$.next(applications);
        this.form.get('uuids').setValue([this._applicationUuid]);
      });
  }

  public get isSubjectEnabled(): boolean {
    return this.form.get('default_subject_id').enabled;
  }

  public onExport() {
    this.form.markAllAsTouched();
    if (this.form.invalid) {
      return;
    }

    const baseUuid = first(this.form.value.uuids);
    const data = {
      min_fill: this.form.value.min_fill,
      default_subject_id: this.form.value.default_subject_id,
      extra_uuids: undefined,
    };

    if (this.form.value.uuids.length > 1) {
      data.extra_uuids = this.form.value.uuids.filter((uuid) => uuid !== baseUuid);
    }

    this.QUERY[this.form.value.export_type](baseUuid, data);
  }

  private initForm() {
    this.form = this._formBuilder.group({
      export_type: [ExportType.Dicotomizado, Validators.required],
      uuids: [undefined, Validators.required],
      default_subject_id: [undefined, Validators.required],
      min_fill: 75,
    });

    this.form
      .get('export_type')
      .valueChanges.subscribe((value) =>
        value === ExportType.Boletim
          ? this.form.get('default_subject_id').disable()
          : this.form.get('default_subject_id').enable(),
      );

    this.form.get('uuids').valueChanges.subscribe(this.setDefaultSubjects);
  }

  private setDefaultSubjects = (uuids) => {
    this.form.get('default_subject_id').setValue(undefined);

    const defaultSubjects = uniqBy(
      flatMap(
        this.applications$.value.filter((a) => uuids.includes(a.uuid)),
        (item) =>
          flatMap(item.avaliacao.questoes_custom, (questao) =>
            flatMap(questao.categories, (category) => category.component.default_subject),
          ),
      ),
      'id',
    );

    this.defaultSubjects$.next(defaultSubjects);
  };
}
