import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { portalConst } from '@app/config';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';

@Injectable({ providedIn: 'root' })
export class TokenService {
  private url = portalConst.api.login;

  private http: HttpClient;

  constructor(public readonly handler: HttpBackend, private api: ApiService) {
    this.http = new HttpClient(this.handler);
  }

  public refresh(token: string): Observable<any> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${token}`,
    });

    return this.http.post<any>(`${this.url}/atualiza-token`, {}, { headers });
  }

  public token(body: any): Observable<any> {
    return this.api.post<any>(`${this.url}/login-from-user`, body);
  }

  public generateTemporaryToken(): Observable<any> {
    return this.api.post<any>(`${this.url}/generate-temporary-token`);
  }
}
