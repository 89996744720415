import { AssessmentLevelContent } from './assessment-level-content.model';
import { AssessmentQuestionType } from './assessment-question-type.enum';

export interface AssessmentQuestion {
  uuid: string;
  avaliacao_id: number;
  parent_uuid: string;
  tipo: AssessmentQuestionType;
  enunciado: string;
  nivel_complexidade: AssessmentLevelContent;
  criado_por: number;
  alterado_por: number;
  entidade_permissao: any[];
  alternativas: AssessmentAlternative[];
  referencia_ano: ReferenceYear;
  sociodemografica: number;
  ia: number;
  validada: number;
  peso?: number;
  code: string;
  referencia: string;
  count?: number;
  categories: [];
  summaries: [];
  resolucao: any;
  compartilhada: boolean;
  questoes_pais: AssessmentQuestionPais;
  nivel_id: number;
  padrao_disciplina_id: number;
}

export interface AssessmentAlternative {
  id: number;
  uuid: string;
  questao_id: number;
  descricao: string;
  alternativa_correta: boolean;
}

export interface ReferenceYear {
  id: number;
  titulo: string;
  ano: number;
  anoId: number;
  pais_id: number;
  referencia: string;
  referenciaId: number;
}
export interface AssessmentQuestionPais {
  pais_id: number;
}
