import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { POPOVER_DATA } from '@app/shared/modules/template/components/popover';

@Component({
  selector: 'app-popover-description-annul-question',
  templateUrl: './popover-description-annul-question.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PopoverDescriptionAnnulQuestionComponent {
  constructor(@Inject(POPOVER_DATA) public data: any) {}
}
