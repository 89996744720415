import { DIALOG_DATA } from '@angular/cdk/dialog';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { AlertDataType, AlertParams } from '@app/core/models';

@Component({
  templateUrl: './alert.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlertComponent {
  public readonly type = AlertDataType;

  constructor(@Inject(DIALOG_DATA) public data: AlertParams) {}

  public isType(type: AlertDataType): boolean {
    return this.data.type === type;
  }

  public get textPositiveButton(): string {
    return this.data.textPositiveButton || 'geral.sim';
  }

  public get textNegativeButton(): string {
    return this.data.textNegativeButton || 'geral.nao';
  }
}
