import { Pipe, PipeTransform } from '@angular/core';
import { AppSelectors } from '@app/store';
import { AppState } from '@app/store/app.state';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Pipe({
  name: 'groupName',
})
export class GroupNamePipe implements PipeTransform {
  constructor(private store: Store<AppState>) {}

  public transform(value: any): Observable<string> {
    return this.store.select(AppSelectors.ActiveUser).pipe(
      map(({ secretaria_sistema_id }) => {
        if (secretaria_sistema_id === 3) {
          return value.descricao;
        }
        return value.cod_turma;
      }),
    );
  }
}
