import { PaisEnum } from '@app/core/models';

export enum DriveType {
  Hispanics,
  Brasil,
  Weekly,
}

export function folderPath(country: PaisEnum, external: boolean): DriveType {
  if (country === PaisEnum.Brasil) {
    return external ? DriveType.Weekly : DriveType.Brasil;
  }
  return DriveType.Hispanics;
}
