import { InjectionToken } from '@angular/core';

import { RequiredAccessArray, RequiredAccessObject } from '@app/shared/utils';
import { Observable } from 'rxjs';
import { AcademicMenu } from './academic-menu';
import { AssessmentsManagerMenu } from './assessments-manager-menu';
import { DashboardMenu } from './dashboard-menu';
import { EclassMenu } from './eclass-menu';
import { EducationalMaterialsMenu } from './educational-materials-menu';
import { FinanceMenu } from './finance-menu';
import { GamesMenu } from './games-menu';
import { LearningTrailsMenu } from './learning-trails-menu';
import { StudentMenu } from './student-menu';

export const MENU = new InjectionToken<MenuRef[]>('menu');
export const SUBMENU = new InjectionToken<SubmenuRef>('submenu');

export interface SubmenuRef extends MenuRef {
  close: () => void;
}

export interface MenuRef {
  readonly identifier: string;
  readonly menu: MenuItem[];
  readonly submenu: MenuItem[];
}

export type MenuItem = () => Menu;
export interface Menu {
  title: string;
  url?: string | string[];
  icon: string;
  group?: string;
  dimension?: string;
  exact?: boolean;
  permission?: RequiredAccessArray | RequiredAccessObject;
  show?: boolean | Observable<boolean>;
  ref?: MenuRef;
  dropdown?: any;
  link_active?: string[];
  click?: () => void;
  redirect?: string;
  submenu?: Menu[];
  ignore?: string | string[];
  defaultUrl?: string | string[];
}

export const MenuProviders = [
  { provide: MENU, useClass: DashboardMenu, multi: true },
  { provide: MENU, useClass: EclassMenu, multi: true },
  { provide: MENU, useClass: AcademicMenu, multi: true },
  { provide: MENU, useClass: StudentMenu, multi: true },
  { provide: MENU, useClass: AssessmentsManagerMenu, multi: true },
  { provide: MENU, useClass: LearningTrailsMenu, multi: true },
  { provide: MENU, useClass: EducationalMaterialsMenu, multi: true },
  { provide: MENU, useClass: GamesMenu, multi: true },
  { provide: MENU, useClass: FinanceMenu, multi: true },
];
