<div class="bg-theme-100 p-5 shadow-inner-bg">
  <div class="flex flex-col gap-2">
    <app-card
      *ngFor="let profile of store.profiles"
      class="border-theme-500 duration-300"
      [ngClass]="{
        'border-l-[6px] bg-theme-50': isActived(profile, store),
        'cursor-pointer hover:bg-theme-50': !isActived(profile, store),
      }"
      (click)="onChange(profile, store)"
    >
      <div app-card-content class="!px-3 !py-2 text-start">
        <span class="font-semibold">{{ profile.name }}</span>
        <div class="text-gray-500">{{ profile.entity }}</div>
      </div>
    </app-card>
  </div>
</div>
