import { Injectable } from '@angular/core';
import { portalConst } from '@app/config';
import { Observable } from 'rxjs';
import { ApiService, Resp } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class LearningTrailsCollectionService {
  constructor(private api: ApiService) {}

  public listCollection(entity: any, category_id: number, showcase: boolean): Observable<Resp<any>> {
    return this.api.get(`${portalConst.api.trilhas}/v1/colecao`, {
      entidade: entity,
      categoria_id: category_id,
      vitrine: showcase,
    });
  }

  public postCollection(data): Observable<Resp<any>> {
    return this.api.post(`${portalConst.api.trilhas}/v1/colecao`, data);
  }

  public updateCollection(data): Observable<any> {
    return this.api.put(`${portalConst.api.trilhas}/v1/colecao/${data.id}`, data);
  }

  public deleteCollection(id): Observable<any> {
    return this.api.delete(`${portalConst.api.trilhas}/v1/colecao/${id}`);
  }
}
