<app-card
  class="h-full w-80 cursor-pointer select-none !rounded-2xl border-2 border-white shadow-lg shadow-black/20 transition duration-300"
  [ngClass]="containerStyle"
  [routerLink]="link"
>
  <div app-card-content class="grid h-full w-full select-none grid-rows-[1fr_min-content] !p-3">
    <div class="flex h-full w-full items-center justify-center bg-gray-300">
      <ng-content select="[app-img]"></ng-content>
    </div>
    <ng-content select="[app-label]"></ng-content>
  </div>
</app-card>
