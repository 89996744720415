import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { environment } from '@env/environment';
import { AllowDashboardGuard, AuthGuard, LoginGuard, PermissionsComponent } from './core/guards';
import { LearningTrailsMenuEnum } from './core/models';
import { LayoutDashboardComponent } from './layouts/layouts/layout-dashboard/layout-dashboard.component';
import { LayoutDocsComponent } from './layouts/layouts/layout-docs/layout-docs.component';
import { LayoutGamesComponent } from './layouts/layouts/layout-games/layout-games.component';
import { LayoutModuleComponent } from './layouts/layouts/layout-module/layout-module.component';
import { Role } from './shared/roles';
import { GuardValidators } from './shared/validations';

const routes: Routes = [
  {
    path: '',
    canActivate: [LoginGuard, AuthGuard],
    component: PermissionsComponent,
    children: [
      //Dashboard
      {
        path: '',
        component: LayoutDashboardComponent,
        children: [
          {
            path: '',
            canActivate: [AllowDashboardGuard],
            data: {
              theme: 'eclass',
              validation: [GuardValidators.Entity],
            },
            loadChildren: () => import('./modules/dashboard/dashboard.module').then((m) => m.DashboardModule),
          },
        ],
      },
      //Modules
      {
        path: '',
        component: LayoutModuleComponent,
        children: [
          { path: '', pathMatch: 'full', redirectTo: 'eclass' },
          {
            path: 'eclass',
            data: {
              theme: 'eclass',
              validation: [GuardValidators.Entity],
              permission: [20, 42],
            },
            loadChildren: () => import('./modules/eclass/eclass.module').then((m) => m.EclassModule),
          },
          {
            path: 'academico',
            data: {
              theme: 'academic',
              breadcrumb: 'breadcrumb.academico.titulo',
              permission: [1, 2],
            },
            loadChildren: () => import('./modules/academic/academic.module').then((m) => m.AcademicModule),
          },
          {
            path: 'aluno',
            data: {
              theme: 'student',
              breadcrumb: 'breadcrumb.aluno.titulo',
              validation: [GuardValidators.Student],
              permission: [1, 3],
            },
            loadChildren: () => import('./modules/student/student.module').then((m) => m.StudentModule),
          },
          {
            path: 'eclass-prova',
            data: {
              theme: 'assessments-manager',
              breadcrumb: 'breadcrumb.assessment-manager.titulo',
              permission: [1, 4],
            },
            loadChildren: () =>
              import('./modules/assessments-manager/assessments-manager.module').then(
                (m) => m.AssessmentsManagerModule,
              ),
          },
          {
            path: 'prova-diagnostica',
            data: {
              theme: 'corporate-university',
              breadcrumb: 'breadcrumb.assessment-manager.titulo',
              permission: [1, 4],
            },
            loadChildren: () =>
              import('./modules/assessments-manager/assessments-manager.module').then(
                (m) => m.AssessmentsManagerModule,
              ),
          },
          {
            path: 'materiais-didaticos',
            data: {
              theme: 'educational-materials',
              breadcrumb: 'breadcrumb.materiais-didaticos.titulo',
              permission: [1, 1],
              role: [Role.educationalMaterials],
            },
            loadChildren: () =>
              import('./modules/educational-materials/educational-materials.module').then(
                (m) => m.EducationalMaterialsModule,
              ),
          },
          {
            path: 'trilhas-de-aprendizagem',
            data: {
              theme: 'learning-trails',
              module: LearningTrailsMenuEnum.trails,
            },
            loadChildren: () =>
              import('./modules/learning-trails/learning-trails.module').then((m) => m.LearningTrailsModule),
          },
          {
            path: 'universidade-corporativa',
            data: {
              theme: 'corporate-university',
              breadcrumb: 'breadcrumb.universidade-corporativa.titulo',
              module: LearningTrailsMenuEnum.uc,
            },
            loadChildren: () =>
              import('./modules/learning-trails/learning-trails.module').then((m) => m.LearningTrailsModule),
          },
          {
            path: 'ivca',
            data: {
              theme: 'ivca',
              breadcrumb: 'breadcrumb.ivca.titulo',
              module: LearningTrailsMenuEnum.ivca,
            },
            loadChildren: () =>
              import('./modules/learning-trails/learning-trails.module').then((m) => m.LearningTrailsModule),
          },
          {
            path: 'financeiro',
            data: {
              theme: 'finance',
              breadcrumb: 'breadcrumb.financeiro.titulo',
              validation: [
                GuardValidators.Student,
                GuardValidators.Maintenance(
                  6,
                  15,
                  31,
                  36,
                  48,
                  54,
                  81,
                  123,
                  141,
                  158,
                  159,
                  161,
                  165,
                  167,
                  168,
                  173,
                  175,
                  181,
                  198,
                  208,
                  224,
                  257,
                  298,
                  302,
                  304,
                  309,
                  319,
                  328,
                  351,
                  355,
                  360,
                  363,
                  406,
                  455,
                  474,
                  839,
                  878,
                  935,
                  956,
                  972,
                ),
              ],
              permission: [1, 7],
              role: [Role.finance],
            },
            loadChildren: () => import('./modules/finance/finance.module').then((m) => m.FinanceModule),
          },
        ],
      },
      // Games
      {
        path: '',
        component: LayoutGamesComponent,
        children: [
          {
            path: 'jogos',
            data: {
              permission: [1, 6],
              theme: 'games',
            },
            loadChildren: () => import('./modules/games/games.module').then((m) => m.GamesModule),
          },
        ],
      },
    ],
  },
  // Docs
  {
    path: '',
    component: LayoutDocsComponent,
    children: [
      {
        path: 'docs',
        canActivate: [() => !environment.production],
        loadChildren: () => import('./docs/home/home.module').then((m) => m.HomeModule),
      },
      {
        path: 'icons',
        canActivate: [() => !environment.production],
        loadChildren: () => import('./docs/icon/icon.module').then((m) => m.IconModule),
      },
      {
        path: 'politica-de-privacidade',
        loadChildren: () => import('./docs/privacy-policy/privacy-policy.module').then((m) => m.PrivacyPolicyModule),
      },
    ],
  },
  { path: '**', pathMatch: 'full', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
