import { NgModule } from '@angular/core';
import { TemplateModule } from '../template/template.module';
import { SelectDefaultGradeComponent } from './select-default-grade.component';

@NgModule({
  declarations: [SelectDefaultGradeComponent],
  imports: [TemplateModule],
  exports: [SelectDefaultGradeComponent],
})
export class SelectDefaultGradeModule {}
