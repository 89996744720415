<app-modal-header class="border-b-2 border-theme-500">
  <div class="flex w-full gap-5 bg-white">
    <div class="flex">
      <svg-icon
        class="w-10 text-theme-500"
        src="assets/icons/icon-gestor-avaliacoes.svg"
        alt="Materiais Didaticos"
      ></svg-icon>
      <h1 class="ml-3 hidden text-sm font-semibold leading-tight text-gray-800 md:grid" translate>
        gestor-avaliacoes.e-class <br />
        <b class="text-lg text-black" translate> gestor-avaliacoes.prova </b>
      </h1>
    </div>
    <hr app-modal-divider />
    <div>
      <div class="flex w-full items-center justify-between pt-2">
        <div class="md:flex">
          <div class="mr-5">
            <span class="text-md text-black" translate> gestor-avaliacoes.importacao-arquivos </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</app-modal-header>
<div app-modal-content class="h-auto w-full md:w-[80vw] lg:w-[65vw] 2xl:w-[40vw]">
  <form [formGroup]="form">
    <div app-form-field class="w-full">
      <label app-label class="font-semibold" translate>gestor-avaliacoes.selecione-arquivo</label>
      <ng-select class="pb-4" app-select formControlName="fileType" placeholder="Selecione o tipo">
        <ng-option [value]="fileType.ProfAlunos">Proficiência alunos</ng-option>
        <ng-option [value]="fileType.TRI">TRI</ng-option>
      </ng-select>
    </div>
  </form>

  <div *ngIf="isFileTypeSelected; else jumbotronTemplate">
    <app-upload-file (addedFile)="onUpload($event)" [config]="config" [componentReset]="resetDropzone$ | async">
    </app-upload-file>
  </div>
  <div
    *ngIf="!!file"
    class="shadow-t-none flex h-[10rem] w-full overflow-auto rounded-lg !rounded-tl-none !rounded-tr-none border border-solid !bg-gray-50 p-2 shadow-inner shadow-black/40"
  >
    <div class="grid w-full gap-4">
      <button
        app-button
        class="border-1 flex w-full justify-start rounded-md bg-white !p-5 shadow-lg transition-all duration-300"
      >
        <div class="flex w-full justify-start">
          <span>{{ file.fileName }}</span>
        </div>
        <div class="flex w-full justify-center">
          <span class="whitespace-nowrap rounded-full bg-orange-300 px-3 text-white shadow-sm">
            <div class="flex h-full items-center gap-2">
              <span class="font-semibold">{{ file.type === 2 ? 'TRI' : 'Proficiência alunos' }}</span>
            </div>
          </span>
        </div>
        <div class="flex w-full justify-end gap-2">
          <svg-icon
            (click)="removeFile()"
            class="h-6 w-6 text-base-status-danger hover:text-red-300"
            src="assets/icons/icon-close.svg"
          ></svg-icon>
        </div>
      </button>
    </div>
  </div>

  <ng-template #jumbotronTemplate>
    <app-card>
      <div app-card-content class="h-[130px]">
        <app-jumbotron class="px-5" translate>gestor-avaliacoes.selecione-arquivo</app-jumbotron>
      </div>
    </app-card>
  </ng-template>

  <div class="mt-5 flex w-full justify-end">
    <button
      app-flat-button
      type="button"
      class="transform bg-theme-500 px-6 text-white shadow-md transition duration-300 ease-in-out hover:bg-theme-500 focus:outline-none focus:ring-2 focus:ring-theme-400 focus:ring-opacity-50"
      (click)="save()"
      [disabled]="(loading$ | async) || !isSaveEnabled"
    >
      <div class="flex items-center justify-center gap-3">
        <span translate>geral.salvar</span>
        <div class="h-5 w-5" *ngIf="loading$ | async">
          <app-skeleton class="flex h-full w-full" baseStyle="bg-gray-400" [showLabel]="false"></app-skeleton>
        </div>
      </div>
    </button>
  </div>
</div>
