import { Dialog } from '@angular/cdk/dialog';
import { Injectable } from '@angular/core';
import { ModalContentsComponent } from '../components/modal-contents/modal-contents.component';
import { ModalFilesComponent } from '../components/modal-files/modal-files.component';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class WebsiteService {
  constructor(private dialog: Dialog) {}

  public openWebSchoolFiles(): Observable<any> {
    return this.dialog.open(ModalFilesComponent).closed;
  }

  public openWebSchoolContent(): Observable<any> {
    return this.dialog.open(ModalContentsComponent).closed;
  }
}
