import { DialogRef } from '@angular/cdk/dialog';
import { Component } from '@angular/core';

@Component({
  selector: 'app-answers-audit-header',
  templateUrl: './answers-audit-header.component.html',
})
export class AnswersAuditHeaderComponent {
  constructor(private _dialogRef: DialogRef) {}
  public onClose() {
    this._dialogRef.close();
  }
}
