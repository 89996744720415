import { Injectable } from '@angular/core';
import { portalConst } from '@app/config';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class IntegrationService {
  constructor(private api: ApiService) {}

  public superPro(req?: any): Observable<any> {
    return this.api.get(`${portalConst.api.usuario}/v1/integra-super-pro`, req);
  }

  public estuda(): Observable<any> {
    return this.api.get(`${portalConst.api.usuario}/v1/integra-estuda`);
  }

  public enem(): Observable<any> {
    return this.api.get(`${portalConst.api.usuario}/v1/integra-estuda`, { isEnem: true });
  }

  public faama(): Observable<any> {
    return this.api.get(`${portalConst.api.integrations}/portal/integra-faama`);
  }
}
