import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  OnDestroy,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { GuardValidationService } from '@app/core';
import { SidenavDashboardComponent } from '@app/modules/dashboard/components/sidenav-dashboard/sidenav-dashboard.component';
import { notNull } from '@app/shared';
import { SidenavData, SidenavGroupComponent } from '@app/shared/modules/template/components/sidenav';
import {
  BehaviorSubject,
  Observable,
  Subject,
  distinctUntilChanged,
  map,
  merge,
  share,
  startWith,
  switchMap,
  tap,
} from 'rxjs';

@Component({
  templateUrl: './layout-dashboard.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [GuardValidationService],
})
export class LayoutDashboardComponent implements AfterViewInit, OnDestroy {
  public validation$: Observable<any>;
  public status$: Observable<SidenavData>;
  private _status$ = new Subject<boolean>();
  private _containerRef$ = new BehaviorSubject<SidenavGroupComponent>(null);

  constructor(
    private destroy: DestroyRef,
    private cdref: ChangeDetectorRef,
    private guardValidationService: GuardValidationService,
  ) {
    this.validation$ = this.guardValidationService.validation$.pipe(takeUntilDestroyed(this.destroy));
  }

  public ngAfterViewInit() {
    const sidenav$ = this._containerRef$.pipe(
      notNull(),
      switchMap((containerRef) => containerRef.start.open$),
    );
    this.status$ = merge(this._status$, sidenav$).pipe(
      takeUntilDestroyed(this.destroy),
      distinctUntilChanged(),
      startWith(false),
      map((opened) => ({ componentRef: SidenavDashboardComponent, opened, closedAnchor: true })),
      share(),
      tap(() => this.cdref.detectChanges()),
    );
  }

  public ngOnDestroy() {
    this._containerRef$.complete();
    this._status$.complete();
  }

  public onAttached(containerRef: SidenavGroupComponent) {
    this._containerRef$.next(containerRef);
  }

  public onSidenavToogle(status) {
    this._status$.next(status);
  }

  public label(validation: string): string {
    return `geral.guards.${validation}`;
  }
}
