<app-modal-header>
  <div class="flex">
    <svg-icon class="hidden w-24 md:block" src="assets/images/logos/logo-eclass.svg"></svg-icon>
    <hr app-modal-divider />
    <h1 translate class="ml-2 flex items-center text-xl font-medium text-base-ea-700">Clube do Golfe</h1>
  </div>
</app-modal-header>
<div app-modal-content class="h-[75vh] w-[70vw] !p-0">
  <div class="flex h-full justify-center p-8">
    <iframe
      [src]="game | safe : 'resourceUrl'"
      frameborder="0"
      class="grid w-full select-none rounded-lg !bg-white"
    ></iframe>
  </div>
</div>
