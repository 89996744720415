import { NgModule } from '@angular/core';
import { UserPhotoComponent } from './user-photo.component';
import { TemplateModule } from '../template/template.module';
import { ImgFallbackModule } from 'ngx-img-fallback';

@NgModule({
  declarations: [UserPhotoComponent],
  imports: [TemplateModule, ImgFallbackModule],
  exports: [UserPhotoComponent],
})
export class UserPhotoModule {}
