<div class="flex h-24 w-full flex-row items-center rounded-lg bg-modules-academic-100 p-3">
  <div class="mx-2 flex h-full items-center rounded-md bg-modules-academic-200 px-4 text-center text-base-gray-400">
    <h1 class="flex flex-col text-2xl font-normal">27 <span class="text-sm">Jul</span></h1>
  </div>
  <div class="flex h-full flex-col overflow-clip">
    <h1 class="mb-1 text-base font-semibold capitalize">Comunicado 1</h1>
    <p class="text-sm font-normal">
      Lorem ipsum dolor sit amet consectetur adipisicing elit. Numquam quas natus possimus voluptate quis cumque,
      pariatur explicabo. Accusantium error, esse provident, tempore perferendis nesciunt, eos earum nemo incidunt nulla
      iure!
    </p>
  </div>
  <div class="ml-auto flex w-1/4">
    <svg-icon class="w-8" src="assets/icons/icon-three-dots.svg"></svg-icon>
  </div>
</div>
