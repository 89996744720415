<app-modal-header class="border-b-2 border-theme-500">
  <div class="flex w-full grid-cols-[150px_auto_1fr_auto_auto] flex-col gap-5 md:grid">
    <div class="flex w-full items-center gap-2">
      <svg-icon
        class="w-10 text-theme-500"
        src="assets/icons/icon-gestor-avaliacoes.svg"
        alt="Materiais Didaticos"
      ></svg-icon>
      <h1 class="text-sm font-semibold leading-tight text-gray-800" translate>
        gestor-avaliacoes.e-class
        <br />
        <b class="text-lg text-black" translate>gestor-avaliacoes.prova</b>
      </h1>
    </div>

    <hr app-modal-divider />

    <div class="mr-5 flex w-full items-center justify-center text-black md:grid md:w-fit">
      <span class="whitespace-nowrap text-lg font-semibold" translate> gestor-avaliacoes.configuracoes-avaliacao </span>
    </div>
  </div>
</app-modal-header>
