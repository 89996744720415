import { portalConst } from '@app/config';

function getVideoUrl(file: any): string {
  const videoId = getVideoId(file);
  if (!file.vimeo_hash) {
    return;
  }
  return `${portalConst.vimeo_url}${videoId}?h=${file.vimeo_hash}&fun=0&title=0&portrait=0&byline=0`;
}

function getVideoId(file: any) {
  let videoId: string;

  if (isNaN(file.file)) {
    const url = file.file.split('/');

    videoId = url.pop();
  } else {
    videoId = file.file;
  }

  return videoId;
}

export const Vimeo = { getVideoId, getVideoUrl };
