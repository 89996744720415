import { DIALOG_DATA } from '@angular/cdk/dialog';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AssessmentApplicationService } from '@app/core/services/assessment-application.service';
import { REDUCED } from '@app/shared/modules/text-editor/config';
import { TranslateService } from '@ngx-translate/core';
import { isNil, uniqBy } from 'lodash';

@Component({
  selector: 'app-modal-create-response-card',
  templateUrl: './modal-create-response-card.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalCreateResponseCardComponent {
  public readonly editorConfigToolbar = REDUCED;
  public form: FormGroup;
  public groups: any[] = [];
  public filteredStudents = [];
  public printResponseCard: any[] = [];
  public searchCtrl = this.formBuilder.control('');

  constructor(
    @Inject(DIALOG_DATA) public data,
    private translate: TranslateService,
    private formBuilder: FormBuilder,
    private _assessmentApplicationService: AssessmentApplicationService,
  ) {
    this.initForm();
    this.groups = uniqBy(
      this.data.dataStudent
        .filter((student) => !isNil(student.turma_descricao))
        .map((student) => ({ id: student.turma_id, descricao: student.turma_descricao })),
      'descricao',
    );
    this.printCard();
  }

  public get totalSelectedStudents(): number {
    return this.filteredStudents.filter((aluno) => aluno.isSelected).length > 0
      ? this.filteredStudents.filter((aluno) => aluno.isSelected).length
      : 1;
  }

  public printCard() {
    this.printResponseCard = [
      {
        id: 'full',
        name: this.translate.instant('gestor-avaliacoes.modelo-cartao-resposta-folha-inteira'),
        icon: 'assets/icons/icon-answer-card-1.svg',
      },
      {
        id: 'regular',
        name: this.translate.instant('gestor-avaliacoes.modelo-cartao-resposta-meia-folha'),
        icon: 'assets/icons/icon-answer-card-2.svg',
      },
      {
        id: 'text',
        name: this.translate.instant('gestor-avaliacoes.modelo-cartao-resposta-folha-redacao'),
        icon: 'assets/icons/icon-answer-card-2.svg',
      },
    ];
  }

  public selected(aluno: any) {
    aluno.isSelected = !aluno.isSelected;
  }

  public print() {
    this.form.markAllAsTouched();
    const selectedStudents = this.filteredStudents.filter((aluno) => aluno.isSelected);

    const data = {
      text_instruction: this.form.value.text_instruction,
      students:
        selectedStudents.length > 0
          ? selectedStudents.map((aluno) =>
              JSON.stringify({
                usuario_id: aluno.usuario_id,
                nome: aluno.nome,
                turma_id: aluno.turma_id,
              }),
            )
          : undefined,
    };

    if (this.form.valid) {
      if (this.form.get('modelo')?.value === 'full') {
        this._assessmentApplicationService.sendFullLeaf(this.data.application_uuid, data);
      } else if (this.form.get('modelo')?.value === 'regular') {
        this._assessmentApplicationService.sendHalfLeaf(this.data.application_uuid, data);
      } else {
        this._assessmentApplicationService.sendTextLeaf(this.data.application_uuid, data);
      }
    }
  }

  public markAll(selecionar: boolean) {
    this.filteredStudents = this.filteredStudents.map((aluno) => ({
      ...aluno,
      isSelected: selecionar,
    }));
  }

  private initForm() {
    this.form = this.formBuilder.group({
      turmas: undefined,
      modelo: [undefined, Validators.required],
      text_instruction: undefined,
      busca: undefined,
    });
    this.filterStudents('');

    this.form.get('turmas')?.valueChanges.subscribe((turmaId) => {
      this.form.get('busca')?.setValue('');
      this.filterStudents('');
    });

    this.form.get('busca')?.valueChanges.subscribe((search) => {
      this.filterStudents(search);
    });
  }

  private filterStudents(search: string) {
    if (!this.form.get('turmas')?.value) {
      this.filteredStudents = this.data.dataStudent
        .filter((aluno) => aluno.nome?.toLowerCase().includes(search))
        .map((aluno) => ({ ...aluno, isSelected: false }));
      return;
    }
    this.filteredStudents = this.data.dataStudent
      .filter(
        (aluno) =>
          aluno.turma_id === this.form.get('turmas')?.value && aluno.nome.toLowerCase().includes(search.toLowerCase()),
      )
      .map((aluno) => ({ ...aluno, isSelected: false }));
  }
}
