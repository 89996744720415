import { CdkAccordion } from '@angular/cdk/accordion';
import { ChangeDetectionStrategy, Component, InjectionToken } from '@angular/core';

export const ACCORDION = new InjectionToken<AccordionComponent>('CdkAccordion');
@Component({
  selector: 'app-accordion',
  templateUrl: './accordion.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { class: 'relative' },
  providers: [{ provide: ACCORDION, useExisting: AccordionComponent }],
})
export class AccordionComponent extends CdkAccordion {}
