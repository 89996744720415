import { Injectable } from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { ActivatedRouteSnapshot, NavigationEnd, Router } from '@angular/router';
import { AppSelectors } from '@app/store';
import { AppState } from '@app/store/app.state';
import { Store } from '@ngrx/store';
import { isEqual } from 'lodash';
import { Observable, combineLatest, distinctUntilChanged, filter, map, startWith } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GuardValidationService {
  public validation$: Observable<ValidationErrors>;

  constructor(private store: Store<AppState>, private router: Router) {
    const store$ = this.store.select(AppSelectors.appFeature);
    const route$ = this.route();
    this.validation$ = combineLatest([route$, store$]).pipe(map(([route, store]) => this.resolver(route, store)));
  }

  private route(): Observable<ActivatedRouteSnapshot> {
    return this.router.events.pipe(
      distinctUntilChanged(isEqual),
      filter((event) => event instanceof NavigationEnd),
      map(() => this.router.routerState.snapshot.root),
      startWith(this.router.routerState.snapshot.root),
    );
  }

  private resolver = (route: ActivatedRouteSnapshot, store: AppState): ValidationErrors => {
    if (!route) {
      return null;
    }
    if (route?.routeConfig?.data?.validation) {
      const validations: any[] = route?.routeConfig?.data?.validation || [];
      const error = validations.map((validation) => validation(store)).find((res) => !!res);
      if (error) {
        return error;
      }
    }
    return this.resolver(route.firstChild, store);
  };
}
