<div app-modal-content class="w-[80vw] !p-0 !pb-3">
  <app-modal-header>
    <div class="flex">
      <div
        class="h-16 w-16 flex-shrink-0 self-start rounded-tl-lg bg-green-700 p-2 text-base-status-warning [box-shadow:0px_2px_4px_0px_rgba(0,_0,_0,_0.15)]"
      >
        <svg-icon src="assets/icons/icon-new-course.svg"></svg-icon>
      </div>
      <hr app-modal-divider />
      <div class="flex items-center gap-2">
        <h1 class="text-lg">
          {{ data.data.title }}
        </h1>
      </div>
    </div>
  </app-modal-header>
  <div class="h-[80vh] w-full overflow-auto p-5">
    <app-preview-certificado [data]="data.data"></app-preview-certificado>
  </div>
</div>
