import { Injectable } from '@angular/core';
import { MenuItem, MenuRef } from './menu';

@Injectable({ providedIn: 'root' })
export class DashboardMenu implements MenuRef {
  public identifier = 'dashboard';

  public readonly menu: MenuItem[] = [
    () => ({
      title: 'menu.painel',
      url: '/',
      icon: 'icon-home',
      dimension: 'h-8 w-8',
      permission: [20, 42],
      exact: true,
    }),
  ];

  public readonly submenu: MenuItem[] = [];
}
