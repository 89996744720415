import { Editor } from '@ckeditor/ckeditor5-core';
import Plugin from '@ckeditor/ckeditor5-core/src/plugin';
import ButtonView from '@ckeditor/ckeditor5-ui/src/button/buttonview';

const icon =
  '<svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><g clip-path="url(#a)"><path d="M7.85 16.643V14.98h1.637l3.356-3.856-3.356-3.91H7.434l-1.97 10.152c-.147.832-.462 1.475-.943 1.928-.48.453-1.1.68-1.858.68s-1.392-.223-1.9-.666c-.509-.444-.763-1-.763-1.665 0-.5.139-.901.416-1.206.277-.306.638-.458 1.082-.458.388 0 .707.11.957.333.25.222.374.517.374.887 0 .204-.041.393-.124.569a1.134 1.134 0 0 1-.347.43c.074.037.152.065.236.083.083.019.17.028.263.028.24 0 .444-.116.61-.347.167-.231.287-.531.361-.901l1.914-9.848H2.247V5.548h3.8l.583-2.94c.166-.833.499-1.475.998-1.928.5-.453 1.12-.68 1.859-.68.758 0 1.382.222 1.872.666.49.444.735.998.735 1.664 0 .5-.138.902-.416 1.207-.277.305-.638.457-1.082.457-.37 0-.684-.106-.943-.319a1.04 1.04 0 0 1-.388-.846c0-.185.037-.365.11-.54.075-.176.167-.32.278-.43a.628.628 0 0 0-.388-.139c-.24 0-.449.11-.624.333-.176.222-.3.508-.375.86l-.499 2.635h5.243v1.664h-1.304l2.302 2.663 2.164-2.663H14.84V5.548H20v1.664h-1.609l-3.329 3.911 3.33 3.856H20v1.665h-5.16v-1.665h1.332l-2.22-2.58-2.274 2.58h1.332v1.665H7.85Z" fill="currentColor"/></g><defs><clipPath><path fill="currentColor" d="M0 0h20v20H0z"/></clipPath></defs></svg>';

export default class MathPlugin extends Plugin {
  static get pluginName() {
    return 'MathPlugin';
  }

  init() {
    const editor = this.editor;

    Object.assign(window.CKEDITOR_TRANSLATIONS['pt-br'].dictionary, {
      'Math Label': 'Fórmula matemática',
    });
    Object.assign(window.CKEDITOR_TRANSLATIONS['es'].dictionary, {
      'Math Label': 'Fórmula matemática',
    });
    Object.assign(window.CKEDITOR_TRANSLATIONS['en'].dictionary, {
      'Math Label': 'Math Formula',
    });

    editor.ui.componentFactory.add(MathPlugin.pluginName, () => {
      const button = new ButtonView();

      button.set({
        label: editor.t('Math Label'),
        withText: false,
        tooltip: true,
        icon,
      });

      button.on('execute', () => !!this.upload && this.upload(editor));

      return button;
    });
  }

  upload?(editor: Editor): void;
}
