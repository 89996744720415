<app-card class="h-full">
  <div app-card-header class="rounded-t-lg bg-modules-educational-materials-500 !py-6">
    <div class="flex w-full flex-row items-center justify-between">
      <h1 class="text-lg uppercase text-white" translate>menu.materiais-didaticos</h1>
      <svg-icon class="flex h-5 w-5 text-white" src="assets/icons/icon-library.svg"></svg-icon>
    </div>
  </div>
  <div app-card-content class="h-full !px-0">
    <ng-container *ngIf="{ load: loading$ | async, books: books$ | async } as data">
      <ng-container *ngIf="!data.load; else loadTemplate">
        <ng-container *ngIf="!(data.books | isEmpty); else emptyTemplate">
          <app-horizontal-scroll class="!absolute bottom-5 h-[calc(100%_-_5rem)] w-full">
            <div class="flex h-full w-fit gap-2 px-5">
              <app-card-list
                class="pb-5"
                *ngFor="let book of data.books"
                [link]="['materiais-didaticos/' + book.slug]"
                containerStyle="hover:border-modules-educational-materials-500"
              >
                <img app-img class="h-full max-h-[18rem] select-none" [src]="book.image" />
                <div app-label class="flex flex-col justify-center truncate text-center">
                  <h4 class="truncate pt-4 text-sm font-bold text-base-whisper-900">{{ book.title }}</h4>
                  <h5 class="truncate text-base-whisper-900">{{ book.fasciculo }}</h5>
                </div>
              </app-card-list>
            </div>
          </app-horizontal-scroll>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>

  <ng-template #loadTemplate>
    <app-horizontal-scroll class="!absolute bottom-5 h-[calc(100%_-_5rem)] w-full">
      <div class="flex h-full w-fit gap-2 px-5">
        <app-skeleton class="!w-80"></app-skeleton>
        <app-skeleton class="!w-80"></app-skeleton>
        <app-skeleton class="!w-80"></app-skeleton>
      </div>
    </app-horizontal-scroll>
  </ng-template>

  <ng-template #emptyTemplate>
    <div class="h-full w-full px-5 pt-5">
      <app-jumbotron translate>geral.filtro-vazio</app-jumbotron>
    </div>
  </ng-template>
</app-card>
