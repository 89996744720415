import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AppSelectors } from '@app/store';
import { AppState } from '@app/store/app.state';
import { environment } from '@env/environment';
import { Store } from '@ngrx/store';
import { isEmpty, isEqual } from 'lodash';
import { combineLatest } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';

declare const gtag: any;

@Injectable({
  providedIn: 'root',
})
export class StatisticService {
  constructor(private router: Router, private store: Store<AppState>) {}

  public startAnalytics() {
    if (environment.env !== 'local') {
      this.googleAnalytics();
    }
  }

  private googleAnalytics() {
    const user$ = this.store.select(AppSelectors.appFeature).pipe(
      filter(({ usuario_ativo }) => !!usuario_ativo),
      map((store) => ({
        user: store.usuario_ativo.usuario_id,
        function: store.funcao_padrao,
        entity: store.usuario_ativo.entidade_id,
        association: store.usuario_ativo.campo_id,
        union: store.usuario_ativo.uniao_id,
        publisher: store.usuario_ativo.editora_id,
        division: store.usuario_ativo.divisao_id,
        plus: !isEmpty(store.usuario_ativo.produtos),
        external: !!store.usuario_ativo.escola_externa,
      })),
      distinctUntilChanged(isEqual),
    );
    const router$ = this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map((event: any) => event.urlAfterRedirects),
      distinctUntilChanged(isEqual),
    );
    combineLatest([user$, router$])
      .pipe(map(([user, page_path]) => ({ ...user, page_path })))
      .subscribe((data) => gtag('event', 'page_view', data));
  }
}
