import { ChangeDetectorRef, Directive, Input, OnDestroy, TemplateRef, ViewContainerRef } from '@angular/core';
import { AppSelectors } from '@app/store';
import { AppState } from '@app/store/app.state';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, Subject, combineLatest } from 'rxjs';
import { map, switchMap, takeUntil } from 'rxjs/operators';
import { RequiredAccessArray, RequiredAccessObject, notNull } from '../utils';

export type ResolverTemplate = [RequiredAccessObject, TemplateRef<any>, TemplateRef<any>];
@Directive({
  selector: '[isAllow]',
})
export class IsAllowDirective implements OnDestroy {
  private _requiredAcess$ = new BehaviorSubject<RequiredAccessObject>(null);
  private _thenTemplate$ = new BehaviorSubject<TemplateRef<any>>(null);
  private _elseTemplate$ = new BehaviorSubject<TemplateRef<any>>(null);
  private _destroy$ = new Subject<void>();

  constructor(
    private cdref: ChangeDetectorRef,
    private store: Store<AppState>,
    private templateRef: TemplateRef<any>,
    private _viewContainer: ViewContainerRef,
  ) {
    const access$ = this._requiredAcess$.pipe(notNull());
    combineLatest([access$, this._thenTemplate$, this._elseTemplate$])
      .pipe(takeUntil(this._destroy$), switchMap(this.resolverTemplate))
      .subscribe(this.updateView);
  }

  @Input()
  public set isAllow(requiredAccess: RequiredAccessArray | RequiredAccessObject) {
    const safePolice = this.transform(requiredAccess);
    this._requiredAcess$.next(safePolice);
    this._thenTemplate$.next(this.templateRef);
  }

  @Input()
  public set isAllowElse(templateRef: TemplateRef<any> | null) {
    this._elseTemplate$.next(templateRef);
  }

  public ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

  private transform(requiredAccess: RequiredAccessArray | RequiredAccessObject): RequiredAccessObject | null {
    if (requiredAccess instanceof Array) {
      const [moduleId, elementoId, operacao] = requiredAccess;
      return { moduleId, elementoId, operacao: operacao || 'listar' };
    }
    return { ...requiredAccess, operacao: requiredAccess.operacao || 'listar' };
  }

  private resolverTemplate = ([access, thenTemplate, elseTemplate]: ResolverTemplate): Observable<any> =>
    this.store.select(AppSelectors.isAllow(access)).pipe(map((allow) => (allow ? thenTemplate : elseTemplate)));

  private updateView = (templateRef: TemplateRef<any> | null = null) => {
    this._viewContainer.clear();
    if (templateRef) {
      this._viewContainer.createEmbeddedView(templateRef);
    }
    this.cdref.markForCheck();
  };
}
