<ng-container *ngIf="{ load: loading$ | async } as data">
  <app-modal-header class="border-b-2 border-theme-500">
    <div class="flex w-full items-center gap-2">
      <div class="flex">
        <svg-icon
          class="w-10 text-theme-500"
          src="assets/icons/icon-gestor-avaliacoes.svg"
          alt="Materiais Didaticos"
        ></svg-icon>
        <h1 class="ml-3 text-sm font-semibold leading-tight text-gray-800" translate>
          gestor-avaliacoes.e-class
          <br />
          <b class="text-lg text-black" translate> gestor-avaliacoes.prova </b>
        </h1>
      </div>

      <hr app-modal-divider />
      <h1 class="text-lg" translate>gestor-avaliacoes.historico-alteracoes</h1>
    </div>
  </app-modal-header>

  <div
    app-modal-content
    class="h-[43rem] w-[30rem] overflow-y-auto !rounded-none md:h-[30rem] md:w-[60rem] md:p-4 lg:w-[70rem]"
  >
    <ng-container *ngIf="!!data.load; else dataTemplate"><app-skeleton></app-skeleton></ng-container>
    <ng-template #dataTemplate>
      <ng-container *ngIf="!!logs.length; else emptyTemplate">
        <table app-table>
          <thead>
            <tr>
              <th app-table-cell translate>gestor-avaliacoes.acao</th>
              <th app-table-cell translate>geral.usuario</th>
              <th app-table-cell translate>geral.data</th>
            </tr>
          </thead>
          <tbody app-table-body>
            <ng-container *ngFor="let log of logs">
              <tr>
                <td class="text-center" app-table-cell>
                  <span class="font-semibold">{{ onLabelAction(log.action) }}</span>
                </td>
                <td class="text-center" app-table-cell>
                  <span class="font-semibold">{{ log.user_name }}</span>
                </td>
                <td class="text-center" app-table-cell>
                  <span class="font-semibold">
                    {{ log.date | date : 'dd/MM/yyyy HH:mm:ss' }}
                  </span>
                </td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </ng-container>
    </ng-template>
  </div>
</ng-container>
<hr app-divider class="my-5" />
<div app-card-footer class="flex gap-1">
  <button dialogClose app-button class="bg-red-500 text-theme-contrast" translate>geral.fechar</button>
</div>
<ng-template #emptyTemplate
  ><app-jumbotron>
    <span class="mb-3" translate>geral.nenhum-registro</span>
  </app-jumbotron></ng-template
>
