import { AfterContentInit, ChangeDetectionStrategy, Component, ElementRef, ViewChild } from '@angular/core';

const MAX_STEP = 500;
const SCROLL_STEP = 0.1;

@Component({
  selector: 'app-horizontal-scroll',
  templateUrl: './horizontal-scroll.component.html',
  host: { class: 'relative flex flex-nowrap' },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HorizontalScrollComponent implements AfterContentInit {
  @ViewChild('content', { static: true, read: ElementRef }) private element: ElementRef<HTMLElement>;
  public isAtScrollEndLeft = false;
  public isAtScrollEndRight = false;

  public get isEnableScroll() {
    const element = this.element.nativeElement;
    return element.scrollWidth > element.offsetWidth;
  }

  public ngAfterContentInit() {
    this.addScrollListener();
  }

  private addScrollListener() {
    this.element.nativeElement.addEventListener('scroll', () => this.handleScroll());
  }

  private handleScroll() {
    const element = this.element.nativeElement;
    const maxScrollLeft = element.scrollWidth - element.offsetWidth;

    this.isAtScrollEndLeft = element.scrollLeft === 0;
    this.isAtScrollEndRight = element.scrollLeft >= maxScrollLeft;
  }

  public onScrollLeft() {
    const element = this.element.nativeElement;
    element.scrollLeft -= this.scrollStep(element);
    this.handleScroll();
  }

  public onScrollRight() {
    const element = this.element.nativeElement;
    element.scrollLeft += this.scrollStep(element);
    this.handleScroll();
  }

  private scrollStep(element: HTMLElement) {
    const step = element.scrollWidth * SCROLL_STEP;
    if (step > MAX_STEP) {
      return MAX_STEP;
    }
    return element.scrollWidth * SCROLL_STEP;
  }
}
