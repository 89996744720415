import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit,
  forwardRef,
} from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Popover, Strategy } from '../popover';
import { DatepickerComponent, DatepickerData } from './datepicker.component';
import { endOfDay, isDate, startOfDay, max } from 'date-fns';
import { filter } from 'rxjs/operators';
import { safeDate } from '@app/shared/utils';

@Component({
  selector: 'app-input-datepicker',
  templateUrl: './input-datepicker.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputDatepickerComponent),
      multi: true,
    },
  ],
  host: { class: 'relative' },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputDatepickerComponent implements ControlValueAccessor, OnInit {
  @Input() public placeholder = '00/00/0000';
  @Input() public showTime = false;
  @Input() public minDate: Date;
  @Input() public maxDate: Date;
  @Input() public weekend = true;
  @Input() public clearable = true;
  @Input() public defaultDateStartOfDay = false;
  public ctrl = new FormControl();

  constructor(private popover: Popover, private cdref: ChangeDetectorRef) {}

  public ngOnInit(): void {
    if (this.showTime) {
      this.placeholder = '00/00/0000 00:00';
    }
  }

  public writeValue(data: any) {
    this.ctrl.setValue(data);
    this.cdref.detectChanges();
  }

  public setDisabledState?(isDisabled: boolean) {
    if (isDisabled) {
      this.ctrl.disable();
    } else {
      this.ctrl.enable();
    }
  }

  public onClear() {
    this.changed(null);
    this.ctrl.setValue(null);
    this.onTouched();
    this.cdref.markForCheck();
  }

  public registerOnChange(fn: any) {
    this.changed = fn;
  }

  public registerOnTouched(fn: any) {
    this.touched = fn;
  }

  public onDatepicker(elementRef: ElementRef) {
    const minDate = safeDate(this.minDate);
    const maxDate = safeDate(this.maxDate);
    this.popover
      .open<Date, DatepickerData>(DatepickerComponent, {
        positionStrategy: [Strategy.TOP, Strategy.BOTTOM],
        elementRef,
        data: {
          showTime: this.showTime,
          date: this.ctrl.value,
          minDate: !!minDate && startOfDay(minDate),
          maxDate: !!maxDate && endOfDay(maxDate),
          weekend: this.weekend,
          defaultDateStartOfDay: this.defaultDateStartOfDay,
        },
      })
      .closed.pipe(filter(isDate))
      .subscribe((data) => {
        this.onTouched();
        this.ctrl.setValue(data);
        this.changed(data);
        this.cdref.markForCheck();
      });
  }

  private onTouched() {
    this.touched(true);
    this.ctrl.markAsTouched();
  }

  private changed = (_: any) => true;
  private touched = (_: any) => true;
}
