import { Injectable } from '@angular/core';
import { portalConst } from '@app/config';
import { Observable } from 'rxjs';
import { StudentSummary } from '../models';
import { ApiService, Resp } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class StudentService {
  private url = portalConst.api.usuario;

  constructor(private api: ApiService) {}

  public getStudentsByEntity(entityId: number, req?: any): Observable<Resp<StudentSummary[]>> {
    return this.api.get<StudentSummary[]>(`${this.url}/v2/entity/${entityId}/students`, req);
  }

  public getStudents(data: any) {
    return this.api.get<any[]>(`${this.url}/v2/student`, data);
  }

  public updateResponsibleData(data: any) {
    return this.api.post<any[]>(`${this.url}/v2/update-responsible-data`, data);
  }
}
