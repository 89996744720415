import { AfterViewInit, ChangeDetectionStrategy, Component, DestroyRef, Input, OnDestroy, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Category } from '@app/core/models';
import { DriveService } from '@app/core/services';
import { debounceDelay, notNull, safeEmptyList, switchDelay } from '@app/shared/utils/operators';
import { AppSelectors } from '@app/store';
import { AppState } from '@app/store/app.state';
import { Store } from '@ngrx/store';
import { saveAs } from 'file-saver';
import { last } from 'lodash';
import { BehaviorSubject, Observable, Subject, combineLatest } from 'rxjs';
import { distinctUntilChanged, map, startWith, switchMap, tap } from 'rxjs/operators';
import { BreadcrumbFolderService } from '../modal-drive/breadcrumb-folder.service';

export const PREFIX = 'escola/driver';
@Component({
  selector: 'app-table-storage',
  templateUrl: './table-storage.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableStorageComponent implements OnInit, AfterViewInit, OnDestroy {
  public storage$: Observable<any>;
  public data$: Observable<any>;
  private _load$ = new Subject();
  private _refresh$ = new BehaviorSubject<string>(null);
  private _analytic$ = new BehaviorSubject<string>(null);
  private readonly extensoes = ['pdf', 'jpg', 'mp4', 'png'];

  constructor(
    private _destroy: DestroyRef,
    private _store: Store<AppState>,
    private driveService: DriveService,
    private breadcrumbFolderService: BreadcrumbFolderService,
  ) {}

  @Input()
  public set category(data: Category) {
    const path = `${PREFIX}/${data.path}`;
    this._refresh$.next(path);
    this.breadcrumbFolderService.breadcrumb({ ...data, path });
  }

  public ngOnInit() {
    this.loadData();
  }

  public ngAfterViewInit() {
    this.loadListeners();
  }

  public ngOnDestroy() {
    this._load$.complete();
    this._analytic$.complete();
    this._refresh$.complete();
  }

  public nameElement(path: string): string {
    return last(path.split('/'));
  }

  public color(extension: string): string {
    return extension === 'pasta' ? 'bg-blue-400' : 'bg-green-500';
  }

  public onView(element: any, external: boolean) {
    if (element.extensao === 'pasta') {
      this._refresh$.next(element.caminho);
      this.breadcrumbFolderService.append(element.caminho);
    } else {
      this.download(element, external);
    }
  }

  private loadData() {
    const load$ = this._load$.pipe(switchDelay());
    const storage$ = this.loadStorage();
    this.data$ = combineLatest({ load: load$, storage: storage$ }).pipe(
      map(({ load, storage }) => ({ load, ...storage })),
      startWith({ load: true, storage: [], external: false }),
    );
  }

  private loadListeners() {
    this.breadcrumbListeners();
    this.analyticsListeners();
  }

  private breadcrumbListeners() {
    this.breadcrumbFolderService.navigate$
      .pipe(takeUntilDestroyed(this._destroy), distinctUntilChanged())
      .subscribe((path) => this._refresh$.next(path));
  }

  private analyticsListeners() {
    this._analytic$
      .pipe(
        notNull(),
        debounceDelay(),
        distinctUntilChanged(),
        switchMap((filePath) => this.driveService.accessControl({ filePath })),
      )
      .subscribe();
  }

  private loadStorage(): Observable<any> {
    const external$ = this._store.select(AppSelectors.isExternal).pipe(takeUntilDestroyed(this._destroy));
    const refresh$ = this._refresh$.pipe(notNull(), distinctUntilChanged(), debounceDelay());

    return combineLatest([external$, refresh$]).pipe(
      takeUntilDestroyed(this._destroy),
      tap(() => this._load$.next(true)),
      switchMap(([external, path]) =>
        this.driveService.files({ nome_pasta: path, external }).pipe(
          safeEmptyList(),
          map((storage) => ({ storage, external })),
        ),
      ),
      tap(() => this._load$.next(false)),
    );
  }

  private download(element: any, external: boolean) {
    this.analyticsDownload(element, external);
    if (this.extensoes.includes(element.extensao)) {
      window.open(element.url, '_blank');
    } else {
      saveAs(element.url, this.nameElement(element.caminho));
    }
  }

  private analyticsDownload(element: any, external: boolean) {
    if (external) {
      this._analytic$.next(element.caminho);
    }
  }
}
