import { Injectable } from '@angular/core';
import { storageKeys } from '@app/config';
import { Checker, ENTITY_KEY, EntityIdentifier, compareEntity, safeEmptyList } from '@app/shared/utils';
import { AppActions } from '@app/store';
import { AppState } from '@app/store/app.state';
import { Store } from '@ngrx/store';
import { cloneDeep, flattenDeep, head } from 'lodash';
import { LocalStorageService } from 'ngx-webstorage';
import { firstValueFrom, lastValueFrom } from 'rxjs';
import { LoginService } from './login.service';
import { PoliciesService } from './policies.service';
import { UserService } from './user.service';
import { I18nService } from './i18n.service';
import { BimesterSchoolService } from './bimester-school.service';

export interface UnidadeIdentificador {
  aluno_id?: number;
  entidade_id?: number;
  campo_id?: number;
  uniao_id?: number;
  editora_id?: number;
  divisao_id?: number;
  escola_aluno: any[];
}

export interface StorageData {
  token: string;
  access_token?: string;
  funcaoId?: number;
  dependenteId?: number;
  unidade?: UnidadeIdentificador;
}

interface UpdateTokenData {
  cpf: string;
  usuarioAtivo: any;
  funcao_padrao: number;
  dependente_padrao?: number;
}

interface UsuarioAtivoData {
  funcoes: any[];
  funcaoId?: number;
  dependenteId?: number;
  unidade?: any;
}

@Injectable({
  providedIn: 'root',
})
export class LoggedUserService {
  constructor(
    private store: Store<AppState>,
    private i18nService: I18nService,
    private userService: UserService,
    private loginService: LoginService,
    private policiesService: PoliciesService,
    private localStorageService: LocalStorageService,
    private bimesterSchoolService: BimesterSchoolService,
  ) {}

  public async build(data: any): Promise<AppState> {
    const { unidade, profileId, dependentId, ...user } = cloneDeep(data);
    //função padrão
    if (profileId) {
      user.funcao_padrao = await this.funcaoPadrao(profileId, user);
    }

    //Dependente padrão
    if (Checker.isResposible(user.funcao_padrao)) {
      const funcaoPadrao = user.funcoes.find((data: any) => data.funcao_id === user.funcao_padrao);

      user.dependente_padrao = await this.dependente(dependentId, funcaoPadrao, user);
      user.dependentes = funcaoPadrao.escola_aluno;
      user.grupos = this.grupos(user);
    }

    Checker.isInvalidResponsavel(user);

    // Usuário Ativo
    user.usuario_ativo = await this.usuarioAtivo({
      funcaoId: user.funcao_padrao,
      unidade,
      funcoes: user.funcoes,
      dependenteId: user.dependente_padrao,
      ...user,
    });

    // Token Atualizado
    user.token = await this.token({
      cpf: user?.perfil?.cpf,
      usuarioAtivo: user?.usuario_ativo,
      funcao_padrao: user?.funcao_padrao,
      dependente_padrao: user?.dependente_padrao,
    });

    // Policies
    user.policies = await this.policies({
      sistema_id: user?.usuario_ativo.secretaria_sistema_id,
      funcao_id: user?.funcao_padrao,
      escola_id: user?.usuario_ativo.entidade_id,
    });

    this.i18nService.change(user.usuario_ativo.lingua);

    return await user;
  }

  private async funcaoPadrao(profileId: number, user: any): Promise<number> {
    const funcaoPadrao = profileId || user.funcao_padrao || head<any>(user.funcoes).funcao_id;

    return firstValueFrom(this.userService.setFuncao(funcaoPadrao)).then(({ funcao_id }) => funcao_id);
  }

  private async dependente(dependentId: number, funcaoPadrao: any, user: any): Promise<number> {
    const dependentePadrao = dependentId || user.dependente_padrao || head<any>(funcaoPadrao?.escola_aluno)?.aluno_id;

    if (dependentePadrao) {
      const dependente = firstValueFrom(this.userService.setDependente(dependentePadrao));

      return dependente.then(({ dependente_id }) => dependente_id);
    }
    return null;
  }

  private async token(data?: UpdateTokenData): Promise<any> {
    const params = {
      cpf: data?.cpf,
      dependente_padrao: data?.dependente_padrao,
      funcao_padrao: data?.funcao_padrao,
      entidade: data?.usuarioAtivo?.entidade,
      cod_escola: data?.usuarioAtivo?.cod_escola,
      ra: data?.usuarioAtivo?.ra,
      sistema: data?.usuarioAtivo?.secretaria_sistema_id,
    };
    const token = await firstValueFrom(this.loginService.updateToken(params)).then((res) => res.token);

    this.store.dispatch(AppActions.TokenEffect({ token }));
    return await token;
  }

  private async policies(params: any): Promise<any> {
    return await firstValueFrom(this.policiesService.get(params)).then((res) => res?.data);
  }

  private grupos(user: any): any {
    const grupos = user.dependentes.filter((dependente: any) => dependente.aluno_id === user.dependente_padrao);
    return head(grupos.grupos);
  }

  private async usuarioAtivo(data: UsuarioAtivoData) {
    const user = await this.safeUsuarioAtivo(data);
    if (!!user?.entidade_id) {
      const bimesters$ = this.bimesterSchoolService.lastsYearsEntity(user.entidade_id).pipe(safeEmptyList());
      user.bimestres = await firstValueFrom(bimesters$);
    }
    return user;
  }

  private async safeUsuarioAtivo(data: UsuarioAtivoData) {
    if (Checker.isResposible(data.funcaoId)) {
      const funcoes = data.funcoes
        .filter(({ funcao_id }) => funcao_id === data.funcaoId)
        .map(({ escola_aluno }) => escola_aluno);
      return flattenDeep(funcoes).find((escola: any) => escola.aluno_id === data.dependenteId);
    }
    const standard = this.standard(data);
    const entity = this.extractEntity(data.unidade) || (await this.storage(standard));
    const safeEntity = this.safeEntity(entity, standard);
    this.saveStorage(entity ?? safeEntity);
    return {
      ...standard,
      ...(entity ?? safeEntity),
      ...this.name(entity ?? safeEntity),
    };
  }

  private standard(data: UsuarioAtivoData): any {
    const entities = data?.funcoes.filter(({ funcao_id }) => funcao_id === data.funcaoId);
    if (!!data.unidade?.editora_id) {
      const entity = entities.find((entity) => entity.editora_id === data.unidade.editora_id);
      return !!entity ? this.extractEntity(entity) : this.extractEntity(head(entities));
    }
    return this.extractEntity(head(entities));
  }

  private name(entity: any) {
    if (entity?.lingua?.sigla) {
      return {
        lingua: entity.lingua.sigla,
        escola_externa: entity.externo,
        nome_escola: entity.nome,
      };
    }
    return {
      lingua: entity.lingua,
      escola_externa: !!entity.escola_externa,
      nome_escola: entity.nome_escola,
    };
  }

  private async storage(profile: any) {
    const storage = this.localStorageService.retrieve(storageKeys.ENTITY);
    const key = storage ? [ENTITY_KEY.find((k) => !!profile[k])] : [];
    if (compareEntity(storage, profile, key)) {
      const entities = await lastValueFrom(this.userService.entidadeContexto(storage));
      return entities.find((entity) => ENTITY_KEY.every((k) => entity[k] === storage[k]));
    }
    if (storage) {
      const entities = await lastValueFrom(this.userService.entidadeContexto(storage));
      const k = ENTITY_KEY.find((entity) => !!entity);
      return entities.find((entity) => entity[k] === storage[k]);
    }
    return null;
  }

  private saveStorage(identifier: EntityIdentifier) {
    const entity = ENTITY_KEY.reduce((p, k) => ({ ...p, [k]: identifier[k] }), {});
    this.store.dispatch(AppActions.EntityEffect({ entity }));
  }

  private extractEntity(profile: any) {
    if (profile?.escola_aluno) {
      const [entity] = profile?.escola_aluno || [];
      return entity;
    }
    return profile;
  }

  private safeEntity(entity: any, standard: any) {
    const key = ENTITY_KEY.find((k) => !!standard[k]);
    if (!!entity && compareEntity(entity, standard, [key])) {
      return entity;
    }
    return standard;
  }
}
