import { Injectable } from '@angular/core';
import { portalConst } from '@app/config';
import { Observable } from 'rxjs';
import { FinanceDocument } from '../models';
import { ApiService, Resp } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class FilesService {
  private url = `${portalConst.api.school}/v1/portal/files`;

  constructor(private api: ApiService) {}

  public contract(entityId: number): Observable<Resp<FinanceDocument>> {
    return this.api.get<FinanceDocument>(`${this.url}/contract/${entityId}`);
  }

  public codeOfEthics(entityId: number): Observable<Resp<FinanceDocument>> {
    return this.api.get<FinanceDocument>(`${this.url}/code-ethics/${entityId}`);
  }

  public dormitoryContract(entityId: number): Observable<Resp<FinanceDocument>> {
    return this.api.get<FinanceDocument>(`${this.url}/dormitory-contract/${entityId}`);
  }

  public dormitoryCodeOfEthics(entityId: number): Observable<Resp<FinanceDocument>> {
    return this.api.get<FinanceDocument>(`${this.url}/dormitory-code-ethics/${entityId}`);
  }

  public listFiles(entityId: number, functionId: number, req?: any): Observable<any> {
    return this.api.get(`${this.url}/school-site/${entityId}/${functionId}`, req);
  }

  public loadCategory(entity_id: any): Observable<any> {
    return this.api.get<any>(`${portalConst.api.school}/v1/hub-educacional/school/categories-filter`, { entity_id });
  }
}
