import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AssessmentApplicationService, NotificationService } from '@app/core';
import { AssessmentAnnulTypeEnum } from '@app/core/models';
import { notNull } from '@app/shared';
import { POPOVER_DATA, PopoverRef } from '@app/shared/modules/template/components/popover';
import { map } from 'rxjs';

@Component({
  selector: 'app-popover-annul-question',
  templateUrl: './popover-annul-question.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PopoverAnnulQuestionComponent implements OnDestroy {
  public form: FormGroup;
  public assessmentAnnulTypeEnum = AssessmentAnnulTypeEnum;

  constructor(
    @Inject(POPOVER_DATA) public data: any,
    private _popoverRef: PopoverRef<any>,
    private _gdaReportService: AssessmentApplicationService,
    private _notificationService: NotificationService,
    private _cdRef: ChangeDetectorRef,
    private _formBuilder: FormBuilder,
    private _assessmentApplicationService: AssessmentApplicationService,
  ) {
    this.initFormAnnulment();
  }

  public ngOnDestroy(): void {
    this._popoverRef.close();
  }

  public onAnnul() {
    if (this.form.get('type').value === AssessmentAnnulTypeEnum.Answer) {
      this.anullStudentQuestion();
    } else {
      this.annulQuestion();
    }
  }

  private anullStudentQuestion() {
    this._assessmentApplicationService
      .printedCorrection(this.data.tentativa_uuid, this.data.question.uuid, this.form.value)
      .pipe(
        notNull(),
        map((res) => (!!res?.ret ? res.data : undefined)),
      )
      .subscribe((data: any) => {
        this.processAnnullationData(data);
      });
  }

  private annulQuestion() {
    this._gdaReportService
      .annulQuestion(this.data.aplicacao_uuid, this.data.question.uuid, this.form.value)
      .pipe(
        notNull(),
        map((res) => (!!res?.ret ? res.data : undefined)),
      )
      .subscribe((data: any) => {
        this.processAnnullationData(data);
      });
  }

  private processAnnullationData(data) {
    const selectedType = this.form.get('type').value;
    this._notificationService.success('Questão anulada com sucesso!');
    this._popoverRef.close(selectedType === 2 ? data : this.form.value);
    this._cdRef.markForCheck();
  }

  public onOpenAnnulmentOptions() {
    this.form.reset({ type: 1, anulado: 1 });
    this.form.markAsPristine();
  }

  private initFormAnnulment() {
    this.form = this._formBuilder.group({
      type: this.data.type,
      anulado_motivo: undefined,
      anulado: 1,
    });
  }
}
