import { ChangeDetectionStrategy, Component, DestroyRef, EventEmitter, Input, Output } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AssessmentApplicationService } from '@app/core';
import { ApplicationStatusExecutionEnum } from '@app/core/models';
import { notNull, safeEmptyList, selectedEntityActive } from '@app/shared';
import { AppSelectors } from '@app/store';
import { AppState } from '@app/store/app.state';
import { Store } from '@ngrx/store';
import { isAfter, isBefore } from 'date-fns';
import { first, isNil, last, uniqBy } from 'lodash';
import { BehaviorSubject, switchMap, take } from 'rxjs';

@Component({
  selector: 'app-tab-organization',
  templateUrl: './tab-organization.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabOrganizationComponent {
  @Output() public activateStudentTab = new EventEmitter();
  public readonly applicationStatusExecutionEnum = ApplicationStatusExecutionEnum;
  public application$ = new BehaviorSubject<any>(undefined);
  public isOpen: boolean = false;
  public data$ = new BehaviorSubject<any[]>(undefined);

  constructor(
    private _assessmentApplicationService: AssessmentApplicationService,
    private _store: Store<AppState>,
    private _destroyRef: DestroyRef,
  ) {}

  @Input()
  public set application(value) {
    this.application$.next(value);
    this._store
      .select(AppSelectors.ActiveUser)
      .pipe(
        takeUntilDestroyed(this._destroyRef),
        notNull(),
        switchMap((activeUser) =>
          this._assessmentApplicationService
            .getReportEntity(value.uuid, { entidade: first(selectedEntityActive(activeUser)) })
            .pipe(take(1), safeEmptyList()),
        ),
      )
      .subscribe((data: any) => {
        data = data.map((item) => {
          item.data_inicio = first(item.datas?.map((data) => data.data_inicio)?.sort((a, b) => a - b));
          item.data_fim = last(item.datas?.map((data) => data.data_fim)?.sort((a, b) => a - b));
          item.status = undefined;
          if (!!item.datas.length) {
            if (item.datas.every((date) => isBefore(new Date(), new Date(date.data_inicio)))) {
              item.status = ApplicationStatusExecutionEnum.Scheduled;
            } else if (
              item.datas.some(
                (date) =>
                  isAfter(new Date(), new Date(date.data_inicio)) && isBefore(new Date(), new Date(date.data_fim)),
              )
            ) {
              item.status = ApplicationStatusExecutionEnum.InProgress;
            } else if (item.datas.every((date) => isAfter(new Date(), new Date(date.data_fim)))) {
              item.status = ApplicationStatusExecutionEnum.Closed;
            }
          }
          return item;
        });

        const allFields = uniqBy(
          data.map((item) => {
            const entities = data.filter((entity) => item.campo_id === entity.campo_id);
            const status = this.getStatus(entities);
            const tentativas = entities.reduce((accumulator, entity: any) => accumulator + (entity.tentativas || 0), 0);
            const totalPublicoAlvo = entities.reduce(
              (accumulator, entity: any) => accumulator + (entity.total_publico_alvo || 0),
              0,
            );
            const totalTaxaAcertos = entities.reduce(
              (accumulator, entity: any) => accumulator + (entity.taxa_acertos || 0),
              0,
            );
            const mediaTaxaAcertos = entities.length > 0 ? Math.trunc(totalTaxaAcertos / entities.length) : 0;
            const percent = !!tentativas ? Math.trunc((tentativas * 100) / totalPublicoAlvo) : 0;
            return {
              ...item.campo,
              entities,
              status,
              tentativas,
              total_publico_alvo: totalPublicoAlvo,
              percent,
              mediaTaxaAcertos,
            };
          }),
          'id',
        );
        const allUnions = uniqBy(
          data.map((item) => {
            const fields = allFields.filter((field: any) => item.uniao_id === field.uniao_id);
            const status = this.getStatus(fields);
            const tentativas = fields.reduce((accumulator, field: any) => accumulator + (field.tentativas || 0), 0);
            const totalPublicoAlvo = fields.reduce(
              (accumulator, field: any) => accumulator + (field.total_publico_alvo || 0),
              0,
            );
            const totalMediaTaxaAcertos = fields.reduce(
              (accumulator, field: any) => accumulator + (field.mediaTaxaAcertos || 0),
              0,
            );
            const mediaTaxaAcertosUnion = fields.length > 0 ? Math.trunc(totalMediaTaxaAcertos / fields.length) : 0;
            const percent = !!tentativas ? Math.trunc((tentativas * 100) / totalPublicoAlvo) : 0;
            return {
              ...item.uniao,
              fields,
              status,
              tentativas,
              total_publico_alvo: totalPublicoAlvo,
              percent,
              mediaTaxaAcertosUnion,
            };
          }),
          'id',
        );

        this.data$.next(allUnions);
      });
  }

  public get tentativas(): number {
    return this.data$.value?.reduce((value, union) => value + union.tentativas, 0);
  }

  public get totalPublicoAlvo(): number {
    return this.data$.value?.reduce((value, union) => value + union.total_publico_alvo, 0);
  }

  public isNil(value) {
    return isNil(value);
  }

  public onSchoolClick(entityId: number) {
    this.activateStudentTab.emit(entityId);
  }

  private getStatus(items: any): ApplicationStatusExecutionEnum | undefined {
    let status = undefined;
    if (items.every((entity) => entity.status === undefined)) {
      status = undefined;
    } else if (items.every((entity) => [ApplicationStatusExecutionEnum.Closed, undefined].includes(entity.status))) {
      status = ApplicationStatusExecutionEnum.Closed;
    } else if (items.some((entity) => entity.status === ApplicationStatusExecutionEnum.InProgress)) {
      status = ApplicationStatusExecutionEnum.InProgress;
    } else if (items.every((entity) => [ApplicationStatusExecutionEnum.Scheduled, undefined].includes(entity.status))) {
      status = ApplicationStatusExecutionEnum.Scheduled;
    }

    return status;
  }
}
