<div class="relative h-full w-full pb-5">
  <div class="group/card relative h-full w-full" *ngIf="token$ | async as token">
    <ng-container *ngIf="!isShowcase; else showcase">
      <div class="locked group/voucher cursor-pointer" (click)="onOpenModal()" *ngIf="!course.enable">
        <div class="absolute z-10 h-full w-full rounded-2xl bg-gray-500 pb-5 opacity-50"></div>
        <div class="absolute top-1/2 z-20 flex w-full -translate-y-1/2 items-center justify-center px-5">
          <svg-icon
            src="assets/icons/icon-lock.svg"
            class="block h-20 w-20 text-white transition duration-300 group-hover/voucher:scale-125"
          ></svg-icon>
        </div>
      </div>
      <div class="opacity-0 group-hover/card:opacity-100" *ngIf="course.emite_certificado && course.enable">
        <div class="absolute z-10 h-full w-full rounded-2xl bg-theme-950 opacity-50"></div>
        <div class="absolute z-20 flex h-full w-full flex-col items-center justify-center">
          <button
            type="button"
            (click)="withdrawCertificate(course.evento.id, course.slug, token)"
            translate
            class="mx-5 my-2 w-[85%] rounded-lg bg-[#003249] px-5 py-2 text-theme-50"
          >
            {{ 'trilhas.certificado.imprimir-certificado' | translate }}
          </button>
          <button
            type="button"
            (click)="course?.enable || isShowcase ? onOpenCourse(course) : {}"
            translate
            class="mx-5 my-2 w-[85%] rounded-lg bg-[#003249] px-5 py-2 text-theme-50"
          >
            trilhas.visualizar-curso
          </button>
        </div>
      </div>
    </ng-container>
    <ng-template #showcase>
      <div class="opacity-0 group-hover/card:opacity-100">
        <div class="absolute z-10 h-full w-full rounded-2xl bg-theme-950 opacity-50"></div>
        <div class="absolute z-20 flex h-full w-full flex-col items-center justify-center">
          <button
            (click)="onOpenCourse(course)"
            translate
            class="mx-5 my-2 w-[85%] rounded-lg bg-[#003249] px-5 py-2 text-theme-50"
          >
            trilhas.visualizar-curso
          </button>
          <!-- *isAllow="[19, 31]" -->
          <button
            *isAllow="[18, 53]"
            type="button"
            (click)="onOpenEvents()"
            translate
            class="mx-5 my-2 w-[85%] rounded-lg bg-[#003249] px-5 py-2 text-theme-50"
          >
            trilhas.evento.gerenciar
          </button>
          <button
            *ngIf="course.relatorio"
            translate
            class="mx-5 my-2 w-[85%] rounded-lg bg-[#003249] px-5 py-2 text-theme-50"
            (click)="onOpenReportTemp(course.slug, token)"
          >
            trilhas.acompanhar-relatorio-vitrine
          </button>
        </div>
      </div>
    </ng-template>

    <div class="relative flex h-full w-full cursor-pointer flex-col items-center">
      <app-card
        [ngClass]="{ 'cursor-pointer': course.enable || isShowcase }"
        class="h-full w-80 cursor-pointer select-none !rounded-2xl border-2 border-white shadow-lg shadow-black/20 transition duration-300 hover:border-modules-learning-trails-500"
        (click)="course?.enable || isShowcase ? onOpenCourse(course) : {}"
      >
        <div app-card-content class="grid h-full w-full select-none grid-rows-[1fr_min-content] !p-3">
          <div class="flex h-full w-full items-center justify-center !rounded-2xl bg-gray-300">
            <img
              app-img
              class="h-[218px] w-full select-none !rounded-2xl"
              [src]="course.thumbnail"
              onerror="src='assets/images/default.png'"
            />
          </div>
          <div app-label class="flex flex-col justify-center truncate text-center">
            <div
              *ngIf="!isShowcase"
              class="mt-3 h-1.5 w-full rounded-full bg-gray-200"
              app-tooltip
              tooltip="{{ course.progresso }}%"
            >
              <div
                class="h-1.5 rounded-full"
                [style.width.%]="course.progresso"
                [ngClass]="{
                  'bg-[#f59e0b]': course.progresso <= 30,
                  'bg-blue-500': course.progresso >= 31 && course.progresso <= 90,
                  'bg-green-500': course.progresso >= 91
                }"
              ></div>
            </div>
            <div class="flex w-full items-center justify-between gap-2 truncate pt-4">
              <h4 class="truncate text-sm font-bold text-base-whisper-900">
                {{ course.titulo }}
              </h4>
            </div>
          </div>
        </div>
      </app-card>
    </div>
  </div>
</div>
