import { Directive } from '@angular/core';

@Directive({
  selector: '[app-icon-button]',
  host: {
    class:
      'relative inline-flex cursor-pointer select-none items-center justify-center rounded-lg p-2 p-2 outline-none hover:brightness-110 active:brightness-85 disabled:pointer-events-none disabled:cursor-not-allowed disabled:opacity-75 disabled:brightness-100',
  },
})
export class IconButtonDirective {}
