import { Attachment } from './eclass-attachment.model';

export interface Feedback {
  id: number;
  answer_id: string;
  parent_id: number;
  type: TypeFeedbackEnum;
  description: string;
  student: number;
  user_id: number;
  status: number;
  viewed_at: string;
  deleted_at: string;
  created_at: string;
  updated_at: string;
  attachments: Attachment[];
  user?: any;
}

export enum TypeFeedbackEnum {
  Text = 1,
  Audio = 2,
  File = 3,
}
