import { NgModule } from '@angular/core';
import { TemplateModule } from '../template/template.module';
import { SelectGroupComponent } from './select-group.component';

@NgModule({
  declarations: [SelectGroupComponent],
  imports: [TemplateModule],
  exports: [SelectGroupComponent],
})
export class SelectGroupModule {}
