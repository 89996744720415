<app-modal-header>
  <div class="flex flex-grow items-center">
    <div class="flex items-center">
      <svg-icon src="assets/icons/icon-megaphone-2.svg" class="my-1 w-8 text-amber-500"></svg-icon>
      <p translate class="hidden pl-3 text-lg font-semibold text-amber-500 md:block">geral.comunicados</p>
    </div>
    <hr app-modal-divider class="border-left-[1px] m-0 mx-5 h-full border border-solid border-zinc-100 lg:block" />
    <p class="whitespace-nowrap px-1 text-lg font-medium">{{ data.communication.title }}</p>
  </div>
</app-modal-header>
<div app-modal-content class="h-[70vh] w-[80vw] overflow-y-scroll !p-0">
  <div class="flex h-auto min-h-full w-full flex-col bg-gray-100 p-8">
    <div class="mb-6">
      <div class="flex justify-between">
        <div>
          <p><b translate>geral.data-geral</b> {{ data.communication.date | date : 'dd/MM/yyyy' }}</p>
          <p *ngIf="data.communication.author"><b translate>geral.autor-geral</b> {{ data.communication.author }}</p>
        </div>
        <a
          *ngIf="!!data.communication?.attachment"
          class="flex items-center rounded-lg border p-2 text-theme-500 outline-none hover:bg-theme-500 hover:text-theme-contrast md:border-solid md:border-theme-500 md:px-4"
          (click)="onDownload(data.communication.attachment)"
        >
          <svg-icon class="mr-2 flex h-6 w-6" src="assets/icons/icon-download.svg"></svg-icon>
          <span class="uppercase" translate>eclass.arquivo</span>
        </a>
      </div>
    </div>
    <div class="mb-6 flex flex-wrap items-center">
      <span
        *ngFor="let grouping of data.communication.communication_groupings"
        class="mb-2 mr-2 whitespace-nowrap rounded-full bg-base-ea-400 px-4 text-white"
      >
        {{ grouping.descricao }}
      </span>
    </div>
    <app-text-editor
      *ngIf="thereText(data.communication.description)"
      readOnly="true"
      [formControl]="editor"
      [value]="data.communication.description"
    ></app-text-editor>
    <div class="flex flex-1">
      <app-file-viewer
        class="w-full"
        *ngIf="!!data.communication.attachment_id"
        [data]="dataViewer$ | async"
      ></app-file-viewer>
    </div>
  </div>
</div>
