<app-modal-header (click)="onPrevent($event)">
  <div class="flex items-end gap-2">
    <svg-icon class="h-9 w-9" src="assets/icons/icon-math-formula.svg"></svg-icon>
    <p class="hidden text-lg font-semibold text-gray-700 lg:block" translate>geral.math</p>
  </div>
</app-modal-header>

<div app-modal-content (click)="onPrevent($event)" class="w-[90vw] lg:w-[50vw]">
  <div class="mb-10 rounded-2xl border px-2 py-4 shadow-inner-bg" #mathField></div>
  <div class="grid grid-cols-4 gap-4">
    <button
      *ngFor="let item of tips"
      app-outline-button
      class="ML__preview h-32 border-theme-500 text-theme-500 hover:bg-theme-500 hover:text-theme-contrast"
      type="button"
      (click)="onTip(item.latex)"
      [innerHTML]="item.html | safe : 'html'"
    ></button>
  </div>
</div>
<div app-modal-footer (click)="onPrevent($event)" class="flex-col-reverse items-end justify-between gap-2 md:flex-row">
  <button
    app-outline-button
    class="h-fit w-48 border-theme-500 uppercase text-theme-500 hover:bg-theme-500 hover:text-theme-contrast"
    type="button"
    dialogClose
  >
    {{ 'geral.cancelar' | translate }}
  </button>
  <button
    *ngIf="{ submitted: submitted$ | async } as action"
    app-outline-button
    class="w-48 gap-3 bg-theme-500 uppercase text-theme-contrast"
    type="button"
    (click)="onSubmit()"
    [disabled]="action.submitted"
  >
    <ng-container *ngIf="action.submitted; else elseTemplate">
      <app-skeleton class="!h-5 !w-5" baseStyle="bg-theme-500" [showLabel]="false"></app-skeleton>
    </ng-container>
    <ng-template #elseTemplate>
      <svg-icon class="flex h-5 w-5 text-white" src="assets/icons/icon-plus.svg"></svg-icon>
    </ng-template>
    {{ 'geral.adicionar' | translate }}
  </button>
</div>
