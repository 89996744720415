export enum PaisEnum {
  Brasil = 1,
  Argentina = 2,
  Uruguai = 3,
  Paraguai = 4,
  Bolivia = 5,
  Chile = 6,
  Peru = 7,
  Equador = 8,
}
