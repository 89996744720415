import { Component, ContentChild, Input } from '@angular/core';
import { FormControlDirective, FormControlName, NgControl, Validators } from '@angular/forms';
import { isNil } from 'lodash';
import { ErrorDirective } from './error.directive';

@Component({
  selector: 'app-form-field',
  templateUrl: './form-field.component.html',
  styleUrls: ['./form-field.component.scss'],
})
export class FormFieldComponent {
  @Input() public showError: boolean = null;
  @Input() public required: boolean = null;
  @ContentChild(FormControlName, { static: false }) public controlName: FormControlName;
  @ContentChild(FormControlDirective, { static: false }) public formControl: FormControlDirective;
  @ContentChild(ErrorDirective, { static: false }) public error: ErrorDirective;

  public get control(): NgControl {
    return this.controlName || this.formControl;
  }

  public get showInvalid(): boolean {
    if (isNil(this.showError)) {
      return this.control?.invalid && this.control?.touched;
    }
    return this.showError;
  }

  public get isRequired(): boolean {
    if (isNil(this.required)) {
      return this.control?.control.hasValidator(Validators.required);
    }
    return this.required;
  }

  public label(error: string): string {
    return `geral.erros.${error}`;
  }
}
