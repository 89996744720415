import { Injectable } from '@angular/core';
import { notNull } from '@app/shared/utils';
import { isEqual } from 'lodash';
import { BehaviorSubject, Observable, distinctUntilChanged } from 'rxjs';
import { Menu } from '../menu';

@Injectable({
  providedIn: 'root',
})
export class MenuService {
  public submenu$: Observable<Menu[]>;
  private _submenu$ = new BehaviorSubject<Menu[]>([]);

  constructor() {
    this.submenu$ = this._submenu$.asObservable().pipe(notNull(), distinctUntilChanged(isEqual));
  }

  public menu(submenu: Menu[]) {
    this._submenu$.next(submenu);
  }
}
