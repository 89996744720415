import { Pipe, PipeTransform } from '@angular/core';
import { isEmpty } from 'lodash';

@Pipe({
  name: 'noEmpty',
})
export class NoEmptyPipe implements PipeTransform {
  public transform(value: any): boolean {
    return !isEmpty(value);
  }
}
