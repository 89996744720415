import { ActivityTypeEnum } from './activity-type.enum';
import { Daily } from './daily.model';
import { Answer } from './eclass-answer.model';
import { Attachment } from './eclass-attachment.model';
import { StudentSummary } from './student.model';

export interface ActivitySummary {
  id: string;
  daily_id: number;
  date: Date;
  date_start: Date;
  date_end: Date;
  group_id: number;
  group_name: string;
  limit: boolean;
  score: number;
  subject: string;
  subject_id: number;
  title: string;
  type: ActivityTypeEnum;
}

export interface Activity extends ActivitySummary {
  user_id: number;
  scale_id: number;
  description: string;
  record: number;
  evaluative: number;
  academic_id: number;
  app: number;
  daily?: Daily;
  deleted_at: string;
  created_at: string;
  updated_at: string;
  scale: any;
  answers: Answer[];
  attachments: Attachment[];
  published: boolean;
  students: StudentSummary[];
  completed: number;
  total: number;
  trail_elements?: any[];
}
