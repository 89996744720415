<app-popover>
  <div>
    <button
      app-icon-button
      type="button"
      class="mb-2 w-full font-semibold hover:bg-modules-sky-english-500 hover:text-white"
      (click)="onDownloadObservationReport(data.report, 0)"
      translate
    >
      servicos.sky-english.relatorio-geral
    </button>
    <button
      app-icon-button
      type="button"
      class="w-full font-semibold hover:bg-modules-sky-english-500 hover:text-white"
      (click)="onDownloadObservationReport(data.report, 1)"
      translate
    >
      servicos.sky-english.relatorio-professor
    </button>
  </div>
</app-popover>
