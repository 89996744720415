import { Injectable } from '@angular/core';
import { portalConst } from '@app/config';
import { Collection } from '@app/modules/learning-trails/models';
import { Observable } from 'rxjs';
import { ApiService, Resp } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class LearningTrailsCollectionService {
  constructor(private api: ApiService) {}

  public listCollection(entity: any, category_id: number, showcase: boolean): Observable<Resp<any>> {
    return this.api.get(`${portalConst.api.trilhas}/v1/colecao`, {
      entidade: entity,
      categoria_id: category_id,
      vitrine: showcase,
    });
  }

  public postCollection(data: any): Observable<any> {
    return this.api.post(`${portalConst.api.trilhas}/v1/colecao`, data);
  }

  public updateCollection(data: any): Observable<any> {
    return this.api.put(`${portalConst.api.trilhas}/v1/colecao/${data.id}`, data);
  }

  public deleteCollection(id: number): Observable<any> {
    return this.api.delete(`${portalConst.api.trilhas}/v1/colecao/${id}`);
  }

  public highlightCollection(id: number): Observable<Resp<Collection>> {
    return this.api.patch(`${portalConst.api.trilhas}/v1/destacar-colecao/${id}`);
  }
}
