import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { SIDENAV_REF, SidenavRef } from './sidenav';

@Component({
  selector: 'app-sidenav-header',
  templateUrl: './sidenav-header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidenavHeaderComponent {
  constructor(@Inject(SIDENAV_REF) private sidenavRef: SidenavRef) {}

  public onClose() {
    this.sidenavRef.close();
  }
}
