import { Dialog } from '@angular/cdk/dialog';
import { Component, DestroyRef, ElementRef, Input, OnDestroy } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl } from '@angular/forms';
import { AssessmentApplicationService } from '@app/core';
import {
  AssessmentApplicationMode,
  AssessmentManagedType,
  AssessmentStatusAttemptEnum,
  AssessmentWeightType,
  Entidade,
  FunctionsEnum,
} from '@app/core/models';
import { TipoEntidadeEnum } from '@app/core/models/tipo-entidade.enum';
import { ModalCreateResponseCardComponent } from '@app/modules/assessments-manager/shared/modal-create-response-card/modal-create-response-card.component';
import { ModalUploadFilesComponent } from '@app/modules/assessments-manager/shared/modal-upload-files/modal-upload-files.component';
import { debounceDelay, notNull, selectedEntityActive } from '@app/shared';
import { Popover, Strategy } from '@app/shared/modules/template/components/popover';
import { AppSelectors } from '@app/store';
import { AppState } from '@app/store/app.state';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { differenceInMinutes, format, isAfter, parse } from 'date-fns';
import { first, isEqual, isNil, orderBy, uniqBy } from 'lodash';
import { BehaviorSubject, combineLatest, distinctUntilChanged, Observable, take } from 'rxjs';
import { ModalAnswersAuditComponent } from '../../../modal-answers-audit/modal-answers-audit.component';
import { ModalAnswersComponent } from '../../../modal-answers/modal-answers.component';
import { ModalAssessmentsMirrorComponent } from '../../../modal-assessments-mirror/modal-assessments-mirror.component';
import { ModalAssessmentsPrintSettingsComponent } from '../../../modal-assessments-print-settings/modal-assessments-print-settings.component';
import { ModalReleaseStudentsComponent } from '../../../modal-release-students/modal-release-students.component';
import { ModalScaleProficienciaComponent } from '../../../modal-scale-proficiencia/modal-scale-proficiencia.component';
import { ModalAssessmentsImportFileComponent } from '../modal-assessments-import-file/modal-assessments-import-file.component';
import { ModalExportFileComponent } from '../modal-export-file/modal-export-file.component';
import { PopoverPrintOptionsComponent } from '../popover-print-options/popover-print-options.component';

interface StudentDate {
  usuario_id: number;
  nome: string;
  turma_id: number;
  turma_descricao: string;
  liberar_data_inicio: Date;
  liberar_data_fim: Date;
  data_inicio: Date;
  data_fim: Date;
  tentativa_uuid: string;
  nota_calculada: number;
  qtd_questoes_respondidas: number;
  qtd_acertos: number;
  status: AssessmentStatusAttemptEnum;
  foto: string;
  tentativa_questoes: any[];
  isSelected: boolean;
}

@Component({
  selector: 'app-tab-student',
  templateUrl: './tab-student.component.html',
})
export class TabStudentComponent implements OnDestroy {
  public readonly assessmentApplicationMode = AssessmentApplicationMode;
  public readonly assessmentStatusAttemptEnum = AssessmentStatusAttemptEnum;
  public readonly tipoEntidadeEnum = TipoEntidadeEnum;
  public readonly strategy = Strategy;
  public application$ = new BehaviorSubject<any>(undefined);
  public dataStudent$ = new BehaviorSubject<any[]>([]);
  public groups: any[] = [];
  public originalStudents: any[] = [];
  public popoverResult$: Observable<any>;
  public loading$ = new BehaviorSubject<boolean>(false);
  public data$ = new BehaviorSubject<any>(undefined);
  public hasSelected: boolean;
  public isPreview: boolean;
  public searchStudentNameCtrl = new FormControl();
  public searchGroupCtrl = new FormControl();
  public entities$ = new BehaviorSubject<any>([]);
  public entityIdCtrl = new FormControl(undefined);
  public hasEntity = new BehaviorSubject<boolean>(false);
  public defaultFunction: number = 0;
  public entityActive: Entidade;
  public canPreview = false;
  private _activeUser$ = new BehaviorSubject<any>(undefined);

  constructor(
    private _dialog: Dialog,
    private _popover: Popover,
    private _destroyRef: DestroyRef,
    private _store: Store<AppState>,
    private _translateService: TranslateService,
    private _gdaReportService: AssessmentApplicationService,
  ) {
    this._store
      .select(AppSelectors.ActiveUser)
      .pipe(takeUntilDestroyed(this._destroyRef), notNull(), distinctUntilChanged(isEqual))
      .subscribe((activeUser) => {
        this._activeUser$.next(activeUser);
        this.entityActive = first(selectedEntityActive(activeUser));
      });

    this._store
      .select(AppSelectors.DefaultFunction)
      .pipe(takeUntilDestroyed(this._destroyRef), notNull(), distinctUntilChanged(isEqual))
      .subscribe((defaultFunction) => {
        this.defaultFunction = defaultFunction;
      });

    this.entityIdCtrl.valueChanges
      .pipe(takeUntilDestroyed(this._destroyRef), notNull())
      .subscribe(() => this.loadData());

    this.searchStudentNameCtrl.valueChanges.pipe(debounceDelay(300)).subscribe(() => this.applyFilters());
    this.searchGroupCtrl.valueChanges.pipe(debounceDelay(300)).subscribe(() => this.applyFilters());
  }

  @Input() public set application(value: any) {
    if (!value) return;

    this.application$.next(value);

    this.entities$.next(
      uniqBy(
        value.publico_alvo.map((target) => target.entidade),
        'id',
      ),
    );

    if (this.entities$.value.length === 1) {
      this.entityIdCtrl.setValue((first(this.entities$.value) as any).id);
    }

    this.application$.pipe(takeUntilDestroyed(this._destroyRef), notNull(), take(1)).subscribe(() => {
      combineLatest([
        this._store.select(AppSelectors.ActiveUser).pipe(takeUntilDestroyed(this._destroyRef), notNull()),
        this._store
          .select(AppSelectors.DefaultFunction)
          .pipe(takeUntilDestroyed(this._destroyRef), notNull(), distinctUntilChanged(isEqual)),
      ]).subscribe(([activeUser, defaultFunction]) => {
        this.entityActive = first(selectedEntityActive(activeUser));
        this.canPreview =
          this.application$.value.previewer ||
          this.application$.value.criado_por === activeUser.usuario_id ||
          this.permission() ||
          [FunctionsEnum.desenvolvedorPortal].includes(defaultFunction);
      });
    });
  }

  @Input() public set entityId(value) {
    this.entityIdCtrl.setValue(value);
  }

  public get isOnline(): boolean {
    return this.application$.value.modo_aplicacao === AssessmentApplicationMode.Online;
  }

  public get isEntity(): boolean {
    return this.entityActive?.tipo === 1;
  }

  public get isPreviewer(): boolean {
    return !!this.application$.value.previewer;
  }

  public get studentChecked() {
    return Object.values(this.dataStudent$.value || {})
      .flat()
      .filter((student) => student.isSelected).length;
  }

  public get isDivision(): boolean {
    return this.entityActive?.tipo === 5;
  }

  public get qtdQuestao(): number {
    return this.data$.value.qtd_questao;
  }

  public get assesmentWeightType(): boolean {
    return this.data$.value.avaliacao.peso_tipo === AssessmentWeightType.Peso;
  }

  public get isSchool(): boolean {
    return this.entityActive.tipo === this.tipoEntidadeEnum.Escola;
  }

  public formattedNecessidadesEspeciais(necessidades_especiais): string {
    return necessidades_especiais.map((necessidade) => necessidade.descricao).join('\n');
  }

  public isNil(note) {
    return isNil(note);
  }

  public get canViewEscalaProficiencia(): boolean {
    return (
      this.isPAAEB &&
      (!this.application$.value.data_resultado ||
        (!!this.application$.value.data_resultado &&
          isAfter(new Date(), parse(this.application$.value.data_resultado, 'yyyy-MM-dd HH:mm:ss', new Date()))))
    );
  }

  public get isPAAEB(): boolean {
    return [AssessmentManagedType.PAAEB, AssessmentManagedType.PAEE].includes(
      this.application$.value?.avaliacao?.tipo_gerenciada,
    );
  }

  public ngOnDestroy(): void {
    this.application$.complete();
    this.dataStudent$.complete();
    this.loading$.complete();
    this.data$.complete();
  }

  public onPrintPopover(elementRef: ElementRef): void {
    this._popover
      .open(PopoverPrintOptionsComponent, {
        positionStrategy: [Strategy.RIGHT],
        elementRef,
        data: {
          dataStudent: this.data$.value?.alunos,
          application: this.application$.value,
        },
      })
      .closed.pipe(take(1), notNull())
      .subscribe(() => this.loadData());
  }

  public onModalResolutionEvaluation(userId?: number) {
    this._dialog
      .open(ModalAnswersComponent, {
        data: {
          userId,
          dataStudent: this.dataStudent$.value,
          applicationUuid: this.application$.value.uuid,
          evaluation: this.data$.value.avaliacao,
          status_execution: this.data$.value.status_execucao,
        },
        disableClose: true,
        hasBackdrop: true,
      })
      .closed.pipe(take(1), notNull())
      .subscribe(() => this.loadData());
  }

  public onModalAssessmentsMirror(userId: number, userName: string) {
    this._dialog.open(ModalAssessmentsMirrorComponent, {
      data: {
        userId,
        userName,
        evaluationUuid: this.application$.value.uuid,
        evaluationTitle: this.data$.value.avaliacao.titulo,
        managedType: this.data$.value.avaliacao.tipo_gerenciada_main?.descricao,
      },
    });
  }

  public onModalAnswersAuditComponent(userId?: number) {
    this._dialog
      .open<boolean>(ModalAnswersAuditComponent, {
        data: {
          applicationUuid: this.application$.value.uuid,
          evaluation: this.data$.value.avaliacao,
          status_execution: this.data$.value.status_execucao,
          userId,
        },
      })
      .closed.subscribe(() => this.loadData());
  }

  public onOpenReleaseStudents(student?: any): void {
    this._dialog
      .open<boolean>(ModalReleaseStudentsComponent, {
        data: {
          application: this.application$.value,
          dataStudent:
            student !== undefined
              ? [student]
              : this.dataStudent$.value.filter((student) => student.isSelected === true),
        },
      })
      .closed.pipe(take(1), notNull())
      .subscribe(() => this.loadData());
  }

  public onSelectedAll(check: boolean) {
    this.hasSelected = check;
    this.dataStudent$.next(this.dataStudent$.value.map((alunos) => ({ ...alunos, isSelected: check })));
  }

  public onSelectedCheck() {
    this.hasSelected =
      this.dataStudent$.value.filter((student) => student.isSelected === true).length ===
      this.dataStudent$.value.length;
  }

  public onCalculatePerformance(qtyHits) {
    const result = (qtyHits * 100) / this.data$.value.qtd_questao_avaliadas;
    return result ? result.toFixed(0) : 0;
  }

  public onTimeAssessment(dataStart, dataEnd) {
    if (!dataStart || !dataEnd || dataStart > dataEnd) {
      return '-';
    }
    const start = new Date(dataStart);
    const end = new Date(dataEnd);
    const ms = differenceInMinutes(end, start);
    const rest = Math.floor(ms / 60) + 'h ' + (ms % 60) + 'm';
    return rest;
  }

  public getTimeRange(student: any): string {
    let content = `Hora de início: ${format(new Date(student.data_inicio), 'dd/MM/yyyy HH:mm')}`;
    if (student.data_fim) {
      content += ` e Hora de término: ${format(new Date(student.data_fim), 'dd/MM/yyyy HH:mm')}`;
    }
    return content;
  }

  public onPreviewer(application): boolean {
    return (
      !!application.previewer ||
      application.avaliacao.criado_por === this._activeUser$.value.usuario_id ||
      this.permissionView(application.avaliacao) ||
      [FunctionsEnum.desenvolvedorPortal].includes(this.defaultFunction)
    );
  }

  public onModalScaleProficiencia() {
    this._dialog.open(ModalScaleProficienciaComponent, {
      data: {
        application: this.application$.value,
        entity_id: this.entityIdCtrl.value,
      },
    });
  }

  public onAssessmentsPrintSettingsComponent() {
    this._dialog.open<boolean>(ModalAssessmentsPrintSettingsComponent, {
      data: {
        application_uuid: this.application$.value.uuid,
        shuffle_questions:
          !!this.application$.value.embaralhar_questoes || !!this.application$.value.embaralhar_alternativas,
      },
    });
  }

  public onCreateResponseCard() {
    this._dialog.open(ModalCreateResponseCardComponent, {
      data: {
        application_uuid: this.application$.value.uuid,
        dataStudent: this.dataStudent$.value,
      },
    });
  }

  public onUploadFiles() {
    this._dialog.open(ModalUploadFilesComponent, {
      data: {
        application: this.application$.value,
      },
    });
  }

  public onAssessmentsImportFileComponent() {
    this._dialog.open<boolean>(ModalAssessmentsImportFileComponent, {
      data: {
        application_uuid: this.application$.value.uuid,
      },
    });
  }

  public onExportFile() {
    this._dialog.open(ModalExportFileComponent, {
      data: this.application$.value.uuid,
    });
  }

  private permissionView(assessment): boolean {
    const options = {
      [1]: () => 'entidade_id',
      [2]: () => 'campo_id',
      [3]: () => 'uniao_id',
      [4]: () => 'editora_id',
      [5]: () => 'divisao_id',
    };
    const existePermissao = assessment.entidade_permissao.some(
      (permissao) => permissao[options[this.entityActive.tipo]()] === this.entityActive.id,
    );
    return existePermissao;
  }

  private permission(): boolean {
    const options = {
      [1]: () => 'entidade_id',
      [2]: () => 'campo_id',
      [3]: () => 'uniao_id',
      [4]: () => 'editora_id',
      [5]: () => 'divisao_id',
    };
    const existePermissao = this.application$.value.avaliacao.entidade_permissao.some(
      (permissao) => permissao[options[this.entityActive.tipo]()] === this.entityActive.id,
    );
    return existePermissao;
  }

  private loadData() {
    this.hasEntity.next(false);
    if (!this.entityIdCtrl.value) {
      return;
    }

    this.hasEntity.next(true);

    this.loading$.next(true);
    this._gdaReportService
      .getStudent(this.application$.value.uuid, { entity_id: this.entityIdCtrl.value })
      .pipe(takeUntilDestroyed(this._destroyRef), notNull(), take(1))
      .subscribe(({ data }: any) => {
        this.data$.next(data);
        this.originalStudents = this.studentTransform(data.alunos);
      });
  }

  private studentTransform(data: any[]): any[] {
    const grouped = data
      .map((aluno) => ({ ...aluno, isSelected: false }))
      .reduce((acc, aluno) => {
        const turma = aluno.turma_descricao || this._translateService.instant('gestor-avaliacoes.alunos-especificos');
        if (!acc[turma]) {
          acc[turma] = [];
        }
        acc[turma].push(aluno);
        return acc;
      }, {} as Record<string, StudentDate[]>);

    const specificStudents = this._translateService.instant('gestor-avaliacoes.alunos-especificos');
    const sortedEntries = orderBy(Object.entries(grouped), ([group]) => (group === specificStudents ? -1 : 1));

    this.groups = sortedEntries.map(([group]) => group);

    this.searchGroupCtrl.setValue(this.groups.find((group) => group !== specificStudents) || specificStudents);
    return sortedEntries;
  }

  private applyFilters() {
    const searchName = this.searchStudentNameCtrl.value?.trim().toLowerCase() || '';
    const searchGroup = this.searchGroupCtrl.value?.trim().toLowerCase() || '';

    if (!searchName && !searchGroup) {
      this.dataStudent$.next(this.originalStudents);
      return;
    }

    const filteredData = this.originalStudents
      .map(([group, alunos]) => {
        const groupMatch = searchGroup ? group.toLowerCase().includes(searchGroup) : true;
        const filteredAlunos = alunos.filter((student) =>
          searchName ? student.nome.toLowerCase().includes(searchName) : true,
        );

        if (searchGroup && groupMatch) {
          return [group, filteredAlunos.length ? filteredAlunos : alunos];
        }

        return groupMatch && filteredAlunos.length ? [group, filteredAlunos] : null;
      })
      .filter(Boolean);

    this.dataStudent$.next(filteredData);
    this.loading$.next(false);
  }
}
