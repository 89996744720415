<app-modal-header>
  <div class="flex w-full grid-cols-[100px_auto_1fr_auto] flex-col justify-between bg-white lg:grid">
    <div class="flex">
      <svg-icon
        class="w-10 text-theme-500"
        src="assets/icons/icon-gestor-avaliacoes.svg"
        alt="Materiais Didaticos"
      ></svg-icon>
      <h1 class="ml-3 text-sm font-semibold leading-tight text-gray-800" translate>
        gestor-avaliacoes.e-class
        <br />
        <b class="text-lg text-black" translate>gestor-avaliacoes.prova </b>
      </h1>
    </div>

    <hr app-modal-divider />

    <div>
      <div class="flex w-full items-center justify-between">
        <div class="md:flex">
          <div class="mr-5">
            <span class="text-xl font-semibold leading-tight text-gray-800" translate>
              gestor-avaliacoes.analise-grafica-questao
            </span>
          </div>
        </div>
        <div #iconPopoverShortcut class="flex justify-end">
          <button
            (click)="onOpenPopoverReadingChart(iconPopoverShortcut)"
            app-flat-button
            class="gap-2 bg-base-status-info-500 text-theme-contrast"
            type="button"
            translate
          >
            <svg-icon class="ml-1 flex h-6 w-6 text-white" src="assets/icons/icon-info-rounded.svg"></svg-icon>
            gestor-avaliacoes.como-ler-grafico
          </button>
        </div>
      </div>
    </div>
  </div>
</app-modal-header>

<div app-modal-content class="h-[80vh] overflow-y-auto !p-0 md:w-[80vw]">
  <ng-container *ngIf="data$ | async as data; else elseTemplate">
    <div class="flex h-[calc(100%-70px)] w-full flex-col justify-between gap-8 sm:h-[calc(100%-70px)]">
      <div class="h-[80%]">
        <highcharts-chart [Highcharts]="Highcharts" [options]="chartOptions" class="block h-full w-full">
        </highcharts-chart>
      </div>
      <div class="flex flex-col items-center">
        <div class="max-w-full overflow-x-auto">
          <table class="min-w-full table-auto border-collapse border border-gray-400">
            <thead>
              <tr>
                <th class="border border-gray-300 px-4 py-2"><b translate>gestor-avaliacoes.acertos</b></th>
                <th *ngFor="let adjustment of data.adjustments" class="border border-gray-300 px-4 py-2">
                  {{ adjustment.acertos }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="border border-gray-300 px-4 py-2"><b translate>gestor-avaliacoes.quantidade-alunos</b></td>
                <td *ngFor="let adjustment of data.adjustments" class="border border-gray-300 px-4 py-2">
                  {{ adjustment.alunos }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </ng-container>
</div>

<ng-template #elseTemplate>
  <div class="flex h-[80vh] items-center justify-center !p-0" app-card-content>
    <app-skeleton *ngIf="loading$ | async" class="h-full w-full"></app-skeleton>
  </div>
</ng-template>
