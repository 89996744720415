import { ChangeDetectionStrategy, Component, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CategoriesService } from '@app/core';
import { compareLike, safeEmptyList } from '@app/shared/utils';
import { isEmpty } from 'lodash';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

@Component({
  selector: 'app-select-matrix-contents',
  templateUrl: './select-matrix-contents.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => SelectMatrixContentsComponent),
    },
  ],
})
export class SelectMatrixContentsComponent implements ControlValueAccessor {
  @Input() public required: boolean;
  @Input() public returnObject: boolean;
  @Input() public label: boolean = true;
  @Input() public multiple: boolean = false;

  public class$ = new BehaviorSubject<string>(undefined);
  public ctrl = new FormControl(undefined);
  public data$: Observable<any>;
  public loading$ = new BehaviorSubject<boolean>(false);
  private _defaultLevelId$ = new BehaviorSubject<number>(undefined);
  private _defaultGradeId$ = new BehaviorSubject<number>(undefined);
  private _defaultSubjectId$ = new BehaviorSubject<number>(undefined);
  private _disable$ = new BehaviorSubject<boolean>(false);

  constructor(private _categoriesService: CategoriesService) {
    this.loadData();

    // this._disable$.subscribe((disable) => (disable ? this.ctrl.disable() : this.ctrl.enable()));
  }

  @Input()
  public set defaultLevelFilter(value: number) {
    this._defaultLevelId$.next(value);
  }

  @Input()
  public set defaultGradeFilter(value: number) {
    this._defaultGradeId$.next(value);
  }

  @Input()
  public set defaultSubjectFilter(value: number) {
    this._defaultSubjectId$.next(value);
  }

  @Input('class')
  public set setClass(c: string) {
    this.class$.next(c);
  }

  @Input() public set disable(value: boolean) {
    this._disable$.next(value);
  }

  public writeValue(value: number) {
    this.ctrl.setValue(value);
  }

  public registerOnChange(fn: any): void {
    this.changeValue = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public onSelect(value: any) {
    this.onTouched();
    if (this.returnObject) {
      this.changeValue(value);
    } else {
      this.changeValue(value?.id);
    }
  }

  public groupByFn = (category: any) => `${category.component?.name} > ${category.parent?.name}`;

  public groupValueFn = (_: string, categories: any[]) => ({
    name: `${categories[0].component?.name} > ${categories[0].parent?.name}`,
    total: categories.length,
  });

  public onSearch(term: string, item): boolean {
    return (
      compareLike(term, item.name) || compareLike(term, item.parent?.name) || compareLike(term, item.component?.name)
    );
  }

  private onChange(value: any) {
    const defaultValue = this.multiple ? [] : undefined;
    this.changeValue(value ? value : defaultValue);
  }

  private loadData() {
    this.data$ = combineLatest([this._defaultSubjectId$, this._defaultGradeId$, this._defaultLevelId$]).pipe(
      tap(() => this.loading$.next(true)),
      switchMap(([defaultSubjectId, gradeId, levelId]) =>
        this._categoriesService.matrixContent(defaultSubjectId, gradeId, levelId).pipe(safeEmptyList()),
      ),
      tap(this.reset),
      tap(() => {
        this.loading$.next(false);
        // this.ctrl.setValue(!!this.ctrl.value ? this.ctrl.value : undefined);
      }),
    );
  }

  private reset = (res: any[] = []) => {
    const value = this.multiple
      ? this.ctrl.value?.map((id) => res.find((item) => item.id === id)).filter((item) => item)
      : res.find((item) => item.id === this.ctrl.value);

    if (isEmpty(value)) {
      this.ctrl.reset();
      this.onSelect(undefined);
    }

    this.onChange(value);
  };

  private onTouched = () => true;
  private changeValue = (_: any) => true;
}
