<app-modal-header class="border-b-2 border-theme-500">
  <div class="flex items-center">
    <svg-icon
      class="mr-5 w-10 text-theme-500"
      src="assets/icons/icon-gestor-avaliacoes.svg"
      alt="Materiais Didaticos"
    ></svg-icon>
    <h1 class="hidden text-sm font-semibold leading-tight text-gray-800 md:grid" translate>
      gestor-avaliacoes.e-class
      <br />
      <b class="text-lg text-black" translate>gestor-avaliacoes.prova</b>
    </h1>
    <hr app-modal-divider class="hidden md:block" />

    <div class="flex items-baseline gap-2">
      <h1 class="flex text-lg text-gray-700" translate>gestor-avaliacoes.imprimir-cartao-resposta</h1>
    </div>
  </div>
</app-modal-header>

<div app-modal-content class="h-[85vh] w-full overflow-hidden !p-0 md:h-auto xl:w-[40vw]">
  <app-sidenav-group class="!h-full !w-full">
    <ng-template app-sidenav-outer>
      <form [formGroup]="form" class="mb-5 overflow-y-auto">
        <div class="mb-6 grid w-full rounded-md border border-base-gray-100 px-6 py-4 md:flex md:flex-col">
          <div class="mb-4 flex items-center gap-4 rounded-lg border border-yellow-200 bg-yellow-50 px-5 py-2">
            <svg-icon
              class="flex w-[14px] text-yellow-300"
              src="assets/icons/icon-notify-fine.svg"
              alt="icon"
            ></svg-icon>
            <h1 class="text-sm text-zinc-900" translate>gestor-avaliacoes.aviso-cartao-resposta</h1>
          </div>

          <div class="flex w-full gap-2">
            <app-form-field class="w-full md:w-6/12">
              <label app-label class="font-semibold" translate> gestor-avaliacoes.modelo </label>
              <ng-select
                app-select
                [searchable]="false"
                [clearable]="false"
                [items]="printResponseCard"
                formControlName="modelo"
                bindLabel="name"
                bindValue="id"
                [placeholder]="'gestor-avaliacoes.selecione-modelo' | translate"
              >
                <ng-template ng-label-tmp let-index="index" let-item="item">
                  <div class="flex items-center gap-2">
                    <img height="25" width="25" [src]="item.icon" />
                    <span>{{ item.name }}</span>
                  </div>
                </ng-template>

                <ng-template ng-option-tmp let-item="item" let-index="index">
                  <div class="!flex !items-center !gap-2">
                    <img height="25" width="25" [src]="item.icon" /> <span>{{ item.name }}</span>
                  </div>
                </ng-template>
              </ng-select>
            </app-form-field>
          </div>

          <div class="rounded-t-xl border-x border-t border-base-gray-100">
            <div class="flex flex-col items-center justify-between gap-2 p-4 xl:flex-row">
              <div class="flex w-full items-center xl:w-5/12">
                <ng-select
                  [clearable]="false"
                  [loading]="loading$ | async"
                  app-select
                  formControlName="turmas"
                  class="w-full"
                  placeholder="Selecione a turma"
                >
                  <ng-option *ngFor="let group of groups" [value]="group.id">{{ group.descricao }}</ng-option>
                </ng-select>
              </div>
              <div class="flex w-full xl:w-5/12">
                <div class="relative flex w-full items-center">
                  <input
                    app-input
                    class="pr-9"
                    formControlName="busca"
                    [placeholder]="'Pesquisar por Aluno' | translate"
                  />
                  <svg-icon
                    src="assets/icons/icon-search.svg"
                    class="absolute right-4 top-4 w-4 cursor-pointer"
                  ></svg-icon>
                </div>
              </div>

              <div class="flex w-full flex-row items-center justify-end gap-2 xl:w-2/12">
                <input
                  app-checkbox
                  class="text-theme-500"
                  type="checkbox"
                  [checked]="totalSelectedStudents === filteredStudents.length && filteredStudents.length > 0"
                  (change)="markAll($event.target.checked)"
                />
                <label translate app-label class="!p-0 !text-base"> eclass.marcar-tudo </label>
              </div>
            </div>
          </div>
          <div
            class="h-72 overflow-y-auto rounded-b-xl rounded-br-none border-x border-b border-base-gray-100 bg-base-gray-light-200 p-4 shadow-inner-bg"
          >
            <ng-container *ngIf="filteredStudents | filter : searchCtrl.value : ['nome'] as filteredResults">
              <div *ngIf="filteredResults.length > 0" class="grid grid-cols-1 gap-2 xl:grid-cols-2">
                <button
                  *ngFor="let student of filteredResults"
                  app-button
                  class="border-1 flex w-full justify-start gap-4 rounded-md p-1 shadow-lg transition duration-300"
                  [ngClass]="{ 'bg-blue-100': student.isSelected, 'bg-white': !student.isSelected }"
                  (click)="selected(student)"
                >
                  <span class="border-1 flex w-max !rounded-full bg-white/80 !p-1 shadow-sm shadow-black/40">
                    <app-user-photo class="h-10 w-10" [src]="student.foto"></app-user-photo>
                  </span>
                  <div class="flex w-full items-center justify-between truncate">
                    <div class="grid">
                      <div class="flex w-full items-center justify-between gap-2">
                        <p class="mb-1 text-base">{{ student.nome }}</p>
                      </div>
                      <div class="flex w-full items-center gap-2 truncate text-sm text-gray-600">
                        {{ student.turma_descricao || ('gestor-avaliacoes.aluno-especifico' | translate) }}
                      </div>
                    </div>
                    <div class="flex items-center" *ngIf="student.necessidades_especiais?.length">
                      <svg-icon
                        app-tooltip
                        [tooltip]="formattedNecessidadesEspeciais(student.necessidades_especiais)"
                        class="h-6 w-6 text-gray-400"
                        src="assets/icons/icon-info-rounded.svg"
                      ></svg-icon>
                    </div>
                  </div>
                </button>
              </div>

              <div app-card-content [ngClass]="{ 'h-72': loading$ | async }">
                <app-skeleton *ngIf="loading$ | async" class="h-full w-full"></app-skeleton>

                <app-jumbotron *ngIf="(loading$ | async) === false && filteredResults.length === 0" translate>
                  <ng-container>
                    {{ 'gestor-avaliacoes.nenhum-aluno' | translate }}
                  </ng-container>
                </app-jumbotron>
              </div>
            </ng-container>
          </div>
        </div>
        <div *ngIf="form.get('modelo')?.value === 1" class="w-full px-6 pb-5">
          <label class="!p-0 !text-base" app-label translate> gestor-avaliacoes.orientacoes-cartao-resposta </label>
          <app-text-editor
            formControlName="text_instruction"
            [toolbar]="editorConfigToolbar"
            class="[&_.ck-content>p]:h-[10px]"
          ></app-text-editor>
        </div>

        <div class="mb-5 flex justify-end px-5">
          <button
            (click)="print()"
            app-icon-button
            type="button"
            class="w-48 gap-3 bg-blue-500 uppercase text-theme-contrast"
          >
            <svg-icon class="flex h-5 w-5 text-white" src="assets/icons/icon-print.svg"></svg-icon>
            {{ 'gestor-avaliacoes.caixa-alta.imprimir' | translate }} ({{ totalSelectedStudents }})
          </button>
        </div>
      </form>
    </ng-template>
  </app-sidenav-group>
</div>
