import { Dialog, DialogRef } from '@angular/cdk/dialog';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FunctionsEnum, PaisEnum, schoolAdmin } from '@app/core/models';
import { AlertService, IntegrationService, LoadingService, ServicesService } from '@app/core/services';
import { WebsiteService } from '@app/modules/school-website/services/website.service';
import { AppSelectors } from '@app/store';
import { AppState } from '@app/store/app.state';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { isString } from 'lodash';
import { Observable, Subject, of } from 'rxjs';
import { catchError, finalize, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { Link, Services } from '../../model/link.model';
import { ModalDriveComponent } from '../modal-drive/modal-drive.component';
import { ModalPedagogicalContentComponent } from '../modal-pedagogical-content/modal-pedagogical-content.component';
import { ModalSkyEnglishComponent } from '../modal-sky-english/modal-sky-english.component';

@Component({
  templateUrl: './modal-services.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalServicesComponent implements OnInit, OnDestroy {
  public services$: Observable<any>;
  private stopEvent = false;
  private destroy$ = new Subject<void>();

  private skyEnglish: Link = {
    key: Services.SkyEnglish,
    popover: 'servicos.sky-english.titulo',
    icon: 'assets/icons/icon-sky-english.svg',
    style: { base: 'text-base-service-sky-english group-hover:bg-base-service-sky-english', icon: 'justify-end' },
    link: () => this.openSkyEnglish(),
    show: (store: AppState) => !!store.usuario_ativo.entidade_id && schoolAdmin().includes(store.funcao_padrao),
  };

  private cpbProva: Link = {
    key: Services.CpbProva,
    popover: 'servicos.cpb-provas',
    icon: 'assets/icons/icon-cpbprova.svg',
    style: {
      base: 'text-base-service-cpbprova group-hover:bg-base-service-cpbprova',
      icon: 'justify-end',
    },
    link: () => this.openCpbProva(),
    show: (store: AppState) =>
      (store.funcao_padrao === FunctionsEnum.aluno && store.usuario_ativo.pais_id === PaisEnum.Brasil) ||
      store.funcao_autorizado === 1,
  };

  private enemInterativo: Link = {
    key: Services.EnemInterativo,
    popover: 'servicos.enem-interativo',
    icon: 'assets/icons/icon-enem.svg',
    style: { base: 'text-base-service-enem group-hover:bg-base-service-enem', icon: 'justify-end' },
    link: () => this.openEnem(),
    show: (store: AppState) =>
      (store.funcao_padrao === FunctionsEnum.aluno && store.usuario_ativo.pais_id === PaisEnum.Brasil) ||
      store.funcao_autorizado === 1,
  };

  private canva: Link = {
    key: Services.Canva,
    popover: 'servicos.canva',
    icon: 'assets/icons/icon-canva.svg',
    style: {
      base: 'text-base-service-canva group-hover:bg-base-service-canva',
      icon: 'justify-center',
    },
    link: 'https://www.canva.com/',
  };

  private office: Link = {
    key: Services.Office365,
    popover: 'servicos.office',
    icon: 'assets/icons/icon-office.svg',
    style: {
      base: 'text-base-service-office group-hover:bg-base-service-office',
      icon: 'justify-center',
    },
    link: 'https://account.educadventista.org/home',
  };

  private pmde: Link = {
    key: Services.PMDE,
    popover: 'servicos.pmde',
    icon: 'assets/icons/icon-pmde.svg',
    style: {
      base: 'text-base-service-pmde group-hover:bg-base-service-pmde',
      icon: 'justify-center',
    },
    link: 'https://www.educacionadventista.com/recursos/proyectos/pmde/',
  };

  private drive: Link = {
    key: Services.Drive,
    popover: 'servicos.drive.titulo',
    icon: 'assets/icons/icon-arquivos.svg',
    style: { base: 'text-modules-drive-500 group-hover:bg-modules-drive-500' },
    link: () => this.openDrive(),
  };

  private salaProfessores: Link = {
    key: Services.SalaProfessores,
    popover: 'servicos.sala-professores',
    icon: 'assets/icons/icon-sala.svg',
    style: { base: 'text-base-service-sala group-hover:bg-base-service-sala' },
    link: 'https://saladeprofessores.com.br/',
  };

  private chaveEnemIAno: Link = {
    key: Services.ChaveEnemI,
    popover: 'servicos.chave-enem-1-ano',
    icon: 'assets/icons/icon-chave-1.svg',
    style: { base: 'text-base-service-chave1 group-hover:bg-base-service-chave1' },
    link: 'https://adv7.in/chavedoenem',
  };

  private chaveEnemIIAno: Link = {
    key: Services.ChaveEnemII,
    popover: 'servicos.chave-enem-2-ano',
    icon: 'assets/icons/icon-chave-2.svg',
    style: { base: 'text-base-service-chave2 group-hover:bg-base-service-chave2' },
    link: 'https://adv7.in/chavedoenem2',
  };

  private chaveEnemIIIAno: Link = {
    key: Services.ChaveEnemIII,
    popover: 'servicos.chave-enem-3-ano',
    icon: 'assets/icons/icon-chave-3.svg',
    style: { base: 'text-base-service-chave3 group-hover:bg-base-service-chave3' },
    link: 'https://adv7.in/chavedoenem3',
  };

  private conteudoPedagogico: Link = {
    key: Services.ConteudoPedagogico,
    popover: 'servicos.conteudo-pedagogico',
    icon: 'assets/icons/icon-cont-pedagogico.svg',
    style: {
      base: 'text-modules-pedagogical-500 group-hover:bg-modules-pedagogical-500',
      icon: 'justify-center',
    },
    link: () => this.openPedagogicalContent(),
  };

  private conteudoSiteEscola: Link = {
    key: Services.ConteudoSiteEscola,
    popover: 'servicos.conteudo-site-escola',
    icon: 'assets/icons/icon-student-book.svg',
    style: {
      base: 'text-modules-school-website-500 group-hover:bg-modules-school-website-500',
      icon: 'justify-center',
    },
    link: () => this.openWebSchoolContent(),
    show: (store: AppState) => !!store.usuario_ativo.entidade_id,
  };

  private arquivoSiteEscola: Link = {
    key: Services.ArquivoSiteEscola,
    popover: 'servicos.arquivos-site-escola',
    icon: 'assets/icons/icon-folder.svg',
    style: {
      base: 'text-modules-school-website-500 group-hover:bg-modules-school-website-500',
      icon: 'justify-center',
    },
    link: () => this.openWebSchoolFiles(),
    show: (store: AppState) => !!store.usuario_ativo.entidade_id,
  };

  private superPro: Link = {
    key: Services.SuperPro,
    popover: 'servicos.superpro',
    icon: 'assets/icons/icon-superpro.svg',
    style: { base: 'text-base-service-superpro group-hover:bg-base-service-superpro' },
    link: () => this.openSuperPro(),
  };

  private sequenciaDidatica: Link = {
    key: Services.SequenciaDidatica,
    popover: 'servicos.sequencia-didatica',
    icon: 'assets/icons/icon-sequencia-didatica.svg',
    style: { base: 'text-base-service-didatica group-hover:bg-base-service-didatica' },
    link: () => this.openSequenciaDidatica(),
  };

  private eletivaFaama: Link = {
    key: Services.EletivaFaama,
    popover: 'servicos.eletivas-faama',
    icon: 'assets/icons/icon-logo-gobe.svg',
    style: { base: 'text-[#e52421] group-hover:bg-[#e52421]' },
    link: 'https://ead.faama.edu.br',
  };

  private bibliotecaVirtual: Link = {
    key: Services.BibliotecaVirtual,
    popover: 'servicos.biblioteca-virtual',
    icon: 'assets/icons/icon-biblioteca-virtual.svg',
    style: { base: 'text-base-service-biblioteca group-hover:bg-base-service-biblioteca' },
    link: 'https://adventistas.sophia.com.br/terminal',
    show: (store: AppState) =>
      store.usuario_ativo.pais_id === PaisEnum.Brasil && store.usuario_ativo.entidade !== 113122,
  };

  private redacao: Link = {
    key: Services.Redacao,
    popover: 'servicos.redacao',
    icon: 'assets/icons/icon-essay.svg',
    style: { base: 'text-base-service-chave2 group-hover:bg-base-service-chave2', icon: 'justify-end' },
    link: () => this.openRedacao(),
  };

  constructor(
    private dialog: Dialog,
    private dialogRef: DialogRef,
    private store: Store<AppState>,
    private alertService: AlertService,
    private loadingService: LoadingService,
    private servicesService: ServicesService,
    private translateService: TranslateService,
    private integrationService: IntegrationService,
    private websiteService: WebsiteService,
    private router: Router,
  ) {}

  public ngOnInit() {
    this.loadData();
  }

  public ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public onOpen(service: Link) {
    this.preventManyAction(service.link);
  }

  public isUrl(url: any): boolean {
    return isString(url);
  }

  private loadData() {
    const services = [
      this.skyEnglish,
      this.cpbProva,
      this.redacao,
      this.enemInterativo,
      this.drive,
      this.superPro,
      this.salaProfessores,
      this.sequenciaDidatica,
      this.bibliotecaVirtual,
      this.eletivaFaama,
      this.chaveEnemIAno,
      this.chaveEnemIIAno,
      this.chaveEnemIIIAno,
      this.conteudoPedagogico,
      this.conteudoSiteEscola,
      this.arquivoSiteEscola,
      this.canva,
      this.office,
      this.pmde,
    ];
    this.services$ = this.store.select(AppSelectors.featureKey).pipe(
      takeUntil(this.destroy$),
      map((store) => services.filter((link) => (link.show ? link.show(store) : true))),
      switchMap((item) =>
        this.servicesService.index().pipe(map(({ data }) => item.filter((service) => data.includes(service.key)))),
      ),
    );
  }

  private openCpbProva() {
    this.router.navigate(['/eclass-prova']);
    this.dialogRef.close();
    return;

    this.loadingService.start();
    return this.integrationService.estuda().pipe(
      finalize(() => this.loadingService.finish()),
      catchError(() =>
        of({ status: false, msg: this.translateService.instant('servicos.erros.contato-administrador') }),
      ),
      switchMap((data) => {
        if (data.status) {
          const windows = window.open('', '_blank');
          if (!windows) {
            const message = this.translateService.instant('servicos.erros.permitir-popup');
            const img = `<br><img src="assets/images/pop-up-links.jpg" class="pt-3">`;
            return this.alertService.alert({ title: 'status.atencao', innerHtml: `${message} ${img}` });
          }
          windows.location.href = data.url;
          return of(true);
        }
        return this.alertService.alert({ title: 'status.atencao', innerHtml: data.msg });
      }),
    );
  }

  private openEnem() {
    this.router.navigate(['/eclass-prova']);
    this.dialogRef.close();
    return;
    this.loadingService.start();
    return this.integrationService.enem().pipe(
      finalize(() => this.loadingService.finish()),
      catchError(() =>
        of({ status: false, msg: this.translateService.instant('servicos.erros.contato-administrador') }),
      ),
      switchMap((data) => {
        if (data.status) {
          const windows = window.open('', '_blank');
          if (!windows) {
            const message = this.translateService.instant('servicos.erros.permitir-popup');
            const img = `<br><img src="assets/images/pop-up-links.jpg" class="pt-3">`;
            return this.alertService.alert({ title: 'status.atencao', innerHtml: `${message} ${img}` });
          }
          windows.location.href = data.url;
          return of(true);
        }
        return this.alertService.alert({ title: 'status.atencao', innerHtml: data.msg });
      }),
    );
  }

  private openRedacao() {
    this.loadingService.start();
    return this.integrationService.estuda().pipe(
      finalize(() => this.loadingService.finish()),
      catchError(() =>
        of({ status: false, msg: this.translateService.instant('servicos.erros.contato-administrador') }),
      ),
      switchMap((data) => {
        if (data.status) {
          const windows = window.open('', '_blank');
          if (!windows) {
            const message = this.translateService.instant('servicos.erros.permitir-popup');
            const img = `<br><img src="assets/images/pop-up-links.jpg" class="pt-3">`;
            return this.alertService.alert({ title: 'status.atencao', innerHtml: `${message} ${img}` });
          }
          windows.location.href = data.url;
          return of(true);
        }
        return this.alertService.alert({ title: 'status.atencao', innerHtml: data.msg });
      }),
    );
  }

  private openDrive() {
    return this.dialog.open(ModalDriveComponent).closed;
  }

  private openSkyEnglish() {
    return this.dialog.open(ModalSkyEnglishComponent).closed;
  }

  private openPedagogicalContent() {
    return this.dialog.open(ModalPedagogicalContentComponent).closed;
  }

  private openWebSchoolFiles() {
    return this.websiteService.openWebSchoolFiles();
  }

  private openWebSchoolContent() {
    return this.websiteService.openWebSchoolContent();
  }

  private openSequenciaDidatica() {
    return this.store.select(AppSelectors.ActiveUser).pipe(
      map(({ entidade }) => {
        const options = {
          [108122]: () => 'https://sequenciadidatica-abc.ucob.org.br',
          [109122]: () => 'https://sequenciadidatica-aplac.ucob.org.br',
          [110122]: () => 'https://sequenciadidatica-alm.ucob.org.br',
          [147122]: () => 'https://sequenciadidatica-misom.ucob.org.br',
          [145122]: () => 'https://sequenciadidatica-asm.ucob.org.br',
          [146122]: () => 'https://sequenciadidatica-mto.ucob.org.br',
          [108124]: () => 'https://sequenciadidatica-iabc.ucob.org.br',
          [113122]: () => 'https://sd.ca.org.br/login',
          [102122]: () => 'https://sequenciadidatica.paulistana.app/',
          [107122]: () => 'https://sd.ca.org.br/login',
        };
        return options[entidade] ? options[entidade]() : undefined;
      }),
      tap((data: string) => {
        if (!data) {
          this.alertService.alert({
            title: 'status.atencao',
            innerHtml: this.translateService.instant('servicos.erros.indisponivel'),
          });
          return;
        }

        const windows = window.open('', '_blank');
        if (!windows) {
          const message = this.translateService.instant('servicos.erros.permitir-popup');
          const img = `<br><img src="assets/images/pop-up-links.jpg" class="pt-3">`;
          return this.alertService.alert({ title: 'status.atencao', innerHtml: `${message} ${img}` });
        }
        windows.location.href = data;
      }),
    );
  }

  private openSuperPro() {
    this.loadingService.start();
    return this.store.select(AppSelectors.appFeature).pipe(
      take(1),
      switchMap((store) =>
        this.integrationService.superPro({
          user_id: store.usuario_id,
          funcao_id: store.funcao_padrao,
          entidade_id: store.usuario_ativo.entidade_id,
        }),
      ),
      finalize(() => this.loadingService.finish()),
      catchError(() => of([{ erro: this.translateService.instant('servicos.erros.indisponivel') }])),
      switchMap(([data]) => {
        if (data.msg === 0) {
          const windows = window.open('', '_blank');
          if (!windows) {
            const message = this.translateService.instant('servicos.erros.permitir-popup');
            const img = `<br><img src="assets/images/pop-up-links.jpg" class="pt-3">`;
            return this.alertService.alert({ title: 'status.atencao', innerHtml: `${message} ${img}` });
          }
          windows.location.href = data.url;
          return of(true);
        }
        return this.alertService.alert({ title: 'status.atencao', innerHtml: data.erro || data.msg });
      }),
    );
  }

  private preventManyAction(observable: () => Observable<any>) {
    if (!this.stopEvent) {
      this.stopEvent = true;
      observable()
        .pipe(take(1))
        .subscribe(() => (this.stopEvent = false));
    }
  }
}
