import { NgModule } from '@angular/core';
import { TemplateModule } from '../template/template.module';
import { TextEditorComponent } from './text-editor.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { ModalMathFormulaComponent } from './components/modal-math-formula/modal-math-formula.component';
import { PipesModule } from '@app/shared/pipes/pipes.module';

@NgModule({
  declarations: [TextEditorComponent, ModalMathFormulaComponent],
  imports: [TemplateModule, CKEditorModule, PipesModule],
  exports: [TextEditorComponent],
})
export class TextEditorModule {}
