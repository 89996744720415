import { ChangeDetectionStrategy, Component, EventEmitter, forwardRef, Input, Output, DestroyRef } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CountryService } from '@app/docs/privacy-policy/service/country.service';
import { Observable, tap } from 'rxjs';

@Component({
  selector: 'app-select-country-context',
  templateUrl: './select-country-context.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectCountryContextComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectCountryContextComponent implements ControlValueAccessor {
  @Input() public multiple = false;
  @Input() public clearable = true;
  @Output() public items = new EventEmitter();
  public data$: Observable<any>;
  public ctrl = new FormControl();

  constructor(private countryService: CountryService, private _destroyRef: DestroyRef) {
    this.data$ = this.countryService.index().pipe(
      takeUntilDestroyed(this._destroyRef),
      tap((countries) => this.items.emit(countries)),
    );
  }

  public writeValue(obj: any): void {
    this.ctrl.setValue(obj);
  }

  public registerOnChange(fn: any): void {
    this.changeValue = fn;
  }

  public onFocus() {
    this.onTouched();
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public onChange(value: any) {
    const defaultValue = this.multiple ? [] : undefined;
    this.changeValue(value?.id || defaultValue);
  }

  private onTouched = () => true;
  private changeValue = (_: any) => true;
}
