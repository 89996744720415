import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AppState } from '@app/store/app.state';
import { Store } from '@ngrx/store';
import { filter, of, switchMap } from 'rxjs';
import { Policies } from '@app/shared';
import { appFeature } from '@app/store/app.selectors';
import { isEmpty } from 'lodash';

export const AllowDashboardGuard: CanActivateFn = () => {
  const router = inject(Router);
  const store = inject(Store<AppState>);

  return store.select(appFeature).pipe(
    filter((store) => !isEmpty(store.policies)),
    switchMap((store) => {
      if (!Policies.isAllow({ moduleId: 20, elementoId: 42 }, store.policies)) {
        return store?.usuario_ativo.homeschooling
          ? router.navigateByUrl('/materiais-didaticos')
          : router.navigateByUrl('/trilhas-de-aprendizagem/cursos');
      }
      return of(true);
    }),
  );
};
