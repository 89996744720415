import { Injectable } from '@angular/core';
import { AppSelectors } from '@app/store';
import { AppState } from '@app/store/app.state';
import { Store } from '@ngrx/store';
import { format } from 'date-fns';
import { MenuItem, MenuRef } from './menu';
import { notNull } from '@app/shared';
import { map } from 'rxjs';
import { PaisEnum } from '../models';

@Injectable({ providedIn: 'root' })
export class EclassMenu implements MenuRef {
  public identifier = 'eclass';

  public readonly menu: MenuItem[] = [
    () => ({
      title: 'menu.eclass',
      url: '/eclass',
      icon: '',
      dimension: '',
      permission: [20, 42],
    }),
  ];

  public readonly submenu: MenuItem[] = [
    () => ({
      title: 'breadcrumb.eclass.titulo',
      url: '/eclass',
      icon: 'icon-item-edit',
      defaultUrl: ['/eclass', format(new Date(), 'yyyy-MM-dd')],
      ignore: ['/eclass/lives', '/eclass/relatorios', '/eclass/observacoes'],
    }),
    () => ({
      title: 'breadcrumb.eclass.observacoes',
      url: '/eclass/observacoes',
      icon: 'icon-feedback-line',
      permission: [20, 35],
      show: this.store.select(AppSelectors.ActiveUser).pipe(
        notNull(),
        map((activeUser) => activeUser?.pais_id !== PaisEnum.Brasil),
      ),
    }),
    () => ({
      title: 'breadcrumb.eclass.lives',
      url: '/eclass/lives',
      icon: 'icon-zoom',
      show: this.store.select(AppSelectors.hasZoom),
    }),
    () => ({
      title: 'breadcrumb.eclass.relatorio',
      url: '/eclass/relatorios',
      icon: 'icon-report-teachers',
      permission: [20, 36],
    }),
  ];

  constructor(private store: Store<AppState>) {}
}
