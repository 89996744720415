import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppSelectors } from '@app/store';
import { AppState } from '@app/store/app.state';
import { Store } from '@ngrx/store';
import { exhaustMap, map } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class TokenHeaderInterceptor implements HttpInterceptor {
  constructor(private store: Store<AppState>) {}

  public intercept(req: HttpRequest<any>, next: HttpHandler) {
    return this.store.select(AppSelectors.token).pipe(
      map((token) => req.headers.append('Authorization', `Bearer ${token}`)),
      map((headers) => req.clone({ headers })),
      exhaustMap((custom) => next.handle(custom)),
    );
  }
}
