import { Injectable } from '@angular/core';
import { notNull } from '@app/shared';
import { AppSelectors } from '@app/store';
import { AppState } from '@app/store/app.state';
import { Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { MenuItem, MenuRef } from './menu';

@Injectable({ providedIn: 'root' })
export class LearningTrailsMenu implements MenuRef {
  public identifier = 'learningTrails';

  public readonly menu: MenuItem[] = [
    () => ({
      title: 'menu.cursos',
      url: ['/trilhas-de-aprendizagem', '/universidade-corporativa', '/ivca'],
      icon: 'icon-degree',
      dimension: 'h-9 w-9',
      permission: [1, 5],
    }),
  ];

  public readonly submenu: MenuItem[] = [
    () => ({
      title: 'breadcrumb.learning-trails.studio',
      url: '/trilhas-de-aprendizagem/estudio',
      icon: 'icon-menu-studio',
      group: 'menu.trilhas-aprendizagem',
      permission: [18, 53],
      show: this.store.select(AppSelectors.ActiveUser).pipe(
        notNull(),
        map((activeUser) => !!activeUser?.editora_id),
      ),
    }),
    () => ({
      title: 'breadcrumb.learning-trails.studio',
      url: '/universidade-corporativa/estudio',
      icon: 'icon-menu-studio',
      group: 'menu.universidade-corporativa',
      permission: [31, 56],
      show: this.store.select(AppSelectors.ActiveUser).pipe(
        notNull(),
        map((activeUser) => !!activeUser?.editora_id || !!activeUser?.uniao_id || !!activeUser?.divisao_id),
      ),
    }),
    () => ({
      title: 'breadcrumb.learning-trails.studio',
      url: '/ivca/estudio',
      icon: 'icon-menu-studio',
      group: 'menu.ivca',
      permission: [30, 54],
      show: this.store.select(AppSelectors.ActiveUser).pipe(
        notNull(),
        map((activeUser) => activeUser?.editora_id === 1),
      ),
    }),

    () => ({
      title: 'breadcrumb.learning-trails.showcase',
      url: '/trilhas-de-aprendizagem/vitrine',
      icon: 'icon-menu-showcase',
      group: 'menu.trilhas-aprendizagem',
      permission: [18, 31],
    }),
    () => ({
      title: 'breadcrumb.learning-trails.showcase',
      url: '/universidade-corporativa/vitrine',
      icon: 'icon-menu-showcase',
      group: 'menu.universidade-corporativa',
      permission: [31, 57],
    }),
    () => ({
      title: 'breadcrumb.ivca.showcase',
      url: '/ivca/vitrine',
      icon: 'icon-menu-showcase',
      group: 'menu.ivca',
      permission: [30, 59],
    }),

    () => ({
      title: 'breadcrumb.learning-trails.course',
      url: '/trilhas-de-aprendizagem/cursos',
      icon: 'icon-menu-course',
      group: 'menu.trilhas-aprendizagem',
    }),
    () => ({
      title: 'breadcrumb.universidade-corporativa.course',
      url: '/universidade-corporativa/cursos',
      icon: 'icon-menu-course',
      group: 'menu.universidade-corporativa',
      permission: [31, 58],
      show: this.store.select(AppSelectors.ActiveUser).pipe(
        notNull(),
        map((activeUser) => !activeUser?.escola_externa),
      ),
    }),
    () => ({
      title: 'breadcrumb.ivca.course',
      url: '/ivca/cursos',
      icon: 'icon-menu-course',
      group: 'menu.ivca',
      permission: [30, 60],
    }),
  ];

  constructor(private store: Store<AppState>) {}
}
