<ng-container *ngIf="view$ | async as view">
  <ng-container *ngIf="!!view.config">
    <app-sidenav-container (attached)="onContainerAttached($event)">
      <div
        class="absolute z-150 h-full max-w-[300px] overflow-hidden shadow-black/10 duration-300 md:max-w-full"
        [ngClass]="containerClass(view)"
      >
        <div
          class="h-full max-w-[300px] overflow-y-auto overflow-x-hidden md:max-w-full"
          [style.width]="view.config.panelWidth"
        >
          <ng-template
            *ngIf="!!view.template"
            (attached)="onAttached(view, $event)"
            [cdkPortalOutlet]="view.template"
          ></ng-template>
        </div>

        <button
          *ngIf="isShowAnchor(view)"
          app-icon-button
          type="button"
          [ngClass]="[view.position.toggle, view.config.buttonAnchor]"
          class="!absolute top-[50%] h-[68px] w-3 translate-y-[-50%] rounded-none !p-0 shadow-inner"
          (click)="onToggle(view.config, view.position)"
        >
          <svg-icon
            class="flex h-8"
            [ngClass]="view.config.iconAnchor"
            src="assets/icons/icon-three-dots.svg"
          ></svg-icon>
        </button>
      </div>
    </app-sidenav-container>
  </ng-container>
</ng-container>
