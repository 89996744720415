<a [routerLink]="'/'" class="cursor-pointer" *ngIf="!isIVCA">
  <svg-icon class="flex h-6 w-6 hover:text-base-gray-500" src="assets/icons/icon-house.svg"></svg-icon>
</a>

<ng-container *ngFor="let bread of breadcrumbs$ | async; let last = last; let first = first">
  <ng-container *ngIf="!last; else lastTemplate">
    <svg-icon
      *ngIf="!isIVCA || !first"
      class="mx-[5px] flex h-[8px] w-[4px] text-base-gray-300"
      src="assets/icons/icon-arrow-right.svg"
    ></svg-icon>
    <a [routerLink]="bread.url" class="cursor-pointer font-normal text-base-gray-500 hover:text-theme-500">
      {{ bread.label }}
    </a>
  </ng-container>
  <ng-template #lastTemplate>
    <svg-icon
      class="mx-[5px] flex h-[8px] w-[4px] text-base-gray-300"
      src="assets/icons/icon-arrow-right.svg"
    ></svg-icon>
    <span class="text-base-black font-normal text-theme-700">{{ bread.label }}</span>
  </ng-template>
</ng-container>
