import { Injectable } from '@angular/core';
import { portalConst } from '@app/config';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { NotificationService } from '..';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class SkyEnglishService {
  private url = `${portalConst.api.bilingual}/v1/portal`;

  constructor(
    private api: ApiService,
    private translate: TranslateService,
    private notificationService: NotificationService,
  ) {}

  public getObservationReports(entityId: number, year: number): Observable<any> {
    return this.api.get<any[]>(`${this.url}/observation-reports/${entityId}?year=${year}`);
  }

  public getAttendanceReports(entityId: number, year: number): Observable<any> {
    return this.api.get<any[]>(`${this.url}/attendance-reports/${entityId}?year=${year}`);
  }

  public getReports(entityId: number, year: number): Observable<any> {
    return this.api.get<any[]>(`${this.url}/reports/${entityId}?year=${year}`);
  }

  public downloadReport(id: string, route: string, title: string, params: any) {
    const query = this.api.resolveParams(params).toString();
    const url = `${this.url}/${route}/download/${id}?${query}`;

    this.download(url, title);
  }

  private download(fullUrl, filename: string) {
    fetch(fullUrl, { method: 'GET' })
      .then((response) => {
        if (!response.ok) {
          this.notificationService.error(this.translate.instant('servicos.sky-english.erro-download-pdf'));
        }
        return response.blob();
      })
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');

        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);

        window.URL.revokeObjectURL(url);
      });
  }
}
