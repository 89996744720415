import { ConnectionPositionPair } from '@angular/cdk/overlay';

type IndicatorPosition = 'top' | 'right' | 'bottom' | 'left';

export class PositionBorder {
  public readonly position: IndicatorPosition;

  constructor(private data: ConnectionPositionPair) {
    if (this.data.originX === 'center') {
      this.position = this.data.originY === 'bottom' ? 'top' : 'bottom';
    } else {
      this.position = this.data.originX === 'end' ? 'left' : 'right';
    }
  }
}

export const PositionArrow = {
  ['top']: {
    arrow: {
      'border-top-color': 'transparent',
      'border-right-color': 'transparent',
      'border-left-color': 'transparent',
    },
    align: (pair: ConnectionPositionPair) => ({
      width: '100%',
      padding: '0 5px',
      'justify-content': pair.overlayX,
    }),
  },
  ['right']: {
    arrow: {
      'border-top-color': 'transparent',
      'border-right-color': 'transparent',
      'border-bottom-color': 'transparent',
    },
    align: (pair: ConnectionPositionPair) => ({
      height: '100%',
      padding: '5px 0',
      'align-items': Align[pair.overlayY],
    }),
  },
  ['bottom']: {
    arrow: {
      'border-right-color': 'transparent',
      'border-bottom-color': 'transparent',
      'border-left-color': 'transparent',
    },
    align: (pair: ConnectionPositionPair) => ({
      width: '100%',
      padding: '0 5px',
      'justify-content': pair.overlayX,
    }),
  },
  ['left']: {
    arrow: {
      'border-top-color': 'transparent',
      'border-bottom-color': 'transparent',
      'border-left-color': 'transparent',
    },
    align: (pair: ConnectionPositionPair) => ({
      height: '100%',
      padding: '5px 0',
      'align-items': Align[pair.overlayY],
    }),
  },
};

const Justify = {
  ['start']: 'end',
  ['center']: 'center',
  ['end']: 'start',
};

const Align = {
  ['top']: 'start',
  ['center']: 'center',
  ['bottom']: 'end',
};
