import { Injectable } from '@angular/core';
import { portalConst } from '@app/config';
import { ApiService } from './api.service';
import { CrudService } from './crud.service';
import { AcademicSchedule } from '../models';

@Injectable({
  providedIn: 'root',
})
export class AcademicScheduleService extends CrudService<AcademicSchedule> {
  constructor(api: ApiService) {
    super(api, `${portalConst.api.academic}/v1/schedule`);
  }

  public content(scheduleId: number, data: any) {
    return this.api.post(`${this.url}/content/${scheduleId}`, data);
  }
}
