import { Injectable } from '@angular/core';
import { portalConst } from '@app/config';
import { Observable } from 'rxjs';
import { Activity, ActivitySummary } from '../models';
import { ApiService, Resp } from './api.service';
import { CrudService } from './crud.service';

@Injectable({ providedIn: 'root' })
export class ActivityService extends CrudService<any> {
  constructor(api: ApiService) {
    super(api, `${portalConst.api.eclass}/v1/activities`);
  }

  public answer(id: string): Observable<Resp<any>> {
    return this.api.get(`${this.url}/answer/${id}`);
  }

  public answers(activityId: string): Observable<Resp<Activity>> {
    return this.api.get(`${this.url}/answers/${activityId}`);
  }

  public pendingAnswers(): Observable<Resp<any[]>> {
    return this.api.get(`${this.url}/pending-answers`);
  }

  public activities(): Observable<Resp<ActivitySummary[]>> {
    return this.api.get(`${this.url}/student`);
  }

  public notCompleted(): Observable<Resp<any[]>> {
    return this.api.get(`${this.url}/not-completed`);
  }

  public getTrailElementsProgress(activityId: string) {
    return this.api.get(`${this.url}/trails-progress/${activityId}`);
  }
}
