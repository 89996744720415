import { FunctionsEnum } from '@app/core/models';

function isResponavel(functionId: number): boolean {
  return [FunctionsEnum.responsavelInconcluso, FunctionsEnum.responsavel].includes(functionId);
}

function isStudent(functionId: number): boolean {
  return FunctionsEnum.aluno === functionId;
}

function isStudentOrResponsible(functionId: number): boolean {
  return [FunctionsEnum.aluno, FunctionsEnum.responsavel].includes(functionId);
}

function isEmployee(functionId: number): boolean {
  return !(isStudent(functionId) || isResponavel(functionId));
}

function isTeacher(defaultFunction: number): boolean {
  return [6, 48, 49, 50, 51].includes(defaultFunction);
}

function isAdmin(defaultFunction: number): boolean {
  return ![6, 48, 49, 50, 51, 10, 72, 20].includes(defaultFunction);
}

function isTechnical(defaultFunction: number): boolean {
  return [52].includes(defaultFunction);
}

function isTrue(value: boolean, message: any) {
  if (value) {
    throw message;
  }
}

function isFalse(value: boolean, message: any) {
  isTrue(!value, message);
}

function isInvalidResponsavel(data: any) {
  const invalid = isResponavel(data.funcao_padrao) && !data.dependente_padrao;

  if (invalid) {
    throw {
      title: 'geral.atencao',
      html: 'geral.erros.responsavel-sem-dependente',
      icon: 'warning',
      confirmButtonText: 'Ok',
    };
  }
}

export const Checker = {
  isInvalidResponsavel,
  isResposible: isResponavel,
  isStudent,
  isEmployee,
  isTeacher,
  isAdmin,
  isTechnical,
  isTrue,
  isFalse,
  isStudentOrResponsible,
};
