import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { AppEffects } from './app.effects';
import * as fromApp from './app.reducer';
import { featureKey } from './app.selectors';

@NgModule({
  imports: [CommonModule, StoreModule.forFeature(featureKey, fromApp.reducer), EffectsModule.forFeature([AppEffects])],
})
export class AppStoreModule {}
