<app-popover>
  <div class="grid h-auto px-2">
    <button
      *ngIf="!isOnline && (isPreviewer || canPreview)"
      app-outline-button
      (click)="onAssessmentsPrintSettingsComponent()"
      class="group my-1 h-9 w-full min-w-[140px] !justify-start border-none bg-black/5 text-left text-black/80 hover:bg-theme-500 hover:font-semibold hover:text-white"
    >
      <svg-icon
        class="mr-2 w-5 text-theme-500 group-hover:text-white"
        src="assets/icons/icon-clipboard-check.svg"
      ></svg-icon>
      <span class="group-hover:text-theme-contrast md:inline-block" translate>
        gestor-avaliacoes.configuracoes-impressao
      </span>
    </button>

    <button
      app-outline-button
      *ngIf="isEntity && !isOnline"
      (click)="onCreateResponseCard()"
      class="group my-1 h-9 w-full min-w-[140px] !justify-start border-none bg-black/5 text-left text-black/80 hover:bg-theme-500 hover:font-semibold hover:text-white"
    >
      <svg-icon
        class="mr-2 w-5 text-theme-500 group-hover:text-white"
        src="assets/icons/icon-clipboard-check.svg"
      ></svg-icon>
      <span class="group-hover:text-theme-contrast md:inline-block" translate> gestor-avaliacoes.gerar-cartao </span>
    </button>
  </div>
</app-popover>
