<app-card class="h-full">
  <div app-card-header class="relative flex w-full !flex-row justify-between px-5 pt-5">
    <div class="flex items-center gap-2">
      <h1 class="pl-2 text-lg uppercase" translate>geral.reunioes-atendimento</h1>
    </div>
  </div>
  <hr app-divider class="my-4" />
  <ng-container *ngIf="hasFutureReports || hasPastReports; else emptyTemplate">
    <div
      class="flex h-[22.5rem] flex-col gap-2 overflow-y-auto px-2"
      infinite-scroll
      [infiniteScrollDistance]="scrollDistance"
      [infiniteScrollUpDistance]="scrollUpDistance"
      [infiniteScrollThrottle]="throttle"
      (scrolled)="onScrollDown()"
      [scrollWindow]="false"
    >
      <ng-container *ngIf="hasFutureReports">
        <div class="flex w-full flex-col gap-2 border p-4">
          <div translate>geral.proximas</div>
          <ng-container *ngFor="let futureReport of reports?.future">
            <div
              class="flex flex-row items-center justify-between gap-4 rounded-lg border border-amber-200 bg-amber-50 px-4 py-3 text-gray-700 transition-all duration-300 hover:bg-amber-100"
            >
              <div>
                <div class="flex w-14 flex-col items-center justify-center rounded-lg bg-amber-500/30 py-1">
                  <span class="text-2xl">{{ futureReport.date | date : 'dd' }}</span>
                  <span class="text-xs leading-none">
                    {{ futureReport.date | date : 'MMMM' | slice : 0 : 3 | uppercase }}
                  </span>
                </div>
              </div>
              <div class="flex h-full w-full flex-col justify-center gap-1 truncate">
                <h1 class="truncate text-base font-semibold">{{ futureReport.description }}</h1>
                <h1 class="truncate text-sm"><b translate>geral.autor-geral</b> {{ futureReport.author }}</h1>
                <h1 class="truncate text-sm"><b translate>academico.aluno-nome</b> {{ futureReport.student }}</h1>
                <h1 class="truncate text-sm">
                  <b translate>academico.horario</b> {{ futureReport.date | date : 'HH:mm' }}
                </h1>
              </div>
              <div *ngIf="futureReport.attendance_mode === 2 && !!futureReport.attendance_link">
                <a
                  class="h-9 w-9 bg-amber-500/50 transition duration-300 hover:bg-amber-500/60"
                  app-flat-button
                  app-tooltip
                  tooltip="Acessar link da reunião"
                  [href]="futureReport.attendance_link"
                  target="_blank"
                >
                  <svg-icon class="w-full p-1 text-white" src="assets/icons/icon-arrow-entry.svg"></svg-icon>
                </a>
              </div>
            </div>
          </ng-container>
        </div>
      </ng-container>
      <ng-container *ngIf="hasPastReports">
        <div class="flex w-full flex-col gap-2 border p-4">
          <div translate>geral.anteriores</div>
          <ng-container *ngFor="let pastReport of reports?.past">
            <div
              class="flex flex-row items-center justify-between gap-4 rounded-lg bg-base-gray-light-400 px-4 py-3 text-gray-700 transition-all duration-300 hover:bg-base-gray-light-500"
            >
              <div>
                <div class="flex w-14 flex-col items-center justify-center rounded-lg bg-base-gray-light-600 py-1">
                  <span class="text-2xl">{{ pastReport.date | date : 'dd' }}</span>
                  <span class="text-xs leading-none">
                    {{ pastReport.date | date : 'MMMM' | slice : 0 : 3 | uppercase }}
                  </span>
                </div>
              </div>
              <div class="flex h-full w-full flex-col gap-1 truncate">
                <h1 class="truncate text-base font-semibold">{{ pastReport.description }}</h1>
                <h1 class="truncate text-sm"><b translate>geral.autor-geral</b> {{ pastReport.author }}</h1>
                <h1 class="truncate text-sm"><b translate>academico.aluno-nome</b> {{ pastReport.student }}</h1>
                <h1 class="truncate text-sm">
                  <b translate>academico.horario</b> {{ pastReport.date | date : 'HH:mm' }}
                </h1>
              </div>
            </div>
          </ng-container>
        </div>
      </ng-container>
    </div>
  </ng-container>
  <ng-template #emptyTemplate>
    <div app-card-content class="h-full">
      <app-jumbotron translate class="py-8">geral.nenhuma-reuniao</app-jumbotron>
    </div>
  </ng-template>
</app-card>
