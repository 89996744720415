import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

const DEFAULT_MS = 3000;
@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(private toastr: ToastrService, private _translateService: TranslateService) {}

  public show(message: string, title?: string, timeOut: number = DEFAULT_MS) {
    this.toastr.show(this._translateService.instant(message), title, { timeOut });
  }

  public success(message: string, title?: string, timeOut: number = DEFAULT_MS) {
    this.toastr.success(this._translateService.instant(message), title, { timeOut });
  }

  public warning(message: string, title?: string, timeOut: number = DEFAULT_MS) {
    this.toastr.warning(this._translateService.instant(message), title, { timeOut });
  }

  public error(message: string, title?: string, timeOut: number = DEFAULT_MS) {
    this.toastr.error(this._translateService.instant(message), title, { timeOut });
  }

  public info(message: string, title?: string, timeOut: number = DEFAULT_MS) {
    this.toastr.info(this._translateService.instant(message), title, { timeOut });
  }
}
