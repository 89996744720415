<app-modal-header class="border-b-2 border-theme-500">
  <div class="flex w-full gap-5 bg-white">
    <div class="flex">
      <svg-icon
        class="w-10 text-theme-500"
        src="assets/icons/icon-gestor-avaliacoes.svg"
        alt="Materiais Didaticos"
      ></svg-icon>
      <h1 class="ml-3 hidden text-sm font-semibold leading-tight text-gray-800 md:grid" translate>
        gestor-avaliacoes.e-class <br />
        <b class="text-lg text-black" translate> gestor-avaliacoes.prova </b>
      </h1>
    </div>
    <hr app-modal-divider />
    <div>
      <div class="flex w-full items-center justify-between pt-2">
        <div class="md:flex">
          <div class="mr-5">
            <span class="text-md text-black" translate> gestor-avaliacoes.cartao-resposta-upload </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</app-modal-header>

<div class="flex h-auto !p-0">
  <app-sidenav-group>
    <ng-template app-sidenav-outer>
      <div class="relative h-auto w-[40vw]">
        <div class="w-[90vw] px-4 py-4 lg:w-full">
          <div class="flex flex-wrap gap-6">
            <div *ngIf="false" class="flex flex-col gap-4 rounded-lg border border-yellow-200 bg-yellow-50 px-5 py-2">
              <svg-icon
                class="flex w-[14px] text-yellow-300"
                src="assets/icons/icon-notify-fine.svg"
                alt="icon"
              ></svg-icon>
              <ul class="list-disc pl-5">
                <li class="text-sm text-zinc-900">
                  is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled
                  it to make a type specimen book. It has survived not only five centuries, but also the leap into
                  electronic typesetting, remaining essentially unchanged.
                </li>
                <li class="text-sm text-zinc-900">
                  It is a long established fact that a reader will be distracted by the readable content of a page when
                  looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal
                  distribution of letters, as opposed to using
                </li>
                <li class="text-sm text-zinc-900">
                  There are many variations of passages of Lorem Ipsum available, but the majority have suffered
                  alteration in some form, by injected humour
                </li>
              </ul>
            </div>

            <div class="flex w-full">
              <app-form-field class="w-full">
                <label app-label class="font-semibold" translate> gestor-avaliacoes.modelo </label>
                <ng-select
                  app-select
                  [formControl]="modelCtrl"
                  [clearable]="false"
                  [searchable]="false"
                  [items]="printResponseCard"
                  bindLabel="name"
                  bindValue="id"
                  [placeholder]="'gestor-avaliacoes.selecione-modelo' | translate"
                >
                  <ng-template ng-label-tmp let-index="index" let-item="item">
                    <div class="flex items-center gap-2">
                      <img height="25" width="25" [src]="item.icon" />
                      <span>{{ item.name }}</span>
                    </div>
                  </ng-template>

                  <ng-template ng-option-tmp let-item="item" let-index="index">
                    <div class="!flex !items-center !gap-2">
                      <img height="25" width="25" [src]="item.icon" /> <span>{{ item.name }}</span>
                    </div>
                  </ng-template>
                </ng-select>
              </app-form-field>
            </div>
            <div class="w-full">
              <app-upload-file
                (addedFile)="onUpload($event)"
                [componentReset]="resetDropzone$ | async"
                class="w-full"
                [config]="config"
              ></app-upload-file>
            </div>
            <div class="flex h-[14vh] w-full flex-col gap-2 overflow-auto overflow-y-auto">
              <app-card
                *ngFor="let file of files; let i = index"
                class="flex w-full !flex-row items-center justify-between border p-4"
              >
                <div class="flex items-center gap-2">
                  <svg-icon
                    class="flex h-8 w-8 cursor-pointer text-gray-500 hover:text-gray-600"
                    src="assets/icons/icon-upload-small.svg"
                  ></svg-icon>
                  <div class="mx-4 flex flex-col items-start whitespace-nowrap">
                    <span class="text-sm font-bold md:text-md">{{ file?.fileName }}</span>
                  </div>
                </div>
                <button app-flat-button (click)="removeFile(i)" type="button" class="bg-base-status-danger">
                  <svg-icon class="flex h-5 w-5 text-white" src="assets/icons/icon-trash.svg"></svg-icon>
                </button>
              </app-card>
            </div>
            <div class="flex w-full justify-end py-4">
              <button
                [disabled]="loading$ | async"
                app-flat-button
                type="button"
                class="flex transform items-center justify-center gap-2 bg-theme-500 px-5 py-2 uppercase text-white shadow-md transition duration-300 ease-in-out hover:bg-theme-600 focus:outline-none focus:ring-2 focus:ring-theme-500 focus:ring-opacity-50 disabled:opacity-50"
                (click)="save()"
              >
                <div class="flex h-6 w-6 items-center justify-center">
                  <ng-container *ngIf="loading$ | async; else uploadContent">
                    <app-skeleton class="flex h-5 w-5" baseStyle="bg-theme-500" [showLabel]="false"></app-skeleton>
                  </ng-container>
                  <ng-template #uploadContent>
                    <svg-icon class="flex h-6 w-6" src="assets/icons/icon-upload.svg"></svg-icon>
                  </ng-template>
                </div>
                <span translate>gestor-avaliacoes.fazer-upload</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </ng-template>

    <ng-template app-sidenav-end [opened]="true">
      <ng-container class="!px-4 !py-4" *ngIf="uploadHistory$ | async as uploadHistory">
        <div class="flex flex-col gap-2 px-4 py-4">
          <p class="font-semibold">Histórico de envios</p>
          <ng-container *ngIf="!uploadHistory?.length; else dataTemplate">
            <app-card>
              <div app-card-content class="grid gap-4 !p-3">
                <app-skeleton *ngIf="loadingHistory$ | async; else jumbotron"></app-skeleton>
                <ng-template #jumbotron>
                  <app-jumbotron [showIcon]="false" class="py-2" translate>
                    <svg-icon
                      class="mb-5 w-10 text-gray-300"
                      src="assets/icons/icon-info-rounded.svg"
                      alt="Materiais Didaticos"
                    >
                    </svg-icon>
                    Nenhum envio realizado
                  </app-jumbotron>
                </ng-template>
              </div>
            </app-card>
          </ng-container>
          <ng-template #dataTemplate>
            <app-card
              *ngFor="let history of uploadHistory; let i = index"
              class="flex w-full items-center justify-between border p-4"
            >
              <div class="flex items-center gap-2">
                <div class="mx-4 flex flex-col items-start whitespace-nowrap">
                  <div class="text-sm font-bold md:text-md">{{ history?.nome }}</div>
                  <div class="flex items-start">
                    <span *ngIf="!!history.user" class="text-sm font-light md:text-base">
                      {{ history?.user?.name }} - {{ history?.created_at | date : 'dd/MM/yyyy HH:mm' }}
                    </span>
                    <span
                      *ngIf="history.tentativas_count === history.page_count"
                      class="text-sm font-light md:text-base"
                    >
                      - Finalizado</span
                    >
                    <span
                      *ngIf="history.tentativas_count !== history.page_count"
                      class="text-sm font-light md:text-base"
                    >
                      - Processando</span
                    >
                  </div>
                </div>
              </div>
              <div class="flex items-center gap-4">
                <span>{{ history.tentativas_count || 0 }}/{{ history.page_count }}</span>
                <button app-flat-button (click)="downloadFile(history)" type="button" class="bg-base-status-info">
                  <svg-icon
                    app-tooltip
                    [tooltip]="'geral.download' | translate"
                    class="flex h-5 w-5 text-white"
                    src="assets/icons/icon-download.svg"
                  ></svg-icon>
                </button>
                <button app-flat-button (click)="onRemove(history.id)" type="button" class="bg-base-status-danger">
                  <svg-icon
                    app-tooltip
                    [tooltip]="'gestor-avaliacoes.remover' | translate"
                    class="flex h-5 w-5 text-white"
                    src="assets/icons/icon-trash.svg"
                  ></svg-icon>
                </button>
              </div>
            </app-card>
          </ng-template>
        </div>
      </ng-container>
    </ng-template>
  </app-sidenav-group>
</div>
