export interface Attachment {
  id: number;
  file: string;
  url?: string;
  title: string;
  size: number;
  extension: string;
  type: TypeAttachment;
  html: string;
  vimeo_account?: string;
  thumbnail?: string;
  vimeo_hash?: string;
  created_at?: string;
  viewed_at?: string;
}

export enum TypeAttachment {
  Video = 1,
  Audio = 2,
  Image = 3,
  PDF = 4,
  Office = 5,
  Game = 6,
  HTML = 99,
}

export const ICON_PATHS = {
  [TypeAttachment.Video]: 'assets/icons/icon-video.svg',
  [TypeAttachment.Audio]: 'assets/icons/icon-music.svg',
  [TypeAttachment.Image]: 'assets/icons/icon-image.svg',
  [TypeAttachment.PDF]: 'assets/icons/icon-activity-file.svg',
  [TypeAttachment.Office]: 'assets/icons/icon-page.svg',
  [TypeAttachment.Game]: 'assets/icons/icon-page.svg',
};
